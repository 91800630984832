import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {EcoMapMixin} from '../../../../../version1/bo/ecomap/EcoMapMixin'
//import {PackageReference as PlanPackageReference} from '../../../../bo/transformation/PackageReference'
import { ScenarioProxy } from '../../../../../version1/bo/transformation/ScenarioProxy'
import { PackageReference } from '../../../../../com/vbee/filesystem/PackageReference'

/*define(["require", "jquery", "underscore", 'async', "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/ValueProposition", "appbo/vdml/Participant", "appbo/vdml/ValueDeliveryModel", "appcommon/com/vbee/filesystem/PackageReference", "appbo/transformation/PackageReference", "appbo/transformation/ScenarioProxy", "appbo/vdml/EcoMapDiagramMixin", "appbo/vdml/OrgUnit"],
function (require, $, _, async, Backbone, ko, koMapping, kb, bootbox, DataManager, global, BusinessModel, ValueProposition, Participant, ValueDeliveryModel, PackageReference, PlanPackageReference, ScenarioProxy, EcoMapDiagramMixin, OrgUnit) {
*/
    var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer",global.version);
export class EcomapMappingWizardViewModel {
	constructor(modelElement, model, options,  diagramBO, callback){
		var self = this;
		this.init(modelElement, model, options,  diagramBO, callback);
	}
	init(modelElement, model, options,  diagramBO, callback) {
        var self = this;
        this.EcomapMappingWizardViewModel = this;
        this.model = model;
        this.modelElement = modelElement;
        this.callback = callback;
        this.shapeTypeFromWizard = ko.observable();
        this.id = ko.observable();
        if(options.shapeTypeFromWizard){
            this.shapeTypeFromWizard(options.shapeTypeFromWizard);
            this.id(options.idFromWizard);
        }else{
            this.id(self.model ? self.model.get('id') : null);
        }
        this.mappedDiagramBO = null;
        if(diagramBO.mid){
            this.mappedDiagramBO = diagramBO;
        }
        this.parentView = options.parentView;
        //this.allShapesList = self.parentView.shapesList();
        this.selectedVDMPackage;
        this.BMsColl = new Backbone.Collection();
        this.businessColl = new Backbone.Collection();
        this.PNsColl = new Backbone.Collection();
        this.VPsColl = new Backbone.Collection();
        this.participantsColl = new Backbone.Collection();
        this.rolesColl = new Backbone.Collection();
        this.partTypesList = ko.observableArray([{ name: 'Market Segment', type: 'vdml.Community' }, { name: 'Enterprise', type: 'vdml.OrgUnit' }, { name: 'Individual', type: 'vdml.Actor' }]);
        this.relationTypesList = ko.observableArray(["Partner","Customer"]);
        this.selectedRelType = ko.observable();
        this.selectedPartType = ko.observable(); 
        this.selectedParticipant;
        this.packagesListBM = ko.observableArray([]);
        this.packagesListOther = ko.observableArray([]);
        this.packagesListVP = ko.observableArray([]);
        this.selectedBM;
        this.selectedBMVP;
        this.selectedPN;
        this.selectedRole;
        this.showPartType = ko.observable(false);
        this.enablePartType = ko.observable(true);
        this.fetchedObjects = {};
        this.updateVPBasedOnTheRecipientMapping;
        if(!self.parentView.modeler){
			var presentationModels = self.parentView.charts()
			for(var i=0; i<presentationModels.length; i++){
				if(self.model == presentationModels[i].viewInstance.model){
					self.parentView = presentationModels[i].viewInstance;
				}
			}
		}
        this.eventBus = self.parentView.modeler.get('eventBus');
        function htmlEscape(str) {
            return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
        }
        model = null;
        this.encodeId = htmlEscape(this.id());
        this.mappingObject = {};
        this.mappingObject['vdml:MarketSegment'] = { name: 'Market Segment', type: 'vdml.Community' };
        this.mappingObject['vdml:Enterprise'] = { name: 'Enterprise', type: 'vdml.OrgUnit' };
        this.mappingObject['vdml:Individual'] = { name: 'Individual', type: 'vdml.Actor' };
        this.mappingObject['vdml:BusinessModel'] = { name: 'Business Model', type: 'vdml.BusinessModel' };
        this.mappingObject['vdml:Role'] = { name: 'Role', type: 'vdml.Role' };
        this.mappingObject['vdml:ValueProposition'] = { name: 'Value Proposition', type: 'vdml.ValueProposition' };
        this.shapeTypesList = ko.observableArray([]);
		Object.getOwnPropertyNames(this.mappingObject).forEach(function(key){
            self.shapeTypesList.push(self.mappingObject[key]);
        });
        this.oldShape;
        this.selectedShapeType;
        this.selectedPackageBM = ko.observable();
        this.selectedPackageOther = ko.observable();
        this.selectedPackageVP = ko.observable();
        this.selectedMappingBM;
        this.selectedBusiness;
        this.selectedBusinessOther;
        this.vpProviders = ko.observableArray([]);
        this.vpProvider = ko.observable();
        this.vpProviderRoles = ko.observableArray([]);
        this.vpReceiverRoles = ko.observableArray([]);
        this.selectedVP;
        this.selectedProviderRole = ko.observable();
        this.selectedReceiverRole = ko.observable();
        this.vpReceivers = ko.observableArray([]);
        this.vpReceiver = ko.observable();
        this.providerShape;
        this.receiverShape;
        this.providerShapeMappingExists = false;
        this.receiverShapeMappingExists = false;
        this.myProposition = ko.observable(false);
        this.selectedPackageBM.subscribe(function(pack){
            self.BMsColl.reset();
            self.bmTypeahead.rerender([]);
            $('#selectBM'+self.encodeId).val('');
            self.selectedMappingBM = null;
            self.onChangeSelectedMappingBM();
            if(!pack || pack.name === "New" && !pack.id){
                self.selectedVDMPackage = null;
                $('#showMappedInfoPackageBM'+self.encodeId).hide();
                if(diagramBO.get('name')){
                    $('#selectBM' + self.encodeId).val(diagramBO.get('name'));
                }
            }else{
                $('#showMappedInfoPackageBM'+self.encodeId).show();
                fillVDMPackages(pack,function(VDMPack){
                     self.selectedVDMPackage = VDMPack;
                     for(var i=0;i<VDMPack.get('businessModel').models.length;i++){
                        if(self.mappedDiagramBO){
                            if(VDMPack.get('businessModel').models[i].id === self.mappedDiagramBO.mid){
                                self.BMsColl.push({ id:VDMPack.get('businessModel').models[i].id, name: VDMPack.get('businessModel').models[i].get('name') });
                                $('#selectBM' + self.encodeId).val(self.BMsColl.models[0].get('name'));
                                self.selectedMappingBM = self.BMsColl.models[0];
                                self.onChangeSelectedMappingBM();
                                $('#selectBM' + self.encodeId).attr('disabled', 'disabled');
                                break;
                            }
                         }else{
                            if(self.providerVPShape || self.receiverVPShape){
                                  var VPShape = self.providerVPShape? self.providerVPShape : self.receiverVPShape;
                                  var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({id:VPShape.mid});
                                  var providerRole = vp.get('provider');
                                  var recipientRole = vp.get('recipient');
                                  var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:VDMPack.get('businessModel').models[i].id});
                                  if(bm.get('bmRole').findWhere({'id':providerRole.id})){
                                    if(recipientRole){
                                        if(bm.get('bmRole').findWhere({id:recipientRole.id})){
                                            self.BMsColl.push({ id:VDMPack.get('businessModel').models[i].id, name: VDMPack.get('businessModel').models[i].get('name') });
                                        }
                                    }else{
                                        self.BMsColl.push({ id:VDMPack.get('businessModel').models[i].id, name: VDMPack.get('businessModel').models[i].get('name') });
                                    }
                                  }
                            }else{
                                self.BMsColl.push({ id:VDMPack.get('businessModel').models[i].id, name: VDMPack.get('businessModel').models[i].get('name') });
                            }
                         }
                      }
                      self.businessColl.reset();
                      //get the BusinessColl from package
                      VDMPack.getBusinessCollOfPackage(VDMPack, self.currentAlternative, self.businessColl);
                      if(!self.fetchedObjects[VDMPack.id]){
                          self.fetchedObjects[VDMPack.id] = {};
                      }
                      self.fetchedObjects[VDMPack.id]['vdml_OrgUnit'] = self.businessColl;
                     if(diagramBO.get('name')){
                            $('#selectBM' + self.encodeId).val(diagramBO.get('name'));
                            var bmFound = _.filter(self.BMsColl.models,function(obj){return obj.get('name').toLowerCase() === diagramBO.get('name').toLowerCase();});
                            if(bmFound && bmFound.length){
                                self.selectedMappingBM = bmFound[0];
                                self.onChangeSelectedMappingBM();
                            }
                      }
                      self.enableCompleteForBM();
                 });
            }
        });
        this.selectedPackageOther.subscribe(function(pack){
            self.BMsColl.reset();
            self.bmTypeaheadOther.rerender([]);
            $('#selectBMOther'+self.encodeId).val('');
            self.selectedBMOther = null;
            self.onChangeSelectedBMOther();
            if(!self.mappedDiagramBO){
                $('#selectParticipant'+self.encodeId).val('');
                self.selectedParticipant = null;
                self.onChangeSelectedParticipant();
            }
            /*self.participantsColl.reset();
            self.partTypeahead.rerender([]);*/
            self.enablePartType(true);
            if(!pack || pack.name === "New" && !pack.id){                
                self.selectedVDMPackage = null;
                $('#showMappedInfoPackageOther'+self.encodeId).hide();
            }else{
                $('#showMappedInfoPackageOther'+self.encodeId).show();
                fillVDMPackages(pack,function(VDMPack){
                    self.selectedVDMPackage = VDMPack;
                    _.each(VDMPack.get('businessModel').models,function(bm){
                        self.BMsColl.push({ id:bm.id,name: bm.get('name')});
                    });
                    self.businessColl.reset();
                    //get BusinessColl from package
                    VDMPack.getBusinessCollOfPackage(VDMPack, self.currentAlternative, self.businessColl);
                });
            }
        });
        this.selectedPackageVP.subscribe(function(pack){
            self.BMsColl.reset();
            self.bmTypeaheadVP.rerender([]);
            $('#selectBMVP'+self.encodeId).val('');
            self.selectedBMVP = null;
            self.onChangeSelectedBMVP();
            self.vpProviders.removeAll();
            self.vpReceivers.removeAll();
            self.vpProviderRoles.removeAll();
            self.vpReceiverRoles.removeAll();
            self.vpProvider(null);
            self.vpReceiver(null);
            self.selectedProviderRole(null);
            self.selectedReceiverRole(null);
            self.selectedVDMPackage = null;
            if(self.packagesListVP().length > 0 && self.packagesListVP()[0].id){ 
                if(self.providerShape){
                    self.loadProviders(self.packagesListVP()[0].id, self.mappingObject[self.providerShape.$type].type);
                }else{
                    self.loadProviders(self.packagesListVP()[0].id);
                }
                if(self.receiverShape){
                    self.loadReceivers(self.packagesListVP()[0].id, self.mappingObject[self.receiverShape.$type].type);
                }else{
                    self.loadReceivers(self.packagesListVP()[0].id);
                }
            }
            if(!pack || pack.name === "New" && !pack.id){
                $('#showMappedInfoPackageVP'+self.encodeId).hide();
            }else{
                $('#showMappedInfoPackageVP'+self.encodeId).show();
                fillVDMPackages(pack,function(VDMPack){
                    self.selectedVDMPackage = VDMPack;
                    if(self.mappedDiagramBO){
                        var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.mappedDiagramBO.mid });
                        var vf = vp.get('isValueFormula');
                        var providerRole = vp.get('provider');
                        var recipientRole = vp.get('recipient');
						_.each(VDMPack.get('businessModel').models, function (bm) {
							if (vf) {
								if (bm.get('business') === providerRole.get('roleAssignment').at('0').get('participant')) {
									self.BMsColl.push({ id: bm.id, name: bm.get('name') });
								}
							} else {
								if (bm.get('bmRole').findWhere({ id: providerRole.id }) || (recipientRole && bm.get('bmRole').findWhere({ id: recipientRole.id }))) {
									self.BMsColl.push({ id: bm.id, name: bm.get('name') });
								}
							}
                        });
                        if(self.BMsColl.models.length > 0){
                            $('#selectBMVP'+self.encodeId).val(self.BMsColl.models[0].get('name'));
                            self.selectedBMVP = self.BMsColl.models[0];
                            self.onChangeSelectedBMVP();
                        }
                    }else{
                        _.each(VDMPack.get('businessModel').models,function(bm){
                            self.BMsColl.push({ id:bm.id, name: bm.get('name') });
                        });
                    }
                });
            }
            if(!self.mappedDiagramBO){
                self.findProviderAndReceiverShapes();
            }
        });
        this.onChangeSelectedMappingBM = function(){
            if(self.selectedMappingBM){ //on selecting an existing bm, fill the business and disable the field
                $('#showMappedInfoBM'+self.encodeId).show();
                var business = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedMappingBM.id}).get('business');
                $('#selectBusiness'+self.encodeId).val(business?business.get('name'):'My Business');
                if(business) {
                  self.selectedBusiness = business;
                  $('#showMappedInfoBusiness'+self.encodeId).show();
                  $('#selectBusiness'+self.encodeId).attr('disabled','disabled');
                }
            }else{
                $('#showMappedInfoBM'+self.encodeId).hide();
                $('#selectBusiness'+self.encodeId).val('');
                self.selectedBusiness = null;
                $('#showMappedInfoBusiness'+self.encodeId).hide();
                $('#selectBusiness'+self.encodeId).removeAttr('disabled');
            }
        };
        this.onChangeSelectedBMOther = function(){
            if(!self.mappedDiagramBO){
                $('#selectParticipant'+self.encodeId).val('');
                self.selectedParticipant = null;
                self.onChangeSelectedParticipant();
                self.participantsColl.reset();
                self.partTypeahead.rerender([]);
                self.enablePartType(true);
                if(self.packagesListOther().length > 0){
                    self.loadParticipants(self.packagesListOther()[0].id);  
                }
                $('#selectParticipant' + self.encodeId).removeAttr('disabled');
            }
            $('#relTypes' + self.encodeId).removeAttr('disabled');
            if(self.selectedBMOther){ // if existing bm is selected, load participants
                var business = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMOther.id}).get('business');
                if(self.mappedDiagramBO && self.selectedParticipant){
                    if((self.mappedDiagramBO.$type === 'vdml:Enterprise' && self.mappedDiagramBO.mid === self.selectedParticipant.id) ||
                     (self.selectedRole && self.mappedDiagramBO.$type === 'vdml:Role' && self.mappedDiagramBO.mid === self.selectedRole.id)){
                        if(self.relationTypesList.indexOf('Business') === -1){
                            self.relationTypesList.push("Business");
                        }
                        var part = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id:self.selectedParticipant.id});
                        if(business && business.id === self.selectedParticipant.id) {
                            self.selectedRelType('Business');
                            $('#relTypes' + self.encodeId).attr('disabled','disabled');
                        }else{
                            self.relationTypesList.remove("Business");
                        }
                    }
                }
                $('#showMappedInfoBMOther'+self.encodeId).show();
                if (self.selectedShapeType.name === 'Enterprise' && diagramBO.get('name') && business && diagramBO.get('name').toLowerCase() === business.get('name').toLowerCase()){
                    self.selectedRelType("Business");
                }
                $('#selectBusinessOther'+self.encodeId).val(business?business.get('name'):'My Business');
                self.selectedBusinessOther = business;
                if(business) {
                  $('#showMappedInfoBusinessOther'+self.encodeId).show();
                  $('#selectBusinessOther'+self.encodeId).attr('disabled','disabled');
                }
                if(self.selectedRelType() === "Business" && business){
                    var partToDisplay = self.participantsColl.findWhere({'id':business.id});
                    if(partToDisplay){
                        $('#selectParticipant' + self.encodeId).val(partToDisplay.get('name'));
                        self.selectedParticipant = partToDisplay;
                        self.onChangeSelectedParticipant();
                    }
                }
            }else{
                if((self.selectedShapeType.name === 'Enterprise' || self.selectedShapeType.name === 'Role') && self.relationTypesList.indexOf('Business') === -1){
                    self.relationTypesList.push("Business");
                }
                $('#showMappedInfoBMOther'+self.encodeId).hide();
                $('#selectBusinessOther'+self.encodeId).val('');
                self.selectedBusinessOther = null;
                $('#showMappedInfoBusinessOther'+self.encodeId).hide();
                $('#selectBusinessOther'+self.encodeId).removeAttr('disabled');
                if(self.mappedDiagramBO){
                    if(self.selectedRelType() === "Business"){
                        var businessToDisplay = _.filter(self.businessColl.models,function(obj){return obj.id === self.selectedParticipant.id;});
                        $('#selectBusinessOther'+self.encodeId).val(businessToDisplay[0].get('name'));
                        self.selectedBusinessOther = businessToDisplay[0];
                        $('#showMappedInfoBusinessOther'+self.encodeId).show();
                        $('#selectBusinessOther'+self.encodeId).attr('disabled','disabled');
                    }else{
                        $('#selectBusinessOther'+self.encodeId).removeAttr('disabled');
                    }
                }

            }
            if(!self.mappedDiagramBO && self.selectedRelType() !== "Business"){
                if(diagramBO.get('name')){
                    if(diagramBO.$type !== 'vdml:Role'){
                        $('#selectParticipant' + self.encodeId).val(diagramBO.get('name'));
                    }
                    var partFound = _.filter(self.participantsColl.models,function(obj){return obj.get('name').toLowerCase() === diagramBO.get('name').toLowerCase()});
                    if(partFound && partFound.length){
                        self.selectedParticipant = partFound[0];
                        self.onChangeSelectedParticipant();
                    }
                }
            }
        };
        this.onChangeSelectedBMVP = function(){
            self.vpProviderRoles.removeAll();
            self.vpReceiverRoles.removeAll();
            self.selectedProviderRole(null);
            self.selectedReceiverRole(null);
            if(!self.mappedDiagramBO){
                    if(!self.providerShapeMappingExists){// if its not mapped, you can remove disabled attr
                        $('#providerField'+self.encodeId).removeAttr('disabled');
                        $('#providerRoleField'+self.encodeId).removeAttr('disabled');
                    }else{
                        if(self.providerShape.$type !== "vdml:Role"){//if it is mapped remove disabled attr for role
                            $('#providerRoleField'+self.encodeId).removeAttr('disabled');
                        }
                    }
                    if(!self.receiverShapeMappingExists){
                        $('#recipientField'+self.encodeId).removeAttr('disabled');
                        $('#recipientRoleField'+self.encodeId).removeAttr('disabled');  
                    }else{
                        if(self.receiverShape.$type !== "vdml:Role"){
                            $('#recipientRoleField'+self.encodeId).removeAttr('disabled');
                        }
                    }
            }
            if(self.selectedBMVP && self.vpProvider()){
                $('#showMappedInfoBMVP'+self.encodeId).show();
                self.loadProviderRoles(self.vpProvider(), self.selectedReceiverRole());
                if(self.vpReceiver() && self.selectedBMVP){
                    self.loadReceiverRoles(self.vpReceiver(), self.selectedProviderRole());
                }
                if(self.mappedDiagramBO){
                    var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.mappedDiagramBO.mid });
                    var providerRole = vp.get('provider');
                    var recipientRole = vp.get('recipient');
                    if(vp.get('isValueFormula')){
                       self.myProposition(true);
                    }
                    $('#myProposition'+self.encodeId).attr('disabled','disabled');
                    $("#selectVP" + self.encodeId).attr('disabled','disabled');
                    if(providerRole){
                        var providerAssignment = providerRole.get('roleAssignment').models[0];
                        var providerParticipant = providerAssignment.get('participant');
                        var providerToDisplay = $.grep(self.vpProviders(),function(participantProvider1){return participantProvider1.id === providerParticipant.id;});
                        if(providerToDisplay && providerToDisplay.length){
                            self.vpProvider(providerToDisplay[0]);
                        }
                        var roleToDisplay = $.grep(self.vpProviderRoles(),function(role1){return role1.id === providerRole.id;});
                        if(roleToDisplay && roleToDisplay.length){
                            self.selectedProviderRole(roleToDisplay[0]);
                        }
                        $('#providerField'+self.encodeId).attr('disabled','disabled');
                        $('#providerRoleField'+self.encodeId).attr('disabled','disabled');
                    }
                    if(recipientRole){
                        var recipientAssignment = recipientRole.get('roleAssignment').models[0];
                        var recipientParticipant = recipientAssignment.get('participant');
                        var recipientToDisplay = $.grep(self.vpReceivers(),function(participantReceiver1){return participantReceiver1.id === recipientParticipant.id;});
                        if(recipientToDisplay && recipientToDisplay.length){
                            self.vpReceiver(recipientToDisplay[0]);
                        }
                        var recipientRoleToDisplay = $.grep(self.vpReceiverRoles(),function(role1){return role1.id === recipientRole.id;});
                        if(recipientRoleToDisplay && recipientRoleToDisplay.length){
                            self.selectedReceiverRole(recipientRoleToDisplay[0]);
                        }
                        $('#recipientField'+self.encodeId).attr('disabled','disabled');
                        $('#recipientRoleField'+self.encodeId).attr('disabled','disabled');
                    }
                }else{
                    if($('#providerField'+self.encodeId).is('[disabled=disabled]')){
                        var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMVP.id});
                        if(bm.get('business').id !== self.vpProvider().id){
                            $('#myProposition'+self.encodeId).prop('checked',false);
                            self.myProposition(false);
                            $('#myProposition'+self.encodeId).attr('disabled','disabled');
                        }else if(!self.receiverShape){
                            $('#myProposition'+self.encodeId).removeAttr('disabled');
                        }
                    }
                    if(self.providerShapeMappingExists && self.providerShape.$type === "vdml:Role"){
                        self.fillProviderBasedOnProviderShape();
                    }
                    if(self.receiverShapeMappingExists && self.receiverShape.$type === "vdml:Role"){
                        self.fillReceiverBasedOnReceiverShape();
                    }
                    if(self.selectedVP){
                        self.refreshProviderAndRecipientBasedOnSelectedVP(self.selectedVP);
                    }
                }
            }else if(!self.selectedBMVP){
                $('#showMappedInfoBMVP'+self.encodeId).hide();
            }
        };
        this.refreshRoleTypeahead = function(){
            self.rolesColl.reset();
            if(self.roleTypeahead){
                self.roleTypeahead.rerender([]);    
            }
            $('#selectRole'+self.encodeId).val('');
            self.selectedRole = null;
            self.onChangeSelectedRole();
            var pn, pnRoles;
            if(self.selectedPN && self.selectedRelType() === "Business"){
                var bm = self.selectedBMOther? Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMOther.id}) : null;
                pn = Backbone.Relational.store.getObjectByName("vdml.BusinessNetwork").find({ id: self.selectedPN.id });
                pnRoles = pn.get('collaborationRole').models;
                if(self.selectedParticipant){
                    for(var i=0; i<pnRoles.length; i++){
                        var assignment = pnRoles[i].get('roleAssignment').at(0);
                        if(assignment && assignment.get('participant').id === self.selectedParticipant.id){
                            self.rolesColl.push({id:pnRoles[i].id, name:pnRoles[i].get('name')});
                        }
                    }
                }
            }else if(self.selectedPN && self.selectedParticipant && self.selectedRelType() !== "Business"){
                pn = Backbone.Relational.store.getObjectByName("vdml.BusinessNetwork").find({ id: self.selectedPN.id });
                pnRoles = pn.get('collaborationRole').models;
                for(var i=0; i<pnRoles.length; i++){
                    var assignment = pnRoles[i].get('roleAssignment').at(0);
                    if(assignment && assignment.get('participant').id === self.selectedParticipant.id){
                        self.rolesColl.push({id:pnRoles[i].id, name:pnRoles[i].get('name')});
                    }
                }               
            }
            if(self.updateVPBasedOnTheRecipientMapping){
                var rejected = _.filter(self.rolesColl.models,function(role){return role.id === self.updateVPBasedOnTheRecipientMapping.get('provider').id;});
                self.rolesColl.remove(rejected[0]);
            }
            if(diagramBO.get('name')){
                    if(self.selectedShapeType && self.selectedShapeType.name === "Role"){
                        $('#selectRole'+self.encodeId).val(diagramBO.get('name'));  
                    }
                    var roleFound = _.filter(self.rolesColl.models,function(obj){return obj.get('name').toLowerCase() === diagramBO.get('name').toLowerCase()});
                    if(roleFound && roleFound.length){
                        self.selectedRole = roleFound[0];
                        self.onChangeSelectedRole();
                    }
            }
        }
        this.onChangeSelectedPN = function(){
            self.refreshRoleTypeahead();
            if(self.selectedPN){
                $('#showMappedInfoPN'+self.encodeId).show();
            }else{
                $('#showMappedInfoPN'+self.encodeId).hide();
            }
        };
        this.onChangeSelectedParticipant = function(){
            self.refreshRoleTypeahead();
            if(self.selectedParticipant){
                $('#showMappedInfoParticipant'+self.encodeId).show();
                if(self.selectedShapeType.name === "Role"){
                    var part = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id:self.selectedParticipant.id});
                    var type = part.get('type').replace(/_/g, '.');
                    var partType = self.partTypesList()[_.findLastIndex(self.partTypesList(), {type: type})];
                    self.selectedPartType(partType);
                    self.enablePartType(false);
                }
            }else{
                $('#showMappedInfoParticipant'+self.encodeId).hide();
            }
            self.enableCompleteForOtherShapes();
        };
        this.onChangeSelectedRole = function(){
            if(self.selectedRole){
                $('#showMappedInfoRole'+self.encodeId).show();
                if(self.selectedBMOther){
                    var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMOther.id});
                    var role = Backbone.Relational.store.getObjectByName('vdml.Party').find({ id: self.selectedRole.id });
                    var assignment = role.get('roleAssignment').models[0];
                    var roleAssignedToOtherType, participantFoundInBM,relationType, participant = assignment.get('participant');
                    var partIsAPartner = bm.get('bmNetworkPartner').findWhere({'networkPartner':participant});
                    var partIsACustomer = bm.get('bmCustomer').findWhere({'customer':participant});
                    //if(self.selectedParticipant.id === participant.id){
                        if(self.selectedRelType()=== "Partner"){
                            if(partIsAPartner && partIsAPartner.get('networkPartnerRole').findWhere({'id':role.id})){
                                roleAssignedToOtherType = false;
                            }else if(partIsACustomer){
                                roleAssignedToOtherType = true;
                                relationType = "Customer";
                            }
                        }else if(self.selectedRelType()=== "Customer"){
                            if(partIsACustomer && partIsACustomer.get('customerRole').findWhere({'id':role.id})){
                                roleAssignedToOtherType = false;
                            }else if(partIsAPartner){
                                roleAssignedToOtherType = true;
                                relationType = "Partner";
                            }
                        }
                        if(roleAssignedToOtherType){
                            bootbox.confirm("Role "+role.get("name")+" is assigned to "+participant.get('name')+"("+relationType+"). Do you want you to continue?",function(result){
                                if(!result){
                                    $('#selectRole'+self.encodeId).val('');
                                    self.selectedRole = null;
                                    self.onChangeSelectedRole();
                                    self.enableCompleteForOtherShapes();
                                }
                            });
                        }
                    //}                    
                }
            }else{
                $('#showMappedInfoRole'+self.encodeId).hide();
            }
        };
        this.selectedRelType.subscribe(function(rel){
            if(!self.selectedPackageOther()){
                return;
            }
            if(!self.mappedDiagramBO){
                $('#selectParticipant'+self.encodeId).val('');
                self.selectedParticipant = null;
                if(self.packagesListOther().length > 0){
                    self.loadParticipants(self.packagesListOther()[0].id);  
                }
                $('#selectParticipant' + self.encodeId).removeAttr('disabled');
                if(rel === "Business"){
                    if(self.selectedBMOther){
                        var business = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMOther.id}).get('business');
                        if (!business) {
                            $('#selectParticipant' + self.encodeId).val($('#selectBusinessOther' + self.encodeId).val().trim());
                            self.selectedParticipant = self.selectedBusinessOther;
                            $('#selectParticipant' + self.encodeId).attr('disabled', 'disabled');
                        } else {
                            var partToDisplay = self.participantsColl.findWhere({ 'id': business.id });
                            if(partToDisplay){
                                $('#selectParticipant' + self.encodeId).val(partToDisplay.get('name'));
                                self.selectedParticipant = partToDisplay;
                            }
                        }
                    }else{
                        $('#selectParticipant' + self.encodeId).val($('#selectBusinessOther'+self.encodeId).val().trim());
                        self.selectedParticipant = self.selectedBusinessOther;
                        $('#selectParticipant' + self.encodeId).attr('disabled','disabled');
                    }
                    if(self.showPartType()){
                        var partType = self.partTypesList()[_.findLastIndex(self.partTypesList(), {name: "Enterprise"})];
                        self.selectedPartType(partType);
                        self.enablePartType(false);
                    }
                }else if(diagramBO.get('name')){
                    if(diagramBO.$type !== 'vdml:Role'){
                        $('#selectParticipant' + self.encodeId).val(diagramBO.get('name'));
                    }
                    var partFound = _.filter(self.participantsColl.models,function(obj){return obj.get('name').toLowerCase() === diagramBO.get('name').toLowerCase()});
                    if(partFound && partFound.length){
                        self.selectedParticipant = partFound[0];
                    }
                }
                self.onChangeSelectedParticipant();
                self.refreshRoleTypeahead();
            }else if(!self.selectedBMOther){
                if(rel === "Business"){
                    $('#selectBusinessOther'+self.encodeId).val(self.selectedParticipant.get('name'));
                    self.selectedBusinessOther = self.selectedParticipant;
                    $('#showMappedInfoBusinessOther'+self.encodeId).show();
                    $('#selectBusinessOther'+self.encodeId).attr('disabled','disabled');
                    if(self.showPartType()){
                        var partType = self.partTypesList()[_.findLastIndex(self.partTypesList(), {name: "Enterprise"})];
                        self.selectedPartType(partType);
                        self.enablePartType(false);
                    }
                }else{//comes here in "Add Mapping" and when new bm is added
                //needed when the relation type is changed from Business to Other, the business field needs to be emptied
                    $('#selectBusinessOther'+self.encodeId).val('');
                    self.selectedBMOther = null;
                    $('#showMappedInfoBusinessOther'+self.encodeId).hide();
                    $('#selectBusinessOther'+self.encodeId).removeAttr('disabled');
                }
            }
        });
        this.myProposition.subscribe(function (newVal) {
            if (newVal) {
                $('#recipientLegend' + self.encodeId).hide();
                $('#recipients' + self.encodeId).hide();
                $('#recipientRoles' + self.encodeId).hide();
                self.vpReceivers.removeAll();
                if(!self.selectedBMVP){
                    return;
                }
                var previouslySelectedRole = self.selectedProviderRole();
                self.loadProviders(self.selectedVDMPackage.id,'vdml.OrgUnit',true);
                var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMVP.id});                
                if(previouslySelectedRole && previouslySelectedRole.id){//logic to set previously set role by user
                    var businessRole = false;
                    if(bm && bm.get('business')) {
                        var bmRoles = bm.get('bmRole');
                        var role = bmRoles.findWhere({'id':previouslySelectedRole.id})
                        var roleAssignments = role.get('roleAssignment');
                        for (var i = 0; i < roleAssignments.length; i++) {
                            var assignment = roleAssignments.at(i);
                            if (assignment.get('participant').id === bm.get('business').get('id')) {
                                businessRole = true;
                            }    
                        }
                    }
                    if(businessRole) {
                        for(var i=0;i<self.vpProviderRoles().length;i++){
                            if(previouslySelectedRole.id === self.vpProviderRoles()[i].id){
                                self.selectedProviderRole(self.vpProviderRoles()[i]);
                                break;
                            }
                        }
                    }
                    previouslySelectedRole = null;
                }
                //self.loadVPs();
            } else{
                $('#providerLegend'+self.encodeId).show();
                $('#providers'+self.encodeId).show();
                $('#providerRoles'+self.encodeId).show();
                $('#recipientLegend'+self.encodeId).show();
                $('#recipients'+self.encodeId).show();
                $('#recipientRoles'+self.encodeId).show();
                if(!self.mappedDiagramBO && self.BMsColl.length > 0){
                    if(self.providerShape){
                        self.loadProviders(self.packagesListVP()[0].id, self.mappingObject[self.providerShape.$type].type);
                    }else{
                        self.loadProviders(self.packagesListVP()[0].id);
                    }
                    if(self.receiverShape){
                        self.loadReceivers(self.packagesListVP()[0].id, self.mappingObject[self.receiverShape.$type].type);
                    }else{
                        self.loadReceivers(self.packagesListVP()[0].id);
                    }
                    if(!self.providerShapeMappingExists){// if its not mapped, you can remove disabled attr
                        $('#providerField'+self.encodeId).removeAttr('disabled');
                        $('#providerRoleField'+self.encodeId).removeAttr('disabled');
                    }else{
                        if(self.providerShape.$type !== "vdml:Role"){//if it is mapped remove disabled attr for role
                            $('#providerRoleField'+self.encodeId).removeAttr('disabled');
                        }
                    }
                    if(!self.receiverShapeMappingExists){
                        $('#recipientField'+self.encodeId).removeAttr('disabled');
                        $('#recipientRoleField'+self.encodeId).removeAttr('disabled');  
                    }else{
                        if(self.receiverShape.$type !== "vdml:Role"){
                            $('#recipientRoleField'+self.encodeId).removeAttr('disabled');
                        }
                    }
                }                
                //self.loadVPs();
                if(self.selectedVP){
                    self.refreshProviderAndRecipientBasedOnSelectedVP(self.selectedVP); 
                }
            }   
            self.loadVPs();         
            //self.suggestedVPDisplay(newVal);
        });
        this.refreshProviderAndRecipientBasedOnSelectedVP = function(vpModel){
            self.selectedProviderRole(null);
            self.vpReceiver(null);
            self.selectedReceiverRole(null);
            var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: vpModel.get('id') });
            if(self.providerShape){
                self.loadProviders(self.packagesListVP()[0].id, self.mappingObject[self.providerShape.$type].type);
            }else{
                self.loadProviders(self.packagesListVP()[0].id);
            }
            if(self.receiverShape){
                self.loadReceivers(self.packagesListVP()[0].id, self.mappingObject[self.receiverShape.$type].type);
            }else{
                self.loadReceivers(self.packagesListVP()[0].id);
            }
            var providerRole = vp.get('provider');
            var assignment = providerRole.get('roleAssignment').at(0);
            var participant = assignment? assignment.get('participant') : null;
            var provider = $.grep(self.vpProviders(),function(participantProvider1){return participantProvider1.id === participant.id;});
            if(provider && provider.length){
                self.vpProvider(provider[0]);
            }
            var roleToDisplay = $.grep(self.vpProviderRoles(),function(role1){return role1.id === providerRole.id;});
            if(roleToDisplay && roleToDisplay.length){
                self.selectedProviderRole(roleToDisplay[0]);
            }
            var recipientRole = vp.get('recipient');
            if(recipientRole){
                assignment = recipientRole.get('roleAssignment').at(0);
                participant = assignment? assignment.get('participant') : null;
                var recipient = $.grep(self.vpReceivers(),function(participantReceiver1){return participantReceiver1.id === participant.id;});
                if(recipient && recipient.length){
                    self.vpReceiver(recipient[0]);
                }
                var roleToDisplay = $.grep(self.vpReceiverRoles(),function(role1){return role1.id === recipientRole.id;});
                if(roleToDisplay && roleToDisplay.length){
                    self.selectedReceiverRole(roleToDisplay[0]);
                }
            }
            $('#providerField'+self.encodeId).attr('disabled','disabled');
            $('#providerRoleField'+self.encodeId).attr('disabled','disabled');
            $('#recipientField'+self.encodeId).attr('disabled','disabled');
            $('#recipientRoleField'+self.encodeId).attr('disabled','disabled');
            self.enableCompleteForVP();
        }
        this.onChangeSelectedVP = function(){
            if(self.selectedVP){
                self.refreshProviderAndRecipientBasedOnSelectedVP(self.selectedVP);
                $('#showMappedInfoVP'+self.encodeId).show();
            }else{
                $('#showMappedInfoVP'+self.encodeId).hide();
                if(!self.mappedDiagramBO){
                    if(!self.providerShapeMappingExists){// if its not mapped, you can remove disabled attr
                        $('#providerField'+self.encodeId).removeAttr('disabled');
                        $('#providerRoleField'+self.encodeId).removeAttr('disabled');
                    }else{
                        if(self.providerShape.$type !== "vdml:Role"){//if it is mapped remove disabled attr for role
                            $('#providerRoleField'+self.encodeId).removeAttr('disabled');
                        }
                    }
                    if(!self.receiverShapeMappingExists){
                        $('#recipientField'+self.encodeId).removeAttr('disabled');
                        $('#recipientRoleField'+self.encodeId).removeAttr('disabled');  
                    }else{
                        if(self.receiverShape.$type !== "vdml:Role"){
                            $('#recipientRoleField'+self.encodeId).removeAttr('disabled');
                        }
                    }
                }
                $('#providerLegend'+self.encodeId).show();
                $('#providers'+self.encodeId).show();
                $('#providerRoles'+self.encodeId).show();
                if(!self.myProposition()){
                    $('#recipientLegend'+self.encodeId).show();
                    $('#recipients'+self.encodeId).show();
                    $('#recipientRoles'+self.encodeId).show();  
                }
            }
        };
        this.vpProvider.subscribe(function (provider) {
            if(self.selectedBMVP){
                self.loadProviderRoles(provider, self.selectedReceiverRole());
            }
        });
        this.vpReceiver.subscribe(function (receiver) {
            if(self.selectedBMVP){
                self.loadReceiverRoles(receiver, self.selectedProviderRole());
            }
            if (!self.selectedReceiverRole() && self.vpReceiver()) {
                $('.btn-next').attr('disabled', 'disabled');
            } else if (!self.vpReceiver() && self.selectedProviderRole() && $("#selectVP" + self.encodeId).val().trim()!=="") {
                $('.btn-next').removeAttr('disabled');
            } else if(self.selectedVP && self.selectedPackageVP() && self.selectedBMVP){
                $('.btn-next').removeAttr('disabled');
            }
        });
        this.selectedProviderRole.subscribe(function (role) {
            if (self.selectedProviderRole() && $("#selectVP" + self.encodeId).val().trim()!=="") {
                if (!self.selectedReceiverRole() && self.vpReceiver()) {
                    $('.btn-next').attr('disabled', 'disabled');
                } else if((self.selectedReceiverRole() && self.vpReceiver() && $("#selectVP" + self.encodeId).val().trim()!=="") || (!self.selectedReceiverRole() && !self.vpReceiver() && $("#selectVP" + self.encodeId).val().trim()!=="")) {
                    $('.btn-next').removeAttr('disabled');
                }else if(self.selectedVP && self.selectedPackageVP() && self.selectedBMVP){
                    $('.btn-next').removeAttr('disabled');
                }
                if (!self.selectedReceiverRole() || (self.selectedReceiverRole() && role && role.id === self.selectedReceiverRole().id)) {
                    self.loadReceiverRoles(self.vpReceiver(), role);
                }
            } else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });

        this.selectedReceiverRole.subscribe(function (role) {
            if (self.selectedProviderRole() && $("#selectVP" + self.encodeId).val().trim()!=="") {
                if (!self.selectedReceiverRole() && self.vpReceiver()) {
                    $('.btn-next').attr('disabled', 'disabled');
                } else if((self.selectedReceiverRole() && self.vpReceiver() && $("#selectVP" + self.encodeId).val().trim()!=="") || (!self.selectedReceiverRole() && !self.vpReceiver() && $("#selectVP" + self.encodeId).val().trim()!=="")) {
                    $('.btn-next').removeAttr('disabled');
                } else if(self.selectedVP && self.selectedPackageVP() && self.selectedBMVP){
                    $('.btn-next').removeAttr('disabled');
                }
                if (!self.selectedProviderRole() || (self.selectedProviderRole() && role && role.id === self.selectedProviderRole().id)) {
                    self.loadProviderRoles(self.vpProvider(), role);
                }
            } else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });
        this.cleanUp = function () {
            self.parentView = null;
        }
        this.onSelectedShapeTypeChange = function(shape){
            if (!shape) {
                return;
            }
            if (shape.name !== this.oldShape) {
                while ($('.steps li').length > 1) {
                    $('.steps li').last().remove();
                }
                var stepsNode = $('.steps');
                var stepStr = "<li class=\"wizardLibLi\" data-step=\"stepNo\"><span class=\"badge\">1</span><span>header</span><span class=\"chevron\"></span></li>";
                var reStep = new RegExp('stepNo', 'g');
                var reHeader = new RegExp('header', 'g');
                stepStr = stepStr.replace(reHeader, "Details");
                if (shape.name === 'Business Model') {
                    stepStr = stepStr.replace(reStep, 1);
                    stepsNode.append($(stepStr));
                } else
                    if (shape.name === 'Market Segment') {
                        stepStr = stepStr.replace(reStep, 2);
                        stepsNode.append($(stepStr));
                    } else
                        if (shape.name === 'Enterprise') {
                            stepStr = stepStr.replace(reStep, 2);
                            stepsNode.append($(stepStr));
                        } else
                            if (shape.name === 'Individual') {
                                stepStr = stepStr.replace(reStep, 2);
                                stepsNode.append($(stepStr));
                            } else
                                if (shape.name === 'Role') {
                                    stepStr = stepStr.replace(reStep, 2);
                                    stepsNode.append($(stepStr));
                                    if(!self.selectedParticipant){
                                        self.showPartType(true);
                                    }
                                } else
                                    if (shape.name === 'Value Proposition') {
                                        stepStr = stepStr.replace(reStep, 3);
                                        stepsNode.append($(stepStr));
                                    }
            }
            this.oldShape = shape.name;
        };
        
        function fillPackagesBM(){
            self.packagesListBM().length = 0;
            var packages = _.filter(self.currentAlternative.get('phaseDesignPart').models, function(packagesRef){  if(packagesRef.get('beepType') === 'vdml_ValueDeliveryModel' && packagesRef.get('beepReference').indexOf('Common') == -1){return packagesRef} });
            var tempPackageArray = [];
            for (var i = 0; i < packages.length; i++) {
                var obj = packages[i];
                var tempObj = { id: obj.get('beepReference'), name: obj.get('name'), version: obj.get('version'),type:obj.get('beepType') };
                var include = true;
                if(self.providerVPShape){
                    include = self.filterPackagesForBMFlowsToVP(self.providerVPShape.mid, tempObj);
                }
                if(self.receiverVPShape){
                    include = self.filterPackagesForBMFlowsToVP(self.receiverVPShape.mid, tempObj); 
                }
                if(self.mappedDiagramBO){
                    if(tempObj.id !== self.mappedDiagramBO.mpid){
                        include = false;
                    }
                }
                if(include){
                    tempPackageArray.push({ id: obj.get('beepReference'), name: obj.get('name'), version: obj.get('version'),type:obj.get('beepType') });
                }
            }
            if(self.mappedDiagramBO){
                self.packagesListBM(tempPackageArray);
                $('#packageFieldBM' + self.encodeId).attr('disabled','disabled');
            }else{
                self.packagesListBM(tempPackageArray);
                if(!self.providerVPShape && !self.receiverVPShape){
                    self.packagesListBM.push({name:"New"});
                }
            }
       }
       function fillPackagesOther(){
            self.packagesListOther().length = 0;
            var packages = _.filter(self.currentAlternative.get('phaseDesignPart').models, function(packagesRef){  if(packagesRef.get('beepType') === 'vdml_ValueDeliveryModel' && packagesRef.get('beepReference').indexOf('Common') == -1){return packagesRef} });
            var tempPackageArray = [];
            for (var i = 0; i < packages.length; i++) {
                var obj = packages[i];
                tempPackageArray.push({ id: obj.get('beepReference'), name: obj.get('name'), version: obj.get('version'),type:obj.get('beepType') });
            }
            self.packagesListOther(tempPackageArray);
            if(self.packagesListOther().length > 0){ 
                self.loadParticipants(self.packagesListOther()[0].id);
                var coll = [];
                if(self.fetchedObjects && self.fetchedObjects[self.packagesListOther()[0].id] && self.fetchedObjects[self.packagesListOther()[0].id]['vdml.Collaboration']) {
                coll = self.fetchedObjects[self.packagesListOther()[0].id]['vdml.Collaboration'];
                self.PNsColl(coll);
              }else {
                  DataManager.getDataManager().getAllDocumentsCollectionOfType(self.packagesListOther()[0].id,'vdml.Collaboration',function(result){
                    var repId = DataManager.getDataManager().get('viewAlternative');
                    for (var i = 0; i < result.length; i++) {
                        if(repId !== window.utils.getPrefix(result.at(i).get('id'))){
                            var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                            if(window.utils.getElementModel(newId,['vdml.BusinessNetwork'])){
                                continue;
                            }
                        }
                          var pn = Backbone.Relational.store.getObjectByName('vdml.BusinessNetwork').find({ id: result.at(i).get('id') });

                          if(pn && self.currentAlternative.get('phaseDesignPart').findWhere({'beepReference': pn.getNestedParent().id})) {
                            coll.push({id:pn.id, name:pn.get('name')});
                              self.PNsColl.push({id:pn.id, name:pn.get('name')});
                          }
                      }
                      if(!self.fetchedObjects[self.packagesListOther()[0].id]){
                          self.fetchedObjects[self.packagesListOther()[0].id] = {};
                        }
                        self.fetchedObjects[self.packagesListOther()[0].id]['vdml.Collaboration'] = coll;
                  },false,null,false,null,null,true);
                }
            }
            self.packagesListOther.push({name:"New"});
       }
       function fillPackagesVP(callback){
            self.packagesListVP().length = 0;
            var packages = _.filter(self.currentAlternative.get('phaseDesignPart').models, function(packagesRef){  if(packagesRef.get('beepType') === 'vdml_ValueDeliveryModel' && packagesRef.get('beepReference').indexOf('Common') == -1){return packagesRef} });
            var tempPackageArray = [];
            for (var i = 0; i < packages.length; i++) {
                var obj = packages[i];
                tempPackageArray.push({ id: obj.get('beepReference'), name: obj.get('name'), version: obj.get('version'),type:obj.get('beepType') });
            }
            self.packagesListVP(tempPackageArray);
            self.packagesListVP.push({name:"New"});
            if(self.packagesListVP().length){
                self.loadVPs(callback);
            }
       }
       function fillVDMPackages(pack,callback) {
            /*var repId = DataManager.getDataManager().getRepositoryId(pack.id);
            var vdmStore = DataManager.getDataManager().getVDMStore(repId);
            DataManager.getDataManager().fetchDocumentFromPackage(pack.id,"appbo/vdml/ValueDeliveryModel",pack.version,pack.id,"appbo/vdml/ValueDeliveryModel",vdmStore,{
                success:function(model){
                    callback(model);
                },
                error:function(error){
                    console.log(error);
                }
            });*/
            var vPackage = window.utils.getElementModel(pack.id,['vdml.ValueDeliveryModel']);
            if(!vPackage){
                console.log("NO pack" + pack);
            }
            callback(vPackage);
        }
        this.loadProviders = function (packId,limitToType, limitToBusiness) {
            var selProvider = self.vpProvider();
            self.vpProviders.removeAll();
            //var participants;
            if(!packId) {
                return;
            }
            function filterParticipants(type1, type2, callback){
              var participants = [];
              if(self.fetchedObjects && self.fetchedObjects[packId] && self.fetchedObjects[packId][type1]) {
                participants = self.fetchedObjects[packId][type1];
                callback(participants);
              }else {
                  DataManager.getDataManager().getAllDocumentsCollectionOfType(packId, type1, function (result) {
                    var repId = DataManager.getDataManager().get('viewAlternative');
                        for (var i = 0; i < result.length; i++) {
                            if(repId !== window.utils.getPrefix(result.at(i).get('id'))){
                                var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                                if(window.utils.getElementModel(newId,[type2])){
                                    continue;
                                }
                            }
                             var relOrgUnit = Backbone.Relational.store.getObjectByName(type2).find({ id: result.at(i).get('id') });
                             if(relOrgUnit) {
                                  participants.push(relOrgUnit);
                             }
                         }
                         if(!self.fetchedObjects[packId]){
                            self.fetchedObjects[packId] = {};
                         }
                         self.fetchedObjects[packId][type1] = participants;
                         callback(participants);
                  }, false,null,false,null,null,true);
                }
            }
            function setParticipants(participants){
              if(participants && participants.length > 0){
                  //var tempVpProvidersArray = [];
                   for(var i=0; i<participants.length; i++){
                       self.vpProviders.push({ name:participants[i].get('name'), id:participants[i].id});
                   }
                   //self.vpProviders(tempVpProvidersArray);
              }
              if (selProvider) {
                  var item = $.grep(self.vpProviders(), function (e) { return e.name === selProvider.name; });
                  self.vpProvider(item[0]);
              }
            }
            if(limitToType === 'vdml.Actor'){
                filterParticipants('vdml_Actor','vdml.Actor',setParticipants);
                //participants = VDMPack.get('actor').where({type:'vdml_Actor'});
            }else if(limitToType === 'vdml.Community'){
                filterParticipants('vdml_Community','vdml.Community',setParticipants);
                //participants = VDMPack.get('collaboration').where({type:'vdml_Community'});
            }else if(limitToType === 'vdml.OrgUnit'){
                  filterParticipants('vdml_OrgUnit','vdml.OrgUnit',function(participants){
                    if(limitToBusiness && self.selectedBMVP){
                        var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMVP.id});
                        participants = $.grep(participants, function(part){return part.id === bm.get('business').id;});
                    }
                    setParticipants(participants);
                  });
                //participants = VDMPack.get('collaboration').where({type:'vdml_OrgUnit'});
                
            }else{
                filterParticipants('vdml_Community','vdml.Community',setParticipants);
                filterParticipants('vdml_OrgUnit','vdml.OrgUnit',setParticipants);
                filterParticipants('vdml_Actor','vdml.Actor',setParticipants);
            }
        };
        this.loadReceivers = function (packId,limitToType) {
            var selReceiver = self.vpReceiver(); 
            self.vpReceivers.removeAll();
            var participants;
            //var participants = [];
            function filterParticipants(type1, type2, callback){
              if(self.fetchedObjects && self.fetchedObjects[packId] && self.fetchedObjects[packId][type1]) {
                participants = self.fetchedObjects[packId][type1];
                callback(participants);
              }else {
                DataManager.getDataManager().getAllDocumentsCollectionOfType(packId, type1, function (result) {
                    var repId = DataManager.getDataManager().get('viewAlternative');
                        for (var i = 0; i < result.length; i++) {
                            if(repId !== window.utils.getPrefix(result.at(i).get('id'))){
                                var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                                if(window.utils.getElementModel(newId,[type2])){
                                    continue;
                                }
                            }
                           var relOrgUnit = Backbone.Relational.store.getObjectByName(type2).find({ id: result.at(i).get('id') });
                           if(relOrgUnit) {
                                participants.push(relOrgUnit);
                           }
                       }
                       if(!self.fetchedObjects[packId]){
                          self.fetchedObjects[packId] = {};
                       }
                       self.fetchedObjects[packId][type1] = participants;
                       callback(participants);
                  }, false,null,false,null,null,true);
              }
                return participants;
            }
            function setParticipants(participants){
              if(participants && participants.length > 0){
                  //var tempVpProvidersArray = [];
                   for(var i=0; i<participants.length; i++){
                       self.vpReceivers.push({ name:participants[i].get('name'), id:participants[i].id});
                   }
                   //self.vpProviders(tempVpProvidersArray);
              }
              if (selReceiver) {
                  var item = $.grep(self.vpReceiver(), function (e) { return e.name === selReceiver.name; });
                  self.vpReceiver(item[0]);
              }
            }
            if(limitToType === 'vdml.Actor'){
                filterParticipants('vdml_Actor','vdml.Actor',setParticipants);
                //participants = VDMPack.get('actor').where({type:'vdml_Actor'});
            }else if(limitToType === 'vdml.Community'){
                filterParticipants('vdml_Community','vdml.Community',setParticipants);
                //participants = VDMPack.get('collaboration').where({type:'vdml_Community'});
            }else if(limitToType === 'vdml.OrgUnit'){
                participants = filterParticipants('vdml_OrgUnit','vdml.OrgUnit',setParticipants);
                //participants = VDMPack.get('collaboration').where({type:'vdml_OrgUnit'});
            }else {
                filterParticipants('vdml_Community','vdml.Community',setParticipants);
                filterParticipants('vdml_OrgUnit','vdml.OrgUnit',setParticipants);
                filterParticipants('vdml_Actor','vdml.Actor',setParticipants);
            }
        };
        this.filterPackagesForBMFlowsToVP = function(vpId, pack){
            var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({id:vpId}), providerRole = vp.get('provider'), recipientRole = vp.get('recipient');
            var repId = DataManager.getDataManager().getRepositoryId(pack.id);
            var vdmStore = DataManager.getDataManager().getVDMStore(repId);
            var include = false;
            DataManager.getDataManager().fetchDocumentFromPackage(pack.id,"appbo/vdml/ValueDeliveryModel",pack.version,pack.id,"appbo/vdml/ValueDeliveryModel",vdmStore,{
                success:function(model){//filtering packages based the presence of the vp roles
                  var bms = model.get('businessModel');
                  for(var j=0;j<bms.length;j++){
                    if(bms.at(j).get('bmRole').findWhere({'id':providerRole.id})){
                      include = true;
                      if(recipientRole){
                              if(bms.at(j).get('bmRole').findWhere({id:recipientRole.id})){
                                  include = true;
                                  break;
                              }else {
                                include = false;
                              }
                          }else {
                            break;
                          }
                    }
                  }
                },
                error:function(error){
                    console.log(error);
                }
            });
            return include;
        };
        this.checkAndManageIfInflowIsAMappedVP = function(){
            var incomingShapes = diagramBO.incoming;
            if(incomingShapes && incomingShapes.length){
                var providerShape = incomingShapes[0].sourceRef.businessObject;
                if(!providerShape){
                    providerShape = incomingShapes[0].sourceRef;
                }
                if(providerShape.$type === "vdml:ValueProposition" && providerShape.mid){
                    self.providerVPShape = providerShape;
                    var bm,vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({id:self.providerVPShape.mid}), providerRole = vp.get('provider'), recipientRole = vp.get('recipient');
                    if(vp){
                        if(diagramBO.$type === "vdml:BusinessModel"){
                            //return;
                        }else{
                            if(recipientRole){
                                var providerVPPack = $.grep(self.packagesListOther(), function (e) { return e.id === self.providerVPShape.mpid; });
                                if(providerVPPack && providerVPPack.length){//set the package to package of the Inflow vp
                                    $('#packageFieldOther'+self.encodeId).attr('disabled','disabled');
                                    self.selectedPackageOther(providerVPPack[0]);
                                }
                                var PNToDisplay, partToDisplay,roleToDisplay;
                                for(var i=0;i<self.BMsColl.length;i++){
                                    bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.BMsColl.at(i).id});
                                    if(bm.get('bmRole').findWhere({id:recipientRole.id})){
                                        var bmToDisplay = $.grep(self.BMsColl.models,function(bm1){return bm1.id === bm.id;});
                                        if(bmToDisplay && bmToDisplay.length){
                                            $('#selectBMOther'+self.encodeId).val(''+bmToDisplay[0].get('name'));
                                            self.selectedBMOther = bmToDisplay[0];
                                            self.onChangeSelectedBMOther();
                                        }
                                        break;
                                    }
                                }
                                $('#selectBMOther'+self.encodeId).attr('disabled','disabled');
                                var assignment = recipientRole.get('roleAssignment').at(0);
                                var participant = assignment? assignment.get('participant') : null;
                                if(bm && bm.get('bmNetworkPartner').findWhere({'networkPartner':participant})){
                                    self.selectedRelType("Partner");
                                }else if(bm && bm.get('bmCustomer').findWhere({'customer':participant})){
                                    self.selectedRelType("Customer");
                                }else if(bm && bm.get('business').id === participant.id){
                                    self.selectedRelType("Business");
                                }
                                var pns = _.filter(self.selectedVDMPackage.get('collaboration').models, function(col){ return col.get('type') === 'vdml_BusinessNetwork'; });
                                for(var i=0; i<pns.length; i++){
                                    var roleExists = pns[i].get('collaborationRole').findWhere({'id':recipientRole.id});
                                    if(roleExists){
                                        PNToDisplay = self.PNsColl.findWhere({'id':pns[i].id});
                                        if(PNToDisplay){
                                            $('#selectPN'+self.encodeId).val(PNToDisplay.get('name'));
                                            self.selectedPN = PNToDisplay;
                                            self.onChangeSelectedPN();
                                            break;
                                        }
                                    }
                                }
                                $('#selectPN'+self.encodeId).attr('disabled','disabled');
                                partToDisplay = self.participantsColl.findWhere({id:participant.id});
                                if(partToDisplay){
                                    $('#selectParticipant'+self.encodeId).val(partToDisplay.get('name'));
                                    self.selectedParticipant = partToDisplay;
                                    self.onChangeSelectedParticipant();
                                }
                                $('#selectParticipant'+self.encodeId).attr('disabled','disabled');
                                $('#relTypes'+self.encodeId).attr('disabled','disabled');
                                if(diagramBO.$type === "vdml:Role"){
                                    roleToDisplay = self.rolesColl.findWhere({id:recipientRole.id});
                                    if(roleToDisplay){
                                        $('#selectRole'+self.encodeId).val(roleToDisplay.get('name'));
                                        self.selectedRole = roleToDisplay;
                                        self.onChangeSelectedRole();
                                    }
                                    $('#selectRole'+self.encodeId).attr('disabled','disabled');
                                }
                            }else{
                                self.updateVPBasedOnTheRecipientMapping = vp;
                                for(var i=0;i<self.BMsColl.length;i++){
                                    bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.BMsColl.at(i).id});
                                    if(bm.get('bmRole').findWhere({id:vp.get('provider').id})){
                                        var providerVPPack = $.grep(self.packagesListOther(), function (e) { return e.id === bm.getNestedParent().id; });
                                        if(providerVPPack && providerVPPack.length){
                                            $('#packageFieldOther'+self.encodeId).attr('disabled','disabled');
                                            self.selectedPackageOther(providerVPPack[0]);
                                        }
                                        var bmToDisplay = $.grep(self.BMsColl.models,function(bm1){return bm1.id === bm.id;});
                                        if(bmToDisplay && bmToDisplay.length){
                                            $('#selectBMOther'+self.encodeId).val(''+bmToDisplay[0].get('name'));
                                            self.selectedBMOther = bmToDisplay[0];
                                            self.onChangeSelectedBMOther();
                                        }
                                        break;
                                    }
                                }
                                $('#selectBMOther'+self.encodeId).attr('disabled','disabled');
                            }
                        }
                    }
                }
            }           
		}

        this.checkAndManageIfOutflowIsAMappedVP = function(){
            var outgoingShapes = diagramBO.outgoing;
            if(outgoingShapes && outgoingShapes.length){
                var receiverShape = outgoingShapes[0].targetRef.businessObject;
                if(!receiverShape){
                    receiverShape = outgoingShapes[0].targetRef;
                }
                if(receiverShape.$type === "vdml:ValueProposition" && receiverShape.mid){
                    self.receiverVPShape = receiverShape;
                    var bm,vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({id:self.receiverVPShape.mid}), providerRole = vp.get('provider'), recipientRole = vp.get('recipient');
                    if(diagramBO.$type === "vdml:BusinessModel"){
                        //return;
                    }else{
                        var receiverVPPack = $.grep(self.packagesListOther(), function (e) { return e.id === self.receiverVPShape.mpid; });
                        if(receiverVPPack && receiverVPPack.length){
                            $('#packageFieldOther'+self.encodeId).attr('disabled','disabled');
                            self.selectedPackageOther(receiverVPPack[0]);
                        }
                        var PNToDisplay, partToDisplay,roleToDisplay;
                        for(var i=0;i<self.BMsColl.length;i++){
                            bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.BMsColl.at(i).id});
                            if(bm.get('bmRole').findWhere({id:providerRole.id})){
                                var bmToDisplay = $.grep(self.BMsColl.models,function(bm1){return bm1.id === bm.id;});
                                if(bmToDisplay && bmToDisplay.length){
                                    $('#selectBMOther'+self.encodeId).val(''+bmToDisplay[0].get('name'));
                                    self.selectedBMOther = bmToDisplay[0];
                                    self.onChangeSelectedBMOther();
                                }
                                break;
                            }
                        }
                        $('#selectBMOther'+self.encodeId).attr('disabled','disabled');
                        var assignment = providerRole.get('roleAssignment').at(0);
                        var participant = assignment? assignment.get('participant') : null;
                        if(bm && bm.get('bmNetworkPartner').findWhere({'networkPartner':participant})){
                            self.selectedRelType("Partner");
                        }else if(bm && bm.get('bmCustomer').findWhere({'customer':participant})){
                            self.selectedRelType("Customer");
                        }else if(bm && bm.get('business').id === participant.id){
                            self.selectedRelType("Business");
                        }
                        var pns = _.filter(self.selectedVDMPackage.get('collaboration').models, function(col){ return col.get('type') === 'vdml_BusinessNetwork'; });
                        for(var i=0; i<pns.length; i++){
                            var roleExists = pns[i].get('collaborationRole').findWhere({'id':providerRole.id});
                            if(roleExists){
                                PNToDisplay = self.PNsColl.findWhere({'id':pns[i].id});
                                if(PNToDisplay){
                                    $('#selectPN'+self.encodeId).val(PNToDisplay.get('name'));
                                    self.selectedPN = PNToDisplay;
                                    self.onChangeSelectedPN();
                                    break;
                                }
                            }
                        }
                        $('#selectPN'+self.encodeId).attr('disabled','disabled');
                        partToDisplay = self.participantsColl.findWhere({id:participant.id});
                        self.onChangeSelectedParticipant();
                        if(partToDisplay){
                            $('#selectParticipant'+self.encodeId).val(partToDisplay.get('name'));
                            self.selectedParticipant = partToDisplay;
                            self.onChangeSelectedParticipant();
                            if(self.selectedShapeType && self.selectedShapeType.name === "Role"){
                                var part = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id:self.selectedParticipant.id});
                                var type = part.get('type').replace(/_/g, '.');
                                var partType = self.partTypesList()[_.findLastIndex(self.partTypesList(), {type: type})];
                                self.selectedPartType(partType);
                                self.enablePartType(false);
                            }
                        }
                        $('#selectParticipant'+self.encodeId).attr('disabled','disabled');
                        $('#relTypes'+self.encodeId).attr('disabled','disabled');
                        if(diagramBO.$type === "vdml:Role"){
                            roleToDisplay = self.rolesColl.findWhere({id:providerRole.id});
                            if(roleToDisplay){
                                $('#selectRole'+self.encodeId).val(roleToDisplay.get('name'));
                                self.selectedRole = roleToDisplay;
                                self.onChangeSelectedRole();
                            }
                            $('#selectRole'+self.encodeId).attr('disabled','disabled');
                        }
                    }
                }else{
                    return;
                }
            }           
        };
        this.filterBMs = function(id){
            return _.filter(self.BMsColl.models, function(obj) {
            var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:obj.id});
                return _.where(bm.get('bmRole').models, {id: id}).length > 0;
            });
        };
        this.findProviderAndReceiverShapes = function(){
            var providerShape, receiverShape, incomingShapes = diagramBO.incoming, outgoingShapes = diagramBO.outgoing;
            if(incomingShapes && incomingShapes.length){
                providerShape = incomingShapes[0].sourceRef.businessObject;
                if(!providerShape){
                    providerShape = incomingShapes[0].sourceRef;
                }
                self.providerShape = providerShape;
                if(providerShape.mid){
                    self.providerShapeMappingExists = true;
                }else{
                    self.providerShapeMappingExists = false;
                }
                //self.loadVPs();
            }else{
                self.providerShape = null;
            }
            if(outgoingShapes && outgoingShapes.length){
                receiverShape = outgoingShapes[0].targetRef.businessObject;
                if(!receiverShape){
                    receiverShape = outgoingShapes[0].targetRef;
                }
                self.receiverShape = receiverShape;
                if(self.receiverShape.$type !== "vdml:BusinessModel"){
                    $('#myProposition'+self.encodeId).attr('disabled','disabled');
                }
                if(receiverShape.mid){
                    self.receiverShapeMappingExists = true;
                }else{
                    self.receiverShapeMappingExists = false;
                }
                //self.loadVPs();
            }else{
                self.receiverShape = null;
            }
            if(self.providerShapeMappingExists && self.receiverShapeMappingExists){
                var providerPack, receiverPack, BMs;
                // filter for common package of provider and receiver
                //if(self.providerShape.mpid === self.receiverShape.mpid){
                    /*if(self.providerShape.$type === "vdml:Role" && self.receiverShape.$type === "vdml:Role" ){//filter bms based on roles
                        BMs = _.intersection(self.filterBMs(self.providerShape.mid), self.filterBMs(self.receiverShape.mid));
                    }else if(self.providerShape.$type === "vdml:Role"){
                        BMs = self.filterBMs(self.providerShape.mid);
                    }else if(self.receiverShape.$type === "vdml:Role"){
                        BMs = self.filterBMs(self.receiverShape.mid);
                    }else if(self.providerShape.$type === "vdml:BusinessModel" && self.receiverShape.$type === "vdml:BusinessModel"){
                        if(self.providerShape.mid === self.receiverShape.mid){
                            BMs = $.grep(self.BMsColl.models,function(bm){return bm.id === self.providerShape.mid;});
                        }else{
                            self.BMsColl.reset();
                        }
                        $('#selectBMVP'+self.encodeId).attr('disabled','disabled');
                    }else if(self.providerShape.$type === "vdml:BusinessModel"){
                        BMs = $.grep(self.BMsColl.models,function(bm){return bm.id === self.providerShape.mid;});
                    }else if(self.receiverShape.$type === "vdml:BusinessModel"){
                        BMs = $.grep(self.BMsColl.models,function(bm){return bm.id === self.receiverShape.mid;});
                    }
                    if(BMs && BMs.length){
                        self.BMsColl.reset();
                        self.BMsColl.add(BMs);
                        $('#selectBMVP'+self.encodeId).val(self.BMsColl.at(0).get('name'));
                        self.selectedBMVP = self.BMsColl.at(0);
                        self.onChangeSelectedBMVP();
                    }*/
                    self.fillProviderBasedOnProviderShape();
                    self.fillReceiverBasedOnReceiverShape();
                /*}else{
                    self.packagesListVP.removeAll();
                }*/
            }else if(self.providerShapeMappingExists){
                /*if(self.providerShape.$type === "vdml:Role"){
                    BMs = self.filterBMs(self.providerShape.mid);
                }else if(self.providerShape.$type === "vdml:BusinessModel"){
                    BMs = $.grep(self.BMsColl.models,function(bm){return bm.id === self.providerShape.mid;});
                    $('#selectBMVP'+self.encodeId).attr('disabled','disabled');
                }
                if(BMs && BMs.length){
                    self.BMsColl.reset();
                    self.BMsColl.add(BMs);
                    $('#selectBMVP'+self.encodeId).val(self.BMsColl.at(0).get('name'));
                    self.selectedBMVP = self.BMsColl.at(0);
                    self.onChangeSelectedBMVP();
                }*/
                self.fillProviderBasedOnProviderShape();
            }else if(self.receiverShapeMappingExists){
                /*if(self.receiverShape.$type === "vdml:Role"){
                    BMs = self.filterBMs(self.receiverShape.mid);
                }else if(self.receiverShape.$type === "vdml:BusinessModel"){
                    BMs = $.grep(self.BMsColl.models,function(bm){return bm.id === self.receiverShape.mid;});
                    $('#selectBMVP'+self.encodeId).attr('disabled','disabled');
                }
                if(BMs && BMs.length){
                    self.BMsColl.reset();
                    self.BMsColl.add(BMs);
                    $('#selectBMVP'+self.encodeId).val(self.BMsColl.at(0).get('name'));
                    self.selectedBMVP = self.BMsColl.at(0);
                    self.onChangeSelectedBMVP();
                }*/
                self.fillReceiverBasedOnReceiverShape();
            }
            self.loadVPs();
        };
        this.fillProviderBasedOnProviderShape = function(){
            debugger
			if (self.providerShape.$type === "vdml:BusinessModel") {
				self.incomingBmId = self.providerShape.mid;
                return;
            }else if(self.providerShape.$type === "vdml:MarketSegment" || self.providerShape.$type === "vdml:Enterprise" || self.providerShape.$type === "vdml:Individual"){
                var part = $.grep(self.vpProviders(),function(part){return part.id === self.providerShape.mid;});
                if(part && part.length){
                    self.vpProvider(part[0]);
                    /*if(self.selectedBMVP){
                        var bm = BusinessModel.find({id:self.selectedBMVP.id});
                        if(!bm.get('business').id === part[0].id){
                            $('#myProposition'+self.encodeId).attr('disabled','disabled');
                        }
                    }*/
                    /*if(!Participant.find({id:part[0].id}).get('businessModel')){
                        $('#myProposition'+self.encodeId).attr('disabled','disabled');
                    }*/
                }
                $('#providerField'+self.encodeId).attr('disabled','disabled');
            }else if(self.providerShape.$type === "vdml:Role"){
                var role = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.providerShape.mid });
                var assignment = role ? role.get('roleAssignment').at(0):null;
                var participant = assignment? assignment.get('participant') : null;
                part = participant ? $.grep(self.vpProviders(),function(participantProvider1){return participantProvider1.id === participant.id;}) : null;
                if(part && part.length){
                    self.vpProvider(part[0]);
                    if(self.selectedBMVP){
                        var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMVP.id});
                        if(bm && (!bm.get('business').id === part[0].id)){
                            $('#myProposition'+self.encodeId).attr('disabled','disabled');
                        }
                    }
                    /*if(!Participant.find({id:part[0].id}).get('businessModel')){
                        $('#myProposition'+self.encodeId).attr('disabled','disabled');
                    }*/
                }
                $('#providerField'+self.encodeId).attr('disabled','disabled');
                var roleToDisplay = $.grep(self.vpProviderRoles(),function(role1){return role1.id === role.id;});
                if(roleToDisplay && roleToDisplay.length){
                    self.selectedProviderRole(roleToDisplay[0]);
                }
                $('#providerRoleField'+self.encodeId).attr('disabled','disabled');                               
            }
        };
        this.fillReceiverBasedOnReceiverShape = function(){
			if (self.receiverShape.$type === "vdml:BusinessModel") {
				self.outgoingBmId = self.receiverShape.mid;
                return;
            }else if(self.receiverShape.$type === "vdml:MarketSegment" || self.receiverShape.$type === "vdml:Enterprise" || self.receiverShape.$type === "vdml:Individual"){
                var partReceiver = $.grep(self.vpReceivers(),function(part){return part.id === self.receiverShape.mid;});
                if(partReceiver && partReceiver.length){
                    self.vpReceiver(partReceiver[0]);
                    $('#myProposition'+self.encodeId).attr('disabled','disabled');
                }
                $('#recipientField'+self.encodeId).attr('disabled','disabled');
            }else if(self.receiverShape.$type === "vdml:Role"){
                var roleReceiver = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.receiverShape.mid });
                var assignmentReceiver = roleReceiver.get('roleAssignment').at(0);
                var participantReceiver = assignmentReceiver? assignmentReceiver.get('participant') : null;
                partReceiver = $.grep(self.vpReceivers(),function(participantReceiver1){return participantReceiver1.id === participantReceiver.id;});
                if(partReceiver && partReceiver.length){
                    self.vpReceiver(partReceiver[0]);
                    $('#myProposition'+self.encodeId).attr('disabled','disabled');
                }
                $('#recipientField'+self.encodeId).attr('disabled','disabled');
                var roleReceiverToDisplay = $.grep(self.vpReceiverRoles(),function(role1){return role1.id === roleReceiver.id;});
                if(roleReceiverToDisplay && roleReceiverToDisplay.length){
                    self.selectedReceiverRole(roleReceiverToDisplay[0]);    
                }
                $('#recipientRoleField'+self.encodeId).attr('disabled','disabled');                               
            }
        };
		/*this.updateShapeName = function(id){
			var self = this;
			var shape = self.parentView.modeler.get('elementRegistry')._elements[id].element;
            self.eventBus.fire('elements.changed', {
                elements: [shape]
            });
		}
        this.mapProviderShape = function(bm,vdmPackage,selectedVP,vpProvider,callback){
            if(self.providerShape.$type === 'vdml:BusinessModel'){
                self.mapBusinessModel(bm, self.selectedBMVP.name, vdmPackage, self.providerShape, null,null,function(bm){
                  if(self.providerVPShape || self.receiverVPShape){
                      var vp = ValueProposition.find({id:self.providerVPShape? self.providerVPShape.mid : self.receiverVPShape.mid});
                      if(vp && !vp.get('isValueFormula')){
                          if(!bm.get('bmValueProposition').findWhere({'valueProposition':vp})){ 
                              bm.createBMValueProposition(vp.get('name'),vp.get('description'),vp);
                          }
                      }if(vp && vp.get('isValueFormula')){
                          if(!bm.get('bmValueFormula').findWhere({'valueFormula':vp})){
                              bm.createBMValueFormula(vp.get('name'),vp.get('description'),vp);
                          }
                      }
                  }
                  self.updateShapeName(self.providerShape.id);
                  callback();
                });
            }else if(self.providerShape.$type === 'vdml:MarketSegment' || self.providerShape.$type === 'vdml:Enterprise' || self.providerShape.$type === 'vdml:Individual' || self.providerShape.$type === 'vdml:Role'){
                var relType,shapeType = self.mappingObject[self.providerShape.$type];
                if(selectedVP){
                    var providerRole = selectedVP.get('provider');
                    if(!providerRole)return;
                    var providerRoleAssignment = providerRole.get('roleAssignment').at(0);
                    var provider = providerRoleAssignment ? providerRoleAssignment.get('participant') : null;
                    if(bm.get('bmNetworkPartner').findWhere({'networkPartner':provider})){
                        relType = 'Partner';
                    }else if(bm.get('bmCustomer').findWhere({'customer':provider})){
                        relType = 'Customer';
                    }else if(bm.get('business').id === provider.id){
                        relType = 'Business';
                    }
					self.mapOtherShapes(bm.getNestedParent(),bm,shapeType,provider.get('type'),relType,providerRole.get('collaborationRoleOwner'),provider,providerRole,self.providerShape,null,null,function(){
						self.updateShapeName(self.providerShape.id);
						callback();
                    });    
                }else{
                    provider = Participant.find({id: vpProvider.id});
                    providerRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.selectedProviderRole().id });
                    if(bm.get('bmNetworkPartner').findWhere({'networkPartner':provider})){
                        relType = 'Partner';
                    }else if(bm.get('bmCustomer').findWhere({'customer':provider})){
                        relType = 'Customer';
                    }else if(bm.get('business').id === provider.id){
                        relType = 'Business';
                    }
                    self.mapOtherShapes(bm.getNestedParent(),bm,shapeType,provider.get('type'),relType,providerRole.get('collaborationRoleOwner'),provider,providerRole,self.providerShape,null,null,function(){
						  self.updateShapeName(self.providerShape.id);
						  callback();
                    });  
                }
            }
        };
        this.mapReceiverShape = function(bm,vdmPackage,selectedVP,vpReceiver,callback){
            if(self.receiverShape.$type === 'vdml:BusinessModel'){
                self.mapBusinessModel(bm, self.selectedBMVP.name, vdmPackage, self.receiverShape , null,null,function(bm){
                  if(self.providerVPShape || self.receiverVPShape){
                      var vp = ValueProposition.find({id:self.providerVPShape? self.providerVPShape.mid : self.receiverVPShape.mid});
                      if(vp && !vp.get('isValueFormula')){
                          if(!bm.get('bmValueProposition').findWhere({'valueProposition':vp})){ 
                              bm.createBMValueProposition(vp.get('name'),vp.get('description'),vp);
                          }
                      }if(vp && vp.get('isValueFormula')){
                          if(!bm.get('bmValueFormula').findWhere({'valueFormula':vp})){
                              bm.createBMValueFormula(vp.get('name'),vp.get('description'),vp);
                          }
                      }
                  }
				self.updateShapeName(self.receiverShape.id);
                callback();
                });
            }else if(self.receiverShape.$type === 'vdml:MarketSegment' || self.receiverShape.$type === 'vdml:Enterprise' || self.receiverShape.$type === 'vdml:Individual' || self.receiverShape.$type === 'vdml:Role'){
                var relType,shapeType = self.mappingObject[self.receiverShape.$type];
                if(selectedVP){
                    var receiverRole = selectedVP.get('recipient');
                    if(!receiverRole)return;
                    var receiverRoleAssignment = receiverRole.get('roleAssignment').at(0);
                    var receiver = receiverRoleAssignment ? receiverRoleAssignment.get('participant') : null;
                    if(bm.get('bmNetworkPartner').findWhere({'networkPartner':receiver})){
                        relType = 'Partner';
                    }else if(bm.get('bmCustomer').findWhere({'customer':receiver})){
                        relType = 'Customer';
                    }else if(bm.get('business').id === receiver.id){
                        relType = 'Business';
                    }
                    self.mapOtherShapes(bm.getNestedParent(),bm,shapeType,receiver.get('type'),relType,receiverRole.get('collaborationRoleOwner'),receiver,receiverRole,self.receiverShape,null,null,function(){
						self.updateShapeName(self.receiverShape.id);
						callback();
                    });
                }else if(vpReceiver){
                    receiver = Participant.find({id: vpReceiver.id});
                    receiverRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.selectedReceiverRole().id });
                    if(bm.get('bmNetworkPartner').findWhere({'networkPartner':receiver})){
                        relType = 'Partner';
                    }else if(bm.get('bmCustomer').findWhere({'customer':receiver})){
                        relType = 'Customer';
                    }else if(bm.get('business').id === receiver.id){
                        relType = 'Business';
                    }
                    self.mapOtherShapes(bm.getNestedParent(),bm,shapeType,receiver.get('type'),relType,receiverRole.get('collaborationRoleOwner'),receiver,receiverRole,self.receiverShape,null,null,function(){
						self.updateShapeName(self.receiverShape.id);
						callback();
                    });
                }
            }
            
        };*/
        this.loadParticipants = function(packId){
            self.participantsColl.reset();
            self.partTypeahead.rerender([]);
            if(!packId){
                return; 
            }
            var bm = self.selectedBMOther ? Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMOther.id}):null;
            function filterParticipants(type1, type2){
                var participants = [];
                DataManager.getDataManager().getAllDocumentsCollectionOfType(packId, type1, function (result) {
                    var repId = DataManager.getDataManager().get('viewAlternative');
                        for (var i = 0; i < result.length; i++) {
                            if(repId !== window.utils.getPrefix(result.at(i).get('id'))){
                                var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                                if(window.utils.getElementModel(newId,[type2])){
                                    continue;
                                }
                            }
                           var relOrgUnit = Backbone.Relational.store.getObjectByName(type2).find({ id: result.at(i).get('id') });
                           if(relOrgUnit && self.currentAlternative.get('phaseDesignPart').findWhere({'beepReference': relOrgUnit.getNestedParent().id})) {
                                participants.push(relOrgUnit);
                           }
                       }
                       if(participants){
                            for(var i=0; i<participants.length; i++){
                                if(self.selectedRelType() === "Business"){
                                    if(bm && participants[i] === bm.get('business')){
                                        self.participantsColl.push({id:participants[i].id, name:participants[i].get('name')});
                                    }
                                }else if(bm && participants[i] !== bm.get('business')){ // when existing bm is selected, filter for participans which is not business of the bm
                                    self.participantsColl.push({id:participants[i].id, name:participants[i].get('name')});
                                }else if(!bm){
                                    self.participantsColl.push({id:participants[i].id, name:participants[i].get('name')});
                                }
                            }
                       }
                       participants.length = 0;
                }, false,null,false,null,null,true);
            }
            if(self.selectedShapeType.name === "Market Segment"){
                filterParticipants('vdml_Community','vdml.Community');
            }else if(self.selectedShapeType.name === "Enterprise"){
                filterParticipants('vdml_OrgUnit','vdml.OrgUnit');
            }else if(self.selectedShapeType.name === "Individual"){
                filterParticipants('vdml_Actor','vdml.Actor');
            }else if(self.selectedShapeType.name === "Role"){
                self.showPartType(true);
                filterParticipants('vdml_Community','vdml.Community');
                filterParticipants('vdml_OrgUnit','vdml.OrgUnit');
                filterParticipants('vdml_Actor','vdml.Actor');
            }
        };
        this.loadVPs = function(callback){
            self.VPsColl.reset();
            var filteredVPsArray = [];
            self.vpTypeahead.rerender([]);
            $('#selectVP' + self.encodeId).val('');
            self.selectedVP = null;
            self.onChangeSelectedVP();
            function getFullPath(vp){
                var path = [];
                path.push(vp.get('provider').get('name'));
                path.push(vp.getParent().getParent().get('name'));
                path.push(vp.getParent().getParent().getParent().get('name'));
                var altId = window.utils.getPrefix(vp.id);
                var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id:altId});
                path.push(alt.get('name'));
                var phase = alt.get('phaseAlternativeOwner');
                path.push(phase.get('name'));
                return path;
            }
            function filterRecipients(vp){
                if(vp.get('recipient')){
                    //vp has recipient 
                    if(self.receiverShape && self.receiverShape.mid){
                        //if receiver is mapped
                        if(self.receiverShape.$type === 'vdml:Role'){
                            //filtering for same receiver role if the receiver is role
                            if(vp.get('recipient').id === self.receiverShape.mid){
                                filteredVPsArray.push({id:vp.id, name: vp.get('name'), type: vp.get('type'), path: getFullPath(vp)});
                            }
                        }else if(self.receiverShape.$type === 'vdml:MarketSegment' || self.receiverShape.$type === 'vdml:Enterprise' || self.receiverShape.$type === 'vdml:Individual'){
                            var roleAssignmentsRecipient = vp.get('recipient').get('roleAssignment');
                            var assignmentReceiver = roleAssignmentsRecipient.at(0);
                            if(assignmentReceiver.get('participant').id === self.receiverShape.mid){
                                filteredVPsArray.push({id:vp.id, name: vp.get('name'), type: vp.get('type'), path: getFullPath(vp)});
                            }
                        }else if(self.receiverShape.$type === 'vdml:BusinessModel'){
                            filteredVPsArray.push({id:vp.id, name: vp.get('name'), type: vp.get('type'), path: getFullPath(vp)});
                        }
                    }else if(self.receiverShape && !self.receiverShape.mid){
                        //if receiver is mapped
                        if(self.receiverShape.$type === 'vdml:Role'){
                            filteredVPsArray.push({id:vp.id, name: vp.get('name'), type: vp.get('type'), path: getFullPath(vp)});
                        }else if(self.receiverShape.$type === 'vdml:MarketSegment' || self.receiverShape.$type === 'vdml:Enterprise' || self.receiverShape.$type === 'vdml:Individual'){
                            roleAssignmentsRecipient = vp.get('recipient').get('roleAssignment');
                            assignmentReceiver = roleAssignmentsRecipient.at(0);
                            var participantType = assignmentReceiver.get('participant').get('type');
                            participantType = participantType.replace(/_/g, '.');
                            if (participantType === self.mappingObject[self.receiverShape.$type].type) {
                                filteredVPsArray.push({id:vp.id, name: vp.get('name'), type: vp.get('type'), path: getFullPath(vp)});                      
                            }
                        }else if(self.receiverShape.$type === 'vdml:BusinessModel'){
                            filteredVPsArray.push({id:vp.id, name: vp.get('name'), type: vp.get('type'), path: getFullPath(vp)});
                        }
                    }else if(!self.receiverShape){
                        filteredVPsArray.push({id:vp.id, name: vp.get('name'), type: vp.get('type'), path: getFullPath(vp)});
                    }
                }else if(!self.receiverShape){
                    filteredVPsArray.push({id:vp.id, name: vp.get('name'), type: vp.get('type'), path: getFullPath(vp)});
                }
            }
            var VPs = new Backbone.Collection();
            DataManager.getDataManager().getAllDocumentsCollectionOfType(self.currentAlternative.id, 'vdml_ValueProposition', function (result) {
                var repId = DataManager.getDataManager().get('viewAlternative');
                for (var i = 0; i < result.length; i++) {
                    if(repId !== window.utils.getPrefix(result.at(i).get('id'))){
                        var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                        if(window.utils.getElementModel(newId,['vdml.ValueProposition'])){
                            continue;
                        }
                    }
                     var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: result.at(i).get('id') });
                     if(vp.get('isValueFormula')){
                        if(self.myProposition() && self.currentAlternative.get('phaseDesignPart').findWhere({'beepReference': vp.getNestedParent().id})){
                            VPs.push(vp);
                        }
                     }else if(!self.myProposition() && self.currentAlternative.get('phaseDesignPart').findWhere({'beepReference': vp.getNestedParent().id})){
                        VPs.push(vp);
                     }
                 }
				if (!self.myProposition()) {
					if (self.incomingBmId) {
						var bmIncomingBusiness = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.incomingBmId });
					}
					if (self.outgoingBmId) {
						var bmOutgoingBusiness = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.outgoingBmId });
					}
                     VPs.each(function (vp) {
                        if(self.providerShape && self.providerShape.mid){
                            var roleAssignments = vp.get('provider').get('roleAssignment');
                            var assignment = roleAssignments.at(0);
                            if(self.providerShape.$type === 'vdml:Role'){
                                //if mapping exists for provider shape and the provider shape is role
                                if(vp.get('provider').id === self.providerShape.mid){
                                    //filtering for same provider role
                                    filterRecipients(vp);
                                }
                            }else if(self.providerShape.$type === 'vdml:MarketSegment' || self.providerShape.$type === 'vdml:Enterprise' || self.providerShape.$type === 'vdml:Individual'){
                                if(assignment.get('participant').id === self.providerShape.mid){
                                    // if the provider role is same
                                    filterRecipients(vp); // filtering for recipients
                                }
							} else if (self.providerShape.$type === 'vdml:BusinessModel') {
								if (bmIncomingBusiness && assignment.get('participant') === bmIncomingBusiness.get('business')) {
									filterRecipients(vp);
								} else if (!bmIncomingBusiness){
									filterRecipients(vp);
								}
                            }
                        }else if(self.providerShape && !self.providerShape.mid){
                            roleAssignments = vp.get('provider').get('roleAssignment');
                            assignment = roleAssignments.at(0);
                            if(self.providerShape.$type === 'vdml:Role'){
                                //if mapping doesnt exists for provider shape and the provider shape is role
                                filterRecipients(vp);
                            }else if(self.providerShape.$type === 'vdml:MarketSegment' || self.providerShape.$type === 'vdml:Enterprise' || self.providerShape.$type === 'vdml:Individual'){
                                var participantType = assignment.get('participant').get('type');
                                participantType = participantType.replace(/_/g, '.');
                                if (participantType === self.mappingObject[self.providerShape.$type].type) {
                                      filterRecipients(vp);                   
                                }
                            }else if(self.providerShape.$type === 'vdml:BusinessModel'){
								filterRecipients(vp);
                            }
						} else if (!self.providerShape) {
							if (self.receiverShape && vp.get('recipient') && self.receiverShape.$type === 'vdml:BusinessModel' && self.receiverShape.mid) {
								roleAssignments = vp.get('recipient').get('roleAssignment');
								assignment = roleAssignments.at(0);
								if (bmOutgoingBusiness && assignment.get('participant') === bmOutgoingBusiness.get('business')) {
									filterRecipients(vp);
								} else if (!bmOutgoingBusiness) {
									filterRecipients(vp);
								}
							} else {
								filterRecipients(vp);
							}
                        }           
                    });
                }else{
                    VPs.each(function(vf){
                        if(self.providerShape && !self.receiverShape){
                            if(self.providerShape.$type === 'vdml:Role'){
                                if(vf.get('provider').id === self.providerShape.mid || !self.providerShape.mid){
                                    filteredVPsArray.push({id:vf.id, name: vf.get('name'), type: vf.get('type'), path: getFullPath(vf)});
                                }
                            }else if(self.providerShape.$type === 'vdml:MarketSegment' || self.providerShape.$type === 'vdml:Enterprise' || self.providerShape.$type === 'vdml:Individual'){
                                var roleAssignments = vf.get('provider').get('roleAssignment');
                                var assignment = roleAssignments.at(0);
                                if(self.providerShape.mid){
                                    if(assignment.get('participant').id === self.providerShape.mid){
                                        filteredVPsArray.push({id:vf.id, name: vf.get('name'), type: vf.get('type'), path: getFullPath(vf)});
                                    }
                                }else{
                                    var participantType = assignment.get('participant').get('type');
                                    participantType = participantType.replace(/_/g, '.');
                                    if (participantType === self.mappingObject[self.providerShape.$type].type) {
                                        filteredVPsArray.push({id:vf.id, name: vf.get('name'), type: vf.get('type'), path: getFullPath(vf)});                       
                                    }
                                }
                            }else if(self.providerShape.$type === 'vdml:BusinessModel'){
                                filteredVPsArray.push({id:vf.id, name: vf.get('name'), type: vf.get('type'), path: getFullPath(vf)});
                            }
                        }else if(!self.providerShape && !self.receiverShape){
                            filteredVPsArray.push({id:vf.id, name: vf.get('name'), type: vf.get('type'), path: getFullPath(vf)});
                        }
                    });
                }
                VPs.reset();
                _.map(_.groupBy(filteredVPsArray, function(vp){ return vp.name; }),function(grouped){
                    addNamePath(grouped);
                    _.each(grouped, function(group){
                        self.VPsColl.add({id:group.id, name: group.displayName, type: group.type, shortName: group.name});
                    });
                });
                function addNamePath(grouped){
                    var index;
                    _.each(grouped, function(val){
                        val.displayName = val.name;
                        index = val.path?val.path.length:0;
                    });
                    if(grouped.length>1){
                        var filteredArray = grouped;
                        _.each(filteredArray, function(element){
                            element.displayName = element.displayName + ' [';
                            element.namePath = "";
                        });
                        var duplicates = true, y = 0;
                        while(duplicates){
                            _.each(filteredArray, function(element){
                                if(y === 0){
                                    element.displayName = element.displayName + element.path[y];
                                    element.namePath = element.namePath + element.path[y];
                                }
                                else{
                                    element.displayName = element.displayName + ' /' +element.path[y];
                                    element.namePath = element.namePath + ' /' + element.path[y];
                                }
                            });
                            var z =_.groupBy(filteredArray, function(a){
                                return a.displayName;
                            });
                            if(Object.keys(z).length>1){
                                filteredArray = [];
                                _.each(z, function(v){
                                    if(v.length>1){
                                        _.each(v,function(vals){
                                            filteredArray.push(vals);
                                        });
                                    }
                                    else{
                                        if(v[0].displayName === v[0].name +' ['){
                                            v[0].displayName = v[0].displayName + v[0].path[1] + ']';
                                        }
                                        else{
                                            v[0].displayName = v[0].displayName+ ']';
                                        }
                                    }
                                });
                            }
                            if(filteredArray.length === 0){
                                duplicates = false;
                            }
                            y++;
                        }
                    }
                }
				if (diagramBO.get('name')) {
					var shapeName = diagramBO.get('name');
					if (diagramBO.get('mid')) {
						var vpFound = _.filter(self.VPsColl.models, function (obj) { return obj.get('id') === diagramBO.get('mid') });
					} else {
						var vpFound = _.filter(self.VPsColl.models, function (obj) { return obj.get('shortName').toLowerCase() === diagramBO.get('name').toLowerCase() });
					}
					if (vpFound && vpFound.length) {
						self.selectedVP = vpFound[0];
						shapeName = vpFound[0].get('shortName');
						self.onChangeSelectedVP();
					}
					$('#selectVP' + self.encodeId).val(shapeName);
					self.enableCompleteForVP();
					callback && callback();
				} else {
					callback && callback();
				}
            }, false,null,false);
            
        };
        
        this.loadProviderRoles = function (provider, receiverRole) {
            var selProviderRole = self.selectedProviderRole();
            var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMVP.id});
            self.vpProviderRoles.removeAll();
            if (!provider) {
                return;
            }
            var bmRoles = bm.get('bmRole');
            bmRoles.each(function (role) {
                var roleAssignments = role.get('roleAssignment');
                for (var i = 0; i < roleAssignments.length; i++) {
                    var assignment = roleAssignments.at(i);
                    if (assignment.get('participant').id === provider.id) {
                        var allBMRoles = bm.get('bmRole').models;
                        var displayRolename = self.checkDuplicatesPath(allBMRoles,role,role.get('name'));
                        if (receiverRole && receiverRole.id === role.id) {
                            break;
                        }
                        self.vpProviderRoles.push({ id: role.id, name: role.get('name'), displayRolename: displayRolename});
                    }
                }
            });
            self.vpProviderRoles.sort(sortRolesByName);
            if(self.providerShapeMappingExists && self.receiverShapeMappingExists && self.providerShape.$type === "vdml:BusinessModel" && self.receiverShape.$type === "vdml:BusinessModel" && self.providerShape.mid !== self.receiverShape.mid){
                var receiverBM = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.receiverShape.mid});
                var receiverBMRoles = receiverBM.get('bmRole');
                var commonRoles = [];
                for(var i=0;i<self.vpProviderRoles().length;i++){
                    receiverBMRoles.each(function(bmRole){
                        if(bmRole.id === self.vpProviderRoles()[i].id){
                            commonRoles.push(self.vpProviderRoles()[i]);
                        }
                    });
                }
                self.vpProviderRoles().length = 0;
                self.vpProviderRoles(commonRoles);          
            }
            if (selProviderRole) {
                var item = $.grep(self.vpProviderRoles(), function (e) { return e.name === selProviderRole.name; });
                self.selectedProviderRole(item[0]);
            }
        };
        function sortRolesByName(left, right) {
            return left.displayRolename.toLowerCase() == right.displayRolename.toLowerCase() ? 0 : (left.displayRolename.toLowerCase() < right.displayRolename.toLowerCase() ? -1 : 1);
        }   
        this.loadReceiverRoles = function (receiver, providerRole) {
            var selReceiverRole = self.selectedReceiverRole();
            var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMVP.id});
            self.vpReceiverRoles.removeAll();
            if (!receiver) {
                return;
            }
            var bmRoles = bm.get('bmRole');
            bmRoles.each(function (role) {
                var roleAssignments = role.get('roleAssignment');
                for (var i = 0; i < roleAssignments.length; i++) {
                    var assignment = roleAssignments.at(i);
                    if (assignment.get('participant').id === receiver.id) {
                        var allBMRoles = bm.get('bmRole').models;
                        var displayRolename = self.checkDuplicatesPath(allBMRoles,role,role.get('name'));
                        if (providerRole && providerRole.id === role.id) {
                            break;
                        }
                        self.vpReceiverRoles.push({ id: role.id, name: role.get('name'), displayRolename: displayRolename});
                    }
                }
            });
            self.vpReceiverRoles.sort(sortRolesByName);
            if(self.providerShapeMappingExists && self.receiverShapeMappingExists && self.providerShape.$type === "vdml:BusinessModel" && self.receiverShape.$type === "vdml:BusinessModel" && self.providerShape.mid !== self.receiverShape.mid){
                var receiverBM = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.receiverShape.mid});
                var receiverBMRoles = receiverBM.get('bmRole');
                var commonRoles = [];
                for(var i=0;i<self.vpReceiverRoles().length;i++){
                    receiverBMRoles.each(function(bmRole){
                        if(bmRole.id === self.vpReceiverRoles()[i].id){
                            commonRoles.push(self.vpReceiverRoles()[i]);
                        }
                    });
                }
                self.vpReceiverRoles().length = 0;
                self.vpReceiverRoles(commonRoles);          
            }
            if (selReceiverRole) {
                var item = $.grep(self.vpReceiverRoles(), function (e) { return e.name === selReceiverRole.name; });
                self.selectedReceiverRole(item[0]);
            }
        };
        
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
        'mapItem',
        'prev',
        'next',
        'selectMappingElement',
        'selectDimensionTitle'
        ,'provider'
        ,'recipient'
        ,'SelectRole'
        ,'selectCreateVP'
        ,'BusinessModel'
        ,'selectCreateBM'
        ,'selectCreateBusiness'
        ,'createBusiness'
        ,'selectCreatePN'
        ,'selectCreateParticipant'
        ,'selectCreatePN'
        ,'selectElementType'
        ,'Role'
        ,'SelectParticipant'
        ,'inRole'
        ,'MyProposition'
        ,'Package'
        ,'selectPackage'
        ,'Relationship'
        ,'ParticipantType'
        ]);

        this.startWizard = function(cond){
            var viewModel = this;
            var modalContentNode = $('#wizardModalContent');
            modalContentNode.css('width','auto');
            modalContentNode.css('height','auto');
            modalContentNode.css('minHeight','auto');
            ko.unapplyBindings(modalContentNode,false);
            modalContentNode.children().remove();
            window.utils.startSpinner('createSpinner', "Creating Mapping...");
            $.get("js/app/version1/views/ecoMap/views/designer/EcomapMappingWizardTemplate.html", function(templates) {
                modalContentNode.append(templates);
                var templateNode = $('#wizardTemplateContent');
                ko.applyBindings(viewModel, templateNode[0]);
                viewModel.modelElement.modal('toggle');
                viewModel.$wizard = $('#NewWizard').wizard(cond);
                viewModel.handleWizardButtons();
                viewModel.afterRenderView();
            });
        };
        
        this.afterRenderView = function(){
			var wizardName = "wizardModalContent";
			if(self.shapeTypeFromWizard()){
			wizardName = "wizardModalContent1";
			} 
            $('#'+wizardName).resizable();
			$('#' + wizardName).draggable({
				containment: "#content"
			});
            /*$('#'+wizardName).on("resize", function(event, ui) {// setting min height of the template, to prevent buttons and content from overflowing
              ui.element.css("minHeight",$("#wizardTemplateContent").actual('outerHeight'));
            });*/
            //$('.ui-icon').hide();
            if(self.shapeTypeFromWizard()){
                self.selectedShapeType = self.shapeTypesList()[_.findLastIndex(self.shapeTypesList(), {name: self.shapeTypeFromWizard().name})];
                self.onSelectedShapeTypeChange(self.selectedShapeType);
            }else if(diagramBO){
                self.selectedShapeType = self.shapeTypesList()[_.findLastIndex(self.shapeTypesList(), {name: self.mappingObject[diagramBO.$type].name})];
                self.onSelectedShapeTypeChange(self.selectedShapeType);
            }
            
            var bmExtended = Backbone.Typeahead.extend({
                template: '<input type="text" id="selectBM' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.bmTypeahead = new bmExtended({ collection: this.BMsColl });
            self.bmTypeahead.setElement('#bm' + self.encodeId).render();

            self.bmTypeahead.on('selected', function (selectedBM) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedMappingBM = selectedBM;
                self.onChangeSelectedMappingBM();
                self.enableCompleteForBM();
            });
            
            var bmExtendedOther = Backbone.Typeahead.extend({
                template: '<input type="text" id="selectBMOther' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.bmTypeaheadOther = new bmExtendedOther({ collection: this.BMsColl });
            self.bmTypeaheadOther.setElement('#bmOther' + self.encodeId).render();

            self.bmTypeaheadOther.on('selected', function (selectedBM) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedBMOther = selectedBM;
                self.onChangeSelectedBMOther();
                self.enableCompleteForOtherShapes();
            });
            
            var bmExtendedVP = Backbone.Typeahead.extend({
                template: '<input type="text" id="selectBMVP' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.bmTypeaheadVP = new bmExtendedVP({ collection: this.BMsColl });
            self.bmTypeaheadVP.setElement('#bmVP' + self.encodeId).render();

            self.bmTypeaheadVP.on('selected', function (selectedBM) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedBMVP = selectedBM;
                self.onChangeSelectedBMVP();
                //self.suggestedVPDisplay(self.myProposition());
                self.enableCompleteForVP();
            });
            
            var businessExtended = Backbone.Typeahead.extend({
                template: '<input type="text" id="selectBusiness' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.businessTypeahead = new businessExtended({ collection: this.businessColl });
            self.businessTypeahead.setElement('#business' + self.encodeId).render();

            self.businessTypeahead.on('selected', function (selectedBusiness) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedBusiness = selectedBusiness;
                $('#showMappedInfoBusiness'+self.encodeId).show();
            });
            
            var businessExtendedOther = Backbone.Typeahead.extend({
                template: '<input type="text" id="selectBusinessOther' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.businessTypeaheadOther = new businessExtendedOther({ collection: this.businessColl });
            self.businessTypeaheadOther.setElement('#businessOther' + self.encodeId).render();

            self.businessTypeaheadOther.on('selected', function (selectedBusiness) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedBusinessOther = selectedBusiness;
                $('#showMappedInfoBusinessOther'+self.encodeId).show();
                if(self.selectedShapeType.name === 'Enterprise' && diagramBO.get('name') && diagramBO.get('name').toLowerCase() === selectedBusiness.get('name').toLowerCase()){
                    self.selectedRelType("Business");
                }
                if(!self.selectedBMOther && self.selectedRelType() === "Business" && self.participantsColl.length === 0){
                    $('#selectParticipant' + self.encodeId).val(selectedBusiness.get('name'));
                    $('#selectParticipant' + self.encodeId).attr('disabled','disabled');
                    self.selectedParticipant = selectedBusiness;
                    self.onChangeSelectedParticipant();
                }
            });
            
            var pnExtended = Backbone.Typeahead.extend({
                template: '<input type="text" id="selectPN' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.pnTypeahead = new pnExtended({ collection: this.PNsColl });
            self.pnTypeahead.setElement('#pn' + self.encodeId).render();

            self.pnTypeahead.on('selected', function (selectedPN) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedPN = selectedPN;
                self.onChangeSelectedPN();
                self.enableCompleteForOtherShapes();
            });
            
            var partExtended = Backbone.Typeahead.extend({
                template: '<input type="text" id="selectParticipant' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.partTypeahead = new partExtended({ collection: this.participantsColl });
            self.partTypeahead.setElement('#part' + self.encodeId).render();

            self.partTypeahead.on('selected', function (selectedPart) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedParticipant = selectedPart;
                self.onChangeSelectedParticipant();
                /*if(self.selectedShapeType.name === "Role"){
                    var part = Participant.find({id:self.selectedParticipant.id});
                    var type = part.get('type').replace(/_/g, '.');
                    var partType = self.partTypesList()[_.findLastIndex(self.partTypesList(), {type: type})];
                    self.selectedPartType(partType);
                    self.enablePartType(false);
                }*/
                self.enableCompleteForOtherShapes();
            });            
            
            var roleExtended = Backbone.Typeahead.extend({
                template: '<input type="text" id="selectRole' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%;max-height:70px;" class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.roleTypeahead = new roleExtended({ collection: this.rolesColl });
            self.roleTypeahead.setElement('#role1' + self.encodeId).render();
            
            self.roleTypeahead.on('selected', function (selectedRole) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedRole = selectedRole;
                self.onChangeSelectedRole();
                self.enableCompleteForOtherShapes();
            });
            var vpExtended = Backbone.Typeahead.extend({
                template: '<input type="text" id="selectVP' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.vpTypeahead = new vpExtended({ collection: this.VPsColl });
            self.vpTypeahead.setElement('#vp' + self.encodeId).render();

            self.vpTypeahead.on('selected', function (selectedVP) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedVP = selectedVP;
                self.onChangeSelectedVP();
                self.enableCompleteForVP();
            });
            
            if(self.selectedShapeType){
                $('.btn-next').click();
            }
            $('#selectBM' + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if(view.currentTarget.value.trim() !== ""){
                    self.matchField.show();
                }else{
                    self.matchField.hide();
                }
                var matchFound = _.filter(self.BMsColl.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
                if(matchFound && matchFound.length){
                    self.selectedMappingBM = matchFound[0];
                    self.matchField[0].innerHTML = "Match";
                }else{
                    self.selectedMappingBM = null;
                    self.matchField[0].innerHTML = "New";
                }
                self.onChangeSelectedMappingBM();
                self.enableCompleteForBM();
            });
            $('#selectBM' + self.encodeId).change(function(view){
                if(self.matchField){
                    self.matchField.hide();
                }
            });
			$('#selectBM' + self.encodeId).dblclick(function (view) {
				$('#selectBM'+ self.encodeId).trigger('keyup');
				$('#selectBM'+ self.encodeId).focus();
			});
            $('#selectBMOther' + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if(view.currentTarget.value.trim() !== ""){
                    self.matchField.show();
                }else{
                    self.matchField.hide();
                }
                var matchFound = _.filter(self.BMsColl.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
                if(matchFound && matchFound.length){
                    self.selectedBMOther = matchFound[0];
                    self.matchField[0].innerHTML = "Match";
                }else{
                    self.selectedBMOther = null;
                    self.matchField[0].innerHTML = "New";
                }
                self.onChangeSelectedBMOther();
                self.enableCompleteForOtherShapes();
            });
            $('#selectBMOther' + self.encodeId).change(function(view){
                if(self.matchField){
                    self.matchField.hide();
                }
            });
            $('#selectBMOther' + self.encodeId).dblclick(function (view) {
                $('#selectBMOther' + self.encodeId).trigger('keyup');
                $('#selectBMOther' + self.encodeId).focus();
            });
            $('#selectBMVP' + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if(view.currentTarget.value.trim() !== ""){
                    self.matchField.show();
                }else{
                    self.matchField.hide();
                }
                var matchFound = _.filter(self.BMsColl.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
                if(matchFound && matchFound.length){
                    self.selectedBMVP = matchFound[0];
                    self.matchField[0].innerHTML = "Match";
                }else{
                    self.selectedBMVP = null;
                    self.matchField[0].innerHTML = "New";
                }
                self.onChangeSelectedBMVP();
                self.enableCompleteForVP();
            });
            $('#selectBMVP' + self.encodeId).change(function(view){
                if(self.matchField){
                    self.matchField.hide();
                }
            });
            $('#selectBMVP' + self.encodeId).dblclick(function (view) {
                $('#selectBMVP' + self.encodeId).trigger('keyup');
                $('#selectBMVP' + self.encodeId).focus();
            });
            $("#selectPN" + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if(view.currentTarget.value.trim() !== ""){
                    self.matchField.show();
                }else{
                    self.matchField.hide();
                }
                var matchFound = _.filter(self.PNsColl.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
                if(matchFound && matchFound.length){
                    self.selectedPN = matchFound[0];
                    self.matchField[0].innerHTML = "Match";
                }else{
                    self.selectedPN = null;
                    self.matchField[0].innerHTML = "New";
                }
                self.onChangeSelectedPN();
                self.enableCompleteForOtherShapes();
            });
            $('#selectPN' + self.encodeId).change(function(view){
                if(self.matchField){
                    self.matchField.hide();
                }
            });
            $('#selectPN' + self.encodeId).dblclick(function (view) {
                $('#selectPN' + self.encodeId).trigger('keyup');
                $('#selectPN' + self.encodeId).focus();
            });
            $("#selectParticipant" + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if(view.currentTarget.value.trim() !== ""){
                    self.matchField.show();
                }else{
                    self.matchField.hide();
                }
                var matchFound = _.filter(self.participantsColl.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
                if(matchFound && matchFound.length){
                    self.selectedParticipant = matchFound[0];
                    self.matchField[0].innerHTML = "Match";
                }else{
                    self.selectedParticipant = null;
                    self.matchField[0].innerHTML = "New";
                }
                self.onChangeSelectedParticipant();
                if(self.selectedShapeType.name === "Role" && self.selectedRelType() !== "Business"){
                    self.enablePartType(true);
                }
                self.enableCompleteForOtherShapes();
            });
            $('#selectParticipant' + self.encodeId).change(function(view){
                if(self.matchField){
                    self.matchField.hide();
                }
            });
            $('#selectParticipant' + self.encodeId).dblclick(function (view) {
                $('#selectParticipant' + self.encodeId).trigger('keyup');
                $('#selectParticipant' + self.encodeId).focus();
            });
            $("#selectRole" + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if(view.currentTarget.value.trim() !== ""){
                    self.matchField.show();
                }else{
                    self.matchField.hide();
                }
                var matchFound = _.filter(self.rolesColl.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
                if(matchFound && matchFound.length){
                    self.selectedRole = matchFound[0];
                    self.matchField[0].innerHTML = "Match";
                }else{
                    self.selectedRole = null;
                    self.matchField[0].innerHTML = "New";
                }
                self.onChangeSelectedRole();
                self.enableCompleteForOtherShapes();
            });
            $('#selectRole' + self.encodeId).change(function(view){
                if(self.matchField){
                    self.matchField.hide();
                }
            });
            $('#selectRole' + self.encodeId).dblclick(function (view) {
                $('#selectRole' + self.encodeId).trigger('keyup');
                $('#selectRole' + self.encodeId).focus();
            });
            $("#selectVP" + self.encodeId).keyup(function (view) {
                var currentValue = view.currentTarget.value.trim().toLowerCase();
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if(view.currentTarget.value.trim() !== ""){
                    self.matchField.show();
                }else{
                    self.matchField.hide();
                }
                var matchFound = _.filter(self.VPsColl.models,function(obj){return obj.get('shortName').toLowerCase() === currentValue;});
                if(matchFound && matchFound.length){
                  if(matchFound.length>1 && self.selectedBMVP){
                    var BmModel = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMVP.get('id')});
                    if(BmModel){
                      var bmVPs = BmModel.get('bmValueProposition').models;
                      for(var i=0;i<bmVPs.length;i++){
                        if(bmVPs[i].get('valueProposition').get('name') && bmVPs[i].get('valueProposition').get('name').toLowerCase()==currentValue){
                          self.selectedVP = bmVPs[i].get('valueProposition');
                          self.matchField[0].innerHTML = "Match";
                          break;
                        }
                      }
                    }
                  }
                  else{
                      self.selectedVP = matchFound[0];
                      self.matchField[0].innerHTML = "Match";
                  }
                }else{
                    self.selectedVP = null;
                    self.matchField[0].innerHTML = "New";
                }
                self.onChangeSelectedVP();
                self.enableCompleteForVP();
            });
            $('#selectVP' + self.encodeId).change(function(view){
                if(self.matchField){
                    self.matchField.hide();
                }
            });
            $('#selectVP' + self.encodeId).dblclick(function (view) {
                $('#selectVP' + self.encodeId).trigger('keyup');
                $('#selectVP' + self.encodeId).focus();
            });
            $('#selectBusiness' + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if(view.currentTarget.value.trim() !== ""){
                    self.matchField.show();
                }else{
                    self.matchField.hide();
                }
                var matchFound = _.filter(self.businessColl.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
                if(matchFound && matchFound.length){
                    self.selectedBusiness = matchFound[0];
                    self.matchField[0].innerHTML = "Match";
                    $('#showMappedInfoBusiness'+self.encodeId).show();
                }else{
                    self.selectedBusiness = null;
                    self.matchField[0].innerHTML = "New";
                    $('#showMappedInfoBusiness'+self.encodeId).hide();
                }
            });
            $('#selectBusiness' + self.encodeId).change(function(view){
                if(self.matchField){
                    self.matchField.hide();
                }
            });
            $('#selectBusiness' + self.encodeId).dblclick(function (view) {
                $('#selectBusiness' + self.encodeId).trigger('keyup');
                $('#selectBusiness' + self.encodeId).focus();
            });
            $('#selectBusinessOther'+self.encodeId).keyup(function(view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if(view.currentTarget.value.trim() !== ""){
                    self.matchField.show();
                }else{
                    self.matchField.hide();
                }
                var matchFound = _.filter(self.businessColl.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
                if(matchFound && matchFound.length){
                    self.selectedBusinessOther = matchFound[0];
                    self.matchField[0].innerHTML = "Match";
                    $('#showMappedInfoBusinessOther'+self.encodeId).show();
                }else{
                    self.selectedBusinessOther = null;
                    self.matchField[0].innerHTML = "New";
                    $('#showMappedInfoBusinessOther'+self.encodeId).hide();
                }
                if(self.selectedShapeType.name === 'Enterprise' && diagramBO.get('name') && diagramBO.get('name').toLowerCase() === view.currentTarget.value.trim().toLowerCase()){
                    self.selectedRelType("Business");
                }
                if(!self.mappedDiagramBO){
                    $('#selectParticipant' + self.encodeId).removeAttr('disabled');                
                    if(!self.selectedBMOther && self.selectedRelType() === "Business" && self.participantsColl.length === 0){
                        $('#selectParticipant' + self.encodeId).val(view.currentTarget.value);
                        self.selectedParticipant = null;
                        self.onChangeSelectedParticipant();
                        $('#selectParticipant' + self.encodeId).attr('disabled','disabled');
                    }
                }
            });
            $('#selectBusinessOther' + self.encodeId).change(function(view){
                if(self.matchField){
                    self.matchField.hide();
                }
            });
            $('#selectBusinessOther' + self.encodeId).dblclick(function (view) {
                $('#selectBusinessOther' + self.encodeId).trigger('keyup');
                $('#selectBusinessOther' + self.encodeId).focus();
            });
            var autoCompAttr = 'new-password';
            if(window.browser == "chrome"){
                autoCompAttr = 'off';
            }
            $('input[type="text"]').attr('autocomplete',autoCompAttr);
            $('input[type="text"]').bind("paste", function(e){
                var validTxt = window.utils.pasteHandle(e);
                if(validTxt !== null){
                    e.stopPropagation();
                    e.preventDefault();
                    $(e.target).val(window.utils.pasteContent(e,validTxt));
                    return false;
                }
            });
            if(DataManager.getDataManager().get('readMode')){
                $(".btn-next").hide();
            }
			window.utils.stopSpinner('createSpinner');
        };
        
        this.mapBusinessModel = function(bmElement,mappedElementName,pack,diagBO,business, businessName, callback){
            var bm;
            var vdm;
            function createBusiness(previousWorkspace) {
                function setDiagramId(diagBO, bm, bmCallback) {
                    if (diagBO && bm) {
                        diagBO.set('vdml:mid', bm.id);
                        diagBO.set('vdml:mpid', vdm.id);
                        self.model.get('businessModel').add(bm);
                        if (!diagBO.get('name') || diagBO.get('name') === '') {
                            diagBO.set('name', bm.get('name'));
                        }
                        if (!diagBO.get('description') || diagBO.get('description') === '') {
                            diagBO.set('description', bm.get('description'));
                        }
                    }
                    if (previousWorkspace) {
                        DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
                            bmCallback(bm);
                        });
                    } else {
                        bmCallback(bm);
                    }

                }
                if (!bmElement) {
                    var bmDescription = mappedElementName;
                    if (diagBO && diagBO.$type === "vdml:BusinessModel") {
                        bmDescription = diagBO.description ? diagBO.description : mappedElementName;
                    }
                    vdm.generateBusinessModelId(mappedElementName, bmDescription, vdm, self.currentAlternative, function (bmId) {
                        bm = vdm.getBusinessModelInstance(mappedElementName, bmDescription, bmId);
                        if (business) {
                            if (!(business instanceof Backbone.RelationalModel)) {
                                business = Backbone.Relational.store.getObjectByName('vdml.OrgUnit').find({ id: business.id });
                            }
                            bm.set('business', business);
                        } else {
                            var defaultBusiness = vdm.createDefaultBusiness(bm, businessName);
                        }
                        setDiagramId(diagBO, bm, function (bm){
                            var currentPlan =  DataManager.getDataManager().get('currentPlan');
                            currentPlan.getAllAlternateScenarios(function (altScenarios) {
                                if (!DataManager.getDataManager().altScenarios) {
                                    DataManager.getDataManager().altScenarios = {};
                                }
                                DataManager.getDataManager().altScenarios[currentPlan.get('id')] = altScenarios;
                                callback(bm);
                            });
                        });
                    });
                } else {
                    bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': bmElement.id });
                    setDiagramId(diagBO, bm, callback);
                }
            }
            if (!pack) {
                function createVDMPackage(packCallback) {
                    debugger
                    var ValueDeliveryModel = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel');
                    var packageName = mappedElementName;//mappedElementName + " Package";
                    if (!self.currentAlternative) {
                        self.getCurrentAlt();
                    }
                    vdm = ValueDeliveryModel.createPackageWithName(packageName, packageName, self.currentAlternative);
                    packCallback();
                }                
            
                var previousWorkspace = DataManager.getDataManager().getWorkspace();
                if (previousWorkspace.id != self.currentAlternative.id) {
                    DataManager.getDataManager().getWorkspaceDataWithId(self.currentAlternative.id, function (wsData) {
                        var currentWorkspace = wsData.get('workspace');
                        DataManager.getDataManager().setWorkspace(currentWorkspace, function () {
                            createVDMPackage(function () {
                                createBusiness(previousWorkspace);
                            });
                        });
                    });
                } else {
                    createVDMPackage(createBusiness);
                }

            } else {
                vdm = pack;
                createBusiness();
            }
        };
        this.mapVP = function(diagramBO,callback, revisionEcoMapPackage){
            function checkIfVPAlreadyExistsInBM(){
                if(self.selectedBMVP && self.selectedVP){
                    var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({'id':self.selectedBMVP.id});
                    var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedVP.get('id') });
                    if(bm.get('bmValueProposition').findWhere({'valueProposition':vp})){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }
            }
            function replaceVPObjects(newPackage, vdmlPackageRevision){
                var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
                if(self.selectedBMVP){
                    var selectedBMVPSuffix = window.utils.getSuffix(self.selectedBMVP.id);
                    var bmId = altId + selectedBMVPSuffix;
                }
                var selectedVP, vpProvider, vpReceiver, vpProviderRole, vpReceiverRole;
                if(self.selectedVP /*&& self.selectedVP instanceof Backbone.RelationalModel*/){
                    var selectedVPSuffix = window.utils.getSuffix(self.selectedVP.id);
                    selectedVP = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: altId + selectedVPSuffix });
                }
                var providerSuffix = window.utils.getSuffix(self.vpProvider().id);
                vpProvider = self.vpProvider();
                vpProvider.id = altId+providerSuffix;
                var providerRoleSuffix = window.utils.getSuffix(self.selectedProviderRole().id);
                vpProviderRole = self.selectedProviderRole();
                vpProviderRole.id = altId+providerRoleSuffix;
                if(self.vpReceiver()) {
                    var receiverSuffix = window.utils.getSuffix(self.vpReceiver().id);
                    vpReceiver = self.vpReceiver();
                    vpReceiver.id = altId+receiverSuffix;
                    var receiverRoleSuffix = window.utils.getSuffix(self.selectedReceiverRole().id);
                    vpReceiverRole = self.selectedReceiverRole();
                    vpReceiverRole.id = altId+receiverRoleSuffix;
                }
                saveMapVPObjects(diagramBO,bmId,newPackage,selectedVP,vpProvider,vpProviderRole,vpReceiver,vpReceiverRole,callback, vdmlPackageRevision);
            }
            var viewAltId = DataManager.getDataManager().get('viewAlternative');
            var pack = self.selectedPackageVP();
            if(revisionEcoMapPackage && pack){
                var replacePackage = self.checkIfVDMPackageIsDependentOfEcoMap(viewAltId, revisionEcoMapPackage, pack); 
                if(replacePackage){
                    pack = replacePackage;
                } 
            }
            if(pack && pack.id && DataManager.getDataManager().getRepositoryId(pack.id) !== viewAltId && !checkIfVPAlreadyExistsInBM()){
                var packModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: pack.id });
                self.createRevisionBasedOnContext(packModel,true,function(newPackage){
                    replaceVPObjects(newPackage, true);
                    window.utils.stopSpinner('revisionSpinner');
                }); 
            }else if(replacePackage){
                replaceVPObjects(pack, true);
            }else {
                var selectedVP = self.selectedVP? Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedVP.get('id') }) : null;
                saveMapVPObjects(diagramBO,self.selectedBMVP.id,self.selectedVDMPackage,selectedVP,self.vpProvider(),self.selectedProviderRole(),self.vpReceiver(),self.selectedReceiverRole(),callback);
            }
            function saveMapVPObjects(diagramBO,bmId,selectedVDMPackage,selectedVP,vpProvider,vpProviderRole,vpReceiver,vpReceiverRole,callback, vdmlPackageRevision){
                var vp,bmVF,bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:bmId}), VPName = $("#selectVP" + self.encodeId).val().trim();
                var VPDescription = diagramBO.description;
                /*function saveEcoProviderRole(providerCallback){
                  if(self.providerShape && !self.providerShapeMappingExists){
                      self.mapProviderShape(bm,selectedVDMPackage,selectedVP,vpProvider,function(){
                        providerCallback();
                      });
                  }else {
                    providerCallback();
                  }
                }
                function saveEcoReceiverRole(receiverCallback){
                  if(self.receiverShape && !self.receiverShapeMappingExists){
                      self.mapReceiverShape(bm,selectedVDMPackage,selectedVP,vpReceiver,function(){
                        receiverCallback();
                      });
                  }else {
                    receiverCallback();
                  }
                }
                saveEcoProviderRole(function(){
                  saveEcoReceiverRole(function(){
                    saveEcoVp();
                  });
                });
			//function saveEcoVp(){*/
				var providerRole;
				if (self.selectedProviderRole()) {
					providerRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: vpProviderRole.id });
				}
				function setDiagramId(vp){
					diagramBO.set('vdml:mid', vp.id);
					diagramBO.set('vdml:mpid', selectedVDMPackage.id);
					self.model.get('valueProposition').add(vp);
					if (self.parentView.chartViewInstance && self.parentView.chartViewInstance.initialize) {
						var valueList = [];
						_.each(self.parentView.model.get('valueProposition').models, function (vp) {
							valueList = valueList.concat(vp.get('component').models.concat());
						});
						self.parentView.chartViewInstance.initialize(self.parentView.model, true, self.parentView, valueList);
					}
					if (!diagramBO.get('name') || diagramBO.get('name') === '') {
						diagramBO.set('name', vp.get('name'));
					}
					callback && callback(vdmlPackageRevision);
				}
				if (!self.myProposition()) {
					var vpExists = bm.get('bmValueProposition').findWhere({'valueProposition':selectedVP});
					var receiverRole;
					if (self.selectedReceiverRole()) {
						receiverRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: vpReceiverRole.id });
					}
					if(vpExists){
						vp = selectedVP;
						setDiagramId(vp);
					}else{
						if(selectedVP){
							bm.addBMValueProposition(selectedVP, VPName, providerRole, receiverRole,VPDescription,self.currentAlternative,function(bmVP){
								vp = bmVP.get('valueProposition');
								setDiagramId(vp);
							});
						}else{
							bm.addBMValueProposition(null, VPName, providerRole, receiverRole,VPDescription,self.currentAlternative,function(bmVP){
								vp = bmVP.get('valueProposition');
								setDiagramId(vp);
							});
						}
					}
				}else{
					var vfExists = bm.get('bmValueFormula').findWhere({'valueFormula':selectedVP});
					if(vfExists){
						vp = selectedVP;
						setDiagramId(vp);
					}else{
						if(selectedVP){
							bm.addBMValueFormula(selectedVP, VPName, providerRole,VPDescription,self.currentAlternative,function(bmVF){
							vp = bmVF.get('valueFormula');
							setDiagramId(vp);
							});
						}else{
							bm.addBMValueFormula(null, VPName, providerRole,VPDescription,self.currentAlternative,function(bmVF){
							vp = bmVF.get('valueFormula');
							setDiagramId(vp);
							});
						}
					}
				}
			//} 
            }    
        };
        
        this.createRevisionBasedOnContext = function(pack,createRevision,reviCallback){
            if (window.checkContextForRevision() || createRevision) {
                var vdm = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: DataManager.getDataManager().get('viewAlternative') + window.utils.getSuffix(pack.id) });
                if (vdm) {
                    reviCallback(vdm);
                } else {
                    window.utils.startSpinner('revisionSpinner', "Creating a copy...");
                    function fetchBmModel(newVdmPackage) {
                        if (newVdmPackage) {
                            DataManager.getDataManager().releaseSaveLock();
                            DataManager.getDataManager().set('isActive', false);
                            reviCallback(newVdmPackage);
                            /*DataManager.getDataManager().saveData({
                                success: function () {
                                    reviCallback(newVdmPackage);
                                },
                                error: function () {
                                    reviCallback(newVdmPackage);
                                },
                                persist: true
                            });*/
                        }
                    }
                    window.setTimeout(function () {
                        DataManager.getDataManager().acquireSaveLock(function () {
                            pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                        });
                    }, 100); 
                }
            }
            else {
                reviCallback(pack);
            }
        };
        
        this.mapOtherShapes = function(pack,bm,shapeType,participantType,relType,pn,part,selectedRole,diagBO,partDescription,roleDescription,callback){
            saveMapObjects(pack,bm,shapeType,participantType,relType,pn,part,selectedRole,diagBO,partDescription,roleDescription,callback);
            
            function saveMapObjects(pack,bm,shapeType,participantType,relType,pn,part,selectedRole,diagBO,partDescription,roleDescription,callback){
                participantType = participantType.replace(/_/g, '.');
                var networkModel,partner,customer,partType,typeName;
                var networkName = pn ? pn.get('name') : $("#selectPN" + self.encodeId).val().trim();
                var participantName = part? part.get('name') : $('#selectParticipant' + self.encodeId).val().trim();
                var roleName = selectedRole? selectedRole.get('name') : $('#selectRole'+self.encodeId).val().trim();
                if(shapeType.name === "Role"){
                    if(participantType){
                        partType = Backbone.Relational.store.getObjectByName(participantType);
                        if(participantType === "vdml.Community" || participantType === "vdml.OrgUnit"){
                            typeName = "collaboration";
                        }else{
                            typeName = "actor";
                        }                         
                    }
                }else if(shapeType.name === "Individual"){
                     partType = Backbone.Relational.store.getObjectByName(shapeType.type);
                     typeName= "actor";
                }else{
                    partType = Backbone.Relational.store.getObjectByName(shapeType.type);
                    typeName = "collaboration";
                }
                
                function saveShapeObjects(networkModel,callback){
                    if(relType === 'Partner'){
                        var bmPartner = part?bm.get('bmNetworkPartner').findWhere({'networkPartner':part}):null;
                        var desc = partDescription?partDescription:participantName;
                        if(!bmPartner && !part){
                             bm.createNewPartner(participantName, desc,partType, part, networkModel,self.currentAlternative,function(bmPartner){
                              saveRemainingObjects(networkModel,null,bmPartner);
                            });
                            
                        }else{
                          if(part && !bmPartner){
                            bmPartner = bm.createBmNetworkPartner(participantName, desc, part);
                          }
                            saveRemainingObjects(networkModel,null,bmPartner);
                        }
                    }else if(relType === 'Customer'){
                        var bmCustomer = part?bm.get('bmCustomer').findWhere({'customer':part}):null;
                        var desc = partDescription?partDescription:participantName;
                        if(!bmCustomer && !part){
                            bm.createNewCustomer(participantName, desc,partType, part, networkModel,self.currentAlternative,function(bmCustomer){
                              saveRemainingObjects(networkModel,bmCustomer,null);
                            });
                        }else{
                            if(part && !bmCustomer){
                            bmCustomer = bm.createBmCustomer(participantName, desc, part);
                          }
                            saveRemainingObjects(networkModel,bmCustomer,null);
                        }
                    }else {
                      saveRemainingObjects(networkModel,null,null);
                    }
                  function saveRemainingObjects(networkModel,customer,partner){
                    if (partner && networkModel) {
                    
                        if(!selectedRole && roleName){
                          var desc = roleDescription?roleDescription:roleName;
                            bm.addNewPartnerRole(partner, roleName, desc, networkModel,self.currentAlternative,function(assignment){
                              var role = assignment.get('assignedRole');
                              EcoMapMixin.setPartnerDiagramId(self.model, bm, diagBO, partner,role, callback);
                              if(self.updateVPBasedOnTheRecipientMapping){
                                  self.updateVPBasedOnTheRecipientMapping.set('recipient',role);
                              }
                            });
                        }else if(selectedRole){
                            bm.get('bmRole').add(selectedRole);
                            partner.get('networkPartnerRole').add(selectedRole);
                            bm.checkRoleExistsForCustomer(partner.get('networkPartner'),selectedRole,true);
                            EcoMapMixin.setPartnerDiagramId(self.model, bm, diagBO, partner,selectedRole, callback);
                            if(self.updateVPBasedOnTheRecipientMapping){
                                self.updateVPBasedOnTheRecipientMapping.set('recipient',selectedRole);
                            }
                        }
                        
                    }
                    else if (customer && networkModel) {

                        if(!selectedRole && roleName){
                            var desc = roleDescription?roleDescription:roleName;
                            bm.addNewCustomerRole(customer, roleName, desc, networkModel,self.currentAlternative,function(assignment){
                              var role = assignment.get('assignedRole');
                              EcoMapMixin.setCustomerDiagramId(self.model, bm, diagBO, customer,role, callback);
                              if(self.updateVPBasedOnTheRecipientMapping){
                                  self.updateVPBasedOnTheRecipientMapping.set('recipient',role);
                              }
                            });
                        }else if(selectedRole){
                            bm.get('bmRole').add(selectedRole);
                            customer.get('customerRole').add(selectedRole);
                            bm.checkRoleExistsForPartner(customer.get('customer'),selectedRole,true);
                            EcoMapMixin.setCustomerDiagramId(self.model, bm, diagBO, customer, selectedRole, callback);
                            if(self.updateVPBasedOnTheRecipientMapping){
                                self.updateVPBasedOnTheRecipientMapping.set('recipient',selectedRole);
                            }
                        }
                        
                    }
                    else if(!partner && !customer && networkModel && relType === 'Business'){
                      if(!part){
                            bm.get('business').set('name',participantName);    
                            var businessDesc = diagBO.description?diagBO.description:participantName;
                            bm.get('business').set('description',businessDesc);
                        }
                        var participant = bm.get('business');
                        
                        if(!selectedRole && roleName){
                          var desc = roleDescription?roleDescription:roleName;
                            bm.addNewRoleToBusiness(participant, roleName, desc, networkModel,self.currentAlternative,function(assignment){
                              var role = assignment.get('assignedRole');
                              EcoMapMixin.setBusinessDiagramId(self.model, bm, diagBO, participant, role,callback);
                              if (self.updateVPBasedOnTheRecipientMapping) {
                                  self.updateVPBasedOnTheRecipientMapping.set('recipient', role);
                              }
                            });
                        }else if(selectedRole){
                            bm.get('bmRole').add(selectedRole);
                            EcoMapMixin.setBusinessDiagramId(self.model, bm, diagBO, participant,selectedRole,callback);
                            if (self.updateVPBasedOnTheRecipientMapping) {
                                self.updateVPBasedOnTheRecipientMapping.set('recipient', role);
                            }
                        }
                    }
                  }
                }
                
                if(pn){
                    var pnFoundInBM = bm.get('participantNetwork').findWhere({'collaboration':pn});
                    if(pnFoundInBM){
                        saveShapeObjects(pnFoundInBM,callback);
                    }else{
                        bm.createParticipantNetwork(networkName, pn,self.currentAlternative,function(networkModel){
                          var OrgUnit = Backbone.Relational.store.getObjectByName('vdml.OrgUnit');
                          if(!(partType === OrgUnit && relType === 'Business') || (!bm.get('business'))){
                              networkModel.get('collaboration').createAndAssignDefaultRole(bm);
                          }
                          saveShapeObjects(networkModel,callback);
                        });
                    }
                }else{
                    bm.createParticipantNetwork(networkName,null,self.currentAlternative,function(networkModel){
                      var OrgUnit = Backbone.Relational.store.getObjectByName('vdml.OrgUnit');
                      if(!(partType === OrgUnit && relType === 'Business') || (!bm.get('business'))){
                          networkModel.get('collaboration').createAndAssignDefaultRole(bm);
                      }
                      saveShapeObjects(networkModel,callback);
                    });
                }
           }
        };
        
        this.checkForEcoMapRevision = function(revCallback){
             //if(self.checkAddMappingDuplicates()){
                var ecoMapPackage = self.parentView.model.getNestedParent();
                self.createRevisionBasedOnContext(ecoMapPackage,false,function(newEcoMapPackage){
                    var altId = DataManager.getDataManager().getRepositoryId(newEcoMapPackage.id);
                    var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
                    if(altId != oldAltId){
                        var modelId = window.utils.getSuffix(self.parentView.model.id);
                        var ecoMap = newEcoMapPackage.get('diagrams').findWhere({'id':altId+modelId});
                        self.parentView.model = ecoMap;
                        self.model = ecoMap;
                        window.utils.stopSpinner('revisionSpinner');
                        revCallback(ecoMap, newEcoMapPackage);
                    }else{
                        revCallback(null);
                    }                    
                });
             /* } else {
                revCallback(null);
             } */
        };
        this.checkIfVDMPackageIsDependentOfEcoMap = function(viewAltId, revisionEcoMapPackage, pack){
            var newPackId = viewAltId + window.utils.getSuffix(pack.id);
            var depPacksList = [];
            var ecoDepPacks = revisionEcoMapPackage.get('dependentPackage');
            function getDepPacks(depPacks) {
                depPacks.each(function (depPack) {
                    if (_.find(depPacksList, function (dpack) { return dpack === depPack; })) {
                        return;
                    }
                    depPacksList.push(depPack);
                    var depPackDependencies = depPack.get('dependentPackage');
                    for (var j = 0; j < depPackDependencies.length; j++) {
                        if (depPackDependencies.at(j).get('type') !== "ecomap_EcoMap" && !_.find(depPacksList, function (dpack) { return dpack === depPackDependencies.at(j); })) {
                            depPacksList.push(depPackDependencies.at(j));
                            getDepPacks(depPackDependencies.at(j).get('dependentPackage'));
                        }
                    }
                });
            }
            getDepPacks(ecoDepPacks);
            depPacksList = _.uniq(depPacksList);
            var packageRevised = _.find(depPacksList, function (dpack) { return dpack.id === newPackId; });
            //var packageRevised = revisionEcoMapPackage.get('dependentPackage').findWhere({ id: newPackId});
            return packageRevised;
        };
        
        this.checkAddMappingDuplicatesForOthers = function(callback){
            var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMOther.id });
            var part = Backbone.Relational.store.getObjectByName('vdml.Participant').find({ id: self.selectedParticipant.id });
            var role = Backbone.Relational.store.getObjectByName("vdml.Role").find({ id: self.selectedRole.id });
            var roleExistsInBM = bm.get('bmRole').findWhere({ 'id': role.id });
            if (self.selectedRelType() === "Partner") {
                var partner = bm.get('bmNetworkPartner').findWhere({ 'networkPartner': part });
                if (partner && roleExistsInBM && partner.get('networkPartnerRole').findWhere({ 'id': role.id })) {
                    bootbox.alert("No new updates");
                } else {
                    callback()
                }
            } else if (self.selectedRelType() === "Customer") {
                var customer = bm.get('bmCustomer').findWhere({ 'customer': part });
                if (customer && roleExistsInBM && customer.get('customerRole').findWhere({ 'id': role.id })) {
                    bootbox.alert("No new updates");
                } else {
                    callback();
                }
            } else if (self.selectedRelType() === "Business") {
                var business = bm.get('business');
                if (roleExistsInBM && business.id === part.id) {
                    bootbox.alert("No new updates");
                } else {
                    callback();
                }
            }
        };
        
        this.hideAndCallback = function(revisionEcoMap, vdmlPackageRevision){
          if(!revisionEcoMap && !vdmlPackageRevision && !self.parentView.enableComplete() && self.mappedDiagramBO) {
              //Do Nothing
          }else {
            /*self.eventBus.fire('elements.changed', {
                  elements: [options.shape]
              });*/
			var shape = options.shape;
			shape.businessObject.ignoreSync = true;
			self.parentView.modeler.get('modeling').updateLabel(shape, shape.businessObject.name);
          }
            modelElement.modal("hide");
            if(self.parentView){
                self.parentView.saveDiagram(null,null,null,revisionEcoMap, vdmlPackageRevision, self.parentView.enableComplete());
            }
            if(self.callback){
                self.callback(self.model, revisionEcoMap);
            }
        };
        this.hideWizard = function(){
			modelElement.modal("hide");
			window.cleanViewModel(this);
        };
		
        this.handleWizardButtons = function (){
            self.$wizard.on('finished.fu.wizard', function (e, data) {
                if(!self.selectedShapeType){
                    self.hideWizard();
                    return;
                }
                var bm;
                if (self.selectedShapeType.name === "Business Model") {
                    function replaceBMAndBusiness(newPackage){
                        var selectedBm = self.selectedMappingBM;
                        var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
                        if(self.selectedMappingBM && self.selectedMappingBM instanceof Backbone.Model) {
                            var bmId = window.utils.getSuffix(self.selectedMappingBM.id);
                            self.selectedMappingBM.set('id',altId+bmId);
                        }
                        if(self.selectedBusiness && self.selectedBusiness instanceof Backbone.RelationalModel) {
                            var businessSuffixId = window.utils.getSuffix(self.selectedBusiness.id);
                            self.selectedBusiness = Backbone.Relational.store.getObjectByName('vdml.OrgUnit').find({ id: altId + businessSuffixId});
                        }
                    }
                    if(self.mappedDiagramBO){
                        self.hideAndCallback();
                        return;
                    }
                    var mappedElementName = self.selectedMappingBM ? self.selectedMappingBM.name : $('#selectBM' + self.encodeId).val().trim();
                    var businessName = self.selectedBusiness? self.selectedBusiness.get('name') : $('#selectBusiness' + self.encodeId).val().trim();
                        self.checkForEcoMapRevision(function(revisionEcoMap, revisionEcoMapPackage){
                            var viewAltId = DataManager.getDataManager().get('viewAlternative');
                            if(revisionEcoMapPackage && self.selectedVDMPackage){
                                var replacePackage = self.checkIfVDMPackageIsDependentOfEcoMap(viewAltId, revisionEcoMapPackage, self.selectedVDMPackage); 
                                if(replacePackage){
                                    self.selectedVDMPackage = replacePackage;
                                }
                            }
                            if(self.selectedVDMPackage && self.selectedVDMPackage.id && DataManager.getDataManager().getRepositoryId(self.selectedVDMPackage.id) !== viewAltId && !self.selectedMappingBM){
                                self.createRevisionBasedOnContext(self.selectedVDMPackage,true,function(newPackage){
                                    replaceBMAndBusiness(newPackage);
                                    self.mapBusinessModel(self.selectedMappingBM,mappedElementName,newPackage,diagramBO,self.selectedBusiness, businessName,function(){
                                      window.utils.stopSpinner('revisionSpinner');
                                      self.hideAndCallback(revisionEcoMap, true);
                                    });
                                });
                            }else if(replacePackage){
                                replaceBMAndBusiness(self.selectedVDMPackage);
                                self.mapBusinessModel(self.selectedMappingBM,mappedElementName,self.selectedVDMPackage,diagramBO,self.selectedBusiness, businessName,function(){
                                    self.hideAndCallback(revisionEcoMap, true);
                                });     
                            }else {
                              DataManager.getDataManager().getWorkspaceDataWithId(DataManager.getDataManager().get('viewAlternative'),function(wsData){
                      var currentWorkspace = wsData.get('workspace');
                      var previousWorkspace = DataManager.getDataManager().getWorkspace();
                      DataManager.getDataManager().setWorkspace(currentWorkspace,function(){
                                    self.mapBusinessModel(self.selectedMappingBM,mappedElementName,self.selectedVDMPackage,diagramBO,self.selectedBusiness, businessName,function(){
                                      DataManager.getDataManager().setWorkspace(previousWorkspace,function(){
                          self.hideAndCallback(revisionEcoMap);
                        });
                                    });
                                });
                            }); 
                            }
                        });
                }else if(self.selectedShapeType.name === "Value Proposition"){
                    if (self.mappedDiagramBO && self.selectedBMVP && self.selectedVP) {
                        var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP.id });
                        var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedVP.get('id') });
                        if (bm.get('bmValueProposition').findWhere({ 'valueProposition': vp })) {
                            bootbox.alert("No new updates");
                        }else{
                            mapVPAllowed();
                        }
                    }else {
                        mapVPAllowed();                        
                    }
                    function mapVPAllowed() {
                        self.checkForEcoMapRevision(function (revisionEcoMap, revisionEcoMapPackage) {
                            self.mapVP(diagramBO, function (vdmlPackageRevision) {
                                self.hideAndCallback(revisionEcoMap, vdmlPackageRevision);
                            }, revisionEcoMapPackage);
                        });
                    }
                }else{
                  var bm, bmName, pack, business, businessName,pn, participant, role;
                    function isStructureChanged(){
                        //if shape is mapped to an existing participant, which out changing structure in BM, returns false
                        if(self.selectedBMOther &&  self.selectedPN && self.selectedParticipant && self.selectedRole){
                            var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:self.selectedBMOther.id});
                            var part = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id: self.selectedParticipant.id});
                            var role = Backbone.Relational.store.getObjectByName("vdml.Role").find({ id: self.selectedRole.id });
                            if(self.selectedRelType() === "Partner"){
                                var partner = bm.get('bmNetworkPartner').findWhere({'networkPartner':part});
                                if(partner && partner.get('networkPartnerRole').findWhere({'id':role.id})){
                                    return false;
                                }else{
                                    return true;
                                }
                            }else if(self.selectedRelType() === "Customer"){
                                var customer = bm.get('bmCustomer').findWhere({'customer':part});
                                if(customer && customer.get('customerRole').findWhere({'id':role.id})){
                                    return false;
                                }else{
                                    return true;
                                }
                            }else if(self.selectedRelType() === "Business"){
                                var business = bm.get('business');
                                if(business.id === part.id){
                                    return false;
                                }else{
                                    return true;
                                }
                            }
                        }else{
                            return true;
                        }
                    }
                    function replaceBMPartAndRole(newPackage, revisionEcoMap, bm, vdmlPackageRevision){
                      var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
                        if(self.selectedBMOther){
                            var bmSuffix = window.utils.getSuffix(self.selectedBMOther.id);
                            bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:altId+bmSuffix});
                        }
                        if(self.selectedPN) {
                            var pnSuffix = window.utils.getSuffix(self.selectedPN.id);
                            pn = Backbone.Relational.store.getObjectByName('vdml.BusinessNetwork').find({ id: altId + pnSuffix});
                        }
                        if(self.selectedParticipant) {
                            var part = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id: self.selectedParticipant.id});
                            var partType = 'vdml.Community';
                            var partSuffix = window.utils.getSuffix(part.id);
                            if(part.get('type') === 'vdml_OrgUnit'){
                                partType = 'vdml.OrgUnit';
                            }else if(part.get('type') === 'vdml_Actor'){
                                partType = 'vdml.Actor';
                            }
                            participant = Backbone.Relational.store.getObjectByName(''+partType).find({ id: altId + partSuffix });
                        }
                        if(self.selectedRole) {
                            var roleSuffix = window.utils.getSuffix(self.selectedRole.id);
                            role = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: altId + roleSuffix });
                        }
                        if(self.updateVPBasedOnTheRecipientMapping){
                            var vpSuffix = window.utils.getSuffix(self.updateVPBasedOnTheRecipientMapping.id);
                            self.updateVPBasedOnTheRecipientMapping = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: altId + vpSuffix });
                        }
                        handleParticipantsAndRoles(bm, pn, participant, role, revisionEcoMap, vdmlPackageRevision);
                    }
                    var viewAltId = DataManager.getDataManager().get('viewAlternative');
                    pack = self.selectedVDMPackage;
                    bmName = $('#selectBMOther' + self.encodeId).val();
                    business = self.selectedBusinessOther;
                    businessName = self.selectedBusinessOther? self.selectedBusinessOther.get('name') : $('#selectBusinessOther'+self.encodeId).val();
                    self.checkForEcoMapRevision(function(revisionEcoMap, revisionEcoMapPackage){
                        if(revisionEcoMapPackage && pack){
                            var replacePackage = self.checkIfVDMPackageIsDependentOfEcoMap(viewAltId, revisionEcoMapPackage, pack); 
                            if(replacePackage){
                                pack = replacePackage;
                            }
                        }
                        function replaceObject(type,id) {
                            var obj = Backbone.Relational.store.getObjectByName("" + type).find({ id: self.currentAlternative.id + window.utils.getSuffix(id) });
                            if (!obj){
                                obj = Backbone.Relational.store.getObjectByName("" + type).find({ id: id });
                            }
                            return obj;
                        }
                        if(self.selectedBMOther){
                            if(pack && pack.id && DataManager.getDataManager().getRepositoryId(pack.id) !== viewAltId && isStructureChanged()){
                                self.createRevisionBasedOnContext(pack,true,function(newPackage){
                                    replaceBMPartAndRole(newPackage, revisionEcoMap, null, true);
                                    window.utils.stopSpinner('revisionSpinner');                                 
                                });
                            }else if(replacePackage){
                                replaceBMPartAndRole(pack, revisionEcoMap, null, true);
                            }else{
                                //bm = BusinessModel.find({ id: self.selectedBMOther.id });
                                bm = replaceObject("vdml.BusinessModel", self.selectedBMOther.id);
                                if(self.selectedPN){
                                    //pn = Backbone.Relational.store.getObjectByName("vdml.BusinessNetwork").find({ id: self.selectedPN.id });
                                    pn = replaceObject("vdml.BusinessNetwork", self.selectedPN.id);
                                }
                                if(self.selectedParticipant){
                                    //participant = Participant.find({ id: self.selectedParticipant.id });
                                    participant = Backbone.Relational.store.getObjectByName('vdml.Participant').find({ id: self.currentAlternative.id + window.utils.getSuffix(self.selectedParticipant.id) });
                                    if (!participant) {
                                        participant = Backbone.Relational.store.getObjectByName('vdml.Participant').find({ id: self.selectedParticipant.id });
                                    }
                                }
                                if(self.selectedRole){
                                    //role = Backbone.Relational.store.getObjectByName("vdml.Role").find({ id: self.selectedRole.id });
                                    role = replaceObject("vdml.Role", self.selectedRole.id);
                                }
                                var vdmlPackRevision = false;
                                if (window.checkContextForRevision() && revisionEcoMap && revisionEcoMap.getParent().get('dependentPackage').length > 0){
                                    vdmlPackRevision = true;
                                }
                                handleParticipantsAndRoles(bm, pn, participant, role, revisionEcoMap, vdmlPackRevision);
                            }
                        }else{
                            if(pack && pack.id && DataManager.getDataManager().getRepositoryId(pack.id) !== viewAltId ){
                                self.createRevisionBasedOnContext(pack,true,function(newPackage){
                                        self.mapBusinessModel(null, bmName, replacePackage, null, business, businessName,function(bm){
                                        replaceBMPartAndRole(newPackage, revisionEcoMap, bm, true);
                                        window.utils.stopSpinner('revisionSpinner');
                                    });
                                    
                                });
                            } else if (replacePackage) {
                                if (business) {
                                    business = replaceObject("vdml.OrgUnit", business.id);
                                }
                                bm = self.mapBusinessModel(null, bmName, replacePackage, null, business, businessName,function(bm){
                                    replaceBMPartAndRole(pack, revisionEcoMap, bm, true);
                                    });                                    
                            } else {
                                if (business && revisionEcoMapPackage) {
                                    business = replaceObject("vdml.OrgUnit", business.id);
                                }
                                self.mapBusinessModel(null, bmName, pack, null, business, businessName, function (bm) {
                                    if (self.selectedPN) {
                                        pn = replaceObject("vdml.BusinessNetwork", self.selectedPN.id);
                                        //pn = Backbone.Relational.store.getObjectByName("vdml.BusinessNetwork").find({ id: self.currentAlternative.id + window.utils.getSuffix(self.selectedPN.id) });
                                    }
                                    if (self.selectedParticipant) {
                                        participant = Backbone.Relational.store.getObjectByName('vdml.Participant').find({ id: self.currentAlternative.id + window.utils.getSuffix(self.selectedParticipant.id) });
                                        if (!participant){
                                            participant = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id: self.selectedParticipant.id });
                                        }
                                    }
                                    if (self.selectedRole) {
                                        role = replaceObject("vdml.Role", self.selectedRole.id);
                                        //role = Backbone.Relational.store.getObjectByName("vdml.Role").find({ id: self.currentAlternative.id + window.utils.getSuffix(self.selectedRole.id) });
                                    }
                                    var vdmlPackRevision = false;
                                    if (window.checkContextForRevision() && revisionEcoMap && revisionEcoMap.getParent().get('dependentPackage').length > 0) {
                                        vdmlPackRevision = true;
                                    }
                                    handleParticipantsAndRoles(bm, pn, participant, role, revisionEcoMap, vdmlPackRevision);
                                });
                                
                            } 
                        }
                    });
                    function handleParticipantsAndRoles(bm, pn, participant, role, revisionEcoMap, vdmlPackageRevision){
                        if(self.selectedShapeType.name === "Market Segment" || self.selectedShapeType.name === "Enterprise" || self.selectedShapeType.name === "Individual"){
                            if (self.mappedDiagramBO && self.selectedBMOther && self.selectedPN && self.selectedParticipant && self.selectedRole){
                                self.checkAddMappingDuplicatesForOthers(mapParticipantAllowed);
                            } else{
                                mapParticipantAllowed();
                            }
                            function mapParticipantAllowed() {
                                var partDescription = diagramBO.description;
                                self.mapOtherShapes(bm.getNestedParent(), bm, self.selectedShapeType, self.selectedShapeType.type, self.selectedRelType(), pn, participant, role, diagramBO, partDescription, null, function () {
                                    self.hideAndCallback(revisionEcoMap, vdmlPackageRevision);
                                });
                            }
                        }else if(self.selectedShapeType.name === "Role"){
                            if (self.mappedDiagramBO && self.selectedBMOther && self.selectedPN && self.selectedParticipant && self.selectedRole){
                                self.checkAddMappingDuplicatesForOthers(mapRoleAllowed);
                            } else{
                                mapRoleAllowed();
                            }
                            function mapRoleAllowed() {
                                var roleDescription = diagramBO.description;
                                if (!self.selectedParticipant) {
                                    self.mapOtherShapes(bm.getNestedParent(), bm, self.selectedShapeType, self.selectedPartType().type, self.selectedRelType(), pn, participant, role, diagramBO, null, roleDescription, function () {
                                        self.hideAndCallback(revisionEcoMap, vdmlPackageRevision);
                                    });
                                } else {
                                    self.mapOtherShapes(bm.getNestedParent(), bm, self.selectedShapeType, participant.get('type'), self.selectedRelType(), pn, participant, role, diagramBO, null, roleDescription, function () {
                                        self.hideAndCallback(revisionEcoMap, vdmlPackageRevision);
                                    });
                                }
                            }
                        }
                    }
                }
            });

            self.$wizard.on('clicked.fu.wizard.action',function(e,data){
                if(!self.selectedShapeType){
                    self.hideWizard();
                    return;
                }
                if (self.selectedShapeType.name === "Business Model") {
                    self.checkAndManageIfInflowIsAMappedVP();
                    self.checkAndManageIfOutflowIsAMappedVP();
                    fillPackagesBM();
                    if(diagramBO.get('name')){
                        $('#selectBM' + self.encodeId).val(diagramBO.get('name'));
                    }
                    self.enableCompleteForBM();
                } else
                    if ((self.selectedShapeType.name === 'Market Segment' || self.selectedShapeType.name === 'Enterprise' ||  self.selectedShapeType.name === 'Individual')) {
                        fillPackagesOther();
                        if(self.selectedShapeType.name === 'Enterprise' && self.relationTypesList.indexOf('Business') === -1){
                            self.relationTypesList.push("Business");
                        }
                        if(self.mappedDiagramBO){   
                            var shapeName = self.mappedDiagramBO.get('name');
                            if (self.mappedDiagramBO.get('mid')) {
                                var partFound = _.filter(self.participantsColl.models, function (obj) { return obj.get('id') === self.mappedDiagramBO.get('mid') });
                            } else {
                                var partFound = _.filter(self.participantsColl.models, function (obj) { return obj.get('name').toLowerCase() === self.mappedDiagramBO.get('name').toLowerCase() });
                            }
                            if(partFound && partFound.length){
                                self.selectedParticipant = partFound[0];
                                shapeName = partFound[0].get('name');
                                self.onChangeSelectedParticipant();
                            }
                            $('#selectParticipant' + self.encodeId).val(shapeName);
                            $('#selectParticipant'+self.encodeId).attr('disabled','disabled');
                        }else{
                            self.checkAndManageIfInflowIsAMappedVP();
                            self.checkAndManageIfOutflowIsAMappedVP();
                            if (diagramBO.get('name')) {
                                var shapeName = diagramBO.get('name');
                                if (diagramBO.get('mid')) {
                                    var partFound = _.filter(self.participantsColl.models, function (obj) { return obj.get('id') === diagramBO.get('mid') });
                                } else {
                                    var partFound = _.filter(self.participantsColl.models, function (obj) { return obj.get('name').toLowerCase() === diagramBO.get('name').toLowerCase() });
                                }
                                
                                if(partFound && partFound.length){
                                    self.selectedParticipant = partFound[0];
                                    shapeName = partFound[0].get('name');
                                    self.onChangeSelectedParticipant();
                                }
                                $('#selectParticipant' + self.encodeId).val(shapeName);
                            }
                        } 
                        self.enableCompleteForOtherShapes();
                    } else
                        if (self.selectedShapeType.name === 'Role') {
                            fillPackagesOther();
                            if(!self.mappedDiagramBO && self.relationTypesList.indexOf('Business') === -1){
                                self.relationTypesList.push("Business");
                                $('#selectRole' + self.encodeId).val(diagramBO.get('name'));
                            }
                            if(self.mappedDiagramBO){
                                var role = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.mappedDiagramBO.mid });
                                var assignment = role.get('roleAssignment').at(0);
                                var participant = assignment? assignment.get('participant') : null;
                                var pn = role.get('collaborationRoleOwner');
                                var pnFound = _.filter(self.PNsColl.models,function(obj){return obj.get('name').toLowerCase() === pn.get('name').toLowerCase()});
                                if(pnFound && pnFound.length){
                                    $('#selectPN' + self.encodeId).val(pnFound[0].get('name'));
                                    self.selectedPN = pnFound[0];
                                    self.onChangeSelectedPN();
                                }
                                $('#selectPN' + self.encodeId).attr('disabled', 'disabled');
                                var partFound = _.filter(self.participantsColl.models,function(obj){return obj.get('name').toLowerCase() === participant.get('name').toLowerCase()});
                                if(partFound && partFound.length){
                                    $('#selectParticipant' + self.encodeId).val(partFound[0].get('name'));
                                    self.selectedParticipant = partFound[0];
                                    self.onChangeSelectedParticipant();
                                }
                                $('#selectParticipant' + self.encodeId).attr('disabled', 'disabled');
                                self.enablePartType(false);
                                var roleFound = _.filter(self.rolesColl.models,function(obj){return obj.get('name').toLowerCase() === role.get('name').toLowerCase()});
                                if(roleFound && roleFound.length){
                                    $('#selectRole' + self.encodeId).val(roleFound[0].get('name'));
                                    self.selectedRole = roleFound[0];
                                    self.onChangeSelectedRole();
                                }
                                $('#selectRole' + self.encodeId).attr('disabled', 'disabled');                              
                            }else{
                                self.checkAndManageIfInflowIsAMappedVP();
                                self.checkAndManageIfOutflowIsAMappedVP();
                            }
                            self.enableCompleteForOtherShapes();
                        } else
							if (self.selectedShapeType.name === 'Value Proposition') {
                                fillPackagesVP(function(){
                                    if(!self.mappedDiagramBO){
                                        if(diagramBO.get('name')){
                                            $('#selectVP' + self.encodeId).val(diagramBO.get('name'));
                                            var VPFound = _.filter(self.VPsColl.models,function(obj){return obj.get('shortName').toLowerCase() === diagramBO.get('name').toLowerCase()});
                                            if(VPFound && VPFound.length){
                                                self.selectedVP = VPFound[0];
                                                self.onChangeSelectedVP();
                                            }
                                        }
                                    }
                                    self.enableCompleteForVP();
                                });                                
                            }
            });
            $('#showMappedInfoParticipant'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id:self.selectedParticipant.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoRole'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.Role").find({ id: self.selectedRole.id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoVP'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueProposition").find({ id: self.selectedVP.id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoBM'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedMappingBM.id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoBMOther'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedBMOther.id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoBMVP'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedBMVP.id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoPN'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessNetwork").find({ id: self.selectedPN.id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoPackageBM'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: self.selectedPackageBM().id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoPackageOther'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: self.selectedPackageOther().id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoPackageVP'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: self.selectedPackageVP().id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoBusiness'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id:self.selectedBusiness.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#showMappedInfoBusinessOther'+self.encodeId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id:self.selectedBusinessOther.id});
                self.createDetailsTemplate(mappedElement);
            });
            this.createDetailsTemplate = function(mappedElement){
                var currentAltId = DataManager.getDataManager().get('viewAlternative');
                var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
                var currentAlt = docTypeObj.find({ id: currentAltId });
                var scenario = currentAlt.getMainScenario(mappedElement.getNestedParent());
                var id = window.utils.htmlEscape(window.guidGenerator());
                var options = {};
                options.context = scenario;
                options.mappedModal = mappedElement;
                options.width = '800px';
                var EcoMapDiagramMixin = Backbone.Relational.store.getObjectByName("vdml.EcoMapDiagramMixin");
                window.getAndCreateModalDialog(self.model, id,EcoMapDiagramMixin, self.model, "TargetDetails", null, options);
            }
            this.enableCompleteForOtherShapes = function(){
                if ($('#selectBMOther' + self.encodeId).val().trim() !== "" && $('#selectParticipant' + self.encodeId).val().trim() !== "" && $('#selectPN' + self.encodeId).val().trim() !== "" && $('#selectRole' + self.encodeId).val().trim() !== "") {
                    $('.btn-next').removeAttr('disabled');
                }
                else {
                    $('.btn-next').attr('disabled', 'disabled');
                }
            }
            this.enableCompleteForVP = function(){
                if (self.selectedPackageVP() && $('#selectBMVP' + self.encodeId).val().trim() !== "" && $('#selectVP' + self.encodeId).val().trim() !== "" && self.vpProvider() && self.selectedProviderRole()) {
                    if(!self.selectedReceiverRole() && self.vpReceiver()){
                        $('.btn-next').attr('disabled', 'disabled');
                    }else if(self.selectedReceiverRole() && self.selectedProviderRole().id === self.selectedReceiverRole().id){
                        $('.btn-next').attr('disabled', 'disabled');
                    }else{
                        $('.btn-next').removeAttr('disabled');
                    }
                }/*else if(self.selectedPackageVP() && $('#selectBMVP' + self.encodeId).val().trim() !== "" && self.selectedVP && self.selectedVP.get('planCriterionSetOwner')){
                    $('.btn-next').removeAttr('disabled');
                }*/else {
                    $('.btn-next').attr('disabled', 'disabled');
                }
            }
            this.enableCompleteForBM = function(){
                if ($('#selectBM' + self.encodeId).val().trim() !== "") {
                    if(self.providerVPShape || self.receiverVPShape){
                        if(!self.selectedMappingBM || !self.selectedPackageBM()){
                            $('.btn-next').attr('disabled', 'disabled');
                        }else{
                            $('.btn-next').removeAttr('disabled');
                        }
                    }else{
                        $('.btn-next').removeAttr('disabled');
                    }
                }
                else {
                    $('.btn-next').attr('disabled', 'disabled');
                }
            }
            this.checkDuplicatesPath = function(models,element,path){
                for(var i=0;i<models.length;i++){
                    if((element.get('name')===models[i].get('name') && (element.get('id')!==models[i].get('id')))){
                        for(var k=0;k<element.getPackagePath().length-1;k++){
                            path = path.concat(" ["+element.getPackagePath()[k].name);
                            if(element.getPackagePath()[k+2]){
                                path = path.concat("/");
                            }
                        }
                    path=path.concat("]");
                    break;
                    }
                }
              return path;
           };
           this.getCurrentAlt = function(){
                var phase = DataManager.getDataManager().get('currentPlan').get('phase');
                var alternative = DataManager.getDataManager().get('currentWorkspace').get("id");
                var tempAlt = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative') : alternative;
                for (var j = 0; j < phase.length; j++) {
                    var alts = phase.at(j).get('phaseAlternative');
                    var viewAlternativeModel = alts.findWhere({ 'id': tempAlt });
                    if (viewAlternativeModel) {
                        self.currentAlternative = viewAlternativeModel;
                        break;
                    }
                }
           }
           self.getCurrentAlt();
        };
    }


    
    static getInstance(model, options,  diagramBO, callback){
		var view = new EcomapMappingWizardViewModel($('#wizardModal'), model, options,  diagramBO, callback);
		//view.init(model, options);
		return view;
	};
}
path.EcomapMappingWizardViewModel = EcomapMappingWizardViewModel;