import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as Muuri  from '../../../../../../libs/muuri/muuri.min'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {DateFormat} from '../../../../../com/vbee/utils/DateFormat'
import { BMCanvasDiagram } from '../../../../bo/canvas/BMCanvasDiagram'
import { BMCanvasDiagramMixin } from '../../../../bo/canvas/BMCanvasDiagramMixin'
import {CanvasDetailsViewModel} from './CanvasDetailsViewModel'
import {BMGuidanceViewModel} from '../../../help/views/properties/BMGuidanceViewModel'
import {ValueDeliveryModelMixin} from '../../../../bo/vdml/ValueDeliveryModelMixin'
import {BMCanvasItem} from '../../../../bo/canvas/BMCanvasItem'
import {MappingWizardViewModel} from './MappingWizardViewModel'
import { ValueElement } from '../../../../bo/vdml/ValueElement'
import { Annotation } from '../../../../bo/vdml/Annotation'
import { BusinessModel } from '../../../../bo/vdml/BusinessModel'
import { Participant } from '../../../../bo/vdml/Participant'
import { CapabilityLibraryMixin } from '../../../../bo/vdml/CapabilityLibraryMixin'
import { EcoMapMixin } from '../../../../bo/ecomap/EcoMapMixin'

//define(["require", "jquery", "underscore", "muuri", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "appbo/vdml/Annotation", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/canvas/BMCanvasItem", "knockback", "summernote", "appviews/canvas/views/designer/MappingWizardViewModel", "appviews/canvas/views/designer/CanvasDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appcommon/com/vbee/utils/DateFormat", "appbo/canvas/BMCanvasDiagramMixin", "appbo/vdml/CapabilityLibraryMixin", "appbo/vdml/ValueDeliveryModelMixin", "appbo/ecomap/EcoMapMixin", "domtoimage", "colorpicker"],
//    function (require, $, _, Muuri, Backbone, ko, bootbox, DataManager, Annotation, global, BusinessModel, Participant, ValueElement, BMCanvasItem, kb, summernote, MappingWizardViewModel, CanvasDetailsViewModel, BMGuidanceViewModel, DateFormat, BMCanvasDiagramMixin, CapabilityLibraryMixin, ValueDeliveryModelMixin, EcoMapMixin) {
var path = DataManager.getDataManager().buildAppNsPath("canvas.views.properties", global.version);
export class CanvasDesignerViewModel{
    constructor(model, options) {
        var self = this;
        this.CanvasDesignerViewModel = this;
        this.options = options;
        this.name = ko.observable("");
        this.CanvasDesignerViewModel = this;
        this.model = model;
        this.modelNotLinked = ko.observable(!model.get('businessModel'));
        //this.enableDelete = ko.observable(true);
        this.children = [];
        this.showReportCanvas = false;
        this.showDashboardCanvas = false;
        //this.slipRevision = [];
        this.enableComplete = ko.observable(false);
        this.showLinkBM = ko.observable(true);
        this.viewLoaded = false;
        this.customeResize = false;
        this.creatingRevision = false;
        this.usedSlipColors = ["rgba(255,255,127,0.5)", "rgba(255,81,156,0.5)", "rgba(70,193,237,0.5)", "rgba(250,119,49,0.5)", "rgba(127,140,255,0.5)"];
        this.svgSmiliesList = window.utils.getSmiliesList();
        this.editMode = DataManager.getDataManager().get('readMode')?false:true;
        if(window.utils.checkIsLinked(null,true)){
            self.editMode = false;
        }
        this.showRHS = ko.observable(true);
        this.canvasPage = true;
        this.documentationArray = [];
        if (options.parentView && options.parentView.SummernoteReportViewModel) {
            self.showReportCanvas = true;
            self.viewAlt = options.viewAlt;
            this.showRHS = false;
            this.canvasPage = false;
        } else if (options.parentView && options.parentView == "Dashboard") {
            self.showDashboardCanvas = true;
            self.viewAlt = options.viewAlt;
            this.editMode = options.editMode;
            self.presentationId = options.presentationId;
            this.showRHS = false;
            this.canvasPage = false;
        }
        this.enableComplete.subscribe(function (val) {
            if (val) {
                $("#linkBM" + self.encodeId).attr('disabled', 'disabled');
                $("#linkBusiness" + self.encodeId).attr('disabled', 'disabled');
            } else {
                $("#linkBM" + self.encodeId).removeAttr('disabled');
                $("#linkBusiness" + self.encodeId).removeAttr('disabled');
            }
        });

        this.encodeId = window.utils.htmlEscape(this.model.id);
        this.capabilityLibs = [];
        this.getViewAlternativeModel();
        this.configObj = self.getConfigObj(true);
        if (self.configObj) {
            this.dimensions = self.configObj.dimensions;
        } else {	//just to handle null values
            this.dimensions = [
                { "id": "dimension1", "name": "Key Partners", "mapping": ["partner"] },
                { "id": "dimension2", "name": "Key Activities", "mapping": ["activity"] },
                { "id": "dimension3", "name": "Key Resources", "mapping": ["competence"] },
                { "id": "dimension4", "name": "Value Propositions", "mapping": ["valueProposition"] },
                { "id": "dimension5", "name": "Customer Relationships", "mapping": ["value", "competence", "activity", "network"] },
                { "id": "dimension6", "name": "Channels", "mapping": ["partner", "competence"] },
                { "id": "dimension7", "name": "Customer Segments", "mapping": ["customer"] },
                { "id": "dimension8", "name": "Cost Structure", "mapping": ["value"] },
                { "id": "dimension9", "name": "Revenue Streams", "mapping": ["value"] }
            ];
        }
        if (self.canvasPage) {
            self.fetchBmCounts();
        }
        this.noOfDimensions = this.dimensions.length;
        //Config end
        this.colls = {};
        this.allDimensionsCollection = ko.observableArray([]);
        for (var i = 1; i <= self.noOfDimensions; i++) {
            self['dimension' + i + 'Collection'] = ko.observableArray([]);
            self.colls['dimension' + i + 'Collection'] = self['dimension' + i + 'Collection'];
        }
        for (var i = 1; i <= self.noOfDimensions; i++) {
            this['dimension' + i + 'Collection'].subscribe(self.collectionSubscriptionHandler.bind(self));
        }
        this.mappingObject = {};
        this.mappingObject['vdml_Community'] = 'Market Segment';
        this.mappingObject['vdml_OrgUnit'] = 'Enterprise';
        this.mappingObject['vdml_Actor'] = 'Individual';
        this.mappingObject['vdml_BusinessItem'] = '' + DataManager.getDataManager().get('localeManager').get('Resource');
        this.mappingObject['vdml_CapabilityOffer'] = '' + DataManager.getDataManager().get('localeManager').get('Capability');
        this.mappingObject['vdml_Activity'] = '' + DataManager.getDataManager().get('localeManager').get('Activity');
        this.mappingObject['vdml_ValueAdd'] = '' + DataManager.getDataManager().get('localeManager').get('value');
        this.mappingObject['vdml_ValuePropositionComponent'] = '' + DataManager.getDataManager().get('localeManager').get('value');
        this.mappingObject['vdml_ValueProposition'] = '' + DataManager.getDataManager().get('localeManager').get('ValueProposition');
        this.mappingObject['vdml_BusinessNetwork'] = '' + DataManager.getDataManager().get('localeManager').get('ParticipantNetwork');
        this.mappingObject['planValue'] = '' + DataManager.getDataManager().get('localeManager').get('PlanValue');
        this.mappingObject['MyProposition'] = '' + DataManager.getDataManager().get('localeManager').get('MyProposition');
        this.mappingObject['vdml_CapabilityMethod'] = 'Capability Method';
        this.mappingObject['vdml_CapabilityCategory'] = '' + DataManager.getDataManager().get('localeManager').get('Activity');
        this.mappingObject['vdml_CapabilityDefinition'] = '' + DataManager.getDataManager().get('localeManager').get('Activity');
        this.mappingObject['Activities'] = '' + DataManager.getDataManager().get('localeManager').get('Activities');
        this.mappingObject['Competencies'] = '' + DataManager.getDataManager().get('localeManager').get('Competencies');
        this.mappingObject['Key Activities'] = '' + DataManager.getDataManager().get('localeManager').get('KeyActivities');
        ko.bindingHandlers.canvasPostIt = {
            init: function (element, valueAccessor) {
                $(element).on('blur', function () {
                    var observable = valueAccessor();
                    if (observable() !== $(this).text()) {
                        self.enableComplete(true);
                    }
                    observable($(this).text());
                });
            },
            update: function (element, valueAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                $(element).text(value);
            }
        };
        //window.utils.loadTemplate(function(name){})
        //canvas details
        if (!ko.components.isRegistered('CanvasDetails')) {
            ko.components.register("CanvasDetails", {
                viewModel: CanvasDetailsViewModel,
                template: window.utils.getHtmlPage("CanvasDetailsTemplate"),
                synchronous: true
            });
        }

        if (!ko.components.isRegistered('CanvasGuidance')) {
            self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
            ko.components.register("CanvasGuidance", {
                viewModel: { instance: self.guidanceViewInstance },
                template: '<div id="guidanceTab"></div>',
                synchronous: true
            });
        }
        this.draggedData = ko.observable();
        for (var i = 1; i <= self.noOfDimensions; i++) {
            self['dropDimension' + i] = self.dropHandler(i);
        }
        for (var i = 1; i <= self.noOfDimensions; i++) {
            self['allowDropDimension' + i] = self.allowDropHandler(i);
        }
        for (var i = 1; i <= self.noOfDimensions; i++) {
            self['addDimension' + i] = self.addDimensionHandler(i);
        }
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name'
            , 'description'
            , 'print'
            , 'Delete'
            , 'Complete'
            , 'Close'
            , 'Details'
            , 'canvasDetails'
            , 'Guidance'
            , 'Collaboration'
            , 'business'
            , 'UseColors'
            , 'Copy'
            , 'LinkedBM'
            , 'linkBM'
            , 'unlinkBM'
            , 'keyPartners'
            , 'keyActivities'
            , 'keyResources'
            , 'ValuePropositions'
            , 'CustomerRelationships'
            , 'Channels'
            , 'CustomerSegments'
            , 'CostStructure'
            , 'RevenueStreams'
            , 'mapItem'
            , 'canvasName'
            , 'dimension1'
            , 'dimension2'
            , 'dimension3'
            , 'dimension4'
            , 'dimension5'
            , 'dimension6'
            , 'dimension7'
            , 'dimension8'
            , 'dimension9'
            , 'dimension10'
            , 'dimension11'
            , 'dimension12'
            , 'dimension13'
            , 'dimension14'
            , 'dimension15'
            , 'dimension16'
            , 'dimension17'
            , 'dimension18'
            , 'dimension19'
            , 'dimension20'
            , 'Documentations'
            , 'Slip'
            , 'Dimension'
            , 'targetElement'
            , 'targetElementType'
        ]);
        this.afterRenderView = _.bind(this.afterRenderView,this);
        this.dragSlip = _.bind(this.dragSlip,this);
        this.enableEdit = _.bind(self.enableEdit,self);
        this.dropHandler = _.bind(this.dropHandler,this);
        this.allowDropHandler = _.bind(this.allowDropHandler,this);
        this.getSlipDetails = _.bind(this.getSlipDetails,this);
    }
    getViewAlternativeModel() {
        var self = this;
        var phase = DataManager.getDataManager().get('currentPlan').get('phase');
        var alternative = DataManager.getDataManager().get('currentWorkspace').get("id");
        var tempAlt = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative') : alternative;
        if (self.viewAlt) {
            tempAlt = self.viewAlt;
        }
        for (var j = 0; j < phase.length; j++) {
            var alts = phase.at(j).get('phaseAlternative');
            var viewAlternativeModel = alts.findWhere({ 'id': tempAlt });
            if (viewAlternativeModel) {
                self.currentAlternativeModel = viewAlternativeModel;
                self.context = self.currentAlternativeModel.getMainScenario(self.model.getNestedParent());
                break;
            }
        }
    };
        //Config start
    getConfigObj() {
        var self = this;
        var canvasType = this.model.get('canvasType');
        if (!canvasType) {
            canvasType = "BusinessCanvas";
        }
        var config = window.utils.getCanvasJson(canvasType);
        if (config) {
            var localeAdd = false;
            var localManager = DataManager.getDataManager().get('localeManager');
            var localejson = localManager.getTranslationsByLocale(localManager.current_locale());
            _.each(config.dimensions, function (dimension) {
                if(!localManager.currentTranslations[dimension.label]) {
                    localeAdd = true;
                    if (dimension.labelValue) {
                        localejson[dimension.label] = { value: dimension.labelValue, c: 1 };
                    } else {
                        localejson[dimension.label] = { value: dimension.name, c: 1 };
                    }
                    localManager.currentTranslations[dimension.id] = localejson[dimension.label];
                    dimension.labelValue = localejson[dimension.label].value;
                } else {
                    localManager.currentTranslations[dimension.id] = localManager.currentTranslations[dimension.label];
                    dimension.labelValue = localManager.currentTranslations[dimension.label].value;
                }
            });
            if(localeAdd){
                _.each(config.bmMappings, function (mapping) {
                    if (mapping.label && mapping.name) {
                        localejson[mapping.label] = { value: mapping.name, c: 1 };
                    }
                });
            }
            return config;
        } else {
            return null;
        }
    }
    fetchBmCounts() {
        var self = this;
        if (self.configObj && self.configObj.bmMappings) {
            DataManager.getDataManager().get('currentPlan').loadCapabilityLibraries(function (capabilityLibraries) {
                self.capabilityLibs = capabilityLibraries;
            });
        }
    }
    adjustSlipsTable() {
        var self = this;
        for (var i = 0; i < self.dimensions.length; i++) {
            if (!self.dimensions[i].mapping) {
                $('.hiddenTableCols').hide();
            } else {
                $('.hiddenTableCols').show();
                break;
            }
        }
    }

    sortSlipsInTableByName(left, right) {
        var self = this;
        return left.name().toLowerCase() == right.name().toLowerCase() ? 0 : (left.name().toLowerCase() < right.name().toLowerCase() ? -1 : 1);
    }
    sortBMs(left, right) {
        var self = this;
        return left.get('name').toLowerCase() == right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1);
    }
    sortCheckBoxes(a, b) {
        var self = this;
        var $a = $(a).find(':checkbox'),
            $b = $(b).find(':checkbox');
        if ($a.val() && $b.val()) {
            return $a.val().toLowerCase() == $b.val().toLowerCase() ? 0 : ($a.val().toLowerCase() < $b.val().toLowerCase() ? -1 : 1);
        }
    }
    collectionSubscriptionHandler(changes) {
        var self = this;
        if (!self.creatingRevision && self.canvasPage) {
            changes.forEach(function (data) {
                var item = self.checkIdAlreadyExist(data.dataGuid, self.allDimensionsCollection());
                if (item && !data._destroy) {
                    var index = self.allDimensionsCollection().map(function (el) { return el.dataGuid; }).indexOf(data.dataGuid);
                    self.allDimensionsCollection()[index] = data;
                } else if (item && data._destroy) {
                    self.allDimensionsCollection.remove(data);
                } else {
                    self.allDimensionsCollection.push(data);
                }
            });
            self.allDimensionsCollection.sort(self.sortSlipsInTableByName);
            if (self.viewLoaded && changes && changes.length > 0) {
                self.setResizeHeight();
            }
        }
    }
        
    refreshTemporaryCollections() {
        var self = this;
        for (var i = 1; i <= self.noOfDimensions; i++) {
            self.colls['dimension' + i + 'Collection'].removeAll();
            self.loadDimensionsData('dimension' + i);
        }
        self.syncNameChanges();
        window.utils.applyFontSizeByElement($('.post-it-text'));
    };
    getBMElementType(canvasItem) {
        var self = this;
        //var eleId = canvasItem ? canvasItem.id : "";
        if (canvasItem && canvasItem.id.substr(0, canvasItem.id.lastIndexOf('@') + 1) === window.plansKey) {
            var bmEle = self.mappingObject["planValue"];
        } else if (canvasItem && canvasItem.get('isValueFormula')) {
            bmEle = self.mappingObject["MyProposition"];
        } else {
            bmEle = canvasItem ? self.mappingObject[canvasItem.get('type')] : "";
        }
        return bmEle;
    }

    getName() {
        var self = this;
        var configObj = self.configObj;
        if (configObj) {
            return this.model.get('name') + " (" + configObj.name + ")";
        } else {
            return this.model.get('name');
        }
    }
    linkBusinessModel() {
        var self = this;
        //if (!self.showReportCanvas) {
        var businessModels = self.model.getNestedParent().get('businessModel').models;
        selectBM(businessModels, bootbox);
        //}
        /*else {
            var workspace = DataManager.getDataManager().get('currentWorkspace');
            var businessModels = [];
            DataManager.getDataManager().getAllDocumentsCollectionOfType(workspace, 'vdml_BusinessModel', function (refs) {
                var count = refs.length;
                function getBMInstance() {
                    count--;
                    if (count >= 0 && refs.at(count).get('parent')) {
                        var ref = refs.at(count);
                        var parentId = ref.get('parent');
                        var repId = DataManager.getDataManager().getRepositoryId(parentId);
                        var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                        DataManager.getDataManager().fetchDocumentFromPackage(parentId, "appbo/vdml/ValueDeliveryModel", ref.get('version'), ref.get('id'), "appbo/vdml/BusinessModel", vdmStore, {
                            success: function (model) {
                                //router.DataManager.getDataManager().set('currentPlan',model);
                                businessModels.push(model);
                                getBMInstance();
                            },
                            error: function (error) {
                                console.log(error);
                                getBMInstance();
                            }
                        });
                    } else {
                        if (count >= 0) {
                            getBMInstance();
                        } else {
                            selectBM(businessModels, bootbox);
                        }
                    }
                }
                getBMInstance();
            }, null, null, true);
        }*/

        function selectBM(businessModels, bootbox, BMCanvasDiagram, BMCanvasDiagramMixin) {
            var htmlContent = "<select class=\"form-control\" id=\"selectBM\">";
            htmlContent = htmlContent + "<option bmId=''>" + "Select Business Model ..." + "</option>";
            htmlContent = htmlContent + "</select><p/>";

            bootbox.dialog({
                title: "<div><img class='smallIcon' src='img/icons/icons_14_1.png'>&nbsp;&nbsp;&nbsp;Business Model Selector</div>",
                message: htmlContent,
                buttons: {
                    main: {
                        label: "Close",
                        className: "btn btn-default",
                    },
                    success: {
                        label: '<i id="linkBmCompleteBtn" class="fa fa-check"></i> Complete',
                        className: "btn btn-complete",
                        callback: function () {
                            var selectedBM = $('#selectBM option:selected').attr('bmId');
                            if (selectedBM) {
                                self.saveData(function (revision) {
                                    var model = self.model;
                                    //model.set('lastModified', new Date().getTime());
                                    var selectedBmModel;
                                    for (var i = 0; i < businessModels.length; i++) {
                                        if (businessModels[i].id === selectedBM) {
                                            selectedBmModel = businessModels[i];
                                            break;
                                        }
                                    }
                                    self.model.set('businessModel', selectedBmModel);
                                    if (revision) {
                                        DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + model.getNestedParent().get('version') + "/" + model.get('id') + "/canvas_BMCanvasDiagram/" + DataManager.getDataManager().get('viewAlternative') + "", { trigger: true });
                                    } else {
                                        self.modelNotLinked(false);
                                        if (self.selectedAlternative) {
                                            self.context = self.selectedAlternative.getMainScenario(selectedBmModel.getNestedParent());
                                        }
                                        $("#linkBM" + self.encodeId).children()[0].innerText = self.labels['unlinkBM']();
                                        $('#linkedBMInfoDiv').removeClass('hide');
                                        $('#linkedBM').html(self.model.get('businessModel').get('name'));
                                        //$('#linkedBusinessInfoDiv').removeClass('hide');
                                        var business = self.model.get('businessModel').get('business');
                                        if (business) {
                                            $('#linkedBusinessInfoDiv').removeClass('hide');
                                            $('#linkedBusiness').html(business.get('name'));
                                        }
                                        /*if (self.showReportCanvas) {
                                            var title = self.model.get('name') + (self.context ? " (" + self.context.getAlternative().get('phaseAlternativeOwner').get('name') + " / " + self.context.getAlternative().get('name') + ") " : "");
                                            $('#canvasTitle'+self.encodeId).text(title);
                                            $('.btn-updateBM').hide();
                                        }*/
                                    }

                                }, true);
                            }
                        }
                    }
                }
            });


            function fillBusinessModels() {
                var viewAltId = DataManager.getDataManager().get('viewAlternative');
                if (viewAltId) {
                    DataManager.getDataManager().getAlternative(viewAltId, function (alt) {
                        self.selectedAlternative = alt;
                        $('#selectBM').empty();
                        $('#selectBM').append("<option selected='true'>Select Business Model ...</option>");
                        var packRefs = self.selectedAlternative.get('phaseDesignPart');
                        businessModels.sort(self.sortBMs);
                        for (var i = 0; i < businessModels.length; i++) {
                            var parentId = businessModels[i].getNestedParent().id;
                            packRefs.each(function (packRef) {
                                if (packRef.get('beepReference') === parentId) {
                                    $('#selectBM').append("<option bmId='" + businessModels[i].id + "'>" + businessModels[i].get('name') + "</option>");
                                }
                            });
                        }
                    })
                }
            }
            fillBusinessModels();
            if ($(this).children('option:first-child').is(':selected')) {
                $('#selectBM').empty();
                $('#selectBM').append("<option selected='true'>Select Business Model ...</option>");
            }
        }
    }
    unlinkBusinessModel() {
        var self = this;
        function fetchMappedItems(all) {
            var mappedItems = [];
            for (var i = 1; i <= self.dimensions.length; i++) {
                var dimObj = self.dimensions[_.findLastIndex(self.dimensions, { id: 'dimension' + i })];
                var items = self.model.get('bmCanvasBlock').find({ name: dimObj.name }).get('blockItem').models;
                for (var j = 0; j < items.length; j++) {
                    if (items[j].get('canvasItem')) {
                        mappedItems.push(items[j]);
                        if (!all) {
                            break;
                        }
                    }
                }
            }
            return mappedItems;
        }
        var mappedItems = fetchMappedItems(false);
        if (mappedItems.length) {
            mappedItems.length = 0;
            bootbox.confirm(DataManager.getDataManager().get('localeManager').get('unlinkBMWarning'), function (result) {
                if (result) {
                    self.createRevisionBasedOnContext(true, function (revision) {
                        var model = self.model;
                        //model.set('lastModified', new Date().getTime());
                        self.model.set('businessModel', null);
                        if (revision) {
                            var mappedItems = fetchMappedItems(true);
                            for (var i = 0; i < mappedItems.length; i++) {
                                var canvasItem = mappedItems[i].get('canvasItem');
                                if (canvasItem.id.substr(0, canvasItem.id.lastIndexOf('@') + 1) !== window.plansKey)
                                    mappedItems[i].set('canvasItem', null);
                            }
                            mappedItems.length = 0;
                            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + model.getNestedParent().get('version') + "/" + model.get('id') + "/canvas_BMCanvasDiagram/" + DataManager.getDataManager().get('viewAlternative') + "", { trigger: true });
                        } else {
                            self.modelNotLinked(true);
                            $("#linkBM" + self.encodeId).children()[0].innerText = self.labels['linkBM']();
                            $('#linkedBMInfoDiv').addClass('hide');
                            $('#linkedBM').html('');
                            $('#linkedBusinessInfoDiv').addClass('hide');
                            $('#linkedBusiness').html('');
                            var mappedItems = fetchMappedItems(true);
                            for (var i = 0; i < mappedItems.length; i++) {
                                var canvasItem = mappedItems[i].get('canvasItem');
                                if (canvasItem.id.substr(0, canvasItem.id.lastIndexOf('@') + 1) !== window.plansKey)
                                    mappedItems[i].set('canvasItem', null);
                            }
                            mappedItems.length = 0;
                            self.refreshTemporaryCollections();
                        }
                    });
                }
            });
        } else {
            self.createRevisionBasedOnContext(true, function (revision) {
                var model = self.model;
                //model.set('lastModified', new Date().getTime());
                self.model.set('businessModel', null);
                if (revision) {
                    DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + model.getNestedParent().get('version') + "/" + model.get('id') + "/canvas_BMCanvasDiagram/" + DataManager.getDataManager().get('viewAlternative') + "", { trigger: true });
                } else {
                    self.modelNotLinked(true);
                    $("#linkBM" + self.encodeId).children()[0].innerText = self.labels['linkBM']();
                    $('#linkedBMInfoDiv').addClass('hide');
                    $('#linkedBM').html('');
                    $('#linkedBusinessInfoDiv').addClass('hide');
                    $('#linkedBusiness').html('');
                }
            });
        }

    };
    getCanvasLibrariesFromMarketPlace(callback) {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var url = "/vdmbee/marketplaceinfo?tags=&name=&page=0&size=12&typeList=8";
        dataManager.get('vmpServerService').getAllPromise(url).then(function(data) {
            var productId;
            var packageId;
            if(data && data._embedded.MarketPlace){
                var dataObj = data._embedded.MarketPlace;
                for(var i=0; i< dataObj.length; i++){
                    var canvasType = dataObj[i].productName.replaceAll(' ','');
                    if(self.model.get('canvasType') == canvasType) {
                        productId = dataObj[i].productId;
                        packageId = dataObj[i].packageId
                        break;
                    }
                }
            }
            callback(productId,packageId);
        });
    }
    downloadCanvasLibrary() {
        var self =this;
            /*var htmlContent = "<span>" + "The corresponding Business Canvas Type is missing. Please import it from Cloud..." + "</span>";
            bootbox.dialog({
                title: "<div><img class='smallIcon' src='img/icons/icons_14_1.png'>&nbsp;&nbsp;&nbsp;Download Canvas Library</div>",
                message: htmlContent,
                buttons: {
                    main: {
                        label: "Close",
                        className: "btn btn-close",
                        callback: function() {
                            DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                        }
                    },
                    success: {
                        label: '<i id="downloadCanvasLibrary" class="fa fa-check"></i> Download',
                        className: "btn btn-complete",
                        callback: function () {*/
                            var dataManager = DataManager.getDataManager();
                            var currentws = dataManager.get("currentWorkspaceId");
                            var legalId = dataManager.get("workspaceLegal")[currentws];
                            var planBackboneId = dataManager.get("currentPlan").get("id");
                            var planDoc = dataManager.get("artifactsDocuments")[planBackboneId];
                            var planId = planDoc ? planDoc.artifactId : planBackboneId;
                            window.utils.startSpinner("copyCanvasLibrarySpinner", "importing canvas library...");
                            self.getCanvasLibrariesFromMarketPlace(function(productId,packageId){
                                var url = "/vdmbee/purchase/generatepurchaseorder/" + legalId + "/product/" + productId;
                                var params = {};
                                params.currentEntityId = legalId;
                                dataManager.get('vmpServerService').postAllPromise(url,JSON.stringify(params)).then(function(response) {
				                    if(response){
                                        var params = {};
                                        params.entityId = legalId;
                                        var canvasDownloadUrl = '/vdmbee/workspace/copymarketplacepackagetoplan/'+currentws+'/'+ planId +'/'+packageId+'/'+legalId;
                                        dataManager.get('vmpServerService').postAllPromise(canvasDownloadUrl,JSON.stringify(params)).then(function(data) {
                                            window.location.reload();
                                            window.utils.stopSpinner('copyCanvasLibrarySpinner');
                                        }).catch(error => {	
                                            console.log(error);
                                            bootbox.alert(error);
                                            window.utils.stopSpinner("copyCanvasLibrarySpinner");
                                        });
                                    }
                                    //window.location.reload();
                                    //callback();
			                    });
                            });
                        /*}
                    }
                }
            });*/
        }

    resizableStart(e) {
        var self = this;
        this.originalW = this.clientWidth;
        this.originalH = this.clientHeight;
        this.onmousemove = this.resizableCheck;
        this.onmouseup = this.onmouseout = this.resizableEnd;
    }
    resizableCheck(e) {
        var self = this;
        if (this.clientWidth !== this.originalW || this.clientHeight !== this.originalH) {
            this.originalX = e.clientX;
            this.originalY = e.clientY;
            this.onmousemove = self.resizableMove;
        }
    }
    resizableMove(e) {
        var self = this;
        var newW = this.originalW + e.clientX - this.originalX,
            newH = this.originalH + e.clientY - this.originalY;
        if (newW < this.originalW) {
            this.style.width = newW + 'px';
        }
        if (newH < this.originalH) {
            this.style.height = newH + 'px';
        }
    }
    resizableEnd() {
        this.onmousemove = this.onmouseout = this.onmouseup = null;
    }

    setResizeHeight(view, event) {
        var self = this;
        if (self.canvasPage && self.viewLoaded) {
            setTimeout(function () {
                var propertiesNode = 0;
                if ($('#rightTab' + self.encodeId).width() > 10) {
                    propertiesNode = $('.propertiesArea').outerHeight();
                }
                //var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+20;
                var topAreaHeight = $('.navbar').outerHeight() + 20;
                var lhsHeight = $('#canvas-modal-footer' + self.encodeId).outerHeight() + $('#slipsList' + self.encodeId).outerHeight() + $('#js-canvas' + self.encodeId).outerHeight() + $('#canvas_footer' + self.encodeId).outerHeight() + topAreaHeight;
                var windowHeight = window.innerHeight - $('.navbar').height();
                var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight]) + 25;
                if (self.spliter) {
                    self.spliter.css('height', baseHeight + 'px');
                    if ($('#rightTab' + self.encodeId).width() < 5 && Math.abs($(window).width() - $('#lhs' + self.encodeId).width()) < 30) {
                        var windowWidth = $(window).width() - 15;
                        $('.splitter_bar').css('left', windowWidth);
                    }
                    if (self.model.get('width')) {
                        var canvasWidth = self.model.get('width') * $('#lhs' + self.encodeId).width() / 100;
                        $('#js-canvas' + self.encodeId).width('' + canvasWidth);
                    }
                    //if($('#rightTab').width() > 5){
                    $("#splitPanels" + self.encodeId).enhsplitter('refresh');
                    //}
                    var element = document.getElementById("canvas_footer" + self.encodeId);
                    var footerMarginLeft = parseInt($("#js-canvas" + self.encodeId).css("margin-left"), 10) + 6;
                    element.style["margin-left"] = '' + footerMarginLeft + 'px';
                }
                var rightTab = $('#rightTab' + self.encodeId);
                if (rightTab.width() > 0) {
                    if ($('#rightTab' + self.encodeId + ' .active a')[0]) {
                        var propDivId = $('#rightTab' + self.encodeId + ' .active a')[0].hash;
                        if (propDivId !== null && $('#rightTab .dropdown .active a')[0]) {
                            propDivId = $('#rightTab .dropdown .active a')[0].hash;
                        }
                        var propNode = $('' + propDivId);
                    }
                }
                if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/) {
                    var dropdownDiv = rightTab.children().last();
                    var appendList = dropdownDiv.find('li');
                    var removedList = [];
                    for (var i = 0; i < appendList.length; i++) {
                        dropdownDiv.before(appendList[i]);
                        removedList.push(appendList[i]);
                    }
                    if (appendList.length === removedList.length) {
                        dropdownDiv.remove();
                    }
                }
                if (rightTab.width() > 10 && rightTab.width() < 370 && rightTab.children().length > 2/* && rightTab.height() > 50*/) {
                    var rightChildren = rightTab.children();
                    var appendList = [];
                    for (var i = rightChildren.length; i > 0; i--) {
                        if (!rightChildren[i - 1].classList.contains('dropdown')) {
                            appendList.push(rightChildren[i - 1]);
                            if (rightTab.width() < 325 && rightChildren[i - 2]) {
                                appendList.push(rightChildren[i - 2]);
                                break;
                            } else {
                                break;
                            }
                        }
                    }
                    if (!rightChildren.last()[0].classList.contains('dropdown')) {
                        rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
                    }
                    for (var i = 0; i < appendList.length; i++) {
                        rightTab.children().last().find('ul').prepend(appendList[i]);
                    }
                }
                if (rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')) {
                    var dropdownDiv = rightTab.children().last();
                    var appendList = dropdownDiv.find('li');
                    for (var i = 0; i < appendList.length; i++) {
                        appendList.eq(i).removeClass('active');
                    }
                }
            }, 300);
        }
    };

    syncNameChanges() {
        var self = this;
        for (var i = 1; i <= self.dimensions.length; i++) {
            var slipCollArray = self.colls['dimension' + i + 'Collection']();
            if (slipCollArray) {
                for (var j = 0; j < slipCollArray.length; j++) {
                    if (slipCollArray[j].syncName && $("div[data-guid='" + slipCollArray[j].dataGuid + "']")[0]) {
                        $("div[data-guid='" + slipCollArray[j].dataGuid + "']").find('.text-wrapper p').attr('contenteditable', false);
                    }
                }
            }
        }
    }

    afterRenderView(redraw) {
        var self = this;
        var bmMappings = self.configObj ? self.configObj.bmMappings : null;
        var backgroundColors = self.configObj ? self.configObj.backgroundColors : [];
        var canvasBlocks = $('#canvas-group' + self.encodeId).children();
        if (self.model.get('colors')) {
            for (var i = 0; i < backgroundColors.length; i++) {
                canvasBlocks.eq(i).css('background-color', backgroundColors[i].color);
            }
        }
        if (self.configObj && self.configObj.dimfontsize) {
            $('#canvas-group' + self.encodeId + ' .block-name').css('font-size', self.configObj.dimfontsize);
        } else {
            $('#canvas-group' + self.encodeId + ' .block-name').css('font-size', '18px');
        }
        if (self.configObj && self.configObj.dimfontfamily) {
            $('#canvas-group' + self.encodeId + ' .block-name').css('font-family', self.configObj.dimfontfamily);
        } else {
            $('#canvas-group' + self.encodeId + ' .block-name').css('font-family', 'Roboto medium');
        }
        for (var i = 0; i < self.dimensions.length; i++) {
            var dimension = self.dimensions[i];
            /*if (dimension.src) {
                canvasBlocks.eq(i).css('background-image', 'url(' + dimension.src + ')');
            }*/
            canvasBlocks.eq(i).css('background-size', (dimension.backgroundSize ? dimension.backgroundSize : '30px'));
            canvasBlocks.eq(i).css('background-repeat', (dimension.backgroundRepeat ? dimension.backgroundRepeat : 'no-repeat'));
            canvasBlocks.eq(i).css('background-position', (dimension.backgroundPosition ? dimension.backgroundPosition : 'left left'));
        }
        if (!bmMappings) {
            $("#linkBM" + self.encodeId).hide();
        }
        $("#linkBM" + self.encodeId).unbind("click").click(function () {
            if (self.modelNotLinked()) {
                self.linkBusinessModel();
            }
            else {
                self.unlinkBusinessModel();
            }
        });

        for (var i = 1; i <= self.dimensions.length; i++) {
            self.loadDimensionsData('dimension' + i);
        }
        var els = document.getElementsByClassName('post-it');
        for (var i = 0, len = els.length; i < len; ++i) {
            els[i].onmouseover = self.resizableStart;
        }
        if (self.showDashboardCanvas) {
            var areaOfCanvas = $('#js-canvas' + self.encodeId).width() * $('#js-canvas' + self.encodeId).height();
            $('#canvas-group' + self.encodeId + ' .post-it-text').css('font-size', 0.015 * Math.sqrt(areaOfCanvas));
        } else if (self.configObj && self.configObj.postslipfontsize) {
            $('#canvas-group' + self.encodeId + ' .post-it-text').css('font-size', self.configObj.postslipfontsize);
        } else {
            $('#canvas-group' + self.encodeId + ' .post-it-text').css('font-size', '14px');
        }
        if (self.configObj && self.configObj.postslipfontfamily) {
            $('#canvas-group' + self.encodeId + ' .post-it-text').css('font-family', self.configObj.postslipfontfamily);
        } else {
            $('#canvas-group' + self.encodeId + ' .post-it-text').css('font-family', 'Roboto medium');
        }
        if (self.configObj && self.configObj.postslipfontsize) {
            $('#canvas-group' + self.encodeId + ' .post-it-text').css('font-size', self.configObj.postslipfontsize);
        } else {
            $('#canvas-group' + self.encodeId + ' .post-it-text').css('font-size', '14px');
        }

        if (self.showDashboardCanvas) {
            $('#splitPanels' + self.encodeId).contents().unwrap();
            $(".btn-detailsView").hide();
            //$('.modal-header').hide();
            $('#modelHeaderOfCanvas' + self.encodeId).hide();
            $('.showReportCanvas').hide();
            $('#mainTab' + self.encodeId).hide();
            //$('#canvas-modal-footer' + self.encodeId).hide();
            $('#canvas_footer' + self.encodeId).hide();
            $('#canvas-modal-footer' + self.encodeId).css('padding','0px');
            
            //$('#canvasDesigner' + self.encodeId).css('margin-right', '2%');
            //$('#canvasDesigner' + self.encodeId).css('margin-left', '1%');
            document.getElementById('js-canvas' + self.encodeId).style.width = '98%';
            //$('#js-canvas' + self.encodeId).width("98%");
            if (self.model.get('businessModel')) {
                self.modelNotLinked(false);
            }
            if (redraw !== true && self.options.parentView) {// when canvas is added
                window.vdmModelView.setResizeHeight();
            }
            /*if (self.configObj && self.configObj.licenseInfo) {
                $('#canvas_footer' + self.encodeId).empty();
                $('#canvas_footer' + self.encodeId).append(self.configObj.licenseInfo);
                $('#canvas_footer' + self.encodeId).css('font-family', 'Verdana');
                $('#canvas_footer' + self.encodeId).css('font-size', '9px');
            } else {
                //$('#canvas-modal-footer'+self.encodeId).css('margin-top', '1.5%');
            }*/
            $('#btnClose').hide();
            $('#canvasDesigner' + self.encodeId).click(function (ev) {
                var visibleEditButtons = $(ev.currentTarget).find('.glyphicon-pencil');
                _.each(visibleEditButtons, function (btn) { $(btn).hide(); });
            });
            var canvasContainer = $('#container' + self.encodeId);
            if (!self.editMode) {
                canvasContainer.find(".glyphicon-plus").parent().hide();
                $("#linkBM" + self.encodeId).hide();
                $('#canvasComplete' + self.encodeId).hide();
                canvasContainer.find('.btn-updateBM').hide();
                canvasContainer.find('.glyphicon-pencil').hide();
                canvasContainer.find('.text-wrapper p').attr('contenteditable', false);
                canvasContainer.find('.post-it').css('resize', 'none');
            } else {
                canvasContainer.find(".glyphicon-plus").parent().show();
                if (bmMappings) {
                    $("#linkBM" + self.encodeId).show();
                }
                $('#canvasComplete' + self.encodeId).show();
                canvasContainer.find('.btn-updateBM').show();
                canvasContainer.find('.text-wrapper p').attr('contenteditable', true);
                canvasContainer.find('.post-it').css('resize', 'both');
                self.syncNameChanges();
            }
        } else if (self.showReportCanvas) {
            $(".btn-detailsView").hide();
            $(".glyphicon-plus").parent().hide();
            $("#linkBM" + self.encodeId).hide();
            //$('.modal-header').hide();
            $('#modelHeaderOfCanvas' + self.encodeId).hide();
            $('#canvasComplete' + self.encodeId).hide();
            $('.showReportCanvas').hide();
            $('#mainTab' + self.encodeId).hide();
            $('#canvas-modal-footer' + self.encodeId).css('margin-top','-23px')
            document.getElementById('js-canvas' + self.encodeId).style.width = '98%';
            if (self.model.get('businessModel')) {
                self.modelNotLinked(false);
                $(".btn-updateBM").hide();
            }
            var height = self.model.get('height');
            if (!height) {
                height = 700;
            }
            $("#js-canvas" + self.encodeId).height(height);
        } else {
            if (self.modelNotLinked()) {
                $("#linkBM" + self.encodeId).children()[0].innerText = self.labels['linkBM']();
            } else {
                $("#linkBM" + self.encodeId).children()[0].innerText = self.labels['unlinkBM']();
            }
            if (!self.editMode) {
                $('#canvasDesigner' + self.encodeId).click(function (ev) {
                    var visibleEditButtons = $(ev.currentTarget).find('.glyphicon-pencil');
                    _.each(visibleEditButtons, function (btn) { $(btn).hide(); });
                });
                self.showLinkBM(false);
            }
            self.syncNameChanges();
            $("#js-canvas" + self.encodeId).resizable({
                //handles: "se",
                stop: function (event) {
                    if (self.canvasPage) {
                        var propertiesNode = 0;
                        if ($('#rightTab' + self.encodeId).width() > 0) {
                            propertiesNode = $('.propertiesArea').outerHeight();
                        }
                        var topAreaHeight = $('.navbar').outerHeight();
                        var lhsHeight = $('#slipsList' + self.encodeId).outerHeight() + $('#js-canvas' + self.encodeId).outerHeight() + $('#canvas_footer' + self.encodeId).outerHeight() + topAreaHeight;
                        var windowHeight = window.innerHeight - $('.navbar').height();
                        var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight]) + 15;
                        self.spliter.css('height', baseHeight + 'px');
                        self.spliter.enhsplitter('refresh');
                        var element = document.getElementById("canvas_footer" + self.encodeId);
                        var footerMarginLeft = parseInt($("#js-canvas" + self.encodeId).css("margin-left"), 10) + 6;
                        element.style["margin-left"] = '' + footerMarginLeft + 'px';
                        self.enableComplete(true);
                        self.customeResize = true;
                    }
                }
            });
            if (self.configObj && self.configObj.licenseInfo) {
                $('#canvas_footer' + self.encodeId).append(self.configObj.licenseInfo);
                $('#canvas_footer' + self.encodeId).css('font-family', 'Verdana');
                $('#canvas_footer' + self.encodeId).css('font-size', '9px');
            } else {
                //$('#canvas-modal-footer'+self.encodeId).css('margin-top', '1.5%');
            } 
            //$('.modal-header').hide();
            $('#modelHeaderOfCanvas' + self.encodeId).hide();
            $('#btnClose').hide();
            $('#canvasDesigner' + self.encodeId).click(function (ev) {
                var visibleEditButtons = $(ev.currentTarget).find('.glyphicon-pencil');
                _.each(visibleEditButtons, function (btn) { $(btn).hide(); });
            });
            var height = self.model.get('height');
            if (!height) {
                height = window.innerHeight * 0.80 - $('.navbar').height();
            }
            $("#js-canvas" + self.encodeId).height(height);
            //document.getElementById('js-canvas' + self.encodeId).style.height = self.model.get('height') ? self.model.get('height') + "px" : 621 + "px";
            document.getElementById('js-canvas' + self.encodeId).style.width = self.model.get('width') && self.model.get('width') < $(window).width() ? self.model.get('width') + "%" : 98 + "%";
            var lhsHeight = $('#slipsList' + self.encodeId).outerHeight() + $('#js-canvas' + self.encodeId).outerHeight() + $('#canvas_footer' + self.encodeId).outerHeight() + $('.navbar').outerHeight();
            var propertiesNode = $('.propertiesArea');
            var baseHeight = _.max([lhsHeight, propertiesNode.outerHeight()]) + 20;
            self.spliter = $("#splitPanels" + self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '64%', handle: 'block', splitterSize: '9px', height: baseHeight, onDragEnd: self.setResizeHeight });
            self.spliter.enhsplitter('collapse');
            // $(window).resize(function (view, event) {
            //     if (!event) {
            //         this.timer = clearTimeout();
            //         this.timer = setTimeout(function () { self && self.setResizeHeight && self.setResizeHeight() }, 200);
            //     }
            // });
            $('#mainTab' + self.encodeId + ' a').click(function (e) {
                e.preventDefault();
                $(this).tab('show');
                self.showLinkBM(true);
                $("#canvasComplete"+self.encodeId).show();
                if($(this)[0].className === "ShapeDetails"){
                    self.showLinkBM(false);
                    $("#canvasComplete"+self.encodeId).hide();
                }
                self.setResizeHeight();
            });
            $('#rightTab' + self.encodeId + ' a').click(function (e) {
                e.preventDefault();
                if ($(this).text() === "Collaboration") {
                    return false;
                }
                $(this).tab('show');
                if ($(this).text() === "Guidance") {
                    if ($('#guidanceTab').is(':empty')) {
                        $.get('js/app/version1/views/help/views/properties/CanvasGuidanceTemplate.html',function(viewStr){
                            self.countGuidanceClicks++;
                            $('#guidanceTab').empty();
                            $('#guidanceTab').append(viewStr);
                            if (self.configObj && self.configObj.guidance) {
                                $('#guidanceTab').append(self.configObj.guidance);
                            }
                            window.utils.applyFontSize($('#guidanceTab'));
                            self.setResizeHeight();
                        })
                    } else {
                        self.setResizeHeight();
                    }
                } else {
                    self.setResizeHeight();
                }
            });
            self.adjustSlipsTable();
            self.viewLoaded = true;
            self.setResizeHeight();
            window.utils.applyFontSize($('#content'));
            self.model.getNestedParent().updateScenarioContextBased(self, function () {
                if (!self.configObj) {
                    self.downloadCanvasLibrary();
                    //bootbox.alert('The corresponding Business Canvas Type is missing. Please import it from Cloud.');
                }
                window.utils.stopSpinner('bmCubeSpinner');
            });
            const dialogConfig = {
                title: "<div><img class='smallIcon' src='img/icons/icons_20.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('BMCSteps')+"</div>" ,
                videos: [
                    { id: 'VXsCWEul9Gc', title: '', width: '25vw', height: '14vw',description:DataManager.getDataManager().get('localeManager').get('BMCDescription')},
                ],
            };
            DataManager.getDataManager().handleVideoClick(dialogConfig,'CanvasVideo');
        }
    };
    showobjectExplorerTree() {
        var self = this;
        var vdm = self.model.getNestedParent()
        var addOptions = this;
        var id = window.utils.htmlEscape(window.guidGenerator());
        window.getAndCreateModalDialog(true, id, BMCanvasDiagramMixin, vdm, "explorerModelJson", closeView, addOptions);
        function closeView() {
            window.cleanDialogModel(id, BMCanvasDiagramMixin)
        }
    };
    handlePublicMenus() {
        DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench", "Show Object Explorer", $.proxy(this.showobjectExplorerTree, this));
    };
    getValueAndUnitString(value) {
        var self = this;
        var mest;
        var valueUnitString = '';
        if (value.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
            mest = value.getMeasurement('valueMeasurement', self.currentAlternativeModel.getOrCreatePhaseObjectiveSet());
        } else if (self.context) {
            mest = value.getMeasurement('valueMeasurement', self.context);
        }
        var unit;
        if (mest) {
            unit = mest.get('observedMeasure').get('measure').get('unit');
            valueUnitString = " (" + window.utils.getRoundedValues(mest.get('value'), unit.get('significantDecimals')) + (unit ? " " + unit.get('name') : "") + ")";
        }
        return valueUnitString;
    };

    getSatInterval(value) {
        var self = this;
        var satisfactionMeasurement;
        var selectedInterval;
        if (value.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
            satisfactionMeasurement = value.getMeasurement('satisfactionLevel', self.currentAlternativeModel.getOrCreatePhaseObjectiveSet());
        } else if (self.context) {
            satisfactionMeasurement = value.getMeasurement('satisfactionLevel', self.context);
        }
        if (satisfactionMeasurement && value.getParent().get('overAllSatisfaction') !== value) {
            var satType = 'symbol';
            var satMeasure = satisfactionMeasurement.get('observedMeasure').get('measure');
            if (satisfactionMeasurement.get('value') && satMeasure.get('unit')) {//Ranking
                satType = 'value';
            }
            if (satMeasure) {
                var intervals = satMeasure.get('interval');
                for (var i = 0; i < intervals.length; i++) {
                    if (intervals.at(i).get('' + satType) === satisfactionMeasurement.get('' + satType)) {
                        selectedInterval = intervals.at(i);
                        break;
                    }
                }

            }
        }
        return selectedInterval;
    };

    fetchSmileySrc(smileyValue) {
        var self = this;
        //var smileyValue = smileyValue?smileyValue:'1';
        var smileySrc = '';
        for (var j = 0; j < self.svgSmiliesList.length; j++) {
            if (self.svgSmiliesList[j].value === smileyValue) {
                smileySrc = self.svgSmiliesList[j].src;
                break;
            }
        }
        return smileySrc;
    };

    getCapElementFromMappedObject(mappObj) {
        var self = this;
        var capMappedModal = mappObj.get('capabilityRequirement');
        if (!capMappedModal) {
            capMappedModal = mappObj.get('capability');
        }
        return capMappedModal;
    }

    loadDimensionsData(dimension) {
        var self = this;
        var dimObj = self.dimensions[_.findLastIndex(self.dimensions, { id: dimension })];
        var dimensionsBlock = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
        var blockItems = dimensionsBlock ? dimensionsBlock.get('blockItem') : null;
        var slipCollArray = [];
        if (blockItems) {
            blockItems.each(function (item) {
                var capabilityItemName = '';
                var capElementId = '';
                var itemName = item.get('name');
                var itemDescription = item.get('description') ? item.get('description') : itemName;
                var canvasItemName = item.get('canvasItem') ? item.get('canvasItem').get('name') : " ";
                var capEle = item.get('canvasItem') ? self.getCapElementFromMappedObject(item.get('canvasItem')) : item.get('capabilityItem');
                if (capEle) {
                    capabilityItemName = capEle.get('name');
                    capElementId = capEle.id;
                }
                var syncName = item.get('syncName') ? true : false;
                if (syncName && item.get('canvasItem')) {
                    itemName = item.get('canvasItem').get('name');
                    itemDescription = item.get('canvasItem').get('description');
                }
                var itemNameWithValue = itemName;
                var slipName = itemName;
                var slipColor = item.get('color') ? item.get('color') : "rgba(255,255,127,0.5)";
                var namePath = " ";
                var smileySrc = '';
                var smileyColor = '';

                if (item.get('canvasItem') && item.get('canvasItem').get('valueMeasurement')) {
                    var valueUnitString = self.getValueAndUnitString(item.get('canvasItem'));
                    itemNameWithValue = itemNameWithValue.concat(valueUnitString);
                    var selectedInterval = self.getSatInterval(item.get('canvasItem'));
                    if (selectedInterval) {
                        smileySrc = self.fetchSmileySrc(selectedInterval.get('smiley'));
                        smileyColor = selectedInterval.get('smileyColor');
                    }
                }
                var eleId = item.get('canvasItem') ? item.get('canvasItem').id : "";
                var bmEle = self.getBMElementType(item.get('canvasItem'));
                if (!self.checkIdAlreadyExist(item.id, slipCollArray)) {
                    dimObj = self.dimensions[_.findLastIndex(self.dimensions, { id: dimension })];
                    slipCollArray.push({ id: window.utils.htmlEscape(item.id), dataGuid: item.id, elementId: eleId, syncName: syncName, smileySrc: smileySrc, smileyColor: smileyColor, name: ko.observable(itemNameWithValue), description: itemDescription, slipName: slipName, canvasItemName: canvasItemName, capabilityItemName: capabilityItemName, capElementId: capElementId, namePath: namePath, slipTop: item.get('top'), slipLeft: item.get('left'), slipWidth: item.get('width'), slipHeight: item.get('height'), slipColor: slipColor, type: self.labelValueToDisplay(dimension.substr('dimension'.length)), bmElement: bmEle });
                    slipColor = slipColor.replace(/ /g, '');
                    self.usedSlipColors.push(slipColor);
                    $.unique(self.usedSlipColors);
                }
            });
            for (var i = 0; i < slipCollArray.length; i++) {
                for (var j = 0; j < slipCollArray.length; j++) {
                    if (slipCollArray[i] != slipCollArray[j] && slipCollArray[i].elementId && slipCollArray[i].type === slipCollArray[j].type && slipCollArray[i].canvasItemName === slipCollArray[j].canvasItemName) {
                        var item = self.model.get('bmCanvasBlock').find({ name: slipCollArray[i].type }).get('blockItem').findWhere({ 'id': slipCollArray[i].dataGuid });
                        var namePath = "   [";
                        if (item.get('canvasItem').getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                            namePath = namePath.concat(item.get('canvasItem').getNestedParent().get('name'));
                            namePath = namePath.concat("]");
                            slipCollArray[i].namePath = namePath;
                            break;
                        } else {
                            if (item.get('canvasItem').get('type') === "vdml_Activity") {
                                namePath = self.fetchActivityNsPath(item.get('canvasItem'));
                            } else if (item.get('canvasItem').get('type') === "vdml_ValuePropositionComponent") {
                                namePath = self.fetchValueNsPath(item.get('canvasItem'));
                            } else if (item.get('canvasItem').get('type') === "vdml_BusinessItem" || item.get('canvasItem').get('type') === "vdml_CapabilityOffer") {
                                namePath = self.fetchCompetencyNsPath(item.get('canvasItem'));
                            } else if (item.get('canvasItem').get('type') === "vdml_BusinessNetwork") {
                                namePath = namePath.concat(item.get('canvasItem').getNestedParent().get('name'));
                                namePath = namePath.concat("]");
                            } else {
                                var slipPath = item.get('canvasItem').getPackagePath();
                                if (slipPath[0] && slipPath[0].name && slipPath[1]) {
                                    namePath = namePath.concat(slipPath[0].name + "  /  " + slipPath[1].name);
                                }
                                namePath = namePath.concat("]");
                            }
                            slipCollArray[i].namePath = namePath;
                            break;
                        }

                    }
                }
                var eleExists = self.checkIdAlreadyExist(slipCollArray[i].dataGuid, self.colls[dimension + 'Collection']());
                if (!eleExists) {
                    self.colls[dimension + 'Collection'].push(slipCollArray[i]);
                } else {
                    eleExists.elementId = slipCollArray[i].elementId;
                }

            }

        }
    };

    closeDialog() {
        var self = this;
        this.cleanUp();
        window.cleanDialogModel(self.options.modalId, self);
    };

    saveBMCanvasDiagramSize() {
        var self = this;
        if (self.canvasPage && self.customeResize) {
            self.model.set('height', $('#js-canvas' + self.encodeId).height());
            var newWidth = $('#js-canvas' + self.encodeId).width();
            var percentWidth = newWidth / $("#lhs" + self.encodeId).width() * 100;
            self.model.set('width', percentWidth);
        }
    }

    saveDimensionsData(z) {
        var self = this;
        var dimension = "dimension" + z;
        var dimObj = self.dimensions[_.findLastIndex(self.dimensions, { id: dimension })];
        var dimensionBlock = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
        var columnHeaders = $('.column-header');
        if (columnHeaders && columnHeaders.length > 0) {
            var blockid = '#integrated-canvas-dimension' + z;
            var blockWidth = $(blockid).css('width');
        } else {
            var blockItemDiv = $('#canvas-group' + self.encodeId).children().eq(z - 1);
            blockWidth = blockItemDiv.css('width');
        }
        blockWidth = blockWidth.substr(0, blockWidth.indexOf('px'));
        for (var i = 0; i < self.colls[dimension + 'Collection']().length; i++) {
            if (self.colls[dimension + 'Collection']()[i]._destroy !== true) {
                var dimensionElement = null;
                var dimensionItem = dimensionBlock ? dimensionBlock.get('blockItem').findWhere({ id: self.colls[dimension + 'Collection']()[i].dataGuid }) : null;
                if (!dimensionItem) {
                    dimensionItem = new BMCanvasItem({ id: self.colls[dimension + 'Collection']()[i].dataGuid, name: self.colls[dimension + 'Collection']()[i].name(), blockItemOwner: dimensionBlock });
                    var modelTypeArray = self.mappedDimensionType(dimObj);
                    for (var j = 0; j < modelTypeArray.length; j++) {
                        dimensionElement = Backbone.Relational.store.getObjectByName(modelTypeArray[j]).find({ id: self.colls[dimension + 'Collection']()[i].elementId });
                        if (dimensionElement) {
                            dimensionItem.set('canvasItem', dimensionElement);
                            break;
                        }
                    }
                }
                var top, left, color, description;
                if (self.colls[dimension + 'Collection']()[i].slipTop) {
                    top = self.colls[dimension + 'Collection']()[i].slipTop;
                } else {
                    top = self.getRandomArbitrary(1, 80) + "%";
                }
                if (self.colls[dimension + 'Collection']()[i].slipLeft) {
                    left = self.colls[dimension + 'Collection']()[i].slipLeft;
                } else {
                    left = self.getRandomArbitrary(1, 80) + "%";
                }
                if (self.colls[dimension + 'Collection']()[i].slipColor) {
                    color = self.colls[dimension + 'Collection']()[i].slipColor;
                } else {
                    color = "rgba(255,255,127,0.5)";
                }
                dimensionItem.set('top', self.toRoundedValues(parseFloat(top.replace('%', ''))) + '%');
                dimensionItem.set('left', self.toRoundedValues(parseFloat(left.replace('%', ''))) + '%');
                dimensionItem.set('color', color);
                var dimItemName = self.colls[dimension + 'Collection']()[i].name();
                if (dimensionItem.get('canvasItem')) {
                    if (Backbone.Relational.instanceofmodel(dimensionItem.get('canvasItem'), ValueElement) && dimItemName.lastIndexOf(' (') > 0) {
                        dimItemName = dimItemName.substr(0, dimItemName.lastIndexOf(' ('));
                    }
                    if (dimensionItem.get('name') !== dimItemName) {
                        dimensionItem.set('name', dimItemName);
                    }
                    dimensionItem.set('capabilityItem', null);
                } else {
                    dimensionItem.set('name', dimItemName);
                    var capId = self.colls[dimension + 'Collection']()[i].capElementId;
                    var capElementModel = self.getCapabilityElementModel(capId);
                    dimensionItem.set('capabilityItem', capElementModel);
                }
                dimensionItem.set('syncName', self.colls[dimension + 'Collection']()[i].syncName);
                if (self.colls[dimension + 'Collection']()[i].description) {
                    description = self.colls[dimension + 'Collection']()[i].description;
                } else {
                    description = dimItemName;
                }
                dimensionItem.set('description', description);
                if (!self.creatingRevision && blockWidth!=="0") {
                    dimensionItem.set('height',self.toRoundedValues($("div[data-guid='" + self.colls[dimension + 'Collection']()[i].dataGuid + "']").height()) + 'px');
                    dimensionItem.set('width', self.toRoundedValues($("div[data-guid='" + self.colls[dimension + 'Collection']()[i].dataGuid + "']").width() * 100 / blockWidth) + '%');
                }
            }
            else if (self.colls[dimension + 'Collection']()[i]._destroy === true) {
                var dimensionItem = dimensionBlock.get('blockItem').findWhere({ id: self.colls[dimension + 'Collection']()[i].dataGuid });
                if (dimensionItem) {
                    dimensionItem.destroy();
                }
            }
        }
    }
    cleanModal(view, event, callback) {
        var self = this;
        var revisionCheck = true;//self.slipRevision.length > 0 ? true : false;
        self.tempDeleteArray = []
        self.saveData(function (revision) {
            var model = self.model;
            //model.set('lastModified', new Date().getTime());
            if (revision && !callback) {
                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + model.getNestedParent().get('version') + "/" + model.get('id') + "/canvas_BMCanvasDiagram/" + DataManager.getDataManager().get('viewAlternative') + "", { trigger: true });
            }
            if (callback) {
                callback();
            }
        }, revisionCheck);
    };

    hasUnsavedData() {
        var self = this;
        if (this.enableComplete() && !window.vdmModelView.revision) {
            return true;
        } else {
            return false;
        }
    };

    saveDataBeforeClosing(callback) {
        var self = this;
        self.cleanModal(null, null, callback);
    };
    toRoundedValues(number){
        if (Number.isInteger(number)) {
            return number;
        } else {
            return Math.round(number * 100) / 100;
        }
    }
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }
    dragSlip(data, ev) {
        var self = this;
        if (!self.editMode) {
            return;
        }
        if (data) {
            if (self.showDashboardCanvas) {
                var grid = window.vdmModelView.DashboardViewModel.grid;
                var items = grid._items;
                var itemFound = false;
                for (var i = 0; i < items.length; i++) {
                    if (items[i]._child.id === "view" + self.presentationId) {
                        itemFound = items[i];
                        break;
                    }
                }
                itemFound._drag.destroy();
            }
            self.draggedData(data);
            data.startPageY = ev.originalEvent.pageY;
            data.startPageX = ev.originalEvent.pageX;
        }
        return true;
    };

    dropHandler(i) {
        var self = this;
        return function (view, ev) {
            var data = self.draggedData();
            if (data) {
                var blockElement = $(ev.currentTarget).hasClass('canvas-element') ? $(ev.currentTarget) : $(ev.currentTarget).parents('.canvas-element');
                var maxBottom = blockElement.offset().top + blockElement.height();
                var maxRight = blockElement.offset().left + blockElement.width();
                var minLeft = blockElement.offset().left;
                var minTop = blockElement.offset().top;
                var blockWidth = blockElement.css('width');
                blockWidth = blockWidth.substr(0, blockWidth.indexOf('px'));
                var blockHeight = blockElement.css('height');
                blockHeight = blockHeight.substr(0, blockHeight.indexOf('px'));
                var dropPageY = ev.originalEvent.pageY;
                var dropPageX = ev.originalEvent.pageX;
                if (dropPageY > maxBottom || dropPageX > maxRight || dropPageY < minTop || dropPageX < minLeft) {
                    return;
                }
                var changeX = parseFloat(dropPageX) - parseFloat(data.startPageX);
                var changeY = parseFloat(dropPageY) - parseFloat(data.startPageY);

                var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
                data.slipLeft = parseFloat(data.slipLeft) + parseFloat(changeX) * 100 / blockWidth + '%';
                data.slipTop = parseFloat(data.slipTop) + parseFloat(changeY) * 100 / blockHeight + '%';
                var postElement = $("div[data-guid='" + data.dataGuid + "']");

                postElement.parent().css('left', postElement.parent().position().left + changeX);
                postElement.parent().css('top', postElement.parent().position().top + changeY);

                data.slipHeight = postElement.height() + 'px';
                data.slipWidth = postElement.width() * 100 / blockWidth + '%';
                if (item) {
                    item.set('left', data.slipLeft);
                    item.set('top', data.slipTop);
                    item.set('color', data.slipColor);
                }
                if (self.showDashboardCanvas) {
                    var grid = window.vdmModelView.DashboardViewModel.grid;
                    var items = grid._items;
                    var itemFound = false;
                    for (var i = 0; i < items.length; i++) {
                        if (items[i]._child.id === "view" + self.presentationId) {
                            itemFound = items[i];
                            break;
                        }
                    }
                    itemFound._drag = new Muuri.ItemDrag(itemFound);
                }
                self.enableComplete(true);
            }
        };
    }

        
    allowDropHandler(i) {
        var self = this;
        return function (view, ev) {
            var dimObj = self.dimensions[_.findLastIndex(self.dimensions, { id: 'dimension' + i })];
            if (view.draggedData() && view.draggedData()['type'] === self.labelValueToDisplay(i)) {
                return false;
            } else {
                ev.originalEvent.stopPropagation(false);
                return true;
            }
        }
    }
        

    checkItemAlreadyExist(dataGuid, elements) {
        var self = this;
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].dataGuid === dataGuid && !elements[i]._destroy) {
                return elements[i];
            }
        }
        return null;
    }
    checkElementAlreadyExist(name, elements, skipUnit, eleId) {
        var self = this;
        for (var i = 0; i < elements.length; i++) {
            var eleName = elements[i].name();
            if (skipUnit && elements[i].elementId && eleName.lastIndexOf(' (') > 0) {
                eleName = eleName.substr(0, eleName.lastIndexOf(' ('));
            }
            if (eleId && elements[i].elementId && eleId === elements[i].elementId) {
                return elements[i];
            }
            if (eleName === name && elements[i]) {
                if (eleId) {
                    if (eleId === elements[i].elementId) {
                        return elements[i];
                    }
                } else {
                    return elements[i];
                }

            }
        }
        return null;
    }
    checkIdAlreadyExist(id, elements) {
        var self = this;
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].dataGuid === id) {
                return elements[i];
            }
        }
        return null;
    }
    deleteModel() {
        var self = this;
        self.model.destroy();
        var oldImages = $("img[diagramid = \"" + self.model.id + "\"]");
        oldImages.remove();
        if (window.vdmModelView === self) {
            DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
        }
    };

    addDimensionsData(dimension, htmlContent, collection) {
        var self = this;
        var dimObj = self.dimensions[_.findLastIndex(self.dimensions, { id: dimension })];
        var dimensionBlock = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
        var mappings = dimObj.mapping;
        var slipNames = [];
        _.each(mappings, function (mappingDim) {
            if (mappingDim === 'partner') {
                htmlContent = self.addPartnerData(dimensionBlock, htmlContent, collection, slipNames);
            } else
                if (mappingDim === 'activity') {
                    htmlContent = self.addActivityData(dimensionBlock, htmlContent, collection, slipNames);
                } else
                    if (mappingDim === 'customer') {
                        htmlContent = self.addCustomerData(dimensionBlock, htmlContent, collection, slipNames);
                    } else
                        if (mappingDim === 'competence') {
                            var mappingRules = self.configObj.mappingRules;
                            var showCompetences = [];
                            if (mappingRules && mappingRules[dimObj.id]) {
                                var mapElements = mappingRules[dimObj.id];
                                for (var i = 0; i < mapElements.length; i++) {
                                    if (mapElements[i].mapping === "competence" && mapElements[i].resourceTypes.length > 0) {
                                        var compTypes = mapElements[i].resourceTypes;
                                        _.each(compTypes, function (comp) {
                                            showCompetences.push(comp.type);
                                        });
                                        break;
                                    }
                                }
                            } else {
                                showCompetences = ['Resource', 'CapabilityOffer'];
                            }
                            htmlContent = self.addResourceData(dimensionBlock, htmlContent, collection, showCompetences, slipNames);
                            showCompetences.length = 0;
                        } else
                            if (mappingDim === 'valueProposition') {
                                var mappingRules = self.configObj.mappingRules;
                                var showMyProposition = true;
                                if (mappingRules && mappingRules[dimObj.id]) {
                                    var mapElements = mappingRules[dimObj.id];
                                    for (var i = 0; i < mapElements.length; i++) {
                                        if (mapElements[i].mapping === "valueProposition" && !mapElements[i].myProposition) {
                                            showMyProposition = false;
                                            break;
                                        }
                                    }
                                }
                                htmlContent = self.addVPData(dimensionBlock, htmlContent, collection, showMyProposition, slipNames);
                            } else
                                if (mappingDim === 'value') {
                                    htmlContent = self.addCostStructureData(dimensionBlock, htmlContent, collection, slipNames);
                                } else
                                    if (mappingDim === 'network') {
                                        htmlContent = self.addNetworkData(dimensionBlock, htmlContent, collection, slipNames);
                                    }
        });
        htmlContent = self.addUnmappedData(htmlContent, collection, mappings);
        return htmlContent;
    };

    mappedDimensionType(dimObj) {
        var self = this;
        var mappings = dimObj.mapping;
        var modelType;
        var modelTypeArray = [];
        _.each(mappings, function (mappingDim) {
            if (mappingDim === 'partner' || mappingDim === 'customer') {
                modelType = "vdml.Participant";
            } else
                if (mappingDim === 'competence') {
                    modelType = "vdml.Competence";
                } else
                    if (mappingDim === 'activity') {
                        modelType = "vdml.Activity";
                    } else
                        if (mappingDim === 'valueProposition') {
                            modelType = "vdml.ValueProposition";
                        } else
                            if (mappingDim === 'value') {
                                modelType = "vdml.ValueElement";
                            } else
                                if (mappingDim === 'network') {
                                    modelType = "vdml.Collaboration";
                                }
            modelTypeArray.push(modelType);
        });
        return modelTypeArray;
    };
    addUnmappedData(htmlContent, collection, mappings) {
        var self = this;
        var bmPartners, bmCustomers, bmActivities, bmCompetencies, bmPNs;
        var bmVPs = new Backbone.Collection();
        var showValues = false;
        if (mappings && mappings.indexOf('value') != -1) {
            showValues = true;
        }
        var costValues = [];
        //var bmRevenueValues = [];
        var bm = self.model.get('businessModel');
        if (showValues) {
            DataManager.getDataManager().get('currentPlan').getValues(costValues);
        }
        if (bm) {
            bmPartners = bm.get('bmNetworkPartner');
            bmCustomers = bm.get('bmCustomer');
            bmActivities = bm.get('bmActivity');
            bmCompetencies = bm.get('bmCompetence');
            bmPNs = bm.get('participantNetwork');
            if (showValues) {
                bm.getValues(costValues, true);
            }
            //bmCustomers.each(function (bmCustomer) {
            if (mappings && mappings.indexOf('valueProposition') != -1) {
                var bmRoles = bm.get('bmRole');
                bmRoles.each(function (bmRole) {
                    var providedProposition = bmRole.get('providedProposition');
                    providedProposition.each(function (vp) {
                        bmVPs.add(vp);
                    });
                });
            }
            // });
        }
        for (var i = 0; i < collection.length; i++) {
            var found = false;
            for (var j = 0; j < costValues.length; j++) {
                if (collection[i].elementId === costValues[j].get('id')) {
                    found = true;
                    break;
                }
            }
            if ((!bm || !collection[i].elementId || (bm && collection[i].elementId && !(bmPartners.findWhere({ name: collection[i].canvasItemName }) || bmPNs.findWhere({ name: collection[i].canvasItemName }) || bmCustomers.findWhere({ name: collection[i].canvasItemName }) || bmVPs.findWhere({ name: collection[i].canvasItemName }) || bmActivities.findWhere({ name: collection[i].canvasItemName }) || bmCompetencies.findWhere({ name: collection[i].canvasItemName })))) && !collection[i].deleted && !found) {
                var dataGuid = collection[i].dataGuid;
                var description = collection[i].description ? collection[i].description : collection[i].name();
                if (!collection[i]._destroy) {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + dataGuid + "' elementId='' value='" + collection[i].name() + "' description='" + description + "' style=\"cursor: pointer;\" checked /><label for='" + dataGuid + "'>" + collection[i].name() + "</label></li>";
                }
                else if (collection[i]._destroy && collection[i].name() !== "") {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + dataGuid + "' elementId='' value='" + collection[i].name() + "' description='" + description + "' style=\"cursor: pointer;\"/><label for='" + dataGuid + "'>" + collection[i].name() + "</label></li>";
                }
            }
        }
        costValues.length = 0;
        return htmlContent;
    }

    addPartnerData(dimensionBlock, htmlContent, collection) {
        var self = this;
        var bm = self.model.get('businessModel');
        var bmPartners;
        if (bm) {
            bmPartners = bm.get('bmNetworkPartner');
            for (var i = 0; i < bmPartners.length; i++) {
                var partner = bmPartners.at(i).get('networkPartner');
                if (!partner) {
                    continue;
                }
                var pathName = "";
                if (bmPartners.where({ 'name': partner.get('name') }).length > 1) {
                    pathName = "   [";
                    pathName = pathName.concat("" + partner.getParent().get('name') + "]");
                }
                if (pathName.length < 1) {
                    for (var k = 0; k < collection.length; k++) {
                        if (collection[k].name() === partner.get('name') && !collection[k].elementId) {
                            pathName = "   [";
                            pathName = pathName.concat("" + partner.getParent().get('name') + "]");
                            break;
                        }
                    }
                }
                var partnerData = self.checkElementAlreadyExist(partner.get('name'), collection, null, partner.get('id'));
                var description = partner.get('description') ? partner.get('description') : partner.get('name');
                if (partnerData && !partnerData._destroy) {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + partnerData.dataGuid + "' elementId='" + partner.id + "' value='" + partnerData.name() + "' description='" + description + "' style=\"cursor: pointer;\" checked /><label  for='" + partnerData.dataGuid + "'>" + partnerData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
                else if (partnerData && partnerData._destroy && partner.get('name') !== "") {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + partnerData.dataGuid + "' elementId='" + partner.id + "' value='" + partnerData.name() + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + partnerData.dataGuid + "'>" + partnerData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
                else if (!partnerData) {
                    var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + dataGuid + "' elementId='" + partner.id + "' value='" + partner.get('name') + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + dataGuid + "'>" + partner.get('name') + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
            }
        }
        return htmlContent;
    };
    addCustomerData(dimensionBlock, htmlContent, collection) {
        var self = this;
        var bm = self.model.get('businessModel');
        var bmCustomers;
        if (bm) {
            bmCustomers = bm.get('bmCustomer');
            for (var i = 0; i < bmCustomers.length; i++) {
                var customer = bmCustomers.at(i).get('customer');
                if (!customer) {
                    continue;
                }
                var pathName = "";
                if (bmCustomers.where({ 'name': customer.get('name') }).length > 1) {
                    pathName = "   [";
                    pathName = pathName.concat("" + customer.getParent().get('name') + "]");
                }
                if (pathName.length < 1) {
                    for (var k = 0; k < collection.length; k++) {
                        if (collection[k].name() === customer.get('name') && !collection[k].elementId) {
                            pathName = "   [";
                            pathName = pathName.concat("" + customer.getParent().get('name') + "]");
                            break;
                        }
                    }
                }
                var customerData = self.checkElementAlreadyExist(customer.get('name'), collection, null, customer.get('id'));
                var description = customer.get('description') ? customer.get('description') : customer.get('name');
                if (customerData && !customerData._destroy) {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + customerData.dataGuid + "' elementId='" + customer.id + "' value='" + customerData.name() + "' description='" + description + "' style=\"cursor: pointer;\" checked /><label  for='" + customerData.dataGuid + "'>" + customerData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
                else if (customerData && customerData._destroy && customer.get('name') !== "") {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + customerData.dataGuid + "' elementId='" + customer.id + "' value='" + customerData.name() + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + customerData.dataGuid + "'>" + customerData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
                else if (!customerData) {
                    var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + dataGuid + "' elementId='" + customer.id + "' value='" + customer.get('name') + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + dataGuid + "'>" + customer.get('name') + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
            }
        }
        return htmlContent;
    };
    fetchActivityNsPath(activity) {
        var self = this;
        var activitynsPath = "";
        var activityPath = activity.getPackagePath();
        activitynsPath = "   [";
        if (activityPath[0] && activityPath[0].name && activityPath[1]) {
            activitynsPath = activitynsPath.concat(activityPath[0].name + "  /  " + activity.get('performingRole').get('name'));
        }
        activitynsPath = activitynsPath.concat("]");
        return activitynsPath;
    };

    fetchValueNsPath(value) {
        var self = this;
        var valuensPath = "";
        var dupAggregatePath = value.getPackagePath();
        if (dupAggregatePath.length === 4) {
            valuensPath = "   [" + dupAggregatePath[0].name + "  /  " + dupAggregatePath[1].name + "  /  " + dupAggregatePath[2].name + "]"
        }
        return valuensPath;
    };

    fetchCompetencyNsPath(competency) {
        var self = this;
        var competencynsPath = "";
        var competencePath = competency.getPackagePath();
        competencynsPath = "   [";
        if (competencePath[0] && competencePath[0].name) {
            competencynsPath = competencynsPath.concat(competencePath[0].name + "  /  " + competency.getNestedParent().get('name'));
        }
        competencynsPath = competencynsPath.concat("]");
        return competencynsPath;
    };
    addActivityData(dimensionBlock, htmlContent, collection, slipNames) {
        var self = this;
        var bm = self.model.get('businessModel');
        var bmActivities;
        if (bm) {
            bmActivities = bm.get('bmActivity');
            for (var i = 0; i < bmActivities.length; i++) {
                var activity = bmActivities.at(i).get('activity');
                var pathName = "";
                if (bmActivities.where({ 'name': activity.get('name') }).length > 1) {
                    pathName = self.fetchActivityNsPath(activity);
                }
                if (pathName.length < 1) {
                    for (var k = 0; k < collection.length; k++) {
                        if (collection[k].name() === activity.get('name') && !collection[k].elementId) {
                            pathName = self.fetchActivityNsPath(activity);
                            break;
                        }
                    }
                    if (pathName.length < 1 && slipNames.indexOf(activity.get('name')) != -1) {
                        pathName = self.fetchActivityNsPath(activity);
                    }
                }
                slipNames.push(activity.get('name'));
                var activityData = self.checkElementAlreadyExist(activity.get('name'), collection, null, activity.get('id'));
                var description = activity.get('description') ? activity.get('description') : activity.get('name');
                if (activityData && !activityData._destroy) {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + activityData.dataGuid + "' elementId='" + activity.id + "' value='" + activityData.name() + "' description='" + description + "' style=\"cursor: pointer;\" checked /><label  for='" + activityData.dataGuid + "'>" + activityData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
                else if (activityData && activityData._destroy && activity.get('name') !== "") {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + activityData.dataGuid + "' elementId='" + activity.id + "' value='" + activityData.name() + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + activityData.dataGuid + "'>" + activityData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
                else if (!activityData) {
                    var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + dataGuid + "' elementId='" + activity.id + "' value='" + activity.get('name') + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + dataGuid + "'>" + activity.get('name') + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
            }
        }
        return htmlContent;
    };

    addResourceData(dimensionBlock, htmlContent, collection, showCompetences, slipNames) {
        var self = this;
        var bm = self.model.get('businessModel');
        var bmCompetencies;
        if (bm) {
            bmCompetencies = bm.get('bmCompetence');
            for (var i = 0; i < bmCompetencies.length; i++) {
                var competence = bmCompetencies.at(i).get('competence');
                if (!competence || (competence.get('type') === 'vdml_CapabilityOffer' && showCompetences.indexOf('CapabilityOffer') === -1)) {
                    continue;
                }
                if (!competence || (competence.get('type') === 'vdml_BusinessItem' && showCompetences.indexOf('Resource') === -1)) {
                    continue;
                }
                var pathName = "";
                if (bmCompetencies.where({ 'name': competence.get('name') }).length > 1) {
                    var compPath = competence.getPackagePath();
                    pathName = "   [";
                    if (compPath[0] && compPath[0].name) {
                        var compType = "" + self.mappingObject[competence.get('type')];
                        pathName = pathName.concat(compPath[0].name + "  /  " + competence.getNestedParent().get('name') + "  /  " + compType);
                    }
                    pathName = pathName.concat("]");
                }
                if (pathName.length < 1) {
                    for (var k = 0; k < collection.length; k++) {
                        if (collection[k].name() === competence.get('name') && !collection[k].elementId) {
                            var compPath = competence.getPackagePath();
                            pathName = "   [";
                            if (compPath[0] && compPath[0].name) {
                                var compType = "" + self.mappingObject[competence.get('type')];
                                pathName = pathName.concat(compPath[0].name + "  /  " + competence.getNestedParent().get('name') + "  /  " + compType);
                            }
                            pathName = pathName.concat("]");
                            break;
                        }
                    }
                }
                slipNames.push(competence.get('name'));
                var competenceData = self.checkElementAlreadyExist(competence.get('name'), collection, null, competence.get('id'));
                var description = competence.get('description') ? competence.get('description') : competence.get('name');
                if (competenceData && !competenceData._destroy) {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + competenceData.dataGuid + "' elementId='" + competence.id + "' value='" + competenceData.name() + "' description='" + description + "' style=\"cursor: pointer;\" checked /><label  for='" + competenceData.dataGuid + "'>" + competenceData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
                else if (competenceData && competenceData._destroy && competence.get('name') !== "") {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + competenceData.dataGuid + "' elementId='" + competence.id + "' value='" + competenceData.name() + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + competenceData.dataGuid + "'>" + competenceData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
                else if (!competenceData) {
                    var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + dataGuid + "' elementId='" + competence.id + "' value='" + competence.get('name') + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + dataGuid + "'>" + competence.get('name') + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                }
            }
        }
        return htmlContent;
    };

    addVPData(dimensionBlock, htmlContent, collection, showMyProposition, slipNames) {
        var self = this;
        var bm = self.model.get('businessModel');
        //var bmVPs = new Backbone.Collection();
        if (bm) {
            var bmRoles = bm.get('bmRole');
            var myVPlist = bm.get('bmValueFormula');
            var vplist = bm.get('bmValueProposition');
            bmRoles.each(function (bmRole) {
                var providedProposition = bmRole.get('providedProposition');
                providedProposition.each(function (vp) {
                    //bmVPs.add(vp);
                    if (!showMyProposition && vp.get('isValueFormula')) {
                        return;
                    }
                    if (!bm.get('bmValueProposition').findWhere({ 'valueProposition': vp }) && !bm.get('bmValueFormula').findWhere({ 'valueFormula': vp })) {
                        return;
                    }
                    var vpName = vp.get('name');
                    var pathName = "";
                    if (vplist.where({ 'name': vp.get('name') }).length > 1 || myVPlist.where({ 'name': vp.get('name') }).length > 1) {
                        var vpPath = vp.getPackagePath();
                        pathName = "   [";
                        if (vpPath[0] && vpPath[0].name && vpPath[1]) {
                            pathName = pathName.concat(vpPath[0].name + "  /  " + vpPath[1].name);
                        }
                        pathName = pathName.concat("]");
                    }
                    if (pathName.length < 1) {
                        for (var k = 0; k < collection.length; k++) {
                            if (collection[k].name() === vp.get('name') && !collection[k].elementId) {
                                var vpPath = vp.getPackagePath();
                                pathName = "   [";
                                if (vpPath[0] && vpPath[0].name && vpPath[1]) {
                                    pathName = pathName.concat(vpPath[0].name + "  /  " + vpPath[1].name);
                                }
                                pathName = pathName.concat("]");
                                break;
                            }
                        }
                        if (pathName.length < 1 && slipNames.indexOf(vp.get('name')) != -1) {
                            var vpPath = vp.getPackagePath();
                            pathName = "   [";
                            if (vpPath[0] && vpPath[0].name && vpPath[1]) {
                                pathName = pathName.concat(vpPath[0].name + "  /  " + vpPath[1].name);
                            }
                            pathName = pathName.concat("]");
                        }
                    }
                    var vpData = self.checkElementAlreadyExist(vp.get('name'), collection, null, vp.get('id'));
                    var description = vp.get('description') ? vp.get('description') : vp.get('name');
                    if (vpData && !vpData._destroy) {
                        htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + vpData.dataGuid + "' elementId='" + vp.id + "' value='" + vpData.name() + "' description='" + vpData.description + "' style=\"cursor: pointer;\" checked /><label  for='" + vpData.dataGuid + "'>" + vpData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                        slipNames.push(vpData.name());
                    }
                    else if (vpData && vpData._destroy && vp.get('name') !== "") {
                        htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + vpData.dataGuid + "' elementId='" + vp.id + "' value='" + vpData.name() + "' description='" + vpData.description + "' style=\"cursor: pointer;\"/><label  for='" + vpData.dataGuid + "'>" + vpData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                        slipNames.push(vpData.name());
                    }
                    else if (!vpData) {
                        var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                        htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + dataGuid + "' elementId='" + vp.id + "' value='" + vpName + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + dataGuid + "'>" + vpName + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                        slipNames.push(vpName);
                    }
                });
            });
        }
        return htmlContent;
    }
    addNetworkData(dimensionBlock, htmlContent, collection, slipNames) {
        var self = this;
        var bm = self.model.get('businessModel');
        var bmPNs;
        if (bm) {
            bmPNs = bm.get('participantNetwork');
            for (var i = 0; i < bmPNs.length; i++) {
                var pn = bmPNs.at(i).get('collaboration');
                var pathName = "";
                for (var j = 0; j < bmPNs.length; j++) {
                    if (bmPNs.at(j) != bmPNs.at(i) && bmPNs.at(j).get('collaboration').get('name') === pn.get('name')) {
                        pathName = "   [";
                        pathName = pathName.concat('' + pn.getParent().get('name') + "]");
                        break;
                    }
                }
                if (pathName.length < 1) {
                    for (var k = 0; k < collection.length; k++) {
                        if (collection[k].name() === pn.get('name') && !collection[k].elementId) {
                            pathName = "   [";
                            pathName = pathName.concat('' + pn.getParent().get('name') + "]");
                            break;
                        }
                    }
                    if (pathName.length < 1 && slipNames.indexOf(pn.get('name')) != -1) {
                        pathName = "   [";
                        pathName = pathName.concat('' + pn.getParent().get('name') + "]");
                    }
                }
                var pnData = self.checkElementAlreadyExist(pn.get('name'), collection, null, pn.get('id'));
                var description = pn.get('description') ? pn.get('description') : pn.get('name');
                if (pnData && !pnData._destroy) {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + pnData.dataGuid + "' elementId='" + pn.id + "' value='" + pnData.name() + "' description='" + description + "' style=\"cursor: pointer;\" checked /><label  for='" + pnData.dataGuid + "'>" + pnData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                    slipNames.push(pnData.name());
                }
                else if (pnData && pnData._destroy && pn.get('name') !== "") {
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + pnData.dataGuid + "' elementId='" + pn.id + "' value='" + pnData.name() + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + pnData.dataGuid + "'>" + pnData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                    slipNames.push(pnData.name());
                }
                else if (!pnData) {
                    var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                    htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + dataGuid + "' elementId='" + pn.id + "' value='" + pn.get('name') + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + dataGuid + "'>" + pn.get('name') + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
                    slipNames.push(pn.get('name'));
                }
            }
        }
        return htmlContent;
    };
    addCostStructureData(dimensionBlock, htmlContent, collection) {
        var self = this;
        var costValues = [];
        var bm = self.model.get('businessModel');
        DataManager.getDataManager().get('currentPlan').getValues(costValues);
        if (bm) {
            bm.getValues(costValues, true);
        }
        for (var i = 0; i < costValues.length; i++) {
            var costValue = costValues[i];
            var costValueData = self.checkElementAlreadyExist(costValue.get('name'), collection, true, costValue.get('id'));
            var pathName = "";
            for (var j = 0; j < costValues.length; j++) {
                if (costValues[j] != costValue && costValues[j].get('name') === costValue.get('name')) {
                    if (costValue.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                        pathName = "   [";
                        pathName = pathName.concat(costValue.getNestedParent().get('name'));
                        pathName = pathName.concat("]");
                    } else if (costValue.get('type') === "vdml_ValuePropositionComponent") {
                        pathName = self.fetchValueNsPath(costValue);
                    } else {
                        var costPath = costValue.getPackagePath();
                        pathName = "   [";
                        if (costPath[0] && costPath[0].name && costPath[1]) {
                            pathName = pathName.concat(costPath[0].name + "  /  " + costPath[1].name);
                        }
                        pathName = pathName.concat("]");
                    }
                    break;
                }
            }
            if (pathName.length < 1) {
                for (var k = 0; k < collection.length; k++) {
                    if (collection[k].name() === costValue.get('name') && !collection[k].elementId) {
                        if (costValue.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                            pathName = "   [";
                            pathName = pathName.concat(costValue.getNestedParent().get('name'));
                            pathName = pathName.concat("]");
                        } else if (costValue.get('type') === "vdml_ValuePropositionComponent") {
                            pathName = self.fetchValueNsPath(costValue);
                        } else {
                            var costPath = costValue.getPackagePath();
                            pathName = "   [";
                            if (costPath[0] && costPath[0].name && costPath[1]) {
                                pathName = pathName.concat(costPath[0].name + "  /  " + costPath[1].name);
                            }
                            pathName = pathName.concat("]");
                        }
                        break;
                    }
                }
            }

            var description = costValue.get('description') ? costValue.get('description') : costValue.get('name');
            if (costValueData && !costValueData._destroy) {
                htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + costValueData.dataGuid + "' elementId='" + costValue.id + "' value='" + costValueData.name() + "' description='" + description + "' style=\"cursor: pointer;\" checked /><label  for='" + costValueData.dataGuid + "'>" + costValueData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
            }
            else if (costValueData && costValueData._destroy && costValue.get('name') !== "") {
                htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + costValueData.dataGuid + "' elementId='" + costValue.id + "' value='" + costValueData.name() + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + costValueData.dataGuid + "'>" + costValueData.name() + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
            }
            else if (!costValueData) {
                var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                htmlContent = htmlContent + "<li><input type=\"checkbox\" id='" + dataGuid + "' elementId='" + costValue.id + "' value='" + costValue.get('name') + "' description='" + description + "' style=\"cursor: pointer;\"/><label  for='" + dataGuid + "'>" + costValue.get('name') + "</label><span class=\"namespacefont\" >" + pathName + "</span></li>";
            }
        }
        costValues.length = 0;
        return htmlContent;
    }
    nameKeyUpHandler(event) {
        var self = this;
        var eventTargetId = event.target.id;
        var startIndex = 'newDimension'.length;
        var i = eventTargetId.substr(startIndex, eventTargetId.indexOf('Name') - startIndex);
        event.stopPropagation();
        var resultName = $('#newDimension' + i + 'Name').val();
        resultName = resultName.trim();
        //var found = false;
        if (resultName !== null && resultName !== "") {
            $('.addDimensionDialogComplete').prop("disabled", false);
        } else {
            //                $('.addDimensionDialogComplete').prop("disabled", true);
        }
    }
    dimensionDialogClickHandler(i, dimensionBlock) {
        var self = this;
        return self['dimensionDialogClickHandler' + i]=function() {
            var resultName = $('#newDimension' + i + 'Name').val();
            resultName = resultName.trim()
            if ($('#newDimension' + i + 'Description').val() === null || $('#newDimension' + i + 'Description').val() === "") {
                $('#newDimension' + i + 'Description').val($('#newDimension' + i + 'Name').val());
            }
            var resultDescription = $('#newDimension' + i + 'Description').val();
            if (resultName !== null && resultName !== "" && $('.warning').hasClass('hide')) {
                var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                var dimensionCheckBoxGroup = $("fieldset#selectDimension" + i + " div ul");
                dimensionCheckBoxGroup.append("<li><input type=\"checkbox\" id='" + dataGuid + "' value='" + resultName + "' description='" + resultDescription + "' style=\"cursor: pointer;\" checked /><label style=\"cursor: pointer;\" for='" + dataGuid + "'>" + resultName + "</label></li>");
                $('#selectDimension' + i + ' div ul li').sort(self.sortCheckBoxes).appendTo('.dimension' + i + 'CheckBox');
            }
            $('#newDimension' + i + 'Div').addClass('hide');
            $('.warning').addClass('hide');
            $('#newDimension' + i + 'Name').val('');
            $('#newDimension' + i + 'Description').val('');
        }
    }
    dimensionButtonClickHandler(i, dimensionBlock) {
        var self = this;
        return self['dimensionButtonClickHandler' + i] = function () {
            event.stopPropagation();
            var resultName = $('#newDimension' + i + 'Name').val();
            resultName = resultName.trim()
            if ($('#newDimension' + i + 'Description').val() === null || $('#newDimension' + i + 'Description').val() === "") {
                $('#newDimension' + i + 'Description').val($('#newDimension' + i + 'Name').val());
            }
            var resultDescription = $('#newDimension' + i + 'Description').val();
            if (resultName !== null && resultName !== "" && $('.warning').hasClass('hide')) {
                var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                var dimensionCheckBoxGroup = $("fieldset#selectDimension" + i + " div ul");
                dimensionCheckBoxGroup.append("<li><input type=\"checkbox\" id='" + dataGuid + "' value='" + resultName + "' description='" + resultDescription + "' style=\"cursor: pointer;\" checked /><label style=\"cursor: pointer;\" for='" + dataGuid + "'>" + resultName + "</label></li>");
                $('#selectDimension' + i + ' div ul li').sort(self.sortCheckBoxes).appendTo('.dimension' + i + 'CheckBox');
                $('#newDimension' + i + 'Name').val('');
                $('#newDimension' + i + 'Description').val('');
            } else {
                $('#newDimension' + i + 'Div').removeClass('hide');
            }
            $('#newDimension' + i + 'Name').focus();
        }
    }
    getIdFromType(type){
        const idList = {
            SWOTAnalysisCanvas: 'four',
            BusinessModelInnovationCanvas: 'six',
            OperatingModelCanvas: 'operation',
            PersonalBusinessModelCanvas: 'nine',
            BusinessModelCanvas: 'nine',
            LeanChangeCanvas: 'twelve',
            LeanCanvas: 'twelve',
            IntegratedReportingCanvas :'integrated'
        };
        return idList[type];
    }
    getSlipWidth(slipHeading, id){
        var self = this;
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        if(context) context.font = self.configObj.dimfontsize + " " + self.configObj.postslipfontfamily;
        var textWidth = context?.measureText(slipHeading).width;
        if (textWidth) textWidth = Math.ceil(textWidth);
        const parentElement = document.getElementById(self.getIdFromType(self.configObj.type)+"-canvas-"+id);
        const parentComputedStyle = getComputedStyle(parentElement);
        const parentWidth = parseFloat(parentComputedStyle.width);
        // Step 3: Calculate 65% of the parent element's width
        const sixtyfivePercentWidth = 0.65 * parentWidth;
        var wt = textWidth ? (textWidth < 25 ? "25%" : (textWidth > sixtyfivePercentWidth ? "65%" :`${textWidth}px`)) : "auto";
        return wt;
    }
    dialogCompleteHandler(dimId, dimObj, dimensionBlock) {
        var self = this;
        return self['dialogCompleteHandler' + dimId] = function () {
            var dimObjId = dimObj.id;
            dimId = dimObjId.substr('dimension'.length);
            var dimensionCollectionObs = self['dimension' + dimId + 'Collection'];
            var dimensionCollection = dimensionCollectionObs();
            event.stopPropagation();
            var resultName = $('#newDimension' + dimId + 'Name').val();
            resultName = resultName.trim();
            var resultDescription = $('#newDimension' + dimId + 'Description').val();
            if (resultName !== null && resultName !== "" && $('.warning').hasClass('hide')) {
                var dataGuid = DataManager.getDataManager().guidGeneratorByOwner(dimensionBlock);
                var dimensionCheckBoxGroup = $("fieldset#selectDimension" + dimId + " div ul");
                dimensionCheckBoxGroup.append("<li><input type=\"checkbox\" id='" + dataGuid + "' value='" + resultName + "' description='" + resultDescription + "' style=\"cursor: pointer;\" checked /><label style=\"cursor: pointer;\" for='" + dataGuid + "'>" + resultName + "</label></li>");
            }
            $('#newDimension' + dimId + 'Div').addClass('hide');
            $('.warning').addClass('hide');
            $('#newDimension' + dimId + 'Name').val('');
            $('#newDimension' + dimId + 'Description').val('');
            var selectedDimensionData = $("fieldset#selectDimension" + dimId + " input:checked");
            for (var i = 0; i < selectedDimensionData.length; i++) {
                if (selectedDimensionData[i].value !== "") {
                    var dimensionName;
                    var dimensionDescription;
                    var dimensionId = $(selectedDimensionData[i]).attr('elementId');
                    var itemId = selectedDimensionData[i].id;
                    var canvasItemName = " ";
                    var bmElement = "";
                    var capabilityItemName = '';
                    var capElementId = '';
                    var syncName = false;
                    var modelTypeArray = self.mappedDimensionType(dimObj);
                    var dimensionElement;
                    for (var j = 0; j < modelTypeArray.length; j++) {
                        dimensionElement = Backbone.Relational.store.getObjectByName(modelTypeArray[j]).find({ id: dimensionId });
                        if (dimensionElement) {
                            dimensionName = dimensionElement.get('name');
                            dimensionDescription = dimensionElement.get('description');
                            canvasItemName = dimensionName;
                            bmElement = self.getBMElementType(dimensionElement);
                            var capEle = dimensionElement.get('canvasItem') ? self.getCapElementFromMappedObject(dimensionElement.get('canvasItem')) : dimensionElement.get('capabilityItem');
                            if (capEle) {
                                capabilityItemName = capEle.get('name');
                                capElementId = capEle.id;
                            }
                            syncName = $(selectedDimensionData[i]).attr('syncName');
                            if (syncName && dimensionElement.get('canvasItem')) {
                                dimensionName = dimensionElement.get('canvasItem').get('name');
                                dimensionDescription = dimensionElement.get('canvasItem').get('description');
                            }
                            break;
                        }
                    }
                    modelTypeArray.length = 0;
                    if (!dimensionElement) {
                        dimensionName = selectedDimensionData[i].value;
                        dimensionDescription = $(selectedDimensionData[i]).attr('description');
                    }
                    var dimensionWithValue = dimensionName;
                    var slipName = dimensionName;
                    if (dimensionElement && dimensionElement.get('valueMeasurement')) {
                        var mest;
                        if (dimensionElement.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                            mest = dimensionElement.getMeasurement('valueMeasurement', self.currentAlternativeModel.get('phaseObjectiveSet'));
                        } else if (self.context) {
                            mest = dimensionElement.getMeasurement('valueMeasurement', self.context);
                        }
                        if (mest) {
                            var unit = mest.get('observedMeasure').get('measure').get('unit');
                            dimensionWithValue = dimensionName + " (" + window.utils.getRoundedValues(mest.get('value'), unit.get('significantDecimals')) + (unit ? " " + unit.get('name') : "") + ")";
                        }
                    }
                    var smileySrc = '';
                    var smileyColor = '';
                    if (dimensionElement && dimensionElement.get('satisfactionLevel')) {
                        var selectedInterval = self.getSatInterval(dimensionElement);
                        if (selectedInterval) {
                            smileySrc = self.fetchSmileySrc(selectedInterval.get('smiley'));
                            smileyColor = selectedInterval.get('smileyColor');
                        }
                    }

                    if (!self.checkItemAlreadyExist(itemId, dimensionCollection)) {
                        var namePath = " ";
                        var top = self.getRandomArbitrary(1, 60) + "%";
                        var left = self.getRandomArbitrary(1, 60) + "%";
                        var color = self.previouslyUsedColor ? self.previouslyUsedColor : "rgba(255,255,127,0.5)";
                        if (dimensionCollection.length && self.checkIdAlreadyExist(itemId, dimensionCollection)) {
                            dimensionCollectionObs.remove(self.checkIdAlreadyExist(itemId, dimensionCollection));
                        }
                        if (dimensionElement) {
                            for (var j = 0; j < dimensionCollectionObs().length; j++) {
                                if (dimensionCollectionObs()[j].type === self.labelValueToDisplay(dimId) && dimensionCollectionObs()[j].canvasItemName === canvasItemName) {
                                    namePath = "   [";
                                    if (dimensionElement.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                                        namePath = namePath.concat(dimensionElement.getNestedParent().get('name'));
                                        namePath = namePath.concat("]");
                                    } else {
                                        if (dimensionElement.get('type') === "vdml_Activity") {
                                            namePath = self.fetchActivityNsPath(dimensionElement);
                                        } else if (dimensionElement.get('type') === "vdml_ValuePropositionComponent") {
                                            namePath = self.fetchValueNsPath(dimensionElement);
                                        } else if (dimensionElement.get('type') === "vdml_BusinessItem" || dimensionElement.get('type') === "vdml_CapabilityOffer") {
                                            namePath = self.fetchCompetencyNsPath(dimensionElement);
                                        } else if (dimensionElement.get('type') === "vdml_BusinessNetwork") {
                                            namePath = namePath.concat(dimensionElement.getNestedParent().get('name'));
                                            namePath = namePath.concat("]");
                                        } else {
                                            var vpPath = dimensionElement.getPackagePath();
                                            if (vpPath[0] && vpPath[0].name && vpPath[1]) {
                                                namePath = namePath.concat(vpPath[0].name + "  /  " + vpPath[1].name);
                                            }
                                            namePath = namePath.concat("]");
                                        }
                                    }
                                    var obj;
                                    for (var k = 0; k < self.allDimensionsCollection().length; k++) {
                                        if (self.allDimensionsCollection()[k].dataGuid === dimensionCollectionObs()[j].dataGuid) {
                                            obj = self.allDimensionsCollection()[k];
                                            break;
                                        }
                                    }
                                    if (obj) {
                                        var item = self.model.get('bmCanvasBlock').find({ name: obj.type }).get('blockItem').findWhere({ 'id': obj.dataGuid });
                                        if (item) {
                                            self.allDimensionsCollection.remove(obj);
                                            var subNamePath = "   [";
                                            if (item.get('canvasItem').getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                                                subNamePath = subNamePath.concat(item.get('canvasItem').getNestedParent().get('name'));
                                            } else {
                                                if (item.get('canvasItem').get('type') === "vdml_Activity") {
                                                    subNamePath = self.fetchActivityNsPath(item.get('canvasItem'));
                                                } else if (item.get('canvasItem').get('type') === "vdml_ValuePropositionComponent") {
                                                    subNamePath = self.fetchValueNsPath(item.get('canvasItem'));
                                                } else if (item.get('canvasItem').get('type') === "vdml_BusinessItem" || item.get('canvasItem').get('type') === "vdml_CapabilityOffer") {
                                                    subNamePath = self.fetchCompetencyNsPath(item.get('canvasItem'));
                                                } else if (item.get('canvasItem').get('type') === "vdml_BusinessNetwork") {
                                                    subNamePath = subNamePath.concat(item.get('canvasItem').getNestedParent().get('name'));
                                                    subNamePath = subNamePath.concat("]");
                                                } else {
                                                    var slipPath = item.get('canvasItem').getPackagePath();
                                                    if (slipPath[0] && slipPath[0].name && slipPath[1]) {
                                                        subNamePath = subNamePath.concat(slipPath[0].name + "  /  " + slipPath[1].name);
                                                        subNamePath = subNamePath.concat("]");
                                                    }
                                                }
                                            }
                                            obj.namePath = subNamePath;
                                            self.allDimensionsCollection.push(obj);
                                        }

                                    }
                                    break;
                                }
                            }
                        }
                        var slipHeading = dimensionWithValue ? dimensionWithValue : slipName;
                        var textWidth = self.getSlipWidth(slipHeading, dimObjId);
                        dimensionCollectionObs.push({ id: window.utils.htmlEscape(itemId), dataGuid: itemId, syncName: syncName, elementId: dimensionId, smileySrc: smileySrc, smileyColor: smileyColor, name: ko.observable(dimensionWithValue), description: dimensionDescription, slipName: slipName, slipTop: top, slipLeft: left, slipColor: color, type: self.labelValueToDisplay(dimId), canvasItemName: canvasItemName, capabilityItemName: capabilityItemName, capElementId: capElementId, namePath: namePath, bmElement: bmElement,slipWidth: textWidth,slipHeight:'auto' });
                        var postElementText = $("div[data-guid='" + dataGuid + "'] .post-it-text");
                        window.utils.applyFontSizeByElement(postElementText);
                        self.enableComplete(true);
                        $("[data-guid='" + itemId + "']")[0].onmouseover = self.resizableStart;
                    }
                }
                else {
                    var itemId = selectedDimensionData[i].id;
                    if (self.checkItemAlreadyExist(itemId, dimensionCollection)) {
                        for (var j = 0; j < dimensionCollection.length; j++) {
                            if (dimensionCollection[j].dataGuid === itemId) {
                                dimensionCollectionObs.destroy(dimensionCollection[j]);
                                break;
                            }
                        }
                    }
                }
            }
            var unselectedDimensionData = $("fieldset#selectDimension" + dimId + " input:not(:checked)");
            for (var i = 0; i < unselectedDimensionData.length; i++) {
                var itemId = unselectedDimensionData[i].id;
                if (self.checkItemAlreadyExist(itemId, dimensionCollection)) {
                    for (var j = 0; j < dimensionCollection.length; j++) {
                        if (dimensionCollection[j].dataGuid === itemId) {
                            dimensionCollectionObs.destroy(dimensionCollection[j]);
                            self.enableComplete(true);
                            break;
                        }
                    }
                }
            }
            self.setResizeHeight();
            self.adjustSlipsTable();
        }
    }
    addDimensionHandler(i) {
        var self = this;
        return self['addDimensionHandler' + i] = function () {
            self.loadDimensionsData('dimension' + i);
            var dimObj = self.dimensions[_.findLastIndex(self.dimensions, { id: 'dimension' + i })];
            var dimensionBlock = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
            var dimensionCollectionObs = self['dimension' + i + 'Collection'];
            var dimensionCollection = dimensionCollectionObs();
            var autoCompAttr = 'new-password';
            if(window.browser == "chrome"){
                autoCompAttr = 'off';
            }
            var htmlContent = "<fieldset class=\"group\" id=\"selectDimension" + i + "\" style=\" margin-bottom:0px;overflow-y: auto;height: 200px;overflow-x: hidden;\">";
            htmlContent = htmlContent + "<div class=\"row\">";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-5\">";
            htmlContent = htmlContent + "<span>  "+DataManager.getDataManager().get('localeManager').get('addAnother')+" <icon style=\"font-size: 1.2em;\"  class=\"glyphicon glyphicon-plus-sign glyphicon-button\" id=\"newDimension" + i + "Button\"></icon></span>";
            htmlContent = htmlContent + "</div></div>";
            htmlContent = htmlContent + "<div id=\'newDimension" + i + "Div'\" class=\"row hide\">";
            htmlContent = htmlContent + "<br><div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-10\">";
            htmlContent = htmlContent + "<form class=\"form-inline\"><div class=\"form-group\">";
            htmlContent = htmlContent + "<input id=\"newDimension" + i + "Name\" style=\"width: 100px; margin-right:5px;\" class=\"form-control\" placeholder=\"Name\" type=\"text\" autocomplete ="+ autoCompAttr +"/></div>";
            htmlContent = htmlContent + "<div class=\"form-group\"><input id=\"newDimension" + i + "Description\" style=\"width: 120px\" class=\"form-control\" placeholder=\"Description\" type=\"text\"/></div>";
            htmlContent = htmlContent + "</form>";
            htmlContent = htmlContent + "<span class=\" warning hide\" style=\"font-size: 12px;color: orange;\">Name already exists</span></div>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"checkbox\"><ul class = \"radio dimension" + i + "CheckBox\">";
            htmlContent = self.addDimensionsData('dimension' + i, htmlContent, dimensionCollection);
            htmlContent = htmlContent + "</div></fieldset>";


            var dimensionDialog = bootbox.dialog({
                title: "Enter " + self.labelValueToDisplay(i),
                message: htmlContent,
                show: false,
                className: "modalMedium resizeBox",
                buttons: {
                    main: {
                        label: "Close",
                        className: "btn btn-default",
                    },
                    success: {
                        label: "Complete",
                        className: "btn btn-complete addDimensionDialogComplete",
                        callback: self.dialogCompleteHandler(i, dimObj, dimensionBlock)
                    }
                }
            });


            $("#newDimension" + i + "Name").click(function () {
                event.stopPropagation();
            });
            $("#newDimension" + i + "Name").change(function () {
                $("#newDimension" + i + "Description").val($("#newDimension" + i + "Name").val());
            });
            $("#newDimension" + i + "Description").click(function () {
                event.stopPropagation();
            });
            $("#newDimension" + i + "Description").focus(function () {
                $('#newDimension' + i + 'Description').val($('#newDimension' + i + 'Name').val());
            });
            $("#newDimension" + i + "Name").keyup(self.nameKeyUpHandler);
            var dimensionButton = $("#newDimension" + i + "Button");

            dimensionButton.click(self.dimensionButtonClickHandler(i, dimensionBlock));
            dimensionDialog.click(self.dimensionDialogClickHandler(i, dimensionBlock));
            //              $('.addDimensionDialogComplete').prop("disabled", true);
            dimensionDialog.modal('show');
            $('.radio').click(function () {
                $('.addDimensionDialogComplete').prop("disabled", false);
            });
            $('#selectDimension' + i).find('div ul li').sort(self.sortCheckBoxes).appendTo('.dimension' + i + 'CheckBox');
        };

    }
        
    labelValueToDisplay(dimId) {
        var self = this;
        if (self.configObj && self.dimensions[dimId - 1].rowColumnHeader) {
            return self.dimensions[dimId - 1].rowColumnHeader;
        } else if (self.labels['dimension' + dimId]) {
            return self.labels['dimension' + dimId]();
        }
    };

        //creating a copy
    createRevisionBasedOnContext(revisionCheck, callback) {
        var self = this;
        if (window.checkContextForRevision() && revisionCheck) {
            window.utils.startSpinner('revisionSpinner', "Creating a copy...");
            function fetchBmcModel(newVdmPackage) {
                if (newVdmPackage) {
                    var modelId = self.model.get('id');
                    var modelSuffix = modelId.substr(modelId.lastIndexOf('@') + 1);
                    var newId = DataManager.getDataManager().get('viewAlternative') + '' + modelSuffix;
                    self.model = newVdmPackage.get('canvas').findWhere({ 'id': newId });
                    var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                    self.creatingRevision = true;
                    window.utils.updateObservablesForRevision(self.CanvasDesignerViewModel, oldAltIdReg, []);
                    DataManager.getDataManager().releaseSaveLock();
                    DataManager.getDataManager().set('isActive', false);
                    window.utils.stopSpinner('revisionSpinner');
                    callback(true);
                    /*DataManager.getDataManager().saveData({
                        success: function () {
                            window.utils.stopSpinner('revisionSpinner');
                            callback(true);
                        },
                        error: function () {
                            window.utils.stopSpinner('revisionSpinner');
                            callback(false);
                        },
                        persist: true
                    });*/
                }
            }
            window.setTimeout(function () {
                DataManager.getDataManager().acquireSaveLock(function () {
                    self.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmcModel);
                });
            }, 100);
        }
        else {
            callback(false);
        }
    };
    saveDocumentation() {
        var self = this;
        for (var x = 0; x < self.documentationArray.length; x++) {
            if (self.documentationArray[x]["save"] && !self.documentationArray[x]["del"]) {
                if (!self.documentationArray[x]["id"]) {
                    var bmObj = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: self.documentationArray[x]["dataguide"] });
                    var annotationId = DataManager.getDataManager().guidGeneratorByOwner(bmObj);
                    var annotation = new Annotation({ id: annotationId, name: self.documentationArray[x]["name"], definition: self.documentationArray[x]["definition"], annotationOwner: bmObj });
                    bmObj.get("annotation").add(annotation);
                }
                else {
                    var bmObj = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: self.documentationArray[x]["dataguide"] });
                    for (var y = 0; y < bmObj.get("annotation").length; y++) {
                        if (self.documentationArray[x]["id"] === bmObj.get("annotation").at(y).get("id")) {
                            bmObj.get("annotation").models[y].set({ definition: self.documentationArray[x]["definition"] });
                            bmObj.get("annotation").models[y].set({ name: self.documentationArray[x]["name"] });

                        }
                    }
                }
            }
            if (self.documentationArray[x]["del"]) {
                var bmObj = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: self.documentationArray[x]["dataguide"] });
                for (var y = 0; y < bmObj.get("annotation").length; y++) {
                    if (self.documentationArray[x]["id"] === bmObj.get("annotation").models[y].get("id"))
                        bmObj.get("annotation").models[y].destroy();
                }
            }
        }
        self.documentationArray = [];
    };
    saveData(callback, revisionCheck) {
        var self = this;
        window.utils.startSpinner('saveCanvas', "Saving Canvas...");
        window.setTimeout(function () {
            self.saveDocumentation();
            self.createRevisionBasedOnContext(revisionCheck, function (revision) {
                
                self.saveSyncData(revision)
                self.saveAndRefreshView(function () {
                    window.utils.stopSpinner('saveCanvas');
                    callback(revision);
                });
            });
        }, 100);
    }

    saveShapeDetailsData(name, description, canvasId, elementId, dimensionId) {
        var self = this;
        var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: canvasId });
        var mappedElementModel = item ? item.get('canvasItem') : null;
        if (!mappedElementModel) {
            var dimObj = self.dimensions[_.findLastIndex(self.dimensions, { id: dimensionId })];
            var modelTypeArray = self.mappedDimensionType(dimObj);
            for (var j = 0; j < modelTypeArray.length; j++) {
                mappedElementModel = Backbone.Relational.store.getObjectByName(modelTypeArray[j]).find({ id: elementId });
                if (mappedElementModel) {
                    break;
                }
            }
        }
        var found = false;
        for (var i = 0; i < self.children.length; i++) {
            if (self.children[i].modal == mappedElementModel) {
                self.children[i].name = name;
                self.children[i].description = description;
                found = true;
                break;
            }
        }
        if (!found) {
            self.children.push({ 'name': name, 'description': description, 'modal': mappedElementModel });
        }
    }
    saveSyncData(revision) {
        var self = this;
        for (var i = 0; i < self.children.length; i++) {
            var vdmlModal = self.children[i].modal;
            if (revision) {
                var modelSuffix = vdmlModal.id.substr(vdmlModal.id.lastIndexOf('@') + 1);
                var type = vdmlModal.get('type').replace('_', '.')
                vdmlModal = Backbone.Relational.store.getObjectByName("" + type).find({ id: DataManager.getDataManager().get('viewAlternative') + modelSuffix });
            }
            if (vdmlModal) {
                vdmlModal.set('name', self.children[i].name);
                vdmlModal.set('description', self.children[i].description);
            }
        }
        self.children = [];
    }

    saveAndRefreshView(callback) {
        var self = this;
        self.saveBMCanvasDiagramSize();
        for (var i = 1; i <= self.dimensions.length; i++) {
            self.saveDimensionsData(i);
        }
        self.enableComplete(false);
        self.refreshTemporaryCollections();
        self.adjustSlipsTable();
        if (self.showDashboardCanvas) {
            var areaOfCanvas = $('#js-canvas' + self.encodeId).width() * $('#js-canvas' + self.encodeId).height();
            $('#canvas-group' + self.encodeId + ' .post-it-text').css('font-size', 0.015 * Math.sqrt(areaOfCanvas));
        }
        if (callback) {
            callback();
        }
    };
    editOrAddDocumentation(view, data) {
        var self = this;
        var modalTitle = "Create Documentation";
        var docuId = null;
        if (view.target.id != "newdocid") {
            docuId = view.target.parentElement.id;
            modalTitle = "Edit Documentation";
        }
        var documentationName;
        var htmlContent = "<div class=\"row\">";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-4\">";
        htmlContent = htmlContent + "<span class=\"control-label\">Name<label style=\"color:orange\">*</label></span>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-6\">";
        htmlContent = htmlContent + "<input class=\"form-control \" type=\"text\" id ='documentationName'></input>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "</div><br>";
        htmlContent = htmlContent + "<div class=\"row\">";
        htmlContent = htmlContent + "<div id=\"removeElement\" class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "<div id=\"changeClass\" style=\"border-color: rgb(193, 224, 255); border-width: 1px; border-style: solid;\" class=\"col-xs-10\">";
        htmlContent = htmlContent + "<div id='summernote'></div></div>";
        htmlContent = htmlContent + "</div>";
        bootbox.dialog({
            title: '  &nbsp;' + modalTitle,
            message: htmlContent,
            buttons: {
                main: {
                    label: "Close",
                    className: "btn btn-default pull-left",
                },
                success: {
                    label: "Complete",
                    className: "docComplete btn btn-default ",
                    callback: function () {
                        $('.docComplete').show();
                        var summernote = $('#summernote');
                        documentationName = $('#documentationName');
                        var contentData = summernote.summernote('code');
                        var contentJQuery = $(contentData);
                        contentData = "";

                        contentJQuery.each(function () {
                            contentData = contentData + this.outerHTML;
                        });
                        if (contentJQuery.length == 0) {
                            contentData = summernote.summernote('code');
                        }
                        if (docuId === null) {
                            if (documentationName[0].value) {

                                var lengthOfArray = self.documentationArray.length;
                                self.documentationArray.push({ id: null, dataguide: data.dataGuid, name: documentationName[0].value, definition: contentData, save: false, del: false, lenId: lengthOfArray, editname: null, editdefinition: null, candel: false });
                                $("#lineid").append("<div id=\"" + lengthOfArray + "\">" + documentationName[0].value + "<icon customId = \"" + lengthOfArray + "\" class=\" addDocumentation glyphicon glyphicon-pencil glyphicon-button \" style=\"font-size: large\"></icon><icon   class=\"glyphicon glyphicon-trash glyphicon-button delDocumentation\" style=\"font-size: large\"></icon>" + "</div>");
                            }
                        }
                        else {
                            for (var x = 0; x < self.documentationArray.length; x++) {
                                var lenId = self.documentationArray[x]["lenId"];
                                if (lenId.toString() == docuId) {
                                    $("#" + self.documentationArray[x]["lenId"]).html("").html(documentationName[0].value + "<icon customId = \"" + lenId + "\" class=\" addDocumentation glyphicon glyphicon-pencil glyphicon-button \" style=\"font-size: large\"></icon><icon   class=\"glyphicon glyphicon-trash glyphicon-button delDocumentation\" style=\"font-size: large\"></icon>");
                                    self.documentationArray[x]["editname"] = self.documentationArray[x]["name"];
                                    self.documentationArray[x]["editdefinition"] = self.documentationArray[x]["definition"];
                                    self.documentationArray[x]["name"] = documentationName[0].value;
                                    self.documentationArray[x]["definition"] = contentData;
                                    self.documentationArray[x]["save"] = true;
                                }
                            }
                        }

                        $('.delDocumentation').unbind("click").click(function () {
                            for (var x = 0; x < self.documentationArray.length; x++) {
                                var nameDef = self.documentationArray[x]["lenId"];
                                if (nameDef.toString() === this.parentElement.id) {
                                    self.documentationArray[x]["del"] = true;
                                    $("#" + self.documentationArray[x]["lenId"]).remove();
                                }
                            }
                        });
                        $('.addDocumentation').unbind("click").click(function (view, event) {
                            window.vdmModelView.editOrAddDocumentation(view, data);
                        });


                    }
                }
            }
        });
        self.initializeSummernote(true, docuId);
    };

    initializeSummernote(airMode, docuId) {
        var self = this;
        if (!docuId) {
            $("#completeid").disabled = true;
            $('.docComplete').attr('disabled', 'disabled');
        }
        var summernote = $('#summernote');
        summernote.summernote('destroy');

        summernote.summernote(
            {
                airMode: airMode,
                dialogsInBody: true,
                minHeight: null,             // set minimum height of editor
                maxHeight: null,             // set maximum height of editor
                focus: true,
                placeholder: 'write here...',
                disableResizeEditor: true, //disable resize 
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['font', ['fontname', 'fontsize', 'color', 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']],
                    ['para', ['style', 'ul', 'ol', 'paragraph']],
                    ['height', ['height']],
                    // ['insert', [ 'link', 'table', 'hr']]
                ]
            });
        summernote.find('.note-statusbar').hide();
        $('.note-insert').hide();
        $('.note-table').hide();
        $('#documentationName').attr('enabled', 'enabled');
        if (docuId) {
            for (var x = 0; x < self.documentationArray.length; x++) {
                var lenId = self.documentationArray[x]["lenId"];
                if (lenId == docuId) {
                    var definitionValue = self.documentationArray[x]["definition"];
                    documentName = self.documentationArray[x]["name"];
                    break;
                }
            }
            summernote.summernote('code', definitionValue);
            summernote.summernote("editor.clearHistory", 'editor');
            $('#documentationName').val(documentName);
        }
        if (!window.checkContextForRevision()) {
            $('#documentationName').keyup(function (view) {
                if (view.currentTarget.value.trim() != "") {
                    $('.docComplete').removeAttr('disabled');
                } else {
                    $('.docComplete').attr('disabled', 'disabled');
                }
            });
        }
        var modalBody = summernote.parents('.modal-body');
        modalBody.css('max-height', '400px');
        modalBody.css('overflow-y', 'auto');
        $("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
        if (window.checkContextForRevision()) {
            $('.docComplete').attr('disabled', 'disabled');
        }
    };
    mapSelectedItems(data, callback) {
        var self = this;
        var selectedDim = data.type;
        var selectedSlip = data.dataGuid;
        var revisionCheck = true;//self.slipRevision.length > 0 ? true : false;
        self.saveData(function (revision) {
            var model = self.model;
            if (revision) {
                $('.slipDetailsBox').removeClass('push-back');
                $('.slipDetailsBox').modal('hide');
                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/canvas_BMCanvasDiagram/" + DataManager.getDataManager().get('viewAlternative') + "", { trigger: true });
            } else {
                if (self.wizard) {
                    self.wizard.parentView = null;
                    window.cleanViewModel(self.wizard, $('#wizardModalContent'));
                }
                var wizardOptions = _.extend({}, self.options);
                wizardOptions.parentView = self;
                var configObj = self.getConfigObj(true);
                self.wizard = MappingWizardViewModel.getInstance(self.model, wizardOptions, configObj, selectedDim, selectedSlip, function (model, revisionCanvas) {
                    if (!revisionCanvas) {
                        self.refreshTemporaryCollections();
                        var canvasItem = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid }).get('canvasItem');
                        data.canvasItemName = canvasItem ? canvasItem.get('name') : "";
                        data.bmElement = self.getBMElementType(canvasItem);
                    }
                    callback(data, revisionCanvas);
                });
                self.wizard.startWizard();
            }
        }, revisionCheck);
    };
    sortByName(left, right) {
        return left.get('name').toLowerCase() === right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1);
    }
        
    closeDocumentationBootBox() {
        var self = this;
        for (var x = 0; x < self.documentationArray.length; x++) {
            if (self.documentationArray[x]["editname"] != null) {
                self.documentationArray[x]["name"] = self.documentationArray[x]["editname"];
                self.documentationArray[x]["definition"] = self.documentationArray[x]["editdefinition"];
            }
            if (self.documentationArray[x]["del"] && !self.documentationArray[x]["candel"]) {
                self.documentationArray[x]["del"] = false;
            }
            if (!self.documentationArray[x]["save"] && !self.documentationArray[x]["id"]) {
                delete self.documentationArray[x];
                self.documentationArray.length--;
            }
        }
    }
    checkTargetElementExist(data){
        var self = this;
        var isExist = false;
        if(self.tempDeleteArray && self.tempDeleteArray.length > 0){
            for(var i=0;i<self.tempDeleteArray.length;i++){
                if(self.tempDeleteArray[i].itemId === data.dataGuid){
                    isExist = true;
                    break;
                }
            }
        }
        return isExist;
    }
    deleteTempArray(data){
        var self = this;
        for(var i=self.tempDeleteArray.length-1;i>=0;i--){
            if(self.tempDeleteArray[i].itemId === data.dataGuid){
                self.tempDeleteArray.splice(i,1);
                break;
            }
        }
    }
    getSlipDetails(data, ev) {
        var self = this;
        if (!self.editMode) {
            return;
        }
        var hideicon = false;
        if (window.checkContextForRevision()) {
            hideicon = true;
        }
        var bmObj = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
        if (bmObj) {
                for (var x = 0; x < bmObj.get("annotation").length; x++) {
                var nameDefId = bmObj.get("annotation").models[x].get('id');
                var exist = false;
                for (var y = 0; y < self.documentationArray.length; y++) {
                    if (self.documentationArray[y]["id"] === nameDefId)
                        exist = true;
                }
                if (!exist) {
                    self.documentationArray.push({ id: bmObj.get("annotation").models[x].get('id'), dataguide: data.dataGuid, name: bmObj.get("annotation").models[x].get('name'), definition: bmObj.get("annotation").models[x].get('definition'), save: false, del: false, lenId: self.documentationArray.length, editname: null, editdefinition: null, candel: false });
                }
            }
        }
        var name, description;
        if (data.capabilityItemName == " ") {
            name = data.capabilityItemName ? data.capabilityItemName : data.name();
            description = data.capabilityItemName ? data.capabilityItemName : data.description;
        } else {
            name = data.name();
            description = data.description;
        }
        if (data.bmElement === 'Plan Value' || data.bmElement === 'Value') {
            name = data.slipName;
        }
        var dimension = data.type;
        var presentColor = data.slipColor;
        data.slipColor = data.slipColor.replace(/ /g, '');
        function getDimensionId() {
            for (var i = 1; i <= self.dimensions.length; i++) {
                var item = $.grep(self['dimension' + i + 'Collection'](), function (dim) { return dim.dataGuid === data.dataGuid; });
                if (item.length) {
                    return 'dimension' + i;
                }
            }
        }
        var isExist = self.checkTargetElementExist(data);
        var dimensionId = getDimensionId();
        var htmlContent = "<div class=\"row\">";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-3\">";
        htmlContent = htmlContent + "<span class=\"control-label\">Name</span>";
        htmlContent = htmlContent + "<label style = 'color:orange'>*</label>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-7\">";
        htmlContent = htmlContent + "<input id=\"name\" class=\"form-control focus-ele\" autofocus type=\"text\" value='" + name + "'></input>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "</div>";

        htmlContent = htmlContent + "<div class=\"row\">";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-3\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-7\"><span style='font-size: 12px;color: orange;' id='message" + self.encodeId + "'></span></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "</div><br>";
        if (description) {
            htmlContent = htmlContent + "<div class=\"row\">";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">";
            htmlContent = htmlContent + "<span class=\"control-label\">Description</span>";
            htmlContent = htmlContent + "<label style = 'color:orange'>*</label>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-7\">";
            htmlContent = htmlContent + "<input id=\"description\" class=\"form-control focus-ele\" autofocus type=\"text\" value='" + description + "'/></input>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "</div><br>";
        }

        htmlContent = htmlContent + "<div class=\"row\">";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-3\">";
        htmlContent = htmlContent + "<span class=\"control-label\">Dimension</span>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-7\">";
        htmlContent = htmlContent + "<span>" + dimension + "</span>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "</div>";

        var mappedTo = data.canvasItemName;
        var tempTargetObj = _.find(self.children, function (obj) { return obj.modal.id == data.elementId; });
        if (tempTargetObj) {
            mappedTo = tempTargetObj.name;
        }
        //var capMappedTo = data.capabilityItemName;
        var mappedToType = data.bmElement;

        htmlContent = htmlContent + "<div class=\"row capMapped hide\"><br>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-3\">";
        htmlContent = htmlContent + "<span class=\"control-label\">Capability Element</span>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-7\">";
        htmlContent = htmlContent + "<span id=\"capMappedTo\" title=\"Map Capability\" class=\"glyphicon glyphicon-plus-sign glyphicon-button\" style></span><span id='capabilityName'></span>&nbsp;&nbsp;<span id=\"showCapMappedEdit\" title=\"Edit Capability\" class=\"glyphicon glyphicon-pencil glyphicon-button\" style=\"font-size:medium\"></span><span id=\"showCapMappedInfo\" title=\"Target Details\" class=\"glyphicon glyphicon-info-sign glyphicon-button\" style=\"font-size:medium;color:orange\"></span>&nbsp;&nbsp;<span id=\"removeCapMapping\" class=\"glyphicon glyphicon-trash glyphicon-button\" style=\"font-size:medium\"></span>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "</div>";
        
            htmlContent = htmlContent + "<div class=\"row mapped hide\"><br>";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">";
            htmlContent = htmlContent + "<span class=\"control-label\">Target Element</span>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-7\">";
            htmlContent = htmlContent + "<span id=\"mappedTo\">" + mappedTo + "</span>&nbsp;&nbsp;<span id=\"showMappedInfo\" title=\"Target Details\" class=\"glyphicon glyphicon-info-sign glyphicon-button\" style=\"font-size: medium; color:orange;\"></span>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "</div>";
        
        if (bmObj) {
            htmlContent = htmlContent + "<div class=\"row\"><br>";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">";
            htmlContent = htmlContent + "<span class=\"control-label\">"+DataManager.getDataManager().get('localeManager').get('Documentations')+"</span>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-7\">";
            if (!hideicon) {
                htmlContent = htmlContent + "<span>"+DataManager.getDataManager().get('localeManager').get('addAnother')+" <span><icon id =\"newdocid\" class=\"glyphicon glyphicon-plus-sign glyphicon-button adddocumentation\" style=\"font-size: medium\"></icon></span></span>";
            }
            htmlContent = htmlContent + "<div id=\"lineid\">";
            for (var x = 0; x < self.documentationArray.length; x++) {
                if (self.documentationArray[x]["dataguide"] === data.dataGuid && !self.documentationArray[x]["del"]) {
                    if (self.documentationArray[x]["save"]) {
                        htmlContent = htmlContent + "<div id=\"" + self.documentationArray[x]["lenId"] + "\">" + self.documentationArray[x]["name"] + "<icon class=\"glyphicon glyphicon-pencil glyphicon-button adddocumentation \" style=\"font-size: large\"></icon>";
                        if (!hideicon)
                            htmlContent = htmlContent + "<icon  class=\"glyphicon glyphicon-trash glyphicon-button deletedocumentation\" style=\"font-size: large\"></icon>" + "</div>";
                        else {
                            htmlContent = htmlContent + "</div>"
                        }
                    }
                    else {
                        if (self.documentationArray[x]["id"]) {
                            htmlContent = htmlContent + "<div id=\"" + self.documentationArray[x]["lenId"] + "\">" + self.documentationArray[x]["name"] + "<icon class=\"glyphicon glyphicon-pencil glyphicon-button adddocumentation\" style=\"font-size: large\"></icon>";
                            if (!hideicon)
                                htmlContent = htmlContent + "<icon  class=\"glyphicon glyphicon-trash glyphicon-button deletedocumentation\" style=\"font-size: large\"></icon>" + "</div>";
                            else {
                                htmlContent = htmlContent + "</div>"

                            }
                        }
                    }
                }
            }

            htmlContent = htmlContent + "</div></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "</div>";
        }

        

        
            htmlContent = htmlContent + "<div class=\"row mapped hide\"><br>";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">";
            htmlContent = htmlContent + "<span class=\"control-label\">"+DataManager.getDataManager().get('localeManager').get('SyncTargetElement')+"</span>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-7\">";
            htmlContent = htmlContent + "<input checked: \"false\", id=\"syncName\" type=\"checkbox\">";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "</div>";
        

            htmlContent = htmlContent + "<div class=\"row mapped hide\"><br>";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">";
            htmlContent = htmlContent + "<span class=\"control-label\">"+DataManager.getDataManager().get('localeManager').get('targetElementType')+"</span>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-7\">";
            htmlContent = htmlContent + "<span id=\"mappedToType\">" + mappedToType + "</span></div>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "</div>";
        

        htmlContent = htmlContent + "<div class=\"row\"><br>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-3\">";
        htmlContent = htmlContent + "<span class=\"control-label\">Pick a color</span>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-6\">";
        htmlContent = htmlContent + "<div class=\"colors-wrapper\"></div>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"><icon id=\"addAnotherColor\" class=\"glyphicon glyphicon-plus-sign glyphicon-button\" style=\"font-size: '1.2em'\"></icon></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "</div><br>";

        htmlContent = htmlContent + "<div class=\"row colorpickerComp hide\">";
        htmlContent = htmlContent + "<div class=\"col-xs-4\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-7\"><div id=\"slipsColor\" class=\"input-group colorpicker-component\">";
        htmlContent = htmlContent + "<input type=\"text\" value='" + presentColor + "' class=\"form-control\" />";
        htmlContent = htmlContent + "<span class=\"input-group-addon\"><i></i></span></div></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div><br><br></div>";

        var box = bootbox.dialog({
            title: "<div>&nbsp;&nbsp;&nbsp;Slip Details</div>",
            message: htmlContent,
            show: false,
            buttons: {
                danger: {
                    label: "&nbsp;Delete",
                    className: "btn btn-danger glyphicon glyphicon-trash pull-left deleteSlipDialog",
                    callback: function () {
                        var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
                        var blockItemModel = item ? item.get('canvasItem') : null;
                        var selectedBmsModelsList = [];
                        self.businessObject = data;
                        var htmlContent = "<div class=\"row\">";
                        //htmlContent = htmlContent + "<form style = 'margin-left:15px'>";
                        htmlContent = htmlContent + "<span style ='margin-left:15px'>Do you want to delete the Slip?</span>";
                        if (blockItemModel && !window.checkContextForRevision()) {
                            if (blockItemModel.get('type') == "vdml_ValuePropositionComponent" || blockItemModel.get('type') == "vdml_ValueAdd") {
                                htmlContent = "<span id= 'deleteShapeElement'><input type=\"checkbox\" style=\"cursor: pointer;\" checked /><span style ='margin-left:5px'>Delete value from Plan</span></span>";
                            } else {
                                htmlContent = "<span id= 'deleteShapeElement' style = 'font-weight:bold'>Do you want to delete this element from following Business Models? Deleting it may lead to loss of data, that cannot be recovered, do you want to continue ?</span><br></br>";
                                htmlContent += "<table class='table table-bordered' style = 'width:450px;margin-left:50px'><thead><tr>";
                                htmlContent += "<th>Business Model(s)</th></tr></thead>";
                                htmlContent += "<tbody id= 'bmsModelsList'>";
                                htmlContent += "</tbody></table>"
                            }
                        }
                        //htmlContent = htmlContent + "<div id = 'bmList'></form>"
                        htmlContent = htmlContent + "</div>";

                        var dialog = bootbox.dialog({
                            title: "<div>&nbsp;&nbsp;&nbsp;Shape Deletion</div>",
                            message: htmlContent,
                            show: true,
                            buttons: {
                                main: {
                                    label: "Cancel",
                                    className: "btn btn-default",
                                    callback: function () {
                                        self.closeDocumentationBootBox();
                                    }
                                },
                                success: {
                                    label: '<i id="canvasShapeDeleteBtn" class="fa fa-check"></i> Delete',
                                    className: "btn btn-complete shapeDelete",
                                    callback: function () {
                                        self.deleteSlip(data);
                                        if ($('#deleteShapeElement').length > 0 && blockItemModel) {
                                            window.utils.startSpinner('deletionSpinner', "Deleting Selected Element...");
                                            window.setTimeout(function () {
                                                var selectedBm = [];
                                                var bms = selectedBmsModelsList;
                                                for (var i = 0; i < bms.length; i++) {
                                                    if ($('#' + window.utils.htmlEscape(bms[i].id)).prop('checked')) {
                                                        var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: bms[i].id });
                                                        selectedBm.push(bm);
                                                    }
                                                }
                                                var businessObject = { $type: blockItemModel.get('type').replace('_', ':'), mid: blockItemModel.id };
                                                EcoMapMixin.deleteTargetElement(businessObject, selectedBm, function () {
                                                    window.setTimeout(function () {
                                                        DataManager.getDataManager().invokeValueUpdates(function(){
                                                            window.utils.stopSpinner('deletionSpinner');
                                                        },false);
                                                    },10);
                                                });
                                            }, 100);
                                        }
                                    }
                                }
                            }
                        })
                        dialog.attr("id", "bootbox_id");
                        $('#bootbox_id').draggable(); debugger
                        if (data.elementId && !window.checkContextForRevision()) {
                            var htmlContent = '';
                            if (blockItemModel) {
                                self.model.getNestedParent().checkBmModels(blockItemModel, function (bmsList) {
                                    if (bmsList && !$('#bmsLists').length) {
                                        bmsList.sort(self.sortByName);
                                        for (var a = 0; a < bmsList.length; a++) {
                                            selectedBmsModelsList.push({ id: bmsList[a].get('id') });
                                            htmlContent = "<tr><td><span id = 'bmsLists' style = 'padding-left:5px'><input type=\"checkbox\" id='" + window.utils.htmlEscape(bmsList[a].id) + "'  value='" + bmsList[a].get('name') + "' style=\"cursor: pointer;\" checked /><span>" + " " + bmsList[a].get('name') + "</span></span></tr></td>";
                                            $('#bmsModelsList').append(htmlContent);
                                        }
                                    }
                                })
                            }
                        }
                    }
                },
                main: {
                    label: "Close",
                    className: "btn btn-default",
                    callback: function () {
                        self.closeDocumentationBootBox();

                    }
                },
                mapping: {
                    label: "Add Mapping",
                    className: "btn btn-default btn-mapping hide",
                    callback: function () {
                        box.addClass('push-back');
                        self.mapSelectedItems(data, function (data, revisionCanvas) {
                            box.removeClass('push-back');
                            if (revisionCanvas) {
                                box.modal('hide');
                                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + revisionCanvas.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + revisionCanvas.getNestedParent().get('version') + "/" + revisionCanvas.get('id') + "/canvas_BMCanvasDiagram/" + DataManager.getDataManager().get('viewAlternative') + "", { trigger: true });
                            }
                            else if (data.canvasItemName) {
                                $('.mapped').removeClass('hide');
                                $('#mappedTo').html(data.canvasItemName);
                                $('#mappedToType').html(data.bmElement);
                                box.find('.btn-update').addClass('hide');
                                box.find('.btn-deleteMapping').removeClass('hide');
                            }
                        });
                        return false;
                    }
                },
                deleteMapping: {
                    label: "Delete Mapping",
                    className: "btn btn-default btn-deleteMapping hide",
                    callback: function () {
                        self.createRevisionBasedOnContext(true, function (revision) {
                            var model = self.model;
                            var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
                            var type = '';
                            if(item && item.get('canvasItem')){
                                type = item.get('canvasItem').get('type');
                            }
                            item.set('canvasItem', null);
                            item.set('syncName', false);
                            //data.canvasItemName = ""; data.capElementId = ''; 
                            data.capabilityItemName = '';
                            data.bmElement = "";data.name("")
                            if (revision) {
                                self.revision = true;
                                box.modal('hide');
                                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + model.getNestedParent().get('version') + "/" + model.get('id') + "/canvas_BMCanvasDiagram/" + DataManager.getDataManager().get('viewAlternative') + "", { trigger: true });
                            } else{
                                $('#mappedTo').html('');
                                $('#syncName').prop("checked", false);
                                $('#name').prop("disabled", false);
                                $('#description').prop("disabled", false);
                                $('#mappedToType').html(''); 
                                //$('#capabilityName').html('');
                                box.find('.btn-update').removeClass('hide');
                                box.find('.btn-mapping').addClass('hide');
                                box.find('.btn-deleteMapping').addClass('hide');
                                $('.mapped').addClass('hide');
                                /*if (type == "vdml_Activity" || type === "vdml_CapabilityOffer") {
                                    $('.capMapped').removeClass('hide');
                                    $('#capMappedTo').show(); $('#showCapMappedInfo').hide();
                                    $('#showCapMappedEdit').hide();
                                    $('#removeCapMapping').hide();
                                }*/
                                self.refreshTemporaryCollections();
                            }
                            if(!self.tempDeleteArray){
                                self.tempDeleteArray =[];
                            }
                            self.tempDeleteArray.push({itemId : item.id, slipId : item.get('blockItemOwner').id});
                        });
                        return false;
                    }
                },
                update: {
                    label: "Create Mapping",
                    className: "btn btn-default btn-update hide",
                    callback: function () {
                        box.addClass('push-back');
                        self.mapSelectedItems(data, function (data, revisionCanvas) {
                            box.removeClass('push-back');
                            if (revisionCanvas) {
                                box.modal('hide');
                                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + revisionCanvas.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + revisionCanvas.getNestedParent().get('version') + "/" + revisionCanvas.get('id') + "/canvas_BMCanvasDiagram/" + DataManager.getDataManager().get('viewAlternative') + "", { trigger: true });
                            }
                            else if (data.canvasItemName) {
                                $('.mapped').removeClass('hide');
                                $('#mappedTo').html(data.canvasItemName);
                                $('#mappedToType').html(data.bmElement);
                                box.find('.btn-update').addClass('hide');
                                box.find('.btn-mapping').removeClass('hide');
                                box.find('.btn-deleteMapping').removeClass('hide');
                                if ((data.capabilityItemName != "" || data.capElementId != "") && $('#capabilityName').text()) {
                                    $('#capabilityName').html($('#capabilityName').text());
                                    $('#capMappedTo').hide();
                                    $('#showCapMappedInfo').show();
                                    $('#showCapMappedEdit').hide();
                                    $('#removeCapMapping').hide();
                                } else {
                                    $('.capMapped').addClass('hide');
                                }
                            }
                            if(self.tempDeleteArray && self.tempDeleteArray.length > 0){
                                self.deleteTempArray(data);
                            } 
                            
                        });
                        return false;
                    }
                },
                success: {
                    label: "Complete",
                    className: "btn btn-complete slipComplete",
                    callback: function () {
                        if ($('.dwrapper[active="active"] .dcolor').length) {
                            var color = $('.dwrapper[active="active"]').attr('rel');
                            color = color.replace(/ /g, '');
                            if (data.slipColor != color) {
                                data.slipColor = color;
                                self.previouslyUsedColor = color;
                                self.enableComplete(true);
                            }
                        }
                        for (var x = 0; x < self.documentationArray.length; x++) {
                            if (self.documentationArray[x]["editname"]) {
                                self.documentationArray[x]["editname"] = null;
                                self.documentationArray[x]["editdefinition"] = null;
                            }
                            if (!self.documentationArray[x]["save"]) {
                                if (!self.documentationArray[x]["id"]) {
                                    self.documentationArray[x]["save"] = true;
                                    self.enableComplete(true);
                                }
                            }
                            if (self.documentationArray[x]["save"]) {
                                self.enableComplete(true);

                            }
                            if (self.documentationArray[x]["del"]) {
                                self.documentationArray[x]["candel"] = true;
                                self.enableComplete(true);
                            }
                        }
                        var newName = $('#name').val();
                        if (data.bmElement === 'Plan Value' || data.bmElement === 'Value') {
                            var valueUnitString = data.name().replace(data.slipName, "");
                            data.slipName = newName;
                            newName = newName.concat(valueUnitString);
                        }
                        if (data.name() != newName) {
                            data.name(newName);
                            self.enableComplete(true);
                        }
                        if (data.description != $('#description').val()) {
                            data.description = $('#description').val();
                            self.enableComplete(true);
                        };
                        if (data.capabilityItemName != $('#capabilityName').html()) {
                            data.capabilityItemName = $('#capabilityName').html();
                            self.enableComplete(true);
                        }
                        if (data.syncName != $('#syncName').is(":checked")) {
                            data.syncName = $('#syncName').is(":checked");
                            self.enableComplete(true);
                        }
                        if (data.syncName) {
                            self.saveShapeDetailsData($('#name').val().trim(), data.description, data.dataGuid, data.elementId, dimensionId);
                        }
                        if(!self.checkTargetElementExist(data)){
                            self[dimensionId + 'Collection'].remove(function (item) { return item.dataGuid === data.dataGuid });
                            self[dimensionId + 'Collection'].push(data);
                        }
                        if ($("div[data-guid='" + data.dataGuid + "']")[0]) {
                            $("div[data-guid='" + data.dataGuid + "']").find('.text-wrapper p').attr('contenteditable', !data.syncName);
                        }
                        if(self.enableComplete()){
                            self.cleanModal();
                        }
                    }
                }
            }
        });
        if(isExist){
            $('#mappedTo').html('');
            $('#syncName').prop("checked", false);
            $('#name').prop("disabled", false);
            $('#description').prop("disabled", false);
            $('#mappedToType').html(''); 
            //$('#capabilityName').html('');
            box.find('.btn-update').removeClass('hide');
            box.find('.btn-mapping').addClass('hide');
            box.find('.btn-deleteMapping').addClass('hide');
            $('.mapped').addClass('hide');
        }
        box.modal('show');
        $('.bootbox .modal-content').draggable({
            handle: "#modelHeaderOfCanvas" +self.encodeId,
            //handle: ".modal-header",
            containment: "#content"
        });
        $(".bootbox-close-button").click(function () {
            self.closeDocumentationBootBox();
        });
        if (data.elementId) {
            $('#removeCapMapping').hide();
        }
        box.addClass('slipDetailsBox');
        /*if(DataManager.getDataManager().get('readMode') || window.utils.checkIsLinked(null,true)){
			$(".btn-complete").hide();$(".btn-danger").hide();$(".glyphicon-trash").hide();$(".glyphicon-plus-sign").hide();$(".glyphicon-minus").hide();
			$(".read-access").hide();
        }*/
        var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
        var dimensionJson = _.find(self.dimensions, function (num) { return num.id == dimensionId; });

        if (dimensionJson && dimensionJson.mapping && (dimensionJson.mapping.includes('activity') || dimensionJson.mapping.includes('competence'))) {
            if (!(self.configObj.mappingRules && self.configObj.mappingRules[dimensionId] && self.configObj.mappingRules[dimensionId][0].resourceTypes[0].type === 'Resource')) {
                if (item && item.get('canvasItem')) {
                    if (item.get('canvasItem').get('capabilityRequirement')) {
                        //item.set('capabilityItem', item.get('canvasItem').get('capabilityRequirement'));
                        $('#capabilityName').html(item.get('canvasItem').get('capabilityRequirement').get('name'));
                        $('#capMappedTo').hide(); $('.capMapped').removeClass('hide'); $('#removeCapMapping').hide();
                        box.find('.capMap').addClass('hide'); $('#showCapMappedInfo').show(); $('#showCapMappedEdit').hide();
                    } else if (item.get('canvasItem').get('capability')) {
                        $('#capabilityName').html(item.get('canvasItem').get('capability').get('name'));
                        $('#capMappedTo').hide(); $('.capMapped').removeClass('hide'); $('#removeCapMapping').hide();
                        box.find('.capMap').addClass('hide'); $('#showCapMappedInfo').show(); $('#showCapMappedEdit').hide();
                    } else {
                        $('.capMapped').addClass('hide');
                    }
                } else if (item) {
                    if (item.get('canvasItem') == null && item.get('capabilityItem') == null) {
                        $('.capMapped').removeClass('hide');
                        $('#capMappedTo').show();
                        $('#removeCapMapping').hide();
                        $('#showCapMappedInfo').hide();
                        $('#showCapMappedEdit').hide();
                    } else if (item.get('capabilityItem')) {
                        $('.capMapped').removeClass('hide');
                        $('#capabilityName').html(item.get('capabilityItem').get('name')); $('#capMappedTo').hide();
                        $('#removeCapMapping').show();
                        $('#showCapMappedInfo').hide();
                        $('#showCapMappedEdit').show();
                    } else {
                        $('.capMapped').removeClass('hide');
                        $('#capMappedTo').show();
                        $('#removeCapMapping').hide();
                        $('#showCapMappedInfo').hide();
                        $('#showCapMappedEdit').hide();
                    }
                    box.find('.capMap').addClass('hide');
                } else if (!item && !data.elementId) {
                    $('.capMapped').removeClass('hide');
                    $('#capMappedTo').show();
                    $('#removeCapMapping').hide();
                    $('#showCapMappedInfo').hide();
                    $('#showCapMappedEdit').hide();
                } else if (!item && data.elementId != "" && (data.bmElement === "Activity" || data.bmElement === 'Capability')) {
                    var type = data.bmElement === 'Activity' ? 'vdml.Activity' : 'vdml.Competence';
                    var model = Backbone.Relational.store.getObjectByName(type).find({ id: data.elementId });
                    if (model && (model.get('capabilityRequirement') || model.get('capability'))) {
                        $('.capMapped').removeClass('hide');
                        $('#capabilityName').html(model.get('capabilityRequirement') ? model.get('capabilityRequirement').get('name') : model.get('capability').get('name'))
                        $('#capMappedTo').hide();
                        $('#removeCapMapping').hide();
                        $('#showCapMappedInfo').hide();
                        $('#showCapMappedEdit').hide();
                    }
                }
            }
        }
        if (mappedTo.trim() !== "" && !self.modelNotLinked()) {  //linked and mapped
            box.find('.btn-update').addClass('hide');
            box.find('.btn-deleteMapping').removeClass('hide');
            box.find('.btn-mapping').removeClass('hide');
            $('.mapped').removeClass('hide');
            $('#syncName').prop("checked", data.syncName);
            if (!item) {
                box.find('.btn-deleteMapping').prop("disabled", true);
                $('#showMappedInfo').hide();
            }
        } else if (!self.modelNotLinked()) {  //linked and not mapped
            if (dimensionId) {
                box.find('.btn-update').removeClass('hide');
                var mapping = self.dimensions[_.findLastIndex(self.dimensions, { 'id': dimensionId })].mapping;
                if (!mapping) {
                    box.find('.btn-update').prop("disabled", true);
                }
                $('.mapped').addClass('hide');
            }
        } else if (self.modelNotLinked()) {  //not linked
            if (dimensionId) {
                var mapping = self.dimensions[_.findLastIndex(self.dimensions, { 'id': dimensionId })].mapping;
                if (_.contains(mapping, 'value')) {
                    if (mappedTo.trim() === "") {
                        box.find('.btn-deleteMapping').addClass('hide');
                        box.find('.btn-update').removeClass('hide');
                        $('.mapped').addClass('hide');
                    } else {
                        box.find('.btn-deleteMapping').removeClass('hide');
                        box.find('.btn-update').addClass('hide');
                        $('.mapped').removeClass('hide');
                        var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
                        if (!item) {
                            box.find('.btn-deleteMapping').prop("disabled", true);
                            $('#showMappedInfo').hide();
                        }
                    }
                }
            }
        }
        $(document).on('colorAdd', '.colorpicker', function () {
            if (!self.usedSlipColors) {
                return;
            }
            $('.colorpickerComp').addClass('hide');
            var lengthPrev = self.usedSlipColors.length;
            var value = $('#slipsColor input').val();
            value = value.replace(/ /g, '');
            self.usedSlipColors.push(value);
            $.unique(self.usedSlipColors);
            var length = self.usedSlipColors.length;
            if (length > lengthPrev) {
                var wrapper_id = 'w' + self.getRandomInt(1, 1000);
                $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '"> </div>').appendTo('.colors-wrapper');
                $('<div class="dcolor" style="background-color:' + value + '"> </div>').appendTo('#' + wrapper_id);
            }
        });
        $('#name').change(function () {
            $('#description').val($('#name').val());
        });
        $('#description').keyup(function(view){
                var val = view.currentTarget.value ? false : true;
                $('.slipComplete').prop('disabled',val)
        });
        $("#name").keyup(function (view) {
            var val = view.currentTarget.value ? false : true;
            $('.slipComplete').prop('disabled',val)
            if ($('#syncName').is(":checked")) {
                var newValue = view.currentTarget.value.trim();
                var alt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
                var parentType = "";
                var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
                var canvasMapModal = item ? item.get('canvasItem') : null;
                if (canvasMapModal) {
                    if (canvasMapModal.get('type') == "vdml_ValueProposition" || canvasMapModal.get('type') == "vdml_ValueFormula" || canvasMapModal.get('type') == "vdml_ValueStream") {
                        parentType = "providedProposition";
                    } else if (canvasMapModal.get('type') == "vdml_Activity") {
                        parentType = "activity";
                    } else if (canvasMapModal.get('type') == "vdml_OrgUnit" || canvasMapModal.get('type') == "vdml_MarketSegment" || canvasMapModal.get('type') == "vdml_Actor" || canvasMapModal.get('type') == "vdml_BusinessNetwork") {
                        parentType = "collaboration";
                    } else if (canvasMapModal.get('type') == "vdml_Party") {
                        parentType = "collaborationRole";
                    } else if (canvasMapModal.get('type') == "CapabilityOffer") {
                        parentType = "capabilityOffer";
                    } else if (canvasMapModal.get('type') == "vdml_BusinessItem") {
                        parentType = "businessItem";
                    } else if (canvasMapModal.get('type') == "vdml_ValuePropositionComponent") {
                        parentType = "component";
                    } else if (canvasMapModal.get('type') == "vdml_ValueAdd") {
                        parentType = "valueAdd";
                    }
                    var disallowedNames = parentType ? alt.getDisallowedNames(canvasMapModal, parentType) : null;
                    var unique = canvasMapModal.getNestedParent().checkUniqueName(newValue, canvasMapModal, disallowedNames);
                    if (!unique || newValue == '') {
                        $(".slipComplete").attr("disabled", true);
                        $('#message' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get('DuplicateSyncMessage'));
                    } else {
                        $(".slipComplete").attr("disabled", false);
                        $('#message' + self.encodeId).text("");
                    }
                }
            }
        });
        $('#addAnotherColor').on('click', function () {
            //event.stopPropagation();
            $('#slipsColor').colorpicker();
            $('.colorpickerComp').removeClass('hide');
        });
        $('.adddocumentation').on('click', function (view, event) {
            self.editOrAddDocumentation(view, data);
        });

        $('.deletedocumentation').on('click', function (temp) {
            for (var x = 0; x < self.documentationArray.length; x++) {
                var nameDef = self.documentationArray[x]["lenId"];
                if (nameDef == this.parentElement.id) {
                    self.documentationArray[x]["del"] = true;
                    $("#" + self.documentationArray[x]["lenId"]).remove();
                    break;
                }
            }
        });
        $('#syncName').on('click', function (val) {
            if ($(this).is(':checked')) {
                $('#name').val("" + $('#mappedTo').html());
                var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
                if (item && item.get('canvasItem') && item.get('canvasItem').get('description')) {
                    $('#description').val("" + item.get('canvasItem').get('description'));
                }
            } else {
                $('#name').prop("disabled", false);
                $('#description').prop("disabled", false);
            }
        });
        $('#removeCapMapping').on('click', function () {
            $('#capabilityName').text(null);
            data.capElementId = null;
            $('#removeCapMapping').hide();
            $('#capMappedTo').show();
            $('#showCapMappedInfo').hide();
            $('#showCapMappedEdit').hide();
        });
        $('#showMappedInfo').on('click', function () {
            //event.stopPropagation();
            box.addClass('push-back');
            var slipId = data.dataGuid;
            var mappingElementId = data.elementId;
            var mappingElementType = data.bmElement;
            var dimensionMapping, selectedSlipModal, mappedSlipModal, name, description;
            var dimObj = self.dimensions[_.findLastIndex(self.dimensions, { id: dimensionId })];
            if (slipId) {
                selectedSlipModal = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: slipId });
                if (selectedSlipModal) {
                    mappedSlipModal = selectedSlipModal.get('canvasItem');
                }
            }
            if (selectedSlipModal || mappingElementId) {
                if (dimObj.mapping.length === 1) {
                    dimensionMapping = dimObj.mapping[0];
                }
                if ((dimensionMapping === "partner" || dimensionMapping === "customer") || (!dimensionMapping && (mappingElementType === self.mappingObject['vdml_Community'] || mappingElementType === self.mappingObject['vdml_OrgUnit'] || mappingElementType === self.mappingObject['vdml_Actor']))) {
                    if (!mappedSlipModal) {
                        if (mappingElementType === self.mappingObject['vdml_Community']) {
                            mappedSlipModal = Backbone.Relational.store.getObjectByName('vdml.Community').find({ id: mappingElementId });
                        } else if (mappingElementType === self.mappingObject['vdml_OrgUnit']) {
                            mappedSlipModal = Backbone.Relational.store.getObjectByName('vdml.OrgUnit').find({ id: mappingElementId });
                        } else if (mappingElementType === self.mappingObject['vdml_Actor']) {
                            mappedSlipModal = Backbone.Relational.store.getObjectByName('vdml.Actor').find({ id: mappingElementId });
                        }
                    }
                } else if (dimensionMapping === "activity" || (!dimensionMapping && mappingElementType === self.mappingObject['vdml_Activity'])) {
                    if (!mappedSlipModal) {
                        mappedSlipModal = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: mappingElementId });
                    }
                } else if (dimensionMapping === "value" || (!dimensionMapping && (mappingElementType === self.mappingObject["vdml_ValuePropositionComponent"] || mappingElementType === self.mappingObject["vdml_ValueAdd"]))) {
                    if (!mappedSlipModal) {
                        var element = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: mappingElementId });
                    }
                    if (element || (mappedSlipModal && mappedSlipModal.get('type') === 'vdml_ValuePropositionComponent')) {
                        if (!mappedSlipModal) {
                            mappedSlipModal = element;
                        }
                    } else {
                        if (!mappedSlipModal) {
                            element = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: mappingElementId });
                        }
                        if (element || (mappedSlipModal && mappedSlipModal.get('type') === 'vdml_ValueAdd')) {
                            if (!mappedSlipModal) {
                                mappedSlipModal = element;
                            }
                        }
                    }
                } else if (dimensionMapping === "competence" || (!dimensionMapping && (mappingElementType === self.mappingObject["vdml_BusinessItem"] || mappingElementType === self.mappingObject["vdml_CapabilityOffer"]))) {
                    if (mappingElementType === self.mappingObject["vdml_BusinessItem"]) {
                        if (!mappedSlipModal) {
                            mappedSlipModal = Backbone.Relational.store.getObjectByName('vdml.BusinessItem').find({ id: mappingElementId });
                        }
                    } else if (mappingElementType === self.mappingObject["vdml_CapabilityOffer"]) {
                        if (!mappedSlipModal) {
                            mappedSlipModal = Backbone.Relational.store.getObjectByName('vdml.CapabilityOffer').find({ id: mappingElementId });
                        }
                    }
                } else if (dimensionMapping === "valueProposition") {
                    if (!mappedSlipModal) {
                        mappedSlipModal = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: mappingElementId });
                    }
                } else if (!dimensionMapping && mappingElementType === self.mappingObject["vdml_BusinessNetwork"]) {
                    if (!mappedSlipModal) {
                        mappedSlipModal = Backbone.Relational.store.getObjectByName('vdml.BusinessNetwork').find({ id: mappingElementId });
                    }
                }
            }
            var id = window.utils.htmlEscape(window.guidGenerator());
            var options = {};
            options.context = self.context;
            options.mappedModal = mappedSlipModal;
            options.width = '800px';
            window.getAndCreateModalDialog(self, id, BMCanvasDiagramMixin, self.model.get('businessModel'), "TargetDetails", null, options);
        });
        $('#capMappedTo').on('click', function () {
            box.addClass('push-back');
            self.mapCapabilities(data, function (data, revisionCanvas) {
                box.removeClass('push-back');
            });
        });
        $('#showCapMappedInfo').on('click', function () {
            box.addClass('push-back');
            var capMappingElementId = data.capElementId;
            var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
            if (item) {
                if (item.get('canvasItem') && item.get('canvasItem').get('capabilityRequirement')) {
                    capMappingElementId = item.get('canvasItem').get('capabilityRequirement').id;
                } else if (item.get('capabilityItem')) {
                    capMappingElementId = item.get('capabilityItem').id;
                }
            }
            if (capMappingElementId) {
                var id = window.utils.htmlEscape(window.guidGenerator());
                var currentPlan = DataManager.getDataManager().get('currentPlan');
                var options = {
                    capabilityLibs: self.capabilityLibs, targetDetailsMode: true, callback: function (capId, capName, capDesc, capParentId) {
                        if (!capId) {
                            return;
                        }
                        box.removeClass('push-back');
                    }
                };
                if (capMappingElementId) {
                    options.showCapabilityId = capMappingElementId;
                    options.infoIcon = true;
                }
                window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
            }
            else {
                bootbox.alert("No data found");
                box.removeClass('push-back');
                return;
            }
        });
        $('#showCapMappedEdit').on('click', function () {
            box.addClass('push-back');
            var capMappingElementId = data.capElementId;
            var item = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: data.dataGuid });
            if (item) {
                if (item.get('canvasItem') && item.get('canvasItem').get('capabilityRequirement')) {
                    capMappingElementId = item.get('canvasItem').get('capabilityRequirement').id;
                } else if (item.get('capabilityItem')) {
                    capMappingElementId = item.get('capabilityItem').id;
                }
            }
            if (capMappingElementId) {
                var id = window.utils.htmlEscape(window.guidGenerator());
                var currentPlan = DataManager.getDataManager().get('currentPlan');
                var options = {
                    capabilityLibs: self.capabilityLibs, targetDetailsMode: false, callback: function (capId, capName, capDesc, capParentId) {
                        if (!capId) {
                            return;
                        }
                        data.capElementId = capId;//data.capabilityItemName = capName;
                        $('#capabilityName').html(capName);
                        $('#capMappedTo').hide(); $('#removeCapMapping').show();
                        $('#showCapMappedEdit').show(); $('#showCapMappedInfo').hide();
                    }
                };
                if (capMappingElementId) {
                    options.showCapabilityId = capMappingElementId;
                    options.infoIcon = true;
                }
                window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
            }

        });
        $.each(self.usedSlipColors, function (it, value) {
            if (!$('div[rel=\'' + value + '\']').length) {
                var wrapper_id = 'w' + it;
                if (value === data.slipColor) {
                    $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '" active="active"> </div>').appendTo('.colors-wrapper');
                } else {
                    $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '"> </div>').appendTo('.colors-wrapper');
                }
                $('<div class="dcolor" style="background-color:' + value + '"> </div>').appendTo('#' + wrapper_id);
            }
        });
        $('.colors-wrapper').on('click', '.dwrapper', function () {
            //event.stopPropagation();
            var colorBoxes = $('.dwrapper');
            for (var i = 0; i < colorBoxes.length; ++i) {
                if (colorBoxes[i] !== this) {
                    colorBoxes[i].removeAttribute("active");
                }
            }
            if (!this.active) {
                this.setAttribute("active", "active");
            }
            var color = $(this).children()[0].style.backgroundColor;
            color = color.replace(/ /g, '');
        });

        if (self.capabilityLibs.length == 0) {
            box.find('.capMap').addClass('hide');
        }
    };
    getCapabilityElementModel(id) {
        var self = this;
        var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: id });
        if (!capModel) {
            capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: id });
        }
        return capModel;
    }
    mapCapabilities(data, callback) {
        var self = this;
        var id = window.utils.htmlEscape(window.guidGenerator());
        var options = { capabilityLibs: self.capabilityLibs, callback: mapCapability };
        window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
        function mapCapability(capId, capName, capDesc, capParentId, revisionCanvas) {
            if (!capId) {
                callback(data, revisionCanvas);
                return;
            }
            $('#capabilityName').html(capName);
            $('#capMappedTo').hide();
            $('#showCapMappedEdit').show(); $('#showCapMappedInfo').hide();
            $('#removeCapMapping').show();
            data.capElementId = capId;
            if(!$('#name').val()){
                $('#name').val(capName);
                $('#description').val(capName);
            }
            $('.slipComplete').prop('disabled',false)
            callback(data, revisionCanvas);
        }
    }
    deleteSlip(data, ev) {
        var self = this;
        for (var i = 1; i <= self.dimensions.length; i++) {
            var item = $.grep(self.colls['dimension' + i + 'Collection'](), function (e) { return e.dataGuid === data.dataGuid; });
            if (item.length) {
                self.colls['dimension' + i + 'Collection'].destroy(item[0]);
                item[0].deleted = true;
                self.enableComplete(true);
                self.cleanModal();
                break;
            }
        }
    }
    enableEdit(data, ev) {
        var self = this;
        if (!self.editMode)
            return;
        $($(ev.currentTarget).find('.glyphicon')[0]).show();
        $($(ev.currentTarget).find('.glyphicon')[1]).show();
        var blockElement = $(ev.currentTarget).hasClass('canvas-element') ? $(ev.currentTarget) : $(ev.currentTarget).parents('.canvas-element');
        var blockWidth = blockElement.css('width');
        blockWidth = blockWidth.substr(0, blockWidth.indexOf('px'));
        if (data.slipWidth && data.slipWidth instanceof String) {
            if (data.slipWidth.indexOf('%') > 0) {
                var slipWidthWithoutUnit = data.slipWidth.substr(0, data.slipWidth.indexOf('%'));
                var slipWidth = Math.round(blockWidth * slipWidthWithoutUnit / 100);
            } else {
                slipWidth = Math.round(data.slipWidth.substr(0, data.slipWidth.indexOf('px')));
            }
        }else{
            slipWidth = data.slipWidth;
        }
        if (data.slipHeight && data.slipHeight instanceof String) {
            var slipHeight = Math.round(data.slipHeight.substr(0, data.slipHeight.indexOf('px')));
        }else{
            var slipHeight = data.slipHeight;
        }
        if (Math.abs($(ev.currentTarget).width() - slipWidth) > 1) {
            self.enableComplete(true);
        }
        function slipResize() {
            var currentWidth = $(ev.currentTarget).width();
            var currentHeight = $(ev.currentTarget).height();
            if (currentWidth !== slipWidth || currentHeight !== slipHeight) {
                self.enableComplete(true);
                slipWidth = currentWidth;
                slipHeight = currentHeight;
            }
        }
        slipResize();
        ev.stopPropagation();
    }

    cleanUp() {
        var self = this;
        if (self.wizard) {
            window.cleanViewModel(self.wizard, $('#wizardModalContent'));
        }
        if (self.canvasPage) {
            $(window, ".resize").unbind('resize');
        }
        if (ko.components.isRegistered('CanvasGuidance')) {
            ko.components.unregister('CanvasGuidance');
        }
        if (ko.components.isRegistered('CanvasDetails')) {
            ko.components.unregister('CanvasDetails');
        }
        //window.utils.loadTemplate(null,true);
        if (self.spliter) {
            self.spliter.enhsplitter('remove');
        }
        $(document).off('colorAdd');
    };
    initializePresentation() {
        var self = this;
        //self.afterRenderView(true);
        //self.fetchBmCounts();
    };
    static getInstance(model, options) {
        return new CanvasDesignerViewModel(model, options);
    };
}
path.CanvasDesignerViewModel = CanvasDesignerViewModel;
