import * as _ from 'underscore'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {PlanMixin} from './PlanMixin'
import {NamedPlanElementMixin} from './NamedPlanElementMixin'
import {PatternLookupTableDefinition} from '../lookup/PatternLookupTableDefinition'
import { PlanScenario } from './PlanScenario'
import { ScenarioExecution } from './ScenarioExecution'
import { ScenarioPathStep } from './ScenarioPathStep'
import { PeriodDataset } from './PeriodDataset'
import {ScenarioDataType} from '../vdml/ScenarioDataType'
import { ValueType } from '../vdml/ValueType'

var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);

export class Plan2Mixin {
    static getMixinRelations(){
        var ret = _.union(PlanMixin.getMixinRelations(),[
            {
                type: Backbone.HasMany,
                containingClass: "transformation_Plan",
                key: "valueLibrary",
                relatedModel: "vdml.ValueLibrary",
                includeInJSON: Backbone.Model.prototype.idAttribute
            },
            {
                type: Backbone.HasOne,
                containingClass: "transformation_Plan",
                key: "financialStatementTaxonomy",
                relatedModel: "vdml.ValueLibrary",
                includeInJSON: Backbone.Model.prototype.idAttribute
            },
            {
                type: Backbone.HasOne,
                containingClass: "transformation_Plan",
                key: "defaultValueLibrary",
                relatedModel: "vdml.ValueLibrary",
                includeInJSON: Backbone.Model.prototype.idAttribute
            },
            {
                type: Backbone.HasMany,
                containingClass: "transformation_Plan",
                key: "patternDefinition",
                relatedModel: "lookup.PatternLookupTableDefinition",
                reverseRelation: {
                    key: "patternDefinitionOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ]);
        return ret;
    }
    static getCumulativeMixinRelations(){
        if (!Plan2Mixin.cummulativeRelations) {
            var BeepPackageMixin = Backbone.Relational.store.getObjectByName("beeppackage.BeepPackageMixin");
            Plan2Mixin.cummulativeRelations = _.union(Plan2Mixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
                , NamedPlanElementMixin.getCumulativeMixinRelations()
            );
        }
        return Plan2Mixin.cummulativeRelations.slice();
    }

    static getProperties() {
        let properties = PlanMixin.getProperties();
        properties.push({ name: "instantiationPackage", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        properties.push({ name: "defaultScenario", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        properties.push({ name: "defaultScenarioExecution", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        properties.push({ name: "targetScenarioExecution", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        properties.push({ name: "periodKind", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        // properties.push({ name: "defaultStartYear", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        // properties.push({ name: "defaultStartPeriod", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        return properties;
    }
    getInstantiationPackage(callback) {
        var self = this;
        var docVersion = self.get('documentVersion');
        var vdmStore = DataManager.getDataManager().getVDMStore(self.getCommonRepositoryId());
        DataManager.getDataManager().fetchDocumentFromPackage(self.getCommonRepositoryId() + "-InstantiationPackage", "appbo/instdef/PlanInstantiationPackage", self.get('version'), self.getCommonRepositoryId() + "-InstantiationPackage", "appbo/instdef/PlanInstantiationPackage", vdmStore, {
            success: function (instPackage) {
                callback(instPackage);
            },
            error: function (error) {
                debugger
                console.log('failed to fetch common package');
                self.loadCommonWorkspace(true,callback);
            },
            documentVersion:docVersion
        });
    };

    getValueElementsByPlanScenario(planScenario){
        var self = this;
        var ret = [];
        var step = planScenario.firstStep;
        while(step){
            let altId = step.alternativeId;
            let phaseId = step.phaseId;
            let phase = self.get("phase").findWhere({id:phaseId});
            let alt = phase.get("alternative").findWhere({id:altId});
            let valueElementContexts = alt.get("valueElementContext").models;
            
            ret = valueElementContexts.map(context => {
                return context.get("valueElementContextOwner");
            })
        }
        return ret;
    }
    getValueElementsByScenario(scenario,callback){
        var self = this;
        this.getPlanScenarioFromBackend(scenario,function(planScenario){
            var ret = self.getValueElementsByPlanScenario(planScenario);
            callback(ret);
        });
    }
    getPlanDefaultScnarioFromBackend(callback){
        var self = this;
        let scenario = self.get("defaultScenario");
        var dataManager = DataManager.getDataManager();
        var model = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenario});
        if(model){
            if(callback){
                callback(model);
            }
            return
        }
        // let self = this;
        // var defaultExecutionScenario = self.get("defaultExecutionScenario");
        dataManager.getPlanInstance(scenario,self.get('documentVersion'),function(planScenario){
            let defaultExecutionScenario = self.get("defaultExecutionScenario");
            if(defaultExecutionScenario != undefined){
                dataManager.getPlanInstance(defaultExecutionScenario,self.get('documentVersion'),function(scenarioExecution){
                    if(callback){
                        callback(planScenario);
                        return;
                    }
                },self.get('documentVersion'),"appbo/transformation/ScenarioExecution");
            }else{
                if(callback){
                    callback(planScenario);
                    return;
                }
            }
        },self.get('documentVersion'),"appbo/transformation/PlanScenario");
    }

    getPlanScenarioInstance(){
        var self = this;
        var scenarioId = window.guidGenerator();
        let startTime = (new Date()).getTime();
        let period = 1;
        
        let planScenario = new PlanScenario({id:scenarioId,planId:this.id,startTime:startTime,name:"Default scenario"});
        let executionScenario = new ScenarioExecution({id:window.guidGenerator(),name:"Default scenario execution"});
        planScenario.set("defaultExecutionScenario",executionScenario.id) ;
        let phases = this.get("phase").models;
        let previous = null;
        phases.forEach(phase => {
            var alt = phase.get("primary") ? phase.get("primary") : phase.get("phaseAlternative").models[0];
            let stepObj = new ScenarioPathStep({id:window.guidGenerator(),stepOwner:planScenario,phaseId:phase.id,alternativeId:alt.id,startPeriod:period,noPeriods:1,previous:previous});
            planScenario.get("step").add(stepObj);
            if(previous){
                previous.set("next",stepObj);
            }else{
                planScenario.set("firstStep",stepObj);
            }
            let yearPeriod = utils.calculateYearPeriod(startTime, period, self.get("periodKind") );
            let inputDataset = new PeriodDataset({id:window.guidGenerator(),year:yearPeriod.year,periodKind:self.get("periodKind") ,period:yearPeriod.period,scenarioType:ScenarioDataType.Plan});
            executionScenario.get("input").add(inputDataset);
            period++;
            previous = stepObj;
        });
        return planScenario;
    }

    creatPlanDefaultScenarioInstance(startTime, scenarioStepData){
        var self = this;
        var scenarioId = window.guidGenerator();
        // let startTime = (startDate).getTime();
        let period = 1;
        let planScenario = new PlanScenario({id:scenarioId,planId:this.id,startTime:startTime,name:"Default scenario"});
        let executionScenario = new ScenarioExecution({id:window.guidGenerator(),name:"Default scenario execution"});
        planScenario.set("defaultExecutionScenario",executionScenario.id) ;
        let phases = this.get("phase").models;
        let previous = null;
        phases.forEach(phase => {
            var alt = phase.get("primary") ? phase.get("primary") : phase.get("phaseAlternative").models[0];
            // const id = self.htmlEscape(phase.id);
            var singleStep = scenarioStepData.find((obj) => obj.name === phase.get('name'));
            let stepObj = new ScenarioPathStep({id:window.guidGenerator(),stepOwner:planScenario,phaseId:phase.id,alternativeId:alt.id,startPeriod:singleStep.noOfPeriods === 0 ? 0 : period,noPeriods:singleStep.noOfPeriods,previous:previous});
            planScenario.get("step").add(stepObj);
            if(previous){
                previous.set("next",stepObj);
            }else{
                planScenario.set("firstStep",stepObj);
            }
            let stepInitialPeriod = stepObj.get('startPeriod');
            for (let index = 0; index < singleStep.noOfPeriods; index++) {
                let yearPeriod = utils.calculateYearPeriod(startTime, stepInitialPeriod, self.get("periodKind") );
                let inputDataset = new PeriodDataset({id:window.guidGenerator(),year:yearPeriod.year,periodKind:self.get("periodKind") ,period:yearPeriod.period,scenarioType:ScenarioDataType.Plan,inputOwner:executionScenario});
                executionScenario.get("input").add(inputDataset);
                stepInitialPeriod = stepInitialPeriod + 1;
            }
            period = period + singleStep.noOfPeriods;
            previous = stepObj;
        });
        return planScenario;
    }

    createScenarioPathStep(phase, scenarioStepData, planScenario, previousStep){
        var alt = phase.get("primary") ? phase.get("primary") : phase.get("phaseAlternative").models[0];
        var singleStep = scenarioStepData.find((obj) => obj.name === phase.get('name'));
        var stepObj = new ScenarioPathStep({id:window.guidGenerator(),stepOwner:planScenario,phaseId:phase.id,alternativeId:alt.id,startPeriod:singleStep.startPeriod,noPeriods:singleStep.noOfPeriods,previous:previousStep});
        planScenario.get("step").add(stepObj);
        return stepObj;
    }
    updateScenarioPathStep(existingStep, phase, scenarioStepData, previousStep){
        const updatedPathStep = scenarioStepData.find((obj) => obj.modalId === phase.get('id'));
        existingStep.set('noPeriods', updatedPathStep.noOfPeriods);
        existingStep.set('startPeriod', updatedPathStep.startPeriod);
        existingStep.set('previous', previousStep);
    }

    updatePlanDefaultScenarioInstance(startTime, scenarioStepData, scenarioId){
        var self = this;
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        const executionScenarioId = planScenario.get("defaultExecutionScenario");
        const executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId});
        const updatedTotalNoOfPeriods = scenarioStepData.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.noOfPeriods;
        }, 0);
        const existingTotalNoOfPeriods = executionScenario.get("input").length;
        const differTotalNoOfPeriods = updatedTotalNoOfPeriods - existingTotalNoOfPeriods;
        const phases = this.get("phase").models;
        const currentlySavedSteps =  planScenario ? planScenario.get('step').models : null;
        const addedPhasesId = [];
        const deletedPhaseId = [];
        phases.forEach((phase) => {
            const isPresent =  planScenario.get('step').findWhere({ phaseId: phase.get('id') });
            if(isPresent === undefined) addedPhasesId.push(phase.get('id'));
        });
        let sortedDataset = planScenario.getSortedSteps(self);
        let previousStep = null;
        let stepObj = null;
        deletedPhaseId?.forEach(phaseId => {
            currentlySavedSteps.find((obj) => obj.get('phaseId') === phaseId).destroy();
        });    
        phases.forEach(phase => {
            const existingStep = currentlySavedSteps.find((obj) => obj.get('phaseId') === phase.get('id'));
            if(existingStep === undefined) {
                stepObj = self.createScenarioPathStep(phase, scenarioStepData, planScenario, previousStep);
            } else {
                self.updateScenarioPathStep(existingStep, phase, scenarioStepData, previousStep);
                stepObj = existingStep;
            }
            if(previousStep){
                previousStep.set("next",stepObj);
            }else{
                planScenario.set("firstStep",stepObj);
            }
            previousStep = stepObj;
        });       
        if(differTotalNoOfPeriods > 0) {
            // if total no of periods is incresed.
            let stepInitialPeriod = existingTotalNoOfPeriods + 1;
            for (let index = 0; index < differTotalNoOfPeriods; index++) {
                let yearPeriod = utils.calculateYearPeriod(startTime, stepInitialPeriod, self.get("periodKind") );
                let inputDataset = new PeriodDataset({id:window.guidGenerator(),year:yearPeriod.year,periodKind:self.get("periodKind") ,period:yearPeriod.period,scenarioType:ScenarioDataType.Plan,inputOwner:executionScenario});
                executionScenario.get("input").add(inputDataset);
                stepInitialPeriod = stepInitialPeriod + 1;
            }
            
        }
        if(differTotalNoOfPeriods < 0) {
            // if total no of periods is decresed.
            for (let index = sortedDataset.length; index > (sortedDataset.length + differTotalNoOfPeriods); index--) {
                let inputDataset = executionScenario.get("input").findWhere(sortedDataset[index - 1]);
                inputDataset.destroy();
            }
        }
    }

    static getDialogViewProperties(type) { 
        if (type === "PlanDetails") {
            return {
                templatePath: "views/transformation/views/properties/PlanDetails2Template.html",
                templateName: "PlanDetails2Template",
                viewTypeStr: "appviews/transformation/views/properties/PlanDetails2ViewModel",
                tabId: "PlanDetails2View",
                tabName: "Plan"
            }
        } else {
            return PlanMixin.getDialogViewProperties(type);
        }
    }

    getViewProperties(type) {
        if (type === "") {
            return {
                templatePath: "views/transformation/views/properties/PlanProperties2Template.html",
                templateName: "PlanProperties2Template",
                viewTypeStr: "appviews/transformation/views/properties/Plan2ViewModel",
                tabId: "PlanView",
                tabName: "Plan"
            }
        } else if (type === "PlansView") {
            return {
                templatePath: "views/transformation/views/properties/Values2Template.html",
                templateName: "Values2Template",
                viewTypeStr: "appviews/transformation/views/properties/Values2ViewModel",
                tabId: "Values2View",
                tabName: "Values"

            }
        } else {
            return PlanMixin.getDialogViewProperties(type);
        }
    };

    generateScenarioJuliaModel(scenarioId,optimize){
        var self = this;
        let dataManger = DataManager.getDataManager();
        var tabCount = 0;
        var modelScript = new Object();
        modelScript.script = "";
        if(optimize == undefined){
            optimize = false;
        }
        dataManger.getScenario(scenarioId,self,function(scenario){
            let valueContexts = scenario.getValuesContexts();
            let inputValues= [];
            let calculatedValues = [];
            let valueBounds = {};
            let valueAltRange= {};
            self.generateScriptHeader(tabCount,modelScript);
            tabCount++;
            self.generateContextStruct(tabCount,modelScript,valueContexts,inputValues,calculatedValues,valueBounds,valueAltRange);
            self.generateSetPropertyMethod(tabCount,modelScript,inputValues,calculatedValues);
            self.generatePlanModelConstraints(tabCount,modelScript,inputValues);
            self.generatePlanModelConstructor(tabCount,modelScript,inputValues);
            self.generateGetFieldByName(tabCount,modelScript);
            self.generateUpdateInput(tabCount,modelScript);
            self.generateDefineConstraints(tabCount,modelScript,valueContexts,inputValues,calculatedValues,valueBounds,valueAltRange,optimize);
            self.generateBuildModel(tabCount,modelScript);
            self.generateGetModelValues(tabCount,modelScript);
            self.generateGetStep(tabCount,modelScript);
            self.generateReadPlanScenario(tabCount,modelScript);
            self.generateReadContext(tabCount,modelScript,inputValues,calculatedValues);
            self.generateGetPeriod(tabCount,modelScript);
            self.generateReadContexts(tabCount,modelScript);
            self.generateCalculateScenario(tabCount,modelScript);
    
            self.generateRealMain(tabCount,modelScript); 
            self.generateJuliMain(tabCount,modelScript);      
            self.generateFooter(tabCount,modelScript);
            console.log("script:" + modelScript.script);
        });
    }

    generateDefineConstraints(tabCount,modelScript,valueContexts,inputValues,calculatedValues,valueBounds,valueAltRange,optimize){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function define_constraints(pm::PlanModel,periods::Vector{Int}, contexts::Dict{Int, Context})" );
        tabCount++;
        inputValues.forEach(value =>{
            let escValId = self.htmlEscape(value.get("id"));
            self.writeScriptLine(tabCount, modelScript,"@variable(pm.model, " + escValId + "_var[periods])");
        })
        calculatedValues.forEach(value =>{
            let escValId = self.htmlEscape(value.get("id"));
            self.writeScriptLine(tabCount, modelScript,"@variable(pm.model, " + escValId + "[periods])");
        })
        self.writeScriptLine(tabCount, modelScript,"for (t, context) in contexts");
        tabCount++;
        self.generateInitialVariableValueForContext(tabCount,modelScript,valueContexts,inputValues,optimize,valueBounds,true);
        self.generateInitialVariableValueForContext(tabCount,modelScript,valueContexts,calculatedValues,optimize,valueBounds,false);
        self.generateStepConstraints(tabCount,modelScript,valueContexts,inputValues,optimize,valueBounds);
        

        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }
    createMeasurementCharacteristics(valueModal, oldVpc, satisfaction, weight, recipientValue){
        var self = this;
        var repId = DataManager.getDataManager().getRepositoryId(valueModal.id);
        var mcId;
        var valueName = valueModal.get('name');
        var nameVal;

        if (valueModal.get('type') !== "vdml_ValueAdd") {
            if (!valueModal.get('satisfactionLevel')) {
                if (satisfaction && satisfaction !== "") {
                    if (oldVpc && oldVpc.get('satisfactionLevel')) {
                        mcId = repId + window.utils.getSuffix(oldVpc.get('satisfactionLevel').id);
                    } else {
                        mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                    }

                    nameVal = valueName + ' Satisfaction Level';
                    var satisfactionLevel = new ValueElement({ id: mcId, name: nameVal, description: nameVal,satisfactionLevelOwner:valueModal });
                    valueModal.set('satisfactionLevel', satisfactionLevel);
                    //this.linkOrCreateAndLinkCharacteristic(valueName + ' Satisfaction', satisfactionLevel);
                }
            }
            if (!valueModal.get('percentageWeight')) {
                if (weight && weight !== "") {
                    if (oldVpc && oldVpc.get('percentageWeight')) {
                        mcId = repId + window.utils.getSuffix(oldVpc.get('percentageWeight').id);
                    } else {
                        mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                    }

                    nameVal = valueName + ' Percentage Weight';
                    var percentageWeight = new ValueElement({ id: mcId, name: nameVal, description: nameVal,percentageWeightOwner:valueModal });
                    valueModal.set('percentageWeight', percentageWeight);
                    //this.linkOrCreateAndLinkCharacteristic(valueName + " Weight", percentageWeight);
                }
            }
            if (!valueModal.get('recipientOpinion')) {
                if (recipientValue && recipientValue !== "") {
                    if (oldVpc && oldVpc.get('recipientOpinion')) {
                        mcId = repId + window.utils.getSuffix(oldVpc.get('recipientOpinion').id);
                    } else {
                        mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                    }
                    var nameVal = valueName + ' Recipient MeasurementMC';
                    var recipientMeasurement = new ValueElement({ id: mcId, name: nameVal, description: nameVal,recipientOpinionOwner:valueModal });
                    valueModal.set('recipientOpinion', recipientMeasurement);
                    //this.linkOrCreateAndLinkCharacteristic(valueName + ' Recipient Opinion', recipientMeasurement);
                }
            }
        }
    };
    generateFooter(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"if abspath(PROGRAM_FILE) == @__FILE__");
        tabCount++; 
        self.writeScriptLine(tabCount, modelScript,"real_main()");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }
    generateJuliMain(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"Base.@ccallable function julia_main()::Cint");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"try");
        tabCount++
        self.writeScriptLine(tabCount, modelScript,"real_main()");
        tabCount--
        self.writeScriptLine(tabCount, modelScript,"catch");
        tabCount++
            self.writeScriptLine(tabCount, modelScript,"Base.invokelatest(Base.display_error, Base.catch_stack())");
            self.writeScriptLine(tabCount, modelScript,"return 1");
            tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        self.writeScriptLine(tabCount, modelScript,"return 0");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }
    htmlEscape(str){
        return String(str)
		.replace(/@/g, '')
		.replace(/ /g, '')
		.replace(/#/g, '')
        .replace(/-/g, '_');
    }
    generateRealMain(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function real_main()");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"pipeName = (length(ARGS) > 0)  ? ARGS[1] : \"p1234\"");
        self.writeScriptLine(tabCount, modelScript,"pipeDir = (length(ARGS) > 1)  ? ARGS[2] : \"\\\\\\\\.\\\\pipe\\\\\"");
        self.writeScriptLine(tabCount, modelScript,"pipePath = pipeDir * pipeName");
        self.writeScriptLine(tabCount, modelScript,"server = listen(pipePath)");
    
        self.writeScriptLine(tabCount, modelScript,"while true");
        tabCount++;
            self.writeScriptLine(tabCount, modelScript,"client = Nothing");
            self.writeScriptLine(tabCount, modelScript,"try");
            tabCount++
                self.writeScriptLine(tabCount, modelScript,"println(\"before accepted client\")");
                self.writeScriptLine(tabCount, modelScript,"client = accept(server)");
                self.writeScriptLine(tabCount, modelScript,"println(\"accepted client\")")

                self.writeScriptLine(tabCount, modelScript,"while isopen(client)");
                tabCount++;
                    self.writeScriptLine(tabCount, modelScript,"try");
                    tabCount++;
                        self.writeScriptLine(tabCount, modelScript,"data = readline(client)");
                        self.writeScriptLine(tabCount, modelScript,"data = chomp(data)");
                        self.writeScriptLine(tabCount, modelScript,"println(\"Received: \", data)");
                        
                        self.writeScriptLine(tabCount, modelScript,"dataJson = JSON3.read(data)");
                        self.writeScriptLine(tabCount, modelScript,"planScenario = dataJson[\"planScenario\"]");
                        self.writeScriptLine(tabCount, modelScript,"scenariExecution = dataJson[\"scenariExecution\"]");
                        self.writeScriptLine(tabCount, modelScript,"response = calculateScenario(planScenario,scenariExecution)");
                        self.writeScriptLine(tabCount, modelScript,"write(client, JSON3.write(response)* \"\\n\")");
                        tabCount--;
                    self.writeScriptLine(tabCount, modelScript,"catch e");
                    tabCount++; 
                        self.writeScriptLine(tabCount, modelScript,"if isa(e, JSON3.Error)");
                        tabCount++;
                            self.writeScriptLine(tabCount, modelScript,"println(\"JSON parsing error: $e\")");
                            self.writeScriptLine(tabCount, modelScript,"write(client, \"Unexpected error: $e\\n\")");
                            self.writeScriptLine(tabCount, modelScript,"continue");
                        tabCount--;
                        self.writeScriptLine(tabCount, modelScript,"end");
                        self.writeScriptLine(tabCount, modelScript,"if isa(e, Base.IOError)");
                        tabCount++;
                            self.writeScriptLine(tabCount, modelScript,"println(\"client closed\") ");
                            tabCount--;
                        self.writeScriptLine(tabCount, modelScript,"else");
                        tabCount++;
                            self.writeScriptLine(tabCount, modelScript,"println(\"Error reading/writing to client: \", e)");
                            self.writeScriptLine(tabCount, modelScript,"break");
                            tabCount--;
                        self.writeScriptLine(tabCount, modelScript,"end");
                        tabCount--
                    self.writeScriptLine(tabCount, modelScript,"end");
                    tabCount--;
                self.writeScriptLine(tabCount, modelScript,"end");
                tabCount--;
            self.writeScriptLine(tabCount, modelScript,"finally");
            tabCount++
                self.writeScriptLine(tabCount, modelScript,"if client != Nothing");
                tabCount++
                    self.writeScriptLine(tabCount, modelScript,"close(client)");
                    tabCount--;
                self.writeScriptLine(tabCount, modelScript,"end");
                tabCount--;
            self.writeScriptLine(tabCount, modelScript,"end");
            tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    
        self.writeScriptLine(tabCount, modelScript,"close(server)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }
    generateCalculateScenario(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function calculateScenario(planscenario,scenarioexecution)");
        tabCount++;   
        self.writeScriptLine(tabCount, modelScript,"periods = readPlanScenario(planscenario)");
        self.writeScriptLine(tabCount, modelScript,"startTime = DateTime(planscenario[\"content\"][\"startTime\"][\"\\$date\"], dateformat\"yyyy-mm-ddTHH:MM:SS.sssZ\")");
        self.writeScriptLine(tabCount, modelScript,"contexts = readContexts(startTime,scenarioexecution,periods)");
        self.writeScriptLine(tabCount, modelScript,"for contextkey in keys(contexts)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"setProperty(contexts[contextkey])");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");

        self.writeScriptLine(tabCount, modelScript,"pm = PlanModel()");
        self.writeScriptLine(tabCount, modelScript,"buildModel(contexts,pm)");
        self.writeScriptLine(tabCount, modelScript,"return getModelValues(pm)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }

    generateReadContexts(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function readContexts(startTime,scenariExecution,periods::Vector{Int})");
        tabCount++;   
        self.writeScriptLine(tabCount, modelScript,"content = scenariExecution[\"content\"]");
        self.writeScriptLine(tabCount, modelScript,"scenarioContexts = Dict{Int,Context}()");
        self.writeScriptLine(tabCount, modelScript,"for period in periods");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"scenarioContexts[period] = getPeriod(startTime,period,content[\"input\"])");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        self.writeScriptLine(tabCount, modelScript,"return scenarioContexts");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }

    generateGetPeriod(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function getPeriod(startTime::DateTime,period::Int,contexts:: Any)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"for context in contexts");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"contextPeriod = parse(Int,context[\"period\"][\"\\$numberLong\"])");
        self.writeScriptLine(tabCount, modelScript,"contextYear = context[\"year\"]");
        self.writeScriptLine(tabCount, modelScript,"contextPeriodKind = context[\"periodKind\"]"); 
        self.writeScriptLine(tabCount, modelScript,"scenarioPeriod = calculate_period_number(startTime, contextYear, contextPeriodKind, contextPeriod)");
        self.writeScriptLine(tabCount, modelScript,"if period == scenarioPeriod");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"return readContext(context)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        self.writeScriptLine(tabCount, modelScript,"return nothing");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }

    generateReadContext(tabCount,modelScript,inputValues,calculatedValues){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function readContext(data)::Context");
        tabCount++;
        inputValues.forEach(value =>{
            let escValId = self.htmlEscape(value.get("id"));
            self.writeScriptLine(tabCount, modelScript,escValId + " =  get(data, \""+ value.get("id")+ "\", nothing)");
        })
        self.writeScriptLine(tabCount, modelScript,"return Context(");
        for(let i=0;i< inputValues.length;i++){
            let value = inputValues[i];
            let escValId = self.htmlEscape(value.get("id"));
            if(i<inputValues.length -2 || calculatedValues.length > 0){
                self.writeScriptLine(tabCount, modelScript,escValId + ",");
            }else {
                self.writeScriptLine(tabCount, modelScript,escValId);
            }
        }
        for(let i=0;i< calculatedValues.length;i++){
            if(i<calculatedValues.length - 1 ){
                self.writeScriptLine(tabCount, modelScript, "nothing,");
            }else {
                self.writeScriptLine(tabCount, modelScript,"nothing");
            }
        }
        self.writeScriptLine(tabCount, modelScript,")");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }

    generateReadPlanScenario(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function readPlanScenario(planScenario)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"scenarioPeriods = Vector{Int}()");
        
        self.writeScriptLine(tabCount, modelScript,"content = planScenario[\"content\"]");
        self.writeScriptLine(tabCount, modelScript,"steps = content[\"step\"]");
        self.writeScriptLine(tabCount, modelScript,"firstStepId = content[\"firstStep\"]");
        self.writeScriptLine(tabCount, modelScript,"step = getStep(firstStepId, steps)");
            
        self.writeScriptLine(tabCount, modelScript,"while step !== nothing");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"startPeriodStr = step[\"startPeriod\"][\"\\$numberLong\"]");
        self.writeScriptLine(tabCount, modelScript,"startPeriod = parse(Int, startPeriodStr)");
        self.writeScriptLine(tabCount, modelScript,"noPeriodsStr = step[\"noPeriods\"][\"\\$numberLong\"]");
        self.writeScriptLine(tabCount, modelScript,"noPeriods = parse(Int, noPeriodsStr)");
        self.writeScriptLine(tabCount, modelScript,"for i in 1:noPeriods")
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"push!(scenarioPeriods, startPeriod + i - 1)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        self.writeScriptLine(tabCount, modelScript,"nextStepId = step[\"next\"]");
        self.writeScriptLine(tabCount, modelScript,"step = nextStepId === nothing ? nothing : getStep(nextStepId, steps)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        self.writeScriptLine(tabCount, modelScript,"return scenarioPeriods");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }

    generateGetStep(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function getStep(stepId::String, steps)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"for step in steps");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"if step[\"id\"] == stepId");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"return step");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        self.writeScriptLine(tabCount, modelScript,"return nothing");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }


    generateGetModelValues(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function getModelValues(pm::PlanModel)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"model = pm.model");
        self.writeScriptLine(tabCount, modelScript,"vars_dict = Dict{String, Any}()");
        self.writeScriptLine(tabCount, modelScript,"for v in all_variables(model)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"vars_dict[name(v)] = value(v)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        self.writeScriptLine(tabCount, modelScript,"println(\"vars_dict:\",vars_dict)");
        self.writeScriptLine(tabCount, modelScript,"return vars_dict");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }
    generateBuildModel(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function buildModel(contexts,pm::PlanModel)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"periods = collect(keys(contexts))");
        self.writeScriptLine(tabCount, modelScript,"define_constraints(pm,periods,contexts)");
        self.writeScriptLine(tabCount, modelScript,"@objective(pm.model, Max, 10)");
        self.writeScriptLine(tabCount, modelScript,"optimize!(pm.model)");
        tabCount--
        self.writeScriptLine(tabCount, modelScript,"end");
    }

    generateStepConstraints(tabCount,modelScript,valueContexts,inputValues,optimize,valueBounds){
        var self = this;
        let keys = Object.getOwnPropertyNames(valueContexts);
        let firstStep = true;
        for(let key in keys){
            let altContext = valueContexts[key];
            let range = altContext["startPeriod"] + ":" + (altContext["startPeriod"] + altContext["noPeriods"] -1) 
            self.writeScriptLine(tabCount, modelScript, firstStep == true? "if t==1" : "elseif" + " t in " + range);
            tabCount++;
            altContext.valueElementContext.forEach(context =>{
                let contextId = self.htmlEscape(context.get("id"));
                let value = context.get("contextOwner");
                let valueExpressionStr = this.getValueExpression(context,altContext.valueElementContext);
                if(value.get("valueType") != ValueType.Atomic){
                    let escValId = self.htmlEscape(value.get("id"));
                    self.writeScriptLine(tabCount, modelScript,"@constraint(pm.model, " + escValId + "[t] == " +  valueExpressionStr + ")");
                }
            });
            firstStep = false;
        }
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }
    getValueExpression(context,altContexts){
        var self = this;
        let value = context.get("contextOwner");
        let aggregatedFromValues = context.get("aggregatedFrom");
        let expresionStr = context.get("formula") ? context.get("formula").get("expressionStr") : undefined ;
        if(expresionStr != undefined){
            for(let i = 0;i<aggregatedFromValues.models.length;i++){
                let aggregatedFromValue = aggregatedFromValues.models[i];
                let aggValueFromValueId = aggregatedFromValue.get("contextOwner").get("id");
                if(aggregatedFromValue.get("contextOwner").get("valueType") == ValueType.Atomic){
                    expresionStr = expresionStr.replaceAll(aggregatedFromValue.get("id"),self.htmlEscape(aggValueFromValueId) + "_var[t]");
                }else{
                    expresionStr = expresionStr.replaceAll(aggregatedFromValue.get("id"),self.htmlEscape(aggValueFromValueId) + "[t]");
                }
            }
        }
        return expresionStr;
    }
    generateInitialVariableValueForContext(tabCount,modelScript,valueContexts,values,optimize,valueBounds,isInputs){
        var self = this;
        values.forEach(value =>{
            let escValId = self.htmlEscape(value.get("id"));
            if(!optimize){
                if(isInputs){
                    self.writeScriptLine(tabCount, modelScript,escValId + " = context." + escValId);
                    self.writeScriptLine(tabCount, modelScript,"pm." + escValId + "_constraints[t] = @constraint(pm.model, " + escValId + " <= " + escValId +"_var[t] <= " + escValId + ")");
                }else{
                    self.writeScriptLine(tabCount, modelScript,"set_start_value(" + escValId +"[t],1)" );
                    if(valueBounds[escValId] != null){
                        let maxBound = valueBounds[escValId].maxBound ? valueBounds[escValId].maxBound : undefined;
                        let minBound = valueBounds[escValId].minBound ? valueBounds[escValId].minBound : undefined;
                        let boundConstraintScript = "pm." + escValId + "_constraints[t] = @constraint(pm.model,";
                        if(minBound){
                            boundConstraintScript = boundConstraintScript + minBound + " <= " ;
                        }
                        boundConstraintScript = boundConstraintScript + escValId + "[t]";
                        if(maxBound){
                            boundConstraintScript = boundConstraintScript + " <= " + maxBound ;
                        }
                        boundConstraintScript = boundConstraintScript + ")";
                    }
                }
            }else{
                if(valueBounds[escValId] != null){
                    let maxBound = valueBounds[escValId].maxBound ? valueBounds[escValId].maxBound : undefined;
                    let minBound = valueBounds[escValId].minBound ? valueBounds[escValId].minBound : undefined;
                    let boundConstraintScript = "pm." + escValId + "_constraints[t] = @constraint(pm.model,";
                    if(minBound){
                        boundConstraintScript = boundConstraintScript + minBound + " <= " ;
                    }
                    if(isInputs){
                        boundConstraintScript = boundConstraintScript + escValId + "_var[t]";
                    }else{
                        boundConstraintScript = boundConstraintScript + escValId + "[t]";
                    }
                    if(maxBound){
                        boundConstraintScript = boundConstraintScript + " <= " + maxBound ;
                    }
                    boundConstraintScript = boundConstraintScript + ")";
                }
                if(isInputs){
                    self.writeScriptLine(tabCount, modelScript,"set_start_value(" + escValId +"_var[t],1)" );
                }else{
                    self.writeScriptLine(tabCount, modelScript,"set_start_value(" + escValId +"[t],1)" );
                }
            }
        })
    }
    generateUpdateInput(tabCount, modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function updateInput(prop::String,period::Int64,pm::PlanModel,val::Float64)" );
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"model = pm.model");
        self.writeScriptLine(tabCount, modelScript,"constrainField = getFieldByName(pm, prop * \"_constraints\")");
        self.writeScriptLine(tabCount, modelScript,"delete(model,constrainField[period])");
        self.writeScriptLine(tabCount, modelScript,"propVar = JuMP.variable_by_name(model, prop * \"_var[$period]\")");
        self.writeScriptLine(tabCount, modelScript,"constrainField[period]  = @constraint(model, val <= propVar <= val)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }
    
    generateGetFieldByName(tabCount, modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function getFieldByName(pm::PlanModel, field_name::String)" );
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"return getfield(pm, Symbol(field_name))");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }

    generatePlanModelConstructor(tabCount, modelScript,inputValues){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function PlanModel()" );
        tabCount++;
        let script = "PlanModel(Model(Ipopt.Optimizer)"
        for (let i=0;i<inputValues.length;i++)
            script = script + ", Vector{ConstraintRef}(undef, 12)"
        script = script + ")";
        self.writeScriptLine(tabCount, modelScript,script);
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }
    generatePlanModelConstraints(tabCount, modelScript,inputValues){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"mutable struct PlanModel" );
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"model::Model");
        inputValues.forEach(value =>{
            let escValId = self.htmlEscape(value.get("id"));
            self.writeScriptLine(tabCount, modelScript,escValId + "_constraints::Vector{ConstraintRef}");
        })
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }

    generateSetPropertyMethod(tabCount, modelScript,inputValues,calculatedValues){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"function setProperty(context::Context)" );
        tabCount++;
        inputValues.forEach(value =>{
            let escValId = self.htmlEscape(value.get("id"));
            self.writeScriptLine(tabCount, modelScript,"context." +  escValId + " = context." + escValId + " === nothing ? 0.0 : context." + escValId);
        })
        calculatedValues.forEach(value =>{
            let escValId = self.htmlEscape(value.get("id"));
            self.writeScriptLine(tabCount, modelScript,"context." +  escValId + " = context." + escValId + " === nothing ? 0.0 : context." + escValId);
        })
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
    }
    generateContextStruct(tabCount,modelScript,valueContexts,inputValues,calculatedValues,valueBounds,valuePeriodRange){
        var self = this;
        self.writeScriptLine(tabCount, modelScript,"mutable struct Context" );
        tabCount++;
        var alts = Object.getOwnPropertyNames(valueContexts);

        for(let alt in alts){
            let altData = valueContexts[alt];
            altData.valueElementContext.forEach(context =>{
                let value = context.get("contextOwner");
                if(!valuePeriodRange[value.get("id")]){
                    valuePeriodRange[value.get("id")] = {"start":altData.startPeriod,"end":altData.noPeriods};
                    let escValId = self.htmlEscape(value.get("id"));
                    let valueDefinition = value.get("valueDefinition");
                    let unit = value.get("unit");
                    if(unit == undefined && valueDefinition){
                        unit = valueDefinition.get("unit")
                    }
                    if(valueDefinition != undefined && (valueDefinition.get("maxBound") || valueDefinition.get("minBound"))){
                        let valBound = {}
                        if(valueDefinition.get("maxBound")){
                            valBound.maxBound = valueDefinition.get("maxBound")
                        }
                        if(valueDefinition.get("minBound")){
                            valBound.minBound = valueDefinition.get("minBound")
                        }
                        valueBounds.escValId = valBound;
                    }
                    if(value.get("valueType") == ValueType.Atomic){
                        inputValues.push(value);
                    }else{
                        calculatedValues.push(value);
                    }
                    self.writeScriptLine(tabCount, modelScript,escValId + "::Union{Float64, Nothing}");
                }else{
                    valuePeriodRange[value.get("id")].end = altData.startPeriod + altData.noPeriods - 1;
                }
            });
        };
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end" );
    }
    generateScriptHeader(tabCount,modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "module " + self.htmlEscape(self.get("name")));
        self.writeScriptLine(tabCount, modelScript, "using JuMP, Ipopt, JSON3, Dates");
        self.writeScriptLine(tabCount, modelScript, "using Sockets");
        self.writeScriptLine(tabCount, modelScript, "#Model");
    }


}


utils.customExtendClass (Plan2Mixin,new PlanMixin());

path.Plan2Mixin = Plan2Mixin;