import * as React from "react";
import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import QueueIcon from "@mui/icons-material/Queue";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MaterialReactTable from "material-react-table";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PanoramaWideAngleSelectIcon from "@mui/icons-material/PanoramaWideAngleSelect";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import DashboardElement from "./DashboardReact";
import NavigationTree from "./NavigationTree";
import "./PresentationDataGrid.scss";
import * as bootbox from "../../../../../../libs/bootbox/bootbox";
import { ArrowDropDown, ArrowDropUp, ExpandLess, ExpandMore } from "@mui/icons-material";
import { DataManager } from "../../../../../com/vbee/data/DataManager";

export default function PresentationDataGrid({ dashboardId }) {
  const dashId = dashboardId;
  const dashboardModel = Backbone.Relational.store
    .getObjectByName("dashboard.Dashboard")
    .find({ id: dashId });
  const [treeData, setTreeData] = React.useState({});
  const [presentation,setPresentation] =  React.useState("");
 
  const [presentationArr, setPresentationArr] = React.useState([]);
  const [isDragging,setIsDragging] = React.useState(false);
  const [heightFieldError, setHeightFieldError] = React.useState("");
  const intializedHeight = React.useRef(false);
  const [isEnableEditing, setIsEnableEditing] = React.useState(
    window.vdmModelView.editMode
  );
  const typeOptionArray = [
    { text: "Container", value: 0 },
    { text: "Presentation", value: 1 },
  ];
 

  const createHashMap = (nodes, map = new Map()) => {
    if (typeof nodes !== 'object' || Object.keys(nodes).length === 0) {
      return map;
    }
  
    if (nodes.uId) {
      if (window.vdmModelView.hashTreeData) {
        window.vdmModelView.hashTreeData.set(nodes.uId, nodes);
      }
    }
  
    if (Array.isArray(nodes.subRows)) {
      nodes.subRows.forEach(subNode => {
        createHashMap(subNode, map);
      });
    }
  
    return map;
  };

  function renderDashboard(arr,render=true) {
    const stringifiedArr = JSON.stringify(arr);
    dashboardModel.set("treeStructure", stringifiedArr);
    if(render){
      window.vdmModelView.eventBus.emit("propsUpdated", {
        component: DashboardElement,
        treeData: arr,
      });
    }
    window.vdmModelView.eventBus.emit("updatedNav", {
      component: NavigationTree,
      treeData:arr
    });
    
  }

  React.useEffect(() => {
    const rootObj = { uId:window.uuidGenerator(),id: "root",name: "",type: 0,b: false,subRows: [],w: 100,pId: "",h: ""}
    const treeStructure = dashboardModel.get("treeStructure");
    if (treeStructure !== undefined && treeStructure !== null) {
      try {
        if (typeof treeStructure === "string") {
          let parsedData = JSON.parse(treeStructure);
          if (intializedHeight.current === false) {
            renderDashboard(parsedData);
            setTreeData(parsedData);
            intializedHeight.current = true;
          }
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      setTreeData({...rootObj});
      renderDashboard({...rootObj});
      getPresentationsList();
      console.warn("treeStructure is undefined or null");
    }
    
    window.vdmModelView.setResizeHeight();
  }, []);


  React.useEffect(() => {
    if (treeData) {
      createHashMap(treeData);
    }
  }, [treeData]);

  const deleteHandler = (parentId,id)=>{
    const currentElement = window.vdmModelView.hashTreeData.get(parentId)
    const index = currentElement.subRows.findIndex(element => element.uId === id);
    if (index !== -1) {
      currentElement.subRows.splice(index, 1);
    }
    setTreeData(treeData);
    renderDashboard(treeData, false);
    window.vdmModelView.eventBus.emit(`${parentId}deleteContainer`, parentId);
    setTimeout(() => {
      setIsEnableEditing(true);
      window.vdmModelView.setResizeHeight();
      window.utils.stopSpinner("saveData");
    }, 10);
  }

  const handleAddChild = (parentId, id, parentHeight) => {
    const newNode = {
      uId:window.uuidGenerator(),
      id: window.uuidGenerator(),
      name: "",
      type: 1,
      w:100,
      b: false,
      subRows: [],
      pId: parentId,
      h: "600",
    };
    let parentElement = window.vdmModelView.hashTreeData.get(id)
    if(parentElement.subRows.length>0){
      if(parentId==="root"){
        parentElement.subRows.push({...newNode,type:parentElement.subRows[0].type,b:true});
      }else{
        parentElement.subRows.push({...newNode,type:parentElement.subRows[0].type});
      }
      
    }else{
      if(parentId==="root"){
        parentElement.subRows.push({...newNode,type:0,b:true});
      }else{
        parentElement.subRows.push({...newNode});
      }
    }
    parentElement.subRows = parentElement.subRows
    setTreeData({...treeData});
    renderDashboard({...treeData},false);
    setTimeout(()=>{
      window.vdmModelView.eventBus.emit(`${id}addChild`,id);
      window.vdmModelView.setResizeHeight();
    },100)
  };

 

  const handleChangeType = (data,id,newValue,h) => {
    const currentElement =  window.vdmModelView.hashTreeData.get(id);
    const obj = {...data,uId:window.uuidGenerator()}
    if(newValue===0 && currentElement.id.includes("@")){
      const id = window.uuidGenerator()
      currentElement.type = newValue;
      currentElement.h = h
      currentElement.id = id;
      currentElement.name = ""
      currentElement.subRows = [{...obj,pId:id,w:100}]
    }else {
      currentElement.type = newValue;
      currentElement.subRows = [];
      currentElement.name = ""
    }
    
    setTreeData({...treeData});
    renderDashboard({...treeData},false);
    setTimeout(()=>{
      window.vdmModelView.eventBus.emit(`${id}changeType`,id);
      window.vdmModelView.eventBus.emit(`${id}editContainerName`,id);
      var chartPresnt = window.vdmModelView.charts().find((element)=>element.encodeId===window.utils.htmlEscape(obj.id));
      if(chartPresnt){
        chartPresnt.loadContext();
      }
     
    },50)
    
  };

  const handleSelectPresentation = (id, newValue) => {
    const currentElement =  window.vdmModelView.hashTreeData.get(id);
    const newName =
      !!newValue && presentationArr.find((d) => d.value === newValue)?.text;
      currentElement.id = newValue;
      currentElement.name = newName;
      setPresentation(currentElement.id);
      setTreeData(treeData);
      renderDashboard(treeData,false);
      window.vdmModelView.eventBus.emit(`${id}addPresentation`, id);
  };

  const getPresentationFromContainer = (arr, result = new Set()) => {
    if(arr && arr.length>0){
      return
    }
    for (const item of arr) {
      if (item.type === 1 && item.name !== "") {
        result.add(item);
      } else if (item.type === 0){
        result
      }
      if (Array.isArray(item.subRows)) {
        getPresentationFromContainer(item.subRows, result);
      }
    }
    return [...result];
  };


  const dragHandler = (hoveredRow, draggingRow) => {
  const presentationCheck =  hoveredRow && draggingRow && hoveredRow.original.type === 1 && draggingRow.original.type === 1 && hoveredRow.original.type === draggingRow.original.type
  if (presentationCheck===true) {
    if (hoveredRow.original.pId === draggingRow.original.pId && hoveredRow.original.id !== draggingRow.original.id) {
      window.utils.startSpinner("saveData", "Updating ... ");
      const fromIndex = window.vdmModelView.hashTreeData.get(draggingRow.getParentRow().original.uId).subRows.findIndex(element => element.uId === draggingRow.original.uId);
      const toIndex =  window.vdmModelView.hashTreeData.get(hoveredRow.getParentRow().original.uId).subRows.findIndex(element => element.uId === hoveredRow.original.uId);
      let element
      if(fromIndex!==-1){
        element = window.vdmModelView.hashTreeData.get(draggingRow.getParentRow().original.uId).subRows.splice(fromIndex,1)
      }
      if(element){
        window.vdmModelView.hashTreeData.get(hoveredRow.getParentRow().original.uId).subRows.splice(toIndex,0,element[0])
      }
      setTreeData(treeData)
      renderDashboard(treeData,false);
      window.vdmModelView.eventBus.emit(`${hoveredRow.getParentRow().original.uId}move`,hoveredRow.getParentRow().original.uId);
      setIsEnableEditing(false);
      setTimeout(() => {
        var chartPresnt = window.vdmModelView.charts();
        for (let pres of chartPresnt) {
          renderContainer([hoveredRow.getParentRow().original],hoveredRow.original.pId,pres)
        }
        setIsEnableEditing(true);
        window.utils.stopSpinner("saveData");
      }, 100);
    } else {
      window.utils.startSpinner("saveData", "Updating ... ");
      const fromIndex = window.vdmModelView.hashTreeData.get(draggingRow.getParentRow().original.uId).subRows.findIndex(element => element.uId === draggingRow.original.uId);
      const toIndex =  window.vdmModelView.hashTreeData.get(hoveredRow.getParentRow().original.uId).subRows.findIndex(element => element.uId === hoveredRow.original.uId);
      let element
      if(fromIndex!==-1){
        element = window.vdmModelView.hashTreeData.get(draggingRow.getParentRow().original.uId).subRows.splice(fromIndex,1)
        window.vdmModelView.eventBus.emit(`${draggingRow.getParentRow().original.uId}move`, draggingRow.getParentRow().original.uId);
      }
      if(element!==undefined){
        window.vdmModelView.hashTreeData.get(hoveredRow.getParentRow().original.uId).subRows.splice(toIndex,0,{...element[0],pId:hoveredRow.getParentRow().original.id})
      }
      setTreeData(treeData)
      renderDashboard(treeData,false);
      window.vdmModelView.eventBus.emit(`${hoveredRow.getParentRow().original.uId}move`, hoveredRow.getParentRow().original.uId);
      setIsEnableEditing(false);
      setTimeout(() => {
        setIsEnableEditing(true);
        var chartPresnt = window.vdmModelView.charts();
        for (let pres of chartPresnt) {
          renderContainer([draggingRow.getParentRow().original],draggingRow.original.pId,pres)
          renderContainer([hoveredRow.getParentRow().original],hoveredRow.original.pId,pres)
        }
        window.vdmModelView.setResizeHeight();
        window.utils.stopSpinner("saveData");
      }, 100);
    }
  } else if (
    hoveredRow &&
    draggingRow &&
    hoveredRow.original.type === 0 &&
    hoveredRow.original.id!=="root" &&
    draggingRow.original.type === 1
  ) {
    window.utils.startSpinner("saveData", "Updating ... ");
    const fromIndex = window.vdmModelView.hashTreeData.get(draggingRow.getParentRow().original.uId).subRows.findIndex(element => element.uId === draggingRow.original.uId);
    let element
    if(fromIndex!==-1){
      element = window.vdmModelView.hashTreeData.get(draggingRow.getParentRow().original.uId).subRows.splice(fromIndex,1)
      window.vdmModelView.eventBus.emit(`${draggingRow.getParentRow().original.uId}move`,draggingRow.getParentRow().original.uId)
    }
    if(element){
      window.vdmModelView.hashTreeData.get(hoveredRow.original.uId).subRows.unshift({...element[0],pId:hoveredRow.original.id})
    }
    window.vdmModelView.eventBus.emit(`${hoveredRow.original.uId}move`, hoveredRow.original.uId);
    setIsEnableEditing(false);
    setTreeData(treeData)
    renderDashboard(treeData);
    setTimeout(() => {
      setIsEnableEditing(true);
      var chartPresnt = window.vdmModelView.charts();
      for (let pres of chartPresnt) {
        renderContainer([draggingRow.getParentRow().original],draggingRow.original.pId,pres);
        renderContainer([hoveredRow.original],hoveredRow.original.id,pres);
      }
      window.vdmModelView.setResizeHeight();
      window.utils.stopSpinner("saveData");
    }, 100);
  } else if (
    hoveredRow &&
    draggingRow &&
    hoveredRow.original.type === 0 &&
    draggingRow.original.type === 0 &&
    hoveredRow?.original?.id !== draggingRow?.original?.id
  ) {   
    window.utils.startSpinner("saveData", "Updating ... ");
    const fromIndex = window.vdmModelView.hashTreeData.get(draggingRow.getParentRow().original.uId).subRows.findIndex(element => element.uId === draggingRow.original.uId);
    let element
    if(fromIndex!==-1){
      element = window.vdmModelView.hashTreeData.get(draggingRow.getParentRow().original.uId).subRows.splice(fromIndex,1)
      window.vdmModelView.eventBus.emit(`${draggingRow.getParentRow().original.uId}move`,draggingRow.getParentRow().original.uId);
    }
    if(element){
      var hgt = hoveredRow.original.h == "" ? draggingRow.original.h : hoveredRow.original.h;
      window.vdmModelView.hashTreeData.get(hoveredRow.original.uId).subRows.unshift({...element[0],pId:hoveredRow.original.id,h:hgt})
    }
    window.vdmModelView.eventBus.emit(`${hoveredRow?.original?.uId}move`,hoveredRow.original.uId);
    const moveArr = [{...draggingRow.original},{...hoveredRow.original}]
    const presentationsArr = getPresentationFromContainer(moveArr);
    setIsEnableEditing(false);
    setTreeData(treeData)
    renderDashboard(treeData);     
    setTimeout(() => {
      setIsEnableEditing(true);
      var chartPresnt = window.vdmModelView.charts();
      for (let pres of chartPresnt) {
        if(presentationsArr && presentationsArr.length>0 && draggingRow.original?.pId !=="root"){
          bindMovePresentation(presentationsArr,pres);
        }else {
          bindMovePresentation([treeData],pres);
        }
      }
      window.vdmModelView.setResizeHeight();
      window.utils.stopSpinner("saveData");
    }, 500);
  }else {
    if (hoveredRow.original.id===draggingRow.original.id){
    }else{
      bootbox.alert("Container(s) and Presentation(s) can't be under same level")   
    }
    
  }
};  

  const handleMoveContainer = (parentRow,srcRow,action)=>{
    const currentElement = window.vdmModelView.hashTreeData.get(parentRow.uId)
    const fromIndex = currentElement.subRows.findIndex(element => element.uId === srcRow.uId);
    let toIndex 
    if(action==="up"){
      toIndex = fromIndex-1
    }else{
      toIndex = fromIndex+1
    }
    let element
    if(fromIndex!==-1){
      element = currentElement.subRows.splice(fromIndex,1)
      window.vdmModelView.eventBus.emit(`${parentRow.uId}move`, parentRow.uId);
    }
    if(element!==undefined){
      currentElement.subRows.splice(toIndex,0,{...element[0]})
    }
  }

  function deletePresentation (id,uId,pId){
    var items = window.vdmModelView.model.get('presentation');
    var itemFound;
    for(var item=0;item<items.length;item++){
      if(items.at(item).id === "view"+window.utils.htmlEscape(id)){
        itemFound = items[i];
        break;
      }
    }
    const index = window.vdmModelView.hashTreeData.get(pId).subRows.findIndex(element => element.uId === uId);
    if (index !== -1) {
      window.vdmModelView.hashTreeData.get(pId).subRows.splice(index, 1);
    }
    window.vdmModelView.model.get('presentation').remove(itemFound);
    $("#bind" + window.utils.htmlEscape(id)).remove();
    setTreeData({ ...treeData });
    renderDashboard(treeData, false);
    window.vdmModelView.eventBus.emit(`${pId}deleteContainer`,pId);
  }

  const reAdjustContainer = (nodes,id) => {
    return nodes.map((node) => {
      if (node.pId === id) {
        if(node.type == 0){
          reAdjustContainer(node.subRows,node.id);
        } else {
          reAdjustPres(node.id);
        }
      } else if (node.subRows && node.subRows.length > 0) {
        return {
          ...node,
          subRows: reAdjustContainer(node.subRows,id),
        };
      }
      return node;
    });
  }

  const reAdjustPres = (id) => {
    var chartPresnt = window.vdmModelView.charts();
    for (let pres of chartPresnt) {
      if(window.utils.htmlEscape(id) === pres.encodeId){
        var totalHeight = $('#view'+ pres.encodeId).parent().height();
        var legendHeight = $('#view'+ pres.encodeId + ' legend').outerHeight(true);
        if(pres.viewInstance.selectedChartType && pres.viewInstance.selectedChartType() !== "Table"){
          legendHeight = legendHeight + $('#altLegend'+ pres.encodeId).outerHeight(true);
        }
        var presHeight = totalHeight - legendHeight;
        $('#js-canvas'+pres.viewInstance.encodeId).outerHeight(presHeight);
        pres.viewInstance.initializePresentation(true);
        break;
      }
    }
  }
  const handleBorderChange = (checked, id) => {
    const currentElement =  window.vdmModelView.hashTreeData.get(id)
    window.utils.startSpinner("saveData", "Updating Border ... ");
    setIsEnableEditing(false);
    currentElement.b = checked;
    setTreeData({...treeData});
    renderDashboard(treeData);
    window.vdmModelView.eventBus.emit(`${id}changeBorder`,id);
    window.vdmModelView.setResizeHeight();
    setTimeout(() => {
      setIsEnableEditing(true);
      window.utils.stopSpinner("saveData");
    }, 1000);
  };

  function idExistsInTree(tree, id) {
    if (tree !== undefined && tree?.id === id) {
      return true;
    }
    for (const child of tree?.subRows) {
      if (idExistsInTree(child, id)) {
        return true;
      }
    }
    return false;
  }

  const getPresentationsList = (id) => {
    const presentationList = window.vdmModelView.model
      .get("presentation")
      .filter(function (chart) {
        if (id && id !== "" && id.includes("@")) {
          return (
            id === chart?.id ||
            !treeData?.subRows?.some((tree) => idExistsInTree(tree, chart?.id))
          );
        } else {
          return !treeData?.subRows?.some((tree) => idExistsInTree(tree, chart?.id));
        }
      })
      .map((chart) => {
        return { value: chart.id, text: chart.get("name") };
      });
    setPresentationArr(presentationList);
  };

  const getSubGroup = (parentIndex, i, arr) => {
    let requiredArray = arr.subRows;
    if (i < parentIndex.length - 1) {
      let k = i;
      requiredArray = getSubGroup(
        parentIndex,
        ++i,
        arr.subRows[parentIndex[k]]
      );
    }
    return requiredArray;
  };

  const bindMovePresentation = (nodes,pres) => {
    return nodes.map((node) => {
      if (window.utils.htmlEscape(node.id) === pres.encodeId) {
        $("#bind" + pres.encodeId).remove();
        pres.loadContext();
      } else if (node.subRows && node.subRows.length > 0) {
        return {
          ...node,
          subRows: bindMovePresentation(node.subRows,pres),
        };
      }
      return node;
    });
  }

  const bindPresentation = (nodes,pres) => {
   return nodes.map((node) => {
      if (window.utils.htmlEscape(node?.id) === pres.encodeId) {
        pres.loadContext();
      } else if (node?.subRows && node?.subRows.length > 0) {
        return {
          ...node,
          subRows: bindPresentation(node?.subRows,pres),
        };
      }
      return node;
    });

  }

  const renderContainer = (nodes,id,pres) => {
    return nodes.map((node) => {
      if (node.pId === id) {
        if(window.utils.htmlEscape(node.id) === pres.encodeId){
          $("#bind" + pres.encodeId).remove();
          pres.loadContext();
        }
      } else if (node.subRows && node.subRows.length > 0) {
        return {
          ...node,
          subRows: renderContainer(node.subRows,id,pres),
        };
      }
      return node;
    });
  }

  function heightValidation(input,parentHeight,childHeight) {
    const number = parseInt(input, 10);
    if (!input) {
      setHeightFieldError("Required.");
      return;
    }
    if (isNaN(number)) {
      setHeightFieldError("Invalid Value.");
    } else if (number < 50) {
      setHeightFieldError("range > 50.");
    } else if (parentHeight && number > +parentHeight) {
      setHeightFieldError(`Height should not be greater than ${parentHeight}`);
    } else if(childHeight && number < +childHeight){
      setHeightFieldError(`Height should not be less than ${childHeight}`);
    }
     else if (!/^\d*$/.test(input)) {
      setHeightFieldError("Height can only be an integer.");
    } else {
      setHeightFieldError("");
    }
  }

  Array.max = function( array ){
    return Math.max.apply( Math, array );
  };

  const columns = React.useMemo(() => [
    {
      accessorKey: "name",
      header: DataManager.getDataManager().get('localeManager').get('name'),
      size: 180,
      enableEditing: (row) => {
       row.original.id === "root"
        ? false
        : true;
      },
      muiTableBodyCellEditTextFieldProps: ({ Cell, column, row, table }) => {
        if (table.editingCell) {
          getPresentationsList(row.original.id);
          setContainerName(row.original.name);
          setPresentation(row.original.id);
        }
      },
      Edit: ({ cell, table }) => {
        const [containerName,setContainerName] =  React.useState(cell.row.original.name);
        let charts = window.vdmModelView.model.get("presentation").models.concat();
        function handleContainerName(id, newValue) {
          const currentElement = window.vdmModelView.hashTreeData.get(id)
          currentElement.name = newValue;
          setContainerName(newValue)
        };
        const id = cell.row.original.uId;
        if (cell.row.original.id !== "root") {
          if (cell.row.original.type === 0) {
            return (
              <TextField
                value={containerName}
                onChange={(event) => {
                  const id = cell.row.original.uId;
                  handleContainerName(id, event.target.value);
                }}
                variant="standard"
                autoFocus
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (cell.column.columnDef.enableEditing !== false) {
                      setTreeData(treeData);
                      renderDashboard(treeData, false);
                      window.vdmModelView.eventBus.emit(`${cell.row.original.uId}editContainerName`,cell.row.original.uId);
                      table.setEditingCell(null);
                    }
                  }
                }}
                onBlur={(e) => {
                  if (cell.column.columnDef.enableEditing !== false) {
                    setTreeData(treeData);
                    renderDashboard(treeData, false);
                    window.vdmModelView.eventBus.emit(
                    `${cell.row.original.uId}editContainerName`,cell.row.original.uId)
                    table.setEditingCell(null);
                  }
                  table.setEditingCell(null);
                }}
                sx={{ width: "180", fontSize: "15px" }}
                className="modifyFontSize"
              />
            );
          } else {
            return (
              <Select
                defaultOpen
                autoFocus
                value={cell.row.original.name === "" ? "" : presentation}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    table.setEditingCell(null);
                  }
                }}
                onBlur={(e) => {
                  table.setEditingCell(null);
                }}
                onChange={(event) => {
                  window.utils.startSpinner("saveData", "Saving ... ");
                  if (cell.row.original.name !== "") {
                    $(
                      "#bind" + window.utils.htmlEscape(cell.row.original.id)
                    ).remove();
                  }
                  handleSelectPresentation(id, event.target.value);
                  setTimeout(() => {
                    setIsEnableEditing(true);
                    var chartPresnt = window.vdmModelView.charts();
                    const pres = chartPresnt.find(
                      (obj) =>
                        obj.encodeId ===
                        window.utils.htmlEscape(
                          window.vdmModelView.hashTreeData.get(id).id
                        )
                    );
                    if (pres !== undefined) {
                      pres.loadContext();
                    } else {
                      if (charts.length > 0) {
                        charts.forEach((d) => {
                          if (
                            d.id === window.vdmModelView.hashTreeData.get(id).id
                          ) {
                            window.vdmModelView.renderNewPresentation(d, [
                              { ...treeData },
                            ]);
                          }
                        });
                      }
                    }
                    window.vdmModelView.setResizeHeight();
                    window.utils.stopSpinner("saveData");
                  }, 100);
                }}
                sx={{ width: "150px", fontSize: "15px" }}
                variant="standard"
              >
                {presentationArr.map((d) => (
                  <MenuItem
                    autoFocus
                    sx={{ fontSize: "14px" }}
                    value={d?.value}
                    key={d?.value}
                  >
                    {d?.text}
                  </MenuItem>
                ))}
              </Select>
            );
          }
        }
      },
      Cell: ({ cell, table }) => {
        return (
          <div>
            {cell.row.original.type === 1
              ? cell.row.original.name === ""
                ? "Select Presentation"
                : cell.row.original.name
              : cell.row.original.id==='root'?
              window.vdmModelView.model.get('name')
              :
              cell.row.original.name}
          </div>
        );
      },
    },
    {
      accessorKey: "type",
      header: DataManager.getDataManager().get('localeManager').get('type'),
      size: 80,
      editVariant: "select",
      enableEditing: (row) => {
        return (row.original.id==="root" || row.original.pId==="root") ||
          (row.original.subRows !== undefined &&
            row.original.subRows.length > 0)
          ? false
          : true;
      },
      Edit: ({ cell, column, table }) => {
        const [type, setType] = React.useState(cell.row.original.type);
        if (cell.row.original.id !== "root" && cell.row.original.pId !== "root") {
          return (
            <Select
              onChange={(event) => {
                if (cell.row.original.type != event.target.value) {
                  window.utils.startSpinner("saveData", "Updating ... ");
                  setType(event.target.value);
                  handleChangeType(
                    cell.row.original,
                    cell.row.original.uId,
                    event.target.value,
                    cell.row.getParentRow().original.h
                  );
                  setTimeout(() => {
                    setIsEnableEditing(true);
                  
                    window.vdmModelView.setResizeHeight();
                    window.utils.stopSpinner("saveData");
                  }, 100);
                }
              }}
              autoFocus
              defaultOpen
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  if (cell.column.columnDef.enableEditing !== false) {
                    window.utils.startSpinner("saveData", "Saving ... ");
                    setTreeData(treeData);
                    renderDashboard(treeData, false);
                    window.vdmModelView.eventBus.emit(
                      `${cell.row.getParentRow().original.uId}changeType`,cell.row.getParentRow().original.uId);
                    table.setEditingCell(null);
                    setTimeout(() => {
                      setIsEnableEditing(true);
                      window.utils.stopSpinner("saveData");
                    }, 500);
                  }
                }
              }}
              onBlur={(e) => {
                setTreeData(treeData);
                renderDashboard(treeData, false);
                window.vdmModelView.eventBus.emit(
                `${cell.row.getParentRow().original.uId}changeType`,cell.row.getParentRow().original.uId);
                table.setEditingCell(null);
              }}
              sx={{ width: "130px", fontSize: "15px" }}
              variant="standard"
              value={type}
            >
              {typeOptionArray.map((d) => (
                <MenuItem
                  sx={{ fontSize: "14px" }}
                  value={d?.value}
                  key={d?.value}
                >
                  {d?.text}
                </MenuItem>
              ))}
            </Select>
          );
        }
      },
      Cell: ({ cell, table }) => {
        return (
          <div>
            <Tooltip
              title={
                cell.row.original.type === 1 ? "Presentation" : "Container"
              }
            >
            <IconButton>
                {cell.row.original.type === 1 ? (
                  <PanoramaWideAngleSelectIcon fontSize="medium" />
                ) : (
                  <AutoAwesomeMosaicIcon fontSize="medium" />
                )}
              </IconButton>
            </Tooltip>
            {/* {cell.row.original.type === 1 ? "Presentation" : "Container"} */}
          </div>
        );
      },
    },
    {
      accessorKey: "w",
      header: DataManager.getDataManager().get('localeManager').get('Width'),
      size: 60,
      editVariant: "number",
      enableEditing: true,
      Edit: ({ cell, table }) => {
        const [widthField, setWidthField] = React.useState(
          parseFloat(cell.row.original.w).toFixed(2)
        );
        const [widthFieldError, setWidthFieldError] = React.useState("");
        function widthValidation(input) {
          const number = parseFloat(input).toFixed(2);

          if (!input) {
            setWidthFieldError("Required.");
            return;
          }
          if (isNaN(number)) {
            setWidthFieldError("Invalid Value.");
          } else if (number < 15 || number > 100.0) {
            setWidthFieldError("Width should be in between 15 to 100.");
          } else {
            setWidthFieldError("");
          }
        }

        function handleChangeWidth(id, value) {
          widthValidation(value);
          setWidthField(value);
        }
        if (cell.row.original.id !== "root") {
          return (
            <TextField
              sx={{ width: "180", fontSize: "15px" }}
              className="modifyFontSize"
              type="number"
              autoFocus
              error={!!widthFieldError}
              helperText={widthFieldError}
              value={widthField}
              variant="standard"
              onChange={(event) => {
                handleChangeWidth(cell.row.original.uId, event.target.value);
              }}
              onBlur={(event) => {
                widthValidation(event.target.value);
                if (widthFieldError === "") {
                  window.utils.startSpinner("saveData", "Updating Width ... ");
                  setIsEnableEditing(false);
                  window.vdmModelView.hashTreeData.get(
                    cell.row.original.uId
                  ).w = event.target.value;
                  renderDashboard(treeData, false);
                  window.vdmModelView.eventBus.emit(
                    `${cell.row.original.uId}changeWidth`, cell.row.original.uId);
                  setTimeout(() => {
                    setIsEnableEditing(true);
                    if (cell.row.original.type == 0) {
                      reAdjustContainer([treeData], cell.row.original.id);
                    } else {
                      reAdjustPres(cell.row.original.id);
                    }
                    window.utils.stopSpinner("saveData");
                  }, 100);
                }
                table.setEditingCell(null);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  widthValidation(e.target.value);
                  if (widthFieldError === "") {
                    window.utils.startSpinner(
                      "saveData",
                      "Updating Width ... "
                    );
                    setIsEnableEditing(false);
                    window.vdmModelView.hashTreeData.get(
                      cell.row.original.uId
                    ).w = e.target.value;
                    renderDashboard(treeData, false);
                    window.vdmModelView.eventBus.emit(
                      `${cell.row.original.uId}changeWidth`,cell.row.original.uId);
                    setTimeout(() => {
                      setIsEnableEditing(true);
                      if (cell.row.original.type == 0) {
                        reAdjustContainer([treeData], cell.row.original.id);
                      } else {
                        reAdjustPres(cell.row.original.id);
                      }
                      window.utils.stopSpinner("saveData");
                    }, 100);
                  }
                  table.setEditingCell(null);
                }
              }}
            />
          );
        }
      },

      Cell: ({ cell }) => {
        if (cell.row.original.id !== "root") {
          return <div>{`${parseFloat(cell.row.original.w).toFixed(2)}%`}</div>;
        }
      },
    },
    {
      accessorKey: "h",
      header: DataManager.getDataManager().get('localeManager').get('HeightInPx'),
      size: 60,
      enableEditing: true,
      Edit: ({ cell, table }) => {
        const arr = cell.row?.original.subRows
        const updArr = cell.row?.original.subRows.map(d=> +d.h);
        const doChildIsPresentation = cell.row.original?.type === 0;
        const [heightField, setHeightField] = React.useState(
          cell.row.original.h
        );
        const childHeight =
          cell.row?.subRows[0]?.original.type === 0
            ? Array.max(updArr)
            : "";
        if (cell.row.original.id !== "root") {
          return (
            doChildIsPresentation && (
              <TextField
                disabled={!doChildIsPresentation}
                sx={{ width: "180", fontSize: "15px" }}
                className="modifyFontSize"
                error={heightFieldError !== ""}
                helperText={heightFieldError}
                value={heightField}
                autoFocus
                type="number"
                variant="standard"
                onChange={(event) => {
                  heightValidation(
                    event.target.value,
                    cell.row.getParentRow()?.original.h,
                    childHeight
                  );
                  setHeightField(event.target.value);
                }}
                onBlur={(event) => {
                  heightValidation(
                    event.target.value,
                    cell.row.getParentRow()?.original.h,
                    childHeight
                  );
                  if (heightFieldError === "") {
                    window.utils.startSpinner(
                      "saveData",
                      "Updating Height ... "
                    );
                    setIsEnableEditing(false);
                    window.vdmModelView.hashTreeData.get(
                      cell.row.original.uId
                    ).h = event.target.value;
                    renderDashboard(treeData);
                    window.vdmModelView.eventBus.emit(`${cell.row.original.uId}changeHeight`,cell.row.original.uId);
                    window.vdmModelView?.setResizeHeight();
                    setTimeout(() => {
                      setIsEnableEditing(true);
                      reAdjustContainer([treeData], cell.row.original.id);
                      window.utils.stopSpinner("saveData");
                    }, 100);
                  }
                  table.setEditingCell(null);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    heightValidation(
                      e.target.value,
                      cell.row.getParentRow()?.original.h,
                      childHeight
                    );
                    if (heightFieldError === "") {
                      window.utils.startSpinner(
                        "saveData",
                        "Updating Height ... "
                      );
                      setIsEnableEditing(false);
                      window.vdmModelView.hashTreeData.get(
                        cell.row.original.uId
                      ).h = e.target.value;
                      renderDashboard(treeData);
                      window.vdmModelView.eventBus.emit(`${cell.row.original.uId}changeHeight`,cell.row.original.uId)
                      window.vdmModelView.setResizeHeight();
                      setTimeout(() => {
                        setIsEnableEditing(true);
                        reAdjustContainer([treeData], cell.row.original.id);
                        window.utils.stopSpinner("saveData");
                      }, 100);
                    }
                    table.setEditingCell(null);
                  }
                }}
              />
            )
          );
        }
      },
      Cell: ({ cell }) => {
        const doChildIsPresentation = cell.row.original?.type === 0;
        const h = doChildIsPresentation ? cell.row.original.h : "";
        return <div>{h}</div>;
      },
    },
    {
      accessorKey: "b",
      header: DataManager.getDataManager().get('localeManager').get('Border'),
      size: 60,
      enableEditing: false,
      Cell: ({ cell }) => {
        if (cell.row.original.id !== "root") {
          return (
            cell.row.original.type === 0 && (
              <Tooltip title="Border">
                <span>
                  <Checkbox
                    disabled={!isEnableEditing}
                    inputProps={{ "aria-label": "controlled" }}
                    checked={cell.row.original.b}
                    // color="rgba(0, 0, 0, 0.54)"
                    onChange={(e) => {
                      handleBorderChange(
                        e.target.checked,
                        cell.row.original.uId
                      );
                    }}
                    className="borderCheckbox"
                  />
                </span>
              </Tooltip>
            )
          );
        }
      },
    },
    {
      accessorKey: "",
      id: window.uuidGenerator(),
      header: DataManager.getDataManager().get('localeManager').get('Action'),
      size: 100,
      Cell: ({ renderedCellValue, row, cell, table }) => {
        var deleteMsg = DataManager.getDataManager().get('localeManager').get('ContainerDeleteMsg');
        let hideMoveUp = cell.row.index !== 0;
        let hideMoveDown = cell.row.index !== cell.row?.getParentRow()?.original.subRows.length - 1;
        return (
          <>
            <div className="containerActionWrapper">
              {row.original.type === 0 && (
                <div>
                  <Tooltip title="Add Child">
                    <span>
                      <IconButton
                        disabled={!isEnableEditing}
                        onClick={() => {
                          window.utils.startSpinner("saveData", "Saving ... ");
                          setIsEnableEditing(false);
                          handleAddChild(
                            row?.original.id,
                            row?.original.uId,
                            row?.original?.h
                          );
                          table.setExpanded((prev) => ({
                            ...prev,
                            [row.id]: row.getIsExpanded(),
                          }));
                          setTimeout(() => {
                            setIsEnableEditing(true);
                            window.utils.stopSpinner("saveData");
                          }, 500);
                        }}
                      >
                        <AddCircleIcon fontSize="large" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              )}
              {row.original.id !== "root" && row.original.type === 0 && (
                <>
                  <Box>
                    <Tooltip title="Delete">
                      <span>
                        <IconButton
                          disabled={!isEnableEditing}
                          onClick={() => {
                            bootbox.confirm(deleteMsg, function(result) {
                              if (result) {
                                window.utils.startSpinner(
                                  "saveData",
                                  "Deleting ... "
                                );
                                setIsEnableEditing(false);
                                deleteHandler(
                                  row.getParentRow().original.uId,
                                  row.original.uId
                                );
                              }
                            });
                          }}
                        >
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </>
              )}
              {row.original.type === 1 && (
                <>
                  {row.original.name === "" ? (
                    <>
                      <Tooltip title="Add Presentation">
                        <span>
                          <IconButton
                            disabled={!isEnableEditing}
                            onClick={() => {
                              window.vdmModelView.dashboardDesignViewModel.addChart(
                                row?.original.id,
                                row?.original.uId,
                                treeData
                              );
                            }}
                          >
                            <QueueIcon fontSize="large" />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <span>
                          <IconButton
                            disabled={!isEnableEditing}
                            onClick={() => {
                              window.utils.startSpinner(
                                "saveData",
                                "Deleting ... "
                              );
                              setIsEnableEditing(false);
                              deleteHandler(
                                row.getParentRow().original.uId,
                                row.original.uId
                              );
                            }}
                          >
                            <DeleteIcon fontSize="large" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit Presentation">
                        <span>
                          <IconButton
                            disabled={!isEnableEditing}
                            onClick={() => {
                              window.vdmModelView.dashboardDesignViewModel.editChart(
                                row?.original,
                                treeData
                              );
                            }}
                          >
                            <ModeEditIcon fontSize="large" />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <span>
                          <IconButton
                            disabled={!isEnableEditing}
                            onClick={() => {
                              bootbox.confirm(
                                DataManager.getDataManager()
                                  .get("localeManager")
                                  .get("PresentationDelete"),
                                function(result) {
                                  if (result) {
                                    window.vdmModelView.dashboardDesignViewModel.deleteChart(
                                      { id: cell.row.original.id }
                                    );
                                    deletePresentation(
                                      cell.row.original.id,
                                      cell.row.original.uId,
                                      cell.row.getParentRow().original.uId
                                    );
                                    window.vdmModelView.setResizeHeight();
                                    window.utils.stopSpinner("saveData");
                                  }
                                }
                              );
                            }}
                          >
                            <DeleteIcon fontSize="large" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                </>
              )}
              {row.original.id !== "root" && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {hideMoveUp && (
                    <Tooltip title="Up" placement="right">
                      <IconButton
                        sx={{
                          paddingTop: !hideMoveUp && "0 !important",
                          paddingBottom: !hideMoveUp && "0 !important",
                        }}
                        onClick={() => {
                          handleMoveContainer(
                            cell.row.getParentRow().original,
                            cell.row.original,
                            "up"
                          );
                          setTreeData(treeData);
                          renderDashboard(treeData, false);
                          window.vdmModelView.eventBus.emit(
                            `${cell.row.getParentRow().original.uId}move`,
                            cell.row.getParentRow().original.uId
                          );
                          setIsEnableEditing(false);
                          setTimeout(() => {
                            var chartPresnt = window.vdmModelView.charts();
                            for (let pres of chartPresnt) {
                              renderContainer(
                                [cell.row.getParentRow().original],
                                cell.row.original.pId,
                                pres
                              );
                            }
                            setIsEnableEditing(true);
                            window.utils.stopSpinner("saveData");
                          }, 100);
                        }}
                      >
                        <ArrowDropUp fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {hideMoveDown && (
                    <Tooltip title="Down" placement="right">
                      <IconButton
                        sx={{
                          paddingTop: !hideMoveUp && "0 !important",
                          paddingBottom: !hideMoveUp && "0 !important",
                        }}
                        onClick={() => {
                          handleMoveContainer(
                            cell.row.getParentRow().original,
                            cell.row.original,
                            "down"
                          );
                          setTreeData(treeData);
                          renderDashboard(treeData, false);
                          window.vdmModelView.eventBus.emit(
                            `${cell.row.getParentRow().original.uId}move`,
                            cell.row.getParentRow().original.uId
                          );
                          setIsEnableEditing(false);
                          setTimeout(() => {
                            var chartPresnt = window.vdmModelView.charts();
                            for (let pres of chartPresnt) {
                              renderContainer(
                                [cell.row.getParentRow().original],
                                cell.row.original.pId,
                                pres
                              );
                            }
                            setIsEnableEditing(true);
                            window.utils.stopSpinner("saveData");
                          }, 100);
                        }}
                      >
                        <ArrowDropDown fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
            </div>
          </>
        );
      },
    },
  ]);

  return (
    <MaterialReactTable
      data={[treeData]}
      columns={columns}
      enableEditing={isEnableEditing}
      enableTopToolbar={isEnableEditing}
      enableRowOrdering={true}
      enableDensityToggle={false}
      enableFilters={false}
      enableColumnActions={false}
      enableColumnDragging={false}
      enableFullScreenToggle={false}
      muiExpandButtonProps={({ row }) => ({
        children: row.getIsExpanded() ? <ExpandMore /> : <ExpandLess />,
      })}
      displayColumnDefOptions
      initialState={{ columnVisibility: { b: false },expanded:{"0":true} }}
      muiTableBodyRowDragHandleProps={({ row, table }) => ({
        sx: { display: row.original.id === 'root' && "none" },
        onDragStart: () => {
          setIsDragging(true);
        },
        onDragEnd: (e) => {
          const { draggingRow, hoveredRow } = table.getState();
          setIsDragging(false);
          setTimeout(() => {
            dragHandler(hoveredRow, draggingRow);
          }, 100);
        },
        onDragOver: (e) => {
          e.preventDefault(); // Prevent default behavior to allow drop
          const { clientY } = e;
          const topBuffer = 10; 
          const scrollSpeed = 30;
          const tableBody = e.currentTarget.closest('.MuiTableBody-root');
          const { top: tableBodyTop, height: tableBodyHeight } = tableBody.getBoundingClientRect();
          const offsetY = clientY - tableBodyTop;
          if (offsetY < topBuffer) {
            tableBody.scrollTop -= scrollSpeed;
          } else if (offsetY > tableBodyHeight - topBuffer) {
            tableBody.scrollTop += scrollSpeed;
          }
          // Handling scrolling when dragging to the bottom
          const tableBodyBottom = tableBodyTop + tableBodyHeight;
          if (offsetY > tableBodyBottom - topBuffer && tableBody.scrollTop + tableBody.clientHeight < tableBody.scrollHeight) {
            tableBody.scrollTop += scrollSpeed;
          }
        },
      })}
      enableExpanding
      enableExpandAll
      enablePagination={false}
      muiTableHeadCellProps={{
        sx: {
          fontSize: "14px !important",
          backgroundColor: "li",
        },
      }}
      muiTableBodyCellProps={({ row, table }) => ({
        onDoubleClick: (e) => {
          getPresentationsList(row?.original?.id);
        },
        sx: {
          fontSize: "14px",
        },
      })}
      editingMode="cell"
    />
  );
}
