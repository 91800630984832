import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global)
{*/
    var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties", global.version);
    export class AlternativeViewModel {
        //this.init(model, options, detailedView);
    //}
	constructor(model,options){
		var self = this;
		this.init(model, options);
	}
    htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
    cleanUp() {
        var self = this;
        delete self.model.phaseAlternativeOwner;
        self.model.destroy();
    };
	openAlternativeDetailsViewHandler(view,event){
		event.stopPropagation();
		var self=this;
		var phaseId = self.model.get("phaseAlternativeOwner");
		var planId = event.currentTarget.getAttribute('altPlanId');
		var AlternativeMixin=Backbone.Relational.store.getObjectByName("transformation.AlternativeMixin");
		if(!utils.versionChange(window.vdmModelView.plansCollection.findWhere({'id':planId}).get('version'))){ return }
		window.utils.startSpinner('loadAlternative',"Loading Alternative Data...");
		setTimeout(function () {
			window.utils.loadPlan(planId, function (plan) {
				var phaseAlternativeCollection = plan.get('phase').findWhere({'id':phaseId}).get('phaseAlternative');
				var altArray = [];
				for(var i=0;i<phaseAlternativeCollection.length;i++){
					var phaseAlternateModel=phaseAlternativeCollection.models[i];
					var obj={id:altArray.length,originalId:phaseAlternateModel.get("id"),modalId:self.htmlEscape(phaseAlternateModel.get("id"))+"alternative", name:phaseAlternateModel.get("name"),description:phaseAlternateModel.get("description"),purpose:phaseAlternateModel.get("purpose"),del:false};
					altArray.push(obj);
				}
				var bo = plan.get('phase').findWhere({'id':phaseId}).get('phaseAlternative').findWhere({ 'id': view.model.id });
				var addOptions = {'planModel': plan,'parentModel':view.model,'alternatives':altArray,'alternativesCustom':true};
				window.getAndCreateModalDialog(true, self.encodeId, AlternativeMixin, bo, "AlternativeDetails", function(view,encodeId){
					window.utils.startSpinner('saveAlternative','Saving Data...');
					var nameChange = false;
					if(view.name()!= view.model.get('name')){
						nameChange = true;
					}
					view.model.set('name',view.name());
					view.model.set('description',view.description());
					view.model.set('purpose',view.purpose());
    
					var inputValuesView = view.inputValuesView;
					if(inputValuesView) {
						view.model.saveDirectValues(inputValuesView.directValueChanges);
						var inputValuesEncodeId = inputValuesView.encodeId;
						window.cleanDialogModel(inputValuesEncodeId,inputValuesView);
					}
					if (nameChange){
						$(".plan-heading span[encodeId='" + self.htmlEscape(view.id()) + "']").text(view.name());
						var dataManager = DataManager.getDataManager();
						var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
						dataManager.saveChangeSetToBackend(serverChangeSet,function(){
							dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
							window.utils.stopSpinner('saveAlternative');
							});
						});
					}
					else{
						window.utils.stopSpinner('saveAlternative');
					}
					window.cleanDialogModel(encodeId,view);

				}, addOptions);
				window.utils.stopSpinner('loadAlternative');
			});
		},100);
		//event.stopPropagation();
	};
	onAltExplorer = function (view,event) {
        var self = this;
        var planId = event.currentTarget.getAttribute('altPlanId');
        //var plan = self.relModel.getParent().getParent();
		//var planId = plan.id;
        //var docVersion = plan.get('documentVersion');
        var altId = self.model.get('id');
		if(!utils.versionChange(window.vdmModelView.plansCollection.findWhere({'id':planId}).get('version'))){ return }
        window.utils.startSpinner('spinnerPhaseExplorer', "Loading Plan Data...");
		//$('#currentRepository').attr('href', "#search/" + window.vdmModelView.visualSearch.searchBox.value());
		//$('#brandIcon').attr('href', "#search/" + window.vdmModelView.visualSearch.searchBox.value());
		//DataManager.getDataManager().get('router').navigate("search/" + window.vdmModelView.visualSearch.searchBox.value(), { replace: true });
        window.utils.showSpinnerPercentage(10);
        setTimeout(function () {
            var dataManager = DataManager.getDataManager();
            window.utils.loadPlan(planId, function (plan) {
                window.utils.showSpinnerPercentage(30); 
                dataManager.get('router').navigate("/" + window.utils.getPlanNavigateUrl(planId,plan.get('documentVersion')) + '/'+ altId, { trigger: true });                       
            });
        }, 100);
    };
    init(model,options){
		var self = this;
		this.AlternativeViewModel = this;
		this.model = model;
		model = null;
		if(this.model){
			this.relModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({id:self.model.id});
		}

		this.id = kb.observable(self.model, 'id');

		this.encodeId = self.htmlEscape(this.id());
		this.version = kb.observable(self.model, 'version');
		this.name = kb.observable(self.model, 'name');
		this.description = kb.observable(self.model, 'description');
		this.enableComplete=ko.observable(true);
		this.openAlternativeDetailsView = _.bind(self.openAlternativeDetailsViewHandler,self);
		this.afterRenderView = function(){
		}
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['general','Alternative'
		,'version'
		,'name'
		,'description']);
	}
	
	getInstance(model,options){
		var view = new AlternativeViewModel(model,options);
		//view.init(model, options);
		return view;
	};
}
path.AlternativeViewModel = AlternativeViewModel;