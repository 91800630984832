import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as moment from 'moment'
import {NamedPlanElement} from './NamedPlanElement'
import {NamedPlanElementMixin} from './NamedPlanElementMixin'
import {Alternative} from './Alternative'
import * as async from 'async'
import * as jszip from '../../../../libs/jszip/jszip.min'
import * as bootbox from '../../../../libs/bootbox/bootbox'
import * as FileSaver from '../../../../libs/FileSaver/FileSaver'
//import {Plan} from './Plan'
import {BeepModule} from '../beeppackage/BeepModule'
import {BeepVocabulary} from '../concept/BeepVocabulary'
import {Vocabulary} from '../concept/Vocabulary'
import {Scenario} from '../vdml/Scenario'
import {Observation} from '../smm/Observation'
import {MeasuredCharacteristic} from '../vdml/MeasuredCharacteristic'
import {Phase} from './Phase'
import {ScenarioProxy} from './ScenarioProxy'
import {CodeContainer} from '../beeppackage/CodeContainer'
import {ValueDeliveryModel} from '../vdml/ValueDeliveryModel'
import {EcoMap} from '../ecomap/EcoMap'
import {RDFModel} from '../../../com/vbee/rdf/RDFModel'
//import {PackageReference} from './PackageReference'
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {ValueDeliveryModelMixin} from '../vdml/ValueDeliveryModelMixin'
import {EcoMapDiagram} from '../vdml/EcoMapDiagram'
import {StrategyMapDiagram} from '../vdml/StrategyMapDiagram'
import {ValueStreamMapDiagram} from '../vdml/ValueStreamMapDiagram'
import {CapabilityLibrary} from '../vdml/CapabilityLibrary'
import {CapabilityLibraryDiagram} from '../vdml/CapabilityLibraryDiagram'
import {CapabilityCategory} from '../vdml/CapabilityCategory'
import {CapabilityDefinition} from '../vdml/CapabilityDefinition'
import {Status} from './Status'
import {PackageReference} from '../../../com/vbee/filesystem/PackageReference'
//import {PackageReference as PlanPackageReference} from './PackageReference'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {Role} from './Role'
import {User} from '../tickets/User'
import {ValueProposition} from '../vdml/ValueProposition'
import {SmmModel} from '../smm/SmmModel'
import { DataManager } from '../../../com/vbee/data/DataManager'
import {asyncsimple} from '../../../../libs/asyncsimple/asyncsimple'
import {DimensionalMeasure} from '../smm/DimensionalMeasure'

/*define(["require", "jquery", "underscore", "jszip", "FileSaver", 'async', "asyncsimple", "backbone", "Lawnchair", "backbone-lawnchair", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/transformation/NamedPlanElement", "appbo/transformation/NamedPlanElementMixin", "appcommon/com/vbee/rdf/RDFModel", "appbo/transformation/Plan", "appbo/beeppackage/BeepModule", "appbo/concept/Vocabulary", "appbo/vdml/Scenario", "appbo/smm/Observation", "appbo/vdml/MeasuredCharacteristic", "appbo/transformation/Phase", "appbo/transformation/Alternative", "appbo/transformation/ScenarioProxy", "appbo/beeppackage/CodeContainer", "appbo/vdml/ValueDeliveryModel", "appbo/ecomap/EcoMap", "appbo/transformation/PackageReference", "moment", "appbo/beeppackage/BeepPackageMixin", "appbo/smm/BusinessIndicatorDefinition", "appbo/smm/BusinessIndicatorCategory", "appbo/vdml/ValueDeliveryModelMixin", "appbo/vdml/EcoMapDiagram", "appbo/vdml/StrategyMapDiagram", "appbo/vdml/ValueStreamMapDiagram", "appbo/vdml/CapabilityLibrary", "appbo/vdml/CapabilityLibraryDiagram", "appbo/vdml/CapabilityCategory", "appbo/vdml/CapabilityDefinition", "appbo/transformation/Status", "appcommon/com/vbee/filesystem/PackageReference", "jsencrypt"],
    function (require, jQuery, _, jszip, FileSaver, async, asyncsimple, Backbone, Lawnchair, backboneLawnchair, bootbox, DataManager, global, NamedPlanElement, NamedPlanElementMixin, RDFModel, Plan, BeepModule, Vocabulary, Scenario, Observation, MeasuredCharacteristic, Phase, Alternative, ScenarioProxy, CodeContainer, ValueDeliveryModel, EcoMap, PlanPackageReference, moment, BeepPackageMixin, BusinessIndicatorDefinition, BusinessIndicatorCategory, ValueDeliveryModelMixin, EcoMapDiagram, StrategyMapDiagram, ValueStreamMapDiagram, CapabilityLibrary, CapabilityLibraryDiagram, CapabilityCategory, CapabilityDefinition, Status, PackageReference, aes, md5
        , BeepPackage
        , Role
        , User
        , ValueProposition
        , SmmModel) {*/

       // var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);

          if (!BeepPackage) {
            var importPath = DataManager.getDataManager().buildAppNsPath("beeppackage", global.version);
            if (importPath.BeepPackage) {
                BeepPackage = importPath.BeepPackage;
            }
            else {
                import('../beeppackage/BeepPackage').then(loadedModule => {
                    BeepPackage = loadedModule;
                });

                /*require(["appbo/beeppackage/BeepPackage"], function (loadedModule) {
                    BeepPackage = loadedModule;
                });*/
            }
        }
        if (!BeepPackageMixin) {
            var importPath = DataManager.getDataManager().buildAppNsPath("beeppackage", global.version);
            if (importPath.BeepPackageMixin) {
                BeepPackageMixin = importPath.BeepPackageMixin;
            }
            else {
                import('../beeppackage/BeepPackageMixin').then(loadedModule => {
                    BeepPackageMixin = loadedModule;
                });

               /* require(["appbo/beeppackage/BeepPackageMixin"], function (loadedModule) {
                    BeepPackageMixin = loadedModule;
                });*/
            }
        }
        if (!Role) {
            var importPath = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
            if (importPath.Role) {
                Role = importPath.Role;
            }
            else {
                import('./Role').then(loadedModule => {
                    Role = loadedModule;
                });

                /*require(["appbo/transformation/Role"], function (loadedModule) {
                    Role = loadedModule;
                });*/
            }
        }
        if (!User) {
            var importPath = DataManager.getDataManager().buildAppNsPath("tickets", global.version);
            if (importPath.User) {
                User = importPath.User;
            }
            else {
                import('../tickets/User').then(loadedModule => {
                    User = loadedModule;
                });

                /*
                require(["appbo/tickets/User"], function (loadedModule) {
                    User = loadedModule;
                });*/
            }
        }
       // if (!Plan) {
            var importPath = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
            if (importPath.Plan) {
                Plan = importPath.Plan;
            }
            else {
                import('./Plan').then(loadedModule => {
              //      Plan = loadedModule;
                });

                /*
                require(["appbo/transformation/Plan"], function (loadedModule) {
                    Plan = loadedModule;
                });*/
            }
       // }
        if (!Phase) {
            var importPath = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
            if (importPath.Phase) {
                Phase = importPath.Phase;
            }
            else {
                import('./Phase').then(loadedModule => {
                    Phase = loadedModule;
                });

                /*
                require(["appbo/transformation/Phase"], function (loadedModule) {
                    Phase = loadedModule;
                });*/
            }
        }
        if (!ValueProposition) {
            var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            if (importPath.ValueProposition) {
                ValueProposition = importPath.ValueProposition;
            }
            else {
                import('../vdml/ValueProposition').then(loadedModule => {
                    ValueProposition = loadedModule;
                });

                
                /*require(["appbo/vdml/ValueProposition"], function (loadedModule) {
                    ValueProposition = loadedModule;
                });*/
            }
        }
        if (!SmmModel) {
            var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            if (importPath.SmmModel) {
                SmmModel = importPath.SmmModel;
            }
            else {
                import('../smm/SmmModel').then(loadedModule => {
                    SmmModel = loadedModule;
                });

                /*require(["appbo/smm/SmmModel"], function (loadedModule) {
                    SmmModel = loadedModule;
                });*/
            }
}
var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
export class PlanMixin{
        defaults() {
            var ret = {
                type: "transformation_Plan",
                maxIterations: 100,
                maxChange: 0.0001
            }
            return jQuery.extend(NamedPlanElement.prototype.defaults.apply(this), ret);
        }
        static getMixinRelations() {
            return _.union([
                {
                    type: Backbone.HasMany,
                    containingClass: "transformation_Plan",
                    key: "phase",
                    relatedModel: "transformation.Phase",
                    reverseRelation: {
                        key: "phaseOwner",
                        type: Backbone.HasOne,
                        includeInJSON: "id"
                    }
                },
                {
                    type: Backbone.HasOne,
                    containingClass: "transformation_Plan",
                    key: "asIs",
                    relatedModel: "transformation.Phase",
                    includeInJSON: Backbone.Model.prototype.idAttribute,
                },
                {
                    type: Backbone.HasMany,
                    containingClass: "transformation_Plan",
                    key: "planRole",
                    relatedModel: "transformation.Role",
                    reverseRelation: {
                        key: "planRoleOwner",
                        type: Backbone.HasOne,
                        includeInJSON: "id"
                    }
                },
                //		{
                //			type :Backbone.HasMany,
                //			containingClass:"transformation_Plan",
                //			key:"user",
                //			relatedModel:"tickets.User",
                //			reverseRelation: {
                //				key:"planUserOwner",
                //				type :Backbone.HasOne,
                //				includeInJSON:"id"
                //			}
                //		},
                {
                    type: Backbone.HasOne,
                    containingClass: "transformation_Plan",
                    key: "goal",
                    relatedModel: "transformation.Phase",
                    includeInJSON: Backbone.Model.prototype.idAttribute,
                },
                {
                    type: Backbone.HasOne,
                    containingClass: "transformation_Plan",
                    key: "planCriterionSet",
                    relatedModel: "vdml.ValueProposition",
                    reverseRelation: {
                        key: "planCriterionSetOwner",
                        type: Backbone.HasOne,
                        includeInJSON: "id"
                    }
                },
                /*		{
                            type :Backbone.HasMany,
                            containingClass:"transformation_Plan",
                            key:"planPackage",
                            relatedModel:"beeppackage.BeepPackage",
                            includeInJSON: Backbone.Model.prototype.idAttribute,
                        },*/
                {
                    type: Backbone.HasOne,
                    containingClass: "transformation_Plan",
                    key: "planObservationContainer",
                    relatedModel: "smm.SmmModel",
                    reverseRelation: {
                        key: "planObservationContainerOwner",
                        type: Backbone.HasOne,
                        includeInJSON: "id"
                    }
                },
                {
                    type: Backbone.HasMany,
                    containingClass: "transformation_Plan",
                    key: "planMeasureLibrariesContainer",
                    relatedModel: "smm.SmmModel",
                    includeInJSON: Backbone.Model.prototype.idAttribute,
                },
                {
                    type: Backbone.HasOne,
                    containingClass: "transformation_Plan",
                    key: "defaultMeasureLibrary",
                    relatedModel: "smm.MeasureLibrary",
                    includeInJSON: Backbone.Model.prototype.idAttribute,
                },
                {
                    type :Backbone.HasMany,
                    containingClass:"transformation_Plan",
                    key:"assignedTicket",
                    relatedModel:"tickets.Ticket",
                    includeInJSON: Backbone.Model.prototype.idAttribute,
                }
                /*{
                    type: Backbone.HasMany,
                    containingClass: "concept_Vocabulary",
                    key: "vocabulary",
                    relatedModel: "concept.Vocabulary",
                    includeInJSON: Backbone.Model.prototype.idAttribute,
                }*/
            ])
        }
        static getCumulativeMixinRelations() {
            if (!PlanMixin.cummulativeRelations) {
                var BeepPackageMixin = Backbone.Relational.store.getObjectByName("beeppackage.BeepPackageMixin");
                PlanMixin.cummulativeRelations = _.union(PlanMixin.getMixinRelations()
                    , BeepPackageMixin.getCumulativeMixinRelations()
                    , NamedPlanElementMixin.getCumulativeMixinRelations()
                );
            }
            return PlanMixin.cummulativeRelations.slice();
        }
        static getSuperTypes() {
            return [
                "beeppackage_BeepPackage",
                "transformation_NamedPlanElement"
            ];
        }
        static getProperties() {
            return [
                { name: "version", type: "EString", defaultValue: "null", containingClass: "beeppackage_BeepPackage" },
                { name: "label", type: "EString", defaultValue: "null", containingClass: "beeppackage_BeepPackage" },
                { name: "parentLabel", type: "EString", defaultValue: "null", containingClass: "beeppackage_BeepPackage" },
                { name: "syncedChangeLog", type: "EString", defaultValue: "null", containingClass: "beeppackage_BeepPackage" },
                { name: "synchedChangeSet", type: "EString", defaultValue: "null", containingClass: "beeppackage_BeepPackage" },
                { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
                { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
                { name: "planType", type: "PlanType", defaultValue: "null", containingClass: "transformation_Plan" },
                { name: "status", type: "Status", defaultValue: "null", containingClass: "transformation_Plan" },
                { name: "maxIterations", type: "EString", defaultValue: "100", containingClass: "transformation_Plan" },
                { name: "originalId", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" }
            ]
        }
        getParent() {
            return this;
        }
        getPackagePath(path) {
            if (!path) {
                path = [];
            }
            return NamedPlanElement.prototype.getPackagePath.apply(this, arguments);
        }
        getViewProperties(type) {
            return PlanMixin.getDialogViewProperties(type);
        };
        getAlternatives() {
            var currentPlan = this;
            var ret = [];
            if (currentPlan) {
                var phases = currentPlan.get('phase');
                for (var i = 0; i < phases.length; i++) {
                    var phase = phases.at(i);
                    var alternatives = phase.get('phaseAlternative');
                    for (var j = 0; j < alternatives.length; j++) {
                        var alternative = alternatives.at(j);
                        ret.push(alternative.get('id'));
                    }
                }
            }
            return ret;
        };

        //#startCustomMethods	
        incrementOtherAlts(model, view, callback) {
            var self = this;
            var depPackagesList = self.get('dependentPackage').models.concat();
            //var nextAltList = view.currentAlternativeModel.getAlternativesWhereICanBeReffered();
            var nextAltList = [];
            var currentPhase = view.currentAlternativeModel.get('phaseAlternativeOwner');
            var master = currentPhase.get('master');
            var primary = currentPhase.get('primary');
            if (master === primary && master != view.currentAlternativeModel) {
                callback();
                return;
            }
            nextAltList = nextAltList.concat(currentPhase.get('phaseAlternative').models);
            if (master !== primary) {
                if (primary != view.currentAlternativeModel) {
                    callback();
                    return;
                } else {
                    var nextAltLen = nextAltList.length;
                    while (nextAltLen--) {
                        if (nextAltList[nextAltLen].getParent() === currentPhase) {
                            nextAltList.splice(nextAltList[nextAltLen], 1);
                        }
                    }
                }

            }

            var nextPhase = currentPhase.get('nextPhase');
            if (nextPhase && nextPhase.get('primary').getOrCreatePhaseObjectiveSet().get('localChange')) {
                nextPhase = null;
            }
            while (nextPhase) {
                nextAltList = nextAltList.concat(nextPhase.get('phaseAlternative').models);
                nextPhase = nextPhase.get('nextPhase');
                if (nextPhase && nextPhase.get('primary').getOrCreatePhaseObjectiveSet().get('localChange')) {
                    nextPhase = null;
                }
            }

            var altList = _.filter(nextAltList, function (alt) { if (alt !== view.currentAlternativeModel) { return alt; } });
            var incrementUpdate = false;
            var previousWorkspace = DataManager.getDataManager().getWorkspace();
            async.eachSeries(altList, function (alt, handleAlt) {
                if (alt.getOrCreatePhaseObjectiveSet().get('localChange') !== true) {
                    incrementUpdate = true;
                    DataManager.getDataManager().getWorkspaceDataWithId(alt.id, function (wsData) {
                        var altWorkspace = wsData.get('workspace');
                        DataManager.getDataManager().setWorkspace(altWorkspace, function () {

                            async.eachSeries(depPackagesList, function (pack, handlePack) {
                                var packExists = alt.get('phaseDesignPart').findWhere({ 'beepReference': pack.id });
                                if (packExists) {
                                    pack.updateScenario(alt, function () {
                                        handlePack();
                                    });
                                } else {
                                    handlePack();
                                }
                            }, function () {
                                //Update Plan
                                var accumulatorJson = view.fetchAccumulatorJson(view.accumulator());
                                var valUnitObj = { unitName: view.measurementUnit(), roundingDigits: view.roundingDigits(), significantDecimals: view.significantDecimals(), unitObj: view.measureUnits.findWhere({ 'name': view.measurementUnit() }) };
                                var satUnitObj = { unitName: view.satisfactionUnitValue(), roundingDigits: view.satRoundingDigits(), significantDecimals: view.satSignificantDecimals(), unitObj: view.measureUnits.findWhere({ 'name': view.satisfactionUnitValue() }) };
                                var baseTupleslen = view.baseTuples.length;
                                var updateValue = true
                                while (baseTupleslen--) {
                                    var compPackage = view.baseTuples[baseTupleslen].new ? view.baseTuples[baseTupleslen].new.component.getNestedParent() : null;
                                    if (view.baseTuples[baseTupleslen].new && compPackage && compPackage != DataManager.getDataManager().get('currentPlan') && !alt.get('phaseDesignPart').findWhere({ 'beepReference': compPackage.id })) {
                                        view.baseTuples.splice(view.baseTuples[baseTupleslen], 1);
                                    }
                                    else if (view.baseTuples[baseTupleslen].new && !view.baseTuples[baseTupleslen].old) {
                                        view.baseTuples[baseTupleslen].new.baseMeasureRel = null;
                                        updateValue = false;
                                    }
                                }
                                var prime = false;
                                if (view.satisfactionOnly() && view.enableSatisfactionOnly()) {
                                    prime = true;
                                } else if (view.enablePrime() && view.checkPrime()) {
                                    prime = true;
                                }
                                if (prime) {
                                    model.prime(view.measurementValue(), valUnitObj, view.formula(), view.satisfaction(), view.satisfactionType(), view.checkDimensional(), satUnitObj, view.weight(), view.checkDimensional() ? view.recipientValue() : view.recipientSatValue(), accumulatorJson, view.baseTuples, view.intervalTuples, null, alt, view.maxChange(), view.bm, view.expression(), function () {
                                        handleAlt();
                                    });
                                } else if (updateValue && view.checkPrime()) {
                                    model.update(view.name(), view.measurementValue(), valUnitObj, view.formula(), view.satisfactionType(), view.checkDimensional(), view.satisfaction(), satUnitObj, view.weight(), view.checkDimensional() ? view.recipientValue() : view.recipientSatValue(), accumulatorJson, view.baseTuples, view.intervalTuples, view.timestamp(), alt, view.maxChange(), view.bm, view.expression(), function () {
                                        handleAlt();
                                    });
                                } else {
                                    handleAlt();
                                }
                            });
                        });
                    });
                } else {
                    handleAlt();
                }
            }, function () {
                if (DataManager.getDataManager().get('currentWorkspace') == previousWorkspace) {
                    callback(incrementUpdate);
                } else {
                    DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
                        callback(incrementUpdate);
                    });
                }
            });
        }

        importMetricsCSV(timeStamp, startPoint, callback, fileObject) {
            var self = this;
            function readCSV(fileObject) {
                if(!fileObject || (fileObject && fileObject.type != "text/csv")){
                    window.utils.stopSpinner("importCSV");
                    bootbox.alert(DataManager.getDataManager().get("localeManager").get("InvalidFileFormat", "CSV"));
                    return;
                }
                var reader = new FileReader();
                reader.onload = function (ev) {
                    //require(["xlsx"], function (_XLSX) {
                    import('xlsx')
                    .then((XLSX) => {
                        var csvContent = reader.result;
                        if (csvContent.trim().length === 0) {
                            window.utils.stopSpinner('importCSV');
                            bootbox.alert('Imported csv is empty');
                            return;
                        }

                        var wb = XLSX.read(csvContent, { type: "binary", cellDates: true });
                        //var wb = XLSX.read(csvContent, { type: "binary" });
                        var sheet1 = wb.Sheets[wb.SheetNames[0]];
                        var csvObj = XLSX.utils.sheet_to_row_object_array(sheet1);
                        if (csvObj.length === 0) {
                            window.utils.stopSpinner('importCSV');
                            bootbox.alert('Imported csv is empty');
                            return;
                        }
                        var cloumnHeaders = Object.getOwnPropertyNames(csvObj[0]);
                        window.setTimeout(function () {
                            if (!cloumnHeaders.some(header => header.startsWith('Scenario-')) || cloumnHeaders.indexOf('Phase>Alternative') < 0 || cloumnHeaders.indexOf('Unit') < 0 || cloumnHeaders.indexOf('Value Type') < 0 || cloumnHeaders.indexOf('ValueName') < 0) {
                                window.utils.stopSpinner('importCSV');
                                bootbox.alert('Imported csv has wrong layout');
                                return;
                            } else {
                                self.importCsvData(csvObj, timeStamp, startPoint, callback);
                            }
                        }, 100);
                    });
                };
                reader.readAsText(fileObject, 'UTF-8');
            }
            window.utils.startSpinner('importCSV', "Importing CSV ... ");
            readCSV(fileObject);
        }
        static checkTypeOfImportFunction(valid,type,fileObject,operation,planModel,name,desc,newFileObject,plCallback){
                //var self=this;
                if(planModel){
                    DataManager.getDataManager().set('currentPlan',planModel,{silent:true});
                }
			    if(type == 'xlsx') {
					if(valid) {
						window.utils.startSpinner('importXlsx', "Importing xlsx ... ");
                        window.setTimeout(function () {
                            ValueDeliveryModel.createFromXlsxFile(fileObject, operation, newFileObject, function(workbook){
                                bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete', 'xlsx'));
                                window.utils.stopSpinner('importXlsx');
                                DataManager.getDataManager().set('currentPlan', null);
                            }, planModel);
                    }, 200);
					} else {
						DataManager.getDataManager().set('currentPlan', null);
					}	
				} else {
                    var PlanMixin = Backbone.Relational.store.getObjectByName('transformation.PlanMixin');
					if (valid) {
						if (planModel && type === 'Vocabulary Library Package') {
							window.utils.startSpinner('importVocabulary', "Importing Vocabulary ... ");
							BeepVocabulary.unzipVocFiles(fileObject,function(lib){
								BeepVocabulary.createVocabularyFromFiles(lib, planModel, function(){
									window.utils.stopSpinner('importVocabulary');
									bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete','Vocabulary Library Package'));
								});
							});
						} else if (planModel && type === 'Business Canvas Type') {
							window.utils.startSpinner('importModule', "Importing Business Canvas Type ... ");
							PlanMixin.unzipModuleFiles(fileObject, function (files) {
								PlanMixin.createCanvasFromFiles(canvas, planModel, function () {
									window.utils.stopSpinner('importModule');
									jQuery('.btn').removeAttr('disabled');
									bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete', 'Business Canvas Type'));
								});
							});
						} else if (!planModel && type === 'Plan') {
							window.utils.startSpinner('importPlan', "Importing Plan...");
                            PlanMixin.unzipFiles(fileObject, function (files, passKey) {
                                if (files) {
                                    PlanMixin.createPlanFromFiles(files, passKey, name, desc, true,true, function (plan) {
                                        var dataManager = DataManager.getDataManager();
                                        dataManager.saveWorkspaceNamesData(dataManager.get("currentWorkspaceId"), dataManager.get("currentWorkspaceName"));
                                        dataManager.savePlanArifactsData(plan.get("id"), dataManager.get("currentWorkspaceId"));
                                        jQuery('.btn-editPlanClose').click();
                                        //window.cleanDialogModel(self.encodeId, self);
                                        //Backbone.history.fragment = "";
                                        if(plCallback){
                                            plCallback(plan);
                                        }
                                    });
                                } else {
                                    //var searchUrl = "search/null/null/null/0/Tag: \"Plan\"";
                                    //DataManager.getDataManager().get('router').navigate(searchUrl, { trigger: true });
                                    window.utils.stopSpinner('importPlan');
                                }
							});
						} else if (planModel && type === 'Capability Library Package') {
							PlanMixin.unzipCLPackFiles(fileObject,["vdml_ValueDeliveryModel","beeppackage_CodeContainer"], function (files) {
                                var CapMixin = Backbone.Relational.store.getObjectByName("vdml.CapabilityLibraryMixin");
								CapMixin.showCapLibList(planModel,files,function(result, selectedCapLibList, existCapPackId){
                                    if(result) {
									    CapMixin.createCapLibFromFiles(files, planModel, function () {
										    bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete', fileObject.name));
										    window.utils.stopSpinner('importCapabilityLibraryPackage');
									    },selectedCapLibList, existCapPackId);
                                    }else{
                                        bootbox.alert(DataManager.getDataManager().get('localeManager').get('LibPackageDenied'));
                                    }
								});
							});
						} else if (planModel && type === 'EcoMap Package') {
							PlanMixin.unzipCLPackFiles(fileObject,["ecomap_EcoMap","beeppackage_CodeContainer","vdml_ValueDeliveryModel"], function (files) {
								PlanMixin.showSelectionList(planModel,files,function(result, alternativeId, selectedMapList, packId,selectedCapLibList){
                            		if (result) {
									    PlanMixin.createEcoMapFromFiles(files, planModel, function (file) {
											bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete', fileObject.name));
											window.utils.stopSpinner('importEcoPackage');
										},alternativeId,selectedMapList,packId,selectedCapLibList);
									}
                            	});
								
							});
						} else if (planModel && type === 'VRM XML') {
							window.utils.startSpinner('importVRM', "Importing VRM Library ... ");
							ValueDeliveryModel.unzipVRMFiles(fileObject, function (files) {
								ValueDeliveryModel.createVRMFromFiles(files, function () {
									bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete', 'Capability Library Package'));
									window.utils.stopSpinner('importVRM');
								}, planModel);
							});
						}else if(type == ""){
						    var message  = '"'+fileObject.name+'" is not a valid file';
						    bootbox.alert(''+message);
						}
						else{
						    var fileName = (fileObject.name).replace('.vpk', '');
							if(!planModel && type !== 'Plan'){
								var message  = '"'+fileObject.name+'" does not contain a Plan';
								bootbox.alert(''+message);
							}else if(type === 'Plan' && planModel) {
								var message  = '"'+fileObject.name+'" contains a Plan. You cannot import a Plan into an existing Plan';
								bootbox.alert(''+message);
							}
						}
					}else {
					    var fileName = (fileObject.name).replace('.vpk', '');
						if(!planModel && type !== 'Plan'){
							var message  = '"'+fileObject.name+'" does not contain a Plan';
							bootbox.alert(''+message);
						}else if(type === 'Plan' && planModel) {
							var message  = '"'+fileObject.name+'" contains a Plan. You cannot import a Plan into an existing Plan';
							bootbox.alert(''+message);
						}
						else {
						    var message  = '"'+fileObject.name+'" is not a valid file';
						    bootbox.alert(''+message);
						}
					}
				}
		}
        /*static loadCapData(capCallback){
            var packages =[];
            var planId = DataManager.getDataManager().get('currentPlan').id;
            var commonAltId = planId.substr(planId.lastIndexOf('@')) + "-Common@";
            DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
               if (result && result.length > 0) {
                   for(var i=0;i<result.length;i++){
                        if (result.models[i].get('id').indexOf("Common") > -1) {
                            if(result.models[i].get('name') != "Common Package" && result.at(i).get('id').includes('-Common@')){
                                 packages.push(result.models[i]);
                            }
                        }
                   };
                   async.eachSeries(packages,function(pack,callback){
                       var repId = DataManager.getDataManager().getRepositoryId(pack.id);
                       var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                       DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'appbo/vdml/ValueDeliveryModel', pack.get('version'),pack.get('id'), 'appbo/vdml/ValueDeliveryModel', vdmStore, {
                            success: function (model) {
                                callback();	
                            },
                       });
                   },function(err){
                      capCallback(packages);
                   })
               }
            });
        }*/

        
        static showSelectionList(planModel, files, selectionCallback){
            var phases = planModel.get('phase').length;
            var htmlContent ="";
            var capLibPackId = null;
            var planId = planModel.id.substr(planModel.id.lastIndexOf('@') + 1)
            var commonAltId = "@" + planId + "-Common@";
            var existCapPackId;
            
            if (phases >= 1) {
                htmlContent += "<b>Do you want to import Package in ? </b><br/>"
                htmlContent += "<select class=\"form-control\" id=\"selectValueStreamPhase\">";
                htmlContent = htmlContent + "<option>Select Phase ...</option>";
                for(var i=0;i<phases;i++){
                    htmlContent = htmlContent + "<option phaseId='" + planModel.get('phase').at(i).attributes.id + "'>" +  planModel.get('phase').at(i).attributes.name + "</option>";
                }
                htmlContent = htmlContent + "</select><p/>"; 
                
                htmlContent = htmlContent + "<select class=\"form-control\" id=\"selectAlternative\">";
                htmlContent = htmlContent + "<option>Select Alternative ...</option>";
                htmlContent = htmlContent + "</select><p/>";
                htmlContent = htmlContent + "<div style='font-size: 14px;color: orange;' id='duplicate'></div>";
                
                htmlContent += "<b>List of Diagram(s)<span style= 'padding-left:58%'></span></b><br/>";
                htmlContent +=  "<table class='table table-bordered' style = 'width:450px;margin-left:50px'>";
                htmlContent +=  "<thead><tr><th><input type = 'checkbox' checked id = 'checkAllVS'></th><th>Diagram(s)</th><th>Type</th></tr></thead>";
                htmlContent +=  "<tbody>";
                var ecomapPackid = null;
                var packageName = null;
                for (var i = 0; i < files.length; i++) {
                    if(files[i].type == "ecomap_EcoMap"){
                        packageName = files[i].name;
                        ecomapPackid = files[i].id;
                        var diagrams = files[i].diagrams;
                        for (var j = 0; j < diagrams.length; j++) {
                            var diagram = diagrams[j]
                            htmlContent = htmlContent + "<tr><td><span style = 'padding-left:5px'><input type='checkbox' class = 'listOfValuestreams'  value='" + diagram.id + "' style=\"cursor: pointer;\" checked /><span></td><td>" + diagram.name + "</td><td>" + window.utils.getMapName(diagram.type) + "</td></tr>";
                            //htmlContent +=  "<input type='checkbox' class = 'listOfValuestreams' value = \"" + diagram.id + "\">" + " " + diagram.name +'</br>';
                        }
                    }
                }
                htmlContent +=  "</tbody></table>"
                
                var capHTMLContent = false;
                async.eachSeries(files, function(file, fileCallBack){
                    if(file.type === "vdml_ValueDeliveryModel"){
                        if(!capHTMLContent){
                            htmlContent += "<b>List of Capability Package(s)<span style= 'padding-left:58%'></span></b><br/>";
                            htmlContent +=  "<table class='table table-bordered' style = 'width:450px;margin-left:50px'>";
                            htmlContent +=  "<thead><tr><th><input type = 'checkbox' checked id='checkAllCapLibs'></th><th>Package(s)</th><th>type</th></tr></thead>";
                            htmlContent +=  "<tbody>";
                            capHTMLContent = true;
                        }
                        capLibPackId = file.id.substr(file.id.lastIndexOf('@') + 1);
                        capLibPackId = commonAltId + capLibPackId;
                        var altRDFModel = DataManager.getDataManager().getRDFModel(commonAltId);
                        if(altRDFModel) {
                            altRDFModel.getTypeOfSubject(capLibPackId,function(isExist){
                                var addDisable = isExist ? "disabled" : "";
                                existCapPackId = isExist ? capLibPackId : "";
                                htmlContent = htmlContent + "<tr><td><span style = 'padding-left:5px'><input type='checkbox' class = 'listOfCapLibs'  value='" + file.id + "' style=\"cursor: pointer;\" checked / "+addDisable+"><span></td><td>" + file.name + "</td><td>" + window.utils.getMapName(file.type) + "</td></tr>";     
                                fileCallBack();
                            })
                        }else{
                        fileCallBack();
                        }
                    }else{
                        fileCallBack();
                    }
                },function(err){	
                    htmlContent +=  "</tbody></table>";
                });

                var dialog = bootbox.dialog({
                    title: "<div>&nbsp;&nbsp;Select following to import...</div>",
                    message: htmlContent,
                    buttons: {
                        main: {
                        label: "Close",
                        className: "btn btn-default",
                        },
                        success: {
                            label: "Complete",
                            className: "btn btn-complete complete-btn disabled",
                            callback: function () {
                                var alternativeId = $('#selectAlternative option:selected').attr('altId');
                                var pacId = null;
                                if(alternativeId !== undefined) {
                                    var selectedMapList =[];
                                    window.utils.startSpinner('importEcoPackage', "Importing EcoMap Package ... ");
                                    var pacId = null;
                                    var selectedMapList =[];var selectedCapLibList =[];
                                    for(var list =0;list<$('.listOfCapLibs').length;list++){
                                        if($('.listOfCapLibs')[list].checked && !$('.listOfCapLibs')[list].disabled){
                                            selectedCapLibList.push($('.listOfCapLibs')[list].value)
                                        }
                                    }
                                    for(var list =0;list<$('.listOfValuestreams').length;list++){
                                    if($('.listOfValuestreams')[list].checked && !$('.listOfValuestreams')[list].disabled){
                                        selectedMapList.push($('.listOfValuestreams')[list].value)
                                    }
                                    }
                                    pacId = checkPackExists(alternativeId);
                                    selectionCallback(true,alternativeId,selectedMapList,pacId,selectedCapLibList,existCapPackId);
                                }
                            }
                        }
                    }
                });
                dialog.attr("id", "bootboxForVS_id");
                $('#bootboxForVS_id').find('.bootbox-body').css('height',window.innerHeight/2+'px');
                $('#bootboxForVS_id').find('.bootbox-body').css('overflow-y','scroll');
                $('input[id="checkAllCapLibs"]').change(function(event,checked) {
                    var vsList = $('.listOfCapLibs');
                    for(var i=0;i<vsList.length;i++){
                        if(!vsList[i].disabled) {
                            vsList[i].checked = event.currentTarget.checked;
                        }
                    }
                })
            }else {
                selectionCallback(false);
            }
            
            function checkPackExists(altId){
                var selectedPhase = getPhaseObj();
                var packexists = null;
                if(selectedPhase){
                    var alts = selectedPhase.get('phaseAlternative');
                    for (var j = 0; j < alts.length; j++) {
                        if(altId == alts.at(j).id){
                            var designparts = alts.at(j).get('phaseDesignPart');
                            for(var k=0; k < designparts.length; k++){
                                if(designparts.at(k).get('beepType') == "ecomap_EcoMap" && window.utils.getSuffix(designparts.at(k).get('beepReference')) == window.utils.getSuffix(ecomapPackid)){
                                    packexists = designparts.at(k).get('beepReference');
                                    break;
                                }
                            }
                        }
                    }
                }
                return packexists;
            }
            function getPhaseObj(){
                var phaseId = $( "#selectValueStreamPhase option:selected" ).attr('phaseId');
                var phases = planModel.get('phase');
                var selectedPhase = null;
                for(var i=0;i<phases.length;i++){
                    if(phases.at(i).id === phaseId){
                        selectedPhase = phases.at(i);
                        break;
                    }
                }
                return selectedPhase;
            }
                        
            function fillAlternatives(){
                var selectedPhase = getPhaseObj();
                if(selectedPhase){
                    $('#selectAlternative').empty();
                    $('#selectAlternative').append("<option selected='true'>Select Alternative ...</option>");	           
                    var alts = selectedPhase.get('phaseAlternative');
                    alts.each(function(alt){
                        $('#selectAlternative').append("<option altId='"+ alt.id+ "'>"+alt.get('name')+ "</option>");
                    });
                }	            	
            }
            $('input[id="checkAllVS"]').change(function(event,checked) {
                var vsList = $('.listOfValuestreams');
                for(var i=0;i<vsList.length;i++){
                    if(!vsList[i].disabled) {
                        vsList[i].checked = event.currentTarget.checked;
                    }
                }
            })
            $('#selectValueStreamPhase').change(function(){
                fillAlternatives();
                if( $(this).children('option:first-child').is(':selected'))
                {
                    $('#selectAlternative').empty();
                    $('#selectAlternative').append("<option selected='true'>Select Alternative ...</option>");
                }
                $(".complete-btn").prop('disabled', true);
            });
            $('#selectAlternative').change(function(){
                var altId = $("#selectAlternative option:selected" ).attr('altid');
                if(altId  != undefined) {
                    var pacId = checkPackExists(altId);
                    $("#duplicate").html('');
                    if(pacId){
                        var altId = DataManager.getDataManager().getRepositoryId(pacId);
                        var altRDFModel = DataManager.getDataManager().getRDFModel(altId);
                        if(altRDFModel) {
                            altRDFModel.getDiagramsfromPackage(pacId,"ecomap_EcoMap-diagrams",function(res){
                                if(res){
                                    for(var i=0;i<res.length;i++){
                                        var resSuffix = window.utils.getSuffix(res[i].id);
                                        for(var list =0;list<$('.listOfValuestreams').length;list++){
                                        if(window.utils.getSuffix($('.listOfValuestreams')[list].value) == resSuffix){
                                            $('.listOfValuestreams')[list].checked = true;
                                            $('.listOfValuestreams')[list].disabled = true;
                                        }
                                        }
                                    }
                                }
                            })
                        }
                        $("#duplicate").html('*Package already exists in the selected Alternative');
                    }
                    $(".complete-btn").prop('disabled', false);
                    $(".complete-btn").removeClass("disabled");
                } else {
                    $(".complete-btn").prop('disabled', true);
                    $(".complete-btn").addClass("disabled");
                }
            });
        }
        static importFile(type, reader, fileObject, planModel, callback) {
            var self=this;
            var dm = DataManager.getDataManager();
            if (type == 'xlsx') {
                reader.onload = function (ev) {
                    import('xlsx').then((XLSX) => {
                        function libraryDetails(sheet) {
                            var title = "";
                            //var rgsFile;
                            var range = XLSX.utils.decode_range(sheet['!ref']);
                            for (var colNum = range.s.c; colNum <= range.e.c; colNum++) {
                                var nextCol = sheet[XLSX.utils.encode_cell({ r: 0, c: colNum })];
                                if (nextCol != null && (nextCol.h == "VALUE TYPE" || nextCol.h == "RUBRIC TYPE")) {
                                    bootbox.alert(DataManager.getDataManager().get('localeManager').get('ValueLibImportDenied'));
                                    return false;
                                }
                            }
                            var nextCell;
                            for (var rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                                nextCell = sheet[XLSX.utils.encode_cell({ r: rowNum, c: 0 })];
                                if (nextCell !== undefined) {
                                    nextCell = nextCell.v?nextCell.v:nextCell.h;
                                }
                                if (nextCell === 'ABOUT THE BUSINESS ARCHITECTURE CONTENTS HEREIN' || nextCell == "Business Domain") {
                                    title = sheet[XLSX.utils.encode_cell({ r: rowNum - 1, c: 0 })];
                                    break;
                                }

                            }
                            /*for (var colNum = range.s.c; colNum <= range.e.c; colNum++) {
                                    nextCell = sheet[XLSX.utils.encode_cell({ r: 0, c: colNum })];
                                    if (nextCell !== undefined) {
                                        nextCell = nextCell.h;
                                    }
                                    if (nextCell && nextCell.indexOf("RGS") >= 0) {
                                        rgsFile = nextCell;
                                    }
                                }
                                if (rgsFile) {
                                    return rgsFile;
                                }*/
                            if(!title){
                                title = sheet[XLSX.utils.encode_cell({ r: 1, c: 0})];
                            }
                            return title;
                        }
                        var bookContent = reader.result;
                        //var bookContent = new Uint8Array(bookContent);
                        var data = new Uint8Array(reader.result);
                        var wb = XLSX.read(data, { type: 'array' });
                        var CoverSheet = wb.SheetNames[0];
                        var sheet = wb.Sheets[CoverSheet];
                        var operationType = "create";
                        var fileName = libraryDetails(sheet);
                        if(fileName == false){
                            operationType = "invalid";
                            return;
                        }

                        var packName = fileName ? fileName.v : "";
                        if(!packName && fileName){
                            packName = fileName.h;
                        }
                        if(packName === "Business Domain"){
                            packName = 'Panorama360';
                            packName = packName + " Functional Definition"; 
                        }
                        var isRgs;
                        /*if(fileName && !fileName.h && fileName.indexOf('') === 0){
                            isRgs = fileName.indexOf("RGS") >= 0;
                        }*/
                        
                        var message = 'Are you sure you want to import ' + packName;
                        if (!planModel) {
                            var message = '"' + fileObject.name + '" does not contain a Plan';
                            bootbox.alert('' + message);
                            callback(false, type, null, null);
                        } else {
                            DataManager.getDataManager().set('currentPlan', planModel);
                            window.utils.startSpinner('loadPlan', "Load Plan Data...");
                            function waitForPlanLoad(){
                                setTimeout(function(){
                                    if(window.loadedPackages[planModel.get('id')]){
                                        window.utils.stopSpinner('loadPlan');
                                        var packagesList = [];
                                        var beepPacks = planModel.getPlanPackageList(["vdml_ValueDeliveryModel"],true);
                                        for(var i=0;i<beepPacks.length; i++){
                                            if (beepPacks[i].beepReference.indexOf("CommonPackage") == -1 && beepPacks[i].beepReference.indexOf("Common") > -1) {
                                                packagesList.push(beepPacks[i]);
                                            }
                                        }
                                        if(packagesList.length > 0){
                                            var htmlContent = "Select Option :<span style='padding-left:10px'><select id = 'selectedPackageId'>";
                                            //htmlContent += "</br><span>Existing Capability Libraries</span></br><div style='border-top: 1px solid #CBC2C2;'>";
                                        
                                            for(var a=0;a<packagesList.length;a++){
                                                var capPackModel = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({id:packagesList[a].beepReference});
                                                if(capPackModel){
                                                    var capLibs = capPackModel.get('capabilityLibrary');
                                                    for(var l=0;l<capLibs.length;l++){
                                                        htmlContent += "<option name='pacakgeName' value = \"" + capLibs.at(l).get('id') + "\">"+capLibs.at(l).get('name')+ '</option>';
                                                    }
                                                }
                                            }
                                            htmlContent += '<option name = "pacakgeName" value = "0">New</option></select></span><span id = "libraryDetailsInfo" class="glyphicon glyphicon-info-sign" style="font-size: small; color:orange;padding-left:10px"></span>'
                                        
                                            operationType = "update"
                                            var dialog = bootbox.dialog({
                                                title : "Do you want to import or update?",
                                                message : htmlContent,
                                                buttons: {
                                                    cancel: {
                                                        label: "Cancel"
                                                    },
                                                    confirm: {
                                                        label: "Complete",
                                                        callback: function(result){
                                                            var radioValue = $('select[id="selectedPackageId"]').val();
                                                            if(radioValue === "0"){
                                                                operationType = "create";
                                                                callback(true, type, wb, operationType, isRgs, packName + " Package");
                                                            }else{
                                                                callback(true, type, wb, operationType, isRgs, radioValue);
                                                            }
                                                        }
                                                    }
                                                }
                                            })
                                            dialog.attr('id',"bootbox_id");
    
                                            $('select[id="selectedPackageId"]').change(function(){
                                                $('#libraryDetailsInfo').show();
                                                if($(this).val() && $(this).val() == 0){
                                                    $('#libraryDetailsInfo').hide();
                                                }
                                            })
                                            $('#libraryDetailsInfo').click(function(){
                                                var model = Backbone.Relational.store.getObjectByName('vdml.CapabilityLibrary').find({id:$('#selectedPackageId').val()})
                                                if(!model){
                                                    bootbox.alert("No Info");
                                                    return;
                                                }
                                                $('#bootbox_id').css('z-index','1350');
                                                var options = {};
                                                var id = window.utils.htmlEscape(window.guidGenerator());
                                                options.mappedModal = model;
                                                options.width = '800px';
                                                var EcoMapDiagramMixin = Backbone.Relational.store.getObjectByName("vdml.EcoMapDiagramMixin");
                                                window.getAndCreateModalDialog(model, id, EcoMapDiagramMixin, model, "TargetDetails", function (refresh) {
                                                }, options);
                                                $('#modal' + id).css('z-index','9999')
                                            })
                                        }
                                        if(operationType === 'create'){
                                            bootbox.confirm(message, function (result) {
                                                if (result) {
                                                    callback(true, type, wb, operationType, isRgs, packName + " Package");
                                                }
                                                else {
                                                    callback(false, type, wb, operationType, isRgs, packName + " Package");
                                                }
                                            });
                                        }
                                    } else {
                                        waitForPlanLoad();
                                    }
                                },100);
                            }
                            waitForPlanLoad();                                    
                        }

                    })
                }
                if (!DataManager.getDataManager().isEnterpriseEdition() && dm.get('currentLegalEntity').name != "VDMbee") {
                    window.utils.limitExceeded();
                } else {
                    reader.readAsArrayBuffer(fileObject);
                }
            }
            else if (type == 'vpk' || type == 'zip') {
                var allowedTypes = { 'transformation_Plan': 'Plan', 'beeppackage_BeepModule': 'Business Canvas Type', 'concept_BeepVocabulary': 'Vocabulary Library Package', 'vdml_ValueDeliveryModel': 'Capability Library Package','ecomap_EcoMap':'EcoMap Package', 'vdml_VRM': 'VRM XML' };
                reader.onload = function (ev) {
                    var vpkContent = reader.result;
                    var newplanZip = new jszip();
                    newplanZip.loadAsync(vpkContent).then(function (vocZip) {
                        var obj = vocZip.files;
                        var manifestFile = obj['manifest.json'];
                        if (manifestFile) {
                            manifestFile.async("string").then(function (fileText) {
                                var manifestJson = JSON.parse(fileText);
                                var type = allowedTypes[manifestJson.type];
                                if (planModel && type && type !== 'Plan') {
                                    if (type === 'Vocabulary Library Package') {
                                        var message = '"' + fileObject.name + '" contains a ' + type + '. Are you sure you want to continue ?';
                                        bootbox.confirm(message, function (result) {
                                            if (result) {
                                                callback(true, type, fileObject);
                                            }
                                            else {
                                                callback(false, type, fileObject);
                                            }
                                        });
                                    } else if (type === 'Capability Library Package') {
                                        //checkPackageExists(planModel, manifestJson, type, fileObject,function(operationType){
	                                       // if (operationType == "create") {
	                                            message = '"' + fileObject.name + '" contains Library Package' + '"' + manifestJson.name + '". Are you sure you want to continue ?';
	                                       /* }
	                                        else {
	                                            message = 'Do you want to Update ' + manifestJson.name;
	                                        }*/
	                                        bootbox.confirm(message, function (result) {
	                                            if (result) {
	                                                callback(true, type, fileObject, "create");
	                                            }
	                                        });
                                        //});
                                    } else if (type === 'EcoMap Package') {
	                                    message = '"' + fileObject.name + '" contains the Business Maps Package for the ' + manifestJson.name + '. Are you sure you want to continue ?';
	                                    bootbox.confirm(message, function (result) {
		                                    if (result) {
		                                        callback(true, type, fileObject, "create");
		                                    }
		                                });    
                                    }
                                    else {
                                        var message = '"' + fileObject.name + '" contains the ' + type + ' for the ' + manifestJson.name + '. Are you sure you want to continue ?';
                                        bootbox.confirm(message, function (result) {
                                            if (result) {
                                                callback(true, type, fileObject);
                                            }
                                            else {

                                                callback(false, type, fileObject);
                                            }
                                        });
                                    }
                                } else {
                                    if (manifestJson.type === "transformation_Plan") {
                                        type = 'Plan';
                                    }
                                    var valid = planModel ? false : true;
                                    if (manifestJson.type === 'concept_BeepVocabulary' || manifestJson.type === 'beeppackage_BeepModule' || manifestJson.type === "vdml_ValueDeliveryModel") {
                                        valid = false;
                                    }
                                    callback(valid, type, fileObject);
                                }
                            });
                        } else {
                            var valid = planModel ? false : true;
                            callback(valid, '', fileObject);
                        }
                    });
                };
                reader.readAsBinaryString(fileObject);
            }
            else {
                var fileType = type;
                bootbox.alert(type + " type files are not allowed to Create/Edit Plan");
            }
        }
        /*static importRGSLibrary(valid, fileObject, operation, planModal, fileName) { 
            if (valid) {
                window.utils.startSpinner('importXlsx', "Importing xlsx ... ");
                ValueDeliveryModel.createRGSFromXlsxFile(fileObject, operation, fileName, function (library) {
                    bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete', 'xlsx'));
                    window.utils.stopSpinner('importXlsx');
                    DataManager.getDataManager().set('currentPlan', null);
                }, planModal);
            } else {
                DataManager.getDataManager().set('currentPlan', null);
            }
        }*/
        
        createValueDetails(valueModal, oldVpc, satisfaction, weight, recipientValue){
            var self = this;
            this.createMeasurementCharacteristics(valueModal, oldVpc, satisfaction, weight, recipientValue);
        }

        loadValueDocument(docId, parentId, callback) {
            var document;
            document = MeasuredCharacteristic.find({ id: docId });
            if (document) { //plan value will always be in catch
                callback(document);
            } else {
                var altId = DataManager.getDataManager().getRepositoryId(docId);
                var vdmStore = DataManager.getDataManager().getVDMStore(altId);
                var version = DataManager.getDataManager().get('currentVDMVersion');
                DataManager.getDataManager().fetchDocumentFromPackage(parentId, "appbo/vdml/ValueDeliveryModel", version, docId, "appbo/vdml/MeasuredCharacteristic", vdmStore, {
                    success: function (mc) {
                        callback(mc);
                    },
                    error: function (error) {
                        console.log('Unable to load Value document');
                        callback();
                    }
                });
            }
        }

        getValueDocumentWithNamePath(namePath, callback) {
            var self = this;
            var names = namePath.split('>');
            if (names.length === 4) {//planValue
                var planCriterionSet = self.get('planCriterionSet');
                if(planCriterionSet && names[1] == planCriterionSet.get('name')){
                    var planComponents = planCriterionSet ? planCriterionSet.get('component') : null;
                    var valModel;
                    var pModel = planComponents.findWhere({'name':names[2]});
                    if(pModel){
                        var mclist = ["valueMeasurement", "satisfactionMeasurement", "weightMeasurement", "recipientMeasurement"];
                        for(var i=0; i<mclist.length; i++){
                            var mc = pModel.get(mclist[i]);
                            if(mc && mc.get('name') == names[3]){
                                valModel = mc;
                                break;
                            }
                        }
                    }
                    callback(valModel);
                } else {
                    self.getDocumentWithNamePath(namePath, 'vdml_MeasuredCharacteristic', function (result) {
                        if (result[0]) {
                            self.loadValueDocument(result[0].doc, result[0].parent, function (valModel) {
                                callback(valModel);
                            });
                        } else {
                            callback();
                        }
                    });
                }

            } else //BMValue
                if (names.length === 5) {
                    //bm Package>sdf>My Business Role>vf>v1>v1 Value MeasurementMC,1>Base Alternative,,23.0000,rs,Input
                    self.getDocumentWithNamePath(namePath, 'vdml_MeasuredCharacteristic', function (result) {
                        if (result[0]) {
                            self.loadValueDocument(result[0].doc, result[0].parent, function (valModel) {
                                callback(valModel);
                            });

                        } else {
                            callback();
                        }
                    });
                } else {//activityValue
                    //bm Package>sdf>a1>a1 port>a11>a11 Value MeasurementMC,1>Base Alternative,,23.0000,rs,Input
                    self.getDocumentWithNamePath(namePath, 'vdml_MeasuredCharacteristic', function (result) {
                        if (result[0]) {
                            self.loadValueDocument(result[0].doc, result[0].parent, function (valModel) {
                                callback(valModel);
                            });
                        } else {
                            callback();
                        }
                    })
                    //callback();
                }
        }
        checkScenarioLimit(callback) {
            var self = this;
            var obsModels = self.get('planObservationContainer') ? self.get('planObservationContainer').get('observations') : [];
            var count = 0;
            //var firstPhase = _.filter(self.get('phase').models,function(phase){return phase.get('previousPhase') === null;});
            //var obsModels = firstPhase[0].get('master').get('phaseObjectiveSet').get('objectiveObservation').models;

            var json = {};
            for (var i = 0; i < obsModels.length; i++) {
                if (obsModels.at(i).get('whenObserved') && isNaN(obsModels.at(i).get('whenObserved')) && !json[obsModels.at(i).get('whenObserved')]) {
                    json[obsModels.at(i).get('whenObserved')] = {};
                    count++;
                }
            }

            if (count >= 10) {
                callback(true);
            } else {
                callback(false);
            }
        };
        importCsvData(csvObj, timeStamp, startPoint, finalCallback) {
            var self = this;
            var data = csvObj;
            var errorMessage = '';
            var message;
            var valueNameIndex = "ValueName";
            var altIndex = "Phase>Alternative";

            var obsIndex = [];
            var timeStampColumnExists = false;
            for (var key in csvObj[0]) {
                if (key.indexOf("Scenario-") != -1) {
                    obsIndex.push(key);
                }
                if (key.indexOf("Time Stamp") != -1) {
                    timeStampColumnExists = true;
                }
            }
            //var obsIndex = "Scenario";
            var valueIndex = "Value";
            var unitIndex = "Unit";
            var timeStampIndex;
            var altList = [];
            var obsList = [];
            var earliestAlt;
            var collectiveMeasurements = [];
            var allCalcMeasurements = [];
            var values = [];
            var observationData = {};
            var codeContainerList = [];

            for (var i = 0; i < data.length; i++) {
                if (!data[i][valueNameIndex] || data[i][valueNameIndex] === "") {
                    continue;
                }
                values.push({ namePath: data[i][valueNameIndex] });
            }
            values = _.uniq(values, false, function (val) { return val.namePath });
            function getPlanValueMeasurement(alt, value, whenObserved) {
                var observations = alt.get('phaseObjectiveSet').get('objectiveObservation');
                for (var i = 0; i < observations.length; i++) {
                    var obs = observations.at(i);
                    if (obs.get('whenObserved') !== whenObserved) {
                        continue;
                    }
                    var obsMeasures = obs.get('observedMeasures');
                    for (var j = 0; j < obsMeasures.length; j++) {
                        var obsMes = obsMeasures.at(j);
                        var mest = obsMes.get('measurements').at(0);
                        if (mest.get('measuredCharacteristic') === value) {// as value is based on plan value
                            return mest;
                        }
                    }
                }
            }
            function getAltValueMeasurement(alt, value, whenObserved) {
                //var altRefs = alt.getAlternativesThatICanReffer();  
                var packagesSuffixId = value.getNestedParent().id.substr(value.getNestedParent().id.lastIndexOf('@') + 1);
                /* for(var a= 0; a < altRefs.length; a++){
                     var possibleAlt = altRefs[a];
                     if (observation) {
                         break;
                     }*/
                var altPhaseDesignPart = alt.get('phaseDesignPart').models;
                for (var z = 0; z < altPhaseDesignPart.length; z++) {
                    if (altPhaseDesignPart[z].get('beepType') === 'vdml_ValueDeliveryModel' && altPhaseDesignPart[z].get('beepReference').substr(altPhaseDesignPart[z].get('beepReference').lastIndexOf('@') + 1) === packagesSuffixId) {
                        var packId = altPhaseDesignPart[z].get('beepReference');
                        break;
                    }
                }
                /*if(packId){
                    break;
                }*/
                //}
                var foundScenarioMest
                var packagebbModel = window.utils.getElementModel(packId,['vdml.ValueDeliveryModel']);
                if (packagebbModel) {
                    /*var dataManager = DataManager.getDataManager();
                    var repId = dataManager.getRepositoryId(packId);
                    var vdmStore = dataManager.getVDMStore(repId);
                    dataManager.fetchDocumentFromPackage(packId, "appbo/vdml/ValueDeliveryModel", dataManager.get('currentVDMVersion'), packId, "appbo/vdml/ValueDeliveryModel", vdmStore, {
                        success: function (packagebbModel) {*/
                            var observation;
                            var mainScenario = alt.getMainScenario(packagebbModel);
                            var scenarioObs = mainScenario.get('contextObservation').models;
                            for (var i = 0; i < scenarioObs.length; i++) {
                                if (scenarioObs[i].get('whenObserved') === whenObserved) {
                                    observation = scenarioObs[i];
                                    break;
                                }
                            };
                            if (observation) {
                                var obsModels = observation.get('observedMeasures').models;
                                for (var i = 0; i < obsModels.length; i++) {
                                    var obMeasure = obsModels[i];
                                    if (obMeasure.get('measurements').at(0).get('measuredCharacteristic').get('name') === value.get('name')) {
                                        var mest = obMeasure.get('measurements').at(0);
                                        var mc = mest.get('measuredCharacteristic');
                                        var mcSuffixId = mc.get('id').substr(mc.get('id').lastIndexOf('@') + 1);
                                        var valueSuffixId = value.get('id').substr(value.get('id').lastIndexOf('@') + 1);
                                        var context = mest.get('observedMeasure').get('observation').getContext();
                                        while (context.get('parentContext')) {
                                            context = context.get('parentContext');
                                        }
                                        var altScenarios = alt.get('designScenario');
                                        for (var j = 0; j < altScenarios.length; j++) {
                                            if (altScenarios.at(j).get('scenario') === context.id && mcSuffixId === valueSuffixId) {
                                                foundScenarioMest = mest;
                                                break;
                                            }
                                        }
                                    }
                                    if (foundScenarioMest) {
                                        break;
                                    }
                                }
                                return foundScenarioMest;
                            } else {
                                return foundScenarioMest;
                            }
                    /*    },
                        error: function (error) {
                            console.log(error);
                            callback();
                        }
                    });*/
                } else {
                    return foundScenarioMest;
                }
            };

            function updateCodeContainers(del, createObsList, updatedCode) {
                var obsKeys = Object.getOwnPropertyNames(observationData);
                function updateData(oldData, newvalues) {
                    var newKeys = Object.getOwnPropertyNames(newvalues);
                    _.each(newKeys, function (key) {
                        oldData[key] = newvalues[key];
                    })
                }
                async.eachSeries(obsKeys, function (key, handledObservation) {
                    var obsData = observationData[key];
                    var obs = Observation.find({ id: key });
                    var dataRef = obs.get('dataReference');
                    var date = new Date();
                    if (dataRef) {
                        CodeContainer.getBlobInstanceWithId(dataRef, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
                            if (codeContainer) {
                                var dataStr = codeContainer.get('code');
                                var data = dataStr ? JSON.parse(dataStr) : null;
                                if (data) {
                                    updateData(data, obsData);
                                    codeContainer.set({'code': JSON.stringify(data),'lastModified': date.getTime()});
                                    codeContainerList.push(codeContainer.id);
                                }
                                handledObservation();
                            } else {
                                console.log('Unable to load selected Package');
                                handledObservation();
                            }
                        });
                    } else {
                        var plan = DataManager.getDataManager().get('currentPlan');
                        var codeContainer = CodeContainer.getBlobInstance(obs.get('name'), obs.get('name'), plan);
                        obs.getNestedParent().get('dependentPackage').add(codeContainer);
                        var data = {};
                        updateData(data, obsData);
                        codeContainer.set({'code': JSON.stringify(data),'lastModified': date.getTime()});
                        codeContainerList.push(codeContainer.id);
                        obs.set('dataReference', codeContainer.id);
                        handledObservation();
                    }
                }, function () {
                    if (del) {
                        //var plan = DataManager.getDataManager().get('currentPlan');
                        //plan.getAllDocumentsCollectionOfType(plan, "smm_Observation", function (results) {
                        var dataManager = DataManager.getDataManager();
                        var emptyCalculateValuesCache = true;
                        if (dataManager.calculateValuesCache && Object.keys(dataManager.calculateValuesCache).length > 0) {
                            emptyCalculateValuesCache = false;
                        }
                        var createObsListLen = createObsList.length;
                        dataManager.set(dataManager.SUPPRESS_CHANGE_LOGGING, true);
                        while (createObsListLen--) {
                            var observation = createObsList[createObsListLen];
                            if (observation && observation.get('dataReference')) {
                                var observedMeasures = observation.get('observedMeasures') ? observation.get('observedMeasures') : [];
                                var observedMeasuresLength = observedMeasures.length;
                                while (observedMeasuresLength--) {
                                    observedMeasures.at(observedMeasuresLength).destroy();
                                }
                            }
                        }
                        if (emptyCalculateValuesCache) {
                            dataManager.calculateValuesCache = {};
                        }
                        dataManager.set(dataManager.SUPPRESS_CHANGE_LOGGING, false);
                        if (updatedCode) {
                            updatedCode(codeContainerList);
                        }
                        //}, null, null, null, "vbc:cmof_EObject-name", true, null, true);
                    } else {
                        if (updatedCode) {
                            updatedCode();
                        }
                    }
                });
            }
            asyncsimple.each(values, function (value, gotValueDetails) {
                var namePath = value['namePath'];
                /*if (namePath.indexOf('#Unit') > 0) {
                    gotValueDetails();
                    return;
                }*/
                self.getValueDocumentWithNamePath(namePath, function (valModel) {
                    value['document'] = valModel;
                    gotValueDetails();
                })
            }, function () {
                self.getAllDocumentsCollectionOfType(this, "smm_Observation",
                    function (observations) {
                        var observationsList = observations;
                        var totalCreateObsList = [];
                        var count = 0;
                        //var scenarioIndex = null;
                        //for (var i = 0; i < obsIndex.length; i++) {
                            //scenarioIndex = i;
                            function checkUniqueErrorMsg(message) {
                                if (errorMessage.indexOf(message) > -1) {
                                    var index = errorMessage.indexOf(message) + message.length + 2;
                                    var secondIndex = index;
                                    while (errorMessage[secondIndex + 1] !== ']') {
                                        secondIndex = secondIndex + 1;
                                    }
                                    count = parseInt(errorMessage.substring(index, secondIndex + 1)) + 1;
                                    errorMessage = errorMessage.substr(0, index) + count + errorMessage.substr(secondIndex + 1);
                                } else {
                                    errorMessage = errorMessage.concat(message).concat(' [1]');
                                    errorMessage = errorMessage.concat('<br>');
                                }
                            }
                            var obsName = obsIndex[0];
                            obsName = obsName.substring(obsName.indexOf('-') + 1); //get scenario name from text
                            if (!obsName || obsName == "") {
                                message = ' Scenario Name not specified';
                                checkUniqueErrorMsg(message);
                                //rowHandled();
                                return;
                            }
                            
                            asyncsimple.each(data, function (obsMesData, rowHandled) {
                                var phaseName = obsMesData[altIndex].substr(0, obsMesData[altIndex].indexOf('>'));
                                if (!phaseName || phaseName === "") {
                                    message = ' Phase name not specified';
                                    checkUniqueErrorMsg(message);
                                    rowHandled();
                                    return;
                                }
                                var phase = self.get('phase').findWhere({ name: phaseName });
                                if (!phase) {
                                    message = '<b>' + phaseName + '</b>' + ' Phase is not present in the Plan';
                                    checkUniqueErrorMsg(message);
                                    rowHandled();
                                    return;
                                }
                                var altName = obsMesData[altIndex].substr(obsMesData[altIndex].indexOf('>') + 1);
                                var alt = phase.get('phaseAlternative').findWhere({ name: altName });
                                
                                if (alt) {
                                    if(altList.findIndex(item => item.altId === alt.id) == -1) {
                                        altList.push({ altId: alt.id, phaseId: alt.get('phaseAlternativeOwner').id });
                                    }
                                } else {
                                    message = '<b>' + altName + '</b>' + ' Alt is not present in the Plan';
                                    checkUniqueErrorMsg(message);
                                    rowHandled();
                                    return;
                                }
                                var phaseObjSet = alt.get('phaseObjectiveSet');
                                //var obsName = obsIndex[scenarioIndex];

                                var obs;
                                var importTimeStamp = timeStamp;
                                if(timeStampColumnExists){
                                    var obsMesDataKeys = Object.getOwnPropertyNames(obsMesData);
                                    for (var i = 0; i < obsMesDataKeys.length; i++) {
                                        var exists = obsMesDataKeys[i].includes('Time Stamp');
                                        if (exists) {
                                            timeStampIndex = obsMesDataKeys[i];
                                            break;
                                        }
                                    }
                                    var dateFormat = timeStampIndex ? timeStampIndex.substr(timeStampIndex.indexOf('(') + 1, timeStampIndex.length - (timeStampIndex.indexOf('(') + 2)) : '';
                                    var formatedDate = dateFormat ? convertDate(dateFormat, obsMesData[timeStampIndex]) : "";
                                    importTimeStamp = !isNaN(Date.parse(formatedDate)) ? Date.parse(formatedDate) : timeStamp;
                                }

                                if (phaseObjSet) {
                                    var observation = phaseObjSet.get('objectiveObservation').findWhere({ name: obsName }); //obsName
                                    if (observation) {
                                        if (obsMesData[timeStampIndex] && obsMesData[timeStampIndex] !== '') {
                                            if (Date.parse(formatedDate) !== observation.get('whenObserved')) {
                                                message = ' Time Stamp input only allowed to update an existing actual Scenario';
                                                checkUniqueErrorMsg(message);
                                                rowHandled();
                                                return;
                                            } else if (timeStamp) {
                                                var cond = false;
                                            } else {
                                                cond = true;
                                            }
                                            //var cond = !(observation.get('whenObserved') === timeStamp || (!observation.get('whenObserved')?false:isNaN(new Date(observation.get('whenObserved')))));
                                        } else if (obsMesData[timeStampIndex] == null || obsMesData[timeStampIndex] === '') {
                                            if (isNaN(observation.get('whenObserved')) && timeStamp) {
                                                cond = true;
                                            } else {
                                                cond = !(observation.get('whenObserved') === timeStamp || (!observation.get('whenObserved') ? false : isNaN(new Date(observation.get('whenObserved')))));
                                            }
                                        } else if (!isNaN(Date.parse(formatedDate))) {
                                            cond = !(observation.get('whenObserved') === Date.parse(formatedDate));
                                        } else if (formatedDate === 'Invalid date' && observation.get('whenObserved') !== timeStamp) {
                                            cond = true;
                                        }
                                        if (cond) {
                                            message = '<b>' + obsName + '</b>' + ' cannot be created as Scenario Name is unique';//obsName
                                            checkUniqueErrorMsg(message);
                                            rowHandled();
                                            return;
                                        } else {
                                            obs = observation;
                                        }
                                    } else {
                                        if (!isNaN(Date.parse(formatedDate)) ? Date.parse(formatedDate) : '') {
                                            message = ' Time Stamp input only allowed to update an existing actual Scenario';
                                            checkUniqueErrorMsg(message);
                                            rowHandled();
                                            return;
                                        } else {
                                            obs = null;
                                        }
                                    }
                                    handleObservation(obs);
                                } else {
                                    var altRefs = alt.getAlternativesThatICanReffer();
                                    var foundObsId;
                                    var foundParentId;
                                    var vdmStore;
                                    async.eachSeries(altRefs, function (possibleAlt, altChecked) {
                                        if (foundObsId) {
                                            altChecked();
                                            return;
                                        }
                                        var rdfModel = DataManager.getDataManager().getRDFModel(possibleAlt.id);
                                        rdfModel.getObservationWithName(obsName, function (obsId, parentId) {//obsName
                                            if (obsId) {
                                                foundObsId = obsId;
                                                foundParentId = parentId;
                                            }
                                            altChecked();
                                        });
                                    }, function () {
                                        if (foundObsId) {
                                            var foundObsAltId = DataManager.getDataManager().getRepositoryId(foundObsId);
                                            var vdmStore = DataManager.getDataManager().getVDMStore(foundObsAltId);
                                            var version = DataManager.getDataManager().get('currentVDMVersion');
                                            DataManager.getDataManager().fetchDocumentFromPackage(foundParentId, "appbo/vdml/ValueDeliveryModel", version, foundObsId, "appbo/smm/Observation", vdmStore, {
                                                success: function (obs) {
                                                    var context = obs.getContext();
                                                    while (context.get('parentContext')) {
                                                        context = context.get('parentContext');
                                                    }
                                                    var altScenarios = alt.get('designScenario');
                                                    var foundScenarioObs = false;
                                                    for (var i = 0; i < altScenarios.length; i++) {
                                                        if (altScenarios.at(i).get('scenario') === context.id) {
                                                            foundScenarioObs = true;
                                                            break;
                                                        }
                                                    }
                                                    handleObservation(foundScenarioObs ? obs : null);
                                                },
                                                error: function (error) {
                                                    console.log('Unable to load Value document');
                                                    rowHandled();
                                                    return;
                                                }
                                            });
                                        } else {
                                            handleObservation();
                                        }
                                    });
                                }

                                function convertDate(dateFormat, dateString) {
                                    var momentObj = moment(dateString, dateFormat);
                                    var momentString = momentObj.format('MM/DD/YYYY,HH:mm:ss');
                                    return momentString;

                                }
                                function handleObservation(obs) {
                                    var mest;
                                    if (!obs) {
                                        //dateFormat = timeStampIndex ? timeStampIndex.substr(timeStampIndex.indexOf('(') + 1, timeStampIndex.length - (timeStampIndex.indexOf('(') + 2)) : '';
                                        /*if(!timeStampIndex){
                                            errorMessage = errorMessage.concat('<b>' + 'TimeStamp' + '</b>' +' column header is missing');
                                            errorMessage = errorMessage.concat('<br>');
                                            rowHandled();
                                        }  */
                                        //formatedDate = dateFormat ? convertDate(dateFormat, obsMesData[timeStampIndex]) : "";
                                        //importTimeStamp = !isNaN(Date.parse(formatedDate)) ? Date.parse(formatedDate) : timeStamp;
                                        var alreadyExists = phaseObjSet.get('objectiveObservation').findWhere({ whenObserved: importTimeStamp });
                                        if (alreadyExists && alreadyExists.get('name') !== obsName) {
                                            var message = 'Scenario ' + '<b>' + obsName + '</b>' + ' cannot be created with same Time Stamp ' + '(' + '<b>' + dateTimeStamp + '</b>' + ')' + ' as Scenario ' + '<b>' + alreadyExists.get('name') + '</b>';
                                            checkUniqueErrorMsg(message);
                                            rowHandled();
                                        } else if (formatedDate === 'Invalid date' && !importTimeStamp && obsMesData[timeStampIndex] !== '') {
                                            var dateTimeStamp = new Date(importTimeStamp).toLocaleString();
                                            message = '<b>' + dateFormat + '</b>' + 'and ' + '<b>' + obsMesData[obsMesDataKeys[4]] + '</b>' + ' are not a valid Date format and Date';
                                            checkUniqueErrorMsg(message);
                                            rowHandled();
                                        } else {
                                            self.createNewObservation(obsName, function (whenObserved, createObsList) {
                                                obsList.push(whenObserved);
                                                totalCreateObsList = totalCreateObsList.concat(createObsList);
                                                mest = handleMeasures(whenObserved);
                                                if (!isNaN(whenObserved)) {
                                                    updateCodeContainersForTimedObservations(obsName, createObsList, rowHandled);
                                                } else {
                                                    rowHandled();
                                                }
                                            }, timeStamp, observationsList);
                                        }
                                    } else if (!isNaN(obs.get('whenObserved')) && obs.get('observedMeasures').length === 0) {
                                        self.createNewObservation(obs.get('name'), function (whenObserved, createObsList) {
                                            mest = handleMeasures(whenObserved);
                                            totalCreateObsList = totalCreateObsList.concat(createObsList);
                                            rowHandled();
                                        }, obs.get('whenObserved'), observationsList);
                                    } else {
                                        mest = handleMeasures(obs.get('whenObserved'));
                                        rowHandled();
                                    }
                                }
                                function updateCodeContainersForTimedObservations(obsName, createObsList, callback) {
                                    //var plan = DataManager.getDataManager().get('currentPlan');
                                    //plan.getAllDocumentsCollectionOfType(plan, "smm_Observation", function (results) {
                                    var timedObservations = _.filter(createObsList, function (a) { return a.get('name') === obsName; });
                                    _.each(timedObservations, function (obs) {
                                        var observation = Observation.find({ id: obs.id });
                                        var observedMeasuresModels = observation ? observation.get('observedMeasures').models : [];
                                        _.each(observedMeasuresModels, function (obMeasure) {
                                            var mest = obMeasure.get('measurements').at('0');
                                            var value = mest.get('measuredCharacteristic');
                                            updateObservationMeasurementData(mest, value.id);
                                        });
                                    });
                                    updateCodeContainers(false, createObsList, callback);
                                    // }, null, null, null, "vbc:cmof_EObject-name", true, null, true);

                                }
                                function updateObservationMeasurementData(mest, valueId) {
                                    var date = new Date();
                                    var obs = mest.get('observedMeasure').get('observation');
                                    if (!isNaN(date.setTime(obs.get('whenObserved')))) {
                                        var cachedObsData = observationData[obs.id];
                                        if (!cachedObsData) {
                                            cachedObsData = {};
                                            observationData[obs.id] = cachedObsData;
                                        }
                                        cachedObsData[valueId] = mest.get('value') ? mest.get('value') : mest.get('symbol');
                                    }
                                }

                                function handleMeasures(whenObserved) {
                                    var valName = obsMesData[valueNameIndex];
                                    var mesValue = obsMesData['Scenario-' + obsName];//valueIndex
                                    var mesUnit = obsMesData[unitIndex];
                                    var mest;
                                    if (!mesValue) {
                                        return;
                                    }
                                    var valueElement = _.find(values, function (val) { return val.namePath === valName });
                                    var value = valueElement['document'];
                                    if (!value) {
                                        message = '<b>' + valName + '</b>' + ' Value name is not present in the Plan';
                                        checkUniqueErrorMsg(message);
                                        return;
                                    }
                                    if (!isNaN(whenObserved)) {
                                        DataManager.getDataManager().set(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING, true);
                                    }
                                    /*if (isNaN(mesValue)) {
                                        mesValue = mesValue.substr(0, mesValue.lastIndexOf("("));
                                    }*/
                                    if (value.id.indexOf(window.plansKey) >= 0) {
                                        mest = getPlanValueMeasurement(alt, value, whenObserved);
                                    }else {
                                        mest = getAltValueMeasurement(alt, value, whenObserved);
                                    }
                                    var cond = !isNaN(whenObserved) ? mest && mest.get('type') === 'smm_DirectMeasurement' : mest;
                                    if (cond) {
                                        if (isNaN(parseFloat(mesValue))) {
                                            var intervalArray = [];
                                            if (mest.get('observedMeasure').get('measure') && mest.get('observedMeasure').get('measure').get('interval')) {
                                                mest.get('observedMeasure').get('measure').get('interval').each(function (interval) {
                                                    intervalArray.push(interval.get('symbol') ? interval.get('symbol') : ' ');
                                                });
                                                if (intervalArray.indexOf(mesValue) >= 0) {
                                                    mest.set('symbol', mesValue);
                                                } else {
                                                    message = '<b>' + mesValue + '</b>' + ' is not present in the ' + '<b>' + mest.get('name') + '</b>' + ' Intervals';
                                                    checkUniqueErrorMsg(message);
                                                }
                                            }
                                        } else {
                                            if (isNaN(whenObserved) && mest.get('type') === 'smm_CollectiveMeasurement') {
                                                //mest.calculate();
                                                allCalcMeasurements.push(mest);
                                            } else {
                                                mest.set('value', mesValue);
                                                if(mest.get('type') === 'smm_DirectMeasurement'){
                                                    allCalcMeasurements.push(mest);
                                                } else {
                                                    mest.calculate();
                                                }
                                            }
                                            var unit = mest.get('observedMeasure').get('measure').get('unit');
                                            var previousUnit = unit ? unit.get('name') : 'Grade only';
                                            if (previousUnit !== mesUnit && previousUnit !== 'Grade only') {
                                                message = '<b>' + mesUnit + '</b>' + ' Unit is not valid for the ' + '<b>' + mest.get('name') + '</b>';
                                                checkUniqueErrorMsg(message);
                                            }
                                        }
                                        updateObservationMeasurementData(mest, mest.get('measuredCharacteristic').id);
                                    } else if (mest && mest.get('type') === 'smm_CollectiveMeasurement') {
                                        collectiveMeasurements.push({ mest: mest, whenObserved: whenObserved, altId: alt.id, valId: value.id, mesValue: mesValue, mesUnit: mesUnit });
                                    }
                                    DataManager.getDataManager().set(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING, false);
                                    return mest;
                                }
                            }, function () {
                                if (timeStamp != "" && !isNaN(timeStamp)) {
                                    DataManager.getDataManager().set(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING, true);
                                } else {
                                    _.each(allCalcMeasurements, function (mes) {
                                        mes.calculate();
                                    });
                                }
                                _.each(collectiveMeasurements, function (obsMesData) {

                                    function checkUniqueErrorMsg(message) {
                                        if (errorMessage.indexOf(message) > -1) {
                                            var index = errorMessage.indexOf(message) + message.length + 1;
                                            var count = parseInt(errorMessage[index]) + 1;
                                            errorMessage = errorMessage.substr(0, index) + count + errorMessage.substr(index + 1);
                                        } else {
                                            errorMessage = errorMessage.concat(message).concat(' [1]');
                                            errorMessage = errorMessage.concat('<br>');
                                        }
                                    }
                                    var date = new Date();
                                    var mesValue = obsMesData.mesValue;
                                    var mest = obsMesData.mest;
                                    var mesUnit = obsMesData.mesUnit;
                                    var obs = mest.get('observedMeasure').get('observation');
                                    if (isNaN(parseFloat(mesValue))) {
                                        var intervalArray = [];
                                        mest.get('observedMeasure').get('measure').get('interval').each(function (interval) {
                                            intervalArray.push(interval.get('symbol') ? interval.get('symbol') : ' ');
                                        });
                                        if (intervalArray.indexOf(mesValue) >= 0) {
                                            mest.set('symbol', mesValue);
                                        } else {
                                            errorMessage = '<b>' + mesValue + '</b>' + ' is not present in the ' + '<b>' + mest.get('name') + '</b>' + ' Intervals';
                                            checkUniqueErrorMsg(message);
                                        }
                                    } else {
                                        mest.set('value', mesValue);
                                        var unit = mest.get('observedMeasure').get('measure').get('unit');
                                        var previousUnit = unit ? unit.get('name') : 'Grade only';
                                        if (previousUnit !== mesUnit && previousUnit !== 'Grade only') {
                                            message = '<b>' + mesUnit + '</b>' + ' Unit is not valid for the ' + '<b>' + mest.get('name') + '</b>';
                                            checkUniqueErrorMsg(message);
                                        }
                                    }
                                    var date = new Date();
                                    if (!isNaN(date.setTime(obs.get('whenObserved')))) {
                                        var cachedObsData = observationData[obs.id];
                                        if (!cachedObsData) {
                                            cachedObsData = {};
                                            observationData[obs.id] = cachedObsData;
                                        }
                                        cachedObsData[mest.get('measuredCharacteristic').id] = mest.get('value') ? mest.get('value') : mest.get('symbol');
                                    }
                                });
                                DataManager.getDataManager().set(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING, false);

                                //},function () {
                                updateCodeContainers(true, totalCreateObsList, function (codeContainerList) {
                                    function getEarlierAlt(altList) {
                                        var phaseList = [];
                                        var phaseIndex;
                                        var phaseAltsList = [];
                                        var phase = DataManager.getDataManager().get('currentPlan').get('phase').findWhere({ previousPhase: null });
                                        while (phase) {
                                            phaseList.push(phase.id);
                                            phase = phase.get('nextPhase');
                                        }
                                        var index = phaseList.length;
                                        _.map(_.groupBy(altList, function (a) {
                                            return a.phaseId;
                                        }), function (grouped) {
                                            phaseAltsList.push(grouped);
                                        });
                                        for (var i = 0; i < phaseAltsList.length; i++) {
                                            phaseIndex = phaseList.indexOf(phaseAltsList[i][0].phaseId);
                                            if (phaseIndex < index) {
                                                index = phaseIndex;
                                            }
                                        }
                                        var earlierphaseId = phaseList[index];
                                        for (var i = 0; i < phaseAltsList.length; i++) {
                                            var earlier = phaseAltsList[i][0].phaseId === earlierphaseId;
                                            if (earlier) {
                                                var earliestAlts = phaseAltsList[i];
                                                break;
                                            }
                                        }
                                        if (earliestAlts && earliestAlts.length > 1) {
                                            return DataManager.getDataManager().get('currentPlan').get('phase').findWhere({ id: earliestAlts[0].phaseId }).get('master').id;
                                        } else if (earliestAlts && earliestAlts.length === 1) {
                                            return earliestAlts[0].altId;
                                        }
                                    }
                                    altList = _.uniq(altList, "altId");
                                    if (startPoint) {
                                        var exists = _.find(altList, function (a) { return a.altId === startPoint; });
                                        if (exists) {
                                            earliestAlt = startPoint;
                                        } else {
                                            earliestAlt = getEarlierAlt(altList);
                                        }
                                    } else {
                                        earliestAlt = getEarlierAlt(altList);
                                    }
                                    var updateObs = { altId: earliestAlt, obs: obsList, codeContainerList: _.uniq(codeContainerList) };
                                    window.utils.stopSpinner('importCSV');
                                    if (errorMessage !== '') {
                                        bootbox.alert(errorMessage);
                                    }
                                    finalCallback && finalCallback(updateObs);
                                });
                                //});
                            });
                        //}
                    }, null, null, null, "vbc:cmof_EObject-name", null, null, true, true, true);
            });
        };
        // sortByName(left, right) {
        //     return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
        // }
        scenarioExportDialog(timeStampArray) {  
            var self = this;         
            function sortByName(left, right) {
                return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
            }
            var observations=[];
            var date = new Date();
            var dataGuid = DataManager.getDataManager().guidGenerator();
            // self.timeStampArray = self.fetchDashboardTimeStampsList();
            var htmlContent = "<div class=\"exportClass\"><fieldset class=\"fieldsetClass\"><legend class=\"legendClass\">"+DataManager.getDataManager().get('localeManager').get('Include')+":</legend><input type=\"checkbox\" id='" + dataGuid +"ValueMeasurements' value='Value Measurements' checked style=\"cursor: pointer;\" >&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "ValueMeasurements'>Value Measurements</label><br>";
            htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "RecipientMeasurements' value='Recipient Measurements' style=\"cursor: pointer; checked \">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "RecipientMeasurements'>Recipient Opinion Measurements</label><br>";
            htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "SatisfactionLevelMeasurements' value='Satisfaction Level Measurements' style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "SatisfactionLevelMeasurements'>Satisfaction Measurements</label><br>";
            htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "PercentageWeightMeasurements' value='Percentage Weight Measurements' style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "PercentageWeightMeasurements'>Weight Measurements</label></fieldset></div>";
            htmlContent = htmlContent + "<br><div class=\"exportClass\"><label>"+DataManager.getDataManager().get('localeManager').get('selectScenario')+"</label><br>";

            var alts,observationModels, phases = self.get('phase').models;
            async.eachSeries(phases, function(phase, phaseHandled){
                alts = phase.get('phaseAlternative').models;
                async.eachSeries(alts, function(alt, altHandled){
                    observationModels = alt.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models;
                    async.eachSeries(observationModels, function(observation, obsHandled){
                        if(timeStampArray?.indexOf(observation.get('whenObserved'))>-1 || !observation.get('whenObserved') || !isNaN(observation.get('whenObserved'))){
                            var name = observation.get('name');
                            var timeStamp = observation.get('whenObserved');
                            var timeStampGuid = observation.get('whenObservedGuid');
                            observations.push({ 'name': name, 'id': observation.get('id'), 'timeStamp': timeStamp, 'timeStampGuid': timeStampGuid, 'escapeId': window.utils.htmlEscape(observation.get('id')), checked: false});
                        }
                        obsHandled();
                    }, function(){
                        altHandled();
                    });
                }, function(){
                    phaseHandled();
                });
                observations.sort(sortByName);
            },function(){
                observations=_.uniq(observations, function(obs){
                    return obs.timeStamp;
                });
                observations.sort(sortByName);
                var checked = "";
                for (var i = 0; i < observations.length; i++){
                    checked = "";
                    if (!observations[i].timeStamp) {
                        checked = " checked";
                    }
                    htmlContent = htmlContent + "<label style=\"display: inline\" for:" + observations[i].id + "><input style=\"cursor:pointer;margin-left: 2.8%\" id="+observations[i].id+" value="+observations[i].timeStamp+ checked + " type=\"checkbox\">&nbsp;&nbsp;<span style=\"cursor:pointer;word-wrap: break-word\">"+observations[i].name+"</span></label><br>";
                }
                htmlContent = htmlContent + "</div><br>";
            });
            htmlContent = htmlContent + "<div class=\"exportClass row\"><div class=\"col-xs-3\"><label>"+DataManager.getDataManager().get('localeManager').get('selectFormat')+"</label></div><div class=\"col-xs-2\"><select id=\"selectFormat\"><option selected=\"selected\" value=\"csv\">csv</option><option value=\"xlsx\">xlsx</option>";
            if(DataManager.getDataManager().get('debugMode')){
                htmlContent = htmlContent + "<option value=\"template\">template</option>";
                /*htmlContent = htmlContent + "<option value=\"library\">Business Indicator Library</option>";*/
                //htmlContent = htmlContent + "<option value=\"pythonModel\">Python Model</option>";
            }	
            htmlContent = htmlContent + "</select></div></div>";
            bootbox.dialog({
                title: '<img class="smallIcon" src="img/icons/icons_15.png">  '+DataManager.getDataManager().get('localeManager').get('exportOptions'),
                message: htmlContent,
                buttons: {
                    main: {
                        label: "Close",
                        className: "btn btn-default",
                    },
                    success: {
                        label: '<i id="generateCompleteBtn" class="fa fa-check"></i> Complete',
                        className: "btn btn-complete",
                        callback: function () {
                            var selectedOptions = jQuery('.exportClass input:checked');
                            var excludeValue = true;
                            var excludeRecipientOpinion = true;
                            var excludeSatisfaction = true;
                            var excludePercentageWeight = true;
                            var filterObs = [];
                            for (var i=0;i<selectedOptions.length;i++) {
                                var val= selectedOptions[i].value;
                                if(val==='Value Measurements'){
                                    excludeValue = false;
                                }else if(val==='Recipient Measurements'){
                                    excludeRecipientOpinion = false;
                                }else if(val==='Satisfaction Level Measurements'){
                                    excludeSatisfaction = false;
                                }else if(val==='Percentage Weight Measurements'){
                                    excludePercentageWeight=false;
                                }else{
                                    if(val ==="undefined" || val == "null"){
                                        filterObs.push(undefined);
                                    }else{
                                        filterObs.push(val);
                                    }
                                }
                            }
                            var e = document.getElementById("selectFormat");
                            var selectedFormat = e.options[e.selectedIndex].text;
                            if (filterObs.length > 1 && selectedFormat === "template") {
                                bootbox.alert("Can export a template for only one scenario");
                                return;
                            }
                            var timeStampGuid = null;
                            var scenarioName = null;
                            if (selectedFormat === "template") {
                                for (var i = 0; i < observations.length; i++) {
                                    if (observations[i].timeStamp == filterObs[0]) {
                                        timeStampGuid = observations[i].timeStampGuid;
                                        scenarioName = observations[i].name;
                                        break;
                                    }
                                }
                            }
                            self.generateMetricsCSV(undefined, filterObs, undefined, excludeRecipientOpinion, excludePercentageWeight, excludeSatisfaction, excludeValue, selectedFormat, timeStampGuid, scenarioName);
                        }
                    }
                }
            });
            if(DataManager.getDataManager().get('readMode')){
				$(".btn-complete").hide();$(".btn-danger").hide();$(".glyphicon-trash").hide();$(".glyphicon-plus-sign").hide();$(".glyphicon-minus").hide();
				$(".read-access").hide();
			}
        }

        
        generateMetricsCSV(filterMC, filterObs, filterAlt, excludeRecipientOpinion, excludePercentageWeight, excludeSatisfaction, excludeValue, extension, timeStampGuid, scenarioName, isOptimise, objectiveArray) {
            var self = this;
            var csvDataObj = [];
            var handledPackages = [];
            var phases = self.get('phase');
            async.eachSeries(phases.models, function (phase, phaseHandled) {
                var alts = phase.get('phaseAlternative');
                async.eachSeries(alts.models, function (alt, altHandled) {
                    var phaseObjSet = alt.get('phaseObjectiveSet');
                    if (phaseObjSet) {
                        var observations = phaseObjSet.get('objectiveObservation');
                        async.eachSeries(observations.models, function (obs, obsHandled) {
                            //observations.each(function (obs) {
                            self.addDataForObservation(self, alt.id, phaseObjSet, obs, csvDataObj, filterMC, filterObs, filterAlt, excludeRecipientOpinion, excludePercentageWeight, excludeSatisfaction, excludeValue, extension, obsHandled);
                            //});
                        }, function () {
                            var designParts = alt.get('phaseDesignPart');
                            async.eachSeries(designParts.models, function (designPart, packageHandled) {
                                if (designPart.get('beepType') === 'vdml_ValueDeliveryModel') {
                                    var ref = designPart.get('beepReference');
                                    if (ref.indexOf('Common@') > 0) {
                                        packageHandled();
                                        return;
                                    }
                                    if (_.indexOf(handledPackages, ref) < 0) {
                                        handledPackages.push(ref);
                                        self.handlePackageData(alt.id, ref, DataManager.getDataManager().get('currentVDMVersion'), csvDataObj, filterMC, filterObs, filterAlt, excludeRecipientOpinion, excludePercentageWeight, excludeSatisfaction, excludeValue, extension, packageHandled);
                                    } else {
                                        packageHandled();
                                    }
                                } else {
                                    packageHandled();
                                }
                            }, function () {
                                altHandled();
                            });
                        })
                    } else {
                        altHandled();
                    }
                }, function (err) {
                    phaseHandled();
                });
            }, function (err) {
                if (!extension) {
                    extension = 'csv';
                }
                var blobData;
                require(["xlsx"], function (XLSX) {
                    if (extension === 'csv') {
                        var csvObj = self.generateCSVData(csvDataObj, self);
                        var ws = XLSX.utils.json_to_sheet(csvObj);
                        blobData = XLSX.utils.sheet_to_csv(ws);
                        window.utils.stopSpinner('exportCSV');
                        var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete','Data');
                        window.utils.exportFileToSystem(self.get('name'),'CSV File',".csv",blobData,exportMsg);
                    } else if (extension === 'xlsx') {
                        var wb = self.generateXLSXData(csvDataObj,XLSX);
                        var bolbData = window.utils.s2ab(XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' }));
                        window.utils.stopSpinner('exportCSV');
                        var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete','Data');
                        window.utils.exportFileToSystem(self.get('name'),'XLSX File',".xlsx",bolbData,exportMsg);
                    } else if (extension === 'template') {
                        self.handleTemplate(csvDataObj, timeStampGuid, scenarioName);
                    } /*else if (extension === 'Business Indicator Library') {
                        self.createOrUpdateBusinessIndicatorLibrary(csvDataObj, self);
                    }*/ else if (extension === "Python Model") {
                        self.generateJuliaConcreteModel(csvDataObj, isOptimise, objectiveArray);
                    }
                });
            });
        };
        getBounds(templateData) {
            var self = this;
            var minMax = {}; var found = "";
            var max = ""; var min = "";
            var model = Backbone.Relational.store.getObjectByName('smm.CollectiveMeasurement').find({ id: templateData.id })
            if (templateData.ValueType === "Input") {
                model = Backbone.Relational.store.getObjectByName('smm.DirectMeasurement').find({ id: templateData.id })
            }
            if (model) {
                min = model.get('minRange');
                max = model.get('maxRange');
                if (!min && !max) {
                    var measurementOwner = model.get('measuredCharacteristic').get('valueMeasurementOwner');
                    if (measurementOwner) {
                        measurementOwner.get('valueDefinition');
                        var valueDef = self.getValueModel(measurementOwner.get('valueDefinition') ? measurementOwner.get('valueDefinition').id : "");
                        if (valueDef) {
                            max = valueDef.get('maxRange');
                            min = valueDef.get('minRange');
                        }
                    }
                    if (!measurementOwner) {
                        measurementOwner = model.get('measuredCharacteristic').get('percentageWeightOwner');
                        if (measurementOwner) {
                            measurementOwner.get('valueDefinition');
                            var valueDef = self.getValueModel(measurementOwner.get('valueDefinition') ? measurementOwner.get('valueDefinition').id : "");
                            if (valueDef) {
                                max = valueDef.get('maxRange');
                                min = valueDef.get('minRange');
                            }
                        }
                    }
                }
            }
            minMax.maxRange = max;
            minMax.minRange = min;
            return minMax;
        }
        getValueModel(valueId) {
            var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueCategory').find({ id: valueId });
            if (!valueDef) {
                valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: valueId });
            }
            return valueDef;
        }
        generateJuliaConcreteModel(csvDataObj, doOptimize, objectiveArray) {
            let regex = /(?<=\W|^)@[^,]+/g;
            window.utils.startSpinner('Optimising', "Optimising Values...");    
            var self = this;
            var templateData = self.generateTemplate(csvDataObj, self);
            console.log(templateData);
            var tabCount = 0;
            var modelScript = new Object();
            modelScript.script = "";
            self.writeScriptLine(tabCount, modelScript, "using JuMP");
            self.writeScriptLine(tabCount, modelScript, "using Ipopt");
            self.writeScriptLine(tabCount, modelScript, "include(\"vmplib.jl\")");
            self.writeScriptLine(tabCount,modelScript, "using .vmplib")
            self.writeScriptLine(tabCount, modelScript, "");
            self.writeScriptLine(tabCount, modelScript, "model = Model(optimizer_with_attributes(Ipopt.Optimizer))");

            var kpisHandled = [];
            var uniqKpis = [];
            var componets = self.get('planCriterionSet') ? self.get('planCriterionSet').get('component') : "";
            for (var i = 0; i < templateData.length; i++) {
                if (_.contains(kpisHandled, templateData[i].id)) {
                    continue;
                }
                var kpiId = templateData[i].id.replaceAll(/[@#-]/g, "_");
                self.writeScriptLine(tabCount, modelScript, "#" + templateData[i].ValueName);
                var minMax = self.getBounds(templateData[i]);
                var min = minMax.minRange; var max = minMax.maxRange;
                self.writeScriptLine(tabCount, modelScript, "@variable(model, " + kpiId + ")")
                if (templateData[i].ValueType === "Input") {
                    if(templateData[i].Value == undefined){
                        console.log("Invalid input for value:" + kpiId);
                    }
                    var val = templateData[i].Value ? templateData[i].Value : 0;
                    //self.writeScriptLine(tabCount, modelScript, "@variable(model, " + kpiId + ", Real)")
                    self.writeScriptLine(tabCount, modelScript, "set_start_value("+ kpiId +", " + val + ")")
                    if (doOptimize) {
                        if (!isNaN(parseFloat(min)) && !isNaN(parseFloat(max))) {
                            //self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " @variable(model, t_hex2_liquid_out)" + val + ",bounds=(" + min + "," + max + "))");
                            self.writeScriptLine(tabCount, modelScript, "@constraint(model, " + min + "<=" + kpiId + "<=" + max +")");
                        } else {
                            //self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " = Var(within=NonNegativeReals,initialize=" + val + ")");
                        }
                    } else {
                        //self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " = Var(within=NonNegativeReals,initialize=" + val + ",bounds=(" + val + "," + val + "))");
                        self.writeScriptLine(tabCount, modelScript, "@constraint(model," + kpiId + "==" + val + ")");
                    }   
                } else {

                    
                    if (objectiveArray && window.utils.htmlEscape(templateData[i].id) === window.utils.htmlEscape(objectiveArray.id)) {
                        objectiveArray.id = objectiveArray.id.replaceAll('_', '@');
                        var bound = "maximize"
                        if (objectiveArray.minOrMax == '0') {
                            bound = "minimize"
                        }
                        //self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " = Objective()");
                        //self.writeScriptLine(tabCount, modelScript, "model." + kpiId + ".set_sense(" + bound + ")");
                        if(bound == "maximize"){
                            self.writeScriptLine(tabCount, modelScript, "@objective(model, Max, "+ kpiId + ")")
                        }else{
                            self.writeScriptLine(tabCount, modelScript, "@objective(model, Min, "+ kpiId + ")")
                        }
                        
                    } else {
                        //self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " = Expression()");
                        if (!isNaN(parseFloat(min)) && !isNaN(parseFloat(max))) {
                            self.writeScriptLine(tabCount, modelScript, "@constraint(model, " + min + "<=" + kpiId + "<=" + max +")");
                        }
                        else if (!isNaN(parseFloat(min)) && isNaN(parseFloat(max))) {
                            self.writeScriptLine(tabCount, modelScript, "@constraint(model, " + min + "<=" + kpiId +")");
                        }
                        else if (isNaN(parseFloat(min)) && !isNaN(parseFloat(max))) {
                            self.writeScriptLine(tabCount, modelScript, "@constraint(model, " + kpiId + "<=" + max +")");
                        }
                    }
                }
                kpisHandled.push(templateData[i].id);
                uniqKpis.push(templateData[i]);
            }
            self.writeScriptLine(tabCount, modelScript, "");
            templateData = uniqKpis;
            for (i = 0; i < templateData.length; i++) {
                kpiId = templateData[i].id.replaceAll(/[@#-]/g, "_");
                if(kpiId == '_ec24be_b782_1d00__2989_4bf6_a9aa_6f141312'){
                    debugger;
                }
                if (kpiId && templateData[i].ValueType === "Calculated") {
                    var relations = templateData[i].Formula.relations;
                    var relKeys = _.allKeys(templateData[i].Formula.relations);
                    var kpiExpr = templateData[i].Formula.pythonScript;
                    var val = templateData[i].Value ? templateData[i].Value : 0;
                    for (var j = 0; j < relKeys.length ; j++) {
                        var key = relKeys[j];
                        var baseMestId = templateData[i].Formula.relations[key].baseMeasurement;
                        //var repVal = "model." + baseMestId;
                        var repVal = baseMestId;
                        repVal = repVal.replaceAll(/[@#-]/g, "_");
                        kpiExpr = kpiExpr.replaceAll(baseMestId, repVal);
                    }
                    if (!isNaN(parseFloat(min)) && !isNaN(parseFloat(max))) {
                        self.writeScriptLine(tabCount, modelScript, "@constraint(model, " + min + "<=" + kpiId + "<=" + max +")");
                    }
                    else if (!isNaN(parseFloat(min)) && isNaN(parseFloat(max))) {
                        self.writeScriptLine(tabCount, modelScript, "@constraint(model, " + min + "<=" + kpiId +")");
                    }
                    else if (isNaN(parseFloat(min)) && !isNaN(parseFloat(max))) {
                        self.writeScriptLine(tabCount, modelScript, "@constraint(model, " + kpiId + "<=" + max +")");
                    }
                    self.writeScriptLine(tabCount, modelScript, "set_start_value("+ kpiId +", " + (val ? val : "rand(Float64,1)[1]") + ")")
                    kpiExpr = kpiExpr.replace(regex, match => match.replace(/[@-]/g, '_'));
                    self.writeScriptLine(tabCount, modelScript, "#" + templateData[i].ValueName);
                    //self.writeScriptLine(tabCount, modelScript, "model." + kpiId + ".set_value(" + kpiExpr + ")");
                    self.writeScriptLine(tabCount, modelScript, "@constraint(model," + kpiId + " == " + kpiExpr +")");
                }
                //self.writeScriptLine(tabCount, modelScript, "");
            }
            /*if (!doOptimize) {
                self.writeScriptLine(tabCount, modelScript, "model.y = Var(within=NonNegativeReals,initialize=10.00)");
                self.writeScriptLine(tabCount, modelScript, "model.a = Objective()");
                self.writeScriptLine(tabCount, modelScript, "model.a.set_value(0)");
                self.writeScriptLine(tabCount, modelScript, "model.a.set_sense(maximize)");

                self.writeScriptLine(tabCount, modelScript, "def maxz(m):");
                tabCount++;
                self.writeScriptLine(tabCount, modelScript, "return m.y <= 100000");
                tabCount--;
                self.writeScriptLine(tabCount, modelScript, "model.constz = Constraint(rule = maxz)");
            } else {
                self.writeScriptLine(tabCount, modelScript, "def maxz(m):");
                tabCount++;
                var objectiveId = objectiveArray.id.replaceAll(/[@#-]/g, "_");
                if (objectiveArray && objectiveArray.minOrMax == 0) {
                    self.writeScriptLine(tabCount, modelScript, "return m." + objectiveId + " >= " + objectiveArray.value + "");
                } else {
                    self.writeScriptLine(tabCount, modelScript, "return m." + objectiveId + " <= " + objectiveArray.value + "");
                }
                tabCount--;
                self.writeScriptLine(tabCount, modelScript, "model.constz = Constraint(rule = maxz)");
            }*/
            
            self.writeScriptLine(tabCount, modelScript, "");

            self.writeScriptLine(tabCount, modelScript, "@objective(model, Max, 1)");
            self.writeScriptLine(tabCount, modelScript, "JuMP.optimize!(model)");
            //self.writeScriptLine(tabCount, modelScript, "solver.options['max_iter'] = 6000");
            //self.writeScriptLine(tabCount, modelScript, "results = solver.solve(model, tee=True)");
            //self.writeScriptLine(tabCount, modelScript, "print()");
            self.writeScriptLine(tabCount, modelScript, "if termination_status(model) == MOI.OPTIMAL || termination_status(model) == MOI.LOCALLY_SOLVED");
            tabCount++;
            self.writeScriptLine(tabCount, modelScript, "print(\"done\")");
            self.writeScriptLine(tabCount, modelScript, "returnValue= Dict()");
            for (i = 0; i < templateData.length; i++) {
                if (templateData[i].id) {
                    kpiId = templateData[i].id.replaceAll(/[@#-]/g, "_");
                    self.writeScriptLine(tabCount, modelScript, "valDetails = Dict()");
                    self.writeScriptLine(tabCount, modelScript, "valDetails[\"phase\"] = \"" + templateData[i]["Phase>Alternative"] + "\"");
                    self.writeScriptLine(tabCount, modelScript, "valDetails[\"type\"] = \"" + templateData[i].ValueType + "\"");
                    self.writeScriptLine(tabCount, modelScript, "valDetails[\"name\"] = \"" + templateData[i].ValueName + "\"");
                    self.writeScriptLine(tabCount, modelScript, "valDetails[\"originalId\"] = \"" + templateData[i].id + "\"");
                    self.writeScriptLine(tabCount, modelScript, "valDetails[\"value\"] = value(" + kpiId + ")");
                    //self.writeScriptLine(tabCount, modelScript, "print(\"phase:" + templateData[i]["Phase>Alternative"] + " , Type:" + templateData[i].ValueType + " , Value:" + templateData[i].ValueName + "\")");
                    self.writeScriptLine(tabCount, modelScript, "returnValue[\""+ kpiId + "\"] = valDetails" );
                } 
            }
            tabCount--;
            self.writeScriptLine(tabCount, modelScript, "elseif termination_status(model) == MOI.INFEASIBLE");
            tabCount++;
            self.writeScriptLine(tabCount, modelScript, "throw(ArgumentError(\"Termination condition infeasible\"))");
            tabCount--;
            self.writeScriptLine(tabCount, modelScript, "else");
            tabCount++;
            self.writeScriptLine(tabCount, modelScript, "throw(ArgumentError(\"Something went wrong:\" * string(termination_status(model))" + "))");
            tabCount--;
            self.writeScriptLine(tabCount, modelScript, "end");
            console.log(modelScript.script);
            //var deferred = jQuery.Deferred();
            var postData = {
                "url": DataManager.getDataManager().get("vmpPyomoServer"),
                "method": "POST",
                "processData": false,
                "contentType" : "application/json",
                "dataType": "text",
                "data": modelScript.script,
                "success": function (data, status, jqXHR) {// success callback
                    if (data) {
                        var jsonData = JSON.parse(data);
                        for (key in jsonData) {
                            var sigDec = 2; 
                            var modelId = jsonData[key].originalId;
                            var model = Backbone.Relational.store.getObjectByName('smm.CollectiveMeasurement').find({ id: modelId})
                            if (jsonData[key].type === "Input") {
                                model = Backbone.Relational.store.getObjectByName('smm.DirectMeasurement').find({ id: modelId})
                            }
                            if (model) {
                                model.set('value', jsonData[key].value, { silent: true });
                                var measure = model.get('observedMeasure').get('measure') ? model.get('observedMeasure').get('measure').get('unit') : "";
                                sigDec = measure ? measure.get('significantDecimals') : sigDec
                            }
                            if (jsonData[key].type === "Calculated") {
                                if (jQuery('#valueContent') && jQuery("#valueContent").length > 0) {
                                    var childs = jQuery('#valueContent').children();
                                    for (var i = 0; i < childs.length; i++) {
                                        if (childs[i].id === modelId) {
                                            childs[i].children[2].textContent = parseFloat(jsonData[key].value).toFixed(sigDec);
                                            break;
                                        }
                                        
                                    }
                                }
                            }
                        }
                        window.utils.stopSpinner('Optimising')
                        bootbox.alert('Updated');
                    }
                },
                "error": function (e) {
                    if (e.responseText) {
                        bootbox.alert(e.responseText);
                    } else {
                        bootbox.alert("Something went wrong.");
                    }
                    window.utils.stopSpinner('Optimising')
                }
            };
            jQuery.ajax(postData);
            //self.writeToFile(modelScript.script);
        };
        
        generatePythonConcreteModel(csvDataObj, doOptimize, objectiveArray) {
            window.utils.startSpinner('Optimising', "Optimising Values...");    
            var self = this;
            var templateJSON = {};
            var snapshotJSON = {};
            var templateData = self.generateTemplate(csvDataObj, self);
            console.log(templateData);
            var tabCount = 0;
            var modelScript = new Object();
            modelScript.script = "";
            self.writeScriptLine(tabCount, modelScript, "from pyomo.environ import *");
            self.writeScriptLine(tabCount, modelScript, "from vmplib import *");
            self.writeScriptLine(tabCount, modelScript, "import sys");
            self.writeScriptLine(tabCount, modelScript, "");
            self.writeScriptLine(tabCount, modelScript, "model = ConcreteModel()");

            self.writeScriptLine(tabCount, modelScript, "model.limits = ConstraintList()");

            var kpisHandled = [];
            var uniqKpis = [];
            var componets = self.get('planCriterionSet') ? self.get('planCriterionSet').get('component') : "";
            for (var i = 0; i < templateData.length; i++) {
                if (_.contains(kpisHandled, templateData[i].id)) {
                    continue;
                }
                var kpiId = templateData[i].id.replaceAll(/[@#-]/g, "_");
                self.writeScriptLine(tabCount, modelScript, "#" + templateData[i].ValueName);
                var minMax = self.getBounds(templateData[i]);
                var min = minMax.minRange; var max = minMax.maxRange;
                if (templateData[i].ValueType === "Input") {
                    var val = templateData[i].Value ? templateData[i].Value : 0;
                    if (doOptimize) {
                        if (!isNaN(parseFloat(min)) && !isNaN(parseFloat(max))) {
                            self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " = Var(within=NonNegativeReals,initialize=" + val + ",bounds=(" + min + "," + max + "))");
                        } else {
                            self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " = Var(within=NonNegativeReals,initialize=" + val + ")");
                        }
                    } else {
                        self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " = Var(within=NonNegativeReals,initialize=" + val + ",bounds=(" + val + "," + val + "))");
                    }
                } else {
                    if (objectiveArray && window.utils.htmlEscape(templateData[i].id) === window.utils.htmlEscape(objectiveArray.id)) {
                        objectiveArray.id = objectiveArray.id.replaceAll('_', '@');
                        var bound = "maximize"
                        if (objectiveArray.minOrMax == '0') {
                            bound = "minimize"
                        }
                        self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " = Objective()");
                        self.writeScriptLine(tabCount, modelScript, "model." + kpiId + ".set_sense(" + bound + ")");
                    } else {
                        self.writeScriptLine(tabCount, modelScript, "model." + kpiId + " = Expression()");
                        if (!isNaN(parseFloat(min)) && !isNaN(parseFloat(max))) {
                            self.writeScriptLine(tabCount, modelScript, "model.limits.add(model." + kpiId + "<=" +max + ")");
                            self.writeScriptLine(tabCount, modelScript, "model.limits.add(model." + kpiId + ">=" +  min + ")");
                        }
                    }
                }
                kpisHandled.push(templateData[i].id);
                uniqKpis.push(templateData[i]);
            }
            self.writeScriptLine(tabCount, modelScript, "");
            templateData = uniqKpis;
            for (i = 0; i < templateData.length; i++) {
                kpiId = templateData[i].id.replaceAll(/[@#-]/g, "_");
                if (kpiId && templateData[i].ValueType === "Calculated") {
                    var relations = templateData[i].Formula.relations;
                    var relKeys = _.allKeys(templateData[i].Formula.relations);
                    var kpiExpr = templateData[i].Formula.pythonScript;
                    for (var j = 0; j < relKeys.length ; j++) {
                        var key = relKeys[j];
                        var baseMestId = templateData[i].Formula.relations[key].baseMeasurement;
                        var repVal = "model." + baseMestId;
                        repVal = repVal.replaceAll(/[@#-]/g, "_");
                        kpiExpr = kpiExpr.replaceAll(baseMestId, repVal);
                    }
                    self.writeScriptLine(tabCount, modelScript, "#" + templateData[i].ValueName);
                    self.writeScriptLine(tabCount, modelScript, "model." + kpiId + ".set_value(" + kpiExpr + ")");
                    self.writeScriptLine(tabCount, modelScript, "");
                } 
                //self.writeScriptLine(tabCount, modelScript, "");
            }
            if (!doOptimize) {
                self.writeScriptLine(tabCount, modelScript, "model.y = Var(within=NonNegativeReals,initialize=10.00)");
                self.writeScriptLine(tabCount, modelScript, "model.a = Objective()");
                self.writeScriptLine(tabCount, modelScript, "model.a.set_value(0)");
                self.writeScriptLine(tabCount, modelScript, "model.a.set_sense(maximize)");

                self.writeScriptLine(tabCount, modelScript, "def maxz(m):");
                tabCount++;
                self.writeScriptLine(tabCount, modelScript, "return m.y <= 100000");
                tabCount--;
                self.writeScriptLine(tabCount, modelScript, "model.constz = Constraint(rule = maxz)");
            } else {
                self.writeScriptLine(tabCount, modelScript, "def maxz(m):");
                tabCount++;
                var objectiveId = objectiveArray.id.replaceAll(/[@#-]/g, "_");
                if (objectiveArray && objectiveArray.minOrMax == 0) {
                    self.writeScriptLine(tabCount, modelScript, "return m." + objectiveId + " >= " + objectiveArray.value + "");
                } else {
                    self.writeScriptLine(tabCount, modelScript, "return m." + objectiveId + " <= " + objectiveArray.value + "");
                }
                tabCount--;
                self.writeScriptLine(tabCount, modelScript, "model.constz = Constraint(rule = maxz)");
            }
            
            self.writeScriptLine(tabCount, modelScript, "");

            self.writeScriptLine(tabCount, modelScript, "solver = SolverFactory('ipopt')");
            self.writeScriptLine(tabCount, modelScript, "solver.options['max_iter'] = 6000");
            self.writeScriptLine(tabCount, modelScript, "results = solver.solve(model, tee=True)");
            //self.writeScriptLine(tabCount, modelScript, "print()");
            self.writeScriptLine(tabCount, modelScript, "if (results.solver.status == SolverStatus.ok) and (results.solver.termination_condition == TerminationCondition.optimal):");
            tabCount++;
            self.writeScriptLine(tabCount, modelScript, "print('done')");
            self.writeScriptLine(tabCount, modelScript, "returnValue={}");
            for (i = 0; i < templateData.length; i++) {
                if (templateData[i].id) {
                    kpiId = templateData[i].id.replaceAll(/[@#-]/g, "_");
                    self.writeScriptLine(tabCount, modelScript, "valDetails = {}");
                    self.writeScriptLine(tabCount, modelScript, "valDetails['phase'] = '" + templateData[i]["Phase>Alternative"] + "'");
                    self.writeScriptLine(tabCount, modelScript, "valDetails['type'] = '" + templateData[i].ValueType + "'");
                    self.writeScriptLine(tabCount, modelScript, "valDetails['name'] = '" + templateData[i].ValueName + "'");
                    self.writeScriptLine(tabCount, modelScript, "valDetails['originalId'] = '" + templateData[i].id + "'");
                    self.writeScriptLine(tabCount, modelScript, "valDetails['value'] = value(model." + kpiId + ")");
                    //self.writeScriptLine(tabCount, modelScript, "print(\"phase:" + templateData[i]["Phase>Alternative"] + " , Type:" + templateData[i].ValueType + " , Value:" + templateData[i].ValueName + "\")");
                    self.writeScriptLine(tabCount, modelScript, "returnValue['"+ kpiId + "'] = valDetails" );
                } 
            }
            tabCount--;
            self.writeScriptLine(tabCount, modelScript, "elif results.solver.termination_condition == TerminationCondition.infeasible:");
            tabCount++;
            self.writeScriptLine(tabCount, modelScript, "raise Exception('Termination condition infesable')");
            tabCount--;
            self.writeScriptLine(tabCount, modelScript, "else:");
            tabCount++;
            self.writeScriptLine(tabCount, modelScript, "raise Exception('Something went wrong:' + results.solver.termination_condition)");
            console.log(modelScript.script);
            //var deferred = jQuery.Deferred();
            var postData = {
                "url": DataManager.getDataManager().get("vmpPyomoServer"),
                "method": "POST",
                "processData": false,
                "contentType" : "application/json",
                "dataType": "text",
                "data": modelScript.script,
                "success": function (data, status, jqXHR) {// success callback
                    if (data) {
                        var jsonData = JSON.parse(data);
                        for (key in jsonData) {
                            var sigDec = 2; 
                            var modelId = jsonData[key].originalId;
                            var model = Backbone.Relational.store.getObjectByName('smm.CollectiveMeasurement').find({ id: modelId})
                            if (jsonData[key].type === "Input") {
                                model = Backbone.Relational.store.getObjectByName('smm.DirectMeasurement').find({ id: modelId})
                            }
                            if (model) {
                                model.set('value', jsonData[key].value, { silent: true });
                                var measure = model.get('observedMeasure').get('measure') ? model.get('observedMeasure').get('measure').get('unit') : "";
                                sigDec = measure ? measure.get('significantDecimals') : sigDec
                            }
                            if (jsonData[key].type === "Calculated") {
                                if (jQuery('#valueContent') && jQuery("#valueContent").length > 0) {
                                    var childs = jQuery('#valueContent').children();
                                    for (var i = 0; i < childs.length; i++) {
                                        if (childs[i].id === modelId) {
                                            childs[i].children[2].textContent = parseFloat(jsonData[key].value).toFixed(sigDec);
                                            break;
                                        }
                                        
                                    }
                                }
                            }
                        }
                        window.utils.stopSpinner('Optimising')
                        bootbox.alert('Updated');
                    }
                },
                "error": function (e) {
                    if (e.responseText) {
                        bootbox.alert(e.responseText);
                    } else {
                        bootbox.alert("Something went wrong.");
                    }
                    window.utils.stopSpinner('Optimising')
                }
            };
            jQuery.ajax(postData);
            //self.writeToFile(modelScript.script);
        };
        /*writeToFile(code) {
            var self = this;
            var bolbData = s2ab(code);
            self.saveDataToFile(bolbData, "py");
        };*/
        writeScriptLine(tabCount, modelScript, code) {
            var script = modelScript.script;
            script = script.concat("\n");
            for (var i = 0; i < tabCount; i++) {
                script = script.concat("\t");
            }
            script = script.concat(code);
            modelScript.script = script;
        };
        handleTemplate(csvDataObj, timeStampGuid, scenarioName) {
            var self = this;
            var templateJSON = [];
            var snapshotJSON = [];
            var phases = self.get('phase');
            /*{
            id :'snapshot'
            template:[{templateId:'Template_id1',snapshotId:'sid1',previousSnapshotId:null},
                {templateId:'Template_id2',snapshotId:'sid2',previousSnapshotId:sid1}]
            }*/
            var snapJsonList = [];
            for(var i=0;i<phases.length;i++){
                var alternatives = phases.at(i).get('phaseAlternative');
                for(var j=0;j<alternatives.length;j++){
                    var alt = alternatives.at(j);
                    var templateId = alt.get('templateId');
                    var previousPhasePrimary = alt.getPreviousPhasePrimary();
                    var snapshotId = window.utils.getScenarioSnapshotId(alt,null);
                    var previousSnapshotId = previousPhasePrimary?window.utils.getScenarioSnapshotId(previousPhasePrimary,null):null;
                    var snapJson = {templateId:templateId,snapshotId:snapshotId,previousSnapshotId:previousSnapshotId};
                    //TODO for other scenarios
                    snapJsonList.push(snapJson);
                }
            } 
            var templateIdJson = {id :'snapshot', template : snapJsonList}

            var templateData = self.generateTemplate(csvDataObj, self);
            for (var i = 0; i < templateData.length; i++) {
                if (templateData[i].id) {
                    var copy = jQuery.extend(true, {}, templateData[i]);
                    delete templateData[i].Value;
                    templateJSON.push(templateData[i]);
                    
                    delete copy.Formula;
                    /*if(copy.ValueType == "Calculated"){
                        delete copy.Value;
                    }*/
                    snapshotJSON.push(copy);
                }
            }
                          
            var snapshotService = DataManager.getDataManager().get('vmpServerService');
            var tenantId = DataManager.getDataManager().get("currentLegalEntity") ? DataManager.getDataManager().get("currentLegalEntity").entityId : null;
            if (snapshotService && tenantId) {
                var libraries = csvDataObj.libraries;
                var libKeys = Object.getOwnPropertyNames(libraries);
                async.eachSeries(libKeys, function (libKey, postedLib) {
                    var params = {};
                    params.tenantId = tenantId;
                    params.libraryId = libKey;
                    params.fileContent = csvDataObj.libraries[libKey];
                    //DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/library", JSON.stringify(params)).then(function (data) {
                        postedLib();
                    //});
                }, function (e) {
                    var params = {};
                    params.tenantId = tenantId;
                    params.id = self.get('id') + (timeStampGuid ? timeStampGuid : "");
                    params.fileContent = templateJSON;
                    params.name = self.get('name') + "-" + scenarioName;
                    DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/template", JSON.stringify(params)).then(function (data) {
                        var params = {};
                        params.tenantId = tenantId;
                        params.id = self.get('id') + (timeStampGuid ? timeStampGuid : "");
                        params.period = timeStampGuid ? timeStampGuid : "";
                        //params.templateId = self.get('id');
                        //params.templateId = data.id;
                        params.templateId = templateIdJson;
                        params.name = self.get('name') + "-" + scenarioName;
                        params.skipSingleScript = true;
                        params.isVmpTemplate = true;
                        params.fileContent = snapshotJSON;
                        DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/snapshot?tenantId=" + tenantId, JSON.stringify(params)).then(function (data) {
                            bootbox.alert("Updated the template/snapshot/measure on server.");
                        });
                    });
                });
            } else {
                bootbox.alert("Snapshot service could not be contacted or current tenant not available");
            }
        };
        /*saveDataToFile(blobData, extension) {
            var self = this;
            var suggestion = self.get('name').replace(/\"/g, "");
            window.utils.startSpinner('exportCSV', "Exporting Data ... ");
            var blob = new Blob([blobData], { type: extension === 'csv' ? 'text/plain' : "application/octet-stream" });
            saveAs(blob, suggestion + "." + extension);
            window.setTimeout(function () {
                window.utils.stopSpinner('exportCSV');
            }, 500);
        }*/
        getScenarioProxy(scenarioId){
            var phases = this.get('phase');
            for (var i = 0; i < phases.length; i++) {
                var alts = phases.at(i).get('phaseAlternative');
                for (var j = 0; j < alts.length; j++) {
                    var scenarios = alts.at(j).get('designScenario');
                    for (var k = 0; k < scenarios.length; k++) {
                        if (scenarios.at(k).get('scenario') === scenarioId) {
                            return scenarios.at(k);
                        }
                    }
                }
            }
        }
        handlePackageData(altId, id, version, csvDataObj, filterMC, filterObs, filterAlt, excludeRecipientOpinion, excludePercentageWeight, excludeSatisfaction, excludeValue, extension, callback) {
            var self = this;
            var vdmStore = DataManager.getDataManager().getVDMStore(altId);
            DataManager.getDataManager().fetchDocumentFromPackage(id, "appbo/vdml/ValueDeliveryModel", version, id, "appbo/vdml/ValueDeliveryModel", vdmStore, {
                success: function (vdmPackage) {
                    var scenarios = vdmPackage.get('scenario');
                    async.eachSeries(scenarios.models, function (scenario, scenarioHandled) {
                        var observations = scenario.get('contextObservation');
                        var scenarioProxy = self.getScenarioProxy(scenario.id);
                        if (!scenarioProxy) {
                            scenarioHandled();
                            //return;
                        }
                        var obsAlt = scenarioProxy.get('designScenarioOwner');
                        async.eachSeries(observations.models, function (obs, observationHandled) {
                            self.addDataForObservation(self, obsAlt.id, scenario, obs, csvDataObj, filterMC, filterObs, filterAlt, excludeRecipientOpinion, excludePercentageWeight, excludeSatisfaction, excludeValue, extension, observationHandled);
                        }, function (err) {
                            scenarioHandled();
                        });
                    }, function () {
                        callback();
                    });
                },
                error: function (error) {
                    console.log('Unable to load selected Package');
                    callback();
                }
            });
        }
        addDataForObservation(plan, altId, scenario, observation, csvDataObj, filterMC, filterObs, filterAlt, excludeRecipientOpinion, excludePercentageWeight, excludeSatisfaction, excludeValue, extension, obsCallback) {
            if (filterAlt && _.indexOf(filterAlt, altId) < 0) {
                obsCallback();
				return;
            }
            if (filterObs && _.indexOf(filterObs, observation.get('whenObserved') ? String(observation.get('whenObserved')) : undefined) < 0) {
                obsCallback();
				return;
            }
            var libraries = csvDataObj['libraries'];
            if (!libraries) {
                libraries = csvDataObj['libraries'] = {};
            }
            var values = csvDataObj['Values'];
            if (!values) {
                values = csvDataObj['Values'] = [];
            }
            var altsData = csvDataObj['altData'];
            if (!altsData) {
                altsData = csvDataObj['altData'] = {};
            }
            var altData = altsData[altId];
            if (!altData) {
                altsData[altId] = {};
                altData = altsData[altId];
            }
            var BusinessIndicatorLibrary;
            var obsMeasures = observation.get('observedMeasures');
            var whenObserved = observation.get('whenObserved');
            var whenObservedGuid = observation.get('whenObservedGuid');
            /*if (extension === "Business Indicator Library") {
                measurementLibrary = observation.get('smmModel').getOrCreateBusinessIndicatorLibrary(obsName, observation.get('whenObservedGuid'));
            }*/
            var obsName = observation.get('name');
            //var obsName = observation.getContext().get("id");
            var timeStamp = observation.get('whenObserved');
            var obsData = altData[obsName];
            if (!obsData) {
                obsData = altData[obsName] = {};
            }
            var data;
            if (!obsMeasures || observation.get('dataReference')) {
                var dataRef = observation.get('dataReference');
                if (dataRef) {
                    CodeContainer.getBlobInstanceWithId(dataRef, plan, function (codeContainer) {
                        if (codeContainer) {
                            var dataStr = codeContainer.get('code');
                            data = dataStr ? JSON.parse(dataStr) : null;
							if (data) {
								var valIds = Object.getOwnPropertyNames(data);
								_.each(valIds, function (val) {
									var vm = Backbone.Relational.store.getObjectByName('vdml.MeasuredCharacteristic').find({ id: val.split(',')[0] });
									var mesChar = vm.get('measurement').measuredCharacteristic;
									var value = mesChar.getParent();
									if (value.get('satisfactionLevel') === mesChar && excludeSatisfaction) {
										return;
									}
									if (value.get('percentageWeight') === mesChar && excludePercentageWeight) {
										return;
									}
									if (value.get('recipientOpinion') === mesChar && excludeRecipientOpinion) {
										return;
									}
									if (value.get('valueMeasurement') === mesChar && excludeValue) {
										return;
									}
									var mesCharSuffix = mesChar.id.substr(mesChar.id.lastIndexOf('@') + 1);
									var valueSuffix = window.utils.getSuffix(value.id);
									if (filterMC && _.indexOf(filterMC, mesCharSuffix) < 0 && _.indexOf(filterMC, valueSuffix) < 0) {
										return;
									}
									//var valSuffix = mesChar.id.substr(mesChar.id.lastIndexOf('@') + 1);
									//		            obsData[valSuffix] = mest;
									values.push(mesChar.id);
									if (data[val]) {
										values.push(mesChar.id + '#Unit');
									}
								});
							}
                        } else {
                            console.log('Unable to load selected Package');
                        }
						obsCallback();
						return;
                    });
                }
            } else {
                obsMeasures.each(function (obsMes) {
                    var mest = obsMes.get('measurements').at(0);
                    var mesChar = mest.get('measuredCharacteristic');
                    var value = mesChar.getParent();
                    if (value.get('satisfactionLevel') === mesChar && excludeSatisfaction) {
                        return;
                    }
                    if (value.get('percentageWeight') === mesChar && excludePercentageWeight) {
                        return;
                    }
                    if (value.get('recipientOpinion') === mesChar && excludeRecipientOpinion) {
                        return;
                    }
                    if (value.get('valueMeasurement') === mesChar && excludeValue) {
                        return;
                    }
					var mesCharSuffix = mesChar.id.substr(mesChar.id.lastIndexOf('@') + 1);
                    var valueSuffix = window.utils.getSuffix(value.id);
                    if (filterMC && _.indexOf(filterMC, mesCharSuffix) < 0 && _.indexOf(filterMC, valueSuffix) < 0) {
                        return;
                    }
					
                    obsData[mesCharSuffix] = mest;
                    if (mest.get('type') === 'smm_CollectiveMeasurement') {
                        var measure = mest.get('observedMeasure').get('measure');
                        var library = measure.get('libraries');
                        if (!libraries[library.id]) {
                            libraries[library.id] = [];
                        }
                        if (!libraries[library.id][measure.id]) {
                            libraries[library.id].push(measure.getMeasureDetails());
                        }
                        obsData[mesCharSuffix + '-measurement'] = mest.getRelationDetails();
                    }
                    values.push(mesChar.id);
                    if (mest.get('value')) {
                        values.push(mesChar.id + '#Unit');
                    }
                    //if (extension === "Business Indicator Library") {
                     //   var mestElement = measurementLibrary.get('businessIndicatorElement').find({});
                    //}
                });
				obsCallback();
				return;
            }
        }

        createWorkbook (XLSX) {
            var wb = new XLSX.utils.book_new();
            wb.SheetNames = [];
            wb.Sheets = {};
            return wb;
        }
        createWorkbookSheetHeader(wb, XLSX, altsData) {
            var ws = {};

            ws['!merges'] = [];
            ws['!merges'].push({ s: { c: 0, r: 0 }, e: { c: 0, r: 1 } });
            ws['!merges'].push({ s: { c: 1, r: 0 }, e: { c: 1, r: 1 } });
            ws['!merges'].push({ s: { c: 2, r: 0 }, e: { c: 2, r: 1 } });
            ws['!cols'] = [];
            ws['!cols'].push({ wch: 60 });
            ws['!cols'].push({ wch: 20 });
            ws['!cols'].push({ wch: 20 });

            var ws_name = "Sheet1";
            wb.SheetNames.push(ws_name);
            var column = 0;
            var cell = { v: "Value Path" };
            var cell_ref = XLSX.utils.encode_cell({ c: column++, r: 0 });
            cell.t = 's';
            ws[cell_ref] = cell;
            var cell = { v: "Value Name" };
            var cell_ref = XLSX.utils.encode_cell({ c: column++, r: 0 });
            cell.t = 's';
            ws[cell_ref] = cell;
            var cell = { v: "Value Type" };
            var cell_ref = XLSX.utils.encode_cell({ c: column++, r: 0 });
            cell.t = 's';
            ws[cell_ref] = cell;

            if (!altsData) {
                wb.Sheets[ws_name] = ws;
                return ws;
            }
            var alts = Object.getOwnPropertyNames(altsData);
            var phaseName;
            var phaseStart;
            var addPhase;
            _.each(alts, function (alt) {
                var altData = altsData[alt];
                var altModel = Alternative.find({ id: alt });
                if (phaseName !== altModel.getParent().get('name')) {
                    addPhase = true;
                    if (phaseStart) {
                        ws['!merges'].push({ s: { c: phaseStart, r: 0 }, e: { c: column - 1, r: 0 } });
                    }
                    phaseStart = column;
                    phaseName = altModel.getParent().get('name');
                }
                var whenObservedNames = Object.getOwnPropertyNames(altData);
                _.each(whenObservedNames, function (whenObs) {
                    var cell = { v: addPhase ? phaseName : 0 };
                    var cell_ref = XLSX.utils.encode_cell({ c: column++, r: 0 });
                    cell.t = addPhase ? 's' : 'n';
                    ws[cell_ref] = cell;
                    addPhase = false;

                    var cell = { v: 0 };
                    var cell_ref = XLSX.utils.encode_cell({ c: column++, r: 0 });
                    cell.t = 'n';
                    ws[cell_ref] = cell;
                });
            });
            if (phaseStart) {
                ws['!merges'].push({ s: { c: phaseStart, r: 0 }, e: { c: column - 1, r: 0 } });
            }
            column = 3;
            _.each(alts, function (alt) {
                var altData = altsData[alt];
                var altModel = Alternative.find({ id: alt });
                var whenObservedNames = Object.getOwnPropertyNames(altData);
                _.each(whenObservedNames, function (whenObs) {
                    var cell = { v: altModel.get('name') + "#" + whenObs };
                    var cell_ref = XLSX.utils.encode_cell({ c: column++, r: 1 });
                    cell.t = 's';
                    ws[cell_ref] = cell;
                    ws['!cols'].push({ wch: 20 });

                    var cell = { v: altModel.get('name') + "#" + whenObs + "#" + "Unit" };
                    var cell_ref = XLSX.utils.encode_cell({ c: column++, r: 1 });
                    cell.t = 's';
                    ws[cell_ref] = cell;
                    ws['!cols'].push({ wch: 20 });
                });
            });
            wb.Sheets[ws_name] = ws;
            return ws;
        }

        addWorksheetCell(XLSX, ws, R, C, value) {
            var cell = { v: value };
            if (cell.v == null)
                return;
            var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

            if (!isNaN(parseFloat(cell.v))) {
                cell.t = 'n';
                cell.v = parseFloat(cell.v);
            } else if (typeof cell.v === 'boolean') {
                cell.t = 'b';
            } else if (cell.v instanceof Date) {
                cell.t = 'n'; cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            } else {
                cell.t = 's';
            }
            ws[cell_ref] = cell;
        }


        generateXLSXData(csvDataObj,XLSX) {
            var self = this;
            var values = csvDataObj['Values'];
            var valueSuffixes = {};
            var wb = this.createWorkbook(XLSX);
            _.each(values, function (value, index) {
                var isUnit = false;
                if (value.indexOf('#Unit') > 0) {
                    isUnit = true;
                    value = value.substr(0, value.indexOf('#Unit'));
                }
                var mesChar = MeasuredCharacteristic.find({ id: value });

                var suffix = value.substr(value.lastIndexOf('@') + 1) + '#' + mesChar.get('name');
                if (valueSuffixes[suffix + (isUnit ? '#Unit' : '')]) {
                    return;
                }
                valueSuffixes[suffix] = { id: value };
               // var child = MeasuredCharacteristic.find({ id: value });
                var valueName;
                var parent = mesChar;
                while (parent && parent.id !== oldParent) {
                    var oldParent = parent.id;
                    valueName = parent.get('name') + (valueName ? (">" + valueName) : "");
                    parent = parent.getParent();
                }
                valueSuffixes[suffix]['namePath'] = valueName;
                if (isUnit) {
                    valueSuffixes[suffix]['namePathUnit'] = valueName + "#Unit";
                }
            })
            var valueKeys = Object.getOwnPropertyNames(valueSuffixes);
            var altsData = csvDataObj['altData'];

            var ws = this.createWorkbookSheetHeader(wb, XLSX, altsData);
            if (!altsData) {
                return wb;
            }
            var row = 1;
            var col = 0;
            var range = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };
            _.each(valueKeys, function (valueKey) {
                var alts = Object.getOwnPropertyNames(altsData);
                row++;
                if (range.e.r < row) range.e.r = row;
                col = 0;
                self.addWorksheetCell(XLSX, ws, row, col++, valueSuffixes[valueKey]['namePath']);
                var mc = MeasuredCharacteristic.find({ id: valueSuffixes[valueKey].id });
                var mesurandDetails = mc.getMeasurandDetails();
                var mesCharName = mc.get('name');
                self.addWorksheetCell(XLSX, ws, row, col++, mesurandDetails.measurand.get('name'));
                var role = mesurandDetails.role;
                if (role === 'valueMeasurement') {
                    role = 'Value Measurement';
                } else if (role === 'recipientOpinion') {
                    role = 'Recipient Opinion';
                } else if (role === 'satisfactionLevel') {
                    role = 'Satisfaction Level'
                } else if (role === 'percentageWeight') {
                    role = 'Percentage Weight';
                }
                self.addWorksheetCell(XLSX, ws, row, col, role);
                _.each(alts, function (alt) {
                    var altData = altsData[alt];
                    var whenObservedNames = Object.getOwnPropertyNames(altData);
                    _.each(whenObservedNames, function (whenObs) {
                        col++;
                        var whenObservedData = altData[whenObs];
                        var mesCharPName = valueKey;
                        var mesCharId = mesCharPName.substr(0, mesCharPName.lastIndexOf('#'));
                        var mest = whenObservedData[mesCharId];
                        if (mest && mest.get('measuredCharacteristic').get('name') === mesCharName) {
                            var ValueStr = mest.get('value') ? mest.get('value') : mest.get('symbol');
                            self.addWorksheetCell(XLSX, ws, row, col, ValueStr);
                            if (mest.get('value')) {
                                col++;
                                var ValueStr = mest.get('observedMeasure').get('measure').get('unit').get('name');
                                self.addWorksheetCell(XLSX, ws, row, col, ValueStr);
                            } else if(mest.get('observedMeasure').get('measure').get('unit')) {
                                // If the value has only unit
                                col++;
                                var ValueStr = mest.get('observedMeasure').get('measure').get('unit').get('name');
                                self.addWorksheetCell(XLSX, ws, row, col, ValueStr);
                            } else {
                                col++;
                            }
                        } else {
                            col = col + 1;
                        }
                        if (range.e.c < col) range.e.c = col;
                    })
                });
            })

            ws['!ref'] = XLSX.utils.encode_range(range);
            return wb;
        }
        generateTemplate(csvDataObj, plan) {
            var values = csvDataObj['Values'];
            var csvObj = [];
            var valueSuffixes = {};
			var altsData = csvDataObj['altData'];
            if (!altsData) {
                csvObj.push({});
                return csvObj;
            }
            _.each(values, function (value, index) {
                var isUnit = false;
                if (value.indexOf('#Unit') > 0) {
                    isUnit = true;
                    value = value.substr(0, value.indexOf('#Unit'));
                }
                var mesChar = MeasuredCharacteristic.find({ id: value });

                var suffix = value.substr(value.lastIndexOf('@') + 1) + '#' + mesChar.get('name');
                if (valueSuffixes[suffix + (isUnit ? '#Unit' : '')]) {
                    return;
                }
                valueSuffixes[suffix] = { id: value };
                //var child = MeasuredCharacteristic.find({ id: value });
                var valueName;
                var parent = mesChar;
                while (parent && parent.id !== oldParent) {
                    var oldParent = parent.id;
                    valueName = parent.get('name') + (valueName ? (">" + valueName) : "");
                    parent = parent.getParent();
                }
                valueSuffixes[suffix]['namePath'] = valueName;
                if (isUnit) {
                    valueSuffixes[suffix]['namePathUnit'] = valueName + "#Unit";
                }
           // });
           // var valueKeys = Object.getOwnPropertyNames(valueSuffixes);
            

          //  _.each(valueKeys, function (valueKey) {
				var valueKey = suffix;
                var alts = Object.getOwnPropertyNames(altsData);
                _.each(alts, function (alt) {
                    var altObj = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: alt });
                    var templateId = altObj.get('templateId');
                    var altData = altsData[alt];
                    var altObs;
                    var whenObservedNames = Object.getOwnPropertyNames(altData);
                    _.each(whenObservedNames, function (whenObs) {
                        var whenObservedData = altData[whenObs];
                        var altModel = Alternative.find({ id: alt });
                        var mesCharPName = valueKey;
                        
                        var dataStr = null, data = null;	
                        if (valueSuffixes[valueKey].id.indexOf(window.plansKey) >= 0) {
                            altObs = _.find(altModel.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models, function (a) { return a.get('name') === whenObs; });
                        } else {
                            var scenarioObj = Backbone.Relational.store.getObjectByName('vdml.Scenario').find({ id: altModel.get('designScenario').models[0].get('scenario') });
                            var altBmobsModels = scenarioObj.get('contextObservation').models;
                            altObs = _.find(altBmobsModels, function (a) { return a.get('name') === whenObs; });
                        }
                        var context = null;
                        if (altObs) {
                            var dataRef = altObs.get('dataReference');
                            context = altObs.getContext();
                        }
                        //                    }
                       
						function addcsvData(data){
							var mesCharId = mesCharPName.substr(0, mesCharPName.lastIndexOf('#'));
							var mest = whenObservedData[mesCharId];
							var mesCharName = valueSuffixes[valueKey]['namePath'].substr(valueSuffixes[valueKey]['namePath'].lastIndexOf('>') + 1);
							if (mest && mest.get('measuredCharacteristic').get('name') === mesCharName || data) {
								var csvRow = {};
								csvRow['ValueName'] = valueSuffixes[valueKey]['namePath'];

								csvRow['Phase>Alternative'] = altModel.getParent().get('name') + ">" + altModel.get('name');
								csvRow['Scenario'] = whenObs;
                                csvRow['ScenarioId'] = context ? context.get('id') : null;
								csvRow['Time Stamp(MM/DD/YYYY,HH:mm:ss)'] = !isNaN(altObs ? altObs.get('whenObserved') : '') ? new Date(altObs.get('whenObserved')).toLocaleString() : '';
								if (data) {
									var ValueStr = data[value];//data[valueSuffixes[valueKey].id];
								} else {
									ValueStr = (mest.get('value') != undefined) ? mest.get('value') : mest.get('symbol');
								}
								csvRow['Value'] = ValueStr;
								if (data && (ValueStr !== undefined) && valueSuffixes[valueKey].id) {
									var vm = Backbone.Relational.store.getObjectByName('vdml.MeasuredCharacteristic').find({ id: valueSuffixes[valueKey].id});//valueSuffixes[valueKey].id });
									csvRow['Unit'] = vm.get('measurement').at(0) ? vm.get('measurement').at(0).get('observedMeasure').get('measure').get('unit').get('name') : null;
									csvRow['ValueType'] = vm.get('measurement').at(0) ? ((vm.get('measurement').at(0).get('type') === 'smm_CollectiveMeasurement') ? 'Calculated' : 'Input') : '';
                                    if (vm.get('measurement').at(0).get('type') === 'smm_CollectiveMeasurement') {
                                        csvRow['relations'] = vm.get('measurement').at(0).getRelationDetails();
									} else {
										csvRow['relations'] = {};
									}
									csvRow['id'] = vm.id;
                                    //csvRow['id'] = vm.getParent().id;//vm is mc here
									csvRow['Formula'] = {};
								} else if (mest && mest.get('value') !== undefined) {
                                    var unit = mest.get('observedMeasure').get('measure').get('unit');
									csvRow['Unit'] = unit?unit.get('name'):'';
									csvRow['ValueType'] = mest.get('type') === 'smm_CollectiveMeasurement' ? 'Calculated' : 'Input';
                                    if (mest.get('type') === 'smm_CollectiveMeasurement') {
                                        csvRow['Formula'] = mest.getRelationDetails();
									} else {
										csvRow['Formula'] = {};
									}
									csvRow['id'] = mest.id;
                                    //csvRow['id'] = vm.getParent().id;
								} else if (mest) {
									csvRow['Formula'] = {};
									csvRow['id'] = mest.id;
                                    //csvRow['id'] = vm.getParent().id;
								}
                                if(templateId){
                                    csvRow['templateId'] = templateId;
                                }
								csvObj.push(csvRow);
							}
							 /*else {
								csvRow['Value'] = "";
								if (valueSuffixes[valueKey]['namePathUnit']) {
									csvRow['Unit'] = "";
								}
							}*/
						}
						if (dataRef) {
                            CodeContainer.getBlobInstanceWithId(dataRef, plan, function (codeContainer) {
                                if (codeContainer) {
                                    dataStr = codeContainer.get('code');
                                    data = dataStr ? JSON.parse(dataStr) : null;
									addcsvData(data);
                                } else {
                                    console.log('Unable to load selected Package');
                                }
                            });
                        } else {
							addcsvData(data);
						}
                    });
                });
            });
            if (csvObj.length === 0) {
                csvObj.push({});
            }
            return csvObj;
        };
        /*getBusinessIndicatorLibraryForMeasurement(obsName, mest, lib) {
            var self = this;
            if (lib) {
                return lib;
            }
           
            var obsMeasures = mest.get('observedMeasure');
            var observation = obsMeasures.get('observation');
            //var whenObserved = observation.get('whenObserved');
            var whenObservedGuid = observation.get('whenObservedGuid');

            var packageName = obsName + " Package";
            //packageName = window.utils.getUniquePackageName(packageName, packageName, self.currentAlternative, "vdml_ValueDeliveryModel");
            
            
                    var docId = window.guidGenerator();
                    vdm = new ValueDeliveryModel({
                        'name': packageName,
                        'description': packageName,
                        'id': docId,
                        'label': "Base"
                    });
                    DataManager.getDataManager().get('currentWorkspace').save();
            var lib = vdm.createDefaultMetricModel().getOrCreateBusinessIndicatorLibrary(obsName, observation.get('whenObservedGuid'));
            return lib;

            //return observation.get('smmModel').getOrCreateMeasurementLibrary(obsName, observation.get('whenObservedGuid'));
        };
        createOrUpdateBusinessIndicatorLibrary(csvDataObj, plan) {
            var self = this;
            var values = csvDataObj['Values'];
            var valueSuffixes = {};
            var lib = null;
            var businessIndicatorCatjson = {};
            var mestMapping = {}; // to map mest ids to measurement element ids.
            var mestDefs = []; //mest defs that need to have expressions updated.
            var libjson = [];
            _.each(values, function (value, index) {
                var isUnit = false;
                if (value.indexOf('#Unit') > 0) {
                    isUnit = true;
                    value = value.substr(0, value.indexOf('#Unit'));
                }
                var mesChar = MeasuredCharacteristic.find({ id: value });

                var suffix = value.substr(value.lastIndexOf('@') + 1) + '#' + mesChar.get('name');
                if (valueSuffixes[suffix + (isUnit ? '#Unit' : '')]) {
                    return;
                }
                valueSuffixes[suffix] = { id: value };
                //var child = MeasuredCharacteristic.find({ id: value });
                var valueName;
                var parent = mesChar;
                while (parent && parent.id !== oldParent) {
                    var oldParent = parent.id;
                    valueName = parent.get('name') + (valueName ? (">" + valueName) : "");
                    parent = parent.getParent();
                }
                valueSuffixes[suffix]['namePath'] = valueName;
                if (isUnit) {
                    valueSuffixes[suffix]['namePathUnit'] = valueName + "#Unit";
                }
            });
            var valueKeys = Object.getOwnPropertyNames(valueSuffixes);
            var altsData = csvDataObj['altData'];
            if (!altsData) {
                return;
            }
            var planId = self.id.substr(self.id.lastIndexOf('@'));
            var commonAltId = planId + "-Common@";
            var previousWorkspace = DataManager.getDataManager().getWorkspace();
            DataManager.getDataManager().getWorkspaceDataWithId(commonAltId, function (wsData) {
                var commonWorkspace = wsData.get('workspace');
                DataManager.getDataManager().setWorkspace(commonWorkspace, function () {
					_.each(valueKeys, function (valueKey) {
						var alts = Object.getOwnPropertyNames(altsData);
						_.each(alts, function (alt) {
							var altData = altsData[alt];
							var altObs;
							var whenObservedNames = Object.getOwnPropertyNames(altData);
							_.each(whenObservedNames, function (whenObs) {
								var whenObservedData = altData[whenObs];
								var altModel = Alternative.find({ id: alt });
								var mesCharPName = valueKey;
								var mesCharId = mesCharPName.substr(0, mesCharPName.indexOf('#'));
								var mest = whenObservedData[mesCharId];
								var dataStr, data;	
								if (valueSuffixes[valueKey].id.indexOf(window.plansKey) >= 0) {
									altObs = _.find(altModel.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models, function (a) { return a.get('name') === whenObs; });
								} else {
									var scenarioObj = Backbone.Relational.store.getObjectByName('vdml.Scenario').find({ id: altModel.get('designScenario').models[0].get('scenario') });
									var altBmobsModels = scenarioObj.get('contextObservation').models;
									altObs = _.find(altBmobsModels, function (a) { return a.get('name') === whenObs; });
								}
								if (altObs) {
									var dataRef = altObs.get('dataReference');
								}
								//                    }
								if (dataRef) {
									CodeContainer.getBlobInstanceWithId(dataRef, plan, function (codeContainer) {
										if (codeContainer) {
											dataStr = codeContainer.get('code');
											data = dataStr ? JSON.parse(dataStr) : null;
										} else {
											console.log('Unable to load selected Package');
										}
									});
								}
                                var mesCharName = valueSuffixes[valueKey]['namePath'].substr(valueSuffixes[valueKey]['namePath'].lastIndexOf('>') + 1);
								if (mest && mest.get('measuredCharacteristic').get('name') === mesCharName || data) {
									if (!libjson[whenObs]) {
										lib = self.getBusinessIndicatorLibraryForMeasurement(whenObs, mest);
										libjson[whenObs] = lib;
									}
									else {
										lib = libjson[whenObs];
									  //  lib = Backbone.Relational.store.getObjectByName('smm.BusinessIndicatorLibrary').find({ id: libjson[whenObs].id });
                                
									}
									if (!businessIndicatorCatjson[altModel.id  + lib.get('id')] ) {
										var categoryName = altModel.getParent().get('name') + " " + altModel.get('name');
										var mlId = DataManager.getDataManager().guidGeneratorByOwner(lib);

										var businessIndicatorCat = new BusinessIndicatorCategory({ id: mlId, name: categoryName, description: categoryName, version: lib.get('version'), businessIndicatorLibraryElementOwner: lib });
										lib.get('element').add(businessIndicatorCat);
										businessIndicatorCatjson[altModel.id + lib.get('id')] = businessIndicatorCat;
									}
									else {
										businessIndicatorCat = businessIndicatorCatjson[altModel.id + lib.get('id')];
										//  var businessIndicatorCat = Backbone.Relational.store.getObjectByName('smm.BusinessIndicatorCategory').find({ id: businessIndicatorCatjson[altModel.id + lib.get('id')].id });
									}

									var mlId = DataManager.getDataManager().guidGeneratorByOwner(lib);
									measurementDefinition = BusinessIndicatorDefinition.getInstance(mest.get('name'), mest.get('name'),lib);
									// measurementDefinition = new BusinessIndicatorDefinition({ id: mlId, name: mest.get('name'), businessIndicatorLibraryElementOwner: lib });
										measurementDefinition.set('valueName', valueSuffixes[valueKey]['namePath']);
									// businessIndicatorCat.get('child').add(measurementDefinition);
									//  }
                            
									mestMapping[mest.id] = measurementDefinition.id;
									mestDefs.push(measurementDefinition);
									measurementDefinition.set('name', mest.get('name'));
									var csvRow = {};
										measurementDefinition.set('phaseAlternative', altModel.getParent().get('name') + ">" + altModel.get('name'));
									// measurementDefinition.set('scenario', whenObs);
									measurementDefinition.set('timeStamp', !isNaN(altObs ? altObs.get('whenObserved') : '') ? new Date(altObs.get('whenObserved')).toLocaleString() : '');
									if (data) {
										var ValueStr = data[valueSuffixes[valueKey].id];
									} else {
										ValueStr = mest.get('value') ? mest.get('value') : mest.get('symbol');
									}
									measurementDefinition.set('value', ValueStr);
									measurementDefinition.set('valueType', mest.get('type'));
									if (data && data[valueSuffixes[valueKey].id]) {
										var vm = Backbone.Relational.store.getObjectByName('vdml.MeasuredCharacteristic').find({ id: valueSuffixes[valueKey].id });
										measurementDefinition.set('unit', vm.get('measurement').at(0) ? vm.get('measurement').at(0).get('observedMeasure').get('measure').get('unit').get('name') : null);
									} else if (mest && mest.get('value')) {
										measurementDefinition.set('unit', mest.get('observedMeasure').get('measure').get('unit').get('name'));
									}
									if (!mest.get('expression') && mest.getRelationDetails) {
										measurementDefinition.set('expression', mest.getRelationDetails()['expression']);
									} else {
										measurementDefinition.set('expression', mest.get('expression'));
									}
								}
							});
						});
					});
                    for (var i = 0; i < mestDefs.length; i++) {
                        var def = mestDefs[i];
                        if (def.get('expression') && def.get('expression') !== "") {
                            def.set('expression', self.getMappedExpression(def.get('expression'), mestMapping))
                        }
                     }
                    DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
                       
                    });
                });
            });
        };*/
        /*getMappedExpression(expression, mestMapping) {
            var symbols = expression.match(/@[-@0-9a-zA-Z]+[-#0-9a-zA-Z]+/g);
            if (!symbols) {
                return expression;
            }
            for (var i = 0; i < symbols.length; i++) {
                var mappedSymbol = mestMapping[symbols[i]];
                if (mappedSymbol) {
                    expression = expression.replace(new RegExp(symbols[i], 'g'), mappedSymbol);
                }
            }
            return expression;
        };*/
        generateCSVData(csvDataObj, plan) {
            var values = csvDataObj['Values'];
            var csvObj = [];
            var valueSuffixes = {};
            _.each(values, function (value, index) {
                var isUnit = false;
                if (value.indexOf('#Unit') > 0) {
                    isUnit = true;
                    value = value.substr(0, value.indexOf('#Unit'));
                }
				
				var mesChar = MeasuredCharacteristic.find({ id: value });

				var suffix = value.substr(value.lastIndexOf('@') + 1) + '#' + mesChar.get('name');
				if (valueSuffixes[suffix + (isUnit ? '#Unit' : '')]) {
					return;
				}
				valueSuffixes[suffix] = { id: value };
				//var child = MeasuredCharacteristic.find({ id: value });
				var valueName;
				var parent = mesChar;
				while (parent && parent.id !== oldParent) {
					var oldParent = parent.id;
					valueName = parent.get('name') + (valueName ? (">" + valueName) : "");
					parent = parent.getParent();
				}
				valueSuffixes[suffix]['namePath'] = valueName;    
				if (isUnit) {
                    valueSuffixes[suffix]['namePathUnit'] = valueName + "#Unit";
                }
            })
            var valueKeys = Object.getOwnPropertyNames(valueSuffixes);
            var altsData = csvDataObj['altData'];
            if (!altsData) {
                csvObj.push({});
                return csvObj;
            }

            _.each(valueKeys, function (valueKey) {
                var alts = Object.getOwnPropertyNames(altsData);
                _.each(alts, function (alt) {
                    var altData = altsData[alt];
                    var altObs;
                    var date = new Date();
                    var whenObservedNames = Object.getOwnPropertyNames(altData);
                    _.each(whenObservedNames, function (whenObs) {
                        var whenObservedData = altData[whenObs];
                        var altModel = Alternative.find({ id: alt });
                        var mesCharPName = valueKey;
                        var mesCharId = mesCharPName.substr(0, mesCharPName.lastIndexOf('#'));
                        var dataStr, data;
                        //var obsName = whenObs.substring(0,whenObs.indexOf('['));
                        //var timeStamp = whenObs.substring(obsName.length+1,whenObs.length-1);
                        //                   if(!mest){		
                        if (valueSuffixes[valueKey].id.indexOf(window.plansKey) >= 0) {
                            altObs = _.find(altModel.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models, function (a) { return a.get('name') === whenObs; });
                        } else {
                            var scenarioObj = Backbone.Relational.store.getObjectByName('vdml.Scenario').find({ id: altModel.get('designScenario').models[0].get('scenario') });
                            var altBmobsModels = scenarioObj.get('contextObservation').models;
                            altObs = _.find(altBmobsModels, function (a) { return a.get('name') === whenObs; });
                        }
                        if (altObs) {
                            var dataRef = altObs.get('dataReference');
                        }
                        if (dataRef) {
                            CodeContainer.getBlobInstanceWithId(dataRef, plan, function (codeContainer) {
                                if (codeContainer) {
                                    dataStr = codeContainer.get('code');
                                    data = dataStr ? JSON.parse(dataStr) : null;
                                } else {
                                    console.log('Unable to load selected Package');
                                }
                            });
                        }
						var mest = whenObservedData[mesCharId];
                        var mesCharName = valueSuffixes[valueKey]['namePath'].substr(valueSuffixes[valueKey]['namePath'].lastIndexOf('>') + 1);
                        if (mest && mest.get('measuredCharacteristic').get('name') === mesCharName || data) {
                            var csvRow = {};
                            csvRow['ValueName'] = valueSuffixes[valueKey]['namePath'];
                            csvRow['Phase>Alternative'] = altModel.getParent().get('name') + ">" + altModel.get('name');
                            //csvRow['Scenario'] = whenObs;
                            csvRow['Time Stamp(MM/DD/YYYY,HH:mm:ss)'] = !isNaN(altObs ? altObs.get('whenObserved') : '') ? new Date(altObs.get('whenObserved')).toLocaleString() : '';
                            if (data) {
                                var ValueStr = data[valueSuffixes[valueKey].id];
                            } else {
                                ValueStr = mest.get('value') ? mest.get('value') : mest.get('symbol');
                            }
                            csvRow['Scenario-'+whenObs] = ValueStr;
                            if (data && data[valueSuffixes[valueKey].id]) {
                                var vm = Backbone.Relational.store.getObjectByName('vdml.MeasuredCharacteristic').find({ id: valueSuffixes[valueKey].id });
                                csvRow['Unit'] = vm.get('measurement').at(0) ? vm.get('measurement').at(0).get('observedMeasure').get('measure').get('unit').get('name') : null;
                                csvRow['Value Type'] = vm.get('measurement').at(0) ? ((vm.get('measurement').at(0).get('type') === 'smm_CollectiveMeasurement') ? 'Calculated' : 'Input') : '';
                            } else if (mest) {
                                var unit = mest.get('observedMeasure').get('measure').get('unit');
                                csvRow['Unit'] = unit?unit.get('name'):'';
                                csvRow['Value Type'] = mest.get('type') === 'smm_CollectiveMeasurement' ? 'Calculated' : 'Input';
                            }
                            csvObj.push(csvRow);
                        }
                        /*else {
                            csvRow['Value'] = "";
                            if (valueSuffixes[valueKey]['namePathUnit']) {
                                csvRow['Unit'] = "";
                            }
                        }*/

                    })
                });
            })
            if (csvObj.length === 0) {
                csvObj.push({});
            }
            return csvObj;
        }

        getTimeLessObservation(context) {
            while (context.get('parentContext')) {
                context = context.get('parentContext');
            }
            var observations = context.get('contextObservation');
            if (!observations) {
                observations = context.get('objectiveObservation');
            }
            for (var i = 0; i < observations.length; i++) {
                var obs = observations.at(i);
                var whenObs = obs.get('whenObserved');
                if (!whenObs) {
                    return obs;
                }
            }
        }
        createNewObservation (obsName, callback, timestamp, observationsList) {
            var self = this;
            var updatedObservations = {};
            var obsTimeStampId = timestamp ? timestamp : window.guidGenerator();
            var snapshotId = window.uuidGenerator();
            obsTimeStampId = typeof obsTimeStampId === "number" ? obsTimeStampId : obsTimeStampId.substr(obsTimeStampId.lastIndexOf('@') + 1);
            var createdObsList = [];
            function constructObservationsData(observation, cloneObsId) {
                var obsMeasures = observation.get('observedMeasures');
                obsMeasures.each(function (obsMes) {
                    var mest = obsMes.get('measurements').at(0);
                    var mesChar = mest.get('measuredCharacteristic');
                    if (mest.get('value') && mesChar) {
                        updatedObservations[cloneObsId][mesChar.id] = mest.get('value');
                    } else if (mest.get('symbol') && mesChar) {
                        updatedObservations[cloneObsId][mesChar.id] = mest.get('symbol');
                    }
                });
            }

            function observationCreation(observations) {
                var obsCreated = false;
                async.eachSeries(observations.models, function (obsModel, observationHandled) {
                    if (obsModel.id.indexOf(window.plansKey) >= 0) {
                        var obs = Observation.find({ id: obsModel.id });
                        if (!obs || (obs.get('whenObserved') && obs.get('whenObserved') !== "")) {
                            observationHandled();
                            return;
                        }
                        if (obs.getNestedParent().id !== self.id || !obs.get('phaseObjectiveSet')) {
                            observationHandled();
                            return;
                        }
                        var alt = obs.get('phaseObjectiveSet').get('phaseObjectiveSetOwner');
                        /* if(obsName) {
                            obsName = obsName.substring(obsName.indexOf('-') + 1);
                        } */
                        var alternativeObs = obs.get('smmModel').createObservation(obsName, obsTimeStampId, snapshotId, alt.id);
                        createdObsList.push(alternativeObs);
                        var altScenario = alt.getOrCreatePhaseObjectiveSet();
                        var previousLen = altScenario.get('objectiveObservation').length;
                        altScenario.get('objectiveObservation').add(alternativeObs);
                        if(!obsCreated && altScenario.get('objectiveObservation').length > previousLen){
                            obsCreated = true;
                        }
                        var oldObs = self.getTimeLessObservation(altScenario);
                        updatedObservations[alternativeObs.id] = {};
                        constructObservationsData(oldObs, alternativeObs.id);
                        observationHandled();
                    } else {
                        /*var altId = DataManager.getDataManager().getRepositoryId(obsModel.id);
                        if (altId.indexOf('Common@') >= 0) {
                            observationHandled();
                            return;
                        }
                        var version = DataManager.getDataManager().get('currentVDMVersion');
                        var vdmStore = DataManager.getDataManager().getVDMStore(altId);
                        DataManager.getDataManager().fetchDocumentFromPackage(obsModel.get('parent'), "appbo/vdml/ValueDeliveryModel", version, obsModel.id, "appbo/smm/Observation", vdmStore, {
                            success: function (oldObs) {*/
                                var oldObs = Observation.find({ id: obsModel.id });
                                if (oldObs.get('whenObserved') && oldObs.get('whenObserved') !== "") {
                                    observationHandled();
                                    return;
                                }

                                var context = oldObs.getContext();
                                while (context.get('parentContext')) {
                                    context = context.get('parentContext');
                                }
                                var scenarioProxy = self.getScenarioProxy(context.id);
                                if (!scenarioProxy) {
                                    observationHandled();
                                    return;
                                }
                                var obsAlt = scenarioProxy.get('designScenarioOwner');
                                /* if(obsName) {
                                    obsName = obsName.substring(obsName.indexOf('-') + 1);
                                } */
                                obs = oldObs.getNestedParent().get('defaultMetricsModel').createObservation(obsName, obsTimeStampId, snapshotId, obsAlt.id);
                                createdObsList.push(obs);
                                var previousLen = context.get('contextObservation').length;
                                context.get('contextObservation').add(obs);
                                if(!obsCreated && context.get('contextObservation').length > previousLen){
                                    obsCreated = true;
                                }
                                updatedObservations[obs.id] = {};
                                constructObservationsData(oldObs, obs.id);
                                observationHandled();
                            /*},
                            error: function (error) {
                                console.log('Unable to load selected Package');
                                observationHandled();
                            }
                        });*/
                    }

                }, function () {
                    if(obsCreated){
                        var obsKeys = Object.getOwnPropertyNames(updatedObservations);
                        DataManager.getDataManager().set('newObservations', obsKeys);
                        if (timestamp != "" && !isNaN(timestamp)) {
                            DataManager.getDataManager().set(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING, true);
                        }
                        async.each(obsKeys, function (key, handledKey) {
                            var obsData = updatedObservations[key];
                            var obs = Observation.find({ id: key });
                            var oldObs = self.getTimeLessObservation(obs.getContext());
                            oldObs.clone(obsData, obs, function (clonedObs) {
                                handledKey();
                            });
                        }, function () {
                            DataManager.getDataManager().set(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING, false);
                            DataManager.getDataManager().unset('newObservations');
                            callback(obsTimeStampId, createdObsList);
                        });
                    } else {
                        callback(obsTimeStampId, createdObsList);
                    }
                });
            }
            if (!observationsList) {
                self.getAllDocumentsCollectionOfType(this, "smm_Observation", function (observations) {
                    observationCreation(observations);
                }, null, null, null, "vbc:cmof_EObject-name", null, null, true, true, true);
            } else {
                observationCreation(observationsList);
            }
        };

        deleteScenario(scenarioList, callback) {
            var self = this;
            if (scenarioList.length > 0) {
				DataManager.getDataManager().invokeValueUpdates(function(){	
					var plan = DataManager.getDataManager().get('currentPlan');
					plan.getAllDocumentsCollectionOfType(plan, "smm_Observation", function (results) {
						var obsList = _.filter(results.models, function (res) { if (res.get('id').indexOf('Common') == -1) { return res; } });
						async.eachSeries(obsList, function (obs, obsUpdated) {
							var isPlanDoc = obs.id.indexOf(window.plansKey) >= 0;
							if (isPlanDoc) {
								var obsModel = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: obs.id });
								if (obsModel && obsModel.getNestedParent().id !== plan.id) {
									obsUpdated();
									return;
								}
								if (obsModel && obsModel.get('whenObserved') && scenarioList.indexOf(''+obsModel.get('whenObserved')) > -1) {
									var dataRef = obsModel.get('dataReference');
									if (dataRef) {
										CodeContainer.getBlobInstanceWithId(dataRef, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
											if (codeContainer) {
												DataManager.getDataManager().removeInitializedPackage(codeContainer.get('id'));
                                                obsModel.getNestedParent().get('dependentPackage').remove(codeContainer);
												codeContainer.destroy();
												DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
											} else {
												console.log('Unable to load selected Package');
												return;
											}
										});
									}
									obsModel.destroy();
									setTimeout(obsUpdated, 100);
								} else {
									obsUpdated();
								}
							} else {
								var altId = DataManager.getDataManager().getRepositoryId(obs.id);
								var vdmStore = DataManager.getDataManager().getVDMStore(altId);
								DataManager.getDataManager().fetchDocumentFromPackage(obs.get('parent'), "appbo/vdml/ValueDeliveryModel", obs.get('version'), obs.id, "appbo/smm/Observation", vdmStore, {
									success: function (obsModel) {
										if (obsModel && obsModel.get('whenObserved') && scenarioList.indexOf(''+obsModel.get('whenObserved')) > -1) {
											dataRef = obsModel.get('dataReference');
											if (dataRef) {
												CodeContainer.getBlobInstanceWithId(dataRef, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
													if (codeContainer) {
														DataManager.getDataManager().removeInitializedPackage(codeContainer.get('id'));
							                            obsModel.getNestedParent().get('dependentPackage').remove(codeContainer);
														codeContainer.destroy();
														DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
													} else {
														console.log('Unable to load selected Package');
														return;
													}
												});
											}
											obsModel.destroy();
											setTimeout(obsUpdated, 100);
										} else {
											obsUpdated();
										}

									},
									error: function (error) {
										console.log('Unable to load Package for updating observation name');
										obsUpdated();
									}
								});
							}
						}, function () {
							DataManager.getDataManager().calculateValuesCache = {};
							callback();
						});
					}, null, null, null, "vbc:cmof_EObject-name", null, null, true, null, true);
				});
            } else {
                callback();
            }
        };

        static getDialogViewProperties(type) { 
            if (type === "PlanDetails") {
                return {
                    templatePath: "views/transformation/views/properties/PlanDetailsTemplate.html",
                    templateName: "PlanDetailsTemplate",
                    viewTypeStr: "appviews/transformation/views/properties/PlanDetailsViewModel",
                    tabId: "PlanDetailsView",
                    tabName: "Plan"
                }
            } /*else if (type === "CreatePlan") {
                return {
                    templatePath: "views/transformation/views/properties/CreatePlanTemplate.html",
                    templateName: "CreatePlanTemplate",
                    viewTypeStr: "appviews/transformation/views/properties/CreatePlanViewModel",
                    tabId: "CreatePlanView",
                    tabName: "CreatePlan"
                }
            }*/
            else if (type === "ImportFromCloud") {
                return {
                    templatePath: "views/transformation/views/properties/ImportFromCloudTemplate.html",
                    templateName: "ImportFromCloudTemplate",
                    viewTypeStr: "appviews/transformation/views/properties/ImportFromCloudViewModel",
                    tabId: "ImportFromCloudView",
                    tabName: "ImportFromCloud"
                }
            }
            /*else if (type === "ImportFromOldCloud") {
                return {
                    templatePath: "views/transformation/views/properties/ImportFromOldCloudTemplate.html",
                    templateName: "ImportFromOldCloudTemplate",
                    viewTypeStr: "appviews/transformation/views/properties/ImportFromOldCloudViewModel",
                    tabId: "ImportFromOldCloudView",
                    tabName: "ImportFromOldCloud"
                }
            }*/
            else if (type === "ImportFromCloud2") {
                return {
                    templatePath: "views/transformation/views/properties/CloudTemplate.html",
                    templateName: "CloudTemplate",
                    viewTypeStr: "appviews/transformation/views/properties/CloudViewModel",
                    tabId: "CloudView",
                    tabName: "Cloud"
                }
            }
            else if (type === "AdvancedPlanDetails") {
                return {
                    templatePath: "views/transformation/views/properties/AdvancedPlanDetailsTemplate.html",
                    templateName: "AdvancedPlanDetailsTemplate",
                    viewTypeStr: "appviews/transformation/views/properties/AdvancedPlanDetailsViewModel",
                    tabId: "AdvancedPlanDetailsView",
                    tabName: "AdvancedPlanDetails"
                }
            }
            else if (type === "PlanTickets") {
                return {
                    templatePath: "views/tickets/views/properties/ColloborationPropertiesTemplate.html",
                    templateName: "ColloborationPropertiesTemplate",
                    viewTypeStr: "appviews/tickets/views/properties/ColloborationViewModel",
                    tabId: "ColloborationView",
                    tabName: "Colloboration"
                }
            }
            else if (type === "explorerModelJson") {
                return {
                    templatePath: "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
                    templateName: "explorerModelJsonTemplate",
                    viewTypeStr: "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
                    tabId : "explorerModelJsonView",
                    tabName: "explorerModelJson"
                }
            }
            else if (type === "PlanValidation") {
                return {
                    templatePath: "views/transformation/views/correction/PlanValidationTemplate.html",
                    templateName: "PlanValidationTemplate",
                    viewTypeStr: "appviews/transformation/views/correction/PlanValidationViewModel",
                    tabId: "PlanValidationView",
                    tabName: "PlanValidation"
                }
            }
            else if (type === "PlansView") {
                return {
                    templatePath: "views/transformation/views/properties/ValuesTemplate.html",
                    templateName: "ValuesTemplate",
                    viewTypeStr: "appviews/transformation/views/properties/ValuesViewModel",
                    tabId: "ValuesView",
                    tabName: "Values"

                }
            }
            else {
                return {
                    templatePath: "views/transformation/views/properties/PlanPropertiesTemplate.html",
                    templateName: "PlanPropertiesTemplate",
                    viewTypeStr: "appviews/transformation/views/properties/PlanViewModel",
                    tabId: "PlanView",
                    tabName: "Plan"
                }
            }
        }

        handlePlanNameChange(modal, newName) {
            var self = this;
            var currentWorkspacePlan = DataManager.getDataManager().get('currentWorkspace').get('packageReference').findWhere({ 'beepReference': self });
            if (currentWorkspacePlan) {
                currentWorkspacePlan.set('name', newName);
                //currentWorkspacePlan.set('description',newDescription);
            }
            if (self.get('planCriterionSet')) {
                self.get('planCriterionSet').set({'name': newName + ' Plan Criterion Set','description': newName + ' Plan Criterion Set'});
                //self.get('planCriterionSet').set('description', newName + ' Plan Criterion Set');
            }
            if (self.get('planObservationContainer')) {
                self.get('planObservationContainer').set({'name': newName + ' Metrics','description': newName + ' Metrics'});
                //self.get('planObservationContainer').set('description', newName + ' Metrics');
                var libraries = self.get('planObservationContainer').get('libraries');
                if (libraries && libraries.length > 0) {
                    for (var i = 0; i < libraries.length; i++) {
                        libraries.at(i).set({'name': newName + ' Measures','description': newName + ' Measures'});
                        //libraries.at(i).set('description', newName + 'Measures');
                    }
                }
            }
            if (self.get('phase')) {
                for (var i = 0; i < self.get('phase').length; i++) {
                    var phase = self.get('phase').at(i);
                    phase.handlePhaseNameChange(phase, phase.get('name'));
                }
            }
        };

        getPreviousComponent(component) {//fetching component from previous package
            var modelSuffix = window.utils.getSuffix(component.id);
            var linkedPackageAltId = DataManager.getDataManager().getRepositoryId(this.get('id'));
            if (DataManager.getDataManager().getRepositoryId(component.getNestedParent().get('id')) !== window.plansKey) {
                linkedPackageAltId = DataManager.getDataManager().getRepositoryId(component.getNestedParent().get('id'));
            }
            var linkedComponent = component.constructor.find({ id: linkedPackageAltId + modelSuffix });
            return linkedComponent;
        }

        copyMeasurements(measurementsCopied, callback, sourceAlt, ownedPackageList) {
            var replaceMeasurements = [];
            var dupMeasurementsCopied = measurementsCopied.slice();
            async.each(measurementsCopied, function (copyObj, copiedRelations) {
                copyObj.measurement.copyBaseMeasurementRelationships(copyObj.measurementCopy, dupMeasurementsCopied, function (replaceObject) {
                    if (replaceObject) {
                        replaceMeasurements.push(replaceObject);
                    }
                    copiedRelations();
                }, sourceAlt, ownedPackageList);
            }, function () {
                _.each(replaceMeasurements, function (replaceObj) {
                    replaceObj.measurement.replaceMeasureToHandleChangedPreviousAltMeasure(replaceObj.measures);
                    replaceObj.measurement.calculate();
                });
                _.each(measurementsCopied, function (copyObj) {
                    /*if(copyObj.measurementCopy.get("type") == "smm_CollectiveMeasurement"){
                        if(!copyObj.measurementCopy.getCyclicPath() || copyObj.measurementCopy.getCyclicPath().length == 0){
                            copyObj.measurementCopy.calculate();
                        } else {
                            console.log('skipping cyclic:'+copyObj.measurementCopy.get('name'));
                        }
                    } else {*/
                        if(copyObj.measurementCopy.get("type") !== "smm_CollectiveMeasurement"){
                            copyObj.measurementCopy.calculate();
                        }
                   // }
                });

                //replacedMeasure();						
                //},function(){
                if (callback) {
                    callback();
                }
                //});
            });
        };

        loadCommonWorkspace(createCommonPackage, callback) {
            var self = this;
            DataManager.getDataManager().getWorkspaceDataWithId(self.getCommonRepositoryId(), function (wsData) {
                function createOrSetScenario() {
                    if (wsData.get('actionStore')) {
                        if (createCommonPackage) {
                            self.createCommonPackage(wsData);
                        } else {
                            self.setCommonScenario(wsData)
                        }
                        if(callback){
                            callback();
                        }
                    }
                    else {
                        setTimeout(createOrSetScenario, 300);
                    }
                }
                createOrSetScenario();
            });
        };
        getBeepPackageWithId(beepPackageId, type, callback, skipIfUnloaded) {
            var self = this;
            type = type.replace('_', '.');
            var model = Backbone.Relational.store.getObjectByName(type).find({ id: beepPackageId });
            if (model || skipIfUnloaded) {
                if (callback) {
                    callback(model);
                    return;
                }
            }
            //var dataManager = DataManager.getDataManager();
            var wsId = beepPackageId.substr(0, beepPackageId.lastIndexOf('@') + 1);
            var vdmStore = DataManager.getDataManager().getVDMStore(wsId);
            var currentVersion = (type.indexOf('Plan') > 0) ? DataManager.getDataManager().get('currentPlanVersion') : DataManager.getDataManager().get('currentVDMVersion');
            var pack = type.substr(0, type.indexOf('.'));
            var cls = type.substr(type.indexOf('.') + 1);
            var packNS = "appbo/" + pack + "/" + cls;

            DataManager.getDataManager().fetchDocumentFromPackage(beepPackageId, packNS, currentVersion, beepPackageId, packNS, vdmStore, {
                success: function (model) {
                    if (callback) {
                        callback(model);
                    }
                },
                error: function (error) {
                    console.log('Unable to load selected Package');
                    if (callback) {
                        callback();
                    }
                }
            });
        };
        getReportBeepPackageWithId(beepPackageId, callback, skipIfUnloaded) {
            var self = this;
            var dataManager = DataManager.getDataManager();
            var wsId = beepPackageId.substr(0, beepPackageId.lastIndexOf('@') + 1);
            var vdmStore = DataManager.getDataManager().getVDMStore(wsId);
            var model = Backbone.Relational.store.getObjectByName('report.BeepReport').find({ id: beepPackageId });
            if (model || skipIfUnloaded) {
                if (callback) {
                    callback(model);
                    return;
                }
            }
            DataManager.getDataManager().fetchDocumentFromPackage(beepPackageId, "appbo/report/BeepReport", DataManager.getDataManager().get('currentVDMVersion'), beepPackageId, "appbo/report/BeepReport", vdmStore, {
                success: function (model) {
                    if (callback) {
                        callback(model);
                    }
                },
                error: function (error) {
                    console.log('Unable to load selected Package');
                    if (callback) {
                        callback();
                    }
                }
            });
        };
        getVocabularyBeepPackageWithId(beepPackageId, callback, skipIfUnloaded) {
            var self = this;
            var wsId = beepPackageId.substr(0, beepPackageId.lastIndexOf('@') + 1);
            var vdmStore = DataManager.getDataManager().getVDMStore(wsId);
            var model = Backbone.Relational.store.getObjectByName('concept.BeepVocabulary').find({ id: beepPackageId });
            if (model || skipIfUnloaded) {
                if (callback) {
                    callback(model);
                    return;
                }
            }
            DataManager.getDataManager().fetchDocumentFromPackage(beepPackageId, "appbo/concept/BeepVocabulary", DataManager.getDataManager().get('currentVDMVersion'), beepPackageId, "appbo/concept/BeepVocabulary", vdmStore, {
                success: function (model) {
                    if (callback) {
                        callback(model);
                    }
                },
                error: function (error) {
                    console.log('Unable to load selected Package');
                    if (callback) {
                        callback();
                    }
                }
            });
        };
        modelHasReferenceToPackage(model, packageId, callback) {
            //var repId = DataManager.getDataManager().getRepositoryId(model.get('id'));
            var alts = [];
            var filterIds = [];
            filterIds.push(packageId);
            var phases = this.get('phase');
            phases.each(function (phase) {
                alts = alts.concat(phase.get('phaseAlternative').models);
            });
            var packRepId = DataManager.getDataManager().getRepositoryId(packageId);
            if (packRepId.indexOf('-Common@') > 0) {
                alts.push(DataManager.getDataManager().getWorkspaceDataWithIdSync(packRepId));
            }
            var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);
            alts = alts.concat(plansWsData);
            var rdfModels = [];
            _.each(alts, function (alt) {
                rdfModels.push(DataManager.getDataManager().getRDFModel(alt.get('id')));
            });
            if (rdfModels.length > 0) {
                var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                function checkResults(refs) {
                    async.eachSeries(refs, function (ref, checkedReference) {
                        var refRDFModel = null;
                        if(ref.id.indexOf(window.plansKey) == -1){
                            refRDFModel = DataManager.getDataManager().getRDFModel(ref.id);
                        } else {
                            refRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                        }
                        if (!refRDFModel) {
                            checkedReference();
                        } else {
                            refRDFModel.getAndFilterByNestedParentForSingleResult(ref, [], function (ret) {
                                if (ret && (ret.parent === packageId) && (ret.predicate !== 'beeppackage_BeepPackage-dependentPackage')) {
                                    checkedReference("Found Dependency");
                                } else {
                                    checkedReference();
                                }
                            }, 'vbc:cmof_EObject-name', null, filterIds);
                        }
                    }, function (err) {
                        callCallback(err);
                    });
                }
                if (altRDFModel) {
                    altRDFModel.executeReferencesToModelQuery(model, checkResults, rdfModels);
                } else {
                    callCallback();
                }
            } else {
                callCallback();
            }
            function callCallback(err) {
                if (callback) {
                    callback(err ? true : false);
                }
            }
        };
        getReverseAssociationInstances(modelId, associationPredicate, namePropertyNS, skipPackages, callback) {
            var ret = [];
            var alts = [];
            var phases = this.get('phase');
            phases.each(function (phase) {
                alts = alts.concat(phase.get('phaseAlternative').models);
            });
            var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);
            alts = alts.concat(plansWsData);
            var rdfModels = [];
            _.each(alts, function (alt) {
                rdfModels.push(DataManager.getDataManager().getRDFModel(alt.get('id')));
            });
            if (rdfModels.length > 0) {
                var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                if (altRDFModel) {
                    altRDFModel.getReverseAssociationInstances(modelId, associationPredicate, namePropertyNS, callCallback, [], rdfModels);
                } else {
                    callCallback();
                }
            } else {
                callCallback();
            }
            function callCallback(result) {
                if (callback) {
                    callback(result);
                }
            }
        };
        getReferencesToModel2(model, callback, getParents, namePropertyNS) {
            var ret = [];
            var alts = [];
            var phases = this.get('phase');
            phases.each(function (phase) {
                alts = alts.concat(phase.get('phaseAlternative').models);
            });
            var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);
            alts = alts.concat(plansWsData);
            var rdfModels = [];
            _.each(alts, function (alt) {
                rdfModels.push(DataManager.getDataManager().getRDFModel(alt.get('id')));
            });
            if(model.id.indexOf('Common') != -1){
                rdfModels.push(DataManager.getDataManager().getRDFModel(this.getCommonRepositoryId()));
            }
            if (rdfModels.length > 0) {
                var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                if (altRDFModel) {
                    altRDFModel.getReferencesToModel2(model, callCallback, getParents, namePropertyNS, null, null, rdfModels);
                } else {
                    callCallback();
                }
            } else {
                callCallback();
            }
            function callCallback(result) {
                if (callback) {
                    callback(result);
                }
            }
        };
        createCommonPackage(wsData) {
            var self = this;
            //require(["appbo/vdml/ValueDeliveryModel"], function (ValueDeliveryModel) {
                var comPackId = self.getCommonRepositoryId() + '-CommonPackage';
                var commonPackage = new ValueDeliveryModel({ id: comPackId, name: 'Common Package', description: 'Common Package', planId: self.id });
                if (!commonPackage.lawnchair) {
                    commonPackage.lawnchair = wsData.get('vdmStore');
                }
                var commonScenario = commonPackage.createScenario();
                DataManager.getDataManager().get('planScenarios').put(self.get('id') , commonScenario);
                //commonPackage.save();
            //});
        };

        setCommonScenario() {
            var self = this;
            var vdmStore = DataManager.getDataManager().getVDMStore(self.getCommonRepositoryId());
            DataManager.getDataManager().fetchDocumentFromPackage(self.getCommonRepositoryId() + "-CommonPackage", "appbo/vdml/ValueDeliveryModel", self.get('version') ? self.get('version') : DataManager.getDataManager().get('currentVDMVersion'), self.getCommonRepositoryId() + "-CommonPackage", "appbo/vdml/ValueDeliveryModel", vdmStore, {
                success: function (commonPackage) {
                    DataManager.getDataManager().set('commonPackage', commonPackage);
                    var commonScenario = null;
                    if(commonPackage){
                        commonScenario = commonPackage.get('scenario').at(0);
                    }
                    if (!commonScenario) {
                        commonScenario = commonPackage.createScenario();
                    }
                    DataManager.getDataManager().get('planScenarios').put(self.get('id'), commonScenario);
                },
                error: function (error) {
                    console.log('failed to fetch common package');
                }
            });
        };
        getCommonPackage(callback) {
            var self = this;
            var docVersion = self.get('documentVersion');
            DataManager.getDataManager().getWorkspaceDataWithId(self.getCommonRepositoryId(),function(){
                var vdmStore = DataManager.getDataManager().getVDMStore(self.getCommonRepositoryId());
                DataManager.getDataManager().fetchDocumentFromPackage(self.getCommonRepositoryId() + "-CommonPackage", "appbo/vdml/ValueDeliveryModel", self.get('version'), self.getCommonRepositoryId() + "-CommonPackage", "appbo/vdml/ValueDeliveryModel", vdmStore, {
                    success: function (commonPackage) {
                        callback(commonPackage);
                    },
                    error: function (error) {
                        debugger
                        console.log('failed to fetch common package');
                        self.loadCommonWorkspace(true,callback);
                    },
                    documentVersion:docVersion
                });
            })
        };
        getCommonRepositoryId() {
            var id = this.get('id');
            var repId = id.substr(id.lastIndexOf('@') + 1);
            return '@' + repId + '-Common@';
        }

        getCommonScenario(callback) {
            var self = this;
            var commonScenario;

            function getFromCache() {
                commonScenario = DataManager.getDataManager().get('planScenarios').get(self.get('id'));
                if (!commonScenario) {
                    self.getCommonPackage(function(commonPackage){
                        if(commonPackage){
                            commonScenario = commonPackage.get('scenario').at(0);
                        }
                        DataManager.getDataManager().get('planScenarios').put(self.get('id'), commonScenario);
                        callback(commonScenario);
                    });
                    //setTimeout(getFromCache, 300);
                } else {
                    callback(commonScenario);
                }
            }
            getFromCache();
        };

        getOrCreatePlanObservationContainer() {
            var obsContainer = this.get('planObservationContainer');
            if (!obsContainer) {
                var mmId = DataManager.getDataManager().guidGeneratorByOwner(this);
                obsContainer = new SmmModel({ id: mmId, name: this.get('name') + " Metrics",type: "smm_SmmModel" });
                this.set('planObservationContainer', obsContainer);
                this.get('planMeasureLibrariesContainer').add(obsContainer);
            }
            return obsContainer;
        };

        getOrCreatePlanCriterionSet() {
            var planCriterionSet = this.get('planCriterionSet');
            if (!planCriterionSet) {
                var vpId = DataManager.getDataManager().guidGeneratorByOwner(this);
                var name = this.get('name') + ' Plan Criterion Set';
                planCriterionSet = new ValueProposition({ id: vpId, 'name': name, description: name,'planCriterionSetOwner':this });
                this.set('planCriterionSet', planCriterionSet);
            }
            return planCriterionSet;
        };
        linkOrCreateAndLinkCharacteristic(charName, measuredChar) {
            var measureLibrary = this.get('defaultMeasureLibrary');
            var characteristic;
            if (!measureLibrary) {
                measureLibrary = this.createDefaultMeasureLibrary();
            }
            characteristic = measureLibrary.getOrCreateCharacteristic(charName);
            measuredChar.set('characteristicDefinition', characteristic);
            return characteristic;
        };

        findOrCreateUnit(unitName, existingUnitObj) {
            if (!unitName || unitName === "") {
                return null;
            }
            var measureLibrary = this.get('defaultMeasureLibrary');
            if (!measureLibrary) {
                measureLibrary = this.createDefaultMeasureLibrary();
            }
            var unitToLink = measureLibrary.getOrCreateUnit(unitName, existingUnitObj);
            return unitToLink;
        };

        createDefaultMeasureLibrary() {
            var metricsModel = this.get('planObservationContainer');
            var measureLibrary;
            if (!metricsModel) {
                metricsModel = this.createDefaultMetricModel();
            }
            var measureLibrary = this.get('defaultMeasureLibrary');
            if (measureLibrary) {
                return measureLibrary;
            }
            measureLibrary = metricsModel.createMeasureLibrary(this.get('name') + 'Measures');
            this.set('defaultMeasureLibrary', measureLibrary);
            return measureLibrary;
        };

        createDefaultMetricModel() {
            var defMetricModel = this.get('planObservationContainer');
            defMetricModel = this.getOrCreatePlanObservationContainer();
            return defMetricModel;
        };

        createMeasurementCharacteristics(valueModal, oldVpc, satisfaction, weight, recipientValue){
			var repId = DataManager.getDataManager().getRepositoryId(valueModal.id);
            var mcId;
            var valueName = valueModal.get('name');
            var nameVal;
            if (!valueModal.get('valueMeasurement')) {
                if (oldVpc && oldVpc.get('valueMeasurement')) {
                    mcId = repId + window.utils.getSuffix(oldVpc.get('valueMeasurement').id);
                } else {
                    mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                }
                nameVal = valueName + ' Value MeasurementMC';
                var valueMeasurement = new MeasuredCharacteristic({ id: mcId, name: nameVal, description: nameVal,valueMeasurementOwner:valueModal });
                valueModal.set('valueMeasurement', valueMeasurement);
                this.linkOrCreateAndLinkCharacteristic(valueName, valueMeasurement);
            }

            if (valueModal.get('type') !== "vdml_ValueAdd") {
                if (!valueModal.get('satisfactionLevel')) {
                    if (satisfaction && satisfaction !== "") {
                        if (oldVpc && oldVpc.get('satisfactionLevel')) {
                            mcId = repId + window.utils.getSuffix(oldVpc.get('satisfactionLevel').id);
                        } else {
                            mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                        }

                        nameVal = valueName + ' Satisfaction Level';
                        var satisfactionLevel = new MeasuredCharacteristic({ id: mcId, name: nameVal, description: nameVal,satisfactionLevelOwner:valueModal });
                        valueModal.set('satisfactionLevel', satisfactionLevel);
                        this.linkOrCreateAndLinkCharacteristic(valueName + ' Satisfaction', satisfactionLevel);
                    }
                }
                if (!valueModal.get('percentageWeight')) {
                    if (weight && weight !== "") {
                        if (oldVpc && oldVpc.get('percentageWeight')) {
                            mcId = repId + window.utils.getSuffix(oldVpc.get('percentageWeight').id);
                        } else {
                            mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                        }

                        nameVal = valueName + ' Percentage Weight';
                        var percentageWeight = new MeasuredCharacteristic({ id: mcId, name: nameVal, description: nameVal,percentageWeightOwner:valueModal });
                        valueModal.set('percentageWeight', percentageWeight);
                        this.linkOrCreateAndLinkCharacteristic(valueName + " Weight", percentageWeight);
                    }
                }
                if (!valueModal.get('recipientOpinion')) {
                    if (recipientValue && recipientValue !== "") {
                        if (oldVpc && oldVpc.get('recipientOpinion')) {
                            mcId = repId + window.utils.getSuffix(oldVpc.get('recipientOpinion').id);
                        } else {
                            mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                        }
                        var nameVal = valueName + ' Recipient MeasurementMC';
                        var recipientMeasurement = new MeasuredCharacteristic({ id: mcId, name: nameVal, description: nameVal,recipientOpinionOwner:valueModal });
                        valueModal.set('recipientOpinion', recipientMeasurement);
                        this.linkOrCreateAndLinkCharacteristic(valueName + ' Recipient Opinion', recipientMeasurement);
                    }
                }
			}
		};

        getValuesThatCanBeRefferedTo(callback) {
            var self = this;
            var bmColl = new Backbone.Collection();
            var values = [];
            var alts = [];
            var phases = this.get('phase');
            phases.each(function (phase) {
                alts = alts.concat(phase.get('phaseAlternative').models);
            });
            var rdfModels = [];
            _.each(alts, function (alt) {
                rdfModels.push(DataManager.getDataManager().getRDFModel(alt.get('id')));
            });

            if (rdfModels.length > 0) {
                var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                if (altRDFModel) {
                    altRDFModel.getAllDocumentsCollectionOfType('vdml_BusinessModel', function (ret) {
                        bmColl.add(ret.models);
                        callCallback();
                    }, null, null, null, rdfModels);
                } else {
                    callCallback();
                }
            } else {
                callCallback();
            }
            function callCallback() {
                async.each(bmColl.models, function (bm, bmHandled) {
                    var bmId = bm.get('id');
                    var bmAltId = bmId.substr(0, bmId.lastIndexOf('@') + 1);
                    var vdmStore = DataManager.getDataManager().getVDMStore(bmAltId);
                    DataManager.getDataManager().fetchDocumentFromPackage(bm.get('parent'), 'vdml_ValueDeliveryModel', parseInt(bm.get('version')), bm.get('id'), bm.get('type'), vdmStore, {
                        success: function (model) {
                            model.getValues(values, true);
                            bmHandled()
                        },
                        error: function (error) {
                            console.log(error);
                            bmHandled();
                        }
                    });
                }, function () {
                    self.getValues(values);
                    callback(values);
                });
            }
        };

        getValues(values) {
            var self = this;
            var planCriterionSet = self.get('planCriterionSet');
            if (planCriterionSet) {
                var planValues = planCriterionSet.get('component');
                planValues.each(function (value) {
                    values.push(value);
                });
            }
        };
        getAllAlternateScenarios(callback) {
            var alts = [];
            var altScenarios = {};
            var phases = this.get('phase');
            phases.each(function (phase) {
                var phaseAlternatives = phase.get('phaseAlternative');
                phaseAlternatives.each(function (alternative) {
                    alts.push(alternative);
                });
            });
            var packageCollection = new Backbone.Collection();
            async.eachSeries(alts, function (alt, gotAltScenarios) {
                alt.getMainScenariosofAllPackages(packageCollection, function (scenarios) {
                    altScenarios[alt.get('id')] = scenarios;
                    gotAltScenarios();
                });
            }, function () {
                packageCollection.reset();
                alts.length = 0;
                callback(altScenarios);
            });
        };

        getMeasurement(scenarios, valueElement, measuredChar) {
            var measurements = [];
            _.mapObject(scenarios, function (scenario, beepReference) {
                if (scenario) {
                    var mest = valueElement.getMeasurement(measuredChar, scenario);
                    if (mest) {
                        measurements.push(mest);
                    }
                }
            });
            return measurements;
        };
        getMeasurementParents(measurementId, callback) {
            var self = this;
            var alts = this.getAlternativesWhereICanBeReffered();
            alts = _.uniq(alts);
            var rdfModels = [];
            _.each(alts, function (alt) {
                rdfModels.push(DataManager.getDataManager().getRDFModel(alt.get('id')));
            });
            alts.length = 0;
            rdfModels.push(DataManager.getDataManager().getRDFModel(DataManager.getDataManager().getRepositoryId(measurementId)));
            rdfModels = _.uniq(rdfModels);

            if (rdfModels.length > 0) {
                var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                if (altRDFModel) {
                    altRDFModel.getMeasurementParents(measurementId, callCallback, rdfModels);
                } else {
                    callCallback();
                }
            } else {
                callCallback();
            }
            function callCallback(result) {
                if (callback) {
                    callback(result);
                }
            }
        };

        /*getCountOfDocumentsOfType(type, callback, skipPlanDocs, includeCommonAlt) {
            var phases = this.get('phase');
            var lastPhase = phases.findWhere({ nextPhase: null });
            if (lastPhase && lastPhase.get('primary')) {
                lastPhase.get('primary').getCountOfDocumentsOfType(type, callback, skipPlanDocs, includeCommonAlt);
            } else {
                callback({ count: 0 });
            }
        };
        getCountOfDocumentsOfTypeOnlyInPlanAlternative(type, callback) {
            var ret = {};
            var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
            function handledResult(result) {
                if (result && result.count) {
                    ret.count = result.count;
                } else {
                    ret.count = 0;
                }
                callback(ret);
            }
            if (altRDFModel) {
                altRDFModel.getCountOfDocumentsOfType(type, handledResult);
            }
        };*/

        getAlternativesWhereICanBeReffered() {
            var alts = [];
            var phases = this.get('phase');
            phases.each(function (phase) {
                var phaseAlternatives = phase.get('phaseAlternative');
                phaseAlternatives.each(function (alternative) {
                    alts.push(alternative);
                });
            });
            return alts;
        };
        static addPackageToRdf(packModel,generatedRDF){
            var altId = window.utils.getPrefix(packModel.id);
            DataManager.getDataManager().getWorkspaceData(workspaces[altId], function (wsData) {
                var packRDFModel = DataManager.getDataManager().getRDFModel(altId);
                function checkAndAddRDFForModel() {
                    if (packRDFModel) {
                        addRDFFormModel();
                    } else {
                        setTimeout(checkAndAddRDFForModel, 10);
                    }
                }
                function addRDFFormModel() {
                    delete packRDFModel.suppressUpdates;
                    packRDFModel.addRDFForModel(packModel, function () {
                        function savePackageRDF() {
                            DataManager.getDataManager().getWorkspaceDataWithId(altId, function (wsData) {
                                var copyAltVDMStore = wsData.get('vdmStore');
                                packModel.lawnchair = copyAltVDMStore;
                                DataManager.getDataManager().get('initializedPackages').add({ 'id': packModel.get('id'), 'version': parseInt(packModel.get('version')), 'model': packModel });
                                generatedRDF();
                            });
                        }
                        savePackageRDF();
                    }, true);
                }
                checkAndAddRDFForModel();
            });
        }
        getDocumentWithNamePath(namePath, type, callback) {
            var self = this;
            var alts;
            alts = self.getAlternativesWhereICanBeReffered();
            alts = _.uniq(alts);
            var ret = new Backbone.Collection();
            var rdfModels = [];
            async.each(alts, function (alt, altWSLoaded) {
                DataManager.getDataManager().getModelIdInitializedWSData((typeof alt === 'string') ? alt : alt.id, function (wsData) {
                    if (wsData) {
                        rdfModels.push(wsData.get('rdfModel'));
                    }
                    altWSLoaded();
                });
            }, function () {
                alts.length = 0;
                if (rdfModels.length > 0) {
                    var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                    if (altRDFModel) {
                        rdfModels.push(altRDFModel);
                        altRDFModel.getDocumentWithNamePath(namePath, type, rdfModels, false, callback);
                    } else {
                        callback();
                    }
                } else {
                    callback();
                }

            });
        }
        getAllDocumentsCollectionOfType(pac, type, callback, skipDepReferences, queryExt, refferedTo, nameNS, skipParents, getDesc, includePlanRdf, skipCommonAlt, skipOtherPlans) {
            var self = this;
            var alts;
            alts = self.getAlternativesWhereICanBeReffered();
            alts = _.uniq(alts);
            if (!skipCommonAlt) {
                var planId = self.id.substr(self.id.lastIndexOf('@'));
                var commonAltId = planId + "-Common@";
                alts.push(commonAltId);
            }
            var ret = new Backbone.Collection();
            var rdfModels = [];
            var filterPackages = [];
            async.each(alts, function (alt, altWSLoaded) {
                DataManager.getDataManager().getModelIdInitializedWSData((typeof alt === 'string') ? alt : alt.id, function (wsData) {
                    if (wsData) {
                        rdfModels.push(wsData.get('rdfModel'));
                    }
                    altWSLoaded();
                });
            }, function () {
                alts.length = 0;
                if (rdfModels.length > 0) {
                    var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                    function handledResult(resultCollection) {
                        if (resultCollection) {
                            ret.add(resultCollection.models);
                        }
                        callCallback();
                    }
                    if (altRDFModel) {
                        if (includePlanRdf) {
                            rdfModels.push(altRDFModel);
                        }
                        altRDFModel.getAllDocumentsCollectionOfType(type, handledResult, queryExt, filterPackages, nameNS, rdfModels, skipParents, getDesc);
                    } else {
                        callCallback();
                    }
                } else {
                    callCallback();
                }
                function callCallback() {
                    if (callback) {
                        if (skipOtherPlans) {
                            var filerRetList = new Backbone.Collection;
                            _.each(ret.models, function (obs) {
                                if (obs.id.indexOf(window.plansKey) >= 0) {
                                    if (obs.get('parent') === self.id) {
                                        filerRetList.push(obs);
                                    }
                                } else {
                                    filerRetList.push(obs);
                                }
                            });
                            ret = filerRetList;
                        }
                        callback(ret);
                    }
                }
            });
        };

        getEarlierAlternative(alt1, alt2) {
            var nextAlts = alt1.getAlternativesWhereICanBeReffered();
            if (_.indexOf(nextAlts, alt2) >= 0) {
                nextAlts.length = 0;
                return alt1;
            } else {
                nextAlts.length = 0;
                return alt2;
            }
        };
        getBackboneModel() {
            var model = this;
            var planModel = new Backbone.Model({ id: model.id, name: model.get('name'), status: model.get('status'), description: model.get('description') });
            var goal = model.get('goal');
            if (goal) {
                planModel.set('goal', goal.id);
            }
            var asIs = model.get('asIs');
            if (asIs) {
                planModel.set('asIs', asIs.id);
            }
            var phases = new Backbone.Collection();
            phases.comparator = function(ph1, ph2) {
                var next = ph1.get('nextPhase');
                if (next) {
                    var nextModel = Backbone.Relational.store.getObjectByName("transformation.Phase").find({ id: next });
                    while (nextModel) {
                        if (nextModel.id === ph2.id) {
                            return -1;
                        } else {
                            nextModel = nextModel.get('nextPhase');
                        }
                    }
                    return 1;
                } else {
                    return 1;
                }
            };
            model.get('phase').each(function (phase) {
                var phaseModel = phase.getBackboneModel();
                phaseModel.phaseOwner = model;
                phases.add(phaseModel);
            });
            planModel.set('phase', phases);
            return planModel;
        };

        unregisterPlan(skipUnregisterPackages, callback) {
            var selfModel = this;
            DataManager.getDataManager().set('suppressLogging', true);
            DataManager.getDataManager().set('suppressChangeLogging', true);
            DataManager.getDataManager().clearSaveInterval();
            var phasesModels = selfModel.get('phase').models.concat();
            function cleanWSData(wsData, wsDataCleanCallback) {
                if (wsData) {
                    var rdfModel = wsData.get('rdfModel');
                    rdfModel.clean(true, function () {
                        delete RDFModel.rdfModels[wsData.get("id")];
                        wsData.get('workspace').unregisterModel();
                        DataManager.getDataManager().get('workspacesData').remove(wsData);
                        wsData.destroy();
                        wsData = null;
                        wsDataCleanCallback();
                    });
                }
            }
            async.eachSeries(phasesModels, function (phase, phaseUnregistered) {
                var alts = phase.get('phaseAlternative');
                var altModels = alts.models.concat();
                async.eachSeries(altModels, function (alt, alternativeDeleted) {
                    var altRDFModel = DataManager.getDataManager().getRDFModel(alt.get('id'));
                    if (!altRDFModel) {
                        alternativeDeleted();
                        return;
                    }
                    altRDFModel.suppressUpdates = true;

                    var wsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(alt.get("id"));
                    if (!skipUnregisterPackages) {
                        alt.unregisterPackages(function () {
                            cleanWSData(wsData, alternativeDeleted);
                        });
                    } else {
                        cleanWSData(wsData, alternativeDeleted);
                    }
                }, function () {
                    DataManager.getDataManager().get('rdfStore').remove(phase.get("id"));
                    phaseUnregistered();
                });
            }, function () {
                if (!skipUnregisterPackages) {
                    var commomPackId = selfModel.getCommonRepositoryId() + "-CommonPackage";
                    var commonPackage = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: commomPackId });
                    if (commonPackage) {
                        DataManager.getDataManager().removeInitializedPackage(commonPackage.get('id'));
                        commonPackage.unregisterModel();
                    }
                }
                var wsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(selfModel.getCommonRepositoryId());
                if (wsData) {
                    if (skipUnregisterPackages) {
                        cleanWSData(wsData, callback);
                        return;
                    }
                    selfModel.unregisterReports(wsData, function () {
                        selfModel.unregisterVocabularies(wsData, function () {
                            selfModel.unregisterBeepModule(wsData, function () {
                                selfModel.unRegisterCodeContainer(wsData, function () {
                                    DataManager.getDataManager().rdfStore.remove(selfModel.get("id"));
                                    DataManager.getDataManager().removeInitializedPackage(selfModel.get('id'));
                                    delete DataManager.getDataManager().get('planScenarios')[selfModel.id];
                                    if (DataManager.getDataManager().altScenarios) {
                                        delete DataManager.getDataManager().altScenarios[selfModel.id];
                                    }
                                    var plansWSData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);
                                    var plansWS = plansWSData.get('workspace');
                                    var planRef = plansWS.get('packageReference').find({ beepReference: selfModel });
                                    if (planRef) {
                                        plansWS.get('packageReference').remove(planRef);
                                        Backbone.Relational.store.unregister(planRef);
                                    }
                                    selfModel.unregisterModel();
                                    DataManager.getDataManager().set('suppressLogging', false);
                                    DataManager.getDataManager().set('suppressChangeLogging', false);
                                    DataManager.getDataManager().get('changedParentObjects').reset();// doing this as they do contain the workspace docs that are synced again
                                    DataManager.getDataManager().get('changedObjects').reset();
                                    if (callback) {
                                        callback();
                                    }
                                });
                            });
                        });
                    });
                }
            });
        };
        unregisterReports(wsData, callback) {
            var selfModel = this;
            wsData.get('rdfModel').getAllDocumentsCollectionOfType('report_BeepReport', function (reports) {
                var delReports = reports.models.concat();
                async.each(delReports, function (report, reportUnregistered) {
                    selfModel.getReportBeepPackageWithId(report.id, function (repModel) {
                        if (repModel) {
                            repModel.unregisterModel();
                        }
                        reportUnregistered();
                    }, true);
                }, function () {
                    if (callback) {
                        callback();
                    }
                });
            }, false, null, 'vbc:cmof_EObject-name', null, true);
        };
        unregisterBeepModule(wsData, callback) {
            var selfModel = this;
            wsData.get('rdfModel').getAllDocumentsCollectionOfType('beeppackage_BeepModule', function (vocabularies) {
                var delVocs = vocabularies.models.concat();
                async.each(delVocs, function (voc, vocUnregistered) {
                    var moduleObj = window.utils.getElementModel(voc.id,['beeppackage.BeepModule']);
                    //selfModel.getVocabularyBeepPackageWithId(voc.id, function (vocModel) {
                        if (moduleObj) {
                            moduleObj.unregisterModel();
                        }
                        vocUnregistered();
                    //}, true);
                }, function () {
                    if (callback) {
                        callback();
                    }
                });
            }, false, null, 'vbc:cmof_EObject-name', null, true);
        };
        unRegisterCodeContainer(wsData, callback) {
            var selfModel = this;
            wsData.get('rdfModel').getAllDocumentsCollectionOfType('beeppackage_CodeContainer', function (vocabularies) {
                var delVocs = vocabularies.models.concat();
                async.each(delVocs, function (voc, vocUnregistered) {
                    var codeContainerObj = window.utils.getElementModel(voc.id,['beeppackage.CodeContainer']);
                    //selfModel.getVocabularyBeepPackageWithId(voc.id, function (vocModel) {
                        if (codeContainerObj) {
                            codeContainerObj.unregisterModel();
                        }
                        vocUnregistered();
                    //}, true);
                }, function () {
                    if (callback) {
                        callback();
                    }
                });
            }, false, null, 'vbc:cmof_EObject-name', null, true);
        };
        unregisterVocabularies(wsData, callback) {
            var selfModel = this;
            wsData.get('rdfModel').getAllDocumentsCollectionOfType('concept_BeepVocabulary', function (vocabularies) {
                var delVocs = vocabularies.models.concat();
                async.each(delVocs, function (voc, vocUnregistered) {
                    selfModel.getVocabularyBeepPackageWithId(voc.id, function (vocModel) {
                        if (vocModel) {
                            vocModel.unregisterModel();
                        }
                        vocUnregistered();
                    }, true);
                }, function () {
                    if (callback) {
                        callback();
                    }
                });
            }, false, null, 'vbc:cmof_EObject-name', null, true);
        };

        deletePlan(callback) {
            var selfModel = this;
            var planContainedModels = _.pluck(selfModel.getContainedModels(), 'id');
            DataManager.getDataManager().set('suppressLogging', true);
            DataManager.getDataManager().set('suppressChangeLogging', true);
            DataManager.getDataManager().clearSaveInterval();
            var phasesModels = selfModel.get('phase').models.reverse();
            var altModels = [];
            for(var ph in phasesModels){
                var alts = phasesModels[ph].get('phaseAlternative').models.reverse();
                for(var phAlts in alts){
                    altModels.push(alts[phAlts]);
                }
            }
            var altCount = 0;
            //async.eachSeries(phasesModels, function (phase, phaseDeleted) {
            //    var alts = phase.get('phaseAlternative');
           //     var altModels = alts.models.concat();
           window.utils.showSpinnerPercentage(30);
           var serverChangeSet = DataManager.getDataManager().get(DataManager.getDataManager().CURRENT_CHANGESET);
           if(serverChangeSet == undefined){
                serverChangeSet = DataManager.getDataManager().initilaizeWorkspaceChangeset();
           }
           Backbone.skipPersistance = true;
                async.eachSeries(altModels, function (alt, alternativeDeleted) {
                    DataManager.getDataManager().getModelIdInitializedWSData(alt.id, function () {
                        var altRDFModel = DataManager.getDataManager().getRDFModel(alt.get('id'));
                        if (!altRDFModel) {
                            alternativeDeleted();
                            return;
                        }
                        altRDFModel.suppressUpdates = true;
                        alt.deletePackages(function () {
                            DataManager.getDataManager().getWorkspaceDataWithId(alt.get("id"), function (wsData) {
                                //window.myWorker.postMessage("destroy:"+alt.id+new Date().getTime());
                                DataManager.getDataManager().nuke(wsData, function () {
                                    alt.destroy();
									if(wsData) {
										delete RDFModel.rdfModels[wsData.get("id")];
										DataManager.getDataManager().get('workspacesData').remove(wsData);
										wsData.destroy();
									}
                                    wsData = null;
                                    if(altCount == 0){
                                        window.utils.showSpinnerPercentage(40);
                                    }
                                    else if(altCount >= altModels.length/3){
                                        window.utils.showSpinnerPercentage(60);
                                    }
                                    altCount++;
                                    setTimeout(alternativeDeleted,200);
                                });
                            });
                        });
                    });
                }, function () {
                    Backbone.skipPersistance = false;
                    window.utils.showSpinnerPercentage(70);
                    setTimeout(function(){
                    altModels.length = 0
                    var phaseDestroyLen = phasesModels.length;
                    while(phaseDestroyLen--){
                        DataManager.getDataManager().get('rdfStore').remove(phasesModels[phaseDestroyLen].get("id"));
                        phasesModels[phaseDestroyLen].destroy();
                    }
                    
              //      phaseDeleted();
             //   });
           // }, function () {
                try {
                    DataManager.getDataManager().getWorkspaceDataWithId(selfModel.getCommonRepositoryId(), function (wsData) {
                        if(!wsData.get('rdfModel')){ //when json is corrupt
                            DataManager.getDataManager().set('suppressLogging', false);
                            DataManager.getDataManager().set('suppressChangeLogging', false);
                            DataManager.getDataManager().getWorkspaceDataWithId(window.plansKey, function (wsData) {
                                DataManager.getDataManager().unRegisterLastChangeset(wsData);
                                if (callback) {
                                    callback();
                                }
                            });
                        }else {
                            wsData.get('rdfModel').getAllDocumentsCollectionOfType('beeppackage_BeepPackage', function (packs) {
                                var packs = packs.models.concat();
                                var beepPacks = selfModel.getPlanPackageList(["vdml_ValueDeliveryModel"],true);
                                for(var i=0;i<beepPacks.length; i++){
                                    if(!packs.find(item => item.id == beepPacks[i].beepReference)){
                                        var model = new Backbone.Model({ id: beepPacks[i].beepReference, type: beepPacks[i].beepType });
                                        packs.push(model);
                                    }
                                }
                                async.eachSeries(packs, function (pack, packDeleted) {
                                    selfModel.getBeepPackageWithId(pack.id, pack.get('type'), function (repModel) {
                                        if (!repModel) {
                                            packDeleted();
                                            return;
                                        }
                                        DataManager.getDataManager().addpackageForDelete(serverChangeSet,repModel,selfModel);
                                        DataManager.getDataManager().removeInitializedPackage(repModel.get('id'));
                                        repModel.destroy();
                                        //setTimeout(packDeleted, 10);
                                        packDeleted();
                                    });
                                }, function () {
                                    wsData.get('rdfModel').getAllDocumentsCollectionOfType('beeppackage_CodeContainer', function (packs) {
                                        var packs = packs.models.concat().map(item => item.id);
                                        var beepPacks = selfModel.getPlanPackageList(["beeppackage_CodeContainer"],true);
                                        for(var i=0;i<beepPacks.length; i++){
                                            if(!packs.find(item => item.id == beepPacks[i].beepReference)){
                                                packs.push(beepPacks[i].beepReference);
                                            }
                                        }
                                        async.eachSeries(packs, function (pack, packDeleted) {
                                            CodeContainer.getBlobInstanceWithId(pack, selfModel, function (codeContainer) {
                                                if (codeContainer) {
                                                    codeContainer.set('code',null);
                                                    DataManager.getDataManager().addpackageForDelete(serverChangeSet,codeContainer,selfModel);
                                                    DataManager.getDataManager().removeInitializedPackage(codeContainer.get('id'));
                                                    codeContainer.destroy();
                                                    //setTimeout(packDeleted, 10);
                                                    packDeleted();
                                                } else {
                                                    console.log('Unable to load selected Package');
                                                    packDeleted();
                                                    return;
                                                }
                                            });
                                        }, function () {
                                            DataManager.getDataManager().addpackageForDelete(serverChangeSet,selfModel,selfModel);
                                            window.utils.showSpinnerPercentage(90);
                                            DataManager.getDataManager().saveChangeSetToBackend(serverChangeSet,function(){
                                                setTimeout(function(){
                                                    DataManager.getDataManager().nuke(wsData, function () {
                                                        DataManager.getDataManager().getWorkspaceDataWithId(window.plansKey, function (wsData) {
                                                            selfModel.nukePlan(selfModel,planContainedModels,callback);
                                                        });
                                                    },100)
                                                })                    
                                            });
                                        });
                                    }, false, null, 'vbc:cmof_EObject-name', null, true);
                                });
                            }, false, null, 'vbc:cmof_EObject-name', null, true);
                        }
                        
                    });
                }catch(er){
                    console.log(er);
                    DataManager.getDataManager().getWorkspaceDataWithId(window.plansKey, function (wsData) {
                        selfModel.nukePlan(selfModel,planContainedModels,callback);
                    });
                }
            },100);
            });
        };
        nukePlan(selfModel,planContainedModels,callback){
            var dataManager = DataManager.getDataManager();
            dataManager.rdfStore.remove(selfModel.get("id"));
            var currentWorkspacePlan = dataManager.get('currentWorkspace').get('packageReference').findWhere({ 'beepReference': selfModel });
            if (currentWorkspacePlan) {
                dataManager.get('currentWorkspace').get('packageReference').remove(currentWorkspacePlan);
                currentWorkspacePlan.destroy({ silent: true });
            }
            dataManager.removeInitializedPackage(selfModel.get('id'));
            var plansRDFModel = dataManager.getRDFModel(window.plansKey);
            planContainedModels.push(selfModel.get('id'));
            dataManager.asyncEach(planContainedModels, function (containedModel, deletedRdfData) {
                plansRDFModel.removeRDFForModel(containedModel, function () {
                    //containedModel.destroy({silent:true});
                    deletedRdfData();
                });
            }, function () {
                plansRDFModel.save();
                if (dataManager.altScenarios) {
                    delete dataManager.altScenarios[selfModel.id];
                }
                selfModel.destroy({ silent: true });
                dataManager.set('suppressLogging', false);
                dataManager.set('suppressChangeLogging', false);
                dataManager.get('changedParentObjects').reset();// doing this as they do contain the workspace docs that are synced again
                dataManager.get('changedObjects').reset();
                dataManager.calculateValuesCache = {};
                planContainedModels.length = 0;
                dataManager.getWorkspaceDataWithId(window.plansKey, function (wsData) {
                    dataManager.unRegisterLastChangeset(wsData);
                    //dataManager.acquireSaveLock(function(){
                    //    dataManager.pullServerChanges(function(){
                         //   dataManager.clearSaveInterval();
						//    dataManager.releaseSaveLock();
                            if (callback) {
                                callback();
                            }
                   //     },true);
                    //});
                });
            });
        }
        fetchCustomPlanPackages(type,callback,searchArtifacts){
            var self = this;
            var packList = [];
            var dataManager = DataManager.getDataManager();
            var planPackages = this.getPlanPackageList(type,searchArtifacts);
            var docVersion = self.get('documentVersion');
            //var planPacks = dataManager.get("planPackages")[self.id];
            async.eachSeries(planPackages,function(planRef,planLoaded){
                //console.log("loading id:"+planRef.id+"type:"+planRef.type);
                var artDoc = dataManager.get("artifactsDocuments")[planRef.beepReference];
                var docVersion = artDoc ? artDoc.documentVersion:docVersion;
                /*if(planPacks){
                    for(var i=0;i<planPacks.length;i++){
                    }
                }*/
                dataManager.fetchDocumentFromPackage(planRef.beepReference,planRef.beepType,dataManager.get('currentVDMVersion'),planRef.beepReference,planRef.beepType,null,{
                    success:function(model){
                        packList.push(model);
                        planLoaded();
                    },
                    error:function(error){
                        console.log(error);
                        planLoaded();
                    },
                    documentVersion:docVersion
                }); 
            },function(){
                callback(packList);
            });
        }
        applyConcepts(callback) {
            var self = this;
            self.fetchCustomPlanPackages(['concept_BeepVocabulary'], function(vocabularyPackages){
                var conceptsArr = [];
                for (var j = 0; j < vocabularyPackages.length; j++) {
                    var vocabularyLibraries = vocabularyPackages[j].get('vocabularyLibrary');
                    if(vocabularyPackages[j].get('vocabularyLibrary')){
                        for (var k = 0; k < vocabularyLibraries.length; k++) {
                            var vocabularies = vocabularyLibraries.at(k).get('vocabulary');
                            conceptsArr = _.union(conceptsArr, vocabularies.models);
                        }
                    }
                }
                var localManager = DataManager.getDataManager().get('localeManager');
                localManager.applyConcepts(conceptsArr);
                if(callback) {
                    callback();
                }
            },true);
        };
        /*applyModuleConcepts(currentPlan) {
            var localManager = DataManager.getDataManager().get('localeManager');
            var localejson = localManager.getTranslationsByLocale(localManager.current_locale());
            var modules = DataManager.getDataManager().canvasModules[currentPlan.id];
            if (modules) {
                var modKeys = Object.getOwnPropertyNames(modules);
                _.each(modKeys, function (modKey) {
                    var config = JSON.parse(modules[modKey]);
                    _.each(config.dimensions, function (dimension) {
                        if (dimension.label) {
                            if (dimension.labelValue) {
                                localejson[dimension.label] = { value: dimension.labelValue, c: 1 };
                            } else {
                                localejson[dimension.label] = { value: dimension.name, c: 1 };
                            }
                        }
                    });
                    _.each(config.bmMappings, function (mapping) {
                        if (mapping.label && mapping.name) {
                            localejson[mapping.label] = { value: mapping.name, c: 1 };
                        }
                    });
                });
            }
        };*/
        //#endCustomMethods	

        /* Start-Selinium-Test-Methods */

        /*function to fetch all packages*/
        fetchAllPackagesFromPlan() {
            var packages = [];
            var phaseModels = this.get('phase');

            for (var i = 0; i < phaseModels.length; i++) {
                var alternativeModels = phaseModels.at(i).get('phaseAlternative');

                for (var j = 0; j < alternativeModels.length; j++) {
                    var altModel = alternativeModels.at(j);

                    for (var k = 0; k < altModel.get('phaseDesignPart').length; k++) {
                        var designModel = altModel.get('phaseDesignPart').models[k];
                        var beep = designModel.get('beepReference');
                        var packageType = designModel.get('beepType').replace('_', '.');
                        if (beep.indexOf("Common") > -1) {
                            var beepObj = Backbone.Relational.store.getObjectByName("beeppackage.BeepPackage").find({ id: beep });
                            beepObj = beepObj ? beepObj : Backbone.Relational.store.getObjectByName(packageType).find({ id: beep });
                            packages.push(JSON.stringify(beepObj.toJSON()));
                        }
                        else {
                            var vdmlPackage = Backbone.Relational.store.getObjectByName(packageType).find({ id: beep });;
                            packages.push(JSON.stringify(vdmlPackage.toJSON()));
                        }
                    }
                }
            }
            var uniquePackages = _.uniq(packages);
            return uniquePackages;
        }
        getExportJsonsForSelenium(phase, alternative, checkTotalPlan) {
            var packages = [];
            if (checkTotalPlan) {
                packages = this.fetchAllPackagesFromPlan();
            }
            else {
                var phaseModel = this.get('phase').findWhere({ name: phase });
                var altModel = phaseModel.get('phaseAlternative').findWhere({ name: alternative });
                for (var i = 0; i < altModel.get('phaseDesignPart').length; i++) {
                    var designModel = altModel.get('phaseDesignPart').models[i];
                    var beep = designModel.get('beepReference');
                    if (beep.indexOf("Common") > -1) {
                        var beepObj = Backbone.Relational.store.getObjectByName("beeppackage.BeepPackage").find({ id: beep });
                        packages.push(JSON.stringify(beepObj.toJSON()));
                    } else {
                        var vdmlPackage = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: beep });
                        packages.push(JSON.stringify(vdmlPackage.toJSON()));
                    }
                }
            }

            packages.push(JSON.stringify(this.toJSON()));
            return packages;
        };
        /* End-Selinium-Test-Methods */
        /* Start-unzip imported files functions*/
        decodeKey(encrypted, fileHandled) {
            if(chrome && chrome.fileSystem){
				chrome.fileSystem.chooseEntry({
					type: 'openFile', accepts: [
						{ description: "Private key File", extensions: ['pem'] },
						{ description: "Images (don't open please)", mimeTypes: ['image/jpg', 'image/pong'] }
					], acceptsAllTypes: true
				}, function (f) {
					if (chrome.runtime.lastError) {
						console.log(chrome.runtime.lastError);
						fileHandled('Error opening private key file');
					}
					else {
						f.file(function (fileObject) {
							var reader = new FileReader();
							reader.onload = function (ev) {
								var privateKey = reader.result;
								var decrypt = new JSEncrypt();
								decrypt.setPrivateKey(privateKey);
								fileHandled(decrypt.decrypt(encrypted._result));
							};
							reader.readAsText(fileObject);
						});
					}
				});
			}
        };
		static unzipFiles(fileObject, callback) {
            var reader = new FileReader();
            reader.onload = function (ev) {
                var planContent = reader.result;
                var newplanZip = new jszip();
                newplanZip.loadAsync(planContent).then(function (planZip) {
                    var obj = planZip.files;
                    var files = Object.entries(obj).filter(([key, value]) => value.name !== 'manifest.json')
                                .map(([key, value]) => value);
                    var manifestVersionObj = obj['manifest.json'];
                    if(manifestVersionObj){
                        manifestVersionObj.async("string").then(function (fileText) {
                            var pack = JSON.parse(fileText);
                            callback(files, pack.revision);
                        });
                    } else {
                        callback(files, null);
                    }
                });
            };
            reader.readAsBinaryString(fileObject);
        }        
        static createPlanFromFiles(files, manifestVersion, planName, planDescription, saveToBackend,replaceKeys, finalCallback, creatingVersion) {
            var planPackage;
            var originalId;
            var beepPackages = [];
            var dataManager = DataManager.getDataManager();
            function handleFiles() {
                async.eachSeries(files, function (fileObj, packHandleCallback) {
                    if (fileObj.name === "key.pem") {
                        packHandleCallback();
                    } else {
                        function handleFileText(fileText){
                            fileText = fileText.replace(/@BEEP Plans@/g, window.plansKey);
                            if (!fileText || fileText === "") {
                                return;
                            }
                            var pack = JSON.parse(fileText);
                            if (pack.type === 'transformation_Plan') {
                                planPackage = pack;
                            } else {
                                beepPackages.push(pack);
                            }
                            packHandleCallback();
                        }
                        if(fileObj.async){
                            fileObj.async("string").then(function (fileText) {
                                handleFileText(fileText);
                            });
                        }else{
                            handleFileText(JSON.stringify(fileObj,false))
                        }
                    }
                }, function (err) {
                    if (!planPackage) {
                        bootbox.alert(dataManager.get('localeManager').get('ImportPlanCorrupt'));
                        finalCallback();
                        return;
                    }
                    function replacePlanIds() {
                        var replaceIds = {};
                        function replacePackageIds(pack) {
                            var keys = Object.getOwnPropertyNames(replaceIds);
                            var packStr = JSON.stringify(pack);
                            for (var k = 0; k < keys.length; k++) {
                                var re = new RegExp(keys[k], 'g');
                                packStr = packStr.replace(re, replaceIds[keys[k]]);
                            }
                            return JSON.parse(packStr);
                        }
                        var newPlanId = window.planGuidGenerator();
                        var newPlanSuffix = newPlanId.substr(newPlanId.lastIndexOf('@') + 1);
                        var oldPlanSuffix = planPackage.id.substr(planPackage.id.lastIndexOf('@') + 1);
                        replaceIds[window.plansKey + '-' + oldPlanSuffix + "-#-"] = window.plansKey + '-';
                        replaceIds[oldPlanSuffix] = newPlanSuffix;
                        replaceIds[window.plansKey + '-'] = window.plansKey + '-' + newPlanSuffix + "-#-";
                        var planAlts = [];
                        var phases = planPackage.phase;
                        var commonAlt = planPackage.id.substr(planPackage.id.lastIndexOf('@'));
                        planAlts.push(commonAlt + "-Common@");
                        _.each(phases, function (phase) {
                            var alts = phase.phaseAlternative;
                            _.each(alts, function (alt) {
                                planAlts.push(alt.id);
                            });
                        });
                        for (var j = 0; j < planAlts.length; j++) {
                            if (planAlts[j] === '@' + oldPlanSuffix + '-Common@') {
                                replaceIds[planAlts[j]] = '@' + newPlanSuffix + '-Common@';
                            } else {
                                var newAltId = window.projectGuidGenerator()
                                //replaceIds[planAlts[j]] = newAltId;
                                replaceIds[window.utils.htmlEscape(planAlts[j])]  = window.utils.htmlEscape(newAltId);
                            }
                        }
                        originalId = planPackage.originalId;
                        if (!originalId) {
                            originalId = planPackage.id;
                        }
                        planPackage = replacePackageIds(planPackage);
                        planPackage.originalId = originalId;
                        for (var l = 0; l < beepPackages.length; l++) {
                            beepPackages[l] = replacePackageIds(beepPackages[l]);
                        }
                        planAlts.length = 0;
                    }
                    if (planName && planName !== "" && planName !== null) {
                        planPackage.name = planName;
                        planPackage.description = planDescription;
                    }
                    if(saveToBackend){
                        if(replaceKeys && !creatingVersion){
                            replacePlanIds();
                        }
                        var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
                        var revision = creatingVersion ? window.revisionGuidGenerator():null;
                        if(creatingVersion){
                            planPackage.previousDocumentVersion = planPackage.documentVersion
                            planPackage.documentVersion = revision
                        }
                        serverChangeSet = dataManager.addChangeObjectForFile(serverChangeSet,planPackage,planPackage,creatingVersion);
                        console.log("No of beeppackages excluding plan" + beepPackages.length );
                        var imgList = [];
                        for(var i=0;i<beepPackages.length;i++){
                            if(creatingVersion){
                                beepPackages[i].previousDocumentVersion = beepPackages[i].documentVersion
                                beepPackages[i].documentVersion = revision
                            }
                            if(beepPackages[i].type == "beeppackage_CodeContainer" && beepPackages[i].code && beepPackages[i].code.length > 2 && beepPackages[i].code.startsWith("data") /*&& !beepPackages[i].dataReference*/){
					            //var type = beepPackages[i].name.substring(beepPackages[i].name.lastIndexOf(".") + 1);'legalEntityId':currentLegalEntity.entityId,
                                var imgTagList = [];
                                if(beepPackages[i].tags){
                                    var tagNames = beepPackages[i].tags.split(',');
                                    for(var j=0;j<tagNames.length;j++){
                                        imgTagList.push({id:'',name:tagNames[j]});
                                    }
                                }
                                imgList.push({'dataReference':beepPackages[i].id,'packageId':planPackage.id,'code':beepPackages[i].code,'name':beepPackages[i].name,'inputTags':imgTagList,'fullyQualifiedName':planPackage.name + ':'+beepPackages[i].name});
                            } else {
                                serverChangeSet = dataManager.addChangeObjectForFile(serverChangeSet,beepPackages[i],planPackage,creatingVersion);
                            }
                        }
                        function createChangeObjForImages(){
                            var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
                            for(var i=0;i<beepPackages.length;i++){                                
                                if(beepPackages[i].type == "beeppackage_CodeContainer" && beepPackages[i].code && beepPackages[i].code.length > 2 && beepPackages[i].code.startsWith("data")){
                                    var code = beepPackages[i].code;
                                    beepPackages[i].code = null;
                                    serverChangeSet = dataManager.addChangeObjectForFile(serverChangeSet,beepPackages[i],planPackage,creatingVersion);
                                    if(code){
                                        beepPackages[i].code = code;
                                    }
                                }
                            }
                            console.log("No of beeppackages excluding plan" + serverChangeSet.changes.length );  
                            dataManager.saveChangeSetToBackend(serverChangeSet,function(){
                                packageExist();
                            });
                        }
                        if(imgList.length > 0){ //migration
                            dataManager.saveChangeSetToBackend(serverChangeSet,function(){
                                dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
                                /*dataManager.acquireSaveLock(function(){
                                    dataManager.pullServerChanges(function(){
                                        dataManager.clearSaveInterval();
						                dataManager.releaseSaveLock();*/
                                        var url = encodeURI("/vdmbee/image/save?legalEntityId="+dataManager.get("currentLegalEntity").entityId);
                                        dataManager.get('vmpServerService').postAllPromise(url,JSON.stringify(imgList)).then(function(imgResourceList) {
                                            if(imgResourceList){
                                                for(var key in imgResourceList){
                                                    for(var i=0;i<beepPackages.length;i++){
                                                        if(beepPackages[i].type == "beeppackage_CodeContainer" && beepPackages[i].id == key){
                                                            beepPackages[i].dataReference = imgResourceList[key];
                                                            break;
                                                        }
                                                    }
                                                }
                                            }
                                            createChangeObjForImages();
                                        });
                                    //},true);
                                });
                            });
                        } else {
                            createChangeObjForImages();
                        }  
                    } else {
                        packageExist();
                    }
                });
            }
            function packageExist() {
                if (planPackage) {
                    var planAlts = [];
                    var phases = planPackage.phase;
                    var commonAlt = planPackage.id.substr(planPackage.id.lastIndexOf('@'));
                    planAlts.push(commonAlt + "-Common@");
                    _.each(phases, function (phase) {
                        var alts = phase.phaseAlternative;
                        _.each(alts, function (alt) {
                            planAlts.push(alt.id);
                        });
                    });
                    var workspaces = {};
                    DataManager.getDataManager().set('suppressLogging', true);
                    async.eachSeries(planAlts, function (altId, altHandleCallback) {
                        var workspaceStore = DataManager.getDataManager().get("workspaceStore");
                        utils.invokeGetDefaultDirectory(workspaceStore, function (workspace) {
                            workspaces[altId] = workspace;
                            workspace.save();
                            Backbone.Relational.store.register(workspace);
                            var initialiseWorkspaceDataCount = 0;
                            function getAltWorkspaceData(altId) {
                                DataManager.getDataManager().getWorkspaceDataWithId(altId, function (wsData) {
                                    function sleepAndCheckModel() {
                                        if (wsData.get('rdfModel')) {
                                            wsData.get('rdfModel').clean();
                                            altHandleCallback();
                                        } else {
                                            setTimeout(sleepAndCheckModel, 100);
                                        }
                                    }
                                    if (!wsData) {
                                        initialiseWorkspaceDataCount++;
                                        if (initialiseWorkspaceDataCount > 10) {
                                            altHandleCallback('Failed to initialise Workspace. Import of Plan failed');
                                            return;
                                        } else {
                                            setTimeout(function () { getAltWorkspaceData(altId); }, 300);
                                        }
                                    } else {
                                        sleepAndCheckModel();
                                    }
                                    //altHandleCallback();	
                                });
                            }
                            getAltWorkspaceData(altId);
                        }, altId);
                    }, function (err) {
                        //planAlts.length = 0;
                        DataManager.getDataManager().set('suppressLogging', false);
                        if (err) {
                            DataManager.getDataManager().set('migrationPlan', null);
                            bootbox.alert('' + err);
                            callback();
                        } else {
                            window.utils.showSpinnerPercentage(30);
                            setTimeout(handlePackages, 100);
                        }
                    });

                    function handlePackages() {
                        DataManager.getDataManager().set('suppressLogging', true);
                        DataManager.getDataManager().set('suppressChangeLogging', true);
                        var Plan = Backbone.Relational.store.getObjectByName("transformation.Plan");
                        var plan = new Plan(planPackage, { silent: true });
                        planPackage = null;
                        if (originalId) {
                            plan.set('originalId', originalId);
                        }
                        DataManager.getDataManager().get('initializedPackages').add({ 'id': plan.get('id'), 'version': parseInt(plan.get('version')), 'model': plan });
                        DataManager.getDataManager().set('suppressLogging', false);
                        DataManager.getDataManager().set('suppressChangeLogging', false);
                        window.utils.showSpinnerPercentage(40);
                        setTimeout(function(){
                            if(beepPackages.length == 0){
                                createRdfForPackages(plan,[],finalCallback);
                            }else {
                                createPackages(plan,function(packageModels){
                                    createRdfForPackages(plan,packageModels,finalCallback);
                                });
                            }
                        }, 100);
                    }
                    function createRdfForPackages(plan,packageModels,finalCallback) {
                        window.utils.showSpinnerPercentage(80);
                        setTimeout(function(){
                            beepPackages.length = 0;
                            var plansRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                            plansRDFModel.addRDFForModel(plan, function () { 
                                DataManager.getDataManager().checkForCorrection(plan, [], function (planModal) {
                                    plansRDFModel.save();
                                    //planModal.save();
                                    async.eachSeries(packageModels, function (packModel, generatedRDF) {
                                        var altId = window.utils.getPrefix(packModel.id);
                                        DataManager.getDataManager().getWorkspaceData(workspaces[altId], function (wsData) {
                                            var packRDFModel = DataManager.getDataManager().getRDFModel(altId);
                                            function checkAndAddRDFForModel() {
                                                if (packRDFModel) {
                                                    addRDFFormModel();
                                                } else {
                                                    setTimeout(checkAndAddRDFForModel, 10);
                                                }
                                            }
                                            function addRDFFormModel() {
                                                delete packRDFModel.suppressUpdates;
                                                packRDFModel.addRDFForModel(packModel, function () {
                                                    function savePackageRDF() {
                                                        packRDFModel.save();
                                                        DataManager.getDataManager().getWorkspaceDataWithId(altId, function (wsData) {
                                                            var copyAltVDMStore = wsData.get('vdmStore');
                                                            packModel.lawnchair = copyAltVDMStore;
                                                            if (packModel.get('type') !== 'report_BeepReport' && packModel.get('type') !== 'concept_BeepVocabulary' && packModel.get('type') !== 'beeppackage_BeepModule' && altId.indexOf('Common') > 0 && packModel.get('type') !== 'beeppackage_CodeContainer') {
                                                                var commonScenario = packModel.get('scenario')?packModel.get('scenario').at(0):null;
                                                                if (commonScenario) {
                                                                    DataManager.getDataManager().get('planScenarios').put(plan.get('id'),commonScenario);
                                                                }
                                                            }
                                                            DataManager.getDataManager().checkForCorrection(packModel, [], function () {
                                                                packModel.save();
                                                                generatedRDF();
                                                            });
                                                        });
                                                    }
                                                    savePackageRDF();
                                                }, true);
                                            }
                                            checkAndAddRDFForModel();
                                        });
                                    }, function () {
                                        packageModels.length = 0;
                                        if(saveToBackend && !window.utils.compareVersions(manifestVersion,"6.8.5")){
                                            var beepPacks = plan.getPlanPackageList(["ecomap_EcoMap"],false);
                                            var CollaborationDiagramMixin = Backbone.Relational.store.getObjectByName("vdml.CollaborationDiagramMixin");
                                            CollaborationDiagramMixin.migrateDependencyToImages(beepPacks);
                                        }
                                        setTimeout(function(){ 
                                            finalCallback(plan);
                                        }, 50);
                                    });
                                });
                            }, true);
                        }, 100);
                    }

                    function createPackages(plan,packageCallback) {
                        var packageModels = [];
                        var dataManager = DataManager.getDataManager();
                        DataManager.getDataManager().set('suppressLogging', true);
                        DataManager.getDataManager().set('suppressChangeLogging', true);
                        async.eachSeries(planAlts, function (alt, fileHandleCallback) {
                            DataManager.getDataManager().getWorkspaceData(workspaces[alt], function (wsData) {
                                var altId = wsData.get('id');
                                for(var i in beepPackages){
                                    if(window.utils.getPrefix(beepPackages[i].id) == altId){
                                        var packType = beepPackages[i].type.replace('_', '.');
                                        var objType = Backbone.Relational.store.getObjectByName(packType);
                                        if(!Backbone.Relational.store.getObjectByName(packType).find({'id':beepPackages[i].id})){
                                            if(beepPackages[i].type != "beeppackage_BeepModule"){
                                                var copyPck = new objType(beepPackages[i], { silent: true });
                                                copyPck.set("planId",plan.id);
                                                packageModels.push(copyPck);
                                                if(window.myWorker){
                                                    window.myWorker.postMessage(copyPck.get('id')+new Date().getTime());
                                                }
                                                DataManager.getDataManager().get('initializedPackages').add({ 'id': copyPck.get('id'), 'version': parseInt(copyPck.get('version')), 'model': copyPck });
                                            } else {
                                                //dataManager.addCanvasModulesFromVpk(beepPackages[i]);
                                            }
                                        }
                                    }
                                }
                                if(packageModels.length >= beepPackages.length/2){
                                    window.utils.showSpinnerPercentage(50);
                                }
                                setTimeout(fileHandleCallback, 100);
                            });
                        }, function () {
                            DataManager.getDataManager().set('suppressLogging', false);
                            DataManager.getDataManager().set('suppressChangeLogging', false);
                            packageCallback(packageModels);
                        });
                    }
                }

            }
            handleFiles();
            
        }
	/*checkMigrationMessage(){
		var self = this;
		var migrationMessage = DataManager.getDataManager().get('migrationMessage');
		if(migrationMessage){	
			window.setTimeout(function () {
				DataManager.getDataManager().set('migrationPlan', null);
					var message = "<h4>" + DataManager.getDataManager().get('localeManager').get('ImportComplete', 'Plan') + "</h4><br>" + migrationMessage;
					var messageBox = bootbox.dialog({
						title: "Migration Changes",
						message: message,
						buttons: {
							main: {
								label: "Ok",
								className: "btn btn-default",
							},
							print: {
								label: "Print",
								className: "btn btn-default",
								callback: function () {
									var migrationFrame = jQuery('#migrationFrame');
									var iframeBody = migrationFrame.contents().find('body');
									iframeBody.empty();
									iframeBody.html(message);
									setTimeout(function () { migrationFrame[0].contentWindow.print(); }, 100);
									return false;
								}
							}
						}
					});
					var iframeEle = jQuery('#migrationFrame');
					if (!iframeEle.length) {
						jQuery('<iframe>', { src: '', id: 'migrationFrame', frameborder: 0, scrolling: 'no' }).appendTo('body');
						jQuery('#migrationFrame').contents().find('head').append('<link rel="stylesheet" type="text/css" href="js/css/print.css" media="print" />');
						jQuery('#migrationFrame').css('display', 'none');
					}
					messageBox.find('.modal-body').css('height', '400px');
					messageBox.on("shown.bs.modal", function () {
						messageBox.find('.modal-content').resizable();
						messageBox.find('.modal-dialog').draggable();
						var windowHeight = jQuery(window).height();
						var modalBodyHeight = messageBox.find('.modal-content').actual('outerHeight');
						if (windowHeight < modalBodyHeight) {
							messageBox.find('.modal-body').css('height', windowHeight * 0.5);
						}
						messageBox.find('.modal-body').css('overflow-x', 'hidden');
						messageBox.find('.modal-body').height('overflow-y', 'auto');
						var setHeight = false;
						messageBox.find('.modal-content').on("resize", function (event, ui) {// setting min height of the dialog, to prevent buttons and content from overflowing
							if (!setHeight) {
								ui.element.css("minHeight", messageBox.find('.modal-content').actual('outerHeight'));
								ui.element.css("minWidth", messageBox.find('.modal-content').actual('outerWidth'));
								setHeight = true;
							}
						});
					});
					DataManager.getDataManager().set('migrationMessage', null);
			}, 50);
		}
	}*/

    static createCanvasFromFiles(beepPackages, planModel, finalCallback) {
        function handleFiles() {
			var commonAlt = planModel.getCommonRepositoryId();             		
            DataManager.getDataManager().getWorkspaceDataWithId(commonAlt, function (wsData) {
                var count = 0;
                function getRDFModel() {
                    var commonRDFModel = DataManager.getDataManager().getRDFModel(wsData.get('id'));
                    if (commonRDFModel) {
                        commonRDFModel.getAllDocumentsCollectionOfType('beeppackage_BeepModule', function (modColl) {
                            var skipPacks = [];
                            async.each(beepPackages, function (modPack, handledBeepPackage) {
                                var modPackSuffix = modPack.id.substr(modPack.id.lastIndexOf('@') + 1);
                                async.each(modColl.models, function (mod, handledModel) {
                                    var modSuffix = mod.id.substr(mod.id.lastIndexOf('@') + 1);
                                    if (modSuffix === modPackSuffix) {
                                        var parentId = mod.get('parent');
                                        var repId = DataManager.getDataManager().getRepositoryId(parentId);
                                        var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                                        DataManager.getDataManager().fetchDocumentFromPackage(parentId, "appbo/beeppackage/BeepModule", mod.get('version'), mod.get('id'), "appbo/beeppackage/BeepModule", vdmStore, {
                                            success: function (model) {
                                                var configContainer = model.get('artifact').at(0);
                                                if (configContainer) {
                                                    configContainer.set('code', modPack.artifact[0].code);
                                                    model.set('name', modPack.name);
                                                    model.set('description', modPack.description);
                                                    skipPacks.push(modPack);
                                                } else {
                                                    model.destroy({ silent: true });
                                                }
                                                handledModel();
                                            },
                                            error: function (error) {
                                                handledModel();
                                            }
                                        });
                                    } else {
                                        handledModel();
                                    }
                                }, function (err) {
                                    handledBeepPackage();
                                });
                            }, function (err) {
                                for (var i = 0; i < skipPacks.length; i++) {
                                    beepPackages.splice(beepPackages.indexOf(skipPacks[i]), 1);
                                }
                                copyModule();
                                modColl.reset();
                            });
                        }, false, null, 'vbc:cmof_EObject-name');
                        
                    } else {
                        count++;
                        if (count > 10) {
                            finalCallback();
                            return;
                        }else{
                            finalCallback();
                        }
                        setTimeout(getRDFModel, 3);
                    }
                }
                getRDFModel();
            });
        }
        function replaceModuleIds(commonAlt) {
            var replaceIds = {};
            function replacePackageIds(pack) {
                var keys = Object.getOwnPropertyNames(replaceIds);
                var packStr = JSON.stringify(pack);
                for (var k = 0; k < keys.length; k++) {
                    var re = new RegExp(keys[k], 'g');
                    packStr = packStr.replace(re, replaceIds[keys[k]]);
                }
                return JSON.parse(packStr);
            }
            for (var i = 0; i < beepPackages.length; i++) {
                var currentPackAlt = beepPackages[i].id.substr(0, beepPackages[i].id.lastIndexOf('@') + 1);
                replaceIds[currentPackAlt] = commonAlt;
                beepPackages[i] = replacePackageIds(beepPackages[i]);
            }
        }
        function copyModule() {
            //var newModuleId = self.id();
            //var newModuleSuffix = newModuleId.substr(newModuleId.lastIndexOf('@') + 1);
			var commonAlt = planModel.getCommonRepositoryId()//'@' + newModuleSuffix + "-Common@";
            replaceModuleIds(commonAlt);
            var moduleAlts = [];
            moduleAlts.push(commonAlt);
            var workspaces = {};
            DataManager.getDataManager().set('suppressLogging', true);
            async.eachSeries(moduleAlts, function (altId, altHandleCallback) {
                var workspaceStore = DataManager.getDataManager().get("workspaceStore");
                utils.invokeGetDefaultDirectory(workspaceStore, function (workspace) {
                    workspaces[altId] = workspace;
                    workspace.save();
                    Backbone.Relational.store.register(workspace);
                    var initialiseWorkspaceDataCount = 0;
                    function getAltWorkspaceData(altId) {
                        DataManager.getDataManager().getWorkspaceDataWithId(altId, function (wsData) {
                            function sleepAndCheckModel() {
                                if (wsData.get('rdfModel')) {
                                    altHandleCallback();
                                } else {
                                    setTimeout(sleepAndCheckModel, 100);
                                }
                            }
                            if (!wsData) {
                                initialiseWorkspaceDataCount++;
                                if (initialiseWorkspaceDataCount > 10) {
                                    altHandleCallback('Failed to initialise Workspace. Import of Module failed');
                                    return;
                                } else {
                                    setTimeout(function () { getAltWorkspaceData(altId); }, 300);
                                }
                            } else {
                                sleepAndCheckModel();
                            }
                            //altHandleCallback();	
                        });
                    }
                    getAltWorkspaceData(altId);
                }, altId);
            }, function (err) {
                moduleAlts.length = 0;
                DataManager.getDataManager().set('suppressLogging', false);
                if (err) {
                    //window.utils.stopSpinner('importVocabulary');
                    bootbox.alert('' + err);
					finalCallback();
                } else {
					var selectedPlan = DataManager.getDataManager().get('currentPlan') ? DataManager.getDataManager().get('currentPlan') : planModel;
                    createPackages(selectedPlan);
                }
            });

            function createPackages(plan) {
                var dataManager = DataManager.getDataManager();
                dataManager.set('suppressLogging', false);
                var packageModels = [];
                var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
                async.eachSeries(beepPackages, function (pack, fileHandleCallback) {
                    dataManager.clearSaveInterval();
                    var packId = pack.id;
                    var altId = packId.substr(0, packId.lastIndexOf('@') + 1);

                    dataManager.getWorkspaceData(workspaces[altId], function (wsData) {
                        dataManager.set('suppressLogging', true);
                        dataManager.set('suppressChangeLogging', true);
                        var copyPck;
                        if (pack.type === 'beeppackage_BeepModule') {
                            copyPck = new BeepModule(pack, { silent: true });
                            copyPck.set("planId",plan.id);//special case as currentPlan is not set
                        }
                        packageModels.push(copyPck);
                        dataManager.get('initializedPackages').add({ 'id': copyPck.get('id'), 'version': parseInt(copyPck.get('version')), 'model': copyPck });
                        dataManager.set('suppressLogging', false);
                        dataManager.set('suppressChangeLogging', false);
                        fileHandleCallback();
                    });
                }, function () {
                    for(var i=0;i<beepPackages.length;i++){
                        serverChangeSet = dataManager.addChangeObjectForFile(serverChangeSet,beepPackages[i],plan);
                    }
                    beepPackages.length = 0;
                    /*if (dataManager.canvasModules && plan) {
                        dataManager.canvasModules[plan.id] = null;
                    }*/
                    async.eachSeries(packageModels, function (packModel, generatedRDF) {
                        var packId = packModel.get('id');
                        var altId = packId.substr(0, packId.lastIndexOf('@') + 1);
                        dataManager.getWorkspaceData(workspaces[altId], function (wsData) {
                            var packRDFModel = dataManager.getRDFModel(altId);
                            function checkAndAddRDFForModel() {
                                if (packRDFModel) {
                                    addRDFFormModel();
                                } else {
                                    setTimeout(checkAndAddRDFForModel, 10);
                                }
                            }
                            function addRDFFormModel() {
                                packRDFModel.addRDFForModel(packModel, function () {
                                    function savePackageRDF() {
                                        packRDFModel.save();
                                        packModel.save();
                                        dataManager.getWorkspaceDataWithId(altId, function (wsData) {
                                            var copyAltVDMStore = wsData.get('vdmStore');
                                            packModel.lawnchair = copyAltVDMStore;
                                            generatedRDF();
                                        });
                                    }
                                    savePackageRDF();
                                }, true);
                            }
                            checkAndAddRDFForModel();
                        });
                    }, function () {
                        packageModels.length = 0;
                        dataManager.saveChangeSetToBackend(serverChangeSet,function(){
                            if (finalCallback) {
                                finalCallback();
                            }
                            /*DataManager.getDataManager().saveData({
                                success: function () {
                                    if (finalCallback) {
                                        finalCallback();
                                    }
                                },
                                error: function () {
                                    console.log("Error saving data before creating Revision");
                                }
                            });*/
                        })
                    });
                });
            }
        }
        handleFiles();
    }
		  
    static unzipModuleFiles(fileObject, callback){
		var reader = new FileReader();
		reader.onload = function (ev) {
		  	var moduleContent = reader.result;
		  	var newplanZip = new jszip();
		  	newplanZip.loadAsync(moduleContent).then(function (modZip) {
		  	var obj = modZip.files;
		  	delete obj['manifest.json'];
		  	var files = jQuery.map(obj, function(el) { return el });//converting json to array
		  	var beepPackages = [];
		  	async.each(files,function(modFile,handledFile){
				modFile.async("string").then(function (fileText) {
					var modPack = JSON.parse(fileText);
					if(modPack.type==="beeppackage_BeepModule")
					{
						beepPackages.push(modPack);
						handledFile();
					}else{
						handledFile(DataManager.getDataManager().get('localeManager').get('ImportModuleCorrupt'));
					}
				});
			},function(err){
				if(!err){
                    callback(beepPackages);
				}else{
					bootbox.alert(err);
                    callback();	 
				}
			});
		  	
		  });	
		};
		reader.readAsBinaryString(fileObject);
    };
    
    static createEcoMapFromFiles(beepPackages, model, finalCallback, altId, selectedMapList, existingEcoMapPackId,selectedCapLibList) {
			var newclPackId = model.id;
			var newclPackSuffix = newclPackId.substr(newclPackId.lastIndexOf('@') + 1);
			var commonAlt = '@' + newclPackSuffix + "-Common@";
			var replaceIds = {};
			var capabilityexists = false;
			function replacePackageIds(pack) {
				var keys = Object.getOwnPropertyNames(replaceIds);
				var packStr = JSON.stringify(pack);
				for (var k = 0; k < keys.length; k++) {
					var re = new RegExp(keys[k], 'g');
					packStr = packStr.replace(re, replaceIds[keys[k]]);
				}
				return JSON.parse(packStr);
			}
			var vocabularyAlts = [];
			vocabularyAlts.push(altId);
			
			for (var i = 0; i < beepPackages.length; i++) {//doing this to replace images id on diagrams
				if(beepPackages[i].type == "beeppackage_CodeContainer"){
					var currentPackAlt = beepPackages[i].id.substr(0, beepPackages[i].id.lastIndexOf('@') + 1);
					replaceIds[currentPackAlt] = commonAlt;
					beepPackages[i] = replacePackageIds(beepPackages[i]);
				} else if(beepPackages[i].type == "ecomap_EcoMap"){
					var diags = beepPackages[i].diagrams;
					for(var j=0;j<diags.length;j++){
						var capList = diags[j].capability;
						if(capList) {
							for(var k=0;k<capList.length;k++){
								var cap = capList[k];
								var currentPackAlt = cap.substr(0, cap.lastIndexOf('@') + 1);
								replaceIds[currentPackAlt] = commonAlt;
								//capabilityexists = true;
							}
						}
					}
				}
			}
			
			for (var i = 0; i < beepPackages.length; i++) {
				var currentPackAlt = beepPackages[i].id.substr(0, beepPackages[i].id.lastIndexOf('@') + 1);
				if(beepPackages[i].type == "ecomap_EcoMap"){
					//replaceIds[currentPackAlt] = altId;
                    replaceIds[window.utils.htmlEscape(currentPackAlt)]  = window.utils.htmlEscape(altId);
					beepPackages[i] = replacePackageIds(beepPackages[i]);
					if(!existingEcoMapPackId){
						var altModal = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id:altId}); 
						var pprId = DataManager.getDataManager().guidGeneratorByOwner(model);
                        var PlanPackageReference = Backbone.Relational.store.getObjectByName("transformation.PackageReference");
						var newPacRef = new PlanPackageReference({id:pprId,beepReference:beepPackages[i].id,name:beepPackages[i].name,description:beepPackages[i].description,version:beepPackages[i].version,isMain:false,phaseDesignPartOwner:altModal});
						newPacRef.set('beepType', "ecomap_EcoMap");
               			altModal.get('phaseDesignPart').add(newPacRef); 
					}
				}else if(beepPackages[i].type === "vdml_ValueDeliveryModel"){
				    var planId = model.id.substr(model.id.lastIndexOf('@') + 1)
					var commonAlt = "@" + planId + "-Common@";
					replaceIds[currentPackAlt] = commonAlt;
					beepPackages[i] = replacePackageIds(beepPackages[i]);
					selectedCapLibList = replacePackageIds(selectedCapLibList);
					capabilityexists = true;
				}
			}
			/*if(capabilityexists){
				DataManager.getDataManager().set('currentPlan',model)
			}*/
			if(beepPackages.length > 1){
				vocabularyAlts.push(commonAlt);
			}
			selectedMapList = replacePackageIds(selectedMapList);
			var workspaces = {};
			DataManager.getDataManager().set('suppressLogging', true);
			async.eachSeries(vocabularyAlts, function (altId, altHandleCallback) {
				var workspaceStore = DataManager.getDataManager().get("workspaceStore");
				utils.invokeGetDefaultDirectory(workspaceStore, function (workspace) {
					workspaces[altId] = workspace;
					workspace.save();
					Backbone.Relational.store.register(workspace);
					var initialiseWorkspaceDataCount = 0;
					function getAltWorkspaceData(altId) {
						DataManager.getDataManager().getWorkspaceDataWithId(altId, function (wsData) {
							function sleepAndCheckModel() {
								if (wsData.get('rdfModel')) {
									altHandleCallback();
								} else {
									setTimeout(sleepAndCheckModel, 100);
								}
							}
							if (!wsData) {
								initialiseWorkspaceDataCount++;
								if (initialiseWorkspaceDataCount > 10) {
									altHandleCallback('Failed to initialise Workspace. Import of Library Package failed');
									return;
								} else {
									setTimeout(function () { getAltWorkspaceData(altId); }, 300);
								}
							} else {
								sleepAndCheckModel();
							}
							//altHandleCallback();	
						});
					}
					getAltWorkspaceData(altId);
				}, altId);
			}, function (err) {
				vocabularyAlts.length = 0;
				DataManager.getDataManager().set('suppressLogging', false);
				if (err) {
					bootbox.alert('' + err);
					finalCallback();
				} else {
					for(var p=beepPackages.length-1;p>=0;p--){
						if(beepPackages[p].type === "ecomap_EcoMap"){
						    spliceUnwanted(beepPackages[p],selectedMapList,"ecomap_EcoMap");
							break;
						}
					}
					if(capabilityexists) {
					    spliceUnwanted(beepPackages,selectedCapLibList,"vdml_ValueDeliveryModel");
					}
					createPackages(beepPackages)
				}
			});
			function spliceUnwanted(pack,selectedList,type){
				if(type === "ecomap_EcoMap"){
					for(var p=pack.diagrams.length-1;p>=0;p--){
						var isExist = false;
						for(var s=0;s<selectedList.length;s++){
							if(pack.diagrams[p].id === selectedList[s]){
							   isExist = true;
							}
						}
						if(!isExist){
							pack.diagrams.splice(p,1);
						}
					}
				}else if(type === "vdml_ValueDeliveryModel"){
					for(var p=pack.length-1;p>=0;p--){ 
						  if(pack[p].type === type){
							 var isExist = false;
							 for(var s=0;s<selectedList.length;s++){
							     if(pack[p].id === selectedList[s]){
								 	 isExist = true;
									 break;
								 }
							 }
							 if(!isExist){
							 	 pack.splice(p,1);
							 }
						  }
					}
				}
				return pack;
			}
			function createPackages(beepPackages) {
				DataManager.getDataManager().set('suppressLogging', false);
				var packageModels = [];
				async.eachSeries(beepPackages, function (pack, fileHandleCallback) {
					var packId = pack.id;
					var altId = packId.substr(0, packId.lastIndexOf('@') + 1);
					if (pack.type === "beeppackage_CodeContainer") {
						DataManager.getDataManager().getWorkspaceData(workspaces[altId], function (wsData) {
							var planId = model.id.substr(model.id.lastIndexOf('@') + 1)
							var commonAltId = "@" + planId + "-Common@";
							var altRDFModel = DataManager.getDataManager().getRDFModel(commonAltId);
							altRDFModel.getTypeOfSubject(pack.id,function(isExist){
								if (isExist) {
									fileHandleCallback();
								} else {
									var codeContainer = CodeContainer.getBlobInstance(pack.name, pack.name, model, pack.id);
                                    codeContainer.set('tags', pack.tags, {silent: true});
                                    var imgList = [];
                                    var imgTagList = [];
                                    if(pack.tags){
                                        var tagNames = pack.tags.split(',');
                                        for(var i=0;i<tagNames.length;i++){
                                            imgTagList.push({id:'',name:tagNames[i]});
                                        }
                                    }
                                    //var type = pack.name.substring(pack.name.lastIndexOf(".") + 1);
                                    imgList.push({'dataReference':pack.id,'packageId':model.id,'code':pack.code,'name':pack.name,'inputTags':imgTagList,'fullyQualifiedName':pack.name});
                                    var url = encodeURI("/vdmbee/image/save?legalEntityId="+DataManager.getDataManager().get("currentLegalEntity").entityId);
                                    DataManager.getDataManager().get('vmpServerService').postAllPromise(url,JSON.stringify(imgList)).then(function(imgResourceList) {
                                        if(imgResourceList){
                                            for(var key in imgResourceList){
                                                for(var i=0;i<beepPackages.length;i++){
                                                    if(pack.type == "beeppackage_CodeContainer" && pack.id == key){
                                                        codeContainer.set('dataReference',imgResourceList[key]);
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                        codeContainer.set('code', pack.code, {silent: true});
                                        fileHandleCallback();
                                    });
									//codeContainer.set('code', pack.code);
									//fileHandleCallback();
								}
							});
						});
					}  else if (pack.type === 'vdml_ValueDeliveryModel') { 
						DataManager.getDataManager().getWorkspaceData(workspaces[altId], function (wsData) {
							    DataManager.getDataManager().set('suppressLogging', true);
								DataManager.getDataManager().set('suppressChangeLogging', true);
								var copyPck = new ValueDeliveryModel(pack, { silent: true });
								packageModels.push(copyPck);
								DataManager.getDataManager().get('initializedPackages').add({ 'id': copyPck.get('id'), 'version': parseInt(copyPck.get('version')), 'model': copyPck });
								DataManager.getDataManager().set('suppressLogging', false);
								DataManager.getDataManager().set('suppressChangeLogging', false);
							    fileHandleCallback();
						});
					} else if (pack.type === 'ecomap_EcoMap') { 
						DataManager.getDataManager().getWorkspaceData(workspaces[altId], function (wsData) {
							if(existingEcoMapPackId){
								var vdmStore = DataManager.getDataManager().getVDMStore(altId);
                                DataManager.getDataManager().fetchDocumentFromPackage(existingEcoMapPackId, 'ecomap_EcoMap', DataManager.getDataManager().get('currentVDMVersion'), existingEcoMapPackId, 'ecomap_EcoMap', vdmStore, {
                                    success: function (packModel) {
										function createMaps(mapPackModal){
											packageModels.push(mapPackModal);
											var diags = pack.diagrams;
											for(var i=0;i<selectedMapList.length;i++){
												if(!mapPackModal.get('diagrams').findWhere({'id':selectedMapList[i]})){
													for(var j=0;j<diags.length;j++){
														if(diags[j].id == selectedMapList[i]){
															var xml = diags[j].data;
															var diagramInstance = null;
															if(diags[j].type == "vdml_EcoMapDiagram") {
																diagramInstance = EcoMapDiagram.getInstance(diags[j].name, diags[j].description, mapPackModal, selectedMapList[i]);
															}else if(diags[j].type == "vdml_StrategyMapDiagram"){
																diagramInstance = StrategyMapDiagram.getInstance(diags[j].name, diags[j].description, mapPackModal, selectedMapList[i]);
															}else if(diags[j].type == "vdml_ValueStreamMapDiagram"){
																diagramInstance = ValueStreamMapDiagram.getInstance(diags[j].name, diags[j].description, mapPackModal, selectedMapList[i]);
															}
															if(xml){
																xml = mapPackModal.removeMid(xml,true);
																diagramInstance.set('data',xml);
															}
															break;
														}
													}
												}
											}
											fileHandleCallback();
										}
										var pacRepId = DataManager.getDataManager().getRepositoryId(packModel.get('id'));
										if(pacRepId == altId){
											createMaps(packModel);
										}else {
											//DataManager.getDataManager().set('currentPlan', model);
											packModel.createRevision(altId, pacRepId, function(mapPackModal){
												createMaps(mapPackModal);
											});
										}
                                    },
                                    error: function (error) {
                                        fileHandleCallback();
                                    }
								});
							} else {
								DataManager.getDataManager().set('suppressLogging', true);
								DataManager.getDataManager().set('suppressChangeLogging', true);
								var copyPck = new EcoMap(pack, { silent: true });
								packageModels.push(copyPck);
								DataManager.getDataManager().get('initializedPackages').add({ 'id': copyPck.get('id'), 'version': parseInt(copyPck.get('version')), 'model': copyPck });
								DataManager.getDataManager().set('suppressLogging', false);
								DataManager.getDataManager().set('suppressChangeLogging', false);
								fileHandleCallback();
							}
						});
					} else {
						fileHandleCallback();
					}
				}, function () {
                    if(!existingEcoMapPackId){
                        var serverChangeSet = DataManager.getDataManager().get(DataManager.getDataManager().CURRENT_CHANGESET);
                        for(var i=0;i<beepPackages.length;i++){
                            serverChangeSet = DataManager.getDataManager().addChangeObjectForFile(serverChangeSet,beepPackages[i],model);
                        }
                    }                    
					beepPackages.length = 0;
					async.eachSeries(packageModels, function (packModel, generatedRDF) {
						if(!existingEcoMapPackId && packModel.get('type') != "vdml_ValueDeliveryModel") {
							_.each(packModel.get('diagrams').models,function(diag){
								var xml = diag.get('data');
								var diagType = diag.get('type');
								if(xml){
									xml = packModel.removeMid(xml,false);									
									if(diagType == "vdml_ValueStreamMapDiagram"){
										var xmlDoc = jQuery.parseXML(xml);
										var $xml = jQuery(xmlDoc);	
										var nodes = $xml.find('[mcpid]');
										nodes.each(function (callback, node) {
											var cap = window.utils.getElementModel(node.getAttribute('mcid'),['vdml.CapabilityCategory','vdml.CapabilityDefinition']);
											if(!cap){
												diag.get('capability').remove(node.getAttribute('mcid'));
												node.removeAttribute('mcid');
												node.removeAttribute('mcpid');
											}
										});
										xml = (new XMLSerializer()).serializeToString(xmlDoc);
									}
									diag.set('data',xml);
								}
							})
						}
						var packId = packModel.get('id');
						var altId = packId.substr(0, packId.lastIndexOf('@') + 1);
						DataManager.getDataManager().getWorkspaceData(workspaces[altId], function (wsData) {
							var packRDFModel = DataManager.getDataManager().getRDFModel(altId);
							function checkAndAddRDFForModel() {
								if (packRDFModel) {
									addRDFFormModel();
								} else {
									setTimeout(checkAndAddRDFForModel, 10);
								}
							}
							function addRDFFormModel() {
								packRDFModel.addRDFForModel(packModel, function () {
									function savePackageRDF() {
										packRDFModel.save();
										DataManager.getDataManager().getWorkspaceDataWithId(altId, function (wsData) {
											var copyAltVDMStore = wsData.get('vdmStore');
											packModel.lawnchair = copyAltVDMStore;
											DataManager.getDataManager().checkForCorrection(packModel, [], function () {
												//packModel.save();
												generatedRDF();
											});
										});
									}
									savePackageRDF();
								}, true);
							}
							checkAndAddRDFForModel();
						});
					}, function () {
						packageModels.length = 0;
                        DataManager.getDataManager().saveChangeSetToBackend(serverChangeSet,function(){
                            finalCallback();
                        })
					});
				});
			}
	}	
	
	static unzipCLPackFiles(fileObject,types,callback){
	        var reader = new FileReader();
	        reader.onload = function (ev) {
	            //document.getElementById('contents').value = reader.result;
	            var clPackContent = reader.result;
	            var newclPackZip = new jszip();
	            newclPackZip.loadAsync(clPackContent).then(function (clpackZip) {
					var obj = clpackZip.files;
					delete obj['manifest.json'];
					var files = jQuery.map(obj, function(el) { return el });//converting json to array
					var beepPackages = [];
					async.each(files,function(vocFile,handledFile){
						vocFile.async("string").then(function (fileText) {
							var clPack = JSON.parse(fileText);
							if (types.indexOf(clPack.type) > -1) {
								beepPackages.push(clPack);
								handledFile();
							}
							else {
								handledFile(DataManager.getDataManager().get('localeManager').get('ImportLibraryPackageCorrupt'));
							}
						});
					}, function (err){
						if(!err){
							callback(beepPackages);
						}else{
							bootbox.alert(err);
							callback();
							return;	 
						}
					});          	
	            });	
	        };
	        reader.readAsBinaryString(fileObject); 
	};
	
	loadCapabilityLibraries(callback){
		var capLibsCollectionArray = [];
		var planId = DataManager.getDataManager().get('currentPlan').id;
        var commonAltId = planId.substr(planId.lastIndexOf('@')) + "-Common@";
        DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
            if (result && result.length > 0) {
                async.eachSeries(result.models, function (pack, packHandled) {
                    if (pack.get('id').indexOf("Common") > -1) {
                        if (pack.get('id').indexOf('CommonPackage') === -1) {
                            var repId = DataManager.getDataManager().getRepositoryId(pack.get('id'));
                            var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                            DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'vdml_ValueDeliveryModel', pack.get('version'), pack.get('id'), 'vdml_ValueDeliveryModel', vdmStore, {
                                success: function (model) {
								    capLibsCollectionArray.push(model.get('capabilityLibrary'));
									packHandled();
                                },
                                error: function (error) {
									console.log('Unable to load selected Package');
									packHandled();
                                }
                            }, true, null);
                        }else{
							packHandled();
						}
					}else{
						packHandled();
					}
                }, function () {
					callback(capLibsCollectionArray);
				});
            }else{
				callback();
			}
        }, false, null, true);
    };

    loadValueLibraries(callback) {
            var valLibsCollectionArray = [];
            var planId = DataManager.getDataManager().get('currentPlan').id;
            var commonAltId = planId.substr(planId.lastIndexOf('@')) + "-Common@";
            DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
                if (result && result.length > 0) {
                    async.eachSeries(result.models, function (pack, packHandled) {
                        if (pack.get('id').indexOf("Common") > -1) {
                            if (pack.get('id').indexOf('CommonPackage') === -1) {
                                var repId = DataManager.getDataManager().getRepositoryId(pack.get('id'));
                                var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                                DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'vdml_ValueDeliveryModel', pack.get('version'), pack.get('id'), 'vdml_ValueDeliveryModel', vdmStore, {
                                    success: function (model) {
                                        valLibsCollectionArray.push(model.get('valueLibrary'));
                                        packHandled();
                                    },
                                    error: function (error) {
                                        console.log('Unable to load selected Package');
                                        packHandled();
                                    }
                                }, true, null);
                            } else {
                                packHandled();
                            }
                        } else {
                            packHandled();
                        }
                    }, function () {
                            callback(valLibsCollectionArray);
                    });
                } else {
                    callback();
                }
            }, false, null, true);
    };
	/*loadMeasureLibraries(callback){
		var measureLibsCollectionArray =[];
        var planId = DataManager.getDataManager().get('currentPlan').id;
        var commonAltId = planId.substr(planId.lastIndexOf('@')) + "-Common@";
        DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
            if (result && result.length > 0) {
                async.eachSeries(result.models, function (pack, packHandled) {
                    if (pack.get('id').indexOf("Common") > -1) {
                        if (pack.get('id').indexOf('CommonPackage') === -1) {
                            var repId = DataManager.getDataManager().getRepositoryId(pack.get('id'));
                            var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                            DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'vdml_ValueDeliveryModel', pack.get('version'), pack.get('id'), 'vdml_ValueDeliveryModel', vdmStore, {
                                success: function (model) {
								    if(model.get('metricsModel').length > 0){
									   for(var i=0;i<model.get('metricsModel').length;i++){
									     var libs = model.get('metricsModel');
									     measureLibsCollectionArray.push(libs);
										
										}
									}
									packHandled();
                                },
                                error: function (error) {
									console.log('Unable to load selected Package');
									packHandled();
                                }
                            }, true, null);
                        }else{
							packHandled();
						}
					}else{
						packHandled();
					}
                }, function () {
					callback(measureLibsCollectionArray);
				});
            }else{
				callback();
			}
        }, false, null, true);
	};*/
	loadCapabilitiesFromLibrary(capLib, callback){
		var capabilities = [];
        var capability = capLib.get('capability');
        for (var i = 0; i < capability.length; i++) {
            if (capability.at(i).get('type') === "vdml_CapabilityCategory" || capability.at(i).get('type') === "vdml_CapabilityDefinition") {
				capabilities.push(capability.at(i));
            }
        }
		callback(capabilities);
	};

	loadLibraryPackages(callback) {
		var self = this;
		var allCapabilites = [];
		self.loadCapabilityLibraries(function (capLibsCollectionArray) {
			async.eachSeries(capLibsCollectionArray, function (capLibPack, capLibPackPackHandled) {
				async.eachSeries(capLibPack.models, function (capLib, cPackHandled) {
					self.loadCapabilitiesFromLibrary(capLib, function (capabilities) {
						allCapabilites = allCapabilites.concat(capabilities);
						cPackHandled();
					});
				}, function () {
					capLibPackPackHandled();
				});
			}, function () {
				callback(allCapabilites);
			});
			//for(var i = 0; i < capLibsCollectionArray.length; i++){
			//	capLibsCollectionArray[i].each(function(capLib){
			//		self.loadCapabilitiesFromLibrary(capLib, function(capabilities){
			//			allCapabilites = allCapabilites.concat(capabilities);
			//		});
			//	});
			//	callback(allCapabilites);
			//}
		});
	};
	
	loadCapDependencies(callback) {
		var self = this;
		var capDepencies = [];
		var planId = DataManager.getDataManager().get('currentPlan').id;
		var commonAltId = planId.substr(planId.lastIndexOf('@')) + "-Common@";
		DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
			if (result && result.length > 0) {
				async.each(result.models, function (pack, packHandled) {
					if (pack.get('id').indexOf("Common") > -1) {
						if (pack.get('id').indexOf('CommonPackage') === -1) {
							var repId = DataManager.getDataManager().getRepositoryId(pack.get('id'));
							var vdmStore = DataManager.getDataManager().getVDMStore(repId);
							DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'vdml_ValueDeliveryModel', pack.get('version'), pack.get('id'), 'vdml_ValueDeliveryModel', vdmStore, {
								success: function (model) {
									var capLib = model.get('capabilityLibrary');
									for (var j = 0; j < capLib.length; j++) {
										var capDep = capLib.at(j).get('capabilityDependency');
										for (var i = 0; i < capDep.length; i++) {
											capDepencies.push(capDep.at(i));
										}
									}
									packHandled();
								},
								error: function (error) {
									console.log('Unable to load selected Package');
									packHandled();
								}
							}, true, null);
						} else {
							packHandled();
						}
					} else {
						packHandled();
					}
				}, function () {
					callback(capDepencies);
				});
			} else {
				callback();
			}
		}, false, null, true);
	}
    loadAllPackages(callback) {
        var model = this;
        var phases = model.get('phase');
        var dataManager = DataManager.getDataManager();
        var packages = this.getPlanPackageList();
        var planId = model.id.substr(model.id.lastIndexOf('@'));
        var commonAltId = planId + "-Common@";
        
        function loadCommonPackages(commonPackages) {
            for (var i = 0; i < commonPackages.length; i++) {
                if (commonPackages.at(i).get('type') !== "smm_SmmModel") {
                    packages.push({ beepReference: commonPackages.at(i).get('id'), beepType: commonPackages.at(i).get('type') });
                }
            }
            packages = _.uniq(packages, function (x) {
                return x.beepReference;
            });
            packages.sort((a, b) => {//lib need to load first for gen rdf for dependent packages
                const aHasPriority = a.beepReference.toLowerCase().includes("common") || a.beepReference.includes("@BEEPPlans@");
                const bHasPriority = b.beepReference.toLowerCase().includes("common") || b.beepReference.includes("@BEEPPlans@");
                if (aHasPriority && !bHasPriority) {
                    return -1; // a comes before b
                } else if (!aHasPriority && bHasPriority) {
                    return 1; // b comes before a
                } else {
                    return 0; // no change in order
                }
            });
            var planDoc = dataManager.get("artifactsDocuments")[model.id];
            var planDocVersion = planDoc ? planDoc.documentVersion : model.get('documentVersion');
            var packCount = 0
            async.eachSeries(packages, function (pack, packHandleCallback) {
                var packAltId = dataManager.getRepositoryId(pack.beepReference);
                function getAndWaitRepository() {
                    dataManager.getWorkspaceDataWithId(packAltId, function (wsData) {
                        if (wsData && wsData.get('rdfModel')) {
                            var vdmStore = dataManager.getVDMStore(packAltId);
                            var artDoc = dataManager.get("artifactsDocuments")[pack.beepReference];
                            var docVersion = artDoc ? artDoc.documentVersion:planDocVersion;
                            packCount++;
                            dataManager.fetchDocumentFromPackage(pack.beepReference, pack.beepType, dataManager.get('currentVDMVersion'), pack.beepReference, pack.beepType, vdmStore, {
                                success: function (packModel) {
                                    if(packCount == 3){
                                        window.utils.showSpinnerPercentage(60);
                                    }
                                    console.log("packCount:"+packCount);
                                    packHandleCallback();
                                },
                                error: function (error) {
                                    console.log("error fetching pack:"+pack.beepReference+" Type:"+pack.beepType+ "ver:"+docVersion);
                                    packHandleCallback();
                                },
                                documentVersion:docVersion
                            });
                        } else {
                            console.log("getAndWaitRepository:"+packAltId);
                            setTimeout(getAndWaitRepository, 0);
                        }
                    });
                }
                getAndWaitRepository();
            }, function (err) {
                packages.length = 0;
                //model.fetchCustomPlanPackages(null, function(packagesList){
                    callback();
                //});
            });
        };
        if(!dataManager.getRDFModel(commonAltId)){
            dataManager.getModelIdInitializedWSData(commonAltId,function(){
                dataManager.getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_BeepPackage', loadCommonPackages, null, null, 'vbc:cmof_EObject-name', null, true);        
            })
        }else{
            dataManager.getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_BeepPackage', loadCommonPackages, null, null, 'vbc:cmof_EObject-name', null, true);
        }
    };

    generatePlanJsons(callback) {
        var planJsons = [];
        var model = this;
        var json = model.toJSON();
        planJsons.push(json);
        var phases = model.get('phase');
        var packages = model.getPlanPackageList();
        var planId = model.id.substr(model.id.lastIndexOf('@'));
        var commonAltId = planId + "-Common@";
        var dataManager = DataManager.getDataManager();
        function loadCodeContainers(codeContainers) {
            async.eachSeries(codeContainers.models, function (codeContainer, dataHandled) {
                CodeContainer.getBlobInstanceWithId(codeContainer.id, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
                    if (codeContainer && codeContainer.getParent() && codeContainer.getParent().get('type') != 'beeppackage_BeepModule') {
                        var json = codeContainer.toJSON();
                        planJsons.push(json);
                        DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                    } else {
                        console.log('Unable to load selected Package');
                    }
                    dataHandled();
                });
            }, function () {
                callback(planJsons);
            });
        };
        function loadCommonPackages(commonPackages) {
            for (var i = 0; i < commonPackages.length; i++) {
                if (commonPackages.at(i).get('type') !== "smm_SmmModel") {
                    packages.push({ beepReference: commonPackages.at(i).get('id'), beepType: commonPackages.at(i).get('type') });
                }
            }
            packages = _.uniq(packages, function (x) {
                return x.beepReference;
            });
            async.eachSeries(packages, function (pack, packHandleCallback) {
                var packAltId = pack.beepReference.substr(0, pack.beepReference.lastIndexOf('@') + 1);
                function getAndWaitRepository() {
                    DataManager.getDataManager().getWorkspaceDataWithId(packAltId, function (wsData) {
                        if (wsData.get('rdfModel')) {
                            var vdmStore = DataManager.getDataManager().getVDMStore(packAltId);
                            DataManager.getDataManager().fetchDocumentFromPackage(pack.beepReference, pack.beepType, DataManager.getDataManager().get('currentVDMVersion'), pack.beepReference, pack.beepType, vdmStore, {
                                success: function (packModel) {
                                    json = packModel.toJSON();
                                    planJsons.push(json);
                                    packHandleCallback();
                                },
                                error: function (error) {
                                    packHandleCallback();
                                }
                            });
                        } else {
                            setTimeout(getAndWaitRepository, 0);
                        }
                    });
                }
                getAndWaitRepository();
            }, function (err) {
                packages.length = 0;
                DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_CodeContainer', loadCodeContainers, null, null, 'vbc:cmof_EObject-name', null, true);
            });
        };
        if(!dataManager.getRDFModel(commonAltId)){
            dataManager.getModelIdInitializedWSData(commonAltId,function(){
                dataManager.getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_BeepPackage', loadCommonPackages, null, null, 'vbc:cmof_EObject-name', null, true);        
            })
        }else{
            dataManager.getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_BeepPackage', loadCommonPackages, null, null, 'vbc:cmof_EObject-name', null, true);
        }
    };
    getPlanPackageList(type,searchArtifacts){
        var self = this;
        var packages = [];
        self.get('phase').each(function (phase) {
            var alts = phase.get('phaseAlternative');
            alts.each(function (alt) {
                alt.get('phaseDesignPart').each(function (ref) {
                    if(!type){
                        packages.push({ beepReference: ref.get('beepReference'), beepType: ref.get('beepType') });
                    } else if(type && type == ref.get('beepType')){
                        packages.push({ beepReference: ref.get('beepReference'), beepType: ref.get('beepType') });
                    }
                });
            });
        });
        if(!window.loadedPackages[self.get('id')] || searchArtifacts){
            var packList = DataManager.getDataManager().get('planPackages')[self.get("id")];
            if(packList){
                for(var i=0;i<packList.length;i++){
                    if(packages.filter(e => e.beepReference === packList[i].documentId).length == 0){
                        if(!type){
                            packages.push({ beepReference: packList[i].documentId, beepType: packList[i].type });
                        } else if(type && type.includes(packList[i].type)){
                            packages.push({ beepReference: packList[i].documentId, beepType: packList[i].type });
                        }
                    }
                }
            }
        }
        return packages;
    }

    createZip(callback) {
        var model = this;
        var text = JSON.stringify(model);
        var zip = new jszip();
        zip.file(model.get('id') + ".json", text);
        var manifestJson = window.utils.generateManifest(model);
        zip.file("manifest.json", manifestJson);
        var phases = model.get('phase');
        var planId = model.id.substr(model.id.lastIndexOf('@'));
        var commonAltId = planId + "-Common@";
        var packages = model.getPlanPackageList(null,true);
        /*this.fetchCustomPlanPackages(null, function(packages){
            for (var j = 0; j < packagesList.length; j++) {
                var packModel = packagesList[j];
                var text = JSON.stringify(packModel);
                zip.file(packModel.get('id') + ".json", text);
            }
            zip.generateAsync({ type: "blob" }).then(function (content) {
                callback(content);
            });
        });*/
        
        function loadCodeContainers(codeContainers) {
            async.eachSeries(codeContainers.models, function (codeContainer, dataHandled) {
                CodeContainer.getBlobInstanceWithId(codeContainer.id, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
                    if (codeContainer && codeContainer.getParent() && codeContainer.getParent().get('type') != 'beeppackage_BeepModule') {
                        var text = JSON.stringify(codeContainer.toJSON());
                        zip.file(codeContainer.get('id') + ".json", text);
                        DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                    } else {
                        console.log('Unable to load selected Package');
                    }
                    dataHandled();
                });
            }, function () {
                if(!DataManager.getDataManager().getRDFModel(commonAltId)){
                    DataManager.getDataManager().getModelIdInitializedWSData(commonAltId,function(){
                        DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_BeepPackage', loadCommonPackages, null, null, 'vbc:cmof_EObject-name', null, true);        
                    })
                }else{
                    DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_BeepPackage', loadCommonPackages, null, null, 'vbc:cmof_EObject-name', null, true);
                }
            });
        };
        function loadCommonPackages(commonPackages) {
            for (var i = 0; i < commonPackages.length; i++) {
                if (commonPackages.at(i).get('type') !== "smm_SmmModel") {
                    packages.push({ beepReference: commonPackages.at(i).get('id'), beepType: commonPackages.at(i).get('type') });
                }
            }
            packages = _.uniq(packages, function (x) {
                return x.beepReference;
            });
            async.eachSeries(packages, function (pack, packHandleCallback) {
                var packAltId = pack.beepReference.substr(0, pack.beepReference.lastIndexOf('@') + 1);
                function getAndWaitRepository() {
                    DataManager.getDataManager().getWorkspaceDataWithId(packAltId, function (wsData) {
                        if (wsData.get('rdfModel')) {
                            var vdmStore = DataManager.getDataManager().getVDMStore(packAltId);
                            DataManager.getDataManager().fetchDocumentFromPackage(pack.beepReference, pack.beepType, DataManager.getDataManager().get('currentVDMVersion'), pack.beepReference, pack.beepType, vdmStore, {
                                success: function (packModel) {
                                    var text = JSON.stringify(packModel);
                                    zip.file(packModel.get('id') + ".json", text);
                                    packHandleCallback();
                                },
                                error: function (error) {
                                    packHandleCallback();
                                }
                            });
                        } else {
                            setTimeout(getAndWaitRepository, 0);
                        }
                    });
                }
                getAndWaitRepository();
            }, function (err) {
                packages.length = 0;
                zip.generateAsync({ type: "blob" })
                    .then(function (content) {
                        callback(content);
                });
            });
        };
        if(window.loadedPackages[model.id]){
            DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_CodeContainer', loadCodeContainers, null, null, 'vbc:cmof_EObject-name', null, true);
        } else {
            packages = packages.filter(item => item.beepType !== "beeppackage_CodeContainer");
            CodeContainer.fetchPlanCodeContainers(model,function(imgPackages){
                if(imgPackages) {
                    for (var i = 0; i < imgPackages.length; i++) {
                        var imgJson = imgPackages[i];
                        var text = JSON.stringify(imgJson);
                        zip.file(imgJson.id + ".json", text);
                    }
                }
                if(!DataManager.getDataManager().getRDFModel(commonAltId)){
                    DataManager.getDataManager().getModelIdInitializedWSData(commonAltId,function(){
                        DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_BeepPackage', loadCommonPackages, null, null, 'vbc:cmof_EObject-name', null, true);        
                    })
                }else{
                    DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_BeepPackage', loadCommonPackages, null, null, 'vbc:cmof_EObject-name', null, true);
                }
            })
        }
    };

	static createPlanModel(planName,planDes,callback){		 
		var docId = window.planGuidGenerator();
        var Plan = Backbone.Relational.store.getObjectByName('transformation.Plan');
        var plan = new Plan({'name': planName,'description': planDes,'id': docId, 'label': "Base",'planType': "InnovationPlan", 'status': Status.symbols()[0]});

        var role = new Role({'name': 'Plan Owner','roleType': 'Owner', planRoleOwner: plan });

        var user = new User({
            'userId': DataManager.getDataManager().get('gapiId'),
            'name': DataManager.getDataManager().get('gapiDisplayName'),
            'email': DataManager.getDataManager().get('email'),
            'pictureUrl': DataManager.getDataManager().get('gapiImageUrl'),
            'role': role,
            userOwner: plan
        });
        
        var pacRef = new PackageReference({ beepReference: plan, name: plan.get('name'), description: plan.get('description'), version: plan.get('version'), beepType: plan.get('type'), label: 'Base' });

        DataManager.getDataManager().get('currentWorkspace').get('packageReference').add(pacRef);
        callback(plan);
	}
	//
	//return PlanMixin;
}
path.PlanMixin = PlanMixin;
