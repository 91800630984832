import * as _ from 'underscore'
Backbone.sync = (function () {

    var previous = Backbone.sync;

    var sync = function (method, model, options) {
        var store;
        if (model.lawnchair || model.collection) {
            store = model.lawnchair || model.collection.lawnchair;
        } else {
            if (options.lawnchair) {
                store = options.lawnchair;
            } else {
                store = Backbone.defaultLawnchair;	//mmrajende for autofetch	
            }
        }


        var callback = function (record) {

            if (record == null) {
                options.error("Record not found");
                return
            }

            var keyToId = function (obj) {
                obj.id = model.id || obj.key;   // workaround for Lawnchair issue #57
                delete obj.key;
            };

            if (_.isArray(record)) {
                _.each(record, keyToId);
            } else {
                keyToId(record);
            }

            options.success(record);
        };
        function getJSON() {
            var ret = model.toJSON();
            ret.key = model.id;
            delete ret.id;
            return ret;
        }
        function postMessage(repositoryUpdater,obj){
            try{
                repositoryUpdater.postMessage(obj);
            }catch(err){
                try{
                    obj = JSON.parse(JSON.stringify(obj));
                }catch(err){
                    obj = JSON.parse(JSON.stringify(obj));
                }
                repositoryUpdater.postMessage(obj);
            }
            return obj.value;
        }
        function getDocumentFromBackend(id,documentVersion,type,callback){   
            var serverService = Backbone.Relational.store.dataManager.get('vmpServerService');
            var params = {};
            var currentLegalEntity = Backbone.Relational.store.dataManager.get("currentLegalEntity");
			if (currentLegalEntity && currentLegalEntity.entityId) {
				params.currentEntityId = currentLegalEntity.entityId;
			}else{
                if(currentLegalEntity && typeof currentLegalEntity.currentLegalEntity == 'string'){
                    params.currentEntityId = currentLegalEntity.currentLegalEntity;
                }
            }
            let restType = "wsartifact";

            if(type && type == 'transformation_ScenarioExecution'){
                restType = "scenarioexecution";
            }else if (type && type == 'transformation_PlanScenario'){
                restType = "planscenario";
            }else if (type && type == 'transformation_PeriodDataset'){
                restType = "perioddataset";
            }
			if(serverService && params.currentEntityId != undefined){
				// assuming the workspace of user
				serverService.getAllPromise("/vdmbee/workspace/" + Backbone.Relational.store.dataManager.get("currentWorkspaceId") + "/" + restType + "/" + id +"/" + documentVersion + "/?entityId=" + params.currentEntityId, null).then(function (response) {
					if(callback){
                        if(response && response.type && response.type == 'PLAN'){
                            if(!Backbone.Relational.store.dataManager.get("planPackages")){
                                Backbone.Relational.store.dataManager.set("planPackages",{})
                            }
                            var planPackages = Backbone.Relational.store.dataManager.get("planPackages");
                            if(!planPackages[response.content.documentId]){
                                var packages = [];
                                for(var i=0;i<response.planPackage.length;i++){
                                    var planPack = {};
                                    if(response.planPackage[i].content){
                                        planPack.type = response.planPackage[i].content.type;
                                        planPack.documentId = response.planPackage[i].content.documentId;
                                    }
                                    planPack.id = response.planPackage[i].id;
                                    packages.push(planPack);
                                }
                                planPackages[response.content.documentId] = packages;
                            }
                        }
						callback(response);
					}
				}).catch(function (response) {
					if(callback){
						callback(response);
					}
				});	
			} else {
                if(callback){
                    var response = {status:403,message:'Request is Denied'};
                    callback(response);
                }
            }
        }
        function getBackboneType(type) {
            if(type == 'BEEP_PACKAGE') {
                return "beeppackage_BeepPackage";
            }else if(type == 'PLAN') {
                return "transformation_Plan";
            } else if(type == 'VALUE_DELIVERY_MODEL') {
                return "vdml_ValueDeliveryModel";
            } else if(type =='SMM_MODEL') {
                return "smm_SmmModel";
            } else if(type == 'REPORT') {
                return "report_BeepReport";
            } else if(type =='ECO_MAP') {
                return "ecomap_EcoMap";
            } else if(type=='DASHBOARD') {
                return "dashoard_Dashboard";
            } else if(type =='VOCABULARY') {
                return "concept_BeepVocabulary";
            } else if(type == 'BEEP_MODULE') {
                return "beeppackage_BeepModule";
            } else if(type == 'CODE_CONTAINER') {
                return "beeppackage_CodeContainer";
            }
            return null;
        }
        if (Backbone.skipPersistance || !store || !(model instanceof Backbone.RelationalModel)) {	// to skip persistance when unregister is invoked
            var json = getJSON();
            callback(json);
            return;
        }
        var repositoryUpdater = Backbone.Relational.store.dataManager.get('repositorySaver');
        //var jsonStrObj = JSON.parse(JSON.stringify(json));
        switch (method) {
            case "read":
                //repositoryUpdater.postMessage({ action: method, lawnchair: model.lawnchair.projectId, value: json });
                if (options.skipContainedModelSync) {	//mrajende
                    if (model.skipFetch === true) {
                        //callback(json);
                        return model;
                    }
                }
                //model.id ? store.get(model.id, callback) : store.all(callback);
                if(model.id && model.get("type") !== 'com_vbee_filesystem_Workspace' && model.id && model.get("type") !== 'preference_UserPreferences'){
                    getDocumentFromBackend(model.id,model.documentVersion ? model.documentVersion : 0,model.get("type"),function(data){
                        if(Backbone.Relational.store.dataManager.get("artifactsDocuments") && Backbone.Relational.store.dataManager.get("artifactsDocuments")[model.id] && data){ //model.id is documentId so need to reset the artifactId to data.id
                            Backbone.Relational.store.dataManager.get("artifactsDocuments")[model.id].artifactId = data.id; 
                        }
                        callback(data.content);
                    })
                }else{
                    model.id ? store.get(model.id, callback) : store.all(callback);
                }

                break;
            case "create":
                if(model.get("type") !== 'preference_UserPreferences'){
                    var newJson = getJSON();
                    repositoryUpdater.postMessage({ action: method, lawnchair: model.lawnchair.name, value: newJson });
                    
                    callback(newJson);
                }else{
                    resp = store.save(json, callback);
                }
                break;
            case "update":
                if(model.get("type") !== 'preference_UserPreferences'){
                    var updateJson = getJSON();
                    repositoryUpdater.postMessage({ action: method, lawnchair: model.lawnchair.name, value: updateJson });
                    //resp = store.save(json, callback);	
                    callback(updateJson);
                }else{
                    resp = store.save(json, callback);
                }
                break;
            case "delete":
                function doDelete() {
                    if (model.getNestedParent && model.getNestedParent() !== model) {	//TODO no idea why this is called form contained obj	mrajende
                        if (options.success) {
                            options.success();
                        }
                        return;
                    }
                    if (store.exists(model.id, function (exist) {
                        if (exist) {     // exists is false when the model is still not saved and we delete by that time
                            if(model.get("type") !== 'preference_UserPreferences'){
                                repositoryUpdater.postMessage({ action: method, lawnchair: model.lawnchair.name, value: model.id });
                            options.success();
                            }else{
                                store.remove(json.key, options.success);
                            }
                        } else {
                            options.success();
                        }
                    }));
                }
                doDelete();
                break;
        }
        return null;
    };

    //    
    //      Expose the previous Backbone.sync as Backbone.sync.previous in case
    //      the caller wishes to switch provider.
    //     
    sync.previous = previous;

    return sync;

})();
