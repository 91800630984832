import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {ValueElement} from './ValueElement'
import {ValueAddMixin} from './ValueAddMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueElement","appbo/vdml/ValueAddMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,ValueElement,ValueAddMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ValueAdd = ValueElement.extend(utils.customExtends({
		relations:ValueAddMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			ValueElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new ValueAddMixin()
	));
	
	ValueAdd.abstract = false;
	path.ValueAdd = ValueAdd;
	export {ValueAdd};
//});