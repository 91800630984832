import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'

	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties", global.version);
	export class ValuesViewModel {
        constructor(model, options) {
            var self = this;
            this.init(model, options);
        }
		dispose(){
			var self = this;
			$("#xaxis"+self.encodeId).jstree('destroy');
			window.cleanViewModel(self);
		}
		getValueModalFromPreviousPhases(valId,altModel){
			var preAlt = altModel.getPreviousPhasePrimary();
			if(preAlt){
				valId = preAlt.id + window.utils.getSuffix(valId);
				var valueModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				if(!valueModal){
					valueModal = self.getValueModalFromPreviousPhases(valId,preAlt);
				} else {
					return valueModal;
				}
			} else {
				return valueModal;
			}
		}
		
		showAggregationView(view) {
			var self = this;
			var valueId = view.id;
			var id = "aggregation" + window.utils.htmlEscape(valueId);
			var altModel = window.utils.getElementModel(view.altId,['transformation.Alternative']);
			var valueModal = window.utils.getElementModel(valueId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
			if(!valueModal){
				var phaseMasterAlt = altModel.getParent().get('master');
				if(phaseMasterAlt != altModel){
					valueModal = window.utils.getElementModel(phaseMasterAlt.id + window.utils.getSuffix(valueId),['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				}
				if(!valueModal){
					valueModal = self.getValueModalFromPreviousPhases(view.id,altModel);
				}
			}
			if (valueModal) {
				var isTimestamp = false;
				/*if (view.timeStamp != 'undefined') {
					isTimestamp = true;
				}*/
				var mest;
				//valueModal = altModel.getModelLinkedSync(valueModal);
				if (valueModal) {
					var altScenario = altModel.getMainScenario(valueModal.getNestedParent());
					var observedMeasure = altScenario ? altScenario.getObservedMeasureWithMeasurements(valueModal.get('valueMeasurement'), isTimestamp, view.timeStamp) : null;
					mest = observedMeasure ? observedMeasure.get('measurements').at(0) : null;
					var addOptions = { 'currentAlternativeModel': altModel };
					if (!mest) {
						mest = valueModal;
					}
					var ValueElementMixin = Backbone.Relational.store.getObjectByName("vdml.ValueElementMixin");
					window.getAndCreateModalDialog(true, id, ValueElementMixin, mest, "AggregationView", function (changeInValue) {
						if (changeInValue) {
							window.utils.startSpinner('refreshValues', 'Refreshing ...');
							DataManager.getDataManager().invokeValueUpdates(function () {
								self.destroyOfflineTable();
								self.createOfflineTable("offlineTable");
								window.utils.stopSpinner('refreshValues');
							});
						}
					}, addOptions);
				}
			}
		};
			
			buildOrderObject(dt, key, column) {
				var self = this;
				//var rowData = dt.row("#" + key).data();
				var rowData = dt.row("#" + key).data();
				if (typeof rowData === 'undefined') {
					return {};
				} else {
					var object = self.buildOrderObject(dt, rowData['parent'], column);
					var a = object;
					while (typeof a.child !== 'undefined') {
						a = a.child;
					}
					a.child = {};
					a.child.key = rowData['key'];
					a.child.value = rowData[column];
					return object;
				}
			};

			getComponentMeasurement(component, mc, useScenario) {
				var measurement = null;
				var mCharacteristic = component.get(mc);
				var observedMeasure = useScenario.getObservedMeasureWithMeasurements(mCharacteristic, null);
				if (observedMeasure) {
					measurement = observedMeasure.get('measurements').at(0);
				}
				return measurement;
			};

			checkValues(planComponents, valueLevel, dynamicRows, keyIndex, phaseAltName, scenario, altColumnList, valueList) {
				var self = this;
				planComponents.each(function (comp) {
					var valObj;
					var compSuffix = comp.id.substr(comp.id.lastIndexOf('@') + 1);
					for (var z = 0; z < dynamicRows.length; z++) {
						if (dynamicRows[z]["suffix"] == compSuffix) {
							valObj = dynamicRows[z];
						}
						if (valObj != null) {
							break;
						}
					}
					var mstValueUnit = "-";
					var calc = false;
					var smileyValue = "";
					var smileyColor = "";
					var isterative = false;
					var valueMsmt = self.getComponentMeasurement(comp, 'valueMeasurement', scenario);
					if(valueList && valueMsmt){
						var compValues = valueList[valueMsmt.id];
						if(compValues){
							var significantDecimals = 2;//TODO
							mstValueUnit = window.utils.getRoundedValues(compValues[0]['Value'], significantDecimals) + ' ' + compValues[0]['Unit'];
							
						}
					} else {
						var selectedInterval = comp.getSatisfactionInterval(scenario);
						if(selectedInterval){
							smileyValue = selectedInterval.get('smiley') ? selectedInterval.get('smiley') : '';
							smileyColor = selectedInterval.get('smileyColor') ? selectedInterval.get('smileyColor') : '#000000';
							//satisfactionSmiley = comp.fetchSmileySrc(smileyValue);
						}
						if(valueMsmt){
							var observedMeasure = valueMsmt.getParent();
							var measure = observedMeasure.get('measure');
							if (comp.get('aggregatedFrom').length > 0) {
								calc = true;
								if (comp.get('isIterative')) {
									var cyclePath = valueMsmt.getCyclicPath();
									if (cyclePath && cyclePath.length > 0){
										isterative = true;
									}
									
								}
							}
							var unit = measure.get('unit');
							var significantDecimals = unit ? unit.get('significantDecimals') : 2;
							var mstVal = window.utils.getRoundedValues(valueMsmt.get('value'), significantDecimals);
							if(mstVal && mstVal != '-'){
								mstVal = window.utils.thousandSeparator(mstVal);
							}
							mstValueUnit = mstVal + ' ' + unit.get('name');
						} else if(selectedInterval){//Gradeonly
							mstValueUnit = selectedInterval.get('symbol');
						}
						
					}
					if (valObj == null) {
						var isplanValue = false;
						if (DataManager.getDataManager().getRepositoryId(comp.id) == window.plansKey) {
							isplanValue = true;
						}
						valObj = { "DT_RowId": keyIndex, "level": 3, "key": keyIndex, "parent": valueLevel, "name": comp.get('name'), "type": self.titleMap[comp.get('type')], "suffix": compSuffix, "calc": calc,planValue:isplanValue };
						for (var j = 0; j < altColumnList.length; j++) {
							var altName = altColumnList[j];
							if(!valObj[altName]){
								valObj[altName] = "-";
							}
						}
						valObj[phaseAltName] = mstValueUnit;
						//valObj[phaseAltName] = {'value':mstValueUnit,"altId":altId,"customId":comp.id,"whenObserved": null};
						if(smileyValue){
							valObj[phaseAltName + '-satisfactionSmiley'] = smileyValue;
							valObj[phaseAltName + '-smileyColor'] = smileyColor;
						}
						valObj[phaseAltName + '-isIterative'] = isterative;
						dynamicRows.push(valObj);
						keyIndex = keyIndex + 1;
					} else if(phaseAltName){
						if(smileyValue){
							valObj[phaseAltName + '-satisfactionSmiley'] = smileyValue;
							valObj[phaseAltName + '-smileyColor'] = smileyColor;
						}
						valObj[phaseAltName + '-isIterative'] = isterative;
						valObj[phaseAltName] = mstValueUnit;
					}
				});
				return keyIndex;
			};

	createOnlineTable(callback,jsonData) {
        var self = this;
		var valueList = [];
		var templateIdList = [];
		var dataManager = DataManager.getDataManager();
		var plan = dataManager.get('currentPlan');
		for(var i=0;i<plan.get("phase").length;i++){
			var alternatives = plan.get("phase").at(i).get("phaseAlternative");
			for(var j=0;j<alternatives.length;j++){
				var alt = alternatives.at(j);
				templateIdList.push(alt.get('templateId'));
			}
		}
		var currentLegalEntity = dataManager.get("currentLegalEntity");
		var currentEntityId;
		if (currentLegalEntity && currentLegalEntity.entityId) {
			currentEntityId = currentLegalEntity.entityId;
		}
		async.eachSeries(templateIdList,function(temp,templatedHandled){
			var templateUrl = '/vdmbee/snapshot/pythonAll?size=100&page=0&tenantId='+currentEntityId+'&templateId='+temp;
			dataManager.get('vmpServerService').getAllPromise(templateUrl).then(function(data) {
				if(data && data.content[0]){
					var jsonData = data.content[0].fileContent;
					for(var valueData in jsonData){
						if(!valueList[jsonData[valueData].id]){
							valueList[jsonData[valueData].id] = [jsonData[valueData]];
						} else {
							valueList[jsonData[valueData].id].push(jsonData[valueData]);
						}
					}
				}
				templatedHandled();
			});
		},function(err){
			callback(valueList);
		});
		/*var templateUrl = '/vdmbee/snapshot/pythonAll?size=10&page=0&tenantId='+currentEntityId+'&templateId='+templateIdList[0];
		dataManager.get('vmpServerService').getAllPromise(templateUrl).then(function(data) {
			var jsonData = data.content[0].fileContent
			$('#onlineTable').DataTable({
				data: jsonData,
				"columns": [
				  { "data": "id" }, { "data": "ValueName" },{ "data": "ValueType" }, { "data": "Phase>Alternative" }, { "data": "Scenario" },
				  { "data": "Value",render: function ( data, type, row ) {
						return row.Value + ' (' + row.Unit + ')';
					} 
				  }
				]
			});
		});*/
		
    };
	generateMetricsCSV() {
		// currentPlan.scenarioExportDialog();
		DataManager.getDataManager().get('currentPlan').scenarioExportDialog([]);
	};
	
	
	createOfflineTable(tableName,valueList){
		var self = this;
		self.showOnlyValues = false;
		if(self.selectedChartType() == "Show Only Values"){
			self.showOnlyValues = true;
		}
		var dynamicColumns = [{ title: 'Select', targets: 0 },{ title: 'Path', targets: 1 },{ title: 'Key', targets: 2 },
					{ title: 'Parent', targets: 3 },{ title: 'Name', targets: 4 },{ title: 'Type', targets: 5}];
				var columnRendering = [{
					"class": "details-control",
					"orderable": false,
					"data": null,
					"defaultContent": "",
					"width" :100,
					"createdCell": function (td, cellData, rowData, row, col) {
						if (rowData.level > 0) {
							td.className = td.className + ' level-' + rowData.level;
							if (rowData.type == "Value"){
								td.setAttribute("suffix",rowData.suffix);
								td.setAttribute("planValue",rowData.planValue);
							} else if(self.showOnlyValues){
								td.parentElement.style.display = "none";
							}
						} else if(self.showOnlyValues){
							td.parentElement.style.display = "none";
						}
					}
				}, {
					"data": "level",
					"visible": false
				}, {
					"data": "key",
					"visible": false
				}, {
					"data": "parent",
					"visible": false
				}, {
					"data": "name",
					"type": "custom",
					"render": function (data, type, full, meta) {
							var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], 'name').child;
							switch (type) {
								case "display":
									return data;
								case "filter":
									return data;
								case "sort":
									return order;
							}
							return data;
						}
					},
					{
						"data": "type",
                         "visible" : true
					}
				]
				var plan = DataManager.getDataManager().get('currentPlan');
				var phases = plan.get('phase');
				var targetIndex = 6;

				for (var j = 0; j < phases.length; j++) {
					if(_.indexOf(self.xAxisSelected, phases.at(j).id) == -1){
						continue;
					}
					var alternatives = phases.at(j).get('phaseAlternative');
					for (var i = 0; i < alternatives.length; i++) {
						if(_.indexOf(self.xAxisSelected, alternatives.at(i).id) == -1){
							continue;
						}
						var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
						self.altMap[phaseAltName] = alternatives.at(i).id;
						phaseAltName = phaseAltName.replace(/\./g,'\\.');
						dynamicColumns.push({
							title: phases.at(j).get('name') + "/" + alternatives.at(i).get('name'),
							targets: targetIndex
						});
						columnRendering.push({
							"data": phaseAltName,
							"type": "custom",
							"className": "text-right",
							"render": function (data, type, full, meta) {
								var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], phaseAltName).child
								switch (type) {
									case "display":
										return data;
									/*case "filter":
										var val = data != '-'?data.value:data;
										return val;
									case "sort":
										return order;*/
								}
								return data;
							}
						});
						targetIndex++;
					}
				}
				// for dynamic rows
				var dynamicRows = []; 

				function creatDynamicRow(name,id,key,level,parent,phaseAltName,type,altColumnList){
					var valObj;
					var compSuffix = id.substr(id.lastIndexOf('@') + 1);
					for (var k = 0; k < dynamicRows.length; k++) {
						if (dynamicRows[k]["suffix"] == compSuffix) {
							valObj = dynamicRows[k];
						}
						if (valObj != null) {
							break;
						}
					}
					if (valObj == null) {
						valObj = {};
						valObj["DT_RowId"] = key;
						valObj["level"] = level;
						valObj["key"] = key;
						valObj["parent"] = parent;
						valObj["name"] = name;
						valObj["type"] = type;
						valObj["suffix"] = compSuffix;
						if(altColumnList) {
							for (var j = 0; j < altColumnList.length; j++) {
								var altName = altColumnList[j];
								if(!valObj[altName]){
									valObj[altName] = "-";
								}
							}
						}
					}
					if(phaseAltName){
						valObj[phaseAltName] = "-";
					}
					return valObj;
				}
				//---------------- plan section start---------------//
				var altColumnList = [];
				var keyIndex = 1;
				var planObj = creatDynamicRow(plan.get('name'), plan.get('id'), keyIndex, 0, 0, null ,self.titleMap[plan.get("type")]);
				//planObj["type"] = self.titleMap[plan.get("type")]
				dynamicRows.push(planObj);
				keyIndex++;
				var planCriterionSet = plan.getOrCreatePlanCriterionSet();
				var planComponents = planCriterionSet.get('component');
				for (var j = 0; j < phases.length; j++) {
					var alternatives = phases.at(j).get('phaseAlternative');
					for (var i = 0; i < alternatives.length; i++) {
						var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
						altColumnList.push(phaseAltName);
						planObj[phaseAltName] = "-";
						var scenario = alternatives.at(i).getOrCreatePhaseObjectiveSet();
						keyIndex = self.checkValues(planComponents, 1, dynamicRows, keyIndex, phaseAltName, scenario, altColumnList, valueList);
						//keyIndex = self.checkPlanValues(plan, alternatives.at(i), dynamicRows, keyIndex, phaseAltName, valueList);
						//jsonObj[phaseAltName] = "-";
					}
				}
				//---------------- plan section end---------------//
				//---------------- package section start---------------//
				for (var j = 0; j < phases.length; j++) {
					var alternatives = phases.at(j).get('phaseAlternative');
					for (var i = 0; i < alternatives.length; i++) {
						var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
						var phaseDesignPart = alternatives.at(i).get("phaseDesignPart");
						
						for (var k = 0; k < phaseDesignPart.length; k++) {
							var packId = phaseDesignPart.at(k).get("beepReference");
							if (packId.indexOf("Common") == -1 && phaseDesignPart.at(k).get("beepType") === "vdml_ValueDeliveryModel") {
								var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: packId });
								/*var packageLevel = keyIndex;
								var packObj = creatDynamicRow(packageModel.get('name'), packId, keyIndex, 0, 0, phaseAltName, self.titleMap[packageModel.get('type')],altColumnList);
								if(!dynamicRows.includes(packObj)){
									dynamicRows.push(packObj);
									keyIndex++;
								}else {
									packageLevel = packObj.key;
								}*/

								for (var l = 0; l < packageModel.get("businessModel").models.length; l++) {
									var bm = packageModel.get("businessModel").models.at(l);
									var bm1Row = creatDynamicRow(bm.get("name"), bm.get("id"), keyIndex, 1, 0, phaseAltName, self.titleMap[bm.get('type')],altColumnList);
									var bmLevel = keyIndex;
									if(!dynamicRows.includes(bm1Row)){
										dynamicRows.push(bm1Row);
										keyIndex++;
									}else {
										bmLevel = bm1Row.key;
									}
									var scenario =  alternatives.at(i).getMainScenario(bm.getNestedParent());
									keyIndex = fillValueProposition(bm, keyIndex, bmLevel, scenario, phaseAltName, altColumnList, valueList);
									keyIndex = fillActivity(bm, keyIndex, bmLevel, scenario, phaseAltName, altColumnList, valueList)
									keyIndex = fillMyValueProposition(bm, keyIndex, bmLevel, scenario, phaseAltName, altColumnList, valueList)
								}
							}
						}
					}
				}
				function fillValueProposition(bm, keyIndex, bmLevel, scenario, phaseAltName, altColumnList, valueList) {
					for (var m = 0; m < bm.get("bmValueProposition").length; m++) {
						var bmVp = bm.get("bmValueProposition").models.at(m);
						var component = bmVp.get("valueProposition").get("component");
						if(component.length == 0){
							continue;
						}
						var bmChild = creatDynamicRow(bmVp.get("name"), bmVp.get("id"), keyIndex, 2, bmLevel, phaseAltName, self.titleMap[bmVp.get('type')],altColumnList);
						var valueLevel = keyIndex;
						if(!dynamicRows.includes(bmChild)){
							dynamicRows.push(bmChild);
							keyIndex++;
						}else {
							valueLevel = bmChild.key;
						}
						if (scenario) {
							keyIndex = self.checkValues(component, valueLevel, dynamicRows, keyIndex, phaseAltName,scenario, altColumnList, valueList);
						}
					}
					return keyIndex			
				}
				function fillActivity(bm, keyIndex, bmLevel, scenario, phaseAltName, altColumnList, valueList) {
					for (var n = 0; n < bm.get("bmActivity").length; n++) {
						var bmAct = bm.get("bmActivity").models.at(n);
						var component = bmAct.get("activityValue");
						if(component.length == 0){
							continue;
						}
						var bmChild = creatDynamicRow(bmAct.get("name"), bmAct.get("id"), keyIndex, 2, bmLevel, phaseAltName,self.titleMap[bmAct.get('type')],altColumnList);
						var valueLevel = keyIndex;
						if(!dynamicRows.includes(bmChild)){
							dynamicRows.push(bmChild);
							keyIndex++;
						}else {
							valueLevel = bmChild.key;
						}
						/*var valuesPort;
						component.each(function (port) {
							if (port.get('type') === "vdml_OutputPort") {
								valuesPort = port.get("valueAdd");*/
								if (scenario) {
									keyIndex = self.checkValues(component, valueLevel, dynamicRows, keyIndex, phaseAltName, scenario, altColumnList, valueList);
								}
						//	}
						//})
						/*for (var l = 0; l < valuesPort.models.length; l++) {
							var bmChild = creatDynamicRow(valuesPort.models.at(l).get("name"), keyIndex, 3, valueLevel,phaseAltName);
							dynamicRows.push(bmChild);
							keyIndex++;
						}*/
					}
					return keyIndex;
				}
				function fillMyValueProposition(bm, keyIndex, bmLevel, scenario, phaseAltName, altColumnList, valueList) {
					for (var o = 0; o < bm.get("bmValueFormula").length; o++) {
						var bmMyVP = bm.get("bmValueFormula").models.at(o);
						var component = bmMyVP.get("valueFormula").get("component");
						if(component.length == 0){
							continue;
						}
						var bmChild = creatDynamicRow(bmMyVP.get("name"), bmMyVP.get("id"), keyIndex, 2, bmLevel, phaseAltName, self.titleMap[bmMyVP.get('type')],altColumnList);
						var valueLevel = keyIndex;
						if(!dynamicRows.includes(bmChild)){
							dynamicRows.push(bmChild);
							keyIndex++;
						}else {
							valueLevel = bmChild.key;
						}
						/*for (var l = 0; l < component.models.length; l++) {
							var bmChild = creatDynamicRow(component.models.at(l).get("name"), keyIndex, 3, valueLevel,phaseAltName);
//							bmChild[phaseAltName] = component.models.at(l).get("valueMeasurement").get("measurement").models.at(0).get("value");
							dynamicRows.push(bmChild);
							keyIndex++;
						}*/
						if (scenario) {
							keyIndex = self.checkValues(component, valueLevel, dynamicRows, keyIndex, phaseAltName, scenario, altColumnList, valueList);
						}
					}
					return keyIndex;
                }
				//---------------- package section end---------------//

				/*function compareObjectDesc(a, b) {
					if (a.key !== b.key) {
						return ((a.value < b.value) ? 1 : ((a.value > b.value) ? -1 : 0));
					} else if (typeof a.child === 'undefined' && typeof b.child === 'undefined') {
						return ((a.value < b.value) ? 1 : ((a.value > b.value) ? -1 : 0));
					} else if (typeof a.child !== 'undefined' && typeof b.child !== 'undefined') {
						return compareObjectDesc(a.child, b.child);
					} else {
						return typeof a.child !== 'undefined' ? 1 : -1;
					}
				}

				function compareObjectAsc(a, b) {
					if (a.key !== b.key) {
						return ((a.value < b.value) ? -1 : ((a.value > b.value) ? 1 : 0));
					} else if (typeof a.child === 'undefined' && typeof b.child === 'undefined') {
						return ((a.value < b.value) ? -1 : ((a.value > b.value) ? 1 : 0));
					} else if (typeof a.child !== 'undefined' && typeof b.child !== 'undefined') {
						return compareObjectAsc(a.child, b.child);
					} else {
						return typeof a.child !== 'undefined' ? 1 : -1;
					}
				}

				jQuery.fn.dataTableExt.oSort['custom-asc'] = function (a, b) {
					return compareObjectAsc(a, b);
				};

				jQuery.fn.dataTableExt.oSort['custom-desc'] = function (a, b) {
					return compareObjectDesc(a, b);
				};*/
				
				var dt = $('#'+tableName).DataTable({
					"data": dynamicRows,
					"bLengthChange": true,
					"pageLength": 50,
					"ordering": false,
					"pageResize": true,
					"columnDefs": dynamicColumns,
					"columns": columnRendering,
                    "bStateSave": true,
					/*"order": [
						[4, 'asc']
					]*/
					"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
						if(aData["type"] === "Value"){
							if(aData["calc"] == true){
								var imageUrl = '/img/calculator.png';
								$('td:eq(1)', nRow).css('background', 'url(' + imageUrl + ') no-repeat right');
							}							
							var svgSmiliesList = window.utils.getSmiliesList();
							var theadChildrens = $("#offlineTable").children().find('tr').children();
							$('td', nRow).each(function () {
								if (this.className == " text-right") {
									var a = document.createElement('a');
									a.style.color = 'inherit';
									a.style.cursor= 'pointer';
									a.setAttribute('id',aData["suffix"]);
									var val = this.innerText;
									a.appendChild(document.createTextNode(val));
									this.innerText = '';
									this.append(a);
									var colu = theadChildrens[this.cellIndex].innerText;
									if(aData[colu + "-satisfactionSmiley"]){
										var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
										svg.setAttribute("width", 21);
										svg.setAttribute("height", 20);
										svg.setAttribute("style",'vertical-align:bottom;');
										svg.setAttribute("viewBox", "-3 -2 26 26");
										svg.setAttribute("fill", aData[colu + "-smileyColor"]);
										//svg.setAttribute("correctiveActions",found[8]);
										//svg.style.cursor = 'pointer';
										var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
										var smileySrc = '';
										for(var j=0;j<svgSmiliesList.length;j++){
											if(svgSmiliesList[j].value === aData[colu + "-satisfactionSmiley"]) {
												smileySrc = svgSmiliesList[j].src;
												break;
											}
										}
										link.setAttribute("d", smileySrc);
										svg.appendChild(link);
										//$('td:eq(3)', nRow).append(svg);
										this.append(svg);
									}
									if(aData[colu + "-isIterative"] == true){
										var span = document.createElement('span');
										span.style.color = 'orange';
										span.style.cursor= 'pointer';
										span.className = "glyphicon glyphicon-repeat";
										//span.setAttribute('id',aData["suffix"]);
										this.append(span);
									}
									$(a).click(function(event){
										var currentTarget = event.currentTarget;
										var index = currentTarget.parentElement.cellIndex;
										var phaseAltName = $('tr th').eq(index)[0].innerHTML;
										var altId = self.altMap[phaseAltName];
										var suffix = currentTarget.getAttribute('id');
										var valId = altId+suffix;
										var planValue = $(currentTarget.parentElement.parentElement).children()[0].getAttribute('planValue');
										if(planValue == 'true'){
											valId = window.plansKey+suffix;
										}
										var obj1 = {id:valId,altId:altId,timeStamp:null};
										self.showAggregationView(obj1);
									});
								}
							});
						}
					}
				});
				$('#'+tableName).on('init.dt', function () {
					dt.columns([3]).search('^(0)$', true, false).draw();
				});
				var displayed = new Set(_.pluck(dynamicRows, 'key'));
				$('#'+tableName+' tbody').on('click', 'tr td:first-child', function () {
					var tr = $(this).closest('tr');
					var row = dt.row(tr);
					var key = row.data().key;
					if (displayed.has(key)) {
						var childKeyList = [];
						function getChildKeys(childKeyList,key){
							for (var k = 0; k < dynamicRows.length; k++) {
								if (dynamicRows[k]["parent"] == key) {
									var childKey = dynamicRows[k].key;
									childKeyList.push(childKey);
									getChildKeys(childKeyList,childKey);
								}
							}
							return childKeyList
						}
						getChildKeys(childKeyList,key);
						var childList = childKeyList.length;
						while(childList--){
							displayed.delete(childKeyList[childList]);
						}							
						displayed.delete(key);
						tr.removeClass('details');
					} else {
						displayed.add(key);
						tr.addClass('details');
					}
					var regex = "^(0";
					displayed.forEach(function (value) {
						regex = regex + "|" + value;
					});
					regex = regex + ")$";
					dt.columns([3]).search(regex, true, false).draw();
				});		
			}

			destroyOfflineTable(){
				window.utils.setDatatableEmpty('offlineTable');
				$("#offlineTable_wrapper").html('');
				$('<table id="offlineTable" class="display" width="100%" cellspacing="0">    </table>').insertAfter($('#offlineTable_wrapper'));
				$("#offlineTable_wrapper").remove();
			}
		
			getXAxisData() {
				var self = this;
				var xAxisData = [];
				var currentPlan = DataManager.getDataManager().get('currentPlan');
				var phase = currentPlan.get('phase').findWhere({ nextPhase: null });
				while (phase) {
					var phaseData = {};
					xAxisData.unshift(phaseData);
					phaseData.id = phase.get('id');
					phaseData.parent = '#';
					phaseData.text = phase.get('name');
					phaseData.selected = true;
					self.xAxisSelected.push(phaseData.id);
		
					var phasePrimary = phase.get('primary');
					/*if (phasePrimary) {
						self.xAxisSelected.push(phasePrimary.get('id'));
					}*/
					var phaseAlts = phase.get('phaseAlternative');
					phaseAlts.each(function (phaseAlt) {
						var altData = {};
						altData.id = phaseAlt.get('id');
						altData.parent = phase.get('id');
						altData.text = phaseAlt.get('name');
						xAxisData.push(altData);
						if ((phasePrimary === phaseAlt)) {
							altData.state = { opened: true, selected: true };
						}
						self.xAxisSelected.push(phaseAlt.get('id'));
						/*if (preference && (_.indexOf(preference.xAxisSelected, phaseAlt.get('id')) >= 0)) {
							altData.state = { opened: true, selected: true };
							self.xAxisSelected.push(phaseAlt.get('id'));
						}*/
					});
					phase = phase.get('previousPhase');
				}
				return xAxisData;
			};

		afterRenderView(node, view) {
			var self = view;
			var xAxisData = self.getXAxisData();
			self.createOfflineTable("offlineTable");
			var xaxisNode = $("#xaxis" + self.encodeId);
			xaxisNode.jstree({
				'core' : {'data' : xAxisData,'themes':{"icons":false,"dots":false}},
				"checkbox" : {"three_state" : true},		
				"plugins" : [ "wholerow", "checkbox" ]
			}).on("changed.jstree", function (e, data) {
				/*if(reDraw === true){
					self.xAxisSelected = data.selected;
					showValues();
					reDraw = false;				
				}else{
					self.xAxisSelected = data.selected;
				}*/
			}).on("select_node.jstree", function (e, data) {
				var currentNode = data.node.id;
				var parentNode = data.node.parent;
				if(parentNode === '#'){
					var phases = self.model.get('phase');
					phases.each(function(phase){
						if(phase.get('id') == currentNode){
							self.xAxisSelected.push(currentNode);
							var phaseAlts = phase.get('phaseAlternative');
							phaseAlts.each(function(alt){
								self.xAxisSelected.push(alt.id);
							});
						}
					});
				} else {
					var parentPhase = self.model.get('phase').get(parentNode);
					if (parentPhase) {
						var children = data.instance.get_node(parentNode).children;
						_.each(children, function (childNode) {
							if (childNode == currentNode) {
								self.xAxisSelected.push(childNode);
							}
						});
					}
					/*var phases = self.model.get('phase');
					phases.each(function(phase){
						if (phase.get('id') !== parentPhase) {
							var children = data.instance.get_node(phase.get('id')).children;
							_.each(children, function (childNode) {
								if (!data.instance.is_selected(childNode)) {
									self.xAxisSelected.push(childNode);
								}
							});
						}
					});*/
					self.destroyOfflineTable();
					self.createOfflineTable("offlineTable");
				}
			}).on("deselect_node.jstree", function (e, data) {
				var currentNode = data.node.id;
				const index = self.xAxisSelected.indexOf(currentNode);
				if (index > -1) {
					self.xAxisSelected.splice(index, 1);
				}
				self.destroyOfflineTable();
				self.createOfflineTable("offlineTable");
			}).on("open_node.jstree", function (e, data) {
				
			}).on("close_node.jstree", function (e, data) {
				
			});
			var dropdownMenu = $("#dropdownMenu"+self.encodeId);
			$('#dropdown'+self.encodeId).on('click', function(event) {
    			event.stopPropagation();
    			dropdownMenu.toggle();
			});
			$('#ValuesView').on('click', function(event) {
			    if (!dropdownMenu.is(event.target) && dropdownMenu.has(event.target).length === 0) {
			        dropdownMenu.hide();
			    }
			});
		   /*$('#splitPanels').on('click', function (e) {
				$(".dropdown-menu-right").hide();
			});*/
			window.utils.stopSpinner('valueView');
			//$('#onlineTable').hide();
			if(DataManager.getDataManager().get('debugMode')){
				self.createOnlineTable(function(valueList){
					self.createOfflineTable("onlineTable",valueList);
					window.utils.stopSpinner('valueView');
				});
			}
		}
		init(model,options){
			var self = this;
			this.ValuesViewModel = this;
			self.model = model;
			this.alternativeModel = options.addOptions;
			this.selectedChartType = ko.observable();
			this.chartTypes = ko.observableArray(['Show All','Show Only Values']);
			this.encodeId = this.model ? window.utils.htmlEscape(this.model.id) : null;
			self.xAxisSelected = [];
			this.titleMap = {};
			this.altMap = {};
			this.titleMap['vdml_Community'] = "Market Segment";
			this.titleMap['vdml_OrgUnit'] = "Enterprise";
			this.titleMap['vdml_Actor'] = "Individual";
			this.titleMap['transformation_Plan'] = "Plan";
			this.titleMap['vdml_Party'] = "Role";
			this.titleMap['vdml_BusinessModel'] = "Business Model";
			this.titleMap['vdml_BusinessModelActivity'] = "Activity";
			this.titleMap['vdml_BusinessNetwork'] = "Participant Network";
			this.titleMap['vdml_BusinessModelValueProposition'] = "Value Proposition";
			this.titleMap['vdml_BusinessModelValueFormula'] = "My Proposition";
			this.titleMap['vdml_Activity'] = "Activity";
			this.titleMap['vdml_ValuePropositionComponent'] = "Value";
			this.titleMap['vdml_ValueAdd'] = "Value";
			self.packages = []			
			var setPackage = function (package1) {	
		    	var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: package1.id });
				self.packages.push(packageModel);	
			}
			function loadDocuments(results) {
				for (var i = 0; i < results.length; i++) {
					var type = results.at(i).get('type');
					if (type === "vdml_ValueDeliveryModel") {
						setPackage(results.at(i));
					}
				}
			}
			var searchTypes = 'vdml_ValueDeliveryModel';
			var nameNS = 'vbc:cmof_EObject-name';
			var alt = self.model.get("phase").at(0).get("phaseAlternative").at(0)
			DataManager.getDataManager().getAllDocumentsCollectionOfType(alt, searchTypes, loadDocuments, true, null, null, nameNS);
			this.selectedChartType.subscribe(function (selectedType) {
				if(selectedType == "Show Only Values" && self.xAxisSelected.length > 0){
					self.destroyOfflineTable();
					self.createOfflineTable("offlineTable");
				} else if(self.xAxisSelected.length > 0){
					self.destroyOfflineTable();
					self.createOfflineTable("offlineTable");
				}
				/*if($("#placeholder" + self.encodeId).length > 0) {
				var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
				self.maxUnitsPermitted = selectedType === "Stacked bars" || selectedType === "Stacked columns"? 1: 2;
				if (selectedType === 'Radar' && selectedOptions.length < 3) {
					bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
					self.selectedChartType('Curves');
				} else {
					self.getValueMeasurements(self.alts, function(){
						self.plotValues();
						self.plotAccordingToChoices();
					});
				}
				}*/
			});
			this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['ExportCSV', 'PresentationType']);
		}

		static getInstance(model,options){
			var view = new ValuesViewModel(model,options);
			return view;
		};
	}
	path.ValuesViewModel = ValuesViewModel;
