import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {PortContainer} from './PortContainer'
import {ActivityMixin} from './ActivityMixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {Capability} from './Capability'
import {Role} from './Role'
import {ResourceUse} from './ResourceUse'
import {PracticeDefinition} from './PracticeDefinition'
import {DelegationContext} from './DelegationContext'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {CapabilityOffer} from './CapabilityOffer'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortContainer","appbo/vdml/ActivityMixin","appbo/vdml/VDMLCanvasElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortContainer,ActivityMixin,VDMLCanvasElementMixin
, Capability
, Role
, ResourceUse
, MeasuredCharacteristic
, PracticeDefinition
, DelegationContext

, CapabilityOffer){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			Capability = importPath.Capability;
		}
        else {
            import('./Capability').then(loadedModule => {
      //          Capability = loadedModule;
            });
			//require(["appbo/vdml/Capability"],function(loadedModule){
			//	Capability = loadedModule;
			//});
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
        else {
            import('./Role').then(loadedModule => {
      //          Role = loadedModule;
            });
			//require(["appbo/vdml/Role"],function(loadedModule){
			//	Role = loadedModule;
			//});
		}
	}
	if(!ResourceUse){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ResourceUse){
			ResourceUse = importPath.ResourceUse;
		}
		else{
			import('./ResourceUse').then(loadedModule => {
                ResourceUse = loadedModule;
            });
			/*require(["appbo/vdml/ResourceUse"],function(loadedModule){
				ResourceUse = loadedModule;
			});*/
		}
	}
	
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
		else{
			import('./PracticeDefinition').then(loadedModule => {
      //          PracticeDefinition = loadedModule;
            });
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	if(!DelegationContext){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DelegationContext){
			DelegationContext = importPath.DelegationContext;
		}
		else{
			import('./DelegationContext').then(loadedModule => {
          //      DelegationContext = loadedModule;
            });
			/*require(["appbo/vdml/DelegationContext"],function(loadedModule){
				DelegationContext = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
                MeasuredCharacteristic = loadedModule;
            });
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!CapabilityOffer){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityOffer){
			CapabilityOffer = importPath.CapabilityOffer;
		}
		else{
			import('./CapabilityOffer').then(loadedModule => {
                CapabilityOffer = loadedModule;
            });
			/*require(["appbo/vdml/CapabilityOffer"],function(loadedModule){
				CapabilityOffer = loadedModule;
			});*/
		}
	}
	/*function inheritSuperModelRelations(type, superType){
		if (superType.prototype.relations) {
			// Find relations that exist on the '_superModel', but not yet on this model.
			var inheritedRelations = _.filter(superType.prototype.relations || [], function (superRel) {
				return !_.any(type.relations || [], function (rel) {
					return superRel.relatedModel === rel.relatedModel && superRel.key === rel.key;
				}, type);
			}, type);

			type.relations = inheritedRelations.concat(type.relations);
		}
	}*/
	//TODO: preinitialize not in current version of backbone so initializing parent relation in initialize method
	var Activity = PortContainer.extend(utils.customExtends({
		relations:ActivityMixin.getMixinRelations(),
		preinitialize: function(){
			PortContainer.prototype.preinitialize.apply(this, arguments );
			var portContainer = Backbone.Relational.store.getObjectByName("vdml.PortContainer");
			utils.inheritSuperModelRelations(Object.getPrototypeOf(this),portContainer);
		},
		initialize: function(attributes, options) {
			var portContainer = Backbone.Relational.store.getObjectByName("vdml.PortContainer");
			utils.inheritSuperModelRelations(this,Object.getPrototypeOf(this),portContainer);
			PortContainer.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handleActivityNameChange);
		}
		}
		, new VDMLCanvasElementMixin()
		, new ActivityMixin()
	));
    Activity["change:name"] = function (model,newName) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			var previousName = model._previousAttributes ? model._previousAttributes.name:"";
			if(previousName != newName) {
				this.handleActivityNameChange(model, newName);
			}
		}
    }
	Activity['destroy'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updateUIActivity(model,true);
		}
    }
    Activity['change:capabilityRequirement'] = function (model, coll, options) {
        if (Backbone.skipPersistance || options.creatingRevision || options.add) {
            return;
        }
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			if (this.get('delegationContext') && this.get('delegationContext').length > 0) {
				_.each(this.get('delegationContext').models, function(delContext) {
					var capMethod = delContext.get('contextCollaboration');
					if (capMethod){
						capMethod.set('capability', coll)
					}
				});
			}
		}
    };
	Activity.abstract = false;
	path.Activity = Activity;
	export {Activity};
//});