
import { Plan2ViewModel } from './version1/views/transformation/views/properties/Plan2ViewModel';
import { ValueDetails2ViewModel } from './version1/views/vdml/views/properties/ValueDetails2ViewModel';
import { BusinessModel2ViewModel } from './version1/views/vdml/views/properties/BusinessModel2ViewModel';
import { ValueLibrary2ViewModel } from './version1/views/valueLibrary/properties/ValueLibrary2ViewModel';
import { PlanDetails2ViewModel } from './version1/views/transformation/views/properties/PlanDetails2ViewModel';
import { AlternativeInputValues2ViewModel } from './version1/views/transformation/views/properties/AlternativeInputValues2ViewModel.js';
import { Values2ViewModel } from './version1/views/transformation/views/properties/Values2ViewModel.js';
import { BMCube2ViewModel } from './version1/views/vdml/views/bmcube/BMCube2ViewModel';
import AlternativeInputValues2Template from './version1/views/transformation/views/properties/AlternativeInputValues2Template.html'
import ValueLibrary2Template from './version1/views/valueLibrary/properties/ValueLibrary2Template.html';
import PlanDetails2Template from './version1/views/transformation/views/properties/PlanDetails2Template.html';
import BMDetails2Template from './version1/views/vdml/views/properties/BMDetails2Template.html';
import ValueDetailsProperties2Template from './version1/views/vdml/views/properties/ValueDetailsProperties2Template.html';
import PlanValueDetails2Template from './version1/views/transformation/views/properties/PlanValueDetails2Template.html';
import AdvancedPlanDetails2Template from './version1/views/transformation/views/properties/AdvancedPlanDetails2Template.html';
import { AdvancedPlanDetails2ViewModel } from './version1/views/transformation/views/properties/AdvancedPlanDetails2ViewModel.js';
import Values2Template from './version1/views/transformation/views/properties/Values2Template.html';
import { PlanValues2Chart } from './version1/views/transformation/views/chart/PlanValues2Chart.js';
import PlanProperties2Template from './version1/views/transformation/views/properties/PlanProperties2Template.html';
import DynamicClass from './DynamicClass.js';



const htmlList = {
    ValueLibrary2Template,
    AlternativeInputValues2Template,
    AdvancedPlanDetails2Template,
    PlanDetails2Template,
    Values2Template,
    BMDetails2Template,
    PlanProperties2Template,
    ValueDetailsProperties2Template,
    PlanValueDetails2Template
}

// Use ES6 Object Literal Property Value Shorthand to maintain a map
// where the keys share the same names as the classes themselves
const classes = {
    
    Plan2ViewModel,
    PlanValues2Chart,
   
    ValueDetails2ViewModel,
   
    BusinessModel2ViewModel,
   
    ValueLibrary2ViewModel,
    
    PlanDetails2ViewModel,
    
    BMCube2ViewModel,

    AlternativeInputValues2ViewModel,

    AdvancedPlanDetails2ViewModel,

    Values2ViewModel
};

class DynamicClass2  extends DynamicClass{
    constructor (className, opts) {
        return new classes[className](opts);
    }
    static getView(className){
        var view = super.getView(className)
        if(view){
            return view
        }
        return classes[className];
    }
	static getHtml(fileName){
        var htmlPage = super.getHtml(fileName);
        if(htmlPage) {
            return htmlPage;
        }
        return htmlList[fileName];
    }
}

export default DynamicClass2;