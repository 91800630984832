import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {PortContainer} from './PortContainer'
import {PortContainerMixin} from './PortContainerMixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {Capability} from './Capability'
import {Role} from './Role'
import {ResourceUse} from './ResourceUse'
import {PracticeDefinition} from './PracticeDefinition'
import {DelegationContext} from './DelegationContext'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {CapabilityOffer} from './CapabilityOffer'
import {OutputDelegation} from './OutputDelegation'
import {InputDelegation} from './InputDelegation'
import {InputPort} from './InputPort'
import {OutputPort} from './OutputPort'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortContainer","appbo/vdml/PortContainerMixin","appbo/vdml/VDMLCanvasElementMixin"],
function(require,jQuery,_, async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortContainer,PortContainerMixin,VDMLCanvasElementMixin
, Capability
, Role
, ResourceUse
, MeasuredCharacteristic
, PracticeDefinition
, DelegationContext
, InputDelegation
, OutputDelegation
, CapabilityOffer
,InputPort
,OutputPort){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			Capability = importPath.Capability;
		}
		else{
			import('./Capability').then(loadedModule => {
      //          Capability = loadedModule;
            });
			//require(["appbo/vdml/Capability"],function(loadedModule){
			//	Capability = loadedModule;
			//});
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
		else{
			import('./Role').then(loadedModule => {
       //         Role = loadedModule;
            });
			//require(["appbo/vdml/Role"],function(loadedModule){
			//	Role = loadedModule;
			//});
		}
	}
	if(!ResourceUse){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ResourceUse){
			ResourceUse = importPath.ResourceUse;
		}
		else{
			import('./ResourceUse').then(loadedModule => {
                ResourceUse = loadedModule;
            });
			/*require(["appbo/vdml/ResourceUse"],function(loadedModule){
				ResourceUse = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
                MeasuredCharacteristic = loadedModule;
            });
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
		else{
			import('./PracticeDefinition').then(loadedModule => {
       //         PracticeDefinition = loadedModule;
            });
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	if(!DelegationContext){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DelegationContext){
			DelegationContext = importPath.DelegationContext;
		}
		else{
			import('./DelegationContext').then(loadedModule => {
             //   DelegationContext = loadedModule;
            });
			/*require(["appbo/vdml/DelegationContext"],function(loadedModule){
				DelegationContext = loadedModule;
			});*/
		}
	}
	if(!InputDelegation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputDelegation){
			InputDelegation = importPath.InputDelegation;
		}
		else{
			import('./InputDelegation').then(loadedModule => {
                InputDelegation = loadedModule;
            });
			/*require(["appbo/vdml/InputDelegation"],function(loadedModule){
				InputDelegation = loadedModule;
			});*/
		}
	}
	if(!OutputDelegation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputDelegation){
			OutputDelegation = importPath.OutputDelegation;
		}
		else{
			import('./OutputDelegation').then(loadedModule => {
                OutputDelegation = loadedModule;
            });
			/*require(["appbo/vdml/OutputDelegation"],function(loadedModule){
				OutputDelegation = loadedModule;
			});*/
		}
	}	
	if(!CapabilityOffer){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityOffer){
			CapabilityOffer = importPath.CapabilityOffer;
		}
		else{
			import('./CapabilityOffer').then(loadedModule => {
                CapabilityOffer = loadedModule;
            });
			/*require(["appbo/vdml/CapabilityOffer"],function(loadedModule){
				CapabilityOffer = loadedModule;
			});*/
		}
	}
	if(!OutputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputPort){
			OutputPort = importPath.OutputPort;
		}
		else{
			import('./OutputPort').then(loadedModule => {
                OutputPort = loadedModule;
            });
			/*require(["appbo/vdml/OutputPort"],function(loadedModule){
				OutputPort = loadedModule;
			});*/
		}
	}
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
		else{
			import('./InputPort').then(loadedModule => {
        //        InputPort = loadedModule;
            });
			/*require(["appbo/vdml/InputPort"],function(loadedModule){
				InputPort = loadedModule;
			});*/
		}
	}
	export class ActivityMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ActivityMixin = ActivityMixin;
        }*/
	defaults() {
		var ret = {
			type: "vdml_Activity"
		}
		return jQuery.extend(PortContainer.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Activity",
			key:"capabilityRequirement",
			relatedModel:"vdml.Capability",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Activity",
			key:"resourceUse",
			relatedModel:"vdml.ResourceUse",
			reverseRelation: {
				key:"resourceUseOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		/*{
			type :Backbone.HasOne,
			containingClass:"vdml_Activity",
			key:"duration",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"durationOwnerActivity",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},*/
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Activity",
			key:"implementedPractice",
			relatedModel:"vdml.PracticeDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Activity",
			key:"delegationContext",
			relatedModel:"vdml.DelegationContext",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"delegatedActivity",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}/*,
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Activity",
			key:"recurrenceInterval",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"recurrenceIntervalOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!ActivityMixin.cummulativeRelations) {
            ActivityMixin.cummulativeRelations = _.union(ActivityMixin.getMixinRelations()
                ,PortContainerMixin.getCumulativeMixinRelations()
		        ,VDMLCanvasElementMixin.getCumulativeMixinRelations()
            );
        }
		return ActivityMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_PortContainer","vdml_VDMLCanvasElement"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "cmof_EObject" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
    }
    calculateProgress() {

    }
	getParent(){
		var container;
		if(!container){
			container = this.get("activityOwner") ? this.get("activityOwner") : this.previousAttributes().activityOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("activityOwner") ? this.get("activityOwner") : this.previousAttributes().activityOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ActivityPropertiesTemplate.html",
			templateName : "ActivityPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ActivityViewModel",
			tabId : "ActivityView",
			tabName: "Activity"
		}
	}
//#startCustomMethods

	deleteCapabilityUse(currentBM,capabilityOffer,context,callback){
		var self = this;		
		if(!context){
			DataManager.getDataManager().getAlternative(DataManager.getDataManager().getRepositoryId(currentBM.get('id')),function(alternative){
				if(!alternative){
					callback();
					return;
				}
				var alts = alternative.getAlternativesWhereICanBeReffered();
				async.each(alts,function(alt,handleAlt){
					alt.getMainScenarioOfBeepPackageWithId(currentBM.getNestedParent().get('id'),currentBM.getNestedParent().get('version'),function(mainScenario){
						handleScenario(mainScenario,handleAlt);
					});					
				},function(){
					alts.length = 0;
					callback();
				});
			});		
		}else{
			handleScenario(context,callback);
		}

		function handleScenario(context,callCallback){
			if(!context){
				callCallback();
				return;
			}				
			var deleContexts = self.get('delegationContext').models.concat();
			var foundContext = false;
			async.eachSeries(deleContexts,function(delContext,contextHandled){
				if(foundContext){
					contextHandled();
					return;
				}
				var parentScenario = delContext;
				while(parentScenario.get('parentContext')){
					parentScenario = parentScenario.get('parentContext');
				}
				if(parentScenario === context){
					foundContext = true;
					checkActivityReuse(delContext, function(){
						removeOfferUse(delContext,contextHandled);
					});
				}else{
					contextHandled();
				}
			},function(){
				callCallback();
			});
			function removeOfferUse(delContext,offerUseCallback){
				var delContextsToBeDeleted = [];
				var contextColl = delContext.get('contextCollaboration');
				var activities = contextColl.get('activity');
				var delActivities = [];
				async.eachSeries(activities.models,function(activity,activityChecked){
					if(activity.get('appliedCapabilityOffer') === capabilityOffer){
						if(activity.get('appliedCapabilityOffer') === capabilityOffer){
								var activityDelegatedContext ;
								var activityDCs = activity.get('delegationContext');
								activityDCs.each(function(activityDC){
									var actParentContext = activityDC;
									while(actParentContext.get('parentContext')){
										actParentContext = actParentContext.get('parentContext');
										if(actParentContext === delContext){
											activityDelegatedContext = activityDC;
											break;
										}
									}														
								});
								if(activityDelegatedContext){
									var supportsOffer = false;
									var activityDelColl = 	activityDelegatedContext.get('contextCollaboration');
									if(activityDelColl.get('type') === 'vdml_CapabilityMethod'){
										var actCollSuportedOffers = activityDelColl.get('supportedCapability');
										actCollSuportedOffers.each(function(actCollSupportedOffer){
											if(actCollSupportedOffer === capabilityOffer){
												supportsOffer = true;
											}
										});
										if(supportsOffer){
											activityDelegatedContext.destroy();
										}
										//TODO can activityDelColl be removed
										DataManager.getDataManager().getReferencesToModel2(activityDelColl,function(refs){
											var refCount = refs.length;
											_.each(refs,function(ref){
												if(ref.predicate === "vdml_OrgUnit-ownedMethod"){
													refCount--;
												}else if(ref.predicate === "vdml_CapabiltyOffer-method"){	//check this
													capabilityOffer.get('method').each(function(method){
														if(method.get('id') === ref.id){
															refCount--;
														}
													});
												}
											});																
											if(refCount === 0 ){
												activityDelColl.destroy();
												delContextsToBeDeleted.push(activityDelegatedContext);
											}
											activityChecked();
										});
										delActivities.push(activity); 
									}else{
										activityChecked();	
									}
								}else{
									delActivities.push(activity); 
									activityChecked();
								}
						}else{
							activity.deleteCapabilityUse(currentBM,capabilityOffer,context,activityChecked);
						}	
					}else{
						activityChecked();
					}					
				},function(){
					_.each(delActivities,function(act){
						act.destroy();
					});
					if(contextColl.get('activity').length === 0){
						contextColl.destroy();
						delContext.destroy();
					}
					_.each(delContextsToBeDeleted,function(delContext){
						delContext.destroy();
					});
					DataManager.getDataManager().getReferencesToModel2(capabilityOffer,function(refs){
						var refCount = refs.length;
                        _.each(refs, function (ref) {
                            if (ref.predicate === "vdml_OrgUnit-capabilityOffer" || ref.predicate === "vdml_CapabilityOffer-capability" || ref.predicate === "vdml_StrategyMapDiagram-competence" || ref.predicate === "vdml_ValueStreamMapDiagram-competence"){
								refCount--;
							}else if(ref.predicate === "vdml_BusinessModelCompetence-competence"){
								currentBM.get('bmCompetence').each(function(bmComp){
									if(bmComp.get('id') === ref.id){
										refCount--;
									}
								});
							}
						});
						if(refCount === 0 ){
							capabilityOffer.destroy();
						}
						offerUseCallback();
					});						
				});				
			} 			
			function checkActivityReuse(delContext,activityReuseCallback){
				var contextColl = delContext.get('contextCollaboration');
				var activities = contextColl.get('activity');
				var isCollaborationCopied = false; 
				async.eachSeries(activities.models,function(activity,activityChecked){
					if(isCollaborationCopied){
						activityChecked();
						return;
					}
					if(activity.get('appliedCapabilityOffer') === capabilityOffer){
						currentBM.activityReUsed(activity,function(reUsed){
							if(!reUsed){
								if(contextColl.get('delegationContext').length > 1){
									reUsed = true;
								}
							}
							if(reUsed){
								isCollaborationCopied = true;
								var measurementsCopied = [];
								var valuesCopied = [];
								contextColl.copy(contextColl.getNestedParent(),currentBM.getNestedParent(),contextColl.get('methodOwner'),measurementsCopied,valuesCopied,function(collCopy){
									async.each(measurementsCopied,function(copyObj,copiedBaseRelationship){
										copyObj.measurement.copyBaseMeasurementRelationships(copyObj.measurementCopy,measurementsCopied,copiedBaseRelationship);
									},function(err){
										_.each(valuesCopied,function(copyObj){
											copyObj.value.createValueAggregations(copyObj.valueCopy,valuesCopied);
										});
										delContext.disconnectCollaboration(contextColl);	
										self.delegateToCollaboration(collCopy,delContext.get('parentContext'),delContext);	
										delContext.get('contextBasedPortDelegation').each(function(portDel){
											var target = portDel.get('target');
											var newTargetPort = collCopy.get('containedPort').findWhere({name:target.get('name')});
											portDel.set('target',newTargetPort);
										});
										activityChecked();
									});
								});
							}else{
								activityChecked();
							}
						});
					}else{
						activityChecked();
					}					
				},function(){
					activityReuseCallback();
				});
			}
			
		}
	};

	deleteResourceInput(currentBM,resource,context,callback){
		var self = this;		
		var deleContexts = self.get('delegationContext');
		var delModels = [];
		deleContexts.each(function(delContext){
			var portDels = delContext.get('contextBasedPortDelegation');
			portDels.each(function(portDel){
				var collPort = portDel.get('target');
				if((portDel instanceof InputDelegation) && (portDel.get('source').get('containedPortOwner') === self) && (portDel.get('source').get('input') && portDel.get('source').get('input').get('deliverable') === resource) &&
					(collPort.get('containedPortOwner') === delContext.get('contextCollaboration')) ){
					var used = false;
					var collPortDels = collPort.get('delegatedInput');
					collPortDels.each(function(otherDel){
						if(otherDel === portDel){
							return;
						}
						if(otherDel.get('source') === collPort || otherDel.get('target') === collPort){
							used = true;
						}
					});
					delModels.push(portDel);
					if(!used){
						delModels.push(collPort);
					}
				} 
			});
		});
		_.each(delModels,function(delModel){
			delModel.destroy();
		});
		var biStores = 	resource.get('store');
		var countBiStores = biStores.length;
		handleBiStore();
		function handleBiStore(){
			countBiStores--;
			if(countBiStores >=0){
				var biStore = biStores.at(countBiStores);
				var deletedObjIds = [];
				var storePorts = biStore.get('containedPort');
				var delStorePorts = [];
				var delResUse = [];
				storePorts.each(function(port){
					if(port instanceof OutputPort){
						var flow = port.get('output');
						var flowRecipientPort = flow.get('recipient');
						var flowTarget = flowRecipientPort?flowRecipientPort.get('containedPortOwner'):null;
						if(flowTarget === self){
							deletedObjIds.push(flow.get('id'));
							var resourcesUsesOfPort = flowRecipientPort.get('resourceUse');
							flow.destroy();
							flowRecipientPort.destroy();
							resourcesUsesOfPort.each(function(resUse){
								if(resUse.get('resource').length === 0){
									delResUse.push(resUse);
								}
							});
							delStorePorts.push(port);
						}						
					}
				});
				_.each(delResUse,function(delRes){
					delRes.destroy();
				});
				_.each(delStorePorts,function(delPort){
					delPort.destroy();
				})
				//deletedObjIds = deletedObjIds.concat(biStore.get('id'));
				//DataManager.getDataManager().getReferencesToModels(alternative.get('id'),deletedObjIds,function(refs){
				DataManager.getDataManager().getReferencesToModel2(biStore,function(refs){
					var hasOtherReferences = false;
					_.each(refs,function(ref){
						var skipRef = false;
						_.each(deletedObjIds,function(flow){
							if(ref.id === flow){
								skipRef = true;
							}
						});											
						if(!skipRef 
							&& ref.predicate !== 'vdml_OrgUnit-ownedStore' 
							&& ref.predicate !== 'vdml_AnalysisContext-contextStore'
							&& ref.predicate !== 'vdml_BusinessModel-bmResourceStore'
							&& ref.predicate !== 'vdml_BusinessItem-store'
							&& ref.predicate !== 'vdml_Pool-resource'){
							hasOtherReferences = true;
						}
					});
					if(!hasOtherReferences){
						biStore.destroy();
					}
					handleBiStore();
				});									
			}else{
				var delContexts = self.get('delegationContext');
				if(delContexts.length > 0){
					handleActivityDelegates();
				}else{
					callback();
				}
			}
		}
		function handleActivityDelegates(){
			if(!context){
				DataManager.getDataManager().getAlternative(DataManager.getDataManager().getRepositoryId(currentBM.get('id')),function(alternative){
					if(!alternative){
						callback();
						return;
					}
					var alts = alternative.getAlternativesWhereICanBeReffered();
					async.each(alts,function(alt,handleAlt){
						alt.getMainScenarioOfBeepPackageWithId(currentBM.getNestedParent().get('id'),currentBM.getNestedParent().get('version'),function(mainScenario){
							handleScenario(mainScenario,handleAlt);
						});					
					},function(){
						alts.length = 0;
						callback();
					});
				});		
			}else{
				handleScenario(context,callback);
			}	
		}
		
		function handleScenario(context,callCallback){
			if(!context){
				callCallback();
				return;
			}				
			var foundContext = false;
			async.eachSeries(deleContexts.models,function(delContext,contextHandled){
				if(foundContext){
					contextHandled();
					return;
				}
				var parentScenario = delContext;
				while(parentScenario.get('parentContext')){
					parentScenario = parentScenario.get('parentContext');
				}
				if(parentScenario === context){
					foundContext = true;
					checkActivityReuse(delContext, function(){
						removeResourceInput(delContext,contextHandled);
					});
				}else{
					contextHandled();
				}
			},function(){
				callCallback();
			});		
		}

		function removeResourceInput(delContext,resourceInputCallback){
			var contextColl = delContext.get('contextCollaboration');
			var activities = contextColl.get('activity');
			async.eachSeries(activities.models,function(activity,activityChecked){
				var activityPorts = activity.get('containedPort');
				async.each(activityPorts.models,function(port,portHandled){
					if(port instanceof InputPort){
						var inputFlow = port.get('input');
						if(inputFlow.get('deliverable') === resource){
							activity.deleteResourceInput(currentBM,resource,context,portHandled);
						}
					}else{
						portHandled();
					}						
				},function(){
					activityChecked();
				});
			},function(){
				resourceInputCallback();
			});				
		}
		
		function checkActivityReuse(delContext,activityReuseCallback){
			var contextColl = delContext.get('contextCollaboration');
			var activities = contextColl.get('activity');
			var isCollaborationCopied = false; 
			async.eachSeries(activities.models,function(activity,activityChecked){
				if(isCollaborationCopied){
					activityChecked();
					return;
				}
				// check if resource is input to activity
				var activityPorts = activity.get('containedPort');
				var checkActivityReuse = false;
				for(var i=0;i<activityPorts.models.length;i++){
					var activityPort = activityPorts.at(i);
					if(activityPort.get('type') === 'vdml_Inputport' ){
						var flow = activityPort.input();
						if(flow && flow.get('deliverable') === resource){
							checkActivityReuse = true;
							break;
						}
					}
				}
				if(!checkActivityReuse){
					activityChecked();
					return;
				}
				currentBM.activityReUsed(activity,function(reUsed){
					if(reUsed){
						isCollaborationCopied = true;
						var measurementsCopied = [];
						var valuesCopied = [];
						contextColl.copy(contextColl.getNestedParent(),currentBM.getNestedParent(),contextColl.get('methodOwner'),measurementsCopied,valuesCopied,function(collCopy){
							async.each(measurementsCopied,function(copyObj,copiedBaseMeasurementRel){
								copyObj.measurement.copyBaseMeasurementRelationships(copyObj.measurementCopy,measurementsCopied,copiedBaseMeasurementRel);
							},function(err){
								_.each(valuesCopied,function(copyObj){
									copyObj.value.createValueAggregations(copyObj.valueCopy,valuesCopied);
								});
								delContext.disconnectCollaboration(contextColl);
								self.delegateToCollaboration(collCopy,delContext.get('parentContext'),delContext);	
								delContext.get('contextBasedPortDelegation').each(function(portDel){
									var target = portDel.get('target');
									var newTargetPort = collCopy.get('containedPort').findWhere({name:target.get('name')});
									portDel.set('target',newTargetPort);
								});
								activityChecked();
							});
						});								
					}else{
						activityChecked();
					}
				});
			},function(){
				activityReuseCallback();
			});
		}		
	};
	
	createCapabilityUse(bm,newCapabilityOffersArray,orgUnit,context,callback){
		var self = this;
		var activityDelegationContext = context.get('delegationContext').findWhere({delegatedActivity:self});
		if(activityDelegationContext){
			async.each(newCapabilityOffersArray,function(newCapabilityOffer,handledOffer){
				var capabilityMethod = activityDelegationContext.get('contextCollaboration');
				var cmUsedContexts = capabilityMethod.get('delegationContext');
				if(cmUsedContexts.length === 1){
					capabilityMethod.createCapabilityUseAtDelegationLevel(newCapabilityOffer);
					handledOffer();
				}else{
					var measurementsCopied = [];
					var valuesCopied = [];
					capabilityMethod.copy(capabilityMethod.getNestedParent(),bm.getNestedParent(),capabilityMethod.get('methodOwner'),measurementsCopied,valuesCopied,function(collCopy){
						async.each(measurementsCopied,function(copyObj,copiedBaseMestRel){
							copyObj.measurement.copyBaseMeasurementRelationships(copyObj.measurementCopy,measurementsCopied,copiedBaseMestRel);
						},function(err){
							_.each(valuesCopied,function(copyObj){
								copyObj.value.createValueAggregations(copyObj.valueCopy,valuesCopied);
							});
							activityDelegationContext.disconnectCollaboration(capabilityMethod);	
							self.delegateToCollaboration(collCopy,context,activityDelegationContext);	
							collCopy.createCapabilityUseAtDelegationLevel(newCapabilityOffer);
							activityDelegationContext.get('contextBasedPortDelegation').each(function(portDel){
								var target = portDel.get('target');
								var newTargetPort = collCopy.get('containedPort').findWhere({name:target.get('name')});
								portDel.set('target',newTargetPort);
							});
							handledOffer();
						});
					});
				}				
			},function(err){
				if(callback){
					callback();
				}				
			});
		}else{
			var capabilitiesRequired = [];
			capabilitiesRequired = capabilitiesRequired.concat(newCapabilityOffersArray);
			var newCM = this.findExactlyMatchingExistingCapabilityMethod(capabilitiesRequired, bm);

			if(!newCM){
				newCM = bm.createCapabilityMethodForActivityToDelegate(this);
				_.each(capabilitiesRequired,function(capRequired){
					newCM.createCapabilityUseAtDelegationLevel(capRequired);	
				});
			}
			self.delegateToCollaboration(newCM,context);
			var activityPorts = this.get('containedPort');
			activityPorts.each(function(port){
				if(port.get('type') === 'vdml_InputPort' ){
					var isResource = port.get('input').get('provider').get('containedPortOwner').get('type') === 'vdml_Store';
					if(isResource){
						self.createPortDelegationAndTargetCollaborationPort(port,newCM,activityDelegationContext);		
					}
				}
			});
			if(callback){
				callback();
			}
		}
	};
	
	getCurrentCapabilities(context){
		var contextCollaboration = context.get('contextCollaboration');
		if(contextCollaboration){
			return contextCollaboration.getCurrentCapabilities();	
		}else{
			return [];
		}
		
	};
	
	createCapabilityUseOld(bm,newCapabilityOffer,orgUnit,mainScenario){
		var self = this;
		var actDelContexts = this.get('delegationContext');
		var colls = new Backbone.Collection(); 
		if(actDelContexts.length > 0){	//Activity delegates
			actDelContexts.each(function(delCont){
				var contextColl = delCont.get('contextCollaboration');
				var processed = colls.get(contextColl.get('id'));
				if(!processed){
					colls.add(contextColl);
					contextColl.createCapabilityUseAtDelegationLevel(newCapabilityOffer);
				}
			});
			colls.reset();
			
			this.checkAltScenariosAndDelegateToCollaboration(bm,newCapabilityOffer);
		}else{
			var appliedCO = this.get('appliedCapabilityOffer');
			var cm;
			//if(appliedCO){
				cm = this.findBestMatchingExistingCapabilityMethods(appliedCO,newCapabilityOffer,bm);					
				if(!cm){
					cm = bm.createCapabilityMethodForActivityToDelegate(this);
					cm.createCapabilityUseAtDelegationLevel(appliedCO);
					cm.createCapabilityUseAtDelegationLevel(newCapabilityOffer);
				}
				var actColl = this.get('activityOwner');
				var contexts = actColl.get('delegationContext');	//TODO will this work across alternatives?
				contexts.each(function(context){
					self.delegateToCollaboration(cm,context);	
				});
				if(!contexts.get(mainScenario.get('id'))){
					self.delegateToCollaboration(cm,mainScenario);	
				}
				this.set('appliedCapabilityOffer',null);
				//TODO create tickets..for possible conflicts
			//}else{
			//	this.applyCapability(newCapabilityOffer);
			//}
		}		
	};
	
	delegateToCollaboration(targetCollaboration,context,reUseContext){
		var self = this;
		var dc;
		if(!reUseContext){
			var dcId = DataManager.getDataManager().guidGeneratorByOwner(context);
			dc = new DelegationContext({id:dcId,name:context.getUniqueProperty('name',this.get('name') + " Context",'delegationContext'),description:this.get('name') + " Context",parentContext:context});
		}else{
			dc = reUseContext;
		}
		dc.set('contextCollaboration',targetCollaboration);
		dc.set('delegatedActivity',this);
		var activityPorts = this.get('containedPort');
		activityPorts.each(function(port){
			self.createPortDelegationAndTargetCollaborationPort(port,targetCollaboration,dc);
		});
		var targetCollActivities = targetCollaboration.get('activity');
		targetCollActivities.each(function(activity){
			var activityDelContexts = activity.get('delegationContext');
			activityDelContexts.each(function(actDelCont){
				activity.copyDelegationContext(actDelCont);				
			});
		});
		return dc;
	};
	copyDelegationContext(context,importData,timeStamp,importPackage,targetContext,measurementsCopied){
		var copyContext;
		var self = this;
		if(!importData){
			var parentContext;
			if(!targetContext){
				parentContext = context.get('parentContext');	
			}else{
				parentContext = targetContext;
			}
			targetContext.get('delegationContext').each(function(childContext){
				if(childContext.get('delegatedActivity') === self){
					copyContext = childContext;
				}
			});
			if(!copyContext){
				var copyContextId = DataManager.getDataManager().guidGeneratorByOwner(context);
				copyContext = new DelegationContext({id:copyContextId,name:parentContext.getUniqueProperty('name',context.get('name') ,'delegationContext'),description:'Copy of ' + context.get('description') + " Context",'parentContext':parentContext});
				copyContext.set('contextCollaboration',context.get('contextCollaboration'));
				copyContext.set('delegatedActivity',this);
			}
			var portDels = context.get('contextBasedPortDelegation');
			//var activityPorts = this.get('containedPort');
			portDels.each(function(portDel){
				//var sourcePort = activityPorts.get({name:portDel.get('name')});
				var sourcePort = portDel.get('source');
				var targetPort = portDel.get('target');
				portDel.copyPortDelegation(copyContext,sourcePort,targetPort);
			});
		}
		context.copyData(importData ? context : copyContext,importData,timeStamp,importPackage,measurementsCopied);
	};
	
	findExactlyMatchingExistingCapabilityMethod(capabilitiesRequired,bm){
		var filterMethods = [];
		var businessCMs = bm.get('business').getCapabilityMethods();
		businessCMs.each(function(cm){
			var cmCapabilites = cm.getCurrentCapabilities();
			var commonCapabilities = _.intersection(capabilitiesRequired, cmCapabilites);
			if(commonCapabilities.length === capabilitiesRequired.length){
				filterMethods.push({method:cm});
			}
		});
		if(filterMethods.length > 0){
			filterMethods.sort(function(m1Obj,m2Obj){
				var m1Activities = m1Obj.method.get('activity');
				var m2Activities = m2Obj.method.get('activity');
				if(m1Activities.length < m2Activities.length){
					return -1;
				}else if(m1Activities > m2Activities.length){
					return 1;
				}else{
					var m1Flows = m1Obj.method.get('deliverableFlows');
					var m2Flows = m2Obj.method.get('deliverableFlows');
					if(m1Flows < m2Flows){
						return -1
					}else if(m1Flows > m2Flows){
						return 1;
					}else{
						if(m1Obj.method.get('containedPort').length < m2Obj.method.get('containedPort').length){
							return -1;
						}else if(m1Obj.method.get('containedPort').length > m2Obj.method.get('containedPort').length){
							return 1;
						}else{
							var m1Delegates = 0;
							var m2Delegates = 0;
							m1Activities.each(function(activity){
								if(activity.get('delegationContext').length > 1){
									m1Delegates++;
								}
							});
							m2Activities.each(function(activity){
								if(activity.get('delegationContext').length > 1){
									m2Delegates++;
								}
							});			
							return m1Delegates - m2Delegates;							
						}
					}
				}
			});
			return filterMethods[0].method;
		}	
	};
	findBestMatchingExistingCapabilityMethods(coUsed,coAdditional,bm){
		var filterMethods = [];
		var businessCMs = bm.get('business').getCapabilityMethods();
		businessCMs.each(function(cm){
			var appUsed;
			var appAddiUsed;
			
			function checkIfCMApplied(method){
				var cmActivities = method.get('activity');
				if(bm.get('bmActivity').findWhere({'activity':cmActivities})){
					cmActivities.each(function(activity){
						var actAppCO = activity.get('appliedCapabilityOffer');
						if(actAppCO === coUsed){
							appUsed = activity;
						}else if(actAppCO === coAdditional){
							appAddiUsed = activity;
						}
						if(appUsed && appAddiUsed){
							var cmObj = {method:cm,'appUsed':appUsed,'appAddiUsed':appAddiUsed}
							filterMethods.push(cmObj);
						}else{
							var delContexts = activity.get('delegationContext');
							delContexts.each(function(dc){
								var contextColl = dc.get('contextCollaboration');
								checkIfCMApplied(contextColl);
							});
						}
					});
				}
			}
			checkIfCMApplied(cm);
		});
		if(filterMethods.length >0){
			// TODO find best
			filterMethods.sort(function(m1Obj,m2Obj){
				var m1Activities = m1Obj.method.get('activity');
				var m2Activities = m2Obj.method.get('activity');
				if(m1Activities.length < m2Activities.length){
					return -1;
				}else if(m1Activities > m2Activities.length){
					return 1;
				}else{
					var m1ActFlows;
					var m2ActFlows;
					var m1Flows = m1Obj.method.get('deliverableFlows');
					m1Flows.each(function(flow){
						var providerPortContainer = flow.get('provider').get('containedPortOwner');
						if(providerPortContainer === m1Obj.appUsed || providerPortContainer === m1Obj.appAddiUsed){
							m1ActFlows++;
						}
						var recipientPortContainer = flow.get('recipient').get('containedPortOwner');
						if(recipientPortContainer === m1Obj.appUsed || recipientPortContainer === m1Obj.appAddiUsed){
							m1ActFlows++;
						}						
					});
					var m2Flows = m2Obj.method.get('deliverableFlows');
					m2Flows.each(function(flow){
						var providerPortContainer = flow.get('provider').get('containedPortOwner');
						if(providerPortContainer === m2Obj.appUsed || providerPortContainer === m2Obj.appAddiUsed){
							m1ActFlows++;
						}
						var recipientPortContainer = flow.get('recipient').get('containedPortOwner');
						if(recipientPortContainer === m2Obj.appUsed || recipientPortContainer === m2Obj.appAddiUsed){
							m2ActFlows++;
						}						
					});					
					if(m1Flows < m2Flows){
						return -1
					}else if(m1Flows > m2Flows){
						return 1;
					}else{
						if(m1Obj.method.get('containedPort').length < m2Obj.method.get('containedPort').length){
							return -1;
						}else if(m1Obj.method.get('containedPort').length > m2Obj.method.get('containedPort').length){
							return 1;
						}else{
							var m1Delegates = 0;
							var m2Delegates = 0;
							m1Activities.each(function(activity){
								if(activity.get('delegationContext').length > 1){
									m1Delegates++;
								}
							});
							m2Activities.each(function(activity){
								if(activity.get('delegationContext').length > 1){
									m2Delegates++;
								}
							});			
							return m1Delegates - m2Delegates;							
						}
					}
				}
			});
			return filterMethods[0].method;
		}
	};	
	checkAltScenariosAndDelegateToCollaboration(bm,competence){
		var self = this;
		var actPackage = this.getNestedParent();
		var packScenarios = actPackage.get('scenario');
		var plansRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
		plansRDFModel.getAltScenariosReferingToPackageScenarios(packScenarios,function(scenarios){
			var delegatedContexts = [];
			var undelegatedScenarios = [];
			self.getDelegatedContextsAndUndelegatedScenarios(scenarios,delegatedContexts,undelegatedScenarios);
			var delegatedSameColl;
			for(var i=0;i<delegatedContexts.length;i++){
				if(delegatedSameColl == null){
					delegatedSameColl = delegatedContexts[i].get('contextCollaboration');
				}else if(delegatedSameColl !== delegatedContexts[i].get('contextCollaboration')){
					delegatedSameColl = null;
					break;
				}
			}
			for(var j=0;j<undelegatedScenarios.length;j++){
				if(delegatedSameColl){
					self.delegateToCollaboration(delegatedSameColl, undelegatedScenarios[j]);
				}else{
					var cm = bm.createCapabilityMethodForActivityToDelegate(self);
					self.delegateToCollaboration(cm, undelegatedScenarios[j]);
					cm.createCapabilityUseAtDelegationLevel(competence);
				}		
			}
		});
		
	};
	getDelegatedContextsAndUndelegatedScenarios(scenarios,delegatedContexts,undelegatedScenarios){
		var self = this;
		for(var i=0;i<scenarios.length;i++){
			var actDelContext = null;
			function getDelegatedContext(context){
				var delContexts = context.get('delegationContext');
				delContexts.each(function(delContext){
					if(actDelContext){
						return;
					}
					if(delContext.get('delegatedActivity') === self){
						actDelContext = delContext;
					}else{
						getDelegatedContext(delContext);
					}
				});
			}
			getDelegatedContext(scenarios[i]);
			if(actDelContext){
				delegatedContexts.push(actDelContext);
			}else{
				undelegatedScenarios.push(scenarios[i]);
			}
		}
	};
	createResourceInput(bm,businessItem,orgUnit){
		var self = this;
		bm.getOrCreateBIStore(businessItem,function(resourceStore){
			var coll = self.get('activityOwner');
			var deliverableFlow;
			for(var i=0;i<coll.get('flow').length;i++){
				var input = coll.get('flow').at(i).get('recipient');
				if(input.get('containedPortOwner') == self && coll.get('flow').at(i).get('deliverable') == businessItem){
					deliverableFlow = coll.get('flow').at(i);
					break;
				}
			}
			var inputPort;
			if(!deliverableFlow) {
				var outputId = DataManager.getDataManager().guidGeneratorByOwner(resourceStore);
				var outPort = new OutputPort({id:outputId,name:resourceStore.getUniqueProperty('name', businessItem.get('name') + ' Output','containedPort'),description:businessItem.get('description') + ' Output',containedPortOwner:resourceStore});	
				var inputId = DataManager.getDataManager().guidGeneratorByOwner(self);
				inputPort = new InputPort({id:inputId,name:self.getUniqueProperty('name', businessItem.get('name') + ' Input','containedPort'),description:businessItem.get('description') + ' Input',containedPortOwner:self});	
				deliverableFlow = coll.assignDeliverableFlow(businessItem,outPort,inputPort);
			}else{
				return;
			}
			var actDelContexts = self.get('delegationContext');
			var colls = new Backbone.Collection(); 
			if(actDelContexts.length > 0){	//Activity delegates
				actDelContexts.each(function(delCont){
					var contextColl = delCont.get('contextCollaboration');
					var processed = colls.get(contextColl.get('id'));
					if(!processed){
						colls.add(contextColl);
						self.createPortDelegationAndTargetCollaborationPort(inputPort,contextColl,delCont);
					}
				});
				colls.reset();
			}
		});
	};	
	createPortDelegationAndTargetCollaborationPort(port,targetCollaboration,delCont){
		if(port instanceof InputPort){
			this.createInputPortDelegationAndTargetCollaborationPort(port,targetCollaboration,delCont);
		}else{
			this.createOutputPortDelegationAndTargetCollaborationPort(port,targetCollaboration,delCont);
		}
	};
	createInputPortDelegationAndTargetCollaborationPort(port,targetCollaboration,delCont){
		var inputId = DataManager.getDataManager().guidGeneratorByOwner(targetCollaboration);
		var collInputPort = new InputPort({id:inputId,name:targetCollaboration.getUniqueProperty('name', port.get('name'),'containedPort'),description:port.get('description'),containedPortOwner:targetCollaboration});	
		var delId = DataManager.getDataManager().guidGeneratorByOwner(delCont);
		new InputDelegation({source:port,target:collInputPort,id:delId,contextBasedPortDelegationOwner:delCont,name:delCont.getUniqueProperty('name',port.get('name') + 'Delegation','contextBasedPortDelegation'),description:port.get('name') + 'Delegation'});		
	};
	createOutputPortDelegationAndTargetCollaborationPort(port,targetCollaboration,delCont){
		var outputId = DataManager.getDataManager().guidGeneratorByOwner(targetCollaboration);
		var collOutputPort = new OutputPort({id:outputId,name:targetCollaboration.getUniqueProperty('name', port.get('name'),'containedPort'),description:port.get('description'),containedPortOwner:targetCollaboration});	
		var delId = DataManager.getDataManager().guidGeneratorByOwner(delCont);
		new OutputDelegation({source:port,target:collOutputPort,id:delId,contextBasedPortDelegationOwner:delCont,name:delCont.getUniqueProperty('name',port.get('name') + 'Delegation','contextBasedPortDelegation'),description:port.get('name') + 'Delegation'});		
	};	
	
	applyCapability(capability){
		this.set('appliedCapabilityOffer',capability);
	};
	createWhatValues(bm,viewAlternative,whatValueName,isPrime,values,unit,valFormula, accumulator,baseTuples,maxChange,bmActivityModal,oldValModel,expression){
        var valueOutputPort;
        var self = this;
		if(bmActivityModal && bmActivityModal.get('defaultOutput')){
			valueOutputPort = bmActivityModal.get('defaultOutput');
        } else {
            _.each(self.get('containedPort').models, function (port) {
                if (port.get('type') === "vdml_OutputPort"){
                    valueOutputPort = port;
                }
            });
            if (!valueOutputPort) {
                var outputId = DataManager.getDataManager().guidGeneratorByOwner(this);
                valueOutputPort = new OutputPort({ id: outputId, name: this.getUniqueProperty('name', this.get('name'), 'containedPort') + " port", description: this.get('description') + " port", containedPortOwner: this });
            }
            if (bmActivityModal) {
                bmActivityModal.set('defaultOutput', valueOutputPort);
            }
		}
		var valueAdd = valueOutputPort.addValue(bm,viewAlternative,whatValueName,isPrime,values,unit,valFormula,accumulator,baseTuples,maxChange,oldValModel,expression);
		return valueAdd;
	};
	getActivityCompetences(competences) {
	    var self = this;
	    if (!competences) {
	        competences = [];
	    }
	    var activityCompetence = this.get('appliedCapabilityOffer');
	    if (activityCompetence) {
	        competences.push(activityCompetence);
	    }
	    var collaboration = this.get('activityOwner') ? this.get('activityOwner') : this.previousAttributes().activityOwner;
	    var delFlows = collaboration ? collaboration.get('flow').models.concat() : [];
	    _.each(delFlows, function (flow) {
	        var receiver = flow.get('recipient') ? flow.get('recipient').get('containedPortOwner') : null;
	        if (receiver === self) {
	            var provider = flow.get('provider') ? flow.get('provider').get('containedPortOwner') : null;
	            if (provider && provider.get('type') === 'vdml_Pool') {
	                competences.push(flow.get('deliverable'));
	            }
	        }
	    });
	    var activityDelegations = this.get('delegationContext');
	    activityDelegations.each(function (context) {
	        var contextColl = context.get('contextCollaboration');
	        if (contextColl) {
	            var collActivities = contextColl.get('activity');
	            collActivities.each(function (activity) {
	                activity.getActivityCompetences(competences);
	            });
	        }
	    });
	    return competences;
	};
	
	handleActivityNameChange(modelRef, newName){
		if(Backbone.skipPersistance){
			return;
		}
		var self = this;
		DataManager.getDataManager().getReverseAssociationInstances( self, 'vdml_BusinessModelActivity-activity','vdml_BusinessModelActivity',"vbc:" + "cmof_EObject-name", 
		function(bmActivities){	
			bmActivities.each(function(bmActivity){
				if(bmActivity.get('name') !== newName){
					bmActivity.set('name',newName);
				}
			});
			utils.updateUIActivity(self,false);			
		}, true);
		var containedPorts = self.get('containedPort');
		containedPorts.each(function(port){
			if(port.get('type') === "vdml_OutputPort") {
				port.set('name',newName + ' port');
				port.set('description',newName + ' port');	
			}	
		});
		var delegationContexts = self.get('delegationContext');
		delegationContexts.each(function(context){
			context.set('name',newName + ' Context');
			context.set('description',newName + ' Context');		
        });

        /*var modelType = self.get('type').replace('_', '.'); TODO for name change
        var plan = viewAlternative.getNestedParent();
        var suffixId = window.utils.getSuffix(self.id);
        for (var i = 0; i < plan.get('phase').length; i++) {
            var phase = plan.get('phase').at(i);
            _.each(phase.get('phaseAlternative').models, function (alt) {
                if (alt != viewAlternative) {
                    var altVal = Backbone.Relational.store.getObjectByName(modelType).find({ id: alt.id + suffixId });
                    if (altVal) {
                        var compColl = altVal.getParent().get('activity');
                        if (compColl && !compColl.findWhere({ 'name': name })) {
                            altVal.set('name', name);
                        }
                    }
                }
            });
        }*/
	};
	
	updateBMActivities(performedRole){
		var self = this;
		DataManager.getDataManager().getReverseAssociationInstances( self, 'vdml_BusinessModelActivity-activity','vdml_BusinessModelActivity',"vbc:" + "cmof_EObject-name", 
		function(bmActivities){	
			bmActivities.each(function(bmActivity){
				if(bmActivity){
					var bm = bmActivity.get('bmActivityOwner');
					var bmRoles = bm.get('bmRole');
					var performedActivityRole = bmRoles.findWhere({id:performedRole.get('id')});
					if(!performedActivityRole){
						bmActivity.destroy();
					}
				}
			});
		}, true);
	};
	
	copy(targetColl,measurementsCopied,valuesCopied){
		var actId = DataManager.getDataManager().guidGeneratorByOwner(targetColl);
		var sourceColl = this.get('activityOwner');
		var activityCopy;
		if(sourceColl === targetColl){
			activityCopy = new this.constructor({id:actId,activityOwner:targetColl,name:targetColl.getUniqueProperty('name',"Copy of " + this.get('name'),'activity')});
		}else{
			activityCopy = new this.constructor({id:actId,activityOwner:targetColl,name:targetColl.getUniqueProperty('name',this.get('name'),'activity')});
		}
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(activityCopy,measurementsCopied);			
		});	
		var reccuranceInterval = this.get('recurranceInterval');
		if(reccuranceInterval){
			reccuranceInterval.copy(activityCopy,measurementsCopied);
		}
		var duration = this.get('duration');
		if(duration){
			reccuranceInterval.copy(activityCopy,measurementsCopied);
		}
		var performer = this.get('performingRole');
		if(performer){
			var targetPerformer = targetColl.get('collaborationRole').findWhere({name:performer.get('name')});
			activityCopy.set('performingRole',targetPerformer);
		}
		activityCopy.set('capabilityRequirement',this.get('capabilityRequirement'));
		activityCopy.set('appliedCapabilityOffer',this.get('appliedCapabilityOffer'));
		this.get('implementedPractice').each(function(practice){
			activityCopy.get('implementedPractice').add(practice);
		});
		this.get('containedPort').each(function(port){
			port.copy(activityCopy,this.get('activityOwner'),activityCopy.get('activityOwner'),measurementsCopied,valuesCopied);
		});
		this.get('resourceUse').each(function(){
			//TODO
		});
		this.get('delegationContext').each(function(delContext){
			activityCopy.copyDelegationContext(delContext);
		});
		return activityCopy;
	};

	getBusinessModelsInvolved(callback) {
		var self = this;
		var businessModels = [];
		DataManager.getDataManager().getAllDocumentsCollectionOfType(self, 'vdml_BusinessModel', function (refs) {
			var count = refs.length;
			function getBMInstance() {
				count--;
				if (count >= 0 && refs.at(count).get('parent')) {
					var ref = refs.at(count);
					var parentId = ref.get('parent');
					var repId = DataManager.getDataManager().getRepositoryId(parentId);
					var vdmStore = DataManager.getDataManager().getVDMStore(repId);
					DataManager.getDataManager().fetchDocumentFromPackage(parentId, "appbo/vdml/ValueDeliveryModel", ref.get('version'), ref.get('id'), "appbo/vdml/BusinessModel", vdmStore, {
						success: function (model) {
							var bmActivities = model.get('bmActivity');
							var activityExistsInBM = bmActivities.findWhere({'activity': self});
							if (activityExistsInBM) {
								businessModels.push(model);
							}
							getBMInstance();
						},
						error: function (error) {
							console.log(error);
							getBMInstance();
						}
					});
				} else {
					if (count >= 0) {
						getBMInstance();
					} else {
						callback(businessModels);
					}
				}
			}
			getBMInstance();
		}, null, null, true, null, false);
	};	

//#endCustomMethods	
	
	}
	path.ActivityMixin = ActivityMixin;
	//return ActivityMixin;
//});