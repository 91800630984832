import * as _ from 'underscore'
import { ValuePropositionComponentMixin } from "./ValuePropositionComponentMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueElement2Mixin } from './ValueElement2Mixin';


var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class ValuePropositionComponent2Mixin {
    static getMixinRelations(){
        var ret = ValuePropositionComponentMixin.getMixinRelations().filter(item =>{
            return item.key !== "percentageWeight" && item.key !== "satisfactionLevel" && item.key !== "recipientOpinion";
        });
        ret = _.union(ret,[
                {
                    type :Backbone.HasOne,
                    containingClass:"vdml_ValuePropositionComponent",
                    key:"percentageWeight",
                    relatedModel:"vdml.ValueElement",
                    reverseRelation: {
                        key:"percentageWeightOwner",
                        type :Backbone.HasOne,
                        includeInJSON:"id"
                    }
                },
                {
                    type :Backbone.HasOne,
                    containingClass:"vdml_ValuePropositionComponent",
                    key:"satisfactionLevel",
                    relatedModel:"vdml.ValueElement",
                    reverseRelation: {
                        key:"satisfactionLevelOwner",
                        type :Backbone.HasOne,
                        includeInJSON:"id"
                    }
                },
                {
                    type :Backbone.HasOne,
                    containingClass:"vdml_ValuePropositionComponent",
                    key:"recipientOpinion",
                    relatedModel:"vdml.ValueElement",
                    reverseRelation: {
                        key:"recipientOpinionOwner",
                        type :Backbone.HasOne,
                        includeInJSON:"id"
                    }
                }
            ])
        return ret;
    }
    
    static getCumulativeMixinRelations(){
		if (!ValuePropositionComponent2Mixin.cummulativeRelations) {
            ValuePropositionComponent2Mixin.cummulativeRelations = _.union(ValuePropositionComponent2Mixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("ValueElement","vdml").getCumulativeMixinRelations()
            );
        }
		return ValuePropositionComponent2Mixin.cummulativeRelations.slice();
    }

    prime(value,valueUnit,valFormula,satisfactionValue,satisfactionType,isDimensional,satisfactionUnit,weight,recipient,accumulator,baseTuples,interValTuples,timestamp,viewAlternative,maxChange,currentBM,expression,callback){
		var self = this;
		//var currentPackage = this.getNestedParent();
		//var measureLibrary = currentPackage.createDefaultMeasureLibrary();
		var valueMeasurement = self;
		var recipientMC = self.get('recipientOpinion');
        var satisfactionLevel = self.get('satisfactionLevel');
        var percentageWeight = self.get('percentageWeight');

		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		if(isDimensional) {
            var measurementComponent = self.primeValueMeasurement(valueMeasurement, self, value, valueUnit, valFormula, accumulator, baseTuples, timestamp, viewAlternative, maxChange, false, expression);
            if (recipient && recipient != "") {
				if(!recipientMC) {
					self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
					recipientMC = self.get('recipientOpinion');
				}
                self.primeValueMeasurement(recipientMC, self, recipient, valueUnit, null, accumulator, [], timestamp, viewAlternative, maxChange, true, expression);
            }
		}else{
			if (recipient && recipient != "") {
				if(!recipientMC) {
					self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
					recipientMC = self.get('recipientOpinion');
				}
				self.primeSatisfactionLevel(recipientMC,satisfactionType,isDimensional,recipient,null,scenario,null,interValTuples,timestamp);
			}
		}
		
		if(interValTuples.length > 0) {
			if (!satisfactionLevel) {
				self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
				satisfactionLevel = self.get('satisfactionLevel');
			}
			self.primeSatisfactionLevel(satisfactionLevel, satisfactionType, isDimensional, satisfactionValue, satisfactionUnit, scenario, measurementComponent, interValTuples, timestamp);
		}
        
        if (weight && weight !== "") {
			if(!percentageWeight) {
				self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
				percentageWeight = self.get('percentageWeight');
			}
            self.primeWeight(percentageWeight, weight, scenario, timestamp);
        }
		if(isDimensional) {
            if(baseTuples.length > 0) {
                self.calculate(scenario);
            }
            if(callback){
                callback(true);
            }
		}
		else {
			callback();
		}
	};

    update(period, year, name,value,valueUnit,valFormula,satisfactionType,isDimensional,satisfactionValue,satisfactionUnit,weight,recipientValue,accumulator,baseTuples,intervalTuples,timestamp,viewAlternative,maxChange,currentBM,expression,callback){
		var self = this;
		var replaceSatMeasurement = false;
		var replaceSatMeasure = false;
		var calculateSat = false;
		var changeInValue = false;
		var obsMesScenario;
		var obsMesSatisfactionCurrentScenario;
		var obsMesSatisfactionScenario;
		var measureSatisfaction;
		var mestWeight;
		var obsMesWeightCurrentScenario;
		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		var beepPackage = self.getNestedParent();
		var valueMeasurement = null;//self.get('valueMeasurement');
		var satisfactionLevel = self.get('satisfactionLevel');
		var percentageWeight = self.get('percentageWeight');
		var recipientMC = self.get('recipientOpinion');
		var isTimeStamp = false;
		if(timestamp){
			isTimeStamp = true;
		}
		
		DataManager.getDataManager().getMainScenario(beepPackage,viewAlternative.get('id'),function(mainScenario){
            handleChanges(mainScenario);
        });

        function handleChanges(currentScenario) {
            if (!satisfactionLevel || !percentageWeight || !recipientMC) {
                self.getNestedParent().createValueDetails(self, null, intervalTuples.length > 0, weight, recipientValue);
                satisfactionLevel = self.get('satisfactionLevel');
                percentageWeight = self.get('percentageWeight');
                recipientMC = self.get('recipientOpinion');
                if (intervalTuples.length > 0) {
                    var valueObsMes = currentScenario.getObservedMeasureWithMeasurements(valueMeasurement, isTimeStamp, timestamp);
                    if (valueObsMes) {
						obsMesSatisfactionCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(satisfactionLevel, isTimeStamp, timestamp);
						if(!obsMesSatisfactionCurrentScenario){
							var measurement = valueObsMes.get('measurements').at(0);
							self.primeSatisfactionLevel(satisfactionLevel, satisfactionType, isDimensional, satisfactionValue, satisfactionUnit, scenario, measurement, intervalTuples, timestamp);
						}
                    }
                }
                if (weight && weight !== "" && percentageWeight) {
					obsMesWeightCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(percentageWeight,isTimeStamp,timestamp);	
					if(!obsMesWeightCurrentScenario){
						self.primeWeight(percentageWeight, weight, scenario, timestamp);
					}
                }
				if (recipientValue && recipientValue !== "" && recipientMC) {
					var obsMesRecipientCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(recipientMC,isTimeStamp,timestamp);	
					if(!obsMesRecipientCurrentScenario){
						self.primeValueMeasurement(recipientMC, self, recipientValue, valueUnit, null, accumulator, [], timestamp, viewAlternative, maxChange, true, expression);
					}
				}
            }
            self.updateValueElementChanges(currentScenario, period, year, name, value, valueUnit, valFormula, baseTuples, timestamp, viewAlternative, maxChange, function(iObsMesScenario,iMestVM,iMeasureVM,icalculateSat,ichangeInValue){
                changeInValue = ichangeInValue;
                obsMesScenario = iObsMesScenario;
                calculateSat = icalculateSat;
                handleRecipientValueChange();
                handleSatisfactionAndWeight();
            });
			function handleRecipientValueChange(){
				var valueObsMes = currentScenario.getObservedMeasureWithMeasurements(recipientMC,isTimeStamp,timestamp);
				var obsMesScenario = scenario.getObservedMeasureWithMeasurements(valueMeasurement,isTimeStamp,timestamp);
				if(valueObsMes){
					var recipientMeasurement = valueObsMes.get('measurements').at(0);
					var recipientMeasure = valueObsMes.get('measure');	
					var roundingFactor = valueUnit?valueUnit.roundingDigits:null;
					if(recipientValue && !isNaN(recipientValue) && roundingFactor){
						recipientValue = parseFloat(recipientValue).toFixed(roundingFactor);
					}
					if(recipientMeasurement && recipientMeasurement.get('value') !== recipientValue){
						if(recipientMeasure.get('type') === "smm_GradeMeasure"){
							recipientMeasurement.set('symbol',recipientValue);
						}else{
							recipientMeasurement.set('value',recipientValue);
						 }
					}
					if(recipientMeasure && recipientMeasure.get('unit') && recipientMeasure.get('unit').get('name') !== valueUnit.unitName){
						var unit = beepPackage.findOrCreateUnit(valueUnit.unitName,valueUnit.unitObj);
						if(!(obsMesScenario && recipientMeasure.get('observedMeasure').length > 1)){
							recipientMeasure.set('unit',unit);
						}
					}
				}
			}
            function handleSatisfactionAndWeight() {
				obsMesSatisfactionCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(satisfactionLevel,isTimeStamp,timestamp);
				obsMesSatisfactionScenario = scenario.getObservedMeasureWithMeasurements(satisfactionLevel,isTimeStamp,timestamp);
				if(obsMesSatisfactionCurrentScenario){
					measureSatisfaction = obsMesSatisfactionCurrentScenario.get('measure');					
				}			
				handleSatisfactionUnitChange();
				handleSatisfactionTypeChange(function(){
					handleSatisfactionIntervalsChange(function(){
						obsMesWeightCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(percentageWeight,isTimeStamp,timestamp);	
						if(obsMesWeightCurrentScenario){
							mestWeight = obsMesWeightCurrentScenario.get('measurements').at(0);
						}
						removeAssociationsForSatisfactionOnly();
						handleWeightChange();
						
						function removeAssociationsForSatisfactionOnly(){
							if(!isDimensional && satisfactionType === "smm_GradeMeasure") {
								var aggToColl = self.get('aggregatedTo');
								aggToColl.each(function(agg){
									agg.get('aggregatedFrom').remove(self);
								});
							}
						}
					});
				});
			}
		}
		
        function handleSatisfactionIntervalsChange(satCallback) {
                if (!obsMesSatisfactionScenario) {
                    satCallback();
                    return;
                }
				var netTuples = 0;
				var newTuples = 0;
				var updateTuples = 0;
				var oldTuples = 0;
				var measureSatScenario = obsMesSatisfactionScenario.get('measure');
				var satisfactionMest = obsMesSatisfactionScenario.get('measurements').at(0);
				var intervals;
				if(!isDimensional && satisfactionMest && satisfactionMest.get('symbol') != satisfactionValue){
					changeInValue = true;
					satisfactionMest.set('symbol',satisfactionValue);
				}
				intervals = measureSatScenario.get('interval');	
				
				_.each(intervalTuples,function(intervalTuple, i){
					if(intervalTuple.old && intervalTuple.new){
						netTuples++;
						var interval = intervals.get(intervalTuple.old.id);	
						if(interval){
							var changed = false;
							for(var prop in intervalTuple.old){
								if(intervalTuple.old[prop] !== intervalTuple.new[prop]){
									changed = true;
									if(measureSatScenario.get('observedMeasure').length <=1){
										if(prop !== 'id'){
											interval.set(prop,intervalTuple.new[prop]);		
										}
									}
								}
							}
							if(changed){
								updateTuples++;
							}
						}else {//For scenario update
							var changed = false;
							if(measureSatScenario.get('observedMeasure').length <=1) {
								for(var prop in intervalTuple.old){
									if(intervals.at(i) && intervals.at(i).get(''+prop) !== intervalTuple.new[prop] && prop !== 'id'){
										changed = true;
										intervals.at(i).set(prop,intervalTuple.new[prop]);		
									}else if(!intervals.at(i)){
										changed = true;
										if(measureSatScenario.get('type') === 'smm_GradeMeasure'){
											measureSatScenario.createInterval(intervalTuple.new.maximumOpen,intervalTuple.new.minimumOpen,intervalTuple.new.maximumEndPoint,intervalTuple.new.minimumEndPoint, intervalTuple.new.symbol, intervalTuple.new.smiley, intervalTuple.new.smileyColor, intervalTuple.new.correctiveActions);
										}else{
											measureSatScenario.createInterval(intervalTuple.new.maximumOpen,intervalTuple.new.minimumOpen,intervalTuple.new.maximumEndPoint,intervalTuple.new.minimumEndPoint, intervalTuple.new.value, intervalTuple.new.smiley, intervalTuple.new.smileyColor, intervalTuple.new.correctiveActions);
										}
									}
								}
								if(changed){
									updateTuples++;
								}
							}else {
								updateTuples++;
							}
						}
					}else if(intervalTuple.new && !intervalTuple.old){
						netTuples++;
						newTuples++;
						if(measureSatScenario.get('observedMeasure').length <=1){
							if(measureSatScenario.get('type') === 'smm_GradeMeasure'){
								measureSatScenario.createInterval(intervalTuple.new.maximumOpen,intervalTuple.new.minimumOpen,intervalTuple.new.maximumEndPoint,intervalTuple.new.minimumEndPoint, intervalTuple.new.symbol, intervalTuple.new.smiley, intervalTuple.new.smileyColor, intervalTuple.new.correctiveActions);
							}else{
								measureSatScenario.createInterval(intervalTuple.new.maximumOpen,intervalTuple.new.minimumOpen,intervalTuple.new.maximumEndPoint,intervalTuple.new.minimumEndPoint, intervalTuple.new.value, intervalTuple.new.smiley, intervalTuple.new.smileyColor, intervalTuple.new.correctiveActions);
							}
						}						
					}else{
						var interval = intervals.get(intervalTuple.old.id);	
						if(interval){
							oldTuples++;
							if(measureSatScenario.get('observedMeasure').length <=1){
								interval.destroy();		
							}						
						}
					}
				});
				if(intervals.length > intervalTuples.length){//For scenario update
					for(var i=intervals.length-1;i>-1;i--){
						if(!intervalTuples[i]){
							intervals.at(i).destroy();
						}
						
					}
				}
				if(oldTuples> 0 || newTuples > 0 || updateTuples> 0){
					replaceSatMeasure = true;
					calculateSat = true;
					changeInValue = true;
					updateOtherSatScenarios = true;
				}
				if(replaceSatMeasure){
					if(calculateSat && !replaceSatMeasurement){
						if(isDimensional){
							satisfactionMest.calculate();	
						}else{
							satisfactionMest.set('symbol',satisfactionValue);
						}
					}
					satCallback();
				}else{
					if(calculateSat && !replaceSatMeasurement){
						if(isDimensional){
							satisfactionMest.calculate();	
						}else{
							satisfactionMest.set('symbol',satisfactionValue);
						}
						
					}
					satCallback();
				}
				calculateSat = true;
		}			
		
        function handleWeightChange() {
        	var weightCalc = false;
			if(mestWeight && mestWeight.get('value') !== weight){
				mestWeight.set('value',weight);
				weightCalc = true;
			}
			if(weightCalc){
				changeInValue = true;
			}
			if(changeInValue || weightCalc){
				if(obsMesScenario)
					obsMesScenario.set('lastChange',new Date().getTime());
				else {
					obsMesSatisfactionScenario?obsMesSatisfactionScenario.set('lastChange',new Date().getTime()):null;
				}
			}
			callback(changeInValue);
		}		

		
		function handleSatisfactionTypeChange(satTypeChangeCallback){
            var oldDimensional = measureSatisfaction?measureSatisfaction.get('measureRelationships').length > 0:null;
			if(measureSatisfaction && (measureSatisfaction.get('type') !== satisfactionType || isDimensional !== oldDimensional)){
				changeInValue = true;
				if(obsMesSatisfactionScenario){
					replaceSatMeasurement = true;
					replaceSatMeasure = true;
					updateOtherSatScenarios = true;
					satTypeChangeCallback();
				}else{
					destroyOldSatMeasure = true;
					replaceSatMeasure = true;
					replaceSatMeasurement = true;
					updateOtherSatScenarios = true;
					satTypeChangeCallback();
				}
			}else{
				satTypeChangeCallback();
			}
		}
		
		function handleSatisfactionUnitChange(){
			var unit = beepPackage.findOrCreateUnit(satisfactionUnit.unitName,satisfactionUnit.unitObj);
			if(measureSatisfaction && measureSatisfaction.get('unit') != unit){
				changeInValue = true;
				if(obsMesSatisfactionScenario){
					replaceSatMeasure = true;
					updateOtherSatScenarios = true;
				}else{
					measureSatisfaction.set('unit',unit);
				}
				rescaleSat = true;
			}
			if(unit){
				if(unit.get('roundingDigits') != satisfactionUnit.roundingDigits){
					unit.set('roundingDigits',satisfactionUnit.roundingDigits);
					rescaleSat = true;
				}
				unit.set('significantDecimals',satisfactionUnit.significantDecimals);
			}
		}
		
	};
}
utils.customExtendClass (ValuePropositionComponent2Mixin,new ValuePropositionComponentMixin());

path.ValuePropositionComponent2Mixin = ValuePropositionComponent2Mixin;