import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { CapabilityLibraryDiagramDetailsViewModel } from './CapabilityLibraryDiagramDetailsViewModel'
import { BMGuidanceViewModel } from '../../../help/views/properties/BMGuidanceViewModel'
import { BusinessModel } from '../../../../bo/vdml/BusinessModel'
import { Participant } from '../../../../bo/vdml/Participant'
import { ValueElement } from '../../../../bo/vdml/ValueElement'
import { EcoMapDiagram } from '../../../../bo/vdml/EcoMapDiagram'
import { EcoMapDiagramMixin } from '../../../../bo/vdml/EcoMapDiagramMixin'
import * as EcoModeler from '../../../../../../libs/bower-vdml-js/dist/vdml-modeler.min'
import * as EcoNavigatedViewer from '../../../../../../libs/bower-vdml-js/dist/vdml-navigated-viewer.min'

import { CodeContainer } from '../../../../bo/beeppackage/CodeContainer'

/*define(["require", "jquery", 'async', "underscore", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/vdml/EcoMapDiagram", "appbo/vdml/EcoMapDiagramMixin", "knockback", "ecomodeler", "econavigatedviewer", "appviews/capabilityLibrary/views/designer/CapabilityLibraryDiagramDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/beeppackage/CodeContainer"],
	function (require, $, async, _, Backbone, ko, bootbox, DataManager, global, BusinessModel, Participant, ValueElement, EcoMapDiagram, EcoMapDiagramMixin, kb, EcoModeler, EcoNavigatedViewer, CapabilityLibraryDiagramDetailsViewModel, BMGuidanceViewModel, CodeContainer) {*/

var path = DataManager.getDataManager().buildAppNsPath("capabilityLibrary.views.designer", global.version);
export class CapabilityLibraryDiagramViewModel {
    constructor(model, options) {
        this.init(model, options);
    }
	sortShapesInTableByName(left, right) {
		if (left.name && right.name) {
			return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
		}
	}
	getViewportSize(w) {

		// Use the specified window or the current window if no argument
		w = w || window;

		// This works for all browsers except IE8 and before
		if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

		// For IE (or any browser) in Standards mode
		var d = w.document;
		if (document.compatMode == "CSS1Compat")
			return {
				w: d.documentElement.clientWidth,
				h: d.documentElement.clientHeight
			};

		// For browsers in Quirks mode
		return { w: d.body.clientWidth, h: d.body.clientHeight };

	}
	calculateSize(h, w, n, size, constant) {
		var self = this;
		var r = 1, c = 1;
		while ((r * (size * 3 + constant / 4) + (constant / 2)) < w) {
			if (r <= n) {
				r++;
			} else {
				break;
			}
		}
		r = r - 1;
		c = r !== 0 ? Math.round(n / r) : 0;
		if ((c * (size / 3 + constant / 4) + (constant / 2)) > h) {
			if (c > n / r) {
				size = size - size / 20;
			} else {
				size = size - size / 30;
			}
			return self.calculateSize(h, w, n, size, constant);
		} else if (((c + 1) * (size + constant / 4) + (constant / 2)) < h) {
			c = c + 1;
		}
		if (n - c * r >= 1) {
			size = size - size / 30;
			return self.calculateSize(h, w, n, size, constant);
		}
		if (size > 10) {
			return size + ',' + r + ',' + c;
		} else {
			return 10 + ',' + r + ',' + c;
		}
	}
	loadLibraryPackages(callback) {
		var self = this;
		var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
		var capabilityLibraryPackages = [];
		var allCapMapList = [];
		var commonAltId = planId + "-Common@";
		DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
			if (result && result.length > 0) {
				async.eachSeries(result.models, function (pack, diagCallback) {
					if (pack.get('id').indexOf("Common") > -1) {
						if (pack.get('id').indexOf('CommonPackage') === -1) {
							var repId = DataManager.getDataManager().getRepositoryId(pack.get('id'));
							var vdmStore = DataManager.getDataManager().getVDMStore(repId);
							DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'vdml_ValueDeliveryModel', pack.get('version'), pack.get('id'), 'vdml_ValueDeliveryModel', vdmStore, {
								success: function (model) {
									capabilityLibraryPackages.push(model);
									diagCallback();
								},
								error: function (error) {
									diagCallback();
									console.log('Unable to load selected Package');
								}
							}, true, null);
						} else {
							diagCallback();
						}
					} else {
						diagCallback();
					}
				}, function (arr) {
					_.each(capabilityLibraryPackages, function (mypackage) {
						if (mypackage) {
							_.each(mypackage.get('diagrams').models, function (diag) {
								allCapMapList.push(diag);
							})
						}
					});
					callback(allCapMapList);
				});
			} else {
				callback(allCapMapList);
			}
		}, false, null, true);
	}
    init(model, options) {
        var self = this;
        this.CapabilityLibraryDiagramViewModel = this;
        self.model = model;
        self.data = model.get('data');
        this.enableComplete = ko.observable(false);
		//this.zoomButton = ko.observable(true);
        this.shapesList = ko.observableArray([]);
        this.unsortedShapesList = [];
		this.deletedCodedContainers = [];
        this.deleteShapes = [];
        this.completeButton = ko.observable(true);
        this.children = [];
        this.mappingObject = {};
        this.mappingObject['vdml:CapabilityCategory'] = { name: DataManager.getDataManager().get('localeManager').get('CapabilityCategory'), collectionName: 'capabilities' };
        this.mappingObject['vdml:CapabilityDefinition'] = { name: DataManager.getDataManager().get('localeManager').get('CapabilityDefinition'), collectionName: 'capabilities' };
        this.mappingObject['vdml:Capability'] = { name: DataManager.getDataManager().get('localeManager').get('Capability'), collectionName: 'capabilities' };
        this.showDashboardEcoMap = false;
        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        this.encodeId = htmlEscape(self.model.id);
		this.options = options;
        this.modeler = null;
        this.showProperties = _.bind(self.showProperties, self);
		this.editMode = DataManager.getDataManager().get('readMode')?false:true;
        if(window.utils.checkIsLinked(null,true)){
            self.editMode = false;
        }
        //window.utils.loadTemplate(function (name) {
       // })
        if (options.parentView && options.parentView == "Dashboard") {
            this.showDashboardEcoMap = true;
            this.editMode = options.editMode;
            if (!ko.components.isRegistered('CapabilityLibraryDiagramDetails')) {
                ko.components.register("CapabilityLibraryDiagramDetails", {
                    viewModel: CapabilityLibraryDiagramDetailsViewModel,
                    template: '<div></div>'
                });
            }
        } else {
            //window.utils.loadTemplate(function (name) { });
            if (!ko.components.isRegistered('CapabilityLibraryDiagramDetails')) {
                ko.components.register("CapabilityLibraryDiagramDetails", {
                    viewModel: CapabilityLibraryDiagramDetailsViewModel,
					template: window.utils.getHtmlPage("CapabilityLibraryDiagramDetailsTemplate"),
                    synchronous: true
                });
            }
        }

        if (!ko.components.isRegistered('CapabilityLibraryDiagramGuidance')) {
            self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
            ko.components.register("CapabilityLibraryDiagramGuidance", {
                viewModel: { instance: self.guidanceViewInstance },
                template: '<div id="guidanceTab' + self.encodeId + '"></div>',
                synchronous: true
            });
        }


        if (!self.data) {
            self.data = window.utils.getXmlData(model.get('type').replace("_", "."), htmlEscape(model.id));
			self.model.set('data',self.data);
        }

        
        
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name'
            , 'description'
            , 'Delete'
            , 'Complete'
            , 'CapabilityLibraryDiagramDetails'
            , 'Guidance'
            , 'Collaboration'
            , 'shapeDetails'
			, 'NavigateToCapabilityMap'
        ]);

    }

	navigateToCapMap(businessObj) {
		var self = this;
		self.loadLibraryPackages(function(allCapMapList){
			var showCapMapsList = [];
			var shapetype = businessObj.$type.replace(':', '.');
			var shapeCap = Backbone.Relational.store.getObjectByName(shapetype).find({ id: businessObj.mid });
			_.each(allCapMapList, function (capMap) {
				var exists = capMap.get('capabilities').findWhere({'id':shapeCap.id})
				/*_.each(capMap.get('capabilities').models, function (cap) {
					var exists = _.filter(shapeCap.get('childCapability').models,function(childShapeCap){
						return cap.id = childShapeCap.id;
					})
					
				});*/
				if (exists && self.model !== capMap) {
					showCapMapsList.push(capMap);
				}
			});
			var htmlContent = "<select class=\"form-control\" id=\"selectCapMapDiag\">";
			if(showCapMapsList.length === 1){
				var selectedCapMap = showCapMapsList[0];
				DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + selectedCapMap.getParent().id + "/" + selectedCapMap.getNestedParent().get('type') + "/" + selectedCapMap.getNestedParent().get('version') + "/" + selectedCapMap.id + "/" + selectedCapMap.get('type') + "/" + DataManager.getDataManager().getRepositoryId(selectedCapMap.id), {trigger: true});
			}else{
				htmlContent = htmlContent + "<option>Select Capability Map  ...</option>";
				for (var i = 0; i < showCapMapsList.length; i++) {
					htmlContent = htmlContent + "<option capLibMapId ='" + showCapMapsList[i].id + "'>" + showCapMapsList[i].get('name') + "</option>";
				}
			
				htmlContent = htmlContent + "</select><p/>";
			
				bootbox.dialog({
					title: "<div>&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('NavigateToCapabilityMap')+"</div>",
					message: htmlContent,
					buttons: {
						main: {
							label: "Close",
							className: "btn btn-default",
						},
						success: {
							label: "Complete",
							className: "btn btn-complete complete-btn ",
							callback: function () {
								var capLibMapId = $('#selectCapMapDiag option:selected').attr('capLibMapId');
								if (capLibMapId !== undefined) {
									for (var i = 0; i < showCapMapsList.length; i++) {
										if (showCapMapsList[i].id == capLibMapId) {
											var selectedCapMap = showCapMapsList[i];
										}
									}
									window.utils.startSpinner('navigateCapLibDiag', "Navigating to Capability Library Diagram ... ");
									DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + selectedCapMap.getParent().id + "/" + selectedCapMap.getNestedParent().get('type') + "/" + selectedCapMap.getNestedParent().get('version') + "/" + selectedCapMap.id + "/" + selectedCapMap.get('type') + "/" + DataManager.getDataManager().getRepositoryId(selectedCapMap.id), {trigger: true});
									window.utils.stopSpinner('navigateCapLibDiag');
								}
							}
						}
					}
				});
			}
		});
	}
	fixCapabilityRelations(callback) {
		var self = this;
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
			self.capabilityLibraryPackages = capabilityLibraries;
			self.loadTable();
			self.obj = {};
			var shapes = [];
			_.each(self.shapesList(),function(shape){
				if (shape.businessObject.flowElements && shape.businessObject.flowElements.length > 0){
					var flowElements = [];
					$.extend(true, flowElements,shape.businessObject.flowElements);
				}
				shapes.push({ name: shape.name, type: shape.type, mappedEle: shape.mappedEle, id: shape.businessObject.id, mid: shape.businessObject.mid, flowElements: flowElements , businessObject :  shape.businessObject });

			});
			self.obj.shapes = shapes;
			var reloadTable = false;
			_.each(shapes, function (shape) {
				if (shape && shape.mid) {
					var parentShape = shape.businessObject.$parent;
					var shapeType = shape.businessObject.$type.replace(':', '.');
					var shapeCap = Backbone.Relational.store.getObjectByName(shapeType).find({id: shape.mid});
					_.each(shape.flowElements, function (childShape) {
						if (childShape.mid) {
							var exists = _.filter(shapeCap.get('childCapability').models, function (child) {
								return child.id === childShape.mid;
							});
							if (exists.length === 0) {
								childShape.set('mid', null);
								childShape.set('mpid', null);
								if (self.getNumberOfMappings(shape.mid, null, null) === 0) {
									self.model.get('capabilities').remove(shape.mid);
								}
								reloadTable = true;
							}
						}
					});
					/*if (parentShape) {
						var parentCapability = shapeCap.get('parentCapability').length > 0 ? _.filter(shapeCap.get('parentCapability').models, function (parent) {
							return parent.id === parentShape.mid;
						}): null;
						if (parentCapability && parentCapability.length === 0) {
							shape.businessObject.set('mid', null);
							if (self.getNumberOfMappings(shape.mid, null, null) === 0) {
								self.model.get('capabilities').remove(shape.mid);
							}
							shape.businessObject.set('mpid', null);
						}
					}*/
				} else {
					
				}
			});
			_.each(shapes, function (shape) {
				if (shape && shape.businessObject && !shape.businessObject.mid) {
					self.fixUnmappedShapes(shape);
				}
			});
			if(reloadTable){
				self.loadTable();
			}
			callback();
		});
	}
	zoomReset() {
		var self = this;
        self.modeler.get("canvas").zoom("fit-viewport", true);
    }
    zoomIn() {
		var self = this;
        self.modeler.get('zoomScroll').stepZoom(1);
    }
    zoomOut() {
		var self = this;
        self.modeler.get('zoomScroll').stepZoom(-1);
    }
	openDiagram(xml,redraw) {
		var self = this;
		async function myDisplay() {
			let result = new Promise(function(myResolve, myReject) {
				myResolve("Works");
			});
			try {
				result = await self.modeler.importXML(xml);
				var { warnings } = result;
				console.log(warnings);
				//self.modeler.importXML(xml, function (err) {
				// if (err) {
				//      console.error(err);
				//  }
				self.modeler.get("canvas").zoom("fit-viewport", true);
				//$('svg').css('margin-left', 70);
				if (window.vdmModelView === self) {
					var height = self.model.get('height');
					if (!height) {
						height = self.getViewportSize().h * 0.80 + $('.navbar').height();
					}
					height = height > 700 ? height : 700;//to avoid 2 column djs-palette
					$("#js-canvas" + self.encodeId).height(height);
				}
				self.initializePresentation();
				if(redraw){
					self.fixCapabilityRelations(function(){
						window.utils.stopSpinner('capabilitySpinner');
					});
				}
			} catch (err) {
				console.log(err.message, err.warnings);
			}
		} 
		//var id  = "VDMLDiagram_1";//htmlEscape(self.model.get('id'));
		myDisplay();
	};
	showProperties(data, ev) {
		var self = this;
		if(self.editMode){
			var id = window.utils.htmlEscape(window.guidGenerator());
			var elementRegistry = self.modeler.get('elementRegistry');
			var options = { businessObject: data.businessObject, element: elementRegistry.get(data.businessObject.id), width: '660px' };
			window.getAndCreateModalDialog(self, id, EcoMapDiagramMixin, self.model, "ShapeProperties", null, options);
		}
	}
	loadTable(event) {
		var self = this;
		self.shapesList.removeAll();
		self.unsortedShapesList = [];
		var dummyChildShapes = [];
		var childShapes = [];
		var dummyArray = [];
		var dummyShapes = [];
		var shapes, definitions, capabilityLibrary, modeler = self.modeler;
		if (modeler) {
			definitions = modeler._definitions;
		}
		if (definitions) {
			capabilityLibrary = definitions.rootElements;
		}
		if (capabilityLibrary && capabilityLibrary.length) {
			shapes = capabilityLibrary[0].flowElements;
			fillChildShapes(shapes);
		}
		function fillChildShapes(shapes) {
			dummyChildShapes.length = 0;
			_.each(shapes, function (shape) {
				if (shape.flowElements && shape.flowElements.length > 0) {
					for (var i = 0; i < shape.flowElements.length; i++) {
						dummyChildShapes.push(shape.flowElements[i]);
					}
				}
			});
			if (dummyChildShapes && dummyChildShapes.length > 0) {
				dummyArray.length = 0;
				_.each(dummyChildShapes, function (shape) {
					childShapes.push(shape);
					dummyArray.push(shape);
				});
				fillChildShapes(dummyArray);
			}
		}
		if (childShapes || shapes) {
			_.each(childShapes, function (childShape) {
				dummyShapes.push(childShape);
			});
			_.each(shapes, function (childShape) {
				dummyShapes.push(childShape);
			});
		}
		if (dummyShapes) {
			for (var i = 0; i < dummyShapes.length; i++) {
				if (event && event.type === 'shape.remove' && event.element.id === dummyShapes[i].id) {
					continue;
				}
				var type = dummyShapes[i].$type;
				if (type === "vdml:BusinessItem") {
					continue;
				}
				var typeToDisplay = self.mappingObject[type].name;
				var name = dummyShapes[i].name;
				var mappingEleId = dummyShapes[i].mid;
				var packId = dummyShapes[i].mpid;
				var mappingEleName = '';
				if (mappingEleId) {
					var capType = type.replace(':','.');
					var parentCap =  Backbone.Relational.store.getObjectByName(capType).find({'id':mappingEleId});
					mappingEleName = parentCap ? parentCap.get('name') : '';
				}
				self.shapesList.push({ businessObject: dummyShapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
				self.unsortedShapesList.push({ businessObject: dummyShapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
			}
		}
		//self.setResizeHeight();
		self.shapesList.sort(self.sortShapesInTableByName);
	}
	
	cleanUp() {
		var self = this;
		if (self.modeler) {
			self.modeler.destroy();
		}
		if (ko.components.isRegistered('CapabilityLibraryDiagramDetails')) {
			ko.components.unregister('CapabilityLibraryDiagramDetails');
		}
		if (ko.components.isRegistered('CapabilityLibraryDiagramGuidance')) {
			ko.components.unregister('CapabilityLibraryDiagramGuidance');
		}
		//window.utils.loadTemplate(null,true);
		if (self.spliter) {
			self.spliter.enhsplitter('remove');
		}
		/*if (self.loadedCodedContainers) {
			var codeContainersList = self.loadedCodedContainers;
			_.each(codeContainersList, function (codeContainer) {
				codeContainer.save(null, {
					success: function () {
						DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
					},
					error: function (error) {
						console.log(error);
						//DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
					}
				});
			});
			codeContainersList.length = 0;
		}*/
	};
	saveShapeDetailsData(view) {
		var self = this;
		var self = this;
		var found = false;
		for(var i=0; i < self.children.length; i++){
			if(self.children[i].modal == view.mappedElementModel){
				self.children[i].name = view.name();
				self.children[i].description = view.description();
				found = true;
				break;
			}
		}
		if(!found){
			self.children.push({'name':view.name(),'description':view.description(),'modal':view.mappedElementModel});
		}
	}
	saveSyncData(revision) {
		var self = this;
		for(var i=0; i < self.children.length; i++){
			var vdmlModal = self.children[i].modal;
			if(revision){
				var modelSuffix = vdmlModal.id.substr(vdmlModal.id.lastIndexOf('@') + 1);
				var type = vdmlModal.get('type').replace('_', '.')
				vdmlModal = Backbone.Relational.store.getObjectByName(""+type).find({ id: DataManager.getDataManager().get('viewAlternative') + modelSuffix });
			}
			if(vdmlModal) {
				vdmlModal.set('name',self.children[i].name);
				vdmlModal.set('description',self.children[i].description);
			}
		}
		self.children = [];
		var EcoMapMixin = Backbone.Relational.store.getObjectByName("ecomap.EcoMapMixin");
		EcoMapMixin.removeImageArtifact(self.deletedCodedContainers,self.model.getNestedParent());
		self.deletedCodedContainers = [];
	}
	saveDiagram(view, event, callback, revisionCapabilityLibDiagram, vdmlPackageRevision) {
		var self = this;
		if (self.model) {
			window.utils.startSpinner('saveSpinner', "Saving Capability-Map...");
			setTimeout(function () {
				async function saveVdmioXML() {
					let result = new Promise(function(myResolve, myReject) {
						myResolve("Works");
					});
					try {
						result = await self.modeler.saveXML({ format: true });
						var { xml } = result;
						if (xml) {
							async.each(self.shapesList(), function (shape, shapeHandled) {
								var prevShapes = self.obj.shapes;
								if (shape.businessObject && shape.businessObject.mid) {
									var childShapes = shape.businessObject.flowElements;
									var shapetype = shape.businessObject.$type.replace(':', '.');
									var shapeCap = Backbone.Relational.store.getObjectByName(shapetype).find({ id: shape.businessObject.mid });
									var previousShape = _.filter(prevShapes, function (newShape) { return newShape.id === shape.businessObject.id; })
									if (previousShape.length === 0){
										shapeHandled();
									}else{
										_.each(childShapes, function (childShape) {
											if (childShape && childShape.mid) {
												var exists = _.filter(previousShape[0].flowElements, function (child) { return child.id === childShape.id; });
												if (exists.length === 0) {
													var childShapeType = childShape.$type.replace(':', '.');
													var childShapeCap = Backbone.Relational.store.getObjectByName(childShapeType).find({ id: childShape.mid });
													/* if (childShapeCap.get('capabilityOwner').id !== shapeCap.get('capabilityOwner').id ){
														childShapeCap.set('capabilityOwner', shapeCap.get('capabilityOwner'));
													} */
													shapeCap.get('childCapability').add(childShapeCap);
													childShapeCap.get('parentCapability').add(shapeCap);
												}
											}
										});
										_.each(previousShape[0].flowElements, function (childShape) {
											if (childShape && childShape.mid && !self.deleteShapes.includes(childShape.id)) {
												var exists = _.filter(childShapes, function (child) { return child.id === childShape.id;});
												if (exists.length === 0) {
													var childShapeType = childShape.$type.replace(':', '.');
													var childShapeCap = Backbone.Relational.store.getObjectByName(childShapeType).find({ id: childShape.mid });
													shapeCap.get('childCapability').remove(childShapeCap);
													childShapeCap.get('parentCapability').remove(shapeCap);
												}
											}
										});
										shapeHandled();
									}
								}else{
									shapeHandled();
								}
							}, function () {
								var shapes = [];
								_.each(self.shapesList(), function (shape) {
									if (shape.businessObject.flowElements && shape.businessObject.flowElements.length > 0) {
										var flowElements = [];
										$.extend(true, flowElements, shape.businessObject.flowElements);
									}
									shapes.push({ name: shape.name, type: shape.type, mappedEle: shape.mappedEle, id: shape.businessObject.id, mid: shape.businessObject.mid, flowElements: flowElements });

								});
								self.obj.shapes = shapes;
								if (xml) {
									self.model.set('data', xml);
									self.saveSyncData();
									self.enableComplete(false);
									window.utils.stopSpinner('saveSpinner');
									if (callback) {
										callback();
									}
								} else {
									self.enableComplete(false);
									window.utils.stopSpinner('saveSpinner');
									if (callback) {
										callback();
									}
								}
							});
						}else {
							self.enableComplete(false);
							window.utils.stopSpinner('saveSpinner');
							if (callback) {
								callback();
							}
						}
				} catch (err) {
					console.log(err.message, err.warnings);
				}
			}
			saveVdmioXML();
					
			}, 100);
		} else {
			callback();
		}
	};

	hasUnsavedData() {
		var self = this;
		if (this.enableComplete() /*&& !self.viewMode()*/) {
			return true;
		} else {
			return false;
		}
	};

	saveDataBeforeClosing(callback) {
		var self = this;
		self.saveDiagram(null, null, callback);
	};

	shapeDeleteModel(businessObject, element) {
		var self = this;
		var dm = DataManager.getDataManager();
				var dialog ;
				var modeling = self.modeler.get('modeling');
				function capabiliytModel(capabilityModelId){ 
					var cap = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({id: capabilityModelId});
					if(!cap){
						cap = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({id: capabilityModelId});
					}
					return cap;
				}
				var options;
				if(businessObject.mid && businessObject.$parent.mid){
				options = [{
					text: dm.get('localeManager').get('removeCapability') + " (" + businessObject.$parent.name + ")",
					value: '2',
					}, {
					text: dm.get('localeManager').get('deleteCapability'),
					value: '3',
				}]
				}else if (businessObject.mid && !businessObject.$parent.mid){
					options = [
					{
					text: dm.get('localeManager').get('deleteCapability'),
					value: '3',
					}]
				}else {
					options = [{
					text: "<span style='margin-left:-20px'>"+dm.get('localeManager').get('DeleteShapeMsg')+"</span>",
					value: '1',
					}]
				}
				
				dialog =  bootbox.prompt({
				title: "Delete Shape",
				inputType: 'checkbox',
				inputOptions: options,
				callback: function (result) {
					var cParent = businessObject.$parent.mid;
					if(result != null){
						if(result.length >= 1){
							for(var i=0;i<result.length;i++){
								if(result[i] == 2){
									var cap = capabiliytModel(businessObject.mid);
									var parent = businessObject.$parent ? businessObject.$parent.mid: cParent;
									var capParent = capabiliytModel(parent);
									if(cap && cap.get('parentCapability')){
										var capParentModels = cap.get('parentCapability').models;
										var capParentModel = _.find(capParentModels, function(model){ return model.id == cParent; });
										if(capParentModel){
												cap.get('parentCapability').remove(capParent);
										}
									}
									if(capParent){
										var capChildrenModels = capParent.get('childCapability').models;
										var capChildrenModel = _.find(capChildrenModels, function(model){ return model.id == businessObject.mid; });
										if(capChildrenModel){
											capParent.get('childCapability').remove(cap);
										}
									}
								}
								if(result[i] == 3){
									var cap = capabiliytModel(businessObject.mid);
									if(cap){									
										cap.destroy();
										/*var childCaps = cap.get("childCapability");
										for(var i=0; i<childCaps.models.length; i++){
											childCaps.at(i).destroy();
										}*/
									}
								}
							}
						}
						modeling.removeElements([ element ]);
						self.fixCapabilityRelations(function(){
							
						});
					}
				}
				});
				dialog.attr("id", "bootbox_id");
				$('#bootbox_id').draggable();
				$('#bootbox_id').find('.checkbox').find('input[value="1"]').prop('checked',true);
                $('#bootbox_id').find('.checkbox').find('input[value="1"]').css("display", "none");
				//$('#bootbox_id').find('.checkbox').find('input[value="1"]').prop('disabled','disabled');
	}
	fixUnmappedShapes(element){
		var childShapes = element.businessObject?element.businessObject.flowElements:null;
		if(childShapes && !element.businessObject.mid){
			for(var i=0;i<childShapes.length;i++){
				var childShape = childShapes[i];
				if(childShape.mid){
					childShape.set('mid', null);
					childShape.set('mpid', null);
				}
			}
		}
	}
	setResizeHeight(view, event) {
		var self = this;
		if (!self.showDashboardEcoMap) {
			setTimeout(function () {
				var propertiesNode = 0;
				if ($('.propertiesArea').width() > 10) {
					propertiesNode = $('.propertiesArea').outerHeight();
				}
				//var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+20;
				//var topAreaHeight = $('.navbar').outerHeight();
				var lhsHeight = $("#js-canvas" + self.encodeId).outerHeight() + 30;
				if($('#mainTab' +self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('shapeDetails')){
					lhsHeight = $('.table-responsive').outerHeight() + $('.table-responsive').outerHeight()/3 ;
				}
				var windowHeight = window.innerHeight;
				var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight]);
				if (self.spliter) {
					self.spliter.css('height', baseHeight + 'px');
					if ($('.propertiesArea').width() < 5 && Math.abs($(window).width() - $('.modeler').width()) < 30) {
						var windowWidth = $(window).width() - 15;
						$('.splitter_bar').css('left', windowWidth);
					}
					$("#splitPanels" + self.encodeId).enhsplitter('refresh');
					$('#splitter_panel').css('width','0px');
				}
				var rightTab = $('#rightTab' + self.encodeId);
				if (rightTab.width() > 0) {
					if ($('#rightTab' + self.encodeId + ' .active a')[0]) {
						var propDivId = $('#rightTab' + self.encodeId + ' .active a')[0].hash;
						if (propDivId !== null && $('#rightTab .dropdown .active a')[0]) {
							propDivId = $('#rightTab .dropdown .active a')[0].hash;
						}
						var propNode = $('' + propDivId);
					}
				}
				if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/) {
					var dropdownDiv = rightTab.children().last();
					var appendList = dropdownDiv.find('li');
					var removedList = [];
					for (var i = 0; i < appendList.length; i++) {
						dropdownDiv.before(appendList[i]);
						removedList.push(appendList[i]);
					}
					if (appendList.length === removedList.length) {
						dropdownDiv.remove();
					}
				}
				if (rightTab.width() > 10 && rightTab.width() < 415 && rightTab.children().length > 2/* && rightTab.height() > 50*/) {
					var rightChildren = rightTab.children();
					var appendList = [];
					for (var i = rightChildren.length; i > 0; i--) {
						if (!rightChildren[i - 1].classList.contains('dropdown')) {
							appendList.push(rightChildren[i - 1]);
							if (rightTab.width() < 375 && rightChildren[i - 2]) {
								appendList.push(rightChildren[i - 2]);
								break;
							} else {
								break;
							}
						}
					}
					if (!rightChildren.last()[0].classList.contains('dropdown')) {
						rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
					}
					for (var i = 0; i < appendList.length; i++) {
						rightTab.children().last().find('ul').prepend(appendList[i]);
					}
				}
				if (rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')) {
					var dropdownDiv = rightTab.children().last();
					var appendList = dropdownDiv.find('li');
					for (var i = 0; i < appendList.length; i++) {
						appendList.eq(i).removeClass('active');
					}
				}
				if($('#mainTab' +self.encodeId).find('.active a span').text() === 'Capability Map'){
					self.modeler.get("canvas").zoom("fit-viewport", true);
				}
			}, 300);
		}
	};
	getNumberOfMappings(mid, type, event) {
		var self = this;
		var shapes = self.modeler._definitions.rootElements[0].flowElements;
		var count = 0;

		for (var i = 0; i < shapes.length; i++) {
			if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
				continue;
			}
			if (type !== 'vdml:BusinessItem') {
				if (shapes[i].get('mid') === mid) {
					count++;
				}
			} else {
				var flows = shapes.get('flows');
				for (var j = 0; j < flows.length; j++) {
					if (flows[j].get('mid') === mid) {
						count++;
					}
				}
			}
		}
		return count;
	}
	afterRenderView(redraw,view) {
		var self = view;
		var container = $("#js-canvas" + self.encodeId);
		container.empty();
		var fontSize = '12';
		if(self.model.get('fontsize')){
			fontSize = self.model.get('fontsize');
		}
		if (!self.editMode) {
			self.modeler = new EcoNavigatedViewer({
				container: container,
				type: 'vdml_CapabilityLibraryDiagram',
				additionalModules: [
                    {
                      moveCanvas: [ 'value', '' ],
                      zoomScroll: [ 'value', '' ]
                    }
                ],
				textRenderer: {
					defaultStyle: {
						fontSize: fontSize,
					},
					externalStyle: {
						fontSize: fontSize,
					}
				}
			});
			$('#canvas-modal-footer' + self.encodeId + ' .btn-complete').hide();
		} else {
			self.modeler = new EcoModeler({
				keyboard: { bindTo: document },
				container: container,
				type: self.model.get('type'),
				textRenderer: {
					defaultStyle: {
						fontSize: fontSize,
					},
					externalStyle: {
						fontSize: fontSize,
					}
				}
			});
			$('#canvas-modal-footer' + self.encodeId + ' .btn-complete').show();

			self.modeler.get('eventBus')
				.on(['commandStack.shape.create.postExecute', 'commandStack.shape.delete.postExecute', 'element.updateLabel'],
					function (event) {
						if(event.type !== 'shape.changed' && event.type !== "connection.changed"){
							self.loadTable(event);
						}
						if (event.element && event.type == "shape.remove") {
							var bo = event.element.businessObject;
							var backImgUrl = bo.backgroundUrl;
							if(backImgUrl){
								self.deletedCodedContainers.push(backImgUrl);
							}
							var mid = bo.get('mid');
							if (mid) {
								self.deleteShapes.push(bo.get('id'));
								var type = bo.$type;
								if (self.getNumberOfMappings(mid, type, event) === 0) {
									var modelType = self.mappingObject[type];
									var modelColl = self.model.get(modelType.collectionName);
									if (modelColl) {
										modelColl.remove(mid);
									}
								}
							}
						}
						self.enableComplete(true);
					});
			self.modeler.get('eventBus')
				.on(['shape.changed'],
					function (event) {
						self.enableComplete(true);
					});
		}
		self.openDiagram(self.model.get('data'),redraw);
		if (!self.showDashboardEcoMap) {
			self.spliter = $("#splitPanels" + self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '64%', handle: 'block', splitterSize: '9px', height: $("#js-canvas" + self.encodeId).outerHeight() + $('.table-responsive').outerHeight(), onDragEnd: self.setResizeHeight });
			self.spliter.enhsplitter('collapse');
			$(window).resize(function (view, event) {
				this.timer = clearTimeout();
				this.timer = setTimeout(function () { self && self.setResizeHeight && self.setResizeHeight(); }, 200);
			});
			$('#mainTab' + self.encodeId + ' a').click(function (e) {
				e.preventDefault();
				$(this).tab('show');
				/*self.zoomButton(false);
                if ($(this)[0].className === "showMap") {
                    self.zoomButton(true);
                }*/
				if ($(this)[0].className === "ShapeDetails") {
					self.loadTable();
				}
				self.setResizeHeight();
			});
			$('#rightTab' + self.encodeId + ' a').click(function (e) {
				e.preventDefault();
				if ($(this).text() === "Collaboration") {
					return false;
				}
				$(this).tab('show');
				if ($(this).text() === "Guidance") {
					if ($('#guidanceTab' + self.encodeId).is(':empty')) {
						$.get('js/app/version1/views/help/views/properties/CapabilityGuidanceTemplate.html',function(viewStr){
							self.countGuidanceClicks++;
							$('#guidanceTab' + self.encodeId).empty();
							$('#guidanceTab' + self.encodeId).append(viewStr);
							if (self.configObj && self.configObj.guidance) {
								$('#guidanceTab' + self.encodeId).append(self.configObj.guidance);
							}
							window.utils.applyFontSize($('#guidanceTab' + self.encodeId));
							self.setResizeHeight();
						})
					} else {
						self.setResizeHeight();
					}
				} else {
					self.setResizeHeight();
				}
			});
			container.resizable({
				stop: _.bind(function (event, ui) {
					if(ui.size.height >= 370){
						var self = this;
						self.model.set('height', ui.size.height);
					}
				}, self)
			});	
			self.setResizeHeight();
		} else {
			$('#splitPanels' + self.encodeId).contents().unwrap();
			$('.dashboardEcoMap').hide();
			$('.bjs-powered-by').hide();
			$('#mainTab' + self.encodeId).hide();
			if(self.editMode){
				self.completeButton(true);
				$('#complete' + self.encodeId).css('margin-top','-20px')
			}else{
				self.completeButton(false);
				//self.zoomButton(false);
			}
			window.utils.stopSpinner('capabilitySpinner');
			if (redraw !== true && self.options.parentView && self.options.parentView) {// when ecomap is added
				window.vdmModelView.setResizeHeight();
			}
		}

	};
	handlePublicMenus() {
		var self = this;
		DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench", "Show Object Explorer", $.proxy(this.showobjectExplorerTree, this));
	};
	showobjectExplorerTree() {
        var self = this;
		var vdm = self.model.getNestedParent();
		var addOptions = this;
		var id = window.utils.htmlEscape(window.guidGenerator());
		window.getAndCreateModalDialog(true, id, EcoMapDiagramMixin, vdm, "explorerModelJson", closeView, addOptions);
		function closeView() {
			window.cleanDialogModel(id, EcoMapDiagramMixin);
		}
	};
	initializePresentation() {
		var self = this;
		self.modeler.get("canvas").resized();
		self.modeler.get("canvas").zoom("fit-viewport", true);
		//self.afterRenderView(true,self);
	};

	resizeEcoElement(shapeId, newBounds) {
		var self = this;
		var shape = self.modeler.get('elementRegistry')._elements[shapeId].element;
		self.modeler.get('modeling').resizeShape(shape, newBounds);
	};

	mapChildCapability(cap, parentElement) {
		var self = this;
		this.eventBus = self.modeler.get('eventBus');
		this.elementFactory = self.modeler.get('elementFactory');
		this.modeling = self.modeler.get('modeling');
		this.elementRegistry = self.modeler.get('elementRegistry');

		var childCaps = cap.get('childCapability');
		var constant;
		var parentWidth = parentElement.width;
		var parentHeight = parentElement.height;
		if (parentWidth <= 200 || parentHeight <= 200) {
			if (parentWidth < parentHeight) {
				constant = parentWidth / 3;
			} else {
				constant = parentHeight / 3;
			}
		} else {
			constant = 80;
		}
		var x = parentElement.x;
		var y = parentElement.y;
		var size = Math.sqrt((parentHeight * parentWidth) / childCaps.length);
		var r = 0;
		var c = 0;
		var result = self.calculateSize(parentHeight, parentWidth, childCaps.length, size, constant);
		var childSize = parseFloat(result.split(',')[0]);
		if (childSize < 50) {
			return;
		}
		var R = result.split(',')[1];
		function setDiagramId(diagBO, cap, vdm) {
			if (diagBO && cap) {
				diagBO.set('vdml:mid', cap.id);
				diagBO.set('vdml:mpid', vdm.id);
				self.model.get('capabilities').add(cap);
				if (!diagBO.get('name') || diagBO.get('name') === '') {
					diagBO.set('name', cap.get('name'));
				}
				if (!diagBO.get('description') || diagBO.get('description') === '') {
					diagBO.set('description', cap.get('description'));
				}
			}
		}
		childCaps.each(function (childCap) {
			var type = childCap.get('type').replace('_', ':');
			var shape = self.elementFactory.createShape({ type: type });
			setDiagramId(shape.businessObject, childCap, childCap.getNestedParent());
			var childSizeHeight = childSize / 3;
			var childSizeWidth = childSize * 3;
			var childx = x + r * (childSizeWidth + constant / 4) + constant / 2;
			var childy = y + c * (childSizeHeight + constant / 4) + constant / 2;
			if (r + 1 < R) {
				r++;
			} else {
				c++;
				r = 0;
			}
			shape = self.modeling.createShape(shape, { x: childx, y: childy, width: childSizeWidth, height: childSizeHeight }, parentElement);
			shape.dontDraw = false;
			self.mapChildCapability(childCap, shape);
		});
	}
	
    static getInstance(model, options) {
        var view = new CapabilityLibraryDiagramViewModel(model, options);
        view.init(model, options);
        return view;
    }
}
path.CapabilityLibraryDiagramViewModel = CapabilityLibraryDiagramViewModel;
		
