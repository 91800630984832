import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox  from '../../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { ProcessTree } from './ProcessTree'
//import {PackageReference as PlanPackageReference} from '../../../../bo/transformation/PackageReference'
import {PackageReference} from '../../../../../com/vbee/filesystem/PackageReference'
import { ValueStreamMapDiagram } from '../../../../bo/vdml/ValueStreamMapDiagram'
import { EcoMap } from '../../../../bo/ecomap/EcoMap'

//define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference", "appbo/vdml/ValueStreamMapDiagram", "appbo/ecomap/EcoMap", "app/global", "appviews/valueStreamMap/views/designer/ProcessTree"],
//	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, BeepPackageMixin, PlanPackageReference, PackageReference, ValueStreamMapDiagram, EcoMap, global, ProcessTree)
//{
	var path = DataManager.getDataManager().buildAppNsPath("valueStreamMap.views.designer",global.version);
export class ValueStreamMapDetailsViewModel {
	constructor(model, options){
		this.init(model,options);
	}
	htmlEscape(str) {
		return String(str)
		.replace(/@/g, '')
		.replace(/ /g, '');
	}
	dispose() {
		var self = this;
		window.cleanViewModel(self);
	};
	
	
	
	saveValueStreamMapDetails(){
		var self = this;
		if(self.name() !== ""){
			if(self.name().trim() != self.model.get('name')){
				self.model.set('name',self.name().trim());
				window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.ValueStreamMapDetailsViewModel);
			}
			self.model.set('description',self.description().trim());
			var defaultFontSize = self.model.get('fontsize') ? self.model.get('fontsize') : 12;
			if(self.fontsize() && defaultFontSize != self.fontsize()){
				self.model.set('fontsize',self.fontsize());
				window.utils.startSpinner('fontsizespinner', "Applying Font Size ...");
				self.model.getNestedParent().refreshLabels(window.vdmModelView.modeler,self.fontsize(),function(){
					window.utils.stopSpinner('fontsizespinner');
				});
			}	
		}		

	};

	
	navigateToProcess() {
		var self = this;
		var valueStreamMap = window.vdmModelView.model;
		var diagramInstance = valueStreamMap.get('process');
		if (diagramInstance) {
			var pack = diagramInstance.getNestedParent();
			var altId = DataManager.getDataManager().getRepositoryId(diagramInstance.id);
			DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + pack.get('id') + "/vdml_ValueDeliveryModel/" + pack.get('version') + "/" + diagramInstance.get('id') + "/vdml_ProcessDiscoveryDiagram/" + altId + "", { trigger: true });
		}
	}
	generateProcess() {
		var self = this;
		var checkCompentence;
		var modeler = window.vdmModelView.modeler;
		var shapeList = window.vdmModelView.shapesList();
		if(shapeList.length == 0){
			bootbox.alert(DataManager.getDataManager().get('localeManager').get('NoShapesMessage'));
		}
        if(shapeList.length == 1){
			bootbox.alert(DataManager.getDataManager().get('localeManager').get('InsufficientShapesMessage'));
		}
		if(shapeList.length > 1){
			var actExist = false;var compExist = false;
			for(var i=0;i<shapeList.length;i++){
					if(shapeList[i].type === "Activity"){
						actExist = true;
					}
					if(shapeList[i].type === "Competency" && shapeList[i].businessObject != null && shapeList[i].businessObject.mid){
                        var mappedCap = Backbone.Relational.store.getObjectByName('vdml.Competence').find({'id':shapeList[i].businessObject.mid});
                        var capElementId = null;
                        if(mappedCap){
						   capElementId = mappedCap.get('capabilityRequirement') ? mappedCap.get('capabilityRequirement').id: mappedCap.get('capability') ? mappedCap.get('capability').id : '';
						}
						compExist = (capElementId) ? true : false;
					}
					if(actExist && compExist){
						break;
					}
			}
			if(!actExist){
				bootbox.alert(DataManager.getDataManager().get('localeManager').get('NoActivityMessage'));
			}
			else if(actExist && !compExist){
				var processTree = ProcessTree.getInstance(modeler);
				processTree.initialize(false, function(){
					window.autoProcess = false;
					window.utils.stopSpinner('generateProcessDiagramSpinner');
				});
			}
			if(compExist){
				bootbox.confirm(DataManager.getDataManager().get('localeManager').get('SubProcessGenMessage'), function (result) {
					setTimeout(function () {// need timeout to fix scroll issue
						var processTree = ProcessTree.getInstance(modeler);
						processTree.initialize(result,function(){
							window.autoProcess = false;
							window.utils.stopSpinner('generateProcessDiagramSpinner');
						});
					},300);
				});
			}
		}
		/*var self = this;var checkCompentence;var resourceCompetency = 0;var capCompetency = 0;
		var modeler = window.vdmModelView.modeler;
		if(self.model.get('valueStream').length === 0){
			bootbox.alert("No Shapes in Value Stream Map for Process Generation");
		}
		else if(self.model.get('activity').length === 0){
			bootbox.alert("Value Stream is empty; Process is not generated");
		}
		
		if(self.model.get('competence').length > 0){
				var model = self.model.get('competence').models;
				for(var i =0;i<model.length;i++){
					var checkCompentenceType = Backbone.Relational.store.getObjectByName("vdml.Competence").find({id:model[i].id})
					if(checkCompentenceType && checkCompentenceType.get('type') === "vdml_BusinessItem"){
						resourceCompetency++;
					}else{
						capCompetency++;
					}
				}
			
				if(resourceCompetency > 0 && capCompetency == 0){
						checkCompentence = "Resource";
						var processTree = ProcessTree.getInstance(modeler);
						processTree.initialize(checkCompentence);
				}else if(resourceCompetency  == 0 && capCompetency > 0){
						checkCompentence = "Capability"
						var processTree = ProcessTree.getInstance(modeler);
						processTree.initialize(checkCompentence);
				}else{
					checkCompentence = "Competency";
					var processTree = ProcessTree.getInstance(modeler);
					processTree.initialize(checkCompentence);
				}
		}
		if(!checkCompentence && self.model.get('valueStream').length > 0 && self.model.get('activity').length > 0){
			var processTree = ProcessTree.getInstance(modeler);
			processTree.initializeProcessTree(false, false);
		}*/
	}

	copyValueStreamMap(){
		var self = this;
		if (!DataManager.getDataManager().isDiscoverPurchased()) {
			window.utils.limitExceeded();
			return false;
		}
		function savePackage(view, modalId, type, moduleTypes) {
			window.utils.startSpinner('copyValueStreamMapSpinner', "Copying Value Stream Map...");
			setTimeout(function () {
				self.onAddValueStreamMap(view.name(), view.description(), view.selectedEcoMapPackage(), null, null, self.currentAlt, type, moduleTypes);
				window.cleanDialogModel(modalId, view);
				window.utils.stopSpinner('copyValueStreamMapSpinner');
			},100)
		}
		var plan = DataManager.getDataManager().get('currentPlan');
		var mapTitle = DataManager.getDataManager().get('localeManager').get("ValueStreamMap");
		var addOptions = { 'alternative': self.currentAlt, 'plusIconType':'valueStreamArrow',onlyMap:mapTitle};
		//window.getAndCreateModalDialog(self.model, htmlEscape(self.id()), BusinessModelMixin, plan, "CreatePackage", savePackage, addOptions);
		var BeepPackageMixin = Backbone.Relational.store.getObjectByName("beeppackage.BeepPackageMixin");
		window.getAndCreateModalDialog(self.model, window.utils.htmlEscape(self.id()), BeepPackageMixin, plan, "Discover", savePackage, addOptions);
	};
	onAddValueStreamMap(name, description, pack, phase, alternative, currentAlternative, type, moduleTypes) {
		var self = this;
		if (pack !== null && pack.id) {
			var altId = DataManager.getDataManager().getRepositoryId(pack.id);
			DataManager.getDataManager().fetchDocumentFromPackage(pack.id, "appbo/ecomap/EcoMap", pack.version, pack.id, "appbo/ecomap/EcoMap", DataManager.getDataManager().getVDMStore(altId), {
				success: function (model) {
					if (altId !== currentAlternative.get('id')) {
						var ecoPackage = self.model.getNestedParent();
						var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
						self.createRevisionBasedOnContext(model,depPacks,function (newPackage) {
							var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
							var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
							var ValueStreamMap = newPackage.get('diagrams').findWhere({ 'id': altId + bmId });
							var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
							var diagrams = newPackage.get('diagrams');
							for (var i = 0; i < diagrams.length; i++) {
								newPackage.fixDiagramOnRevision(diagrams.at(i), altId,oldAltId);
							}
							//window.utils.startSpinner('revisionSpinner', "Creating a copy...");
							//setTimeout(function(){
	
							//model.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), function (newPackage) {
							window.utils.stopSpinner('revisionSpinner');
							self.createValueStreamMap(name, description, newPackage, type, moduleTypes);
							//});
							//},10);
						});
					} else {
						self.createValueStreamMap(name, description, model, type, moduleTypes);
					}
				},
				error: function (error) {
					console.log('Unable to load selected Package');
				}
			});
		} else {
			self.createValueStreamMap(name, description, null, currentAlternative, type, moduleTypes);
		}
	};
	
	createValueStreamMap(name, description, ValueStreamMapPackage, currentAlternative, type) {
		var self = this;
		if (!ValueStreamMapPackage) {
			if (name !== null) {
				var preWorkspace = DataManager.getDataManager().get('currentWorkspace');
				if(window.checkContextForRevision()){
					var workspaceAlt = DataManager.getDataManager().getWorkspaceDataWithIdSync(currentAlternative.get('id')).get('workspace');
					DataManager.getDataManager().set('currentWorkspace', workspaceAlt);
					DataManager.getDataManager().set('currentWorkSpaceKey', workspaceAlt.get('id'));
				}
				ValueStreamMapPackage = EcoMap.createPackageWithName(name + " Package", description, currentAlternative);
				DataManager.getDataManager().get('currentWorkspace').save();
				self.createValueStreamMapDiagram(ValueStreamMapPackage, name, description, null);
				bootbox.alert("Copy of Value Stream Map Completed");
				DataManager.getDataManager().set('currentWorkspace', preWorkspace);
				DataManager.getDataManager().set('currentWorkSpaceKey', preWorkspace.get('id'));
			}
		} else {
			/*if (saveWorkspace) {
				DataManager.getDataManager().get('currentWorkspace').save();
			}*/
			self.createValueStreamMapDiagram(ValueStreamMapPackage, name, description, null);
			bootbox.alert("Copy of Value Stream Map Completed");
		}
	};

	createValueStreamMapDiagram(pack, name, description, valternative) {
		var self = this;
		var altId = DataManager.getDataManager().get('viewAlternative');
		if (altId) {
			DataManager.getDataManager().getAlternative(altId, function (alternative) {
				var diagramInstance = ValueStreamMapDiagram.getInstance(name, description, pack);
				self.copyDataToEcomap(diagramInstance);
			});
		}
	}
	copyDataToEcomap(diagramInstance){
		var self = this;
		var xml = self.model.get('data');
		var oldId = "v"+ window.utils.htmlEscape(self.model.id);
		var newId = "v"+ window.utils.htmlEscape(diagramInstance.id);
		xml = self.model.getNestedParent().fixXMLOnRevision(xml,oldId,newId);
		diagramInstance.set('data',xml);
		if (self.model.get('activity').length>0){
			_.each(self.model.get('activity').models,function(bm){
				diagramInstance.get('activity').push(bm);
			});	
		}
		if (self.model.get('competence').length>0){
			_.each(self.model.get('competence').models,function(part){
				diagramInstance.get('competence').push(part);
			});	
		}
		if(self.model.get('valueStream').length>0){
			_.each(self.model.get('valueStream').models,function(vp){
				diagramInstance.get('valueStream').push(vp);
			});	
		}
	}
	/*this.importValueStreamMapDiagrams = function(){
		var self = this;
		function loadValueStreamMapPackage(ValueStreamMapPackage) {
			var referenceExists = self.alternativeModel.get('phaseDesignPart').findWhere({ beepReference: ValueStreamMapPackage.get('id') });
			if (!referenceExists || ValueStreamMapPackage.get('id').indexOf("Common") > -1) {
				return;
			} else {
				var pack = Backbone.Relational.store.getObjectByName('ecomap.ValueStreamMap').find({id:ValueStreamMapPackage.get('id')});
				if(pack){
					var diag = pack.get('diagrams');
					for(var j=0;j<diag.length;j++){
						if(diag.at(j) !== self.model){
							self.importDiagramList.push({ id: diag.at(j).get('id'), name: diag.at(j).get('name')});
						}
					}
				}
			}
		}
		DataManager.getDataManager().getAllDocumentsCollectionOfType(self.alternativeModel, 'ecomap_ValueStreamMap', function(results){
			self.importDiagramList.push({ id: '', name: 'Select Diagram'});
			for (var i = 0; i < results.length; i++) {
					var type = results.at(i).get('type');
					if (type === "ecomap_ValueStreamMap") {
						loadValueStreamMapPackage(results.at(i));
					}
			}
			
		}, true, null, null, 'vbc:cmof_EObject-name');

	};
	
	self.importValueStreamMapDiagrams();*/
	
	createRevisionBasedOnContext(pack,skipDependentPackages,callback){
		if(window.checkContextForRevision()) {
			window.utils.startSpinner('revisionSpinner', "Creating a copy...");
			function fetchBmModel(newVdmPackage){
					if(newVdmPackage) {
						DataManager.getDataManager().releaseSaveLock();
						DataManager.getDataManager().set('isActive',false);
						window.utils.stopSpinner('revisionSpinner');
						callback(newVdmPackage);
						/*DataManager.getDataManager().saveData({
							success:function(){
								window.utils.stopSpinner('revisionSpinner');
								callback(newVdmPackage);
							}, 
							error:function(){
								window.utils.stopSpinner('revisionSpinner');
								callback(newVdmPackage);
							}, 
							persist:true});	*/
					}
				}
			window.setTimeout(function(){
				DataManager.getDataManager().acquireSaveLock(function () {
					pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel, skipDependentPackages);
				});
			},100);	
		}
		else {
			callback(pack);
		}
	};
	
	deleteValueStreamMap(){
		var self = this;
		window.vdmModelView.enableComplete(false);
		if(window.checkContextForRevision()) {
			var ecoPackage = self.model.getNestedParent();
			var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
			/*if(ecoPackage.get('diagrams').length === 1) {
				var alternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
				var phaseDesignPartRef = alternativeModel.get('phaseDesignPart').findWhere({'beepReference':ecoPackage.id});
				phaseDesignPartRef && phaseDesignPartRef.destroy();
				DataManager.getDataManager().get('router').navigate(DataManager.getDataManager().get('viewAlternative'), {trigger: true});
			}else {*/
				self.createRevisionBasedOnContext(ecoPackage,depPacks,function(newEcoPackage){
					var len = depPacks.length;
					while(len--){
						newEcoPackage.get('dependentPackage').remove(depPacks[len]);
					}
					var bmId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
					var altId = DataManager.getDataManager().getRepositoryId(newEcoPackage.id);			                	
					var ecoToBeDeleted = newEcoPackage.get('diagrams').findWhere({'id':altId+bmId});
					ecoToBeDeleted.destroy();
					DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});	
				});

			/*}*/
		}else {	
			if(self.model.get('data')){					
				bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('MapDeleteAlert', 'Value Stream Map'), function(result) {
				if(result) {
					self.model.destroy();
					DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});			        									    				    	
				}    
				});
			}else{					
				self.model.destroy();
				DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});
			}
		}
	};			
		
    checkUniqueValue(view, event) {
		var self = this;
		var name = event.currentTarget.value.trim();
		var self = this;
		if(name !== ""){
			if(self.model.get('name') === name){
				$('#duplicateName'+self.encodeId).text("");
				$("#complete" + self.encodeId).removeAttr('disabled');
				return;
			}
			var unique = window.isUniqueName(name,null,self.id(),self.model.getNestedParent().get('diagrams'));
			if(unique){
				$('#duplicateName'+self.encodeId).text("");
				$("#complete" + self.encodeId).removeAttr('disabled');
			}
			else{
				$('#duplicateName' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('DuplicateAlert', 'Value Stream Map'));
				$("#complete" + self.encodeId).attr('disabled','disabled');
			}
		}
		else{
			$("#complete" + self.encodeId).attr('disabled','disabled');
		}
	};
	limiter(view,event) {
		var input = event.currentTarget;
		if (input.value < 10) input.value = 10;
		if (input.value > 50) input.value = 50;
	}  

	afterRenderView(){ 
	}

	init(model, options){
		var self = this;
		this.ValueStreamMapDetailsViewModel = this;
		this.saveValueStreamMapDetails = _.bind(this.saveValueStreamMapDetails,self);
		this.copyValueStreamMap = _.bind(this.copyValueStreamMap,self);
		this.generateProcess = _.bind(this.generateProcess,self);
		this.navigateToProcess = _.bind(this.navigateToProcess,self);
		
		this.model = model.model;
		this.id = kb.observable(self.model, 'id');
		this.encodeId = self.htmlEscape(this.id());
		this.enableDelete = ko.observable(true);
		this.enableComplete = ko.observable(true);
		this.enableProcess = ko.observable(this.model.get('process') ? true : false);
		this.disableProcess = !this.enableProcess();
		this.name = ko.observable(self.model ? self.model.get('name') : null);
		this.enableCopy = ko.observable(true)
		this.description = ko.observable(self.model ? self.model.get('description') : "");
		var fsize = self.model ? self.model.get('fontsize') : 12;
		this.fontsize = ko.observable(fsize ? fsize : 12);
		this.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
			/*this.importDiagramList = ko.observableArray();
			this.importDiagram = ko.observable();
			this.alternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));*/
		this.name.subscribe(function (val) {
			self.description(val);
			self.enableComplete(true);
		});
		if (DataManager.getDataManager().get('viewAlternative') !== DataManager.getDataManager().get('currentWorkspace').get('id')) {
			$('#copy' + self.encodeId).hide();
		}
		if(window.checkContextForRevision()) {
			self.enableDelete(false);
		}
	}
    static getInstance(model, options){
        var view = new ValueStreamMapDetailsViewModel(model, options);
        view.init(model, options);
        return view;
    };
}
path.ValueStreamMapDetailsViewModel = ValueStreamMapDetailsViewModel;
