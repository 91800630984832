import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class VDMLDiagramMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.VDMLDiagramMixin = VDMLDiagramMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_VDMLDiagram"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!VDMLDiagramMixin.cummulativeRelations) {
            VDMLDiagramMixin.cummulativeRelations = _.union(VDMLDiagramMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return VDMLDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return VdmlElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return VdmlElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/VDMLDiagramPropertiesTemplate.html",
			templateName : "VDMLDiagramPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/VDMLDiagramViewModel",
			tabId : "VDMLDiagramView",
			tabName: "VDMLDiagram"
		}
	}
	
	}
	path.VDMLDiagramMixin = VDMLDiagramMixin;
	//return VDMLDiagramMixin;
//});